<template>
  <div class="modal-background" @mousedown.self="hideModal()">
    <div class="modal-container">
      <div class="message">
        <span>Оставить жалобу</span>
        <textarea
          placeholder="Введите текст жалобы..."
          v-model="complaintText"
          maxlength="500"
        ></textarea>
      </div>
      <div class="controls-btns">
        <button class="hide-modal-btn" @click="hideModal">Отменить</button>
        <button class="send-complaint-btn" @click="sendComplaint">Сохранить</button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { domain, MicroServices } from '@/globalVariables'

export default {
  name: 'ComplaintModal',
  mixins: [],
  props: ['lessonId'],
  components: {},
  data() {
    return {
      complaintText: ''
    }
  },
  methods: {
    hideModal() {
      this.$root.$emit('hideComplaintModal', false)
    },

    /**
     * Отправить жалобу на занятие
     */
    sendComplaint() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Complaint/create`,
        method: 'POST',
        data: [
          {
            lesson_id: this.lessonId,
            reason: this.complaintText
          }
        ]
      })
        .then(() => {
          this.hideModal()
          this.successAlert('Жалоба отправлена!')
          this.$emit('complaintCreated')
        })
        .catch((error) => this.errorAlert(error))
    }
  },
  computed: {},
  watch: {},
  validations: {}
}
</script>

<style scoped lang="scss">
@import './src/styleVariables';
.modal-background {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.3);
  animation: fade-in 0.3s;
  .modal-container {
    background-color: $main-white;
    border-radius: 8px;
    padding: 14px 24px;
    min-width: 558px;
    min-height: 257px;
    display: flex;
    flex-direction: column;
    .message {
      display: flex;
      flex-direction: column;
      width: 100%;
      span {
        font-size: 18px;
        margin-bottom: 12px;
      }
      textarea {
        border: 1px solid $light-gray;
        box-sizing: border-box;
        border-radius: 8px;
        min-height: 136px;
        height: auto;
        width: 100%;
        margin: 0 auto;
        padding: 6px 11px;
        outline: none;
        font-family: $font-regular;
        resize: none;
      }
      textarea:focus {
        border: 1px solid $bright-blue;
      }
      textarea::placeholder {
        font-size: 14px;
        color: $light-gray;
      }
    }
    .controls-btns {
      display: flex;
      justify-content: flex-end;
      margin-top: 18px;
      .send-complaint-btn {
        margin-left: 15px;
        border: none;
        background-color: $bright-blue;
        border-radius: 4px;
        padding: 5px 21px;
        color: $main-white;
        outline: none;
      }
      .hide-modal-btn {
        border: 1px solid $bright-blue;
        border-radius: 4px;
        padding: 5px 21px;
        color: $bright-blue;
        outline: none;
      }
    }
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 580px) {
  .modal-background {
    .modal-container {
      width: 90%;
      min-width: unset;
    }
  }
}

@media screen and (max-width: 340px) {
  .modal-background {
    .modal-container {
      .controls-btns {
        flex-direction: column-reverse;
        .send-complaint-btn {
          margin-left: 0;
          margin-bottom: 12px;
        }
      }
    }
  }
}
</style>
