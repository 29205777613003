const timeZoneList = [
  {
    name: '(UTC-12:00) International Date Line West',
    value: -12
  },
  {
    name: '(UTC-11:00) Coordinated Universal Time-11',
    value: -11
  },
  {
    name: '(UTC-10:00) Hawaii',
    value: -10
  },
  {
    name: '(UTC-09:00) Alaska',
    value: -9
  },
  {
    name: '(UTC-08:00) Pacific Time (US, Canada)',
    value: -8
  },
  {
    name: '(UTC-07:00) Arizona',
    value: -7
  },
  {
    name: '(UTC-06:00) Central America',
    value: -6
  },
  {
    name: '(UTC-05:00) Indiana (East)',
    value: -5
  },
  {
    name: '(UTC-04:00) Atlantic Time (Canada)',
    value: -4
  },
  {
    name: '(UTC-03:00) Brasilia',
    value: -3
  },
  {
    name: '(UTC-02:00) Mid-Atlantic - Old',
    value: -2
  },
  {
    name: '(UTC-01:00) Azores',
    value: -1
  },
  {
    name: '(UTC) Edinburgh, London',
    value: 0
  },
  {
    name: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    value: 1
  },
  {
    name: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
    value: 2
  },
  {
    name: '(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk',
    value: 3
  },
  {
    name: '(UTC+04:00) Samara, Ulyanovsk, Saratov',
    value: 4
  },
  {
    name: '(UTC+05:00) Yekaterinburg',
    value: 5
  },
  {
    name: '(UTC+06:00) Nur-Sultan (Astana)',
    value: 6
  },
  {
    name: '(UTC+07:00) Novosibirsk',
    value: 7
  },
  {
    name: '(UTC+08:00) Krasnoyarsk',
    value: 8
  },
  {
    name: '(UTC+09:00) Yakutsk',
    value: 9
  },
  {
    name: '(UTC+10:00) Canberra, Melbourne, Sydney',
    value: 10
  },
  {
    name: '(UTC+11:00) Vladivostok',
    value: 11
  },
  {
    name: '(UTC+12:00) Magadan',
    value: 12
  },
  {
    name: '(UTC+13:00) Samoa',
    value: 13
  }
]

export default timeZoneList
