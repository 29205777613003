import { notEmpty } from '@/components/Core/KernelProject/helperFunction'

export default {
  methods: {
    Select__onChange(data) {
      if (data.isFilter) {
        if (data.multiple) {
          data.value = []
          for (let i in data.event) {
            data.value.push(data.event[i].id)
          }
          data.value = JSON.stringify(data.value)
        } else {
          if (notEmpty(data.event)) {
            data.value = data.event['id']
          }
        }
      } else {
        if (data.multiple) {
          data.value = []
          for (let i in data.event) {
            data.value.push(data.event[i].id)
          }
        } else {
          data.value = data.event
        }
      }
      this.$store.commit('newRec', data)
    }
  },
  data() {
    return {
      Select: {
        value: this.value,
        caption: this.caption
      }
    }
  }
}
