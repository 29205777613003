<template>
  <div>
    <div class="calendar-container">
      <div class="filter-panel">
        <div
          class="lesson-filters"
          v-if="
            $cookie.get('internalName') === 'manager' || $cookie.get('internalName') === 'admin'
          "
        >
          <vue-select
            class="select"
            placeholder="Роль"
            v-model="selectedRole"
            :options="availableRoles"
            @input="getUserAndLevelList()"
            :clearable="daySelected"
            :searchable="false"
            :filterable="false"
          >
            <template slot="option" slot-scope="option">
              <div class="d-center">
                <span>{{ option.name }}</span>
              </div>
            </template>
            <template slot="selected-option">
              <div class="selected d-center">
                {{ selectedRole.name }}
              </div>
            </template>
            <span slot="no-options">Нет ролей</span>
          </vue-select>
          <vue-select
            class="select"
            v-model="selectedLevel"
            placeholder="Уровень"
            :options="levelList"
            :disabled="(!selectedRole || selectedRole.type === 'Methodologist') && !daySelected"
            @input="getUserList()"
            :searchable="false"
            :filterable="false"
            multiple
            clearable
          >
            <template slot="option" slot-scope="option">
              <div class="d-center">
                <span>{{ option.name }}</span>
              </div>
            </template>
            <template slot="selected-option" slot-scope="selectedOption">
              <div class="selected d-center">
                {{ selectedOption.name }}
              </div>
            </template>
            <span slot="no-options">Нет уровней</span>
          </vue-select>
          <vue-select
            class="select"
            style="width: 320px"
            v-model="selectedName"
            placeholder="ФИО"
            :options="userList"
            :disabled="!selectedRole && !daySelected"
            @input="
              getUserTimetable()
              addTestMode = false
              addTimeMode = false
            "
            :clearable="daySelected"
            :multiple="daySelected"
            :searchable="false"
            :filterable="false"
          >
            <template slot="option" slot-scope="option">
              <div class="d-center">
                <span>{{ option.full_name }}</span>
              </div>
            </template>
            <template slot="selected-option" slot-scope="selectedOption">
              <div class="selected d-center">
                {{ selectedOption.full_name }}
              </div>
            </template>
            <span slot="no-options">Нет пользователей</span>
          </vue-select>
        </div>

        <div
          class="time-period-filters"
          v-if="
            ($cookie.get('internalName') === 'manager' ||
              $cookie.get('internalName') === 'admin') &&
            !addTimeMode &&
            !addTestMode
          "
        >
          <button @click="chooseDay" :class="{ selected: daySelected }">День</button>
          <button @click="chooseWeek" :class="{ selected: weekSelected }">Неделя</button>
          <button @click="chooseMonth" :class="{ selected: monthSelected }">Месяц</button>
        </div>
        <div class="choose-test-time" v-if="studentStage === 'FL_CHOICE_TEST_TIME' || addTestMode">
          Выберите время для тестирования
        </div>
        <div class="choose-study-time" v-else-if="studentStage === 'FL_CHOICE_STUDY_TIME'">
          <span> Выберите время для занятий</span>
          <div class="buttons" v-show="freeTimeData.work_times.length">
            <button class="save" @click="saveFreeTime('student')">Сохранить</button>
            <button class="cancel" @click="freeTimeData = { user_uuid: null, work_times: [] }">
              Сбросить
            </button>
          </div>
        </div>
      </div>
      <div
        class="add-buttons"
        v-if="
          teacherStage !== 'FTL_TESTING' &&
          teacherStage !== 'FTL_CHOICE_TEST_TIME' &&
          teacherStage !== 'FTL_PENDING'
        "
      >
        <div
          class="add-free-time-button"
          v-if="
            weekSelected &&
            $cookie.get('internalName') !== 'student' &&
            filter &&
            selectedName.role !== 'student' &&
            !addTestMode
          "
        >
          <button
            v-if="!addTimeMode"
            @click="
              addTimeMode = true
              getUserWorkTime()
            "
          >
            <img src="../../assets/img/ES/plus_white.svg" alt="plus" />Добавить свободное время
          </button>
          <button class="cancel" v-if="addTimeMode" @click="cancelFreeTime">Отмена</button>
          <button class="save" v-if="addTimeMode" @click="saveFreeTime">Сохранить</button>
        </div>
        <div
          class="add-free-time-button"
          v-if="
            weekSelected &&
            ($cookie.get('internalName') === 'manager' ||
              $cookie.get('internalName') === 'admin') &&
            filter &&
            selectedName.role === 'teacher' &&
            !addTimeMode &&
            teacherStage !== 'FTL_CHOICE_TEST_TIME'
          "
        >
          <button
            v-if="!addTestMode"
            @click="
              addTestMode = true
              getMethodistWorkTime()
            "
          >
            <img src="../../assets/img/ES/plus_white.svg" alt="plus" />Добавить тестирование
          </button>
          <button
            class="cancel"
            v-if="addTestMode"
            @click="
              addTestMode = false
              getUserTimetable()
            "
          >
            Отмена
          </button>
          <div class="choose-free-time" v-if="addTestMode">
            Пожалуйста, выберите свободное время преподавателя
          </div>
        </div>
        <div
          class="add-free-time-button"
          v-if="
            weekSelected &&
            ($cookie.get('internalName') === 'manager' ||
              $cookie.get('internalName') === 'admin') &&
            filter &&
            !addTimeMode &&
            !addTestMode &&
            studentStage !== 'FL_CHOICE_STUDY_TIME' &&
            studentStage !== 'FL_CHOICE_TEST_TIME' &&
            studentStage !== 'FL_TESTING' &&
            studentStage !== 'FL_PENDING' &&
            (selectedRole.type === 'Student' ||
              selectedRole.type === 'Teacher' ||
              selectedRole.type === 'Methodologist') &&
            selectedName
          "
        >
          <button class="cancel" style="width: 320px" @click="unsetUserStage">
            Сбросить расписание пользователя
          </button>
        </div>
        <div
          class="add-free-time-button"
          v-if="
            weekSelected &&
            ($cookie.get('internalName') === 'teacher' ||
              $cookie.get('internalName') === 'methodologist' ||
              $cookie.get('internalName') === 'student') &&
            filter &&
            studentStage !== 'FL_CHOICE_STUDY_TIME' &&
            studentStage !== 'FL_CHOICE_TEST_TIME' &&
            studentStage !== 'FL_TESTING' &&
            studentStage !== 'FL_PENDING' &&
            !addTimeMode &&
            !addTestMode
          "
        >
          <button class="cancel" @click="requestToUnsetSchedule">Сбросить расписание</button>
        </div>
      </div>
      <div
        class="date-nav-panel"
        v-if="
          (weekSelected || monthSelected) && !addTimeMode && studentStage !== 'FL_CHOICE_STUDY_TIME'
        "
        v-show="(weekSelected || monthSelected) && filter && showTimeFilter"
      >
        <div class="choose-week">
          <div class="select-week">
            <button class="prev-week arrow-btn" type="Button" @click="selectPrevWeek()">
              <img src="@/assets/img/icons/arrow-left.svg" />
            </button>
            <div class="current-week">
              {{
                weekSelected
                  ? `${formattedStartWeek} - ${formattedEndWeek}`
                  : `${monthes[new Date(weekStart).getMonth()]}`
              }}, {{ currentYear }}
            </div>
            <button class="next-week arrow-btn" type="Button" @click="selectNextWeek()">
              <img src="@/assets/img/icons/arrow-right.svg" />
            </button>
          </div>
          <div class="button-today">
            <button @click="showTodaySchedule()">Сегодня</button>
          </div>
        </div>
        <div
          class="lesson-legend"
          v-if="studentStage !== 'FL_CHOICE_TEST_TIME' || studentStage !== 'FL_CHOICE_STUDY_TIME'"
        >
          <button
            :class="{ active: sortByType === 'free' }"
            class="free-time"
            @click="sortByType === 'free' ? (sortByType = null) : (sortByType = 'free')"
            v-if="$cookie.get('internalName') !== 'student'"
          >
            <img :src="require(`../../assets/img/ES/free_time.svg`)" />Свободное время
          </button>
          <button
            :class="{ active: sortByType === 'empty' }"
            class="empty-card"
            @click="sortByType === 'empty' ? (sortByType = null) : (sortByType = 'empty')"
            v-if="$cookie.get('internalName') !== 'student'"
          >
            <img :src="require(`../../assets/img/empty-card.svg`)" />Незаполненная карточка
          </button>
          <button
            :class="{ active: sortByType === 'complete' }"
            class="lesson-complete"
            @click="sortByType === 'complete' ? (sortByType = null) : (sortByType = 'complete')"
          >
            <img :src="require(`../../assets/img/lesson-complete.svg`)" />Занятие состоялось
          </button>
          <button
            :class="{ active: sortByType === 'test' }"
            class="test"
            @click="sortByType === 'test' ? (sortByType = null) : (sortByType = 'test')"
            v-if="$cookie.get('internalName') !== 'student'"
          >
            <img :src="require(`../../assets/img/test.svg`)" />{{
              $cookie.get('internalName') !== 'teacher' ? `Тест` : 'Мои тестирования'
            }}
          </button>
          <button
            :class="{ active: sortByType === 'lesson' }"
            class="lesson"
            v-if="$cookie.get('internalName') !== 'methodologist'"
            @click="sortByType === 'lesson' ? (sortByType = null) : (sortByType = 'lesson')"
          >
            <img :src="require(`../../assets/img/lesson.svg`)" />Занятие
          </button>
        </div>
      </div>
      <div
        class="calendar"
        v-if="(filter && min !== undefined && weekSelected) || addTimeMode"
        v-show="!loading"
      >
        <div class="hour-list">
          <div
            v-for="(hour, index) in hours"
            :key="hour.id"
            :style="!(index >= min && index <= max) && !addTimeMode ? 'display: none' : ''"
          >
            <span v-if="(index >= min && index <= max) || addTimeMode">{{ hour }}</span>
          </div>
        </div>
        <div class="row-schedule" v-for="row in data" :key="row.index">
          <span :class="{ 'current-day': isCurrentDay(row.date) }"
            >{{ row.day.day_short_name }}
            <div v-if="!addTimeMode && studentStage !== 'FL_CHOICE_STUDY_TIME'">
              , {{ formatDate(row.date) }}
            </div></span
          >
          <div
            class="cell"
            v-for="(cell, index) in row.sections"
            :key="cell.index"
            :style="!(index >= min && index <= max) && !addTimeMode ? 'display: none' : ''"
          >
            <div class="hour-line" v-if="currentHour(cell.hour, row.date)" />
            <div
              v-if="addTimeMode"
              style="width: 100%; height: 100%; padding: 5px 0; grid-row-start: 2; grid-row-end: 3"
            >
              <div
                v-for="card in cell['cards']"
                class="card"
                :key="card.id"
                style="align-self: center"
              >
                <div
                  v-if="card.type && card.kls_type_code !== 'TEACHER_FIRST_TEST'"
                  class="teacher-work-time"
                >
                  <div style="margin-top: 4px">Свободное время</div>
                  <div class="card-bottom-content">
                    <span class="time"
                      ><img :src="require(`../../assets/img/alarm.svg`)" />{{
                        formatHours(cell.hour)
                      }}</span
                    >
                    <div class="controls-btns" v-if="false">
                      <button class="edit-card">
                        <img :src="require(`../../assets/img/edit.svg`)" />
                      </button>
                      <button class="delete-card">
                        <img :src="require(`../../assets/img/trash_full.svg`)" />
                      </button>
                    </div>
                  </div>
                </div>
                <div v-else class="add-free-time" @click="addFreeTime(cell.hour, row.day)">
                  <AddFreeTimeCard :freeTimeData="freeTimeData" :hour="cell.hour" :day="row.day" />
                </div>
              </div>
              <div
                v-if="!cell.cards.length"
                class="add-free-time"
                @click="addFreeTime(cell.hour, row.day)"
              >
                <AddFreeTimeCard :freeTimeData="freeTimeData" :hour="cell.hour" :day="row.day" />
              </div>
            </div>
            <div
              v-else
              v-for="card in cell['cards']"
              :key="card.id"
              class="card"
              :style="
                !addTimeMode && sortByType && sortByType !== 'free'
                  ? (sortByType === 'empty' &&
                      !card.has_filled_card &&
                      card.type !== 'WORKS_TIME' &&
                      card.kls_status_code === 'LESSON_ENDED') ||
                    (sortByType === 'lesson' &&
                      (((card.kls_type_code === 'REGULAR' ||
                        card.kls_type_code === 'TEACHER_TEST_REGULAR_LESSON') &&
                        card.kls_status_code !== 'LESSON_ENDED') ||
                        ($cookie.get('internalName') === 'student' &&
                          card.kls_status_code === 'LESSON_PLANNED'))) ||
                    (sortByType === 'test' &&
                      (card.kls_type_code === 'PLACEMENT_TEST' ||
                        card.kls_type_code === 'CHECK_UP' ||
                        card.kls_type_code === 'CHECK_PROGRESS' ||
                        card.kls_type_code === 'TEACHER_TEST_PROGRESS_TEST_LESSON' ||
                        card.kls_type_code === 'TEACHER_TEST' ||
                        card.kls_type_code === 'TEACHER_FIRST_TEST')) ||
                    (sortByType === 'complete' &&
                      card.kls_status_code === 'LESSON_ENDED' &&
                      (card.has_filled_card || $cookie.get('internalName') === 'student'))
                    ? ''
                    : 'display: none'
                  : sortByType === 'free' && card.type !== 'WORKS_TIME'
                  ? 'display: none'
                  : addTestMode && card.type !== 'WORKS_TIME'
                  ? 'display: none'
                  : ''
              "
            >
              <div
                v-if="card.type === 'WORKS_TIME'"
                class="teacher-work-time"
                :class="{
                  'study-time':
                    wasAddStudyTime(cell.hour, row.day) && studentStage === 'FL_CHOICE_STUDY_TIME',
                  'blocked-date':
                    !canSetLesson(row.date, cell.hour) &&
                    studentStage !== 'FL_CHOICE_STUDY_TIME' &&
                    studentStage !== 'FL_SCHEDULE' &&
                    (studentStage || addTestMode)
                }"
                @click="
                  studentStage === 'FL_CHOICE_TEST_TIME' && canSetLesson(row.date, cell.hour)
                    ? chooseStudentTestTime(cell.hour, row.date)
                    : addTestMode && canSetLesson(row.date, cell.hour)
                    ? openModalAddTest(cell.hour, row.date)
                    : studentStage === 'FL_CHOICE_STUDY_TIME'
                    ? addFreeTime(cell.hour, row.day)
                    : ''
                "
                :style="
                  studentStage === 'FL_CHOICE_TEST_TIME' ||
                  studentStage === 'FL_CHOICE_STUDY_TIME' ||
                  addTestMode
                    ? 'cursor: pointer'
                    : ''
                "
              >
                <div style="margin-top: 4px">Свободное время</div>
                <div class="card-bottom-content">
                  <span class="time"
                    ><img :src="require(`../../assets/img/alarm.svg`)" />{{
                      formatHours(cell.hour)
                    }}</span
                  >
                  <div class="controls-btns" v-if="false">
                    <button class="edit-card">
                      <img :src="require(`../../assets/img/edit.svg`)" />
                    </button>
                    <button class="delete-card">
                      <img :src="require(`../../assets/img/trash_full.svg`)" />
                    </button>
                  </div>
                </div>
              </div>
              <div v-else-if="card.type === 'RESTS_TIME'" class="teacher-rest-time">
                <div style="margin-top: 4px">Нерабочее время</div>
                <div class="card-bottom-content">
                  <span class="time"
                    ><img :src="require(`../../assets/img/alarm.svg`)" />{{
                      formatHours(cell.hour)
                    }}</span
                  >
                  <div class="controls-btns" v-if="false">
                    <button class="edit-card">
                      <img :src="require(`../../assets/img/edit.svg`)" />
                    </button>
                    <button class="delete-card">
                      <img :src="require(`../../assets/img/trash_full.svg`)" />
                    </button>
                  </div>
                </div>
              </div>
              <div
                v-if="card.type === 'LESSON' && !addTimeMode"
                class="lesson"
                :style="card.type !== 'WORKS_TIME' ? 'cursor: pointer' : ''"
                @click="openInfoModalFunc(card.lesson_id)"
              >
                <div
                  :class="{
                    lessonEnded:
                      card.kls_status_code === 'LESSON_ENDED' &&
                      (card.has_filled_card || $cookie.get('internalName') === 'student'),
                    test:
                      (card.kls_type_code === 'PLACEMENT_TEST' ||
                        card.kls_type_code === 'CHECK_UP' ||
                        card.kls_type_code === 'CHECK_PROGRESS' ||
                        card.kls_type_code === 'TEACHER_TEST_PROGRESS_TEST_LESSON' ||
                        card.kls_type_code === 'TEACHER_TEST' ||
                        card.kls_type_code === 'TEACHER_FIRST_TEST') &&
                      (card.has_filled_card || card.kls_status_code === 'LESSON_PLANNED') &&
                      card.kls_status_code !== 'LESSON_ENDED' &&
                      $cookie.get('internalName') !== 'student',
                    regular:
                      (card.kls_type_code === 'REGULAR' ||
                        card.kls_type_code === 'TEACHER_TEST_REGULAR_LESSON') &&
                      card.kls_status_code !== 'LESSON_ENDED',
                    emptyCard:
                      card.has_filled_card === false &&
                      card.type !== 'WORKS_TIME' &&
                      card.kls_status_code !== 'LESSON_PLANNED' &&
                      $cookie.get('internalName') !== 'student',
                    studentCard:
                      card.kls_status_code !== 'LESSON_ENDED' &&
                      $cookie.get('internalName') === 'student'
                  }"
                ></div>
                <div>
                  {{
                    card.kls_type_code === 'TEACHER_TEST' ||
                    card.kls_type_code === 'TEACHER_FIRST_TEST'
                      ? 'Тестирование'
                      : card.sequence_number
                      ? `Занятие номер ${card.sequence_number}`
                      : 'Занятие'
                  }}
                </div>
                <div
                  class="placement-test"
                  v-if="
                    card.kls_type_code === 'PLACEMENT_TEST' &&
                    $cookie.get('internalName') !== 'student'
                  "
                >
                  Placement tests
                </div>
                <div class="card-bottom-content">
                  <span class="time"
                    ><img :src="require(`../../assets/img/alarm.svg`)" />{{
                      formatHours(cell.hour)
                    }}</span
                  >
                  <div class="controls-btns" v-if="false">
                    <button class="edit-card">
                      <img :src="require(`../../assets/img/edit.svg`)" />
                    </button>
                    <button class="delete-card">
                      <img :src="require(`../../assets/img/trash_full.svg`)" />
                    </button>
                  </div>
                </div>
              </div>
              <ModalTemplate
                @hideInfoModal="cardLessonId = null"
                :chosenCardId="card.lesson_id"
                :openModalFlag="openModalFlag"
                v-if="cardLessonId === card.lesson_id && cardLessonId !== null"
                :modalInfo="modalCardInfo"
              ></ModalTemplate>
            </div>
          </div>
        </div>
      </div>
      <div class="mobile-calendar">
        <MobileCalendar
          :data="data"
          :studentStage="studentStage"
          :teacherStage="teacherStage"
          @addTimeModeOn="
            getUserWorkTime()
            showTimeFilter = false
          "
          @addTimeModeOff="showTimeFilter = true"
          @updateSchedule="getUserTimetable()"
          @updateTeacherStage="getCurrentTeacherStage()"
          @updateStudentStage="getCurrentStudentStage()"
        />
      </div>
      <img class="loader" src="../../assets/img/ES/loader.svg" alt="loader" v-show="loading" />
      <AddTestModal
        v-if="isOpenModalAddTest"
        :day="dayForTest"
        :hour="hourForTest"
        :teacherName="selectedName.full_name"
        :teacherUuid="userUuid"
        @hideAddTestModal="hideAddTestModal"
      />
      <ScheduleByDay
        v-else-if="daySelected"
        :hours="dailyHours"
        :data="data"
        @nextDay="selectNextDay"
        @prevDay="selectPrevDay"
        @today="showTodaySchedule()"
        :max="maxDaily"
        :min="minDaily"
        :currentDay="formattedDay"
      />
      <ScheduleByMonth v-else-if="monthSelected" :sortByType="sortByType" :data="data" />
      <div
        v-else-if="
          !filter &&
          ($cookie.get('internalName') === 'manager' || $cookie.get('internalName') === 'admin')
        "
        class="start-screen"
      >
        <span
          >Пожалуйста, выберите роль и имя пользователя, расписание которого вы хотите
          просмотреть</span
        >
      </div>
      <div v-else-if="min === undefined && !addTimeMode" class="start-screen">Нет занятий</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { domain, MicroServices } from '@/globalVariables'
import format from 'date-fns/format'
import endOfWeek from 'date-fns/endOfWeek'
import startOfWeek from 'date-fns/startOfWeek'
import { ru } from 'date-fns/locale'
import ScheduleEvents from './ScheduleEvents'
import VueSelect from 'vue-select'
import ScheduleByDay from '@/views/Schedule/ScheduleByDay'
import ScheduleByMonth from '@/views/Schedule/ScheduleByMonth'
import AddFreeTimeCard from '@/views/Schedule/components/AddFreeTimeCard'
import ModalTemplate from '@/views/Schedule/components/InfoModal/ModalTemplate'
import AddTestModal from '@/views/Schedule/components/AddTestModal'
import MobileCalendar from '@/views/Schedule/components/MobileCalendar/MobileCalendar'
export default {
  name: 'Schedule',
  mixins: [ScheduleEvents],
  props: [],
  components: {
    MobileCalendar,
    AddTestModal,
    AddFreeTimeCard,
    ScheduleByMonth,
    ScheduleByDay,
    VueSelect,
    ModalTemplate
  },
  data() {
    return {
      modalCardInfo: {},
      attributes: [],
      role: [],
      showTimeFilter: true,
      hours: [
        '00:00',
        '01:00',
        '02:00',
        '03:00',
        '04:00',
        '05:00',
        '06:00',
        '07:00',
        '08:00',
        '09:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
        '19:00',
        '20:00',
        '21:00',
        '22:00',
        '23:00'
      ],
      dailyHours: [
        '01:00',
        '02:00',
        '03:00',
        '04:00',
        '05:00',
        '06:00',
        '07:00',
        '08:00',
        '09:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
        '19:00',
        '20:00',
        '21:00',
        '22:00',
        '23:00'
      ],
      weekStart: this.$cookie.get('weekStart'),
      weekEnd: this.$cookie.get('weekEnd'),
      formattedStartWeek: '',
      formattedEndWeek: '',
      currentYear: '',
      sortByType: null,
      hour: [],
      selectedRole: JSON.parse(this.$cookie.get('roleFilter')) || '',
      availableRoles: [
        { name: 'Методист', type: 'Methodologist' },
        { name: 'Преподаватель', type: 'Teacher' },
        { name: 'Студент', type: 'Student' }
      ],
      selectedLevel: JSON.parse(this.$cookie.get('levelFilter')) || [],
      levelList: [],
      selectedName: JSON.parse(this.$cookie.get('userFilter')) || [],
      weekSelected: this.$cookie.get('weekSelected') === 'true',
      daySelected: this.$cookie.get('daySelected') === 'true',
      monthSelected: this.$cookie.get('monthSelected') === 'true',
      userUuid: [],
      data: [],
      filter: false,
      monday: this.$cookie.get('monday') ? new Date(this.$cookie.get('monday')) : new Date(),
      sunday: this.$cookie.get('sunday') ? new Date(this.$cookie.get('sunday')) : new Date(),
      max: 0,
      min: 0,
      maxDaily: 0,
      minDaily: 0,
      addTimeMode: false,
      addTestMode: false,
      currentDay: new Date(this.$cookie.get('currentDay')) || new Date(),
      formattedDay: null,
      userList: [],
      monthes: [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь'
      ],
      shortMonthes: [
        'Янв',
        'Фев',
        'Мар',
        'Апр',
        'Май',
        'Июн',
        'Июл',
        'Авг',
        'Сен',
        'Окт',
        'Ноя',
        'Дек'
      ],
      daysInMonth: '',
      formattedData: [],
      freeTimeData: {
        user_uuid: null,
        work_times: []
      },
      studentStage: null,
      cardOpen: true,
      openInfoModal: false,
      cardLessonId: null,
      modalInfo: [],
      openModalFlag: false,
      isOpenModalAddTest: false,
      hourForTest: null,
      dayForTest: null,
      teacherStage: null,
      loading: false
    }
  },
  mounted() {
    this.getCurrentWeek(new Date())
    if (this.$cookie.get('internalName') === 'student') {
      this.getCurrentStudentStage()
    }
    if (this.$cookie.get('internalName') === 'teacher') {
      this.getCurrentTeacherStage()
    }
    window.addEventListener('resize', this.getWidth)
  },
  methods: {
    /**
     * Открыть прревью занятия
     * @param id
     */
    openInfoModalFunc(id) {
      this.cardLessonId = id
      this.openModalFlag = true
      this.getModalInfo(id)
    },

    /**
     * Получить уровни для преподавателя
     */
    getRoleLevels() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Classifier/getEducationLevels`,
        method: 'GET',
        params: {
          for_teacher: true,
          _count: 100
        }
      })
        .then((response) => {
          this.levelList = response.data.data.items
        })
        .catch((error) => this.errorAlert(error))
    },

    /**
     * Получить уровни для студента
     */
    getStudentLevels() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Classifier/getEducationLevels`,
        method: 'GET',
        params: {
          _count: 100
        }
      })
        .then((response) => {
          this.levelList = response.data.data.items
        })
        .catch((error) => this.errorAlert(error))
    },

    /**
     * Последствия выбора роли в фильтре
     * @param flag
     */
    getUserAndLevelList(flag) {
      this.$cookie.set('roleFilter', JSON.stringify(this.selectedRole))
      if (this.selectedRole) {
        if (this.selectedRole.type === 'Student') {
          this.getStudentLevels()
        } else if (this.selectedRole.type === 'Teacher') {
          this.getRoleLevels()
        } else {
          this.levelList = []
          this.selectedLevel = []
        }
        this.getUserList(flag)
      } else {
        this.selectedRole = ''
        this.levelList = []
      }
      if (this.daySelected) {
        if (!flag) {
          this.selectedLevel = []
        }
        this.selectedName = []
        if (this.selectedRole) {
          this.getScheduleByDay(this.currentDay, [], this.selectedRole.type.toLowerCase())
        } else {
          this.getUsersForFilter()
          this.getScheduleByDay(this.currentDay, [])
        }
      }
    },
    /**
     * Полчение списка пользователей
     * @param flag
     */
    getUserList(flag) {
      let level
      if (this.selectedLevel.length) {
        level = this.selectedLevel.map((level) => {
          return level.id
        })
      }
      this.$cookie.set('levelFilter', JSON.stringify(this.selectedLevel))
      axios({
        url: `${domain}/${MicroServices[0].prefix}/${this.selectedRole.type}/getItems`,
        method: 'GET',
        params: {
          kls_level_id: JSON.stringify(level),
          _count: 1000
        }
      })
        .then((response) => {
          this.userList = response.data.data.items
          if (!flag && this.daySelected) {
            this.selectedName = []
          }
          if (this.daySelected) {
            this.getScheduleByDay(this.currentDay, [], this.selectedRole.type.toLowerCase(), level)
          }
        })
        .catch((error) => this.errorAlert(error))
    },

    /**
     * Получение текущего периода
     * @param day
     * @param chooseWeek
     */
    getCurrentWeek(day, chooseWeek) {
      if (!this.daySelected && !this.weekSelected && !this.monthSelected) {
        this.weekSelected = true
        this.$cookie.set('weekSelected', true)
      }

      if (this.weekSelected) {
        let start = startOfWeek(new Date(day), { weekStartsOn: 1 })
        let end = endOfWeek(new Date(day), { weekStartsOn: 1 })
        end = end.setDate(end.getDate() + 1)
        this.weekStart = !chooseWeek
          ? this.$cookie.get('weekStart') || format(new Date(start), 'yyyy-MM-dd') + ' 00:00:00'
          : format(new Date(start), 'yyyy-MM-dd') + ' 00:00:00'
        this.weekEnd = !chooseWeek
          ? this.$cookie.get('weekEnd') || format(new Date(end), 'yyyy-MM-dd') + ' 00:00:00'
          : format(new Date(end), 'yyyy-MM-dd') + ' 00:00:00'

        let formatWeekStart = Date.parse(this.weekStart.replace(/ /g, 'T'), 'yyyy-MM-dd') // Так хочет Safari
        let formatWeekEnd = Date.parse(this.weekEnd.replace(/ /g, 'T'), 'yyyy-MM-dd')
        this.formattedStartWeek = format(new Date(formatWeekStart), 'do MMM', {
          locale: ru
        })
        this.formattedEndWeek = format(
          new Date(formatWeekEnd).setDate(new Date(formatWeekEnd).getDate() - 1),
          'do MMM',
          { locale: ru }
        )
        this.currentYear = this.$cookie.get('currentYear') || new Date().getFullYear()
        if (
          this.$cookie.get('internalName') !== 'manager' &&
          this.$cookie.get('internalName') !== 'admin'
        ) {
          this.filter = true
          this.getUserTimetable()
        } else if (this.selectedRole) {
          this.getUserAndLevelList(true)
          if (this.selectedName.sync_uuid) {
            this.getUserTimetable()
          }
        }
      } else if (this.monthSelected) {
        let start = new Date(day).setDate(1)
        let end = new Date(day).setMonth(new Date(day).getMonth() + 1)
        end = new Date(end).setDate(1)
        this.weekStart =
          this.$cookie.get('weekStart') || format(new Date(start), 'yyyy-MM-dd') + ' 00:00:00'
        this.weekEnd =
          this.$cookie.get('weekEnd') || format(new Date(end), 'yyyy-MM-dd') + ' 00:00:00'

        let formatWeekStart = Date.parse(this.weekStart.replace(/ /g, 'T'), 'yyyy-MM-dd')
        let formatWeekEnd = Date.parse(this.weekEnd.replace(/ /g, 'T'), 'yyyy-MM-dd')

        this.formattedStartWeek = format(new Date(formatWeekStart), 'do MMM', { locale: ru })
        this.formattedEndWeek = format(new Date(formatWeekEnd), 'do MMM', { locale: ru })
        this.currentYear = this.$cookie.get('currentYear') || new Date().getFullYear()
        if (this.selectedRole) {
          this.getUserAndLevelList(true)
          if (this.selectedName.sync_uuid) {
            this.userUuid = this.selectedName.sync_uuid
            this.filter = true
            this.getScheduleByMonth()
          }
        }
      } else if (this.daySelected) {
        this.currentDay =
          this.$cookie.get('currentDay') || format(new Date(), 'yyyy-MM-dd') + ' 00:00:00'
        this.formattedDay = format(new Date(this.currentDay), 'do MMM', { locale: ru })
        if (this.selectedRole) {
          this.getUserAndLevelList(true)
          if (this.selectedName.sync_uuid) {
            this.getUserTimetable()
          }
        } else {
          this.getScheduleByDay(this.currentDay, [])
        }
      }
    },
    formatDate(date) {
      return date.substring(date.length - 2)
    },

    /**
     * Последствия выбора расписания по дням
     */
    chooseDay() {
      this.loading = false
      this.$cookie.set('monthSelected', false)
      this.$cookie.set('weekSelected', false)
      this.$cookie.set('daySelected', true)
      this.monthSelected = false
      this.weekSelected = false
      this.daySelected = true
      this.currentDay =
        this.$cookie.get('currentDay') || format(new Date(), 'yyyy-MM-dd') + ' 00:00:00'
      this.formattedDay = format(new Date(this.currentDay), 'do MMM', { locale: ru })
      if (this.selectedRole) {
        let level
        if (this.selectedLevel.length) {
          level = this.selectedLevel.map((level) => {
            return level.id
          })
        }
        this.getScheduleByDay(this.currentDay, [], this.selectedRole.type.toLowerCase(), level)
      }
    },

    /**
     * Последствия выбора расписания по неделям
     */
    chooseWeek() {
      this.loading = false
      this.$cookie.set('monthSelected', false)
      this.$cookie.set('weekSelected', true)
      this.$cookie.set('daySelected', false)
      this.monthSelected = false
      this.weekSelected = true
      this.daySelected = false
      this.getCurrentWeek(new Date(), true)
      this.data = null
      if (this.userUuid && typeof this.userUuid === 'string') {
        this.getUserTimetable()
      } else {
        this.selectedName = null
        this.userUuid = []
        this.filter = false
      }
    },

    /**
     * Последствия выбора расписания по месяцам
     */
    chooseMonth() {
      this.loading = false
      this.$cookie.set('monthSelected', true)
      this.$cookie.set('weekSelected', false)
      this.$cookie.set('daySelected', false)
      this.monthSelected = true
      this.weekSelected = false
      this.daySelected = false
      this.getCurrentWeek(new Date())
      this.data = null

      if (this.userUuid && typeof this.userUuid === 'string') {
        this.getScheduleByMonth()
      } else {
        this.selectedName = null
        this.userUuid = []
        this.filter = false
      }
      // else if (this.userUuid.length) {
      //   this.userUuid = this.userUuid[0]
      //   this.getScheduleByMonth()
      // }
    },

    /**
     * Получение расписания пользователя
     * @param chooseDate
     * @returns {null}
     */
    getUserTimetable(chooseDate) {
      if (this.daySelected) {
        this.userUuid = this.selectedName.map((user) => {
          return user.sync_uuid
        })
        this.$cookie.set('userFilter', JSON.stringify(this.selectedName))
        let level
        if (this.selectedLevel.length) {
          level = this.selectedLevel.map((level) => {
            return level.id
          })
        }
        if (this.selectedRole) {
          this.getScheduleByDay(
            this.currentDay,
            this.userUuid,
            this.selectedRole.type.toLowerCase(),
            level
          )
        } else {
          this.getScheduleByDay(this.currentDay, this.userUuid, undefined, level)
        }
        return null
      }
      if (
        this.$cookie.get('internalName') !== 'manager' &&
        this.$cookie.get('internalName') !== 'admin'
      ) {
        this.userUuid = this.$cookie.get('uid')
      } else {
        this.userUuid = this.selectedName.sync_uuid
      }
      this.$cookie.set('userFilter', JSON.stringify(this.selectedName))

      if (this.monthSelected) {
        this.filter = true
        this.getScheduleByMonth()
        return null
      }
      this.loading = true
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Schedule/getItems`,
        method: 'GET',
        params: {
          user_uuid: this.userUuid,
          begin_date: this.weekStart,
          end_date: this.weekEnd
        }
      })
        .then((response) => {
          this.data = response.data.data
          this.$root.$emit('getScheduleData', this.data)
          if (this.weekSelected) {
            this.cutData(response.data.data)
          }
          this.filter = true
          if (this.selectedRole.type === 'Teacher' && !chooseDate) {
            this.getCurrentTeacherStage(this.selectedName.sync_uuid)
          } else if (this.selectedRole.type === 'Student' && !chooseDate) {
            this.getCurrentStudentStage(this.selectedName.sync_uuid)
          } else if (this.selectedRole.type === 'Methodologist') {
            this.teacherStage = null
            this.studentStage = null
          }
          if (
            this.addTestMode &&
            this.teacherStage !== 'FTL_CHOICE_TEST_TIME' &&
            this.selectedRole.type === 'Teacher'
          ) {
            this.getMethodistWorkTime()
          } else {
            this.loading = false
          }
        })
        .catch((error) => {
          this.errorAlert(error)
          this.loading = false
        })
    },

    /**
     * Выбрать предыдущий месяц/неделю
     */
    selectPrevWeek() {
      if (this.weekSelected) {
        this.monday.setDate(this.monday.getDate() - ((this.monday.getDay() + 6) % 7))
        this.monday.setDate(this.monday.getDate() - 7)
        let prevMonday = new Date(
          this.monday.getFullYear(),
          this.monday.getMonth(),
          this.monday.getDate()
        )
        this.sunday.setDate(this.sunday.getDate() - ((this.sunday.getDay() + 6) % 7))
        this.sunday.setDate(this.sunday.getDate())
        let prevSunday = new Date(
          this.sunday.getFullYear(),
          this.sunday.getMonth(),
          this.sunday.getDate()
        )
        this.weekStart = format(new Date(prevMonday), 'yyyy-MM-dd') + ' 00:00:00'
        this.weekEnd = format(new Date(prevSunday), 'yyyy-MM-dd') + ' 00:00:00'

        let formatWeekEnd = Date.parse(this.weekEnd.replace(/ /g, 'T'), 'yyyy-MM-dd')

        this.formattedStartWeek = format(new Date(prevMonday), 'do MMM', { locale: ru })
        this.formattedEndWeek = format(
          new Date(formatWeekEnd).setDate(new Date(formatWeekEnd).getDate() - 1),
          'do MMM',
          { locale: ru }
        )
        this.getUserTimetable(true)
        this.monday = new Date(prevMonday)
        this.$cookie.set('monday', this.monday)
        this.currentYear = new Date(prevMonday).getFullYear()
        this.$cookie.set('currentYear', this.currentYear)

        this.sunday = new Date(prevSunday.setDate(prevSunday.getDate() - 1))
        this.$cookie.set('sunday', this.sunday)
      } else {
        let weekstart = new Date(this.weekStart).setDate(1)
        weekstart = new Date(weekstart).setMonth(new Date(weekstart).getMonth() - 1)
        let weekend = new Date(this.weekEnd).setMonth(new Date(weekstart).getMonth() + 1)
        weekend = new Date(weekend).setDate(1)
        if (new Date(weekstart).getMonth() === 11 || new Date(this.weekEnd).getMonth() === 0) {
          weekend = new Date(weekend).setFullYear(new Date(weekend).getFullYear() - 1)
        }
        this.weekStart = format(new Date(weekstart), 'yyyy-MM-dd') + ' 00:00:00'
        this.weekEnd = format(new Date(weekend), 'yyyy-MM-dd') + ' 00:00:00'
        this.currentYear = new Date(this.weekStart).getFullYear()
        this.$cookie.set('currentYear', this.currentYear)

        this.getScheduleByMonth()
      }
      this.$cookie.set('weekStart', this.weekStart)
      this.$cookie.set('weekEnd', this.weekEnd)
    },

    /**
     * Выбрать следующий месяц/неделю
     */
    selectNextWeek() {
      if (this.weekSelected) {
        this.monday.setDate(this.monday.getDate() - ((this.monday.getDay() + 6) % 7))
        this.monday.setDate(this.monday.getDate() + 7)
        let nextMonday = new Date(
          this.monday.getFullYear(),
          this.monday.getMonth(),
          this.monday.getDate()
        )
        this.sunday.setDate(this.sunday.getDate() - ((this.sunday.getDay() + 6) % 7))
        this.sunday.setDate(this.sunday.getDate())
        let nextSunday = new Date(
          this.sunday.getFullYear(),
          this.sunday.getMonth(),
          this.sunday.getDate() + 14
        )
        this.weekStart = format(new Date(nextMonday), 'yyyy-MM-dd') + ' 00:00:00'
        this.weekEnd = format(new Date(nextSunday), 'yyyy-MM-dd') + ' 00:00:00'
        let formatWeekEnd = Date.parse(this.weekEnd.replace(/ /g, 'T'), 'yyyy-MM-dd')
        this.formattedStartWeek = format(new Date(nextMonday), 'do MMM', { locale: ru })
        this.formattedEndWeek = format(
          new Date(formatWeekEnd).setDate(new Date(formatWeekEnd).getDate() - 1),
          'do MMM',
          { locale: ru }
        )
        this.getUserTimetable(true)
        this.monday = new Date(nextMonday)
        this.$cookie.set('monday', this.monday)
        this.currentYear = new Date(nextMonday).getFullYear()
        this.$cookie.set('currentYear', this.currentYear)
        this.sunday = new Date(nextSunday.setDate(nextSunday.getDate() - 1))
        this.$cookie.set('sunday', this.sunday)
      } else {
        let weekstart = new Date(this.weekStart).setDate(1)
        weekstart = new Date(weekstart).setMonth(new Date(weekstart).getMonth() + 1)
        let weekend = new Date(this.weekEnd).setMonth(new Date(weekstart).getMonth() + 1)
        weekend = new Date(weekend).setDate(1)
        this.weekStart = format(new Date(weekstart), 'yyyy-MM-dd') + ' 00:00:00'
        this.weekEnd = format(new Date(weekend), 'yyyy-MM-dd') + ' 00:00:00'
        this.currentYear = new Date(this.weekStart).getFullYear()
        this.$cookie.set('currentYear', this.currentYear)

        this.getUserTimetable(this.weekStart, this.weekEnd)
      }

      this.$cookie.set('weekStart', this.weekStart)
      this.$cookie.set('weekEnd', this.weekEnd)
    },

    /**
     * Выбрать следующий день
     */
    selectNextDay() {
      let weekstart = new Date(this.currentDay)
      weekstart = weekstart.setDate(weekstart.getDate() + 1)
      this.currentDay = format(new Date(weekstart), 'yyyy-MM-dd') + ' 00:00:00'
      this.formattedDay = format(new Date(this.currentDay), 'do MMM', { locale: ru })
      this.currentYear = new Date(this.currentDay).getFullYear()
      this.$cookie.set('currentYear', this.currentYear)
      this.$cookie.set('currentDay', this.currentDay)
      let level
      if (this.selectedLevel.length) {
        level = this.selectedLevel.map((level) => {
          return level.id
        })
      }
      this.getScheduleByDay(
        this.currentDay,
        this.userUuid,
        this.selectedRole.type?.toLowerCase(),
        level
      )
    },

    /**
     * Выбрать предыдущий день
     */
    selectPrevDay() {
      let weekstart = new Date(this.currentDay)
      weekstart = weekstart.setDate(weekstart.getDate() - 1)
      this.currentDay = format(new Date(weekstart), 'yyyy-MM-dd') + ' 00:00:00'
      this.formattedDay = format(new Date(this.currentDay), 'do MMM', { locale: ru })
      this.currentYear = new Date(this.currentDay).getFullYear()
      this.$cookie.set('currentYear', this.currentYear)
      this.$cookie.set('currentDay', this.currentDay)

      let level
      if (this.selectedLevel.length) {
        level = this.selectedLevel.map((level) => {
          return level.id
        })
      }
      this.getScheduleByDay(
        this.currentDay,
        this.userUuid,
        this.selectedRole.type?.toLowerCase(),
        level
      )
    },

    /**
     * Обрезать время для расписания по неделям
     * @param data
     */
    cutData(data) {
      this.max = 0
      this.min = undefined
      for (let k = 0; k < data.length; k++) {
        if (data[k].sections[0].cards.length) {
          this.min = 0
        }
        if (data[k].sections[data[k].sections.length - 1].cards.length) {
          this.max = data[k].sections.length - 1
        }
        for (let i = 1; i < data[k].sections.length; i++) {
          if (
            data[k].sections[i].cards.length &&
            !data[k].sections[i - 1].cards.length &&
            (this.min === undefined || this.min > i)
          ) {
            this.min = i
          } else if (
            !data[k].sections[i].cards.length &&
            data[k].sections[i - 1].cards.length &&
            this.max < i
          ) {
            this.max = i - 1
          }
        }
      }
    }
  },
  computed: {},
  watch: {},
  validations: {}
}
</script>

<style scoped lang="scss">
@import './src/styleVariables';

.active {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
}
.modal-container {
  position: relative;
}
.loader {
  position: absolute;
  animation-name: rotate;
  animation-duration: 0.7s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  width: 38px;
  top: 50%;
  left: 50%;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.card-container {
  //width: 372px;
  //min-height: 197px;
  //height: 197px;
  //background: $main-white;
  //border-radius: 8px;
  //padding: 15px 10px 10px 18px;
  //z-index: 100;
  //position: relative;
  //top: 0;
  //bottom: 0;
  //left: 100px;
  //right: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 300px;
  position: absolute;
  height: 124px;
  z-index: 1000;
  //margin-left: -300px;
  top: 100px;
  .card-header {
    display: flex;
    justify-content: space-between;
  }
}
/deep/.vs__dropdown-toggle {
  min-height: 42px;
  border: none;
  padding: 0 8px !important;
  .vs__selected {
    margin: 0 !important;
  }
  .vs__selected-options input::placeholder {
    color: #c5cee0;
    margin-top: -4px;
  }
  .vs__search,
  .vs__search:focus {
    margin: 0;
  }
  .vs--disabled .vs__search {
    background-color: #f8f8f8 !important;
  }
  .vs--disabled .vs__dropdown-toggle {
    background-color: #c5cee0;
  }
  .vs__selected {
    background-color: $main-white;
  }
}
.calendar-container {
  font-family: $font-bold;
  font-family: $font-regular, sans-serif !important;
  background-color: $background-blue !important;
  max-width: 1800px;
  margin: 0 auto;
  .mobile-calendar {
    display: none;
  }
  .filter-panel {
    display: flex;
    justify-content: space-between;
    padding: 24px;
    flex-wrap: wrap;

    .lesson-filters {
      display: flex;
      .select {
        width: 210px;
        background-color: $main-white;
        margin-right: 15px;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16) !important;
        border-radius: 4px !important;
        min-height: 42px !important;
        height: fit-content;
        border: none !important;
      }
    }
    .time-period-filters {
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
      border-radius: 4px;
      padding: 3px;
      height: 42px;
      width: 512px;
      display: flex;
      justify-content: space-around;
      background-color: $main-white;
      button {
        width: 158px;
        color: $main-gray;
        outline: none;
        font-family: $font-bold;
        font-size: 18px;
        line-height: 24px;
      }
      .selected {
        background-color: $bright-blue;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
        border-radius: 4px;
        color: $main-white;
        input::placeholder {
        }
      }
    }
  }
  .add-buttons {
    padding: 0 24px;
    margin-bottom: 24px;
    display: flex;
    .add-free-time-button {
      display: flex;
      align-items: center;
      button {
        font-size: 14px;
        color: #ffffff;
        background-color: $bright-blue;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
        border-radius: 4px;
        margin-right: 15px;
        outline: none;
        padding: 6px;
        width: 210px;
        border: 1px solid transparent;

        img {
          margin-right: 4px;
          width: 18px;
        }
        &:active {
          background-color: $hover-bright-blue;
        }
      }

      .cancel {
        background-color: $main-white;
        border: 1px solid $red;
        color: $red;
        margin-right: 12px;
        &:active {
          background-color: $main-white;
        }
      }
    }
    .choose-free-time {
      height: fit-content;
      font-size: 14px;
      margin-left: 12px;
      color: $bright-blue;
    }
  }
  .date-nav-panel {
    display: flex;
    justify-content: space-between;
    margin: 0 24px 24px 24px;
    align-items: center;
    .lesson-legend {
      flex-wrap: wrap;
      justify-content: flex-end;
      button {
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        border-radius: 5px;
        padding: 5px 8px;
        outline: none;
        img {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }
      }
      .empty-card {
        background-color: rgba(250, 75, 0, 0.13);
        margin-right: 14px;
      }
      .lesson-complete {
        background-color: rgba(29, 190, 64, 0.13);
        margin-right: 14px;
      }
      .test {
        background-color: rgba(255, 213, 0, 0.13);
        margin-right: 14px;
      }
      .lesson {
        background-color: rgba(50, 100, 195, 0.13);
      }
      .free-time {
        background-color: $main-white;
        margin-right: 14px;
      }
    }
    .choose-week {
      display: flex;
      .select-week {
        display: flex;

        .arrow-btn {
          background: #ffffff;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
          border-radius: 4px;
          width: 42px;
          min-width: 42px;
          height: 42px;
          outline: none;

          img {
            width: 10px;
            height: 15px;
            margin: 0;
          }
        }

        .prev-week {
          margin-right: 8px;
        }

        .next-week {
          margin-left: 8px;
        }

        .current-week {
          background: #ffffff;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
          border-radius: 4px;
          min-width: 203px;
          width: auto;
          height: 42px;
          margin: 0 auto;
          font-size: 14px;
          text-align: center;
          padding: 0 8px;
          line-height: 42px;
          vertical-align: middle;
        }
      }
      .button-today {
        margin-left: 16px;

        button {
          border-radius: 4px;
          border: 1px solid $bright-blue;
          color: $bright-blue;
          font-size: 14px;
          background-color: $main-white;
          height: 42px;
          width: 120px;
        }
      }
    }
  }
  .calendar {
    display: flex;
    flex-direction: row;
    padding-bottom: 400px;
    max-width: 1658px;
    margin: 0 auto;
    .hour-list {
      margin-top: 50px;
      width: 84px;
      font-family: $font-regular;
      color: $main-gray;
      font-size: 14px;
      div {
        display: flex;
        flex-direction: column;
        height: 108px;
        width: 84px !important;
        align-items: center;
      }
    }
    .row-schedule {
      color: $main-gray;
      font-family: $font-bold;
      text-align: center;
      border-top: 1px solid $light-gray;
      width: 100%;
      span {
        border: 1px solid $light-gray;
        width: 100%;
        height: 59px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 22px;
      }
      .current-day {
        background-color: $main-white;
        border-radius: 4px;
        border: 1px solid $bright-blue;
        color: $bright-blue;
      }
      .cell {
        width: 100%;
        height: 108px;
        border: 1px solid $light-gray;
        display: grid;
        grid-template-rows: 2px 1fr;
        //display: flex;
        //flex-direction: column;
        //justify-content: center;
        //align-items: center;
        .hour-line {
          margin-top: -1px;
          //margin-bottom: 6px;
          position: relative;
          height: 2px;
          width: 100%;
          background-color: $red;
          &::before {
            content: '';
            background-color: $red;
            display: block;
            border-radius: 50px;
            margin-top: -2px;
            width: 6px;
            height: 6px;
          }
        }
        .card {
          width: 100%;
          height: 94%;
          position: relative;
          grid-row-start: 2;
          grid-row-end: 3;
          justify-self: center;
          align-self: center;
          .teacher-work-time,
          .teacher-rest-time,
          .lesson {
            width: 100%;
            height: 100%;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
            border-radius: 4px;
            background-color: $main-white;
            display: flex;
            flex-direction: column;
            div {
              width: 100%;
              height: auto;
            }
            .placement-test {
              border-radius: 5px;
              background-color: rgba(255, 107, 0, 0.13);
              padding: 1px 12px;
              font-size: 14px;
              line-height: 24px;
              color: $main-dark;
              width: fit-content;
              margin: 8px auto 0 auto;
            }
            .card-bottom-content {
              margin-top: auto;
              display: flex;
              width: 100%;
              justify-content: space-between;
              padding: 0 5px 5px 5px;
              .time {
                border: none;
                font-size: 12px;
                line-height: 21px;
                font-family: $font-regular;
                height: auto;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                img {
                  width: 15px;
                  height: 15px;
                  margin-right: 5px;
                }
              }
              .controls-btns {
                display: flex;
                height: auto;
                justify-content: flex-end;
                button {
                  img {
                    width: 15px;
                    height: 15px;
                    margin-right: 5px;
                  }
                }
              }
            }
            .lessonEnded {
              width: 100%;
              height: 4px;
              background: $lesson-ended;
              border-radius: 4px 4px 0px 0px;
            }
            .test {
              width: 100%;
              height: 4px;
              background: $test;
              border-radius: 4px 4px 0px 0px;
            }
            .regular {
              width: 100%;
              height: 4px;
              background: $regular-lesson;
              border-radius: 4px 4px 0px 0px;
            }
            .emptyCard {
              width: 100%;
              height: 4px;
              background: $empty-card;
              border-radius: 4px 4px 0px 0px;
            }
            .studentCard {
              width: 100%;
              height: 4px;
              background: $regular-lesson;
              border-radius: 4px 4px 0px 0px;
            }
          }
          .teacher-rest-time {
            background-color: $regular-lesson-tag;
          }
          .study-time {
            border: 1px solid $bright-blue;
          }
          .blocked-date {
            background-color: $regular-lesson-tag;
            cursor: unset !important;
          }
        }
        .add-free-time {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          background-color: $light-table-blue;
        }
      }
    }
    .weekdays {
      display: flex;
      flex-direction: row;
      min-width: 80%;
      margin: 25px auto 0 auto;
      justify-content: space-between;
      div {
        display: flex;
        width: 225px;
        height: 59px;
        border: 1px solid $light-gray;
        align-items: center;
        justify-content: center;
      }
    }
    .day-hours {
      margin-top: 84px;
      flex-direction: column;
      div {
        display: flex;
        height: 108px;
        align-items: center;
        justify-content: center;
        border-right: 1px solid $light-gray;
        width: 84px;
        color: $main-gray;
      }
    }
  }
  .start-screen {
    width: 100%;
    height: 72vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-size: 26px;
    text-align: center;
    padding-top: 50px;
    color: $bright-blue;
  }
  .choose-test-time,
  .choose-study-time {
    margin-top: 12px;
    width: 100%;
    font-size: 24px;
    text-align: center;
    color: $bright-blue;
    margin-bottom: -24px;
  }
  .choose-study-time {
    display: flex;
    flex-direction: column;
    span {
      margin: 0 auto 24px auto;
    }
    .buttons {
      button {
        font-size: 14px;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
        border-radius: 4px;
        outline: none;
        padding: 6px;
        width: 210px;
      }
      .save {
        border: 1px solid transparent;
        color: #ffffff;
        background-color: $bright-blue;
        margin-right: 15px;
      }
      .cancel {
        border: 1px solid $red;
        color: $red;
        background-color: $main-white;
      }
    }
  }
}
/deep/ {
  .v-select.select-list.vs--single.vs--searchable {
    max-width: 230px;
  }
  .v-input__slot {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16) !important;
    border-radius: 4px !important;
    height: 42px !important;
  }
  .v-text-field.v-text-field--solo .v-input__control input {
    font-family: $font-bold !important;
    font-size: 18px !important;
    color: $main-gray;
  }
  .v-list .v-select-list .v-sheet .theme--light .v-list--dense .theme--light {
    margin-top: 46px !important;
  }
  .v-menu__content.theme--light {
    top: 120px !important;
  }
}
@media screen and (max-width: 1410px) {
  .calendar-container {
    .filter-panel {
      .time-period-filters {
        margin-top: 20px;
      }
    }
  }
}
@media screen and (max-width: 1280px) {
  .date-nav-panel {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start !important;
    .lesson-legend {
      margin-top: 20px;
    }
  }
  .calendar-container .calendar .hour-list div {
    width: 50px !important;
  }
}

@media screen and (max-width: 720px) {
  .calendar-container {
    .calendar {
      display: none;
    }
    .lesson-legend {
      display: none;
    }
    .mobile-calendar {
      display: block;
    }
    .date-nav-panel {
      margin-top: 20px;
      align-items: center !important;
    }
    .filter-panel {
      display: none;
    }
    .add-buttons {
      display: none;
    }
  }
}

@media screen and (max-width: 480px) {
  .calendar-container {
    .date-nav-panel {
      .choose-week {
        flex-direction: column;
        align-items: center;
        .button-today {
          margin-top: 24px;
        }
      }
    }
  }
}
</style>
