<template>
  <div class="input-message-container">
    <div class="input-message">
      <div class="input-message__file">
        <img
          class="loader"
          v-show="loader"
          src="../../../../assets/img/ES/loader.svg"
          alt="loader"
        />
        <file-upload
          v-if="!uploadedFile"
          v-model="file"
          input-id="uploadConstructor"
          class="file-uploader"
          post-action="/upload/post"
          :drop="true"
          :dropDirectory="true"
          :fileDrop="true"
          :multiple="false"
          ref="uploadConstructor"
        >
          <img
            v-show="!($refs.uploadConstructor && $refs.uploadConstructor.dropActive) && !loader"
            src="../../../../assets/img/ES/file_chat.svg"
            alt="file"
          />
        </file-upload>
      </div>
      <div class="input-message__textarea">
        <v-textarea
          id="chat-message-typer-textarea"
          auto-grow
          rows="1"
          v-model="message"
          placeholder="Введите текст сообщения..."
          counter="1000"
          @keydown.enter.exact.prevent
          @keydown.enter.ctrl.exact="newLine"
          @keyup.enter.exact="
            ;((message && message.length <= 1000) || uploadedFile) && !editedMessage && !editedFile
              ? $emit('sendMessage', message, fileId)
              : (editedMessage || editedFile) &&
                ((message && message.length <= 1000) || uploadedFile)
              ? $emit('editMessage', message, fileId)
              : ''
          "
        />
      </div>
      <div
        class="input-message__send"
        v-show="
          ((message && message.length <= 1000) || uploadedFile) && !editedMessage && !editedFile
        "
        @click="$emit('sendMessage', message, fileId)"
      >
        <img src="../../../../assets/img/ES/send_chat.svg" alt="send" />
      </div>
      <div
        class="input-message__cancel"
        v-show="editedMessage || editedFile"
        @click="$emit('cancelEditingMessage')"
      >
        <img src="../../../../assets/img/ES/close_edit_chat.svg" alt="close" />
      </div>
      <div
        class="input-message__save"
        v-show="
          (editedMessage || editedFile) && ((message && message.length <= 1000) || uploadedFile)
        "
        @click="editMessage"
      >
        <img src="../../../../assets/img/ES/check_chat.svg" alt="edit" />
      </div>
    </div>
    <div class="uploaded-file" v-if="uploadedFile" @click="openFile(filePath)">
      <img
        :src="filePath"
        alt="file"
        v-if="
          uploadedFile.file.extension === 'png' ||
          uploadedFile.file.extension === 'jpeg' ||
          uploadedFile.file.extension === 'jpg' ||
          uploadedFile.file.extension === 'svg'
        "
      />
      <img :src="require(`../../../../assets/img/ES/file_icon.svg`)" alt="file" v-else />
      <div class="file-name">
        {{ uploadedFile.file.name }}
      </div>
      <img
        class="delete"
        @click="deleteFile"
        src="../../../../assets/img/ES/close_big.svg"
        alt="delete"
      />
    </div>
  </div>
</template>

<script>
import FileUpload from 'vue-upload-component'
import axios from 'axios'
import { domain, fileDomain } from '@/globalVariables'
export default {
  name: 'InputMessage',
  props: ['editedMessage', 'editedFile'],
  components: { FileUpload },
  data() {
    return {
      message: null,
      file: [],
      fileId: null,
      filePath: null,
      uploadedFile: null,
      loader: false,
      deletedFileId: null
    }
  },
  mounted() {
    this.$root.$on('messageSuccessfullySend', () => {
      this.message = null
      this.unsetFile()
    })
  },
  computed: {
    /**
     * Установить иконку на файл в зависимости от формата файла
     */
    getImgForFile() {
      switch (this.uploadedFile.file.extension) {
        case 'pdf':
          return '../../../../assets/img/ES/pdf_icon.svg'
        case 'doc':
        case 'docx':
          return '../../../../assets/img/ES/word_icon.svg'
        case 'xls':
        case 'xlsx':
          return '../../../../assets/img/ES/xls_icon.svg'
        case 'zip':
        case '7zip':
        case 'rar':
        case '7z':
          return '../../../../assets/img/ES/archive_icon.svg'
        default:
          return '../../../../assets/img/ES/file_icon.svg'
      }
    }
  },
  methods: {
    /**
     * Перевести курсор на новую строку
     */
    newLine() {
      this.message = `${this.message}\n`
    },

    /**
     * Функция запускает процесс загрузки аватара по чанкам, создает пул и высылает его название
     * оно нужно для последующей отправки чанков на сервер и для получения url автара
     */

    uploadFile() {
      if (this.file.length) {
        this.loader = true
        axios({
          url: `${domain}/monolit/File/createChunksPool`,
          method: 'POST',
          data: [this.file[0].name]
        })
          .then((response) => {
            let pullBasename = response.data.data.pull_basename
            this.createChunks(this.file[0], pullBasename)
          })
          .catch((error) => {
            this.loader = false
            this.errorAlert(error)
          })
      }
    },
    /**
     * функция переводит файл в base64, разбивает на чанки и отправляет на сервер последовательно, чанк за чанком
     * когда последний чанк успешно отправлен, запускается заключительная функция генерации url аватара
     * @param file
     * @param pullBasename
     * @returns {Promise<void>}
     */
    async createChunks(file, pullBasename) {
      await this.getBase64(file.file).then((resp) => {
        this.base64 = resp.replace(resp.substring(0, resp.search(',') + 1), '')
        this.chunksArr = this.base64.match(/.{1,500000}/g)
      })
      for (const [i, item] of this.chunksArr.entries()) {
        try {
          await axios({
            url: `${domain}/monolit/File/uploadPoolChunk`,
            method: 'POST',
            data: [pullBasename, item]
          })
        } catch (error) {
          this.loader = false
          this.errorAlert(error)
        } finally {
          if (parseInt(i) === this.chunksArr.length - 1) {
            this.getFileFromPool(pullBasename)
          }
        }
      }
    },
    /**
     * Функция получает url аватара и запускает функцию обновления аватара пользователя
     */
    getFileFromPool(pullBasename) {
      axios({
        url: `${domain}/monolit/File/collectFileFromPool`,
        method: 'POST',
        data: [pullBasename]
      })
        .then((response) => {
          this.fileId = response.data.data.file.id
          this.uploadedFile = response.data.data
          this.filePath = `${fileDomain}${response.data.data.file_url}`
          this.file = []
          this.loader = false
        })
        .catch((error) => {
          this.loader = false
          this.errorAlert(error)
        })
    },
    /**
     * Удалить файл из сообщения и базы
     * @param e
     * @returns {null}
     */
    deleteFile(e) {
      e && e.stopPropagation()
      if (this.editedFile && !this.deletedFileId) {
        this.deletedFileId = this.fileId
        this.unsetFile()
        return null
      }
      let id = this.editedFile && this.deletedFileId ? this.deletedFileId : this.fileId
      axios({
        url: `${domain}/monolit/File/delete/${id}`,
        method: 'POST'
      })
        .then(() => {
          this.unsetFile()
          this.deletedFileId = null
        })
        .catch((error) => this.errorAlert(error))
    },
    /**
     * Установить сообщение и файл на редактирование
     */
    editMessage() {
      this.$emit('editMessage', this.message, this.fileId)
      if (this.editedFile && this.deletedFileId) {
        this.deleteFile()
      }
    },
    /**
     * Убрать файл
     */
    unsetFile() {
      this.uploadedFile = null
      this.file = []
      this.filePath = null
      this.fileId = null
    },
    /**
     * Открыть файл
     * @param path
     */
    openFile(path) {
      window.open(`${path}`, '_blank')
    }
  },
  watch: {
    editedMessage() {
      this.message = this.editedMessage
    },
    editedFile() {
      this.uploadedFile = this.editedFile
      this.deletedFileId = null
      if (this.uploadedFile) {
        this.filePath = `${fileDomain}${this.uploadedFile.file_url}`
        this.fileId = this.uploadedFile.file.id
      } else {
        this.unsetFile()
      }
    },
    file() {
      this.uploadFile()
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../../styleVariables';
.loader {
  position: absolute;
  animation-name: rotate;
  animation-duration: 0.7s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  margin-top: -22px;
  margin-left: -17px;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.input-message-container {
  width: 100%;
  padding: 15px 24px;
  .uploaded-file {
    margin-top: -12px;
    margin-left: 9px;
    border-radius: 4px;
    border: 1px solid $light-gray;
    width: fit-content;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
      height: 32px;
      margin-right: 4px;
      max-width: 90px;
    }
    .delete {
      margin-left: 18px;
      width: 24px;
      cursor: pointer;
    }
    .file-name {
      max-width: 120px;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
  }
}
.input-message {
  width: 100%;
  display: flex;
  align-items: flex-end;
  &__file {
    margin-bottom: 29px;
    cursor: pointer;
    .file-uploads {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .file-uploader {
      /deep/ label {
        cursor: pointer;
      }
    }
  }
  &__send {
    margin-bottom: 29px;
    cursor: pointer;
  }
  &__save {
    margin-bottom: 29px;
    cursor: pointer;
  }
  &__cancel {
    margin-bottom: 29px;
    margin-right: 12px;
    cursor: pointer;
  }
  &__textarea {
    width: 100%;
    margin: 0 9px;
    /deep/ .v-textarea {
      border: none;
      textarea {
        border: 1px solid $light-gray;
        border-radius: 5px;
        padding: 11px 13px;
        font-size: 14px;
        font-family: $font-regular;
        line-height: 18px;
        max-height: 164px;
        overflow-y: auto;
        min-height: 42px !important;
        &::placeholder {
          font-size: 14px;
          line-height: 18px;
          font-family: $font-regular;
        }
      }
    }
    /deep/ .v-text-field__slot:after {
      display: none;
    }
    /deep/ .v-text-field > .v-input__control > .v-input__slot:after {
      display: none;
    }
    /deep/ .v-text-field > .v-input__control > .v-input__slot:before {
      display: none;
    }
  }
}
</style>
