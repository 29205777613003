export default {
  methods: {
    Change__Text(data) {
      this.$store.commit('newRec', data)
    }
  },
  data() {
    return {
      inputText: {
        placeholder: 'Enter',
        value: '',
        disabled: false,
        caption: 'caption',
        maxlength: 10,
        key: ''
      }
    }
  }
}
