import CryptoJS from 'crypto-js'
import axios from 'axios'
import { fileDomain, host } from '@/globalVariables'

/**
 * Константа с найстройками для плагина CryptoJs
 * @type {{parse(*=): *, stringify(*): string}}
 */
const CryptoJSAesJson = {
  /**
   * Формирование объекта с данными зашифрованные в AES для отправки в backend
   * @param cipherParams
   * @return {string}
   */
  stringify(cipherParams) {
    let j = { data: cipherParams.ciphertext.toString(CryptoJS.enc.Base64) }
    if (cipherParams.iv) j.initVector = cipherParams.iv.toString()
    if (cipherParams.salt) j.salt = cipherParams.salt.toString()
    return JSON.stringify(j)
  },
  /**
   * Расшифровка объекта зашифрованным в AES пришедшая с backend для валидации timestamp
   * @param jsonStr
   * @return {CryptoJS.encrypt}
   */
  parse(jsonStr) {
    let j = JSON.parse(jsonStr)
    let cipherParams = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(j.data)
    })
    if (j.initVector) cipherParams.iv = CryptoJS.enc.Hex.parse(j.initVector)
    if (j.salt) cipherParams.salt = CryptoJS.enc.Hex.parse(j.salt)
    return cipherParams
  }
}

export default {
  data() {
    return {
      // email: null,
      // password: null,
      timeStamp: null,
      isReg: false,
      // name: null,
      hash: null,
      role: null
    }
  },
  mounted() {
    if (this.$route.path === '/register') {
      this.switchReg()
    }
  },
  methods: {
    /**
     * Получить политику конфиденциальности
     */
    getPrivacyPolicy() {
      let url = `${fileDomain}/public/Политика_конфиденциальности.pdf`
      this.openFile(url)
    },

    /**
     * Открыть файл
     * @param path
     */
    openFile(path) {
      window.open(`${path}`, '_blank')
    },

    /**
     * Получение текущего unix-времени строкой
     * @return {string}
     */
    getNowTimeStamp() {
      let x = new Date()
      let y = x.toISOString()
      return Math.round(new Date(y).getTime() / 1000).toString()
    },
    // getNowTimeStamp() {
    //   return Math.round(new Date().getTime() / 1000).toString()
    // },
    /**
     * Создание hash'a в AES для отправление данных
     * @param key
     * @param msg
     * @return {string}
     */
    getHash(key, msg) {
      return CryptoJS.AES.encrypt(JSON.stringify(msg), key, { format: CryptoJSAesJson }).toString()
    },
    /**
     * Расшифровка хэша AES
     * @param key
     * @param msg
     * @return {any}
     */
    decrypt(key, msg) {
      return JSON.parse(
        CryptoJS.AES.decrypt(msg, key, { format: CryptoJSAesJson }).toString(CryptoJS.enc.Utf8)
      )
    },
    /**
     * Валидация unix-времени сервера и клиентского, должна быть точность до n-минут
     * @param data
     */
    checkTimeStamp(data) {
      let min = 5
      let error = {
        message: `Разница во времени > ${min} минут. Сервер лежит.`,
        code: 500
      }
      let serverTime = this.decrypt(
        CryptoJS.SHA256(CryptoJS.MD5(this.password).toString()).toString(),
        data.sessionKey.data
      )
      if (Number(serverTime) - this.timeStamp > min * 60) {
        this.errorAlert(error)
      } else {
        this.$store
          .dispatch('login', {
            data: data
          })
          .then((response) => {
            this.$cookie.set(
              'mandate',
              JSON.stringify({
                email: this.email.toLowerCase(),
                data: this.hash
              })
            )
            if (this.role.indexOf('pending') !== -1) {
              return
            }
            this.$router.push('/')
            this.successAlert('Поздравляю. Вы успешно авторизированы')
            this.$root.$emit('subscribeGeneral')
          })
      }
    },
    /**
     * Метод авторизации с получением клиентского Unix-времени и хеширование на основе пароля, вектора и соли
     * @return {checkTimeStamp}
     */
    auth() {
      if (!this.isInvalid) {
        this.timeStamp = this.getNowTimeStamp()
        this.hash = this.getHash(
          CryptoJS.SHA256(CryptoJS.MD5(this.password).toString()).toString(),
          this.timeStamp
        )
        axios({
          url: `${host.authUrl}/User/login`,
          method: 'POST',
          data: [
            {
              email: this.email.toLowerCase(),
              data: this.hash
            }
          ]
        })
          .then((response) => {
            this.role = response.data.data.user.roles[0].internal_name
            this.checkTimeStamp(response.data.data)
            if (this.role.indexOf('pending') !== -1) {
              return
            }
            let uuid = response.data.data.user.uuid
            let id = response.data.data.user.id
            let roleId = response.data.data.user.roles[0].id
            let internalName = this.role
            let userName = response.data.data.user.name
            this.$cookie.set('uid', uuid)
            this.$cookie.set('id', id)
            this.$cookie.set('roleId', roleId)
            this.$cookie.set('userName', userName)
            this.$cookie.set('internalName', internalName)
          })
          .catch((error) => this.errorAlert(error))
      }
    },

    agreePolicy() {
      axios({
        url: `${host.authUrl}/User/acceptAgreement`,
        method: 'POST',
        headers: {
          Authorization: `bearer ${this.$cookie.get('mandate')}`
        }
      })
        .then(() => {
          this.auth()
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },
    /**
     * Выход из учеткой записи текущего сервиса
     * @return {Promise<logout>}
     */
    logout() {
      this.$router.push('/login')
      this.$root.$emit('unsubscribeGeneral')
      return this.$store.dispatch('logout')
    },
    /**
     * Смена флага для отображения формы регистрации/входа
     * @return {boolean}
     */
    switchReg() {
      this.resetPassword = false
      this.isReg = !this.isReg
    },
    /**
     * Создание аккаунт с шифрование пароля в SHA256 для защиты отправки
     */
    createAccount() {
      // if (!this.isInvalidReg) {
      axios({
        url: `${host.authUrl}/User/signUp`,
        method: 'POST',
        data: [
          {
            role: this.role,
            email: this.email,
            password: CryptoJS.SHA256(CryptoJS.MD5(this.password).toString()).toString(),
            phone: this.phone,
            lastname: this.lastName,
            firstname: this.firstName,
            middlename: this.middleName,
            link_to_zoom: this.link,
            time_zone: this.timeZoneReg.value
          }
        ]
      })
        .then(() => {
          setTimeout(() => {
            this.auth()
          }, 300)
        })
        .catch((error) => this.errorAlert(error))
      // }
    },

    /**
     * Запрос на смену пароля
     */
    sendRequestResetPassword() {
      if (this.isEmailValid) {
        return null
      }
      axios({
        url: `${host.authUrl}/User/requestResetPassword`,
        method: 'POST',
        data: [
          {
            email: this.email
          }
        ]
      })
        .then(() => {
          this.resetPassword = false
          this.successAlert('На e-mail выслан запрос на подтверждение смены пароля')
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    changePassword() {
      if (
        this.passwordChange.length < 6 ||
        this.repeatPasswordChange.length < 6 ||
        this.passwordChange !== this.repeatPasswordChange
      ) {
        return null
      }
      axios({
        url: `${host.authUrl}/User/resetPassword`,
        method: 'POST',
        data: [
          {
            password: CryptoJS.SHA256(CryptoJS.MD5(this.passwordChange).toString()).toString(),
            token: this.token
          }
        ]
      })
        .then(() => {
          this.resetPassword = false
          this.isReg = false
          this.successAlert('Пароль успешно изменен!')
          this.$router.push('/login')
          this.token = null
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    }
  },
  computed: {
    /**
     * Блокировки кнопки без заполнения данных (авторизация)
     * @return {boolean}
     */
    isInvalid() {
      return !(this.email && this.password)
    },
    /**
     * Блокировки кнопки без заполнения данных (регистрация)
     * @return {boolean}
     */
    isInvalidReg() {
      return !(this.email && this.password && this.name)
    },
    /**
     * Текст для футтера формы
     * @return {string}
     * @constructor
     */
    FooterText() {
      return this.isReg ? 'Уже зарегистрированы на сайте?' : 'Еще не зарегистрированы?'
    },
    /**
     * Текст для кнопка в футтере формы
     * @return {string}
     * @constructor
     */
    ButtonText() {
      return this.isReg ? 'Войти' : 'Зарегистрироваться'
    }
  }
}
