import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import NotFound from '@/views/NotFound'
import globals from '@/globals'
import store from '@/store/index.js'
// Layouts
import Layout1 from '@/layout/Layout1'
import LayoutAuth from '@/layout/LayoutAuth'
import GeneratedViews from '../views/GeneratedViews'
import { dynamicRoute, startRoutePage } from '../globalVariables'
import Schedule from '@/views/Schedule/Schedule'
import Profile from '@/views/Profile/Profile'
import LessonCard from '@/views/LessonCard/LessonCard'
import UserControl from '@/views/UserControl/UserControl'
import LessonGlossary from '@/views/LessonCard/LessonGlossary'
import Notifications from '@/views/Notifications/Notifications'
import Chat from '@/views/Chat/Chat'
import PacksContainer from '@/views/Packs/PacksContainer'
import StudentReport from '@/views/StudentReport/StudentReport'
import SalaryReport from '@/views/SalaryReport/SalaryReport'
import UserWageRate from '@/views/UserWageRate/UserWageRate'
import StudentRefundRequest from '@/views/StudentRefundRequest/StudentRefundRequest'
import Report from '@/views/Report/Report'
import PayoutHistory from '@/views/PayoutHistory/PayoutHistory'
import Arbitration from '@/views/Arbitration/Arbitration'

Vue.use(Router)
Vue.use(Meta)

const router = new Router({
  base: '/',
  mode: 'history',
  routes: [
    {
      path: '/login',
      component: LayoutAuth
    },
    {
      path: '/register',
      component: LayoutAuth
    },
    {
      path: '/',
      component: Layout1,
      meta: { title: 'Layout1', requiresAuth: true },
      redirect: `${startRoutePage}`,
      children: [
        {
          path: `:prefix/${dynamicRoute}/:model`,
          component: GeneratedViews,
          meta: { title: 'Генерируемая страница', requiresAuth: true }
        },
        {
          path: ':prefix/Schedule',
          component: Schedule,
          meta: { title: 'Рабочий Календарь', requiresAuth: true }
        },
        {
          path: ':prefix/Profile',
          component: Profile,
          name: 'Profile',
          meta: { title: 'Личный кабинет', requiresAuth: true }
        },
        {
          path: ':prefix/LessonCard/:lesson_id',
          component: LessonCard,
          name: 'LessonCard',
          meta: { title: 'Карточка занятия', requiresAuth: true }
        },
        {
          path: ':prefix/UserControl/:user_id',
          component: UserControl,
          name: 'UserControl',
          meta: { title: 'Карточка пользователя', requiresAuth: true }
        },
        {
          path: ':prefix/Dictionary',
          component: LessonGlossary,
          name: 'LessonGlossary',
          meta: { title: 'Словарь', requiresAuth: true }
        },
        {
          path: ':prefix/Notifications',
          component: Notifications,
          name: 'Notifications',
          meta: { title: 'Оповещения', requiresAuth: true }
        },
        {
          path: ':prefix/Chat',
          component: Chat,
          name: 'Chat',
          meta: { title: 'Чат', requiresAuth: true }
        },
        {
          path: ':prefix/LessonPack',
          component: PacksContainer,
          name: 'PacksContainer',
          meta: { title: 'Пакеты', requiresAuth: true }
        },
        {
          path: ':prefix/StudentTransactionHistoryViewForStudent',
          component: StudentReport,
          name: 'StudentReport',
          meta: { title: 'Отчет', requiresAuth: true }
        },
        {
          path: ':prefix/LessonTransactionHistoryView',
          component: SalaryReport,
          name: 'SalaryReport',
          meta: { title: 'Отчет', requiresAuth: true }
        },
        {
          path: ':prefix/UserWageRate',
          component: UserWageRate,
          name: 'UserWageRate',
          meta: { title: 'Ставки', requiresAuth: true }
        },
        {
          path: ':prefix/StudentRefundRequest',
          component: StudentRefundRequest,
          name: 'StudentRefundRequest',
          meta: { title: 'Заявки на возврат', requiresAuth: true }
        },
        {
          path: ':prefix/Report',
          component: Report,
          name: 'Report',
          meta: { title: 'Отчет', requiresAuth: true }
        },
        {
          path: ':prefix/PayoutHistory',
          component: PayoutHistory,
          name: 'PayoutHistory',
          meta: { title: 'Зарплатные ведомости', requiresAuth: true }
        },
        {
          path: ':prefix/Arbitration',
          component: Arbitration,
          name: 'Arbitration',
          meta: { title: 'Арбитраж', requiresAuth: true }
        }
      ]
    },
    {
      // 404 Not Found page
      path: '*',
      component: NotFound
    }
  ]
})

/**
 * Перебор текущих роутов с флагом 'meta: {requiresAuth: true} ' с проверкой на авторизацию пользователя
 */
router.beforeEach((to, from, next) => {
  to.matched.some((record) => record.meta.requiresAuth) && !store.getters.isLoggedIn
    ? next('/login')
    : next()
  if (to.path === '/login' && store.getters.isLoggedIn) {
    next(from.path)
  }
  if (to.path === '/register' && store.getters.isLoggedIn) {
    next(from.path)
  }
})

router.afterEach(() => {
  // On small screens collapse sidenav
  if (
    /* eslint-disable */
    window.layoutHelpers &&
    window.layoutHelpers.isSmallScreen() &&
    !window.layoutHelpers.isCollapsed()
  ) {
    setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10)
  }

  // Scroll to top of the page
  globals().scrollTop(0, 0)
})

router.beforeEach((to, from, next) => {
  // Set loading state
  document.body.classList.add('app-loading')

  // Add tiny timeout to finish page transition
  setTimeout(() => next(), 10)
})

export default router
