<template>
  <div class="help-window" id="chat">
    <div class="help-window__header">
      <span>Help chat LinguaTech</span>
      <img @click="$emit('hide')" src="../../../assets/img/ES/mobile_edit_cross.svg" alt="close" />
    </div>
    <div class="help-window__content" id="helpChat">
      <div class="help-window__content-none" v-if="!messages.length">
        <span>Напишите свой вопрос и я постараюсь Вам помочь</span>
      </div>
      <div class="help-window__content-messages" v-else>
        <div class="message-dates">
          <div class="date" v-for="(group, index) in groupedMessages" :key="index">
            <div class="date-label">{{ group.date }}</div>
            <div class="date-messages">
              <div
                class="date-messages__item"
                v-for="message in group.messages"
                :key="message.message_id"
              >
                <Message
                  :type="'participant'"
                  :message="message"
                  @editMessage="setEditMessage"
                  @deleteMessage="deleteMessage"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="help-window__input">
      <InputMessage
        @sendMessage="sendMessage"
        :editedMessage="editedMessage"
        :editedFile="editedFile"
        @cancelEditingMessage="cancelEditingMessage"
        @editMessage="editMessage"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { domain, MicroServices } from '@/globalVariables'
import InputMessage from '@/views/Chat/HelpChat/components/InputMessage'
import Message from '@/views/Chat/MessagesContainer/components/Message'
import Swal from 'sweetalert2'

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'confirm-button',
    cancelButton: 'delete-button',
    title: 'title-class',
    input: 'input-class'
  },
  buttonsStyling: false
})
export default {
  name: 'HelpWindow',
  components: { InputMessage, Message },
  data() {
    return {
      messages: [],
      groupedMessages: [],
      chatId: null,
      editedMessage: null,
      editedFile: null,
      editedMessageId: null,
      count: 5,
      from: 1
    }
  },
  mounted() {
    this.getChatId()
    this.$root.$on('getNewMessage', () => {
      this.getMessages(null, false, true)
    })
  },
  beforeDestroy() {
    this.$root.$off('getNewMessage')
  },
  methods: {
    /**
     * Получить сообщения
     * @param $state
     * @param pagination
     * @param update
     */
    getMessages($state, pagination, update) {
      if (pagination) {
        this.from += 5
      } else {
        this.from = 1
      }
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Message/getItems`,
        method: 'GET',
        params: {
          chat_id: this.chatId,
          _count: this.count,
          _from: this.from
        }
      })
        .then((response) => {
          if (update) {
            this.messages = []
            this.groupedMessages = []
          }
          if (!response.data.data) {
            return null
          }
          this.messages = response.data.data.reverse()
          this.groupedMessages = this.groupMessages(this.messages)
          if (!pagination) {
            setTimeout(() => {
              let container = document.getElementById('helpChat')
              container.scrollTop = container.scrollHeight
            }, 100)
            this.getChatStatus()
          }
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },
    /**
     * Получить id диалога
     */
    getChatId() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Chat/createChatWithManager`,
        method: 'GET'
      })
        .then((response) => {
          this.chatId = response.data.data.id
          this.messages = []
          this.groupedMessages = []
          if (this.chatId) {
            this.getMessages()
          }
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },
    /**
     * Получить статус диалога (прочитано/непрочитано)
     */
    getChatStatus() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Chat/getItem`,
        method: 'GET',
        params: {
          chat_id: this.chatId
        }
      })
        .then((response) => {
          this.setReadChat(response.data.data[0].has_unread_messages)
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Установить чат как прочитанный
     * @param update
     * @returns {null}
     */
    setReadChat(update) {
      if (!update) {
        return null
      }
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Chat/setChatRead`,
        method: 'POST',
        data: [
          {
            chat_id: this.chatId
          }
        ]
      }).catch((error) => {
        this.errorAlert(error)
      })
    },

    /**
     * Группировка сообщений по дате
     * @param data
     * @returns {*[]}
     */
    groupMessages(data) {
      let dates = []
      data.forEach((item) => {
        if (!dates.filter((date) => date === item.date_group).length) {
          dates.push(item.date_group)
        }
      })

      let groupedMessages = []
      dates.forEach((item, index) => {
        groupedMessages.push({
          date: item,
          messages: null
        })
        groupedMessages[index].messages = data.filter((msg) => msg.date_group === item)
      })
      return groupedMessages
    },

    /**
     * Отправить сообщение
     * @param message
     * @param fileId
     */
    sendMessage(message, fileId) {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Message/create`,
        method: 'POST',
        data: [
          {
            content: message,
            chat_id: this.chatId
          }
        ]
      })
        .then((response) => {
          if (fileId) {
            this.setFileToMessage(response.data.data.id, fileId)
            return null
          }
          this.$root.$emit('messageSuccessfullySend')
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Сопоставить фалу сообщение
     * @param messageId
     * @param fileId
     */
    setFileToMessage(messageId, fileId) {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/MessageFile/create`,
        method: 'POST',
        data: [
          {
            message_id: messageId,
            file_id: fileId
          }
        ]
      })
        .then(() => {
          this.cancelEditingMessage()
          this.$root.$emit('messageSuccessfullySend')
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Установить редактирование сообщению
     * @param content
     * @param id
     * @param file
     */
    setEditMessage(content, id, file) {
      this.editedMessage = content
      this.editedMessageId = id
      if (file) {
        this.editedFile = {
          file: {
            extension: file.extension,
            name: file.name,
            id: file.id
          },
          file_url: file.path
        }
      }
    },

    /**
     * Отменить редактирование
     */
    cancelEditingMessage() {
      this.editedMessage = null
      this.editedMessageId = null
      this.editedFile = null
    },

    /**
     * Удалить сообщение
     * @param id
     * @param canDelete
     */
    deleteMessage(id, canDelete) {
      swalWithBootstrapButtons
        .fire({
          text: `Вы уверены, что хотите удалить сообщение?`,
          input: canDelete ? 'checkbox' : null,
          inputPlaceholder: 'Удалить для всех',
          footer: !canDelete ? 'Сообщение не будет удалено у собеседника' : null,
          showCancelButton: true,
          confirmButtonText: 'Да',
          cancelButtonText: 'Отмена',
          reverseButtons: true
        })
        .then((r) => {
          if (r.isConfirmed) {
            axios({
              url: `${domain}/${MicroServices[0].prefix}/Message/deleteMessage/${id}`,
              method: 'DELETE',
              data: [
                {
                  is_deleted_for_all: canDelete && Boolean(r.value)
                }
              ]
            })
              .then(() => {
                this.successAlert('Сообщение удалено!')
                this.getMessages(this.chatId, this.title, true)
              })
              .catch((error) => this.errorAlert(error))
          }
        })
    },

    /**
     * Редактировать сообщение
     * @param content
     * @param fileId
     */
    editMessage(content, fileId) {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Message/update/${this.editedMessageId}`,
        method: 'PUT',
        data: [
          {
            content: content
          }
        ]
      })
        .then(() => {
          if (fileId) {
            this.setFileToMessage(this.editedMessageId, fileId)
            return null
          }
          this.cancelEditingMessage()
          this.$root.$emit('messageSuccessfullySend')
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../styleVariables';

.help-window {
  background-color: $main-white;
  box-shadow: 1px 0 20px -7px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  width: 282px;
  min-height: 372px;
  max-height: 620px;
  display: grid;
  grid-template-rows: 32px 1fr auto;
  grid-row-gap: 12px;
  &__header {
    border-bottom: 1px solid #edf3fa;
    padding: 7px;
    display: flex;
    justify-content: center;
    span {
      color: $chat-blue;
      font-size: 14px;
      line-height: 18px;
    }
    img {
      display: none;
    }
  }
  &__content {
    height: 100%;
    overflow-y: auto;
    &-none {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      span {
        color: $main-gray;
        font-size: 12px;
        line-height: 15px;
        width: 143px;
        text-align: center;
      }
    }
    &-messages {
      padding: 0 11px;
      .message-dates {
        .date {
          &-label {
            margin: 0 auto 9px auto;
            width: fit-content;
            font-family: $font-regular;
            font-size: 12px;
            line-height: 18px;
            color: $main-gray;
            position: sticky;
            top: 0;
            background-color: $main-white;
            padding: 2px 6px;
            border-radius: 50px;
          }
          /deep/ .content {
            font-size: 14px !important;
          }
          /deep/ .message-header {
            font-size: 12px;
          }
        }
      }
    }
  }
  &__input {
    border-top: 1px solid #edf3fa;
  }
}

@media screen and (max-width: 480px) {
  .help-window {
    border-radius: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
    grid-template-rows: 56px 1fr auto;
    &__header {
      background-color: $bright-blue;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      span {
        color: $main-white;
      }
      img {
        display: block;
      }
    }
    &__input {
      margin-bottom: 30px;
    }
  }
}
</style>
