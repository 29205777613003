<template>
  <div class="chat-container">
    <SideBarChat
      @chooseChat="getMessages"
      @createChat="createChat"
      :chatId="chatId"
      :dialogues="dialogues"
      @getDialogues="getDialogues"
      :users="users"
    />
    <MessagesContainer
      :title="title"
      :messages="groupedMessages"
      @sendMessage="sendMessage"
      :type="currentType"
      :showInputZone="showInputZone"
      @editMessage="editMessage"
      @deleteMessage="deleteMessage"
      @getMoreMessages="(state) => getMessages(chatId, title, false, true, state)"
    />
  </div>
</template>

<script>
import SideBarChat from './SideBar/SideBarChat'
import MessagesContainer from './MessagesContainer/MessagesContainer'
import axios from 'axios'
import { domain, MicroServices } from '@/globalVariables'
import Swal from 'sweetalert2'

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'confirm-button',
    cancelButton: 'delete-button',
    title: 'title-class',
    input: 'input-class'
  },
  buttonsStyling: false
})
export default {
  name: 'Chat',
  components: { MessagesContainer, SideBarChat },
  data() {
    return {
      title: null,
      currentType: null,
      type: null,
      showInputZone: true,
      chatId: null,
      messages: [],
      groupedMessages: [],
      token: null,
      channel: null,
      centrifugeChat: null,
      dialogues: [],
      users: null,
      count: 50,
      from: 1
    }
  },
  mounted() {
    this.$root.$on('getNewMessage', (message) => {
      // Если пришло соообщение в текущем открытом чате
      if (message.data.chat_id === this.chatId) {
        this.getMessages(this.chatId, this.title, true)
      } else {
        this.getDialogues()
      }
    })
    this.getDialogues()
  },
  beforeDestroy() {
    this.$root.$off('getNewMessage')
  },
  methods: {
    /**
     * Получение диалогов
     * @param type
     * @param filters
     * @param search
     * @param setRead
     */
    getDialogues(type, filters, search, setRead) {
      if (!type) {
        type = this.type || 'participant'
      }
      this.type = type
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Chat/getItems`,
        method: 'GET',
        params: {
          member_role: type,
          roles: filters && JSON.stringify(filters.role),
          has_unread_messages: filters && filters.status[0],
          name: search
        }
      })
        .then((response) => {
          this.dialogues = response.data.data
          if ((search || (filters && filters.role.length)) && type === 'participant') {
            this.getUsersForDialogue(search, filters)
          } else {
            this.users = null
          }
          if (setRead) {
            if (this.dialogues.find((item) => item.chat_id === this.chatId).has_unread_messages) {
              this.setReadChat(this.chatId)
            }
          }
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Получить доступных ползователей, с которыми можно начать диалог
     * @param search
     * @param filters
     */
    getUsersForDialogue(search, filters) {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Chat/getAvailableUsersForChat`,
        method: 'POST',
        params: {
          full_name: search,
          roles: filters && JSON.stringify(filters.role)
        }
      })
        .then((response) => {
          this.users = response.data.data
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Начать новый диалог
     * @param uuid
     * @param title
     */
    createChat(uuid, title) {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Chat/create`,
        method: 'POST',
        data: [
          {
            user_uuid: uuid
          }
        ]
      })
        .then((response) => {
          this.getMessages(response.data.data.id, title)
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Получить сообщения диалога
     * @param id
     * @param title
     * @param update
     * @param pagination
     */
    getMessages(id, title, update, pagination) {
      this.currentType = this.type
      if (!id) {
        return null
      }

      // Если кликнули на тот же чат что и был открыт
      if (this.chatId === id && !pagination && !update) {
        setTimeout(() => {
          let container = document.getElementById('chat')
          container.scrollTop = container.scrollHeight
        })
        return null
      }

      // Если открыли новый диалог
      if (this.chatId !== id) {
        this.from = 1
        this.messages = []
        this.groupedMessages = []
        this.showInputZone = this.type === 'participant'
        setTimeout(() => {
          let container = document.getElementById('chat')
          container.scrollTop = container.scrollHeight
        }, 200)
      }

      // Если обновили текущий, но не через пагинацию
      if (update && !pagination) {
        this.from = 1
        let container = document.getElementById('chat')
        setTimeout(() => {
          container.scrollTop = container.scrollHeight
        }, 150)
      }

      // Если получаем сообщения по пагинации
      if (pagination) {
        this.from += this.count
      }
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Message/getItems`,
        method: 'GET',
        params: {
          chat_id: id,
          _count: this.count,
          _from: this.from
        }
      })
        .then((response) => {
          this.title = title
          this.chatId = id
          if (!pagination && update) {
            this.messages = []
            this.groupedMessages = []
          }
          this.messages = response.data.data.reverse()
          this.groupedMessages = this.groupMessages(this.messages)
          if (!pagination) {
            setTimeout(() => {
              let container = document.getElementById('chat')
              container.scrollTop = container.scrollHeight
            })
            this.getDialogues(this.type, null, null, id === this.chatId)
          }
        })
        .catch((error) => this.errorAlert(error))
    },

    /**
     * Установить диалог как прочитанный
     * @param id
     */
    setReadChat(id) {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Chat/setChatRead`,
        method: 'POST',
        data: [
          {
            chat_id: id
          }
        ]
      })
        .then(() => {
          this.dialogues.find((item) => item.chat_id === id).has_unread_messages = false
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Сгрупировать сообщения по дате
     * @param data
     */
    groupMessages(data) {
      let dates = []
      data.forEach((item) => {
        if (!dates.filter((date) => date === item.date_group).length) {
          dates.push(item.date_group)
        }
      })

      let groupedMessages = []
      dates.forEach((item, index) => {
        groupedMessages.push({
          date: item,
          messages: null
        })
        groupedMessages[index].messages = data.filter((msg) => msg.date_group === item)
      })
      return groupedMessages
    },

    /**
     * Отправить сообщение
     * @param message
     * @param fileId
     */
    sendMessage(message, fileId) {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Message/create`,
        method: 'POST',
        data: [
          {
            content: message,
            chat_id: this.chatId
          }
        ]
      })
        .then((response) => {
          if (fileId) {
            this.setFileToMessage(response.data.data.id, fileId)
            return null
          }
          this.$root.$emit('messageSuccessfullySend')
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Редактирование сообщения
     * @param content
     * @param id
     * @param fileId
     */
    editMessage(content, id, fileId) {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Message/update/${id}`,
        method: 'PUT',
        data: [
          {
            content: content
          }
        ]
      })
        .then(() => {
          if (fileId) {
            this.setFileToMessage(id, fileId)
            return null
          }
          this.$root.$emit('messageSuccessfullySend')
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Удаление сообщения
     * @param id
     * @param canDelete
     */
    deleteMessage(id, canDelete) {
      swalWithBootstrapButtons
        .fire({
          text: `Вы уверены, что хотите удалить сообщение?`,
          input: canDelete ? 'checkbox' : null,
          inputPlaceholder: 'Удалить для всех',
          footer: !canDelete ? 'Сообщение не будет удалено у собеседника' : null,
          showCancelButton: true,
          confirmButtonText: 'Да',
          cancelButtonText: 'Отмена',
          reverseButtons: true
        })
        .then((r) => {
          if (r.isConfirmed) {
            axios({
              url: `${domain}/${MicroServices[0].prefix}/Message/deleteMessage/${id}`,
              method: 'DELETE',
              data: [
                {
                  is_deleted_for_all: canDelete && Boolean(r.value)
                }
              ]
            })
              .then(() => {
                this.successAlert('Сообщение удалено!')
                this.getMessages(this.chatId, this.title, true)
              })
              .catch((error) => this.errorAlert(error))
          }
        })
    },

    /**
     * Сопоставление файла сообщению
     * @param messageId
     * @param fileId
     */
    setFileToMessage(messageId, fileId) {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/MessageFile/create`,
        method: 'POST',
        data: [
          {
            message_id: messageId,
            file_id: fileId
          }
        ]
      })
        .then(() => {
          this.$root.$emit('messageSuccessfullySend')
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../styleVariables';

.chat-container {
  box-shadow: 1px 0px 20px -7px rgba(0, 0, 0, 0.16);
  margin: 24px 32px;
  overflow-y: auto;
  //height: 100%;
  //height: 90vh;
  display: flex;
  min-height: 420px;
}
</style>
