export default {
  methods: {
    dateRange(data) {
      this.$store.commit('newRec', data)
    }
  },
  data() {
    return {}
  },
  watch: {}
}
