<template>
  <div class="statistic">
    <div class="statistic-filter">
      <StatisticFilter @filter="filterStatistic" />
    </div>
    <div class="statistic__item">
      <Donut
        header="Статистика по студентам"
        :sections="studentSections"
        :links="studentLinks"
        :centerValue="studentCenterValue"
        :thickness="15"
      />
    </div>
    <div class="statistic__item">
      <Donut
        header="Статистика по занятиям"
        :sections="lessonSections"
        :links="lessonLinks"
        :centerValue="lessonCenterValue"
        :thickness="15"
      />
    </div>
    <div class="statistic__item">
      <Donut
        header="Статистика по жалобам"
        :sections="complaintSections"
        :links="complaintLinks"
        :centerValue="complaintCenterValue"
        :thickness="15"
      />
    </div>
    <div class="statistic__item"></div>
    <div class="statistic__item"></div>
  </div>
</template>

<script>
import Donut from '@/views/Profile/components/Statistic/Donut'

import axios from 'axios'
import { domain, MicroServices } from '@/globalVariables'
import scriptMixins from '@/components/scriptMixins/scriptMixins'
import StatisticFilter from '@/views/Profile/components/Statistic/StatisticFilter'

export default {
  name: 'Statistic',
  components: { StatisticFilter, Donut },
  mixins: [scriptMixins],
  data() {
    return {
      colors: {
        green: '#45C661',
        blue: '#567ECA',
        yellow: '#FAD82E',
        red: '#F66A2E',
        orange: '#FA9862',
        violet: '#CD6EDC'
      },
      adminUid: '00000000-0000-0000-0000-000000000000',
      studentSections: [],
      studentLinks: [
        {
          name: 'Активные студенты',
          color: '#1DBE40'
        },
        {
          name: 'Заблокированные студенты',
          color: '#E13429'
        },
        {
          name: 'Студенты, не покупавшие пакет 1 -  6 месяцев',
          color: '#FFD500'
        },
        {
          name: 'Студенты, не покупавшие пакет > 6 месяцев',
          color: '#FF8541'
        }
      ],
      studentCenterValue: null,
      complaintCenterValue: null,
      complaintSections: [],
      complaintLinks: [
        {
          name: 'Одобренные жалобы',
          color: '#3264C3'
        },
        {
          name: 'Отклоненные жалобы',
          color: '#1DBE40'
        },
        {
          name: 'Жалобы на рассмотрении',
          color: '#FFD500'
        }
      ],
      lessonCenterValue: null,
      lessonSections: [],
      lessonLinks: [
        {
          name: 'Назначенные занятия',
          color: '#3264C3'
        },
        {
          name: 'Состоявшиеся занятия',
          color: '#1DBE40'
        },
        {
          name: 'Отмененные занятия',
          color: '#E13429'
        },
        {
          name: 'Занятия, пропущенные студентом',
          color: '#FFD500'
        },
        {
          name: 'Занятия, пропущенные студентом по УП',
          color: '#FF8541'
        },
        {
          name: 'Занятия, пропущенные учителем',
          color: '#C049D4'
        }
      ],
      startDate: null,
      endDate: null
    }
  },
  mounted() {
    this.setDefaultDate()
    this.getStudentStatistic()
    this.getComplaintStatistic()
    this.getLessonStatistic()
  },
  methods: {
    /**
     * Установка начального значения фильтра по датам (на сегодня)
     */
    setDefaultDate() {
      let today = this.countUserTimeByTimeZone(this.$cookie.get('userTimeZone'))
      this.startDate = `${new Date(today).getFullYear()}-${
        new Date(today).getMonth() + 1 < 10
          ? '0' + (new Date(today).getMonth() + 1)
          : new Date(today).getMonth() + 1
      }-${
        new Date(today).getDate() < 10 ? '0' + new Date(today).getDate() : new Date(today).getDate()
      }`
      this.endDate = this.startDate
    },

    /**
     * Перезапрашивает данные с учетом фильтра
     * @param start
     * @param end
     */
    filterStatistic(start, end) {
      this.getStudentStatistic(start, end)
      this.getLessonStatistic(start, end)
      this.getComplaintStatistic(start, end)
      this.$root.$emit('filterAdminStatistic', start, end)
    },

    /**
     * Получение статистики по студентам
     * @param start
     * @param end
     */
    getStudentStatistic(start, end) {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/StudentStatistic/getItems`,
        method: 'GET',
        params: {
          manager_uuid:
            this.$cookie.get('internalName') === 'manager'
              ? this.$cookie.get('uid')
              : this.adminUid,
          date: end || this.endDate
        }
      })
        .then((response) => {
          this.studentSections = []
          let data = response.data.data.items[0]
          if (data) {
            this.studentCenterValue = `${data.amount_student} ${this.endingOfWordStudent(
              data.amount_student
            )} / ${data.amount_sign_up_student} зарегистрированных`
            this.groupStudentSections(data)
            return null
          }
          this.studentCenterValue = null
          this.studentLinks[0].value = null
          this.studentLinks[1].value = null
          this.studentLinks[2].value = null
          this.studentLinks[3].value = null
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Получение статистики по жалобам
     * @param start
     * @param end
     */
    getComplaintStatistic(start, end) {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/ComplaintsStatistic/getItems`,
        method: 'GET',
        params: {
          manager_uuid:
            this.$cookie.get('internalName') === 'manager'
              ? this.$cookie.get('uid')
              : this.adminUid,
          begin_date: start || this.startDate,
          end_date: end || this.endDate
        }
      })
        .then((response) => {
          let data = response.data.data[0]
          this.complaintCenterValue = `${data.amount_total} ${this.endingOfWordComplaint(
            data.amount_total
          )}`
          this.complaintSections = []
          this.groupComplaintSections(data)
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Получение статистики по занятиям
     * @param start
     * @param end
     */
    getLessonStatistic(start, end) {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/LessonStatistic/getItems`,
        method: 'GET',
        params: {
          manager_uuid:
            this.$cookie.get('internalName') === 'manager'
              ? this.$cookie.get('uid')
              : this.adminUid,
          begin_date: start || this.startDate,
          end_date: end || this.endDate
        }
      })
        .then((response) => {
          let data = response.data.data[0]
          this.lessonSections = []
          this.groupLessonSections(data)
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Получение секций и надписей для легенды ля статистики по студентам
     * @param data
     * @returns {null}
     */
    groupStudentSections(data) {
      delete data.amount_student
      delete data.amount_sign_up_student
      delete data.id
      delete data.date
      delete data.manager_uuid

      let values = Object.values(data)
      let sum = 0
      values.forEach((item) => {
        sum += item
      })

      this.studentLinks[0].value = data.amount_active_student
      this.studentLinks[1].value = data.amount_blocked_student
      this.studentLinks[2].value = data.amount_one_six_month_student
      this.studentLinks[3].value = data.amount_inactive_student

      if (!sum) {
        return null
      }
      this.studentSections.push({
        value: Number(((100 * data.amount_active_student) / sum).toFixed(10)),
        color: this.colors.green
      })
      this.studentSections.push({
        value: Number(((100 * data.amount_blocked_student) / sum).toFixed(10)),
        color: this.colors.red
      })
      this.studentSections.push({
        value: Number(((100 * data.amount_inactive_student) / sum).toFixed(10)),
        color: this.colors.orange
      })
      this.studentSections.push({
        value: Number(((100 * data.amount_one_six_month_student) / sum).toFixed(10)),
        color: this.colors.yellow
      })
    },

    /**
     * Получение секций и надписей для легенды для статистики по жалобам
     * @param data
     * @returns {null}
     */
    groupComplaintSections(data) {
      delete data.amount_total
      delete data.date

      let values = Object.values(data)
      let sum = 0
      values.forEach((item) => {
        sum += item
      })

      this.complaintLinks[0].value = data.amount_accepted
      this.complaintLinks[1].value = data.amount_rejected
      this.complaintLinks[2].value = data.amount_review

      if (!sum) {
        return null
      }
      this.complaintSections.push({
        value: Number(((100 * data.amount_rejected) / sum).toFixed(10)),
        color: this.colors.green
      })
      this.complaintSections.push({
        value: Number(((100 * data.amount_accepted) / sum).toFixed(10)),
        color: this.colors.blue
      })
      this.complaintSections.push({
        value: Number(((100 * data.amount_review) / sum).toFixed(10)),
        color: this.colors.yellow
      })
    },

    /**
     * Получение секций и надписей для легенды для статистики по занятиям
     * @param data
     * @returns {null}
     */
    groupLessonSections(data) {
      delete data.date

      let values = Object.values(data)
      let sum = 0
      values.forEach((item) => {
        sum += item
      })

      this.lessonCenterValue = `${sum} ${this.endingOfWord(sum)}`

      this.lessonLinks[0].value = data.amount_planned
      this.lessonLinks[1].value = data.amount_ended
      this.lessonLinks[2].value = data.amount_cancelled
      this.lessonLinks[3].value = data.amount_skipped_student
      this.lessonLinks[4].value = data.amount_skipped_student_with_reason
      this.lessonLinks[5].value = data.amount_skipped_teacher

      if (!sum) {
        return null
      }

      this.lessonSections.push({
        value: Number(((100 * data.amount_planned) / sum).toFixed(10)),
        color: this.colors.blue
      })
      this.lessonSections.push({
        value: Number(((100 * data.amount_ended) / sum).toFixed(10)),
        color: this.colors.green
      })
      this.lessonSections.push({
        value: Number(((100 * data.amount_cancelled) / sum).toFixed(10)),
        color: this.colors.red
      })
      this.lessonSections.push({
        value: Number(((100 * data.amount_skipped_student) / sum).toFixed(10)),
        color: this.colors.yellow
      })
      this.lessonSections.push({
        value: Number(((100 * data.amount_skipped_student_with_reason) / sum).toFixed(10)),
        color: this.colors.orange
      })
      this.lessonSections.push({
        value: Number(((100 * data.amount_skipped_teacher) / sum).toFixed(10)),
        color: this.colors.violet
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../../styleVariables';

.statistic {
  background-color: $main-white;
  box-shadow: 1px 0px 20px -7px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  padding: 14px 24px;
  &-filter {
    margin-bottom: 24px;
  }
  &__item {
    margin-bottom: 46px;
  }
}
</style>
