var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-background",style:(_vm.dayId === 1 ? 'left: 0' : '')},[_c('div',{staticClass:"info-modal-container"},[_c('div',{staticClass:"info-modal-header"},[(
          _vm.modalInfo.kls_type_code !== 'TEACHER_TEST' &&
          _vm.modalInfo.kls_type_code !== 'TEACHER_FIRST_TEST'
        )?_c('span',[_vm._v("Занятие "+_vm._s(_vm.modalInfo.sequence_number))]):_c('span',[_vm._v(_vm._s(_vm.modalInfo.kls_type_name))]),_vm._v(" "),_c('button',{on:{"click":function($event){$event.stopPropagation();return _vm.$emit('hideInfoModal')}}},[_c('img',{attrs:{"src":require("../../../../assets/img/icons/close_icon.svg")}})])]),_vm._v(" "),_c('div',{staticClass:"info-modal-schedule"},[_c('div',{staticClass:"info-modal-date"},[_c('img',{attrs:{"src":require("../../../../assets/img/icons/calendar-dark.svg")}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.formatDate(_vm.modalInfo.date)))])]),_vm._v(" "),_c('div',{staticClass:"info-modal-time"},[_c('img',{attrs:{"src":require("../../../../assets/img/icons/alarm.svg")}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.formatTime(_vm.modalInfo.hour)))])])]),_vm._v(" "),_c('div',{staticClass:"info-modal-lesson"},[_c('div',{staticClass:"lesson-status"},[_c('span',[_vm._v("Status")]),_vm._v(" "),_c('div',{class:{
            lessonFinished: _vm.modalInfo.kls_status_code === 'LESSON_ENDED',
            lessonPlanned: _vm.modalInfo.kls_status_code === 'LESSON_PLANNED',
            lessonMissedByStudent: _vm.modalInfo.kls_status_code === 'SKIPPED_STUDENT',
            lessonMissedByStudentWithReason:
              _vm.modalInfo.kls_status_code === 'SKIPPED_STUDENT_WITH_REASON',
            lessonMissedByTeacher: _vm.modalInfo.kls_status_code === 'SKIPPED_TEACHER',
            lessonCanceled: _vm.modalInfo.kls_status_code === 'CANCELLED'
          }},[_vm._v("\n          "+_vm._s(_vm.modalInfo.kls_status_name)+"\n        ")])]),_vm._v(" "),(_vm.$cookie.get('internalName') !== 'student')?_c('div',{staticClass:"lesson-type"},[_c('span',[_vm._v("Type")]),_vm._v(" "),_c('div',{class:{
            regularLesson:
              _vm.modalInfo.kls_type_code === 'REGULAR' ||
              _vm.modalInfo.kls_type_code === 'TEACHER_TEST_REGULAR_LESSON',
            teacherTesting:
              _vm.modalInfo.kls_type_code === 'TEACHER_TEST' ||
              _vm.modalInfo.kls_type_code === 'TEACHER_FIRST_TEST',
            placementTest: _vm.modalInfo.kls_type_code === 'PLACEMENT_TEST',
            checkUpLesson: _vm.modalInfo.kls_type_code === 'CHECK_UP',
            progressTest:
              _vm.modalInfo.kls_type_code === 'CHECK_PROGRESS' ||
              _vm.modalInfo.kls_type_code === 'TEACHER_TEST_PROGRESS_TEST_LESSON'
          }},[_vm._v("\n          "+_vm._s(_vm.modalInfo.kls_type_name)+"\n        ")])]):_vm._e(),_vm._v(" "),(
          _vm.modalInfo.student_name &&
          ((_vm.$cookie.get('internalName') === 'teacher' &&
            _vm.modalInfo.kls_type_code !== 'TEACHER_TEST' &&
            _vm.modalInfo.kls_type_code !== 'TEACHER_FIRST_TEST') ||
            _vm.$cookie.get('internalName') === 'manager' ||
            _vm.$cookie.get('internalName') === 'admin' ||
            _vm.$cookie.get('internalName') === 'methodologist')
        )?_c('div',{staticClass:"teacher-name"},[(_vm.modalInfo.student_role)?_c('span',{staticClass:"name-label"},[_vm._v(_vm._s(_vm.modalInfo.student_role[0].toUpperCase() + _vm.modalInfo.student_role.slice(1))+"\n          name")]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.modalInfo.student_name))])]):_vm._e(),_vm._v(" "),(
          (_vm.$cookie.get('internalName') === 'manager' ||
            _vm.$cookie.get('internalName') === 'student' ||
            (_vm.$cookie.get('internalName') === 'teacher' &&
              (_vm.modalInfo.kls_type_code === 'TEACHER_TEST' ||
                _vm.modalInfo.kls_type_code === 'TEACHER_FIRST_TEST')) ||
            _vm.$cookie.get('internalName') === 'admin') &&
          _vm.modalInfo.teacher_name
        )?_c('div',{staticClass:"teacher-name"},[(_vm.modalInfo.teacher_role)?_c('span',{staticClass:"name-label"},[_vm._v(_vm._s(_vm.modalInfo.teacher_role[0].toUpperCase() + _vm.modalInfo.teacher_role.slice(1))+"\n          name")]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.modalInfo.teacher_name || ''))])]):_vm._e(),_vm._v(" "),(
          _vm.$cookie.get('internalName') !== 'student' &&
          !(
            _vm.$cookie.get('internalName') === 'teacher' &&
            (_vm.modalInfo.kls_type_code === 'TEACHER_TEST' ||
              _vm.modalInfo.kls_type_code === 'TEACHER_FIRST_TEST')
          )
        )?_c('div',{staticClass:"teacher-name"},[_c('span',{staticClass:"name-label"},[_vm._v("Ставка")]),_vm._v(" "),_c('div',{staticClass:"price-container"},[_c('div',{staticClass:"price-values"},[_c('span',{staticClass:"wage"},[_vm._v(_vm._s(_vm.modalInfo.wage_rate || 0))])])])]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"info-modal-more-btn"},[_c('button',{on:{"click":_vm.goToLessonCard}},[_vm._v("Подробнее")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }