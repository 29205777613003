<template>
  <div class="main-container">
    <div class="table-container" :class="{ 'wide-container': filterFields.length <= 2 }">
      <div id="table" :class="{ 'wide-table': filterFields.length <= 2 }">
        <div v-if="isLoadEndpoints" class="table-header">
          <p class="nameTable" v-if="isLoadEndpoints">{{ nameTable }}</p>
          <div class="search-filters-widgets">
            <div class="widgets-filters">
              <b-form-row :key="index" v-for="(rec, index) in data" class="widget">
                <b-form-group :label="rec.label" class="col">
                  <div :key="field.key" v-for="field in fields" class="widget__components">
                    <Widget
                      :class="{ 'add-button': field.key === 'add' }"
                      :key="field.key"
                      :type="rec[field.key].type"
                      @$emits="$emits"
                      v-bind="renderBindWidget(field.key, data.fields)"
                      v-if="typeof rec[field.key] === 'object'"
                    />
                    <span :key="field.key" v-else> {{ field.key }} : {{ rec[field.key] }} </span>
                  </div>
                </b-form-group>
              </b-form-row>

              <div class="filter" :class="{ 'hide-filter': filterFields.length <= 2 }">
                <div class="filter-width">
                  <div v-if="filterFields.length > 2">
                    <div>
                      <div class="card" v-if="filterFields.length > 2">
                        <div class="card-body" :class="{ spinner: !filter }">
                          <form v-if="filter">
                            <b-form-row>
                              <!--                  eslint-disable -->
                              <b-form-group
                                :key="index"
                                v-for="(rec, index) in filterData"
                                v-if="renderWidgetRec(rec, filterData)"
                              >
                                <!--                  eslint-enabled -->
                                <div :label="rec.label || ''" class="col">
                                  <div
                                    :key="field.key"
                                    v-for="field in filterFields"
                                    :class="{
                                      filters:
                                        field.key !== 'search_btn' && field.key !== 'clear_btn'
                                    }"
                                    v-show="
                                      $cookie.get('internalName') !== 'methodologist' ||
                                      field.key === 'user_id' ||
                                      field.key === 'search_btn' ||
                                      field.key === 'clear_btn'
                                    "
                                  >
                                    <Widget
                                      :key="field.key"
                                      :type="rec[field.key].type"
                                      @$emits="$emits"
                                      v-bind="renderBindWidget(field.key, rec)"
                                      v-if="typeof rec[field.key] === 'object'"
                                    />
                                  </div>
                                </div>
                              </b-form-group>
                            </b-form-row>
                          </form>
                          <form v-else>
                            <div class="spinner-border text-dark" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--    end filter    -->
                </div>
              </div>
            </div>

            <div class="global-search" v-if="viewSearch">
              <img src="../assets/img/ES/gray_search.svg" alt="search" />
              <input
                id="globalSearch"
                placeholder="Поиск по таблице"
                @keyup.enter="globalSearch($event)"
                style="width: 100%"
              />
            </div>
          </div>
        </div>
        <Table
          :currentStore="store"
          :dataTable="tableData.total_count"
          :fieldsTable="tableFields"
          :head-variant="'light'"
          :items="tableData.items"
          @$emits="$emits"
          :viewGlobalSearch="viewSearch"
          :multiSelect="multiSelect"
        />
      </div>
    </div>
    <div class="swap-phone">
      <span>Переверните устройство в горизонтальное положение, чтобы просмотреть таблицу</span>
    </div>
    <div id="cover-spin" v-show="!loading"></div>
  </div>
</template>

<script>
import Table from '../components/Table/Table'
import eventDelegate from '../components/Core/KernelProject/eventDelegate'
import Widget from '../components/Core/Widget'
import renderWidget from '../components/Core/KernelProject/renderWidget'
import authEvents from '../components/Core/KernelProject/authEvents'
import viewsDefaultEvents from '../components/Core/KernelProject/viewsDefaultEvents'
import tableEvents from '../components/Core/KernelProject/tableEvents'
export default {
  name: 'GeneratedViews',
  components: {
    Widget,
    Table
  },
  mixins: [eventDelegate, renderWidget, authEvents, viewsDefaultEvents, tableEvents],
  data() {
    return {
      tableData: [],
      tableFields: [],
      store: null,
      route: null,
      viewSearch: null,
      multiSelect: null,
      loading: false,
      to: null,
      from: null
    }
  },
  mounted() {
    this.$root.$on('getFilterData', (item) => {
      this.getFilterData()
    })
    // filter need
    this.$root.$on('filterSearch', (data) => {
      this.getDataWithFilter()
    })
    // filter clear
    this.$root.$on('filterClear', (data) => {
      let clear = true
      this.getDataPagination(clear)
      this.$root.$emit('getMetaData', true)
    })
    // filter clear
    this.$root.$on('switchFilter', (data) => {
      this.switchFilter()
    })
    // filter spinner => true
    this.$root.$on('filterSpinner', (data) => {
      setTimeout(() => {
        this.filter = true
      }, 500)
    })
  },
  methods: {},
  watch: {}
}
</script>

<style scoped lang="scss">
@import '../styleVariables';

.swap-phone {
  display: none;
}

.main-container {
  font-family: $font-regular, sans-serif !important;
  background-color: $background-blue !important;
  .search-filters-widgets {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
  }
  .widgets-filters {
    display: flex;
    .add-button {
      /deep/ button.fill {
        background-color: $bright-blue;
        width: 42px !important;
        height: 42px;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
        border-radius: 43px;
        &:active {
          background-color: $hover-bright-blue;
        }
      }
    }
  }
  .card-header {
    padding-bottom: 0 !important;
  }
  .card {
    background-color: unset !important;
    border: none !important;
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  .table-container {
    width: calc(100% - 48px);
    margin: 0 auto;
    overflow: hidden;
    height: 100%;
    padding-top: 24px;
  }
  .filter {
    //border-left: 1px solid $nav-lines;
    min-height: 100%;
    //background: $main-white;
    //z-index: 10;
    /deep/ .form-row {
      display: block;
      margin-left: 0 !important;
    }
    .filters {
      width: 220px;
    }
  }
  .wide-table {
    width: 100%;
    overflow: auto;
  }
  .hide-filter {
    display: none;
  }
  .wide-container {
    width: 100% !important;
  }
}
/deep/ .col {
  //padding-left: 0 !important;
  padding: 5px !important;
  display: flex;
  align-items: flex-end;
  .filters {
    margin-right: 20px;
  }
}
/deep/ table.b-table-selectable > tbody > tr.b-table-row-selected > td,
table.b-table-selectable > tbody > tr.b-table-row-selected > th {
  //background-color: $btn-hover-background !important;
}
/deep/ .table-row {
  overflow-wrap: break-word;
}
.loaderPage {
  text-align-last: center;
  margin-top: 20%;
}
.table-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
#cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 8;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#cover-spin::after {
  content: '';
  display: block;
  position: absolute;
  left: 48%;
  top: 40%;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: black;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
}

.nameTable {
  font-family: $font-bold;
  font-size: 24px;
  line-height: 30px;
  color: $main-dark;
  margin-bottom: 10px;
  margin-left: 10px;
  width: auto;
  display: inline;
}
/deep/#app-layout-navbar {
  display: block !important;
  font-size: 16px;
  line-height: 19px;
  margin: 24px 0 12px 24px;
  width: 50%;
}
.breadcrumbs {
  //color: $main-blue;
}
.collapse:not(.show) {
  display: block;
}
.form-row {
  //display: unset !important;
  margin: 0 0 0 6px !important;
}
@media screen and (max-width: 680px) {
  .table-container {
    display: none;
  }
  .swap-phone {
    display: flex;
    width: 100%;
    font-size: 16px;
    color: $main-gray;
    align-items: center;
    justify-content: center;
    span {
      max-width: 440px;
      text-align: center;
    }
  }
}
</style>
