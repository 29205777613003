var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-container"},[_c('div',{staticClass:"table-container",class:{ 'wide-container': _vm.filterFields.length <= 2 }},[_c('div',{class:{ 'wide-table': _vm.filterFields.length <= 2 },attrs:{"id":"table"}},[(_vm.isLoadEndpoints)?_c('div',{staticClass:"table-header"},[(_vm.isLoadEndpoints)?_c('p',{staticClass:"nameTable"},[_vm._v(_vm._s(_vm.nameTable))]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"search-filters-widgets"},[_c('div',{staticClass:"widgets-filters"},[_vm._l((_vm.data),function(rec,index){return _c('b-form-row',{key:index,staticClass:"widget"},[_c('b-form-group',{staticClass:"col",attrs:{"label":rec.label}},_vm._l((_vm.fields),function(field){return _c('div',{key:field.key,staticClass:"widget__components"},[(typeof rec[field.key] === 'object')?_c('Widget',_vm._b({key:field.key,class:{ 'add-button': field.key === 'add' },attrs:{"type":rec[field.key].type},on:{"$emits":_vm.$emits}},'Widget',_vm.renderBindWidget(field.key, _vm.data.fields),false)):_c('span',{key:field.key},[_vm._v(" "+_vm._s(field.key)+" : "+_vm._s(rec[field.key])+" ")])],1)}),0)],1)}),_vm._v(" "),_c('div',{staticClass:"filter",class:{ 'hide-filter': _vm.filterFields.length <= 2 }},[_c('div',{staticClass:"filter-width"},[(_vm.filterFields.length > 2)?_c('div',[_c('div',[(_vm.filterFields.length > 2)?_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body",class:{ spinner: !_vm.filter }},[(_vm.filter)?_c('form',[_c('b-form-row',_vm._l((_vm.filterData),function(rec,index){return (_vm.renderWidgetRec(rec, _vm.filterData))?_c('b-form-group',{key:index},[_c('div',{staticClass:"col",attrs:{"label":rec.label || ''}},_vm._l((_vm.filterFields),function(field){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(
                                    _vm.$cookie.get('internalName') !== 'methodologist' ||
                                    field.key === 'user_id' ||
                                    field.key === 'search_btn' ||
                                    field.key === 'clear_btn'
                                  ),expression:"\n                                    $cookie.get('internalName') !== 'methodologist' ||\n                                    field.key === 'user_id' ||\n                                    field.key === 'search_btn' ||\n                                    field.key === 'clear_btn'\n                                  "}],key:field.key,class:{
                                    filters:
                                      field.key !== 'search_btn' && field.key !== 'clear_btn'
                                  }},[(typeof rec[field.key] === 'object')?_c('Widget',_vm._b({key:field.key,attrs:{"type":rec[field.key].type},on:{"$emits":_vm.$emits}},'Widget',_vm.renderBindWidget(field.key, rec),false)):_vm._e()],1)}),0)]):_vm._e()}),1)],1):_c('form',[_vm._m(0)])])]):_vm._e()])]):_vm._e()])])],2),_vm._v(" "),(_vm.viewSearch)?_c('div',{staticClass:"global-search"},[_c('img',{attrs:{"src":require("../assets/img/ES/gray_search.svg"),"alt":"search"}}),_vm._v(" "),_c('input',{staticStyle:{"width":"100%"},attrs:{"id":"globalSearch","placeholder":"Поиск по таблице"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.globalSearch($event)}}})]):_vm._e()])]):_vm._e(),_vm._v(" "),_c('Table',{attrs:{"currentStore":_vm.store,"dataTable":_vm.tableData.total_count,"fieldsTable":_vm.tableFields,"head-variant":'light',"items":_vm.tableData.items,"viewGlobalSearch":_vm.viewSearch,"multiSelect":_vm.multiSelect},on:{"$emits":_vm.$emits}})],1)]),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],attrs:{"id":"cover-spin"}})])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"spinner-border text-dark",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"swap-phone"},[_c('span',[_vm._v("Переверните устройство в горизонтальное положение, чтобы просмотреть таблицу")])])}]

export { render, staticRenderFns }