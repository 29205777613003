import axios from 'axios'
import {
  domain,
  host,
  routeNotGenerated,
  isNeedPutMethods,
  DefaultValueSettings
} from '@/globalVariables'
import FileSaver from 'file-saver'
import 'sweetalert2/src/sweetalert2.scss'
import Swal from 'sweetalert2'
import {
  getLastDayOfMonth,
  isEmpty,
  notEmpty,
  valueEmpty
} from '@/components/Core/KernelProject/helperFunction'
import globals from '@/globals'
import CryptoJS from 'crypto-js'

/**
 * Миксин для конфирм модал с указаниям кастомных кнопок подтверждения и отмены
 */
const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'delete-button',
    cancelButton: 'cancel-button',
    popup: 'popup-class',
    title: 'title-class',
    content: 'content-class',
    actions: 'actions-class',
    icon: 'icon-class'
  },
  buttonsStyling: false
})

export default {
  props: [
    'items',
    'fieldsTable',
    'bordered',
    'borderless',
    'outlined',
    'small',
    'hover',
    'dark',
    'fixed',
    'footClone',
    'headVariant',
    'tableVariant',
    'stickyHeader',
    'empty',
    'currentStore',
    'dataTable',
    'viewGlobalSearch',
    'multiSelect'
  ],
  data() {
    return {
      added: null,
      tableView: {},
      isManageRecordShow: false,
      modalContent: {},
      modalField: [],
      filterFields: [],
      filterData: {},
      hideModal: true,
      route: this.$route.path
        .slice(1)
        .split(/\s+/)
        .map((word) => word[0].toUpperCase() + word.substring(1))
        .join(' ')
        .split('/')[1],
      type: {
        name: null,
        value: null
      },
      selectedItems: null,
      filter: false,
      DetailFields: [
        {
          key: 'view_btn',
          label: 'Просмотреть'
        },
        {
          key: 'edit_btn',
          label: 'Редактировать'
        },
        {
          key: 'del_btn',
          label: 'Удалить'
        }
      ],
      DetailData: [
        {
          view_btn: {
            type: 'Button',
            value: 'view',
            label: 'Просмотреть',
            tooltip: 'Просмотреть данную запись',
            variant: 'outline-info',
            role: 'getItem',
            lg: true
          },
          edit_btn: {
            type: 'Button',
            value: 'edit',
            label: 'Редактировать',
            tooltip: 'Редактировать данную запись',
            variant: 'outline-primary',
            role: 'update',
            lg: true
          },
          del_btn: {
            type: 'Button',
            value: 'delete',
            label: 'Удалить',
            tooltip: 'Удалить данную запись',
            variant: 'outline-danger',
            role: 'delete',
            lg: true
          }
        }
      ],
      // for filter and form create and edit rec
      fieldBtnAdd: {
        key: 'add_btn',
        label: 'Добавить'
      },
      addBtn: {
        type: 'Button',
        value: 'save',
        label: 'Сохранить',
        variant: 'outline-primary',
        role: 'update',
        lg: true
      },
      // for filter and form create and edit rec
      fieldBtnAddMore: {
        key: 'add_more_btn',
        label: 'Сохранить и добавить еще'
      },
      addMoreBtn: {
        type: 'Button',
        value: 'saveAndAdd',
        label: 'Сохранить и добавить еще',
        variant: 'outline-primary',
        role: 'update',
        lg: true,
        inList: false
      },
      // for mass edit
      fieldBtnEditAll: {
        key: 'EditAll',
        label: 'Сохранить'
      },
      EditAll: {
        type: 'Button',
        value: 'editAll',
        label: 'Сохранить',
        variant: 'outline-primary',
        role: 'create'
      },
      filterButtonSearchField: {
        key: 'search_btn',
        label: ' '
      },
      filterButtonClearField: {
        key: 'clear_btn',
        label: 'Очистить фильтр',
        role: 'getItems'
      },
      clear_btn: {
        type: 'Button',
        value: 'filterClear',
        label: ' ',
        variant: 'outline-danger',
        role: 'getItems'
      },
      search_btn: {
        type: 'Button',
        value: 'filterSearch',
        label: ' ',
        variant: 'outline-primary',
        role: 'getItems'
      },
      checkedRows: [],
      triggerFunction: {
        edit: false,
        openModalAdd: false,
        openModalEdit: false,
        delete: false,
        save: false,
        view: false,
        openModalControl: false,
        openModalMassEdit: false,
        openModalMassDelete: false,
        getDataWithFilter: false
      },
      // pagination
      maxPage: 5,
      PerPage: 25,
      currentPage: 1,
      inputMask: {
        alias: 'integer',
        rightAlign: false,
        allowMinus: false
      },
      perPageOptions: [25, 50, 100, 150, 300],
      filteredQuery: '',
      queryGlobal: '',
      flagOnClick: 0,
      themeName: null,
      selectedUserId: null,
      chosenUserIndex: null,
      currentActivityItems: [],
      sortFields: null,
      resetSorted: null,
      queryExact: '',
      rangeQuery: {},
      sortBy: null,
      sortDesc: null
    }
  },
  mounted() {
    this.$root.$on('clearFilter', () => {
      this.clearFilter()
    })
    this.$root.$on('closeModal', () => {
      if (this.added) {
        this.$root.$emit('getMetaData')
        this.$root.$emit('offTable')
        this.added = false
      }
    })
  },
  destroyed() {
    /**
     * Отключение всех эвентов, дабы не было дубликатов функций
     * срабатывает только у не генерируемой модели
     */
    this.$root.$on('destroy', () => {
      this.$root.$off('getFilterData')
      this.$root.$off('delete')
      this.$root.$off('add')
      this.$root.$off('view')
      this.$root.$off('close')
      this.$root.$off('edit')
      this.$root.$off('save')
      this.$root.$off('saveAndAdd')
      this.$root.$off('filterSearch')
      this.$root.$off('filterClear')
      this.$root.$off('filterSpinner')
      this.$root.$off('switchFilter')
      this.$root.$off('OpenRelation')
      this.$root.$off('removeAll')
      this.$root.$off('editAll')
      this.$root.$off('editAllModal')
    })
  },
  methods: {
    routeCalc() {
      return (
        this.$route.params.model ||
        this.$route.path
          .slice(1)
          .split(/\s+/)
          .map((word) => word[0].toUpperCase() + word.substring(1))
          .join(' ')
          .split('/')[1]
      )
    },
    OpenUserControl(user) {
      this.$router.push({ name: 'UserControl', params: { user_id: user.id } })
    },
    /**
     * Функция открытие панели управления после нажатия на любую запись в таблице, если выключен multiSelect у таблицы
     * @param item
     * @param index
     * @param event
     * @constructor
     */
    MenuRowClick(item, index, event) {
      this.triggerSwitch('openModalControl')
      let route = this.routeCalc()
      let serverRole = this.$store.state.auth.endpoints[this.$route.params.prefix][route]
      if (serverRole) {
        if (!this.multiSelect) {
          /**
           * Функция генерация кнопок для открытия по связям, только там где флаг openByRelation = true
           * Функция перебирает всю мету и ищет где данная сущность используется для связи
           */
          let storeMetaData = this.$store.state.metaProjectData[this.$route.params.prefix]
          if (storeMetaData[this.routeCalc()].openByRelation) {
            this.addButtonToOpenByRelation(storeMetaData, route)
          } else {
            for (let i in this.DetailFields) {
              if (this.DetailFields.hasOwnProperty(i)) {
                if (this.DetailFields[i].key === 'OpenRelation') {
                  this.DetailFields.splice(i, 1)
                }
              }
            }
          }
          this.selectedItems = item
          this.modalContent = this.DetailData
          this.modalField = this.DetailFields
          // let record = document.getElementsByClassName('manage-record')[0]
          // if (!this.isManageRecordShow) {
          //   record.style.display = 'block'
          //   record.style.left = event.pageX + 'px'
          //   record.style.top = event.pageY + 'px'
          //   this.isManageRecordShow = true
          // } else {
          //   record.style.display = 'none'
          //   this.isManageRecordShow = false
          // }
          this.type.name = 'Управление'
        }
      }
    },
    /**
     * Добавление кнопки открытие по связям выбранного элемента
     * Кнопка добавляется в модалку управления
     * @param storeMetaData
     * @param route
     */
    addButtonToOpenByRelation(storeMetaData, route) {
      let pk = ''
      let label = ''
      let model
      for (let key in storeMetaData) {
        if (storeMetaData.hasOwnProperty(key)) {
          for (let keys in storeMetaData[key].fields) {
            if (storeMetaData[key].fields.hasOwnProperty(keys)) {
              if (
                typeof storeMetaData[key].fields[keys] === 'object' &&
                storeMetaData[key].fields[keys].type === 'relation' &&
                (storeMetaData[key]['relations'][storeMetaData[key].fields[keys]['relation']][
                  'relationModel'
                ] === this.$route.params.model ||
                  storeMetaData[key]['relations'][storeMetaData[key].fields[keys]['relation']][
                    'relationModel'
                  ] === route)
              ) {
                pk = keys
                label = this.findLabelRelation(key)
                model = key
              }
            }
          }
        }
      }
      let button = this.generateButton(
        'OpenRelation',
        `Открыть по связям - ${label}`,
        'OpenRelation',
        pk,
        model
      )
      this.addButtonToData(
        button.newButtonField,
        button['OpenRelation'],
        'DetailFields',
        'DetailData'
      )
    },
    /**
     * Функция находит имя Связи Relation и возвращает его.
     * @param keyLabel - название связи (ex: UserRoles)
     * @returns {*} - label связи
     */
    findLabelRelation(keyLabel) {
      for (let key in this.$store.state.metaProjectMenu[this.$route.params.prefix]) {
        if (typeof this.$store.state.metaProjectMenu[this.$route.params.prefix][key] === 'object') {
          if (this.$store.state.metaProjectMenu[this.$route.params.prefix][key].view) {
            if (
              this.$store.state.metaProjectMenu[this.$route.params.prefix][key].route.split(
                '/'
              )[2] === keyLabel &&
              this.$store.state.metaProjectMenu[this.$route.params.prefix][key].view
            ) {
              return this.$store.state.metaProjectMenu[this.$route.params.prefix][key].label
            }
          } else {
            for (let i in this.$store.state.metaProjectMenu[this.$route.params.prefix][key].deep) {
              if (
                this.$store.state.metaProjectMenu[this.$route.params.prefix][key].deep[
                  i
                ].route.split('/')[2] === keyLabel &&
                this.$store.state.metaProjectMenu[this.$route.params.prefix][key].deep[i].view
              ) {
                return this.$store.state.metaProjectMenu[this.$route.params.prefix][key].deep[i]
                  .label
              }
            }
          }
        }
      }
    },

    /**
     * Функция сохранения записи после заполнения полей в модальном окне
     * Все данные хранятся в сторе this.$store.state.newRecord
     * Происходит перебор и записывается в переменную для отправки на сервер
     * Функция универсально и работает для добавления, так и для редактирования
     */
    addOnModal() {
      this.$root.$emit('setLoading', true, 'save')
      this.triggerSwitch('save')
      let currentPath
      let route = this.routeCalc()
      if (this.currentStore[route]) {
        currentPath = this.currentStore[route]
      }
      let newRecordStore = this.$store.state.newRecord
      let newLocalRec = {}
      let newRec = []
      for (let key in currentPath.fields) {
        if (newRecordStore[key] !== undefined && currentPath.fields.hasOwnProperty(key)) {
          newLocalRec[key] =
            newRecordStore[key] && newRecordStore[key].id
              ? newRecordStore[key].id
              : newRecordStore[key]
        }
      }
      newRec.push(newLocalRec)
      let url = ''
      let method = ''
      if (route === 'UserControl') {
        url = `${host.authUrl}/User/signUp`
        method = 'POST'
        newRec[0].password = CryptoJS.SHA256(CryptoJS.MD5(newRec[0].password).toString()).toString()
        newRec[0].password_approval = CryptoJS.SHA256(
          CryptoJS.MD5(newRec[0].password_approval).toString()
        ).toString()
      } else if (this.type.value === 'edit') {
        url = `${domain}/${this.$route.params.prefix}/${route}/${
          isNeedPutMethods ? 'update/' : ''
        }${this.selectedItems.id}`
        method = 'PUT'
      } else {
        url = `${domain}/${this.$route.params.prefix}/${route}/create`
        method = 'POST'
      }
      const options = {
        method: method,
        data: newRec,
        url
      }
      axios(options)
        .then(() => {
          if (!this.hideModal) {
            this.hideModal = true
            this.added = true
          } else {
            this.added = false
            this.$bvModal.hide('modal')
            this.$root.$emit('getMetaData')
            this.$root.$emit('offTable')
          }
          this.successAlert(
            this.type.value === 'edit' ? 'Запись успешно сохранена!' : 'Запись успешно добавлена!'
          )
          this.$root.$emit('setLoading', false, 'save')
        })
        .catch((error) => {
          this.$root.$emit('setLoading', false, 'save')
          this.errorAlert(error)
        })
    },
    /**
     * Метод для формирования переменной хранящей в себе все выбранные элементы таблицы
     * @param items
     */
    onRowSelected(items) {
      this.selectedRows = items
      this.$root.$emit('rowsSelected', this.selectedRows)
      this.$root.$emit('multiSelectButtonDisabled', !items.length >= 1)
    },
    /**
     * Функция просмотра записи в модальном окне
     * Данные из метаДанных попадут в просмотр с флагом inViewForm !== false
     * @param item
     */
    viewOnModal(item) {
      this.triggerSwitch('view')
      this.modalField = []
      let items = []
      this.type.name = 'Просмотр'
      this.type.value = 'view'
      items.push(item)
      this.modalContent = items
      let currentPath = this.currentStore[this.routeCalc()]
      for (let key in currentPath.fields) {
        if (currentPath.fields.hasOwnProperty(key)) {
          if (
            notEmpty(currentPath.fields[key]['inViewForm'])
              ? currentPath.fields[key]['inViewForm'] !== false
              : currentPath.fields[key]['renderPolicy'] !== false
          ) {
            let objField = {
              key: key,
              label: currentPath.fields[key].label
            }
            this.modalField.push(objField)
          }
        }
      }
      this.$bvModal.show(`modal`)
    },
    /**
     * Удаление записи с выводом sweetAlert модалки подтрвеждения
     * @param item
     */
    deleteOnTable(item) {
      this.triggerSwitch('delete')
      this.$bvModal.hide('modal')
      swalWithBootstrapButtons
        .fire({
          title: 'Подтвердите действие',
          text: `Вы уверены, что хотите удалить запись?`,
          showCancelButton: true,
          confirmButtonText: 'Удалить',
          cancelButtonText: 'Отмена',
          reverseButtons: true,
          imageUrl: `${require('../../../assets/img/delIcon.svg')}`
        })
        .then((result) => {
          if (result.value) {
            let route = this.routeCalc()
            axios
              .delete(`${domain}/${this.$route.params.prefix}/${route}/delete/${item.id}`)
              .then(() => {
                this.$root.$emit('update')
                swalWithBootstrapButtons.fire({
                  title: 'Удалено',
                  text: 'Данная запись успешно удалена.',
                  imageUrl: `${require('../../../assets/img/success.svg')}`
                })
              })
              .catch((error) => this.errorAlert(error))
          }
        })
    },
    /**
     * Открытия модального окна с добавлением записи
     * Виджеты формируются из метаДанных с флагом inCreateForm != false
     * @returns {array}
     */
    openModalAdd() {
      this.triggerSwitch('openModalAdd')
      this.modalField = []
      this.type.name = 'Добавление'
      this.type.value = 'addModal'
      this.modalContent = {}
      let modelRoute = this.routeCalc()
      if (this.currentStore[modelRoute].fields[this.filterButtonSearchField.key]) {
        delete this.currentStore[modelRoute].fields[this.filterButtonSearchField.key]
      }
      let currentPath = this.currentStore[modelRoute]
      for (let key in currentPath.fields) {
        if (currentPath.fields.hasOwnProperty(key)) {
          if (
            notEmpty(currentPath.fields[key]['inCreateForm'])
              ? currentPath.fields[key]['inCreateForm'] !== false
              : currentPath.fields[key]['renderPolicy'] !== false
          ) {
            let objField = {
              key: key,
              label: currentPath.fields[key].label
            }
            this.modalField.push(objField)
          }
        }
      }
      for (let key in currentPath.fields) {
        if (currentPath.fields.hasOwnProperty(key)) {
          currentPath.fields[key]['keyAdd'] = key
        }
      }
      this.modalContent = currentPath
      this.addButtonToData(this.fieldBtnAdd, this.addBtn, 'modalField', 'modalContent')
      // this.addButtonToData(this.fieldBtnAddMore, this.addMoreBtn, 'modalField', 'modalContent')
      this.$bvModal.show('modal')
    },
    /**
     * Открытия модального окна редактирование записи
     * В функции происходит поиск по всем типам связи для поиска нужных значений и передача value для отрисовки
     * компонента
     * Виджеты формируются из метаДанных с флагом inChangeForm != false
     * @param item
     * @returns {array}
     */
    viewEditModal(item) {
      this.triggerSwitch('openModalEdit')
      this.modalField = []
      this.type.name = 'Редактирование'
      this.type.value = 'edit'
      this.modalContent = {}
      let store = this.$store.state
      let route = this.routeCalc()
      let currentPath = JSON.parse(
        JSON.stringify(this.$store.state['metaProjectDataEdit'][this.$route.params.prefix][route])
      )
      for (let key in currentPath.fields) {
        if (currentPath.fields.hasOwnProperty(key)) {
          if (
            notEmpty(currentPath.fields[key]['inChangeForm'])
              ? currentPath.fields[key]['inChangeForm'] !== false
              : currentPath.fields[key]['renderPolicy'] !== false
          ) {
            let objField = {
              key: key,
              label: currentPath.fields[key].label
            }
            this.modalField.push(objField)
          }
          if (
            currentPath.fields[key]['inChangeForm'] === false &&
            currentPath.fields[key]['required']
          ) {
            let objField = {
              key: key,
              label: currentPath.fields[key].label
            }
            currentPath.fields[key].readonly = true
            this.modalField.push(objField)
          }
        }
      }
      for (let key in currentPath.fields) {
        if (currentPath.fields.hasOwnProperty(key)) {
          currentPath.fields[key]['keyAdd'] = key
          if (valueEmpty(item[key])) {
            if (currentPath.fields[key].type === 'relation') {
              this.viewEditModalRelation(currentPath, store, key, item, route)
            } else if (currentPath.fields[key].type === 'list') {
              for (let i = 0; i < currentPath.fields[key].list.length; i++) {
                if (item[key].toString() === currentPath.fields[key].list[i].name.toString()) {
                  currentPath.fields[key].list[i].id = currentPath.fields[key].list[i].id.toString()
                  currentPath.fields[key]['defaultValue'] = currentPath.fields[key].list[i]
                }
              }
            } else {
              currentPath.fields[key]['defaultValue'] = item[key]
            }
          }
        }
      }
      this.modalContent = currentPath
      this.addButtonToData(this.fieldBtnAdd, this.addBtn, 'modalField', 'modalContent')
      this.$bvModal.show('modal')
    },
    /**
     * Обработка поля с типом: relation для вывода под редактирование
     * Происходит поиск внутри стора с данными связей и отправка значения в модальное окно
     * @param currentPath
     * @param store
     * @param key
     * @param item
     * @param route
     */
    viewEditModalRelation(currentPath, store, key, item, route) {
      if (typeof item[key] === 'object') {
        return this.viewEditModalRelationM2M(currentPath, store, key, item, route)
      }
      let currentRelation = currentPath.fields[key]['relation']
      let storeRelation = store['relationsData'][this.$route.params.prefix][route][currentRelation]
      if (!storeRelation) {
        currentPath.fields[key]['defaultValue'] = item[key]
      } else {
        for (let i in storeRelation) {
          if (
            !isNaN(parseInt(item[key])) &&
            (parseInt(item[key]) === storeRelation[i].id || item[key] === storeRelation[i].id)
          ) {
            currentPath.fields[key]['defaultValue'] = storeRelation[i]
          }
        }
      }
    },
    /**
     * Обработка поля с типом: relation многие ко многим для вывода под редактирование
     * Происходит поиск внутри стора с данными связей и отправка значения в модальное окно
     * @param currentPath
     * @param store
     * @param key
     * @param item
     * @param route
     */
    viewEditModalRelationM2M(currentPath, store, key, item, route) {
      let currentRelation = currentPath.fields[key]['relation']
      let storeRelation = store['relationsData'][this.$route.params.prefix][route][currentRelation]
      if (!storeRelation) {
        currentPath.fields[key]['defaultValue'] = item[key]
      } else {
        let massItemsRelation = []
        for (let i in item[key]) {
          massItemsRelation.push(storeRelation[item[key][i]])
        }
        currentPath.fields[key]['defaultValue'] = massItemsRelation
      }
    },
    /**
     * Функция получения данных и формирование фильтров полей с флагом inFilter = true
     * Отключение валидации у всех полей
     */
    getFilterData() {
      this.filterFields = []
      this.filterData = {}
      let route = this.routeCalc()
      let filters = this.$store.state.metaProjectFilter[this.$route.params.prefix][route][
        'filterFields'
      ]
      let filter = {}
      filter['fields'] = {}
      for (let i in filters) {
        filter['fields'][i] = filters[i]
      }
      for (let key in filter.fields) {
        if (filter.fields.hasOwnProperty(key)) {
          let objField = {
            key: key,
            label: filter.fields[key].label || `Введите ${filter.fields[key]}`
          }
          this.filterFields.push(objField)
        }
      }
      for (let key in filter.fields) {
        if (filter.fields.hasOwnProperty(key)) {
          filter.fields[key]['keyAdd'] = key
          filter.fields[key]['isFilter'] = true
        }
      }
      this.filterData = filter
      this.addButtonToData(
        this.filterButtonSearchField,
        this.search_btn,
        'filterFields',
        'filterData'
      )
      this.addButtonToData(
        this.filterButtonClearField,
        this.clear_btn,
        'filterFields',
        'filterData'
      )
    },
    /**
     * Функция получения данных в таблицу используя фильтры
     * С получением данных по связям и формирование запроса с передачей в функцию получение данных
     * Функция работает со связями, происходит поиск по id и достает нужный объект из всех записей и получает итоговой
     * id записи
     * На каждый тип фильтра формируется строка для передаче в функцию запроса с сервера данных
     */
    getDataWithFilter() {
      this.triggerSwitch('getDataWithFilter')
      this.filteredQuery = ''
      this.queryExact = ''
      this.rangeQuery = {}
      let route = this.routeCalc()
      let currentPath = this.$store.state.metaProjectFilter[this.$route.params.prefix][route]
      if (!currentPath.filterFields) {
        return this.$root.$emit(`update`)
      }
      let newRecordStore = this.$store.state.newRecord
      let newLocalRec = {}
      if (currentPath.filterFields['tree']) {
        newLocalRec['_rel_model'] = this.$store.state.newRecord['_rel_model'] || ''
        newLocalRec['_rel_id'] = this.$store.state.newRecord['_rel_id'] || ''
      }
      for (let key in currentPath.filterFields) {
        if (currentPath.filterFields.hasOwnProperty(key)) {
          if (newRecordStore[key]) {
            newLocalRec[key] = newRecordStore[key]
          } else {
            currentPath.filterFields[key]['defaultValue'] = null
          }
        }
      }
      if (isEmpty(newLocalRec)) {
        for (let keys in currentPath.filterFields) {
          if (currentPath.filterFields.hasOwnProperty(keys)) {
            currentPath.filterFields[keys]['defaultValue'] = null
          }
        }
      }

      for (let key in newLocalRec) {
        if (key === '_rel_model' || key === 'value' || key === '_rel_id') {
          currentPath.filterFields['tree']['defaultValue'] =
            this.$store.state.newRecord['value'] || ''
          this.queryExact += `${key}=${newLocalRec[key]}&`
        } else if (currentPath.filterFields[key].type === 'relation') {
          this.getDataWithFilterRelation(currentPath, key, newLocalRec, route)
        } else if (currentPath.filterFields[key].type === 'list') {
          this.getDataWithFilterList(currentPath, key, newLocalRec, false)
        } else if (
          currentPath.filterFields[key].type === 'range-month' ||
          currentPath.filterFields[key].type === 'range-date-for-two-columns' ||
          currentPath.filterFields[key].type === 'range-year' ||
          currentPath.filterFields[key].type === 'month_range'
        ) {
          this.getDataWithFilterRange(currentPath, key, newLocalRec, route)
        } else if (currentPath.filterFields[key].type === 'field') {
          if (currentPath.fields[key].type === 'list') {
            this.getDataWithFilterList(currentPath, key, newLocalRec, true)
          } else if (currentPath.fields[key].type === 'relation') {
            this.getDataWithFilterRelation(currentPath, key, newLocalRec, route)
          } else if (
            currentPath.fields[key].type === 'range-month' ||
            currentPath.fields[key].type === 'range-date-for-two-columns' ||
            currentPath.fields[key].type === 'range-year' ||
            currentPath.fields[key].type === 'month_range'
          ) {
            this.getDataWithFilterRange(currentPath, key, newLocalRec, route)
          }
        } else {
          currentPath.filterFields[key]['defaultValue'] = newLocalRec[key]
          this.filteredQuery += `"${key}":"${newLocalRec[key]}",`
        }
      }
      this.currentPage = 1
      this.getDataPagination()
    },
    /**
     * Обработка полученного фильтра у типа Relation
     * @param currentPath
     * @param key
     * @param newLocalRec
     * @param route
     */
    getDataWithFilterRelation(currentPath, key, newLocalRec, route) {
      let currentRelation =
        currentPath['relations'][currentPath.filterFields[key]['relation']]['relationModel']
      let storeRelation = this.$store.state['relationsData'][this.$route.params.prefix][route][
        currentRelation
      ]
      if (typeof JSON.parse(newLocalRec[key]) === 'object') {
        currentPath.filterFields[key]['defaultValue'] = JSON.parse(newLocalRec[key])
      } else if (typeof newLocalRec[key] === 'number') {
        currentPath.filterFields[key]['defaultValue'] = newLocalRec[key]
      } else {
        for (let i in storeRelation) {
          if (newLocalRec[key].id === storeRelation[i].id) {
            currentPath.filterFields[key]['defaultValue'] = storeRelation[i]
            newLocalRec[key] = newLocalRec[key].id
          }
        }
      }
      this.queryExact += `${key}=${newLocalRec[key]}&`
    },
    /**
     * Обработка полученного фильтра у типа List
     * @param currentPath
     * @param key
     * @param newLocalRec
     * @param isField
     */
    getDataWithFilterList(currentPath, key, newLocalRec, isField) {
      if (typeof JSON.parse(newLocalRec[key]) === 'object') {
        currentPath.filterFields[key]['defaultValue'] = JSON.parse(newLocalRec[key])
      } else {
        let list = isField ? currentPath.fields[key].list : currentPath.filterFields[key].list
        for (let i = 0; i < list.length; i++) {
          if (newLocalRec[key] === list[i].id) {
            currentPath.filterFields[key]['defaultValue'] = list[i]
          }
        }
      }
      this.queryExact += `${key}=${newLocalRec[key]}&`
    },
    /**
     * Обработка полученного фильтра у типа range-month
     * @param currentPath
     * @param key
     * @param newLocalRec
     */
    getDataWithFilterRange(currentPath, key, newLocalRec) {
      newLocalRec[key].start = {
        [key]: `${newLocalRec[key].start}-01 00:00:00`
      }
      newLocalRec[key].end = {
        [key]: `${newLocalRec[key].end}-${getLastDayOfMonth(
          newLocalRec[key]['end'].split('-')[0],
          newLocalRec[key]['end'].split('-')[1]
        )} 00:00:00`
      }
      currentPath.filterFields[key]['defaultValue'] = newLocalRec[key]
      this.rangeQuery = newLocalRec[key]
    },
    /**
     * Добавление в массивы и объекты с проверкой на существование
     * @param _field - филды, которые нужно добавить
     * @param _data - дата, которую нужно добавить
     * @param fields - филды куда добавить, посылать в виде ['filterFields'] - данная дата должна лежать внутри компонента
     * @param data - дата куда добавить, посылать в виде ['filterData'] - данная дата должна лежать внутри компонента
     * @returns {Array.push}
     */
    addButtonToData(_field, _data, fields, data) {
      let signal
      if (fields === 'DetailFields') {
        if (!this[data][0][_field.key]) {
          this[data][0][_field.key] = _data
        }
        for (let i in this[fields]) {
          if (this[fields].hasOwnProperty(i)) {
            if (this[fields][i].key === _field.key) {
              signal = true
            }
          }
        }
        if (!signal) {
          return this[fields].push(_field)
        }
      } else {
        if (this[data] && this[data].fields && !this[data].fields[_field.key]) {
          this[data].fields[_field.key] = _data
        }
        for (let i in this[fields]) {
          if (this[fields].hasOwnProperty(i)) {
            if (this[fields][i].key === _field.key) {
              signal = true
            }
          }
        }
        if (!signal) {
          return this[fields].push(_field)
        }
      }
    },
    /**
     * Генерирует кнопку для открытия по связям в тех компонентах где есть хоть 1 тип - relation
     * @param key - ключ для создания кнопки
     * @param label - имя для пропа кнопок
     * @param value - его значения для обработки
     * @param pk - открытия поо связям
     * @param model - имя модели в сторе
     * @param variant - обычный Bootstrap
     * @return {{newButtonField: {label: *, key: *}}}
     */
    generateButton(key, label, value, pk, model, variant) {
      let buttonArr = [
        {
          newButtonField: {
            key: key,
            label: label
          }
        }
      ]
      buttonArr[0][key] = {
        type: 'Button',
        value: value,
        label: label,
        pk: pk,
        model: model,
        variant: variant || 'outline-primary',
        role: 'getItems'
      }
      return buttonArr[0]
    },
    /**
     * Так как фильтры подгружаются динамически стоит ставить задержку для отображения фильтров и сохранение значений
     */
    switchFilter() {
      this.filter = false
      setTimeout(() => {
        this.filter = true
      }, 1000)
    },
    /**
     * Открытия модального окна настроек таблицы
     */
    showSlideModal() {
      this.$bvModal.show('slideModal')
    },
    /**
     * Отчистка хранилища после закрытия модального окна и обновления фильтров
     */
    clearStore() {
      this.$root.$emit('switchFilter')
    },
    /**
     * вспомогательная функция для изменения настроек таблицы (changeVisibleColumn)
     */
    setCookieValues(route, value, col) {
      value[this.$route.params.prefix] = {}
      value[this.$route.params.prefix][route] = {}
      value[this.$route.params.prefix][route][col.key] = col
    },
    /**
     * Изменение настроек таблицы
     * Так как нету возможности универсально парсить/распарсить объект, то все
     * записывает в отдельности
     * @param col
     */
    changeVisibleColumn(col) {
      let route = this.routeCalc()
      if (!col.visible) {
        col.thClass = 'd-none'
        col.tdClass = 'd-none'
      } else {
        col.thClass = 'table-row'
        col.tdClass = 'table-row'
      }
      if (isEmpty(this.$cookie.get('styleFields'))) {
        this.setCookieValues(route, this.tableView, col)
        this.$cookie.set('styleFields', JSON.stringify(this.tableView))
      } else {
        let styleFields = JSON.parse(this.$cookie.get('styleFields'))
        if (notEmpty(styleFields[this.$route.params.prefix])) {
          if (notEmpty(styleFields[this.$route.params.prefix][route])) {
            styleFields[this.$route.params.prefix][route][col.key] = col
          } else {
            styleFields[this.$route.params.prefix][route] = {}
            styleFields[this.$route.params.prefix][route][col.key] = col
          }
        } else {
          this.setCookieValues(route, styleFields, col)
        }
        this.$cookie.set('styleFields', JSON.stringify(styleFields))
      }
    },
    /**
     * Сохранение настроек таблицы в куки и стор
     * @param variable - параметр настройки
     * @param value - значение параметра
     */
    changeSetting(variable, value) {
      let storeSetting = this.$store.state.settingTable
      if (value === true) {
        storeSetting[variable] = value
        this.$cookie.set(variable, value)
      } else {
        storeSetting[variable] = value
        this.$cookie.delete(variable)
      }
    },
    /**
     * Глобальный поиск
     * Просто получаем value и передаем в функцию запроса данных с сервера
     * @param event
     * @return {this.getDataPagination}
     */
    globalSearch(event) {
      this.queryGlobal = event.target.value
      this.currentPage = 1
      return this.getDataPagination()
    },
    /**
     * Проверка является ли строка допустимой строкой JSON
     * @param str
     * @return {boolean}
     * @constructor
     */
    isJsonString(str) {
      try {
        JSON.parse(str)
      } catch (e) {
        return false
      }
      return true
    },
    /**
     * url из базы в url для вставки в верстку
     * @param url
     * @return {string}
     */
    renderUrl(url) {
      return `${host.fileServerUrl}/${url}`
    },
    /**
     * Выгрузка файла с сервера с помощью библиотеке FileSaver
     * @param url
     * @param name
     * @return {FileSaver}
     */
    downloadFromServer(url, name) {
      axios({
        url: url,
        method: 'GET',
        responseType: 'blob' // important
      }).then((response) => {
        return FileSaver.saveAs(new Blob([response.data]), `${name || this.getRandomName(1, 999)}`)
      })
    },
    /**
     * Псевдо-генерация случайной строки для скачивания файла
     * @param min
     * @param max
     * @return {string}
     */
    getRandomName(min, max) {
      return `DownloadFromTheServer_${Math.floor(Math.random() * (max - min)) + min}`
    },
    /**
     * Метод обработки картинки в base64
     * @param file
     * @return {Promise<string>}
     * Пример: this.getBase64(data.event.target.files[0]).then(response => this.sendFileToServer(response))
     */
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    },
    /**
     * Функция создания фильтра по связям
     * На данный момент подставляет id -> в дальнейшем стоит сделать универсальность поля, которое должно использоваться
     * в качестве связи
     * При переходе проверяет генерируется ли роут для определения куда кидать
     * @param data
     * @return {$router}
     */
    openByRelation(data) {
      data = {}
      let route = this.routeCalc()
      let storeMetaData = this.$store.state.metaProjectData[this.$route.params.prefix]
      for (let key in storeMetaData) {
        if (storeMetaData.hasOwnProperty(key)) {
          for (let keys in storeMetaData[key].fields) {
            if (storeMetaData[key].fields.hasOwnProperty(keys)) {
              if (
                typeof storeMetaData[key].fields[keys] === 'object' &&
                storeMetaData[key].fields[keys].type === 'relation' &&
                (storeMetaData[key]['relations'][storeMetaData[key].fields[keys]['relation']][
                  'relationModel'
                ] === this.$route.params.model ||
                  storeMetaData[key]['relations'][storeMetaData[key].fields[keys]['relation']][
                    'relationModel'
                  ] === route)
              ) {
                data['pk'] = keys
                data['label'] = storeMetaData[key]['label']
                data['model'] = key
              }
            }
          }
        }
      }
      this.$store.state.pagination.queryRelation = `"${data.pk}":"${this.selectedItems.id}"`
      if (route) {
        this.$bvModal.hide('modal')
      }
      let onSearch = routeNotGenerated.filter((item) => {
        return item === data.model
      })
      if (onSearch.length === 0) {
        return this.$router.push(data.model)
      } else {
        return this.$router.push(`/${data.model}`)
      }
    },
    /**
     * Массовое удаление, формируется исходя из выбранных элементов в таблице.
     * При нажатии появляется модальное окно подтверждения, если подтвердить - уйдет запрос в backend.
     */
    multiRemoveItems() {
      this.triggerSwitch('openModalMassDelete')
      let arr = []
      for (let i in this.selectedRows) {
        if (this.selectedRows.hasOwnProperty(i)) {
          arr.push(this.selectedRows[i].id)
        }
      }
      swalWithBootstrapButtons
        .fire({
          title: 'Подтвердите действие',
          text: `Вы уверены, что хотите удалить выбранные записи?`,
          showCancelButton: true,
          confirmButtonText: 'Удалить',
          cancelButtonText: 'Отмена',
          reverseButtons: true,
          imageUrl: `${require('../../../assets/img/delIcon.svg')}`
        })
        .then((result) => {
          let route = this.routeCalc()
          if (result.value) {
            this.$refs.mainTable.clearSelected()
            axios
              .delete(`${domain}/${this.$route.params.prefix}/${route}/deleteByIds/`, {
                data: [arr]
              })
              .then(() => {
                this.$root.$emit('offTable')
                this.$root.$emit('getMetaData')
                swalWithBootstrapButtons.fire({
                  title: 'Удалено',
                  text: 'Записи успешно удалены.',
                  imageUrl: `${require('../../../assets/img/success.svg')}`
                })
              })
              .catch((error) => this.errorAlert(error))
          }
        })
    },
    /**
     * Открытие модального окна с массовым изменением с отключением валидации обязательного поля
     * @constructor
     */
    modalMultiEditItems() {
      this.triggerSwitch('openModalMassEdit')
      let arr = []
      for (let i in this.selectedRows) {
        if (this.selectedRows.hasOwnProperty(i)) {
          arr.push(this.selectedRows[i].id)
        }
      }
      this.$store.state.multiEditIds = arr
      let MultiStore = this.$store.state.metaProjectMultiEdit[this.$route.params.prefix]
      this.modalField = []
      this.type.name = 'Массовое изменение'
      this.type.value = 'editAll'
      this.modalContent = {}
      let modelRoute = this.routeCalc()
      if (MultiStore[modelRoute].fields[this.filterButtonSearchField.key]) {
        delete MultiStore[modelRoute].fields[this.filterButtonSearchField.key]
      }
      let currentPath = MultiStore[modelRoute]
      for (let key in currentPath.fields) {
        if (currentPath.fields.hasOwnProperty(key)) {
          if (currentPath.fields[key]['Inmasschange'] !== false) {
            let objField = {
              key: key,
              label: currentPath.fields[key].label
            }
            this.modalField.push(objField)
          }
        }
      }
      this.$bvModal.show('modal')
      for (let key in currentPath.fields) {
        if (currentPath.fields.hasOwnProperty(key)) {
          currentPath.fields[key]['keyAdd'] = key
          currentPath.fields[key].required = false
        }
      }
      this.modalContent = currentPath
      this.addButtonToData(this.fieldBtnEditAll, this.EditAll, 'modalField', 'modalContent')
    },
    /**
     * Массовое редактирование с подтверждением от пользователя через модалку
     * Собираются id => массово отправляются с полями, которые нужно поменять
     * @constructor
     */
    MultiEditItems() {
      this.$bvModal.hide('modal')
      let arrIds = this.$store.state.multiEditIds
      let newRecord = this.$store.state.newRecord
      let bodyData = {}
      bodyData['ids'] = arrIds
      for (let key in newRecord) {
        if (newRecord.hasOwnProperty(key)) {
          bodyData[key] = newRecord[key].id ? newRecord[key].id : newRecord[key]
        }
        swalWithBootstrapButtons
          .fire({
            title: 'Подтвердите действие',
            text: `Вы уверены, что хотите изменить выбранные записи?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Изменить',
            cancelButtonText: 'Отмена',
            reverseButtons: true
          })
          .then((result) => {
            let route = this.routeCalc()
            if (result.value) {
              this.$refs.mainTable.clearSelected()
              axios
                .post(`${domain}/${this.$route.params.prefix}/${route}/updateByIds/`, {
                  data: bodyData
                })
                .then(() => {
                  this.$root.$emit('offTable')
                  this.$root.$emit('getMetaData')
                  swalWithBootstrapButtons.fire(
                    'Изменено!',
                    'Записи выбранные успешно изменены.',
                    'success'
                  )
                })
                .catch((error) => this.errorAlert(error))
            }
          })
      }
    },
    /**
     * Сортировка по определенному полю
     * @param sortInfo
     * _orderBy - поле, по которому будет проходить сортировка
     * _order - в каком направлении сортировать ASC или DESC
     */
    handleSortChange(sortInfo) {
      let filterRec = this.$store.state.newRecord
      if (sortInfo.sortBy && !filterRec) {
        this.sortFields = ``
        let _orderBy = sortInfo.sortBy
        let _order = `{"${sortInfo.sortBy}": "${sortInfo.sortDesc ? 'desc' : 'asc'}"}`
        this.sortFields = `_order_by=${_orderBy}&_order=${_order}`
        this.getDataPagination()
      } else if (sortInfo.sortBy && filterRec) {
        this.getDataWithFilter()
        this.sortFields = ``
        let _orderBy = sortInfo.sortBy
        let _order = `{"${sortInfo.sortBy}": "${sortInfo.sortDesc ? 'desc' : 'asc'}"}`
        this.sortFields = `_order_by=${_orderBy}&_order=${_order}`
        this.getDataPagination()
      }
    },
    /**
     * Методы для работы пагинации и фильтров
     */
    goFirstPage() {
      document.getElementsByClassName('b-table-sticky-header')[0].scrollTop = 0
      globals().scrollTop(0, 0)
      this.currentPage = 1
    },
    goLastPage() {
      document.getElementsByClassName('b-table-sticky-header')[0].scrollTop = 0
      globals().scrollTop(0, 0)
      this.currentPage = this.totalPages
    },
    goPreviousPage() {
      document.getElementsByClassName('b-table-sticky-header')[0].scrollTop = 0
      globals().scrollTop(0, 0)
      if (parseInt(this.currentPage) === 0) {
        this.currentPage = 1
      }
      if (this.currentPage <= 1) {
        this.currentPage = 1
      } else {
        this.currentPage -= 1
      }
    },
    goNextPage() {
      document.getElementsByClassName('b-table-sticky-header')[0].scrollTop = 0
      globals().scrollTop(0, 0)
      if (parseInt(this.currentPage) === 0) {
        this.currentPage = 1
      }
      if (parseInt(this.currentPage) >= parseInt(this.totalPages)) {
        this.currentPage = this.totalPages
      } else {
        this.currentPage++
      }
    },
    changeNumberPageBlur() {
      if (this.currentPage === '') {
        this.currentPage = 1
      }
    },
    changeNumberPage() {
      if (this.currentPage !== '') {
        this.currentPage = 1
      }
    },
    changeInput() {
      document.getElementsByClassName('b-table-sticky-header')[0].scrollTop = 0
      globals().scrollTop(0, 0)
      if (parseInt(this.currentPage) === 0) {
        this.currentPage = 1
      }
      if (parseInt(this.currentPage) > this.totalPages) {
        this.currentPage = this.totalPages
      }
    },
    getAmountOnPage(amount) {
      document.getElementsByClassName('b-table-sticky-header')[0].scrollTop = 0
      globals().scrollTop(0, 0)
      if (!amount) {
        this.PerPage = 25
      } else {
        this.PerPage = amount
      }
      this.currentPage = 1
      this.getDataPagination()
    },
    clearFilter() {
      this.filteredQuery = ''
      this.queryExact = ''
      this.queryGlobal = ''
      this.rangeQuery = ''
      this.$store.state.newRecord = {}
    },
    /**
     * Получение записей учитывая пагинацию и фильтр
     * Все данные передаются в миксин каждой view, где данные обратно
     * @param clear - отчистить ли фильтр
     */
    getDataPagination(clear) {
      this.$root.$emit('offTable')
      this.filter = false
      if (clear) {
        this.currentPage = 1
        this.filteredQuery = ''
        this.queryGlobal = ''
        this.queryExact = ''
        this.rangeQuery = ''
        this.$store.state.newRecord = {}
        this.sortFields = ''
        this.resetSorted = new Date().toLocaleString()
        this.$root.$emit('setPagination', 25, 1)
      }
      this.filteredQuery = `${this.filteredQuery}`
      let query = this.filteredQuery.slice(0, -1)
      let _from = this.PerPage * this.currentPage - this.PerPage + 1
      let perPage = this.PerPage || null
      let queryGlobal = this.queryGlobal || null
      let querySort = this.sortFields || null
      let queryExact = this.queryExact || null
      let rangeQuery = this.rangeQuery || null
      this.$root.$emit(
        'update',
        _from,
        perPage,
        query,
        queryGlobal,
        querySort,
        queryExact,
        rangeQuery,
        clear
      )
    },
    triggerSwitch(name) {
      this.triggerFunction[name] = !this.triggerFunction[name]
      this.triggerFunction[name] = !this.triggerFunction[name]
    },
    /**
     * Скрытие кнопок управления записью по ролям
     * Роли берет в сторе endPoints и проверяет на совпадение, если роль найдена - кнопка отрисуется
     * иначе кнопка будет скрыта
     * @param role
     * @return {boolean}
     */
    roleHidden(role) {
      let openRelation = role.value
      if (openRelation === 'OpenRelation') {
        return !this.$store.state.metaProjectData[this.$route.params.prefix][this.routeCalc()]
          .openByRelation
      }
      role = role.role
      if (role) {
        let route = this.routeCalc()
        if (this.$store.state.auth.endpoints[this.$route.params.prefix]) {
          if (notEmpty(this.$store.state.auth.endpoints[this.$route.params.prefix][route])) {
            for (let i in this.$store.state.auth.endpoints[this.$route.params.prefix][route]) {
              if (role === this.$store.state.auth.endpoints[this.$route.params.prefix][route][i]) {
                return false
              }
            }
          } else {
            return true
          }
        } else {
          return true
        }
        return true
      }
    }
  },
  computed: {
    // pagination:
    totalItems() {
      if (this.dataTable) {
        return this.dataTable
      }
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.PerPage) || (this.totalItems ? 1 : 1)
    },
    isDisabledBack() {
      if (this.totalPages === 1) {
        return true
      }
      return parseInt(this.currentPage) === 1 && parseInt(this.currentPage) !== this.totalPages
    },
    isDisabledNext() {
      if (this.maxPage === 1) {
        return true
      }
      return parseInt(this.currentPage) === this.totalPages && parseInt(this.currentPage) !== 1
    },
    isViewSettingTable() {
      return DefaultValueSettings.isViewSettingTable
    }
  },
  watch: {
    // pagination:
    currentPage(pages) {
      this.getDataPagination()
      this.$emit('changePage', pages)
    },
    PerPage(pages) {
      this.$emit('changePages', pages)
    },
    totalPages() {
      if (this.totalPages < this.currentPage) {
        this.currentPage = this.totalPages
      }
    }
  }
}
