export default {
  methods: {
    File__click(data) {}
  },
  computed: {},
  data() {
    return {}
  },
  watch: {}
}
