<template>
  <div class="lesson-card" :class="{ 'chosen-time': wasAddStudyTime }">
    <div class="added-time" v-if="wasAddStudyTime">
      <div class="number">Свободное время</div>
      <div class="time">
        <img src="../../../../../assets/img/alarm.svg" alt="time" />
        <span>{{ formatHours }}</span>
      </div>
    </div>
    <div class="empty-time" v-else>
      <div class="time">
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.499 19.25C6.17362 19.193 2.68103 15.7004 2.62402 11.375C2.68103 7.04959 6.17362 3.55701 10.499 3.5C14.8244 3.55701 18.317 7.04959 18.374 11.375C18.317 15.7004 14.8244 19.193 10.499 19.25ZM10.499 5.25C7.1348 5.29429 4.41831 8.01077 4.37402 11.375C4.41831 14.7392 7.1348 17.4557 10.499 17.5C13.8633 17.4557 16.5797 14.7392 16.624 11.375C16.5797 8.01077 13.8633 5.29429 10.499 5.25ZM14.874 12.25H9.62402V7H11.374V10.5H14.874V12.25Z"
            fill="#8F9BB3"
          />
        </svg>
        <span>{{ formatHours }}</span>
      </div>
      <img src="../../../../../assets/img/ES/plus_gray.svg" alt="plus" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChosenCard',
  props: {
    hour: String,
    day: Object,
    freeTimeData: Object
  },
  computed: {
    /**
     * Возвращает true если на текущую дату было выбрано свободное время
     * @returns {boolean}
     */
    wasAddStudyTime() {
      if (this.freeTimeData.work_times) {
        for (let i in this.freeTimeData.work_times) {
          if (this.freeTimeData.work_times[i].kls_day_id === this.day.kls_id) {
            for (let j in this.freeTimeData.work_times[i].work_hours) {
              if (this.hour === this.freeTimeData.work_times[i].work_hours[j]) {
                return true
              }
            }
          }
        }
      }
      return false
    },
    formatHours() {
      return `${Number(this.hour) < 10 ? '0' + Number(this.hour) : Number(this.hour)}:00 - ${
        Number(this.hour) < 9 ? '0' + (Number(this.hour) + 1) : Number(this.hour) + 1
      }:00`
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../../../styleVariables';
.lesson-card {
  background-color: $main-white;
  height: 70px;
  margin-bottom: 8px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
  border-radius: 4px;

  .number {
    margin: 8px 20px 12px;
    font-size: 14px;
    line-height: 18px;
    color: $main-dark;
  }

  .time {
    display: flex;
    align-items: center;
    margin: 0 20px;
    font-size: 14px;
    color: $main-dark;
    line-height: 18px;

    img {
      margin-right: 6px;
    }
  }

  .empty-time {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .time {
      color: $main-gray;
      svg {
        margin-right: 6px;
        margin-bottom: 2px;
      }
    }
  }
}
</style>
