<template>
  <div class="pagination-and-output">
    <div class="amount-output" v-if="totalPages">
      <span> Отображать по: </span>
      <b-form-select
        v-model="perPage"
        :options="perPageOptions"
        size="sm"
        class="amount-on-page-select"
        @input="$emit('changedPerPage', perPage)"
      ></b-form-select>
    </div>
    <div class="pagination">
      <div class="pagination__btn back" @click="$emit('firstPageClick')">
        <p class="first-double-btn">
          <span class="page-link"
            ><img src="../../../assets/img/ES/double_arrow_left.svg" alt="start"
          /></span>
        </p>
      </div>
      <div class="pagination__btn back" @click="$emit('prevPageClick')">
        <p class="first-btn">
          <span class="page-link"
            ><img src="../../../assets/img/ES/arrow_left.svg" alt="prev"
          /></span>
        </p>
      </div>
      <p class="pagination__pages">
        <span>{{ currentPage }}</span>
        из
        <span>{{ totalPages }}</span>
      </p>
      <div class="pagination__btn back" @click="$emit('nextPageClick')">
        <p class="last-double-btn">
          <a class="page-link"><img src="../../../assets/img/ES/arrow_right.svg" alt="next" /></a>
        </p>
      </div>
      <div class="pagination__btn next" @click="$emit('lastPageClick')">
        <p class="last-double-btn">
          <a class="page-link"
            ><img src="../../../assets/img/ES/double_arrow_right.svg" alt="end"
          /></a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotificationPagination',
  props: {
    currentPage: Number,
    totalPages: Number
  },
  data() {
    return {
      perPage: 25,
      perPageOptions: [25, 50, 100, 200]
    }
  }
}
</script>

<style scoped></style>
