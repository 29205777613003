import { debug } from '@/main'
import { isJSON, notEmpty } from '@/components/Core/KernelProject/helperFunction'

export default {
  methods: {
    /**
     * Обработка ошибок от запросов
     * @param error
     */
    errorAlert(error) {
      let msg = `undefined error from backend`
      let code = error.name || '500'
      if (error.response) {
        msg = `${error.response.data.data.error.message}`
        code = `${error.response.data.data.error.code}`
      } else {
        msg = `${error.message || error}`
      }
      if (code === '401') {
        this.$root.$emit('unsubscribeGeneral')
        this.$store.dispatch('logout')
        if (this.$route.path !== '/login') {
          this.$router.push('/login')
        }
      }
      // eslint-disable-next-line no-console
      if (debug) console.error(error)
      if (
        isJSON(error.response.data.data.error.message) &&
        notEmpty(error.response.data.data.error.message)
      ) {
        let msgErrors = JSON.parse(error.response.data.data.error.message)
        for (let i in msgErrors) {
          this.$toasted.show(`${msgErrors[i]}`, {
            type: 'error',
            duration: 4000
          })
        }
      } else {
        return this.$toasted.show(`${msg} code - ${code || ''}`, {
          type: 'error',
          duration: 4000
        })
      }
    },
    successAlert(success) {
      let msg = success
      if (success.response) {
        msg = `${success.response.data.data.error.message}`
      } else {
        msg = `${success.message || msg}`
      }
      return this.$toasted.show(`${msg}`, {
        type: 'success',
        duration: 4000
      })
    },
    messageAnyType(message, type) {
      return this.$toasted.show(`${message}`, {
        type: type,
        duration: 4000
      })
    }
  }
}
