<template>
  <div class="main-container">
    <div class="table-container" :class="{ 'wide-container': filterFields.length <= 2 }">
      <div id="table" :class="{ 'wide-table': filterFields.length <= 2 }">
        <div v-if="isLoadEndpoints" class="table-header">
          <!--          <p class="nameTable" v-if="isLoadEndpoints">{{ nameTable }}</p>-->
          <div class="search-filters-widgets">
            <div class="widgets-filters">
              <b-form-row :key="index" v-for="(rec, index) in data" class="widget">
                <div :label="rec.label">
                  <div :key="field.key" v-for="field in fields" class="widget__components">
                    <Widget
                      :class="{ 'add-button': field.key === 'add' }"
                      :key="field.key"
                      :type="rec[field.key].type"
                      @$emits="$emits"
                      v-bind="renderBindWidget(field.key, data.fields)"
                      v-if="typeof rec[field.key] === 'object'"
                    />
                    <span :key="field.key" v-else> {{ field.key }} : {{ rec[field.key] }} </span>
                  </div>
                </div>
              </b-form-row>

              <div class="filter" :class="{ 'hide-filter': filterFields.length <= 2 }">
                <div class="filter-width">
                  <div v-if="filterFields.length > 2">
                    <div>
                      <div class="card" v-if="filterFields.length > 2">
                        <div class="card-body" :class="{ spinner: !filter }">
                          <form v-if="filter">
                            <b-form-row>
                              <!--                  eslint-disable -->
                              <b-form-group
                                :key="index"
                                v-for="(rec, index) in filterData"
                                v-if="renderWidgetRec(rec, filterData)"
                              >
                                <!--                  eslint-enabled -->
                                <div :label="rec.label || ''" class="col">
                                  <div
                                    :key="field.key"
                                    v-for="field in filterFields"
                                    :class="{ filters: field.key !== 'search_btn' }"
                                  >
                                    <Widget
                                      :key="field.key"
                                      :type="rec[field.key].type"
                                      @$emits="$emits"
                                      v-bind="renderBindWidget(field.key, rec)"
                                      v-if="typeof rec[field.key] === 'object'"
                                    />
                                  </div>
                                </div>
                              </b-form-group>
                            </b-form-row>
                          </form>
                          <form v-else>
                            <div class="spinner-border text-dark" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--    end filter    -->
                </div>
              </div>
            </div>

            <div class="global-search" v-if="viewSearch">
              <img src="../../assets/img/ES/gray_search.svg" alt="search" />
              <input
                id="globalSearch"
                placeholder="Поиск по таблице"
                @keyup.enter="globalSearch($event)"
                style="width: 100%"
              />
            </div>
          </div>
          <div class="dashboard">
            <div class="lesson-amount">
              <div class="label">Остаток занятий</div>
              <img src="../../assets/img/ES/packs-count-blue.svg" alt="" />
              <div class="amount">{{ `${lessonCount} ${endingOfWord(lessonCount)}` }}</div>
            </div>
            <div class="buttons">
              <button class="to-packs" @click="$router.push('/monolit/LessonPack')">
                Купить пакет
              </button>
              <button v-if="!haveRefundRequest" class="return-cash" @click="refundRequest">
                Расторгнуть договор
              </button>
            </div>
          </div>
        </div>
        <Table
          :currentStore="store"
          :dataTable="tableData.total_count"
          :fieldsTable="tableFields"
          :head-variant="'light'"
          :items="tableData.items"
          @$emits="$emits"
          :viewGlobalSearch="viewSearch"
          :multiSelect="multiSelect"
        />
      </div>
    </div>
    <div id="cover-spin" v-show="!loading"></div>
  </div>
</template>

<script>
import Table from './Table'
import eventDelegate from '../../components/Core/KernelProject/eventDelegate'
import Widget from '../../components/Core/Widget'
import renderWidget from '../../components/Core/KernelProject/renderWidget'
import authEvents from '../../components/Core/KernelProject/authEvents'
import viewsDefaultEvents from '../../components/Core/KernelProject/viewsDefaultEvents'
import tableEvents from '../../components/Core/KernelProject/tableEvents'
import Swal from 'sweetalert2'
import axios from 'axios'
import { domain, MicroServices } from '@/globalVariables'
import scriptMixins from '@/components/scriptMixins/scriptMixins'

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'confirm-button',
    cancelButton: 'delete-button',
    title: 'title-class',
    input: 'input-class'
  },
  buttonsStyling: false
})
export default {
  name: 'StudentReport',
  components: {
    Widget,
    Table
  },
  mixins: [eventDelegate, renderWidget, authEvents, viewsDefaultEvents, tableEvents, scriptMixins],
  data() {
    return {
      tableData: [],
      tableFields: [],
      store: null,
      route: null,
      viewSearch: null,
      multiSelect: null,
      loading: false,
      to: null,
      from: null,
      lessonCount: null,
      haveRefundRequest: false
    }
  },
  mounted() {
    this.$root.$on('getFilterData', (item) => {
      this.getFilterData()
    })
    // filter need
    this.$root.$on('filterSearch', (data) => {
      this.getDataWithFilter()
    })
    // filter clear
    this.$root.$on('filterClear', (data) => {
      let clear = true
      this.getDataPagination(clear)
      this.$root.$emit('getMetaData', true)
    })
    // filter clear
    this.$root.$on('switchFilter', (data) => {
      this.switchFilter()
    })
    // filter spinner => true
    this.$root.$on('filterSpinner', (data) => {
      setTimeout(() => {
        this.filter = true
      }, 500)
    })
    this.getLessonsCount()
  },
  methods: {
    /**
     * Получить остаток занятий текущего пользователя (студент)
     */
    getLessonsCount() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Student/getAvailableLessonsAmount`,
        method: 'GET'
      })
        .then((response) => {
          this.lessonCount = response.data.data.lessons_amount
          this.haveRefundRequest = response.data.data.have_refund_request
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Запросить возврат средств
     */
    refundRequest() {
      swalWithBootstrapButtons
        .fire({
          title: `Расторгнуть договор`,
          showCancelButton: true,
          confirmButtonText: 'Сохранить',
          cancelButtonText: 'Отмена',
          reverseButtons: true,
          input: 'textarea',
          inputPlaceholder: 'Укажите причину',
          showClass: {
            popup: 'swal2-noanimation',
            backdrop: 'swal2-noanimation'
          },
          inputValidator: (value) => {
            if (!value) {
              return 'Пожалуйста, укажите причину'
            }
          }
        })
        .then((responseReason) => {
          if (responseReason.isConfirmed && responseReason.value) {
            this.getRefundCount().then((value) => {
              swalWithBootstrapButtons
                .fire({
                  title: `Вам будет возвращено ${value} рублей за занятия`,
                  showCancelButton: true,
                  confirmButtonText: 'Продолжить',
                  cancelButtonText: 'Отмена',
                  reverseButtons: true,
                  showClass: {
                    popup: 'swal2-noanimation',
                    backdrop: 'swal2-noanimation'
                  }
                })
                .then((r) => {
                  if (r.value) {
                    this.createRefundRequest(responseReason.value)
                  }
                })
            })
          }
        })
    },

    /**
     * Получить сумму, которая будет возвращена студенту при возврате средств
     * @returns {Promise<unknown>}
     */
    getRefundCount() {
      return new Promise((resolve, reject) => {
        axios({
          url: `${domain}/${MicroServices[0].prefix}/StudentRefundRequest/getSumToRefund`,
          method: 'GET'
        })
          .then((response) => {
            resolve(response.data.data.sum)
          })
          .catch((error) => {
            reject(this.errorAlert(error))
          })
      })
    },

    /**
     * Отправить запрос на возврат средств
     * @param value
     */
    createRefundRequest(value) {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/StudentRefundRequest/create`,
        method: 'POST',
        data: [
          {
            reason: value
          }
        ]
      })
        .then(() => {
          this.successAlert('Заявка на возврат принята, ждите связи с менеджером')
          this.getLessonsCount()
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    }
  },
  watch: {}
}
</script>

<style scoped lang="scss">
@import '../../styleVariables';

.dashboard {
  background-color: $main-white;
  box-shadow: 1px 0px 20px -7px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  max-width: 1004px;
  width: 100%;
  margin: 2px auto 10px auto;
  padding: 24px 21px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .lesson-amount {
    display: flex;
    align-items: center;
    .label {
      font-size: 14px;
      line-height: 24px;
      color: $main-gray;
      margin-right: 18px;
    }
    img {
      margin-right: 9px;
    }
    .amount {
      font-size: 14px;
      line-height: 24px;
      font-family: 'Roboto', sans-serif;
      color: $main-dark;
    }
  }
  .buttons {
    button {
      font-size: 14px;
      line-height: 24px;
      width: 184px;
      height: 32px;
      border-radius: 4px;
    }
    .return-cash {
      background-color: $main-white;
      color: $bright-blue;
      border: 1px solid $bright-blue;
    }

    .to-packs {
      background-color: $bright-blue;
      color: $main-white;
      margin-right: 15px;
    }
  }
}

.main-container {
  font-family: $font-regular, sans-serif !important;
  background-color: $background-blue !important;
  .search-filters-widgets {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
  }
  .widgets-filters {
    display: flex;
    .add-button {
      /deep/ button.fill {
        background-color: $bright-blue;
        width: 42px !important;
        height: 42px;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
        border-radius: 43px;
        &:active {
          background-color: $hover-bright-blue;
        }
      }
    }
  }
  .card-header {
    padding-bottom: 0 !important;
  }
  .card {
    background-color: unset !important;
    border: none !important;
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  .table-container {
    width: 1024px;
    margin: 0 auto;
    overflow: hidden;
    height: 100%;
    padding-top: 24px;
  }
  .filter {
    //border-left: 1px solid $nav-lines;
    min-height: 100%;
    //background: $main-white;
    //z-index: 10;
    margin-left: -10px;
    /deep/ .form-row {
      display: block;
      margin-left: 0 !important;
    }
    .filters {
      width: 220px;
    }
  }
  .wide-table {
    width: 100%;
    overflow: auto;
  }
  .hide-filter {
    display: none;
  }
  .wide-container {
    width: 100% !important;
  }
}
/deep/ .col {
  //padding-left: 0 !important;
  padding: 5px !important;
  display: flex;
  align-items: flex-end;
  .filters {
    margin-right: 20px;
  }
}
/deep/ table.b-table-selectable > tbody > tr.b-table-row-selected > td,
table.b-table-selectable > tbody > tr.b-table-row-selected > th {
  //background-color: $btn-hover-background !important;
}
/deep/ .table-row {
  overflow-wrap: break-word;
}
.loaderPage {
  text-align-last: center;
  margin-top: 20%;
}
.table-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
#cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 8;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#cover-spin::after {
  content: '';
  display: block;
  position: absolute;
  left: 48%;
  top: 40%;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: black;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
}

.nameTable {
  font-family: $font-bold;
  font-size: 24px;
  line-height: 30px;
  color: $main-dark;
  margin-bottom: 10px;
  margin-left: 10px;
  width: auto;
  display: inline;
}
/deep/#app-layout-navbar {
  display: block !important;
  font-size: 16px;
  line-height: 19px;
  margin: 24px 0 12px 24px;
  width: 50%;
}
.breadcrumbs {
  //color: $main-blue;
}
.collapse:not(.show) {
  display: block;
}
.form-row {
  //display: unset !important;
  margin: 0 0 0 6px !important;
}

@media screen and (max-width: 680px) {
  .main-container {
    .table-container {
      .table-header {
        .search-filters-widgets {
          display: none;
        }
        .dashboard {
          margin: 0 auto;
          flex-direction: column;
          width: fit-content;
          .lesson-amount {
            margin-bottom: 24px;
          }
        }
      }
      /deep/ .main-table-container {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 460px) {
  .main-container {
    .table-container {
      .table-header {
        .dashboard {
          width: auto;
          margin: 0 16px;
          .buttons {
            display: flex;
            flex-direction: column;
            width: 100%;
            button {
              width: 100%;
            }
            .to-packs {
              margin-bottom: 8px;
            }
          }
        }
      }
    }
  }
}
</style>
