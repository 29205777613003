<template>
  <div class="personal-card">
    <!--    <div class="avatar">-->
    <!--      <img src="../../../assets/img/ES/avatar.png" alt="avatar" />-->
    <!--    </div>-->
    <div class="mobile-edit-controls" v-show="editMode">
      <img
        @click="save"
        class="save"
        src="../../../assets/img/ES/mobile_edit_check.svg"
        alt="save"
      />
      <img @click="cancel" src="../../../assets/img/ES/mobile_edit_cross.svg" alt="close" />
    </div>
    <div class="personal-info">
      <div class="personal-info-header" :class="{ 'no-margin': editMode }">
        <div class="personal-info-header-style">
          <div
            class="personal-info-header__name"
            :style="
              $cookie.get('internalName') === 'student'
                ? 'width: fit-content; max-width: 420px'
                : ''
            "
            v-if="!editMode"
          >
            {{ info.full_name }}
          </div>
          <div
            class="personal-info-header__name"
            v-if="editMode"
            :class="{ 'no-margin': editMode }"
          >
            <div class="name-input">
              <div class="mobile-label">Фамилия</div>
              <input
                type="text"
                maxlength="25"
                v-model="last_name"
                :style="!isValid && !last_name ? 'border-color: #E13429' : ''"
              />
            </div>
            <div class="name-input">
              <div class="mobile-label">Имя</div>
              <input
                type="text"
                maxlength="25"
                v-model="first_name"
                :style="!isValid && !first_name ? 'border-color: #E13429' : ''"
              />
            </div>
            <div class="name-input">
              <div class="mobile-label">Отчество</div>
              <input
                type="text"
                maxlength="25"
                v-model="middle_name"
                :style="!isValid && !middle_name ? 'border-color: #E13429' : ''"
              />
            </div>
          </div>
          <div
            class="personal-info-header__balance"
            v-if="(type === 'teacher' || type === 'methodologist') && !editMode"
          >
            <img src="../../../assets/img/ES/coin.svg" alt="coin" />
            <span>{{ info.balance }} рублей</span>
          </div>
          <div class="personal-info-header__lessons" v-if="type === 'student' && !editMode">
            <span>{{ `${lessonCount} ${endingOfWord(lessonCount)}` }} </span>
          </div>
          <img
            src="../../../assets/img/ES/error_outline.svg"
            alt="err"
            v-if="!info.is_confirmed && !editMode"
            class="no-confirmed"
            v-b-tooltip="'Ваш аккаунт еще не подтвердили. Ждите связи с менеджером'"
          />
        </div>
        <div v-if="!editMode" class="personal-info-header__edit" @click="setEditMode">
          <img src="../../../assets/img/ES/edit.svg" alt="edit" />
          <span>Изменить</span>
        </div>
        <div v-if="editMode" class="personal-info-header__edit">
          <span class="save" @click="save">Сохранить</span>
          <span class="cancel" @click="cancel">Отменить</span>
        </div>
      </div>
      <div class="personal-info-content">
        <div class="personal-info-content__item" :class="{ 'no-mobile-edit': editMode }">
          <div class="item-name">Role</div>
          <div class="item-content">
            <div class="no-edit">
              <span>{{ info.role[0].toUpperCase() + info.role.slice(1) }}</span>
            </div>
          </div>
        </div>
        <div class="personal-info-content__item">
          <div class="item-name" :class="{ 'mobile-label': editMode }">Time Zone</div>
          <div class="item-content">
            <div class="no-edit" v-if="!editMode">
              <span>{{ getNameOfTimeZone(info.time_zone).name }}</span>
            </div>
            <div class="edit" v-else>
              <vue-select
                v-model="editedTimeZone"
                :clearable="false"
                :options="timeZoneList"
                :searchable="false"
                :filterable="false"
                :multiple="false"
              >
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    <span>{{ option.name }}</span>
                  </div>
                </template>
                <template slot="selected-option">
                  <div class="selected d-center">
                    {{ editedTimeZone.name }}
                  </div>
                </template>
              </vue-select>
            </div>
          </div>
        </div>
        <div
          class="personal-info-content__item"
          :class="{ 'no-mobile-edit': editMode }"
          v-if="type === 'teacher' || type === 'student'"
        >
          <div class="item-name">Stage</div>
          <div class="item-content">
            <div class="no-edit">
              <span>{{ userStage }}</span>
            </div>
          </div>
        </div>
        <div
          class="personal-info-content__item"
          :class="{ 'no-mobile-edit': editMode }"
          v-if="type === 'teacher'"
        >
          <div class="item-name">Level</div>
          <div class="item-content">
            {{
              info.level
                ? `${info.level.name} / ${info.europe_level ? info.europe_level.name : '-'}`
                : '-'
            }}
          </div>
        </div>
        <div
          class="personal-info-content__item"
          v-if="type === 'teacher' || type === 'methodologist'"
        >
          <div class="item-name" :class="{ 'mobile-label': editMode }">Zoom</div>
          <div class="item-content">
            <div class="no-edit" v-if="!editMode">
              <span>{{ info.link_to_zoom }}</span>
            </div>
            <div class="edit" v-if="editMode">
              <input
                v-model="link"
                type="text"
                :style="!isValid && !link ? 'border-color: #E13429' : ''"
              />
            </div>
          </div>
        </div>
        <div class="personal-info-content__item">
          <div class="item-name" :class="{ 'mobile-label': editMode }">E-mail</div>
          <div class="item-content">
            <div class="no-edit" v-if="!editMode">
              <span>{{ info.email }}</span>
            </div>
            <div class="edit" v-if="editMode">
              <input
                v-model="email"
                type="text"
                :style="!isValid && !email ? 'border-color: #E13429' : ''"
              />
            </div>
          </div>
        </div>
        <div class="personal-info-content__item">
          <div class="item-name" :class="{ 'mobile-label': editMode }">Phone number</div>
          <div class="item-content">
            <div class="no-edit" v-if="!editMode">
              <span>{{ info.phone }}</span>
            </div>
            <div class="edit" v-if="editMode">
              <input
                v-mask="phoneMask"
                v-model="phone"
                type="text"
                :style="!isValid && !phone ? 'border-color: #E13429' : ''"
              />
            </div>
          </div>
        </div>
        <div class="personal-info-content__item" v-if="type === 'student'">
          <div class="item-name" :class="{ 'mobile-label': editMode }">Birthday date</div>
          <div class="item-content">
            <div class="no-edit" v-if="!editMode">
              <span>{{ info.birthday_date || '-' }}</span>
            </div>
            <div class="edit" v-if="editMode">
              <input v-mask="dateMask" v-model="birthday" type="text" />
            </div>
          </div>
        </div>
        <div
          class="personal-info-content__item"
          :class="{ 'no-mobile-edit': editMode }"
          v-if="type === 'student'"
        >
          <div class="item-name">Level</div>
          <div class="item-content">
            {{ info.level ? info.level.name : '-' }}
          </div>
        </div>
        <div
          class="personal-info-content__item"
          :class="{ 'no-mobile-edit': editMode }"
          v-if="type === 'student'"
        >
          <div class="item-name">CEFR</div>
          <div class="item-content">
            {{ info.europe_level ? info.europe_level.name : '-' }}
          </div>
        </div>
        <div
          class="personal-info-content__item"
          :class="{ 'no-mobile-edit': editMode }"
          v-if="type === 'student'"
        >
          <div class="item-name">Start date</div>
          <div class="item-content">
            {{
              `${new Date(info.created_at).getDate()}.${
                new Date(info.created_at).getMonth() + 1
              }.${new Date(info.created_at).getFullYear()}` || '-'
            }}
            <!--            {{ info.created_at }}-->
          </div>
        </div>
        <div
          class="personal-info-content__item"
          :class="{ 'no-mobile-edit': editMode }"
          v-if="type === 'student'"
        >
          <div class="item-name">Revision steps</div>
          <div class="item-content">
            {{ info.revision_steps || '-' }}
          </div>
        </div>
        <div
          class="personal-info-content__item"
          :class="{ 'no-mobile-edit': editMode }"
          v-if="type === 'student'"
        >
          <div class="item-name">The amount of new words</div>
          <div class="item-content">
            {{ info.words_amount }}
          </div>
        </div>
        <div
          class="personal-info-content__item"
          :class="{ 'no-mobile-edit': editMode }"
          v-if="type === 'student'"
        >
          <div class="item-name">Average speed</div>
          <div class="item-content">
            {{ info.average_speed }}
          </div>
        </div>
        <div
          class="personal-info-content__item"
          :class="{ 'no-mobile-edit': editMode }"
          v-if="type !== 'manager' && type !== 'admin'"
        >
          <div class="item-name">Manager name</div>
          <div class="item-content">
            {{ info.manager_full_name || '-' }}
          </div>
        </div>
        <div class="personal-info-content__password" v-show="editMode">
          <div
            class="change-password__label"
            @click="changePassword = true"
            v-show="!changePassword"
          >
            Изменить пароль
          </div>
          <div class="change-password__form" v-show="changePassword">
            <div class="form-name">Current password</div>
            <div class="form-input">
              <input
                :type="hideCurrentPassword ? 'password' : 'text'"
                v-model.trim="currentPassword"
                :class="{ 'error-input': currentPassword.length < 6 && currentPassword }"
                maxlength="16"
              />
              <img
                v-if="hideCurrentPassword"
                src="../../../assets/img/hide.svg"
                alt="show"
                @click="hideCurrentPassword = false"
              />
              <img
                v-else-if="!hideCurrentPassword"
                src="../../../assets/img/show.svg"
                alt="hide"
                @click="hideCurrentPassword = true"
              />
            </div>
          </div>
          <div class="change-password__form" v-show="changePassword">
            <div class="form-name">New password</div>
            <div class="form-input">
              <input
                maxlength="16"
                :type="hidePassword ? 'password' : 'text'"
                v-model.trim="password"
                :class="{ 'error-input': password.length < 6 && password }"
              />
              <img
                v-if="hidePassword"
                src="../../../assets/img/hide.svg"
                alt="show"
                @click="hidePassword = false"
              />
              <img
                v-else-if="!hidePassword"
                src="../../../assets/img/show.svg"
                alt="hide"
                @click="hidePassword = true"
              />
            </div>
          </div>
          <div class="change-password__form" v-show="changePassword">
            <div class="form-name">Repeat password</div>
            <div class="form-input">
              <input
                :type="hideRepeatPassword ? 'password' : 'text'"
                :class="{ 'error-input': repeatPassword.length < 6 && repeatPassword }"
                v-model.trim="repeatPassword"
                maxlength="16"
              />
              <img
                v-if="hideRepeatPassword"
                src="../../../assets/img/hide.svg"
                alt="show"
                @click="hideRepeatPassword = false"
              />
              <img
                v-else-if="!hideRepeatPassword"
                src="../../../assets/img/show.svg"
                alt="hide"
                @click="hideRepeatPassword = true"
              />
            </div>
          </div>
          <div class="change-password__form" v-show="changePassword">
            <div class="change-password__form-btns">
              <button class="cancel" @click="cancelChangePassword">Отменить</button>
              <button class="save" @click="changeUserPassword" :disabled="validPassword">
                Сохранить
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { domain, host, MicroServices } from '@/globalVariables'
import CryptoJS from 'crypto-js'
import VueSelect from 'vue-select'
import scriptMixins from '@/components/scriptMixins/scriptMixins'
const CryptoJSAesJson = {
  /**
   * Формирование объекта с данными зашифрованные в AES для отправки в backend
   * @param cipherParams
   * @return {string}
   */
  stringify(cipherParams) {
    let j = { data: cipherParams.ciphertext.toString(CryptoJS.enc.Base64) }
    if (cipherParams.iv) j.initVector = cipherParams.iv.toString()
    if (cipherParams.salt) j.salt = cipherParams.salt.toString()
    return JSON.stringify(j)
  },
  /**
   * Расшифровка объекта зашифрованным в AES пришедшая с backend для валидации timestamp
   * @param jsonStr
   * @return {CryptoJS.encrypt}
   */
  parse(jsonStr) {
    let j = JSON.parse(jsonStr)
    let cipherParams = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(j.data)
    })
    if (j.initVector) cipherParams.iv = CryptoJS.enc.Hex.parse(j.initVector)
    if (j.salt) cipherParams.salt = CryptoJS.enc.Hex.parse(j.salt)
    return cipherParams
  }
}
export default {
  name: 'PersonalCard',
  props: {
    info: Object,
    lessonCount: Number,
    type: String
  },
  components: { VueSelect },
  mixins: [scriptMixins],
  data() {
    return {
      link: null,
      email: null,
      phone: null,
      first_name: null,
      last_name: null,
      middle_name: null,
      birthday: null,
      editMode: false,
      isValid: true,
      timeStamp: null,
      hash: null,
      phoneMask: {
        mask: '+9 (999) 999 99-99',
        showMaskOnHover: false
      },
      dateMask: {
        mask: '99.99.9999',
        showMaskOnHover: false
      },
      changePassword: false,
      currentPassword: '',
      password: '',
      repeatPassword: '',
      hideCurrentPassword: true,
      hidePassword: true,
      hideRepeatPassword: true,
      userStage: null,
      timeZoneList: [
        {
          name: '(UTC-12:00) International Date Line West',
          value: -12
        },
        {
          name: '(UTC-11:00) Coordinated Universal Time-11',
          value: -11
        },
        {
          name: '(UTC-10:00) Hawaii',
          value: -10
        },
        {
          name: '(UTC-09:00) Alaska',
          value: -9
        },
        {
          name: '(UTC-08:00) Pacific Time (US, Canada)',
          value: -8
        },
        {
          name: '(UTC-07:00) Arizona',
          value: -7
        },
        {
          name: '(UTC-06:00) Central America',
          value: -6
        },
        {
          name: '(UTC-05:00) Indiana (East)',
          value: -5
        },
        {
          name: '(UTC-04:00) Atlantic Time (Canada)',
          value: -4
        },
        {
          name: '(UTC-03:00) Brasilia',
          value: -3
        },
        {
          name: '(UTC-02:00) Mid-Atlantic - Old',
          value: -2
        },
        {
          name: '(UTC-01:00) Azores',
          value: -1
        },
        {
          name: '(UTC) Edinburgh, London',
          value: 0
        },
        {
          name: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
          value: 1
        },
        {
          name: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
          value: 2
        },
        {
          name: '(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk',
          value: 3
        },
        {
          name: '(UTC+04:00) Samara, Ulyanovsk, Saratov',
          value: 4
        },
        {
          name: '(UTC+05:00) Yekaterinburg',
          value: 5
        },
        {
          name: '(UTC+06:00) Nur-Sultan (Astana)',
          value: 6
        },
        {
          name: '(UTC+07:00) Novosibirsk',
          value: 7
        },
        {
          name: '(UTC+08:00) Krasnoyarsk',
          value: 8
        },
        {
          name: '(UTC+09:00) Yakutsk',
          value: 9
        },
        {
          name: '(UTC+10:00) Canberra, Melbourne, Sydney',
          value: 10
        },
        {
          name: '(UTC+11:00) Vladivostok',
          value: 11
        },
        {
          name: '(UTC+12:00) Magadan',
          value: 12
        },
        {
          name: '(UTC+13:00) Samoa',
          value: 13
        }
      ],
      editedTimeZone: null
    }
  },
  mounted() {
    if (this.type === 'teacher' || this.type === 'student') {
      this.getUserStage()
    }
  },
  computed: {
    validation() {
      if (
        !this.first_name.length ||
        !this.last_name.length ||
        !this.email.length ||
        !this.phone.length
      ) {
        return true
      }
      if (this.type === 'teacher' || this.type === 'methodologist') {
        return !this.link.length
      }
      return false
    },
    validPassword() {
      return (
        this.password.length < 6 ||
        this.repeatPassword.length < 6 ||
        this.currentPassword.length < 6 ||
        this.password !== this.repeatPassword
      )
    }
  },
  methods: {
    /**
     * Получить текущую стадию пользователя
     */
    getUserStage() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/${
          this.type[0].toUpperCase() + this.type.slice(1)
        }/getCurrentStage`,
        method: 'GET'
      })
        .then((response) => {
          this.userStage = response.data.data.name
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Сохранить личную информацию
     */
    save() {
      if (!this.validation) {
        if (this.type === 'teacher') {
          axios({
            url: `${domain}/monolit/Teacher/update/${this.$cookie.get('id')}`,
            method: 'POST',
            data: [
              {
                firstname: this.first_name,
                lastname: this.last_name,
                middlename: this.middle_name,
                link_to_zoom: this.link,
                email: this.email,
                phone: this.phone,
                time_zone: this.editedTimeZone.value
              }
            ]
          })
            .then(() => {
              this.successAlert('Данные успешно обновлены!')
              this.$emit('update')
              this.editMode = false
            })
            .catch((error) => this.errorAlert(error))
        } else if (this.type === 'methodologist') {
          axios({
            url: `${domain}/monolit/Methodologist/update/${this.$cookie.get('id')}`,
            method: 'POST',
            data: [
              {
                firstname: this.first_name,
                lastname: this.last_name,
                middlename: this.middle_name,
                link_to_zoom: this.link,
                email: this.email,
                phone: this.phone,
                time_zone: this.editedTimeZone.value
              }
            ]
          })
            .then(() => {
              this.successAlert('Данные успешно обновлены!')
              this.$emit('update')
              this.editMode = false
            })
            .catch((error) => this.errorAlert(error))
        } else if (this.type === 'student') {
          axios({
            url: `${domain}/monolit/Student/update/${this.$cookie.get('id')}`,
            method: 'POST',
            data: [
              {
                firstname: this.first_name,
                lastname: this.last_name,
                middlename: this.middle_name,
                email: this.email,
                phone: this.phone,
                birthday_date: this.birthday,
                time_zone: this.editedTimeZone.value
              }
            ]
          })
            .then(() => {
              this.successAlert('Данные успешно обновлены!')
              this.$emit('update')
              this.editMode = false
            })
            .catch((error) => this.errorAlert(error))
        } else if (this.type === 'manager') {
          axios({
            url: `${domain}/monolit/Manager/update/${this.$cookie.get('id')}`,
            method: 'POST',
            data: [
              {
                firstname: this.first_name,
                lastname: this.last_name,
                middlename: this.middle_name,
                email: this.email,
                phone: this.phone,
                time_zone: this.editedTimeZone.value
              }
            ]
          })
            .then(() => {
              this.successAlert('Данные успешно обновлены!')
              this.$emit('update')
              this.editMode = false
            })
            .catch((error) => this.errorAlert(error))
        } else if (this.type === 'admin') {
          axios({
            url: `${domain}/monolit/Admin/update/${this.$cookie.get('id')}`,
            method: 'POST',
            data: [
              {
                firstname: this.first_name,
                lastname: this.last_name,
                middlename: this.middle_name,
                email: this.email,
                phone: this.phone,
                time_zone: this.editedTimeZone.value
              }
            ]
          })
            .then(() => {
              this.successAlert('Данные успешно обновлены!')
              this.$emit('update')
              this.editMode = false
            })
            .catch((error) => this.errorAlert(error))
        }
      } else {
        this.isValid = false
      }
      this.$root.$emit('updatePersonalCard', this.editedTimeZone.value)
    },

    /**
     * Отменить редактирование личной информации
     */
    cancel() {
      this.editMode = false
      this.link = this.info.link_to_zoom
      this.phone = this.info.phone
      this.email = this.info.email
      this.first_name = this.info.firstname
      this.last_name = this.info.lastname
      this.middle_name = this.info.middlename
      this.editedTimeZone = this.getNameOfTimeZone(this.info.time_zone)
    },

    /**
     * Установить редактирование личной информации
     */
    setEditMode() {
      this.link = this.info.link_to_zoom
      this.phone = this.info.phone
      this.email = this.info.email
      this.first_name = this.info.firstname
      this.last_name = this.info.lastname
      this.middle_name = this.info.middlename
      this.birthday = this.info.birthday_date
      this.editMode = true
      this.editedTimeZone = this.getNameOfTimeZone(this.info.time_zone)
    },

    /**
     * Отменить смену пароля
     */
    cancelChangePassword() {
      this.password = ''
      this.repeatPassword = ''
      this.currentPassword = ''
      this.hideCurrentPassword = true
      this.hidePassword = true
      this.hideRepeatPassword = true
      this.changePassword = false
    },

    /**
     * Поменять пароль
     */
    changeUserPassword() {
      axios({
        url: `${host.authUrl}/User/changeUserPassword`,
        method: 'POST',
        data: [
          {
            previous_password: CryptoJS.SHA256(
              CryptoJS.MD5(this.currentPassword).toString()
            ).toString(),
            new_password: CryptoJS.SHA256(CryptoJS.MD5(this.password).toString()).toString()
          }
        ]
      })
        .then(() => {
          this.successAlert('Изменения успешно сохранены')
          this.$store.dispatch('logout')
          this.reauth()
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },
    getHash(key, msg) {
      return CryptoJS.AES.encrypt(JSON.stringify(msg), key, { format: CryptoJSAesJson }).toString()
    },
    getNowTimeStamp() {
      let x = new Date()
      let y = x.toISOString()
      return Math.round(new Date(y).getTime() / 1000).toString()
    },

    /**
     * Реавторизация (необходима после смены пароля)
     */
    reauth() {
      this.timeStamp = this.getNowTimeStamp()
      this.hash = this.getHash(
        CryptoJS.SHA256(CryptoJS.MD5(this.password).toString()).toString(),
        this.timeStamp
      )
      axios({
        url: `${host.authUrl}/User/login`,
        method: 'POST',
        data: [
          {
            email: this.email,
            data: this.hash
          }
        ]
      }).then(() => {
        this.$cookie.set(
          'mandate',
          JSON.stringify({
            email: this.email,
            data: this.hash
          })
        )
        window.location.reload()
      })
    },

    /**
     * Получить название тайм зоны
     * @param utc
     * @returns {{name: string, value: number} | {name: string, value: number} | {name: string, value: number} | {name: string, value: number} | {name: string, value: number}}
     */
    getNameOfTimeZone(utc) {
      return this.timeZoneList.find((item) => item.value === Number(utc))
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../styleVariables';
.personal-card {
  box-shadow: 1px 0px 20px -7px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  padding: 22px 24px;
  //min-height: 257px;
  background-color: $main-white;

  //display: flex;
  color: $main-dark;
  .avatar {
    img {
      width: 135px;
      height: 135px;
      border-radius: 50%;
      object-fit: cover;
    }
    margin-right: 34px;
  }
  .mobile-edit-controls {
    display: none;
  }
  .personal-info {
    width: 100%;
    &-header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 14px;
      width: 100%;
      .personal-info-header-style {
        display: flex;
        align-items: center;
      }
      &__name {
        font-size: 18px;
        margin-right: 16px;
        display: flex;
        flex-direction: column;
        max-width: 260px;
        .mobile-label {
          display: none;
        }
        input {
          width: 220px;
          height: 25px;
          border: 0.5px solid #c5cee0;
          border-radius: 4px;
          padding: 3px 8px;
          margin-bottom: 6px;
          font-family: $font-regular;

          &:focus {
            outline: none;
            border-color: $bright-blue;
          }
        }
      }
      &__balance {
        padding: 4px 11px;
        background: rgba(75, 97, 173, 0.13);
        border-radius: 5px;
        display: flex;
        align-items: center !important;
        img {
          width: 24px;
          margin-right: 4px;
        }
        span {
          color: $main-dark;
          font-size: 14px;
        }
      }
      &__lessons {
        background-color: $lesson-ended-tag;
        padding: 5px 10px;
        border-radius: 5px;
        span {
          color: $main-dark;
          font-size: 14px;
          line-height: 24px;
        }
      }
      .no-confirmed {
        align-self: center;
        margin-left: 12px;
        cursor: pointer;
      }
      &__edit {
        cursor: pointer;
        img {
          margin-right: 5px;
          width: 22px;
        }
        span {
          font-size: 14px;
        }
        .save {
          margin-right: 6px;
          color: $bright-blue;
        }
        .cancel {
          color: #8f9bb3;
        }
      }
    }

    &-content {
      &__item {
        display: grid;
        grid-template-columns: 150px 432px;
        grid-column-gap: 12px;
        grid-template-rows: 32px;

        .item-name {
          font-size: 14px;
          align-self: center;
          line-height: 12px;
        }
        .item-content {
          font-family: $font-regular;
          font-size: 14px;
          align-self: center;
          word-break: break-word;
          .no-edit {
            display: flex;
            align-items: center;
            justify-content: space-between;
            span {
              font-family: $font-regular;
              height: fit-content;
            }
            img {
              width: 15px;
              cursor: pointer;
            }
          }
          .edit {
            display: flex;
            img {
              width: 15px;
              margin-top: -2px;
              cursor: pointer;
            }
            .save {
              margin-left: -20px;
              color: $bright-blue;
            }
            input {
              width: 100%;
              height: 25px;
              border: 0.5px solid #c5cee0;
              border-radius: 4px;
              padding: 3px 8px;
              font-family: $font-regular;

              &:focus {
                outline: none;
                border-color: $bright-blue;
              }
            }
            /deep/ .v-select {
              width: 100%;
            }
          }
        }
      }
      &__password {
        margin-top: 24px;
        .change-password__label {
          color: $bright-blue;
          cursor: pointer;
          text-align: center;
        }
        .change-password__form {
          display: grid;
          grid-template-columns: 150px 290px;
          grid-column-gap: 12px;
          grid-template-rows: 32px;

          .form-name {
            font-size: 14px;
            align-self: center;
            line-height: 12px;
          }
          .form-input {
            font-family: $font-regular;
            font-size: 14px;
            align-self: center;
            input {
              width: 287px;
              height: 25px;
              border: 0.5px solid #c5cee0;
              border-radius: 4px;
              padding: 3px 32px 3px 8px;
              font-family: $font-regular;

              &:focus {
                outline: none;
                border-color: $bright-blue;
              }
            }
            .error-input {
              border-color: $red !important;
            }
            img {
              cursor: pointer;
              position: absolute;
              margin-left: -32px;
            }
          }
          &-btns {
            grid-column-start: 2;
            grid-column-end: 3;
            margin-top: 12px;
            display: flex;
            justify-content: space-between;
            button {
              width: 134px;
              height: 32px;
              border-radius: 4px;
            }

            .save {
              background-color: $bright-blue;
              color: $main-white;
              &:active {
                background-color: #0e52d7;
              }
              &:disabled {
                background-color: $light-gray;
              }
            }
            .cancel {
              background-color: $main-white;
              color: $bright-blue;
              border: 1px solid $bright-blue;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 680px) {
  .personal-card {
    .mobile-edit-controls {
      display: flex;
      justify-content: flex-end;
      background-color: $bright-blue;
      margin: -22px -24px 20px;
      padding: 8px 16px;
      .save {
        margin-right: 8px;
      }
    }

    .no-margin {
      margin-bottom: 0;
    }
    .personal-info-header {
      &__edit {
        span {
          display: none;
        }
      }
      .personal-info-header-style {
        display: grid;
        width: 100%;
      }
      &__lessons,
      &__balance {
        grid-row-start: 1;
        grid-row-end: 2;
        width: fit-content;
        margin-bottom: 12px;
      }
      .no-margin {
        margin-bottom: 0;
      }
      &__name {
        grid-row-start: 2;
        grid-row-end: 3;
        margin-bottom: 16px;
        margin-right: 0;
        width: 100%;
        .mobile-label {
          display: block;
          font-size: 16px;
          line-height: 20px;
          font-family: $font-regular;
          margin-bottom: 7px;
        }
        input {
          height: 40px;
          width: 100%;
          margin-bottom: 14px;
          font-size: 16px;
        }
      }
    }
    .personal-info-content {
      .no-mobile-edit {
        display: none;
      }
      &__item {
        display: block;
        margin-bottom: 14px;
        .item-name {
          margin-bottom: 2px;
        }

        .mobile-label {
          display: block;
          font-size: 16px;
          line-height: 20px;
          font-family: $font-regular;
          margin-bottom: 7px;
        }
        .item-content {
          .edit {
            input {
              height: 40px;
              width: 100%;
              font-size: 16px;
            }
            /deep/ .vs__dropdown-toggle {
              min-height: 40px;
            }
            /deep/ .selected {
              font-size: 16px;
              font-family: $font-regular;
            }
          }
        }
      }
      &__password {
        .change-password {
          &__form {
            display: block;
            .form-name {
              font-size: 16px;
              line-height: 20px;
              font-family: $font-regular;
              margin-bottom: 7px;
            }
            .form-input {
              input {
                height: 40px;
                width: 100%;
                margin-bottom: 14px;
                font-size: 16px;
              }
              img {
                position: relative;
                left: 86%;
                top: -46px;
                margin-left: 0;
              }
            }
            &-btns {
              flex-direction: column;
              button {
                width: 100%;
                height: 40px;
              }
              .cancel {
                margin-bottom: 12px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
