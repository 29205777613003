import { render, staticRenderFns } from "./Dialogue.vue?vue&type=template&id=da694598&scoped=true&"
import script from "./Dialogue.vue?vue&type=script&lang=js&"
export * from "./Dialogue.vue?vue&type=script&lang=js&"
import style0 from "./Dialogue.vue?vue&type=style&index=0&id=da694598&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da694598",
  null
  
)

export default component.exports