var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"myModalRef",attrs:{"id":'modal' + (_vm.id || ''),"hide-footer":"","hide-header":"","size":_vm.lg},on:{"hidden":function($event){return _vm.clearStore()}}},[_c('div',{staticClass:"title"},[_c('span',[_vm._v("Создание нового пользователя")])]),_vm._v(" "),_c('span',{staticClass:"required-input"},[_vm._v("* Обязательное поле")]),_vm._v(" "),_c('b-form-row',{class:{ 'lg-modal': _vm.lg }},[_c('b-form-group',{staticStyle:{"width":"100%"}},_vm._l((_vm.data),function(rec,index){return _c('div',{key:index},[(rec === _vm.data.fields || rec === _vm.data[0])?_c('div',{staticClass:"col",attrs:{"label":rec.type}},_vm._l((_vm.fields),function(field){return _c('div',{key:field.key},[(
                !Array.isArray(rec[field.key]) &&
                typeof rec[field.key] === 'object' &&
                rec[field.key] !== null &&
                rec[field.key].value !== 'save' &&
                rec[field.key].value !== 'saveAndAdd' &&
                rec[field.key].value !== 'editAll'
              )?_c('Widget',_vm._b({key:field.key,staticStyle:{"margin-bottom":"20px"},style:(rec[field.key].type !== 'Button'
                  ? 'width: 100%'
                  : _vm.lg
                  ? 'margin-bottom: 30px'
                  : 'margin-bottom: 5px'),attrs:{"type":rec[field.key].type},on:{"$emits":_vm.$emits}},'Widget',_vm.renderBindWidget(field.key, _vm.data.fields),false)):(
                rec[field.key] &&
                _vm.isJsonString(rec[field.key]) &&
                typeof JSON.parse(rec[field.key]) === 'object'
              )?_c('div',{key:field.key},[_c('Widget',_vm._b({key:field.key,staticStyle:{"width":"100%"},attrs:{"type":"json"},on:{"$emits":_vm.$emits}},'Widget',{ item: JSON.parse(rec[field.key]), isModal: true },false))],1):_c('div',{staticClass:"view"},[_c('div',{staticClass:"view-item",domProps:{"innerHTML":_vm._s(_vm.renderValue(field.label, rec[field.key], rec))}})])],1)}),0):_vm._e()])}),0)],1),_vm._v(" "),_c('div',{staticClass:"modal-footer"},[_c('div',[_c('div',{staticClass:"save-and-add-btn"},_vm._l((_vm.data),function(rec,index){return _c('div',{key:index},[(rec === _vm.data.fields || rec === _vm.data[0])?_c('div',{staticClass:"col",attrs:{"label":rec.type}},_vm._l((_vm.fields),function(field){return _c('div',{key:field.key},[(
                  !Array.isArray(rec[field.key]) &&
                  typeof rec[field.key] === 'object' &&
                  rec[field.key] !== null &&
                  rec[field.key].value === 'saveAndAdd'
                )?_c('Widget',_vm._b({key:field.key,attrs:{"type":rec[field.key].type},on:{"$emits":_vm.$emits}},'Widget',_vm.renderBindWidget(field.key, _vm.data.fields),false)):_vm._e()],1)}),0):_vm._e()])}),0)]),_vm._v(" "),_c('div',{staticClass:"control-btns"},[_c('div',[_c('button',{staticClass:"cancel-btn",on:{"click":function($event){_vm.$bvModal.hide('modal' + (_vm.id || ''))}}},[_vm._v("Отмена")])]),_vm._v(" "),_c('div',{staticClass:"save-btn"},_vm._l((_vm.data),function(rec,index){return _c('div',{key:index},[(rec === _vm.data.fields || rec === _vm.data[0])?_c('div',{staticClass:"col",attrs:{"label":rec.type}},_vm._l((_vm.fields),function(field){return _c('div',{key:field.key},[(
                  !Array.isArray(rec[field.key]) &&
                  typeof rec[field.key] === 'object' &&
                  rec[field.key] !== null &&
                  rec[field.key].value === 'save'
                )?_c('Widget',_vm._b({key:field.key,attrs:{"type":rec[field.key].type},on:{"$emits":_vm.$emits}},'Widget',_vm.renderBindWidget(field.key, _vm.data.fields),false)):_vm._e()],1)}),0):_vm._e()])}),0),_vm._v(" "),_c('div',{staticClass:"edit-all-btn"},_vm._l((_vm.data),function(rec,index){return _c('div',{key:index},[(rec === _vm.data.fields || rec === _vm.data[0])?_c('div',{staticClass:"col",attrs:{"label":rec.type}},_vm._l((_vm.fields),function(field){return _c('div',{key:field.key},[(
                  !Array.isArray(rec[field.key]) &&
                  typeof rec[field.key] === 'object' &&
                  rec[field.key] !== null &&
                  rec[field.key].value === 'editAll'
                )?_c('Widget',_vm._b({key:field.key,attrs:{"type":rec[field.key].type},on:{"$emits":_vm.$emits}},'Widget',_vm.renderBindWidget(field.key, _vm.data.fields),false)):_vm._e()],1)}),0):_vm._e()])}),0)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }