<template>
  <div class="teacher-profile">
    <div class="left-side">
      <div class="personal-card">
        <PersonalCard
          @update="
            getTeacherInfo()
            getTeacherLessons()
          "
          :info="teacherInfo"
          type="teacher"
        />
      </div>
      <div class="non-working-days">
        <NonWorkingDays :days="nonWorking" :uuid="$cookie.get('uid')" />
      </div>
      <div class="achievements">
        <Achievements
          @update="getTeacherFiles"
          :files="achievements"
          :count="achievementsCount"
          :uuid="$cookie.get('uid')"
        />
      </div>
    </div>
    <div class="right-side">
      <div class="personal-schedule">
        <PersonalSchedule :lessons="lessons" type="teacher" />
      </div>
    </div>
  </div>
</template>

<script>
import PersonalCard from '@/views/Profile/components/PersonalCard'
import NonWorkingDays from '@/views/Profile/components/NonWorkingDays'
import Achievements from '@/views/Profile/components/Achievements'
import PersonalSchedule from '@/views/Profile/components/PersonalSchedule'

import { domain } from '@/globalVariables'
import axios from 'axios'
export default {
  name: 'TeacherProfile',
  components: { PersonalSchedule, Achievements, NonWorkingDays, PersonalCard },
  data() {
    return {
      nonWorking: [],
      teacherInfo: {},
      achievements: [],
      achievementsCount: null,
      lessons: []
    }
  },
  mounted() {
    this.getTeacherInfo()
    this.getTeacherFiles()
    this.getTeacherNonWorking()
    this.getTeacherLessons()
  },
  methods: {
    /**
     * Получить личную информацию о текущем пользователе (учителе)
     */
    getTeacherInfo() {
      axios({
        url: `${domain}/monolit/Teacher/getItem/${this.$cookie.get('id')}`,
        method: 'GET'
      })
        .then((response) => {
          this.teacherInfo = response.data.data
          this.$root.$emit('updateName', response.data.data.full_name)
          this.$cookie.set('userName', response.data.data.full_name)
        })
        .catch((error) => this.errorAlert(error))
    },

    /**
     * Получить личные достижения текущего пользователя (учителя)
     */
    getTeacherFiles() {
      axios({
        url: `${domain}/monolit/AchievementFile/getItems/`,
        method: 'GET',
        params: {
          user_uuid: this.$cookie.get('uid'),
          _count: 20
        }
      })
        .then((response) => {
          this.achievements = response.data.data.items
          this.achievementsCount = response.data.data.total_count
        })
        .catch((error) => this.errorAlert(error))
    },

    /**
     * Получит нерабочие дни текущего пользователя (учителя)
     */
    getTeacherNonWorking() {
      axios({
        url: `${domain}/monolit/Rest/getItems`,
        method: 'GET',
        params: {
          user_uuid: this.$cookie.get('uid')
        }
      })
        .then((response) => {
          this.nonWorking = response.data.data.items
        })
        .catch((error) => this.errorAlert(error))
    },

    /**
     * Получить расписание на день текущего пользователя
     */
    getTeacherLessons() {
      axios({
        url: `${domain}/monolit/Lesson/getLessonsForDay`,
        method: 'GET'
      }).then((response) => {
        this.lessons = response.data.data
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../styleVariables';
.teacher-profile {
  display: grid;
  grid-template-columns: 0.8fr 1fr;
  grid-column-gap: 28px;
  padding: 30px 24px;
  background-color: $background-blue;
  width: 100%;
  max-width: 1800px;
  margin: 0 auto;
  .right-side {
    //max-width: 844px;
    width: 100%;
  }
  .left-side {
    max-width: 740px;
    width: 100%;
    .personal-card {
      margin-bottom: 30px;
    }
    .non-working-days {
      margin-bottom: 30px;
    }
  }
}

@media screen and (max-width: 1830px) {
  .teacher-profile {
    grid-template-columns: 1fr;
    /deep/ .personal-info-header {
      &__name {
        max-width: unset !important;
      }
      .save {
        margin-right: 18px;
      }
    }
    .achievements {
      margin-bottom: 30px;
    }
    .right-side {
      max-width: unset;
    }
  }
}

@media screen and (max-width: 1040px) {
  .non-working-days {
    display: none;
  }
  .personal-schedule {
    display: none;
  }
  .teacher-profile {
    padding: 8px 16px;
  }
  .achievements {
    display: none;
  }
}
</style>
