<template>
  <b-navbar
    toggleable="lg"
    :variant="getLayoutNavbarBg()"
    class="layout-navbar align-items-lg-center container-p-x"
    :style="!isOpenMenu ? 'border-radius: 0px 0px 8px 8px' : ''"
  >
    <!-- Brand -->
    <!--    <b-navbar-brand to="/">-->
    <!--      <img-->
    <!--      :src="require(`../assets/img/icons/logo.svg`)"-->
    <!--      alt="icon"-->
    <!--      class="logo"-->
    <!--    />-->
    <!--    </b-navbar-brand>-->
    <div class="global-search">
      <b-form-group v-if="viewGlobalSearch">
        <input
          id="globalSearch"
          placeholder="Поиск по таблице"
          @keyup.enter="globalSearch($event)"
          style="width: 100%"
        />
        <img src="../assets/img/ES/gray_search.svg" alt="search" />
      </b-form-group>
    </div>

    <!--    Sidenav toggle-->
    <!--    <b-navbar-nav class="align-items-lg-center mr-auto mr-lg-4" v-if="sidenavToggle">-->
    <!--      <a-->
    <!--        class="nav-item nav-link px-0 ml-2 ml-lg-0"-->
    <!--        href="javascript:void(0)"-->
    <!--        @click="toggleSidenav"-->
    <!--      >-->
    <!--        <i class="ion ion-md-menu text-large align-middle" />-->
    <!--      </a>-->
    <!--    </b-navbar-nav>-->

    <!-- Navbar toggle -->

    <div class="mobile-navbar">
      <div class="toggle-sidenav">
        <img
          v-show="!isOpenMenu"
          src="../assets/img/ES/burger.svg"
          alt="menu"
          @click="toggleMenu(true)"
        />
        <img
          v-show="isOpenMenu"
          src="../assets/img/ES/close_menu.svg"
          alt="close"
          @click="toggleMenu(false)"
        />
        <img src="../assets/img/ES/mobile_logo.png" alt="logo" class="mobile-logo" />
      </div>
      <div class="user-time">
        <img src="../assets/img/ES/alarm.svg" alt="clock" />
        <span>{{ mobileUserTime }}</span>
      </div>
      <div class="navbar-elements">
        <a
          :href="telegramLink"
          class="telegram"
          v-if="
            $cookie.get('internalName') === 'teacher' ||
            $cookie.get('internalName') === 'methodologist' ||
            $cookie.get('internalName') === 'student'
          "
          target="_blank"
        >
          <img src="../assets/img/ES/logo_telegram.svg" alt="tlg" />
        </a>

        <div class="notifications-data" @click="$router.push('/monolit/Notifications')">
          <div class="notifications-count" v-show="notificationCount">{{ notificationCount }}</div>
          <img
            :src="
              notificationCount
                ? require(`../assets/img/ES/notification_count.svg`)
                : require(`../assets/img/notification.svg`)
            "
            alt="notifications"
            class="notifications"
          />
        </div>
      </div>
    </div>
    <!--    <b-navbar-toggle target="app-layout-navbar"></b-navbar-toggle>-->
    <b-collapse is-nav id="app-layout-navbar">
      <div class="user-time">
        <img src="../assets/img/ES/alarm.svg" alt="clock" />
        <span>{{ userTime }}</span>
      </div>
      <div class="navbar-nav align-items-lg-center ml-auto">
        <a
          :href="telegramLink"
          class="telegram"
          v-if="
            $cookie.get('internalName') === 'teacher' ||
            $cookie.get('internalName') === 'methodologist' ||
            $cookie.get('internalName') === 'student'
          "
          target="_blank"
        >
          <img src="../assets/img/ES/logo_telegram.svg" alt="tlg" />
        </a>
        <div class="notifications-data" @click="showNotification = !showNotification">
          <div class="notifications-count" v-show="notificationCount">{{ notificationCount }}</div>
          <img
            :src="
              notificationCount
                ? require(`../assets/img/ES/notification_count.svg`)
                : require(`../assets/img/notification.svg`)
            "
            alt="notifications"
            class="notifications"
          />
        </div>
        <NotificationPreview
          v-if="showNotification"
          :notifications="notifications"
          @closeNotifications="showNotification = false"
        />
        <div class="demo-navbar-user nav-item dropdown">
          <!--          <img class="avatar-user" :src="avatarUser" alt="avatar" />-->
          <!--          <span style="margin-right: 32px; color: #6a7e8e;">{{ currentName }}</span>-->
          <!--          <button type="button" class="logout-btn" @click="logout">Выход</button>-->
          <b-dropdown no-caret>
            <template v-slot:button-content>
              <button class="drop-content">
                <!--                <img-->
                <!--                  class="avatar-user"-->
                <!--                  :src="require(`../assets/img/avatar-sample.jpg`)"-->
                <!--                  alt="avatar"-->
                <!--                />-->
                <div>{{ `${userName} (${userRole})` }}</div>
                <svg
                  width="10"
                  height="6"
                  viewBox="0 0 10 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  :style="arrowPosition"
                >
                  <path
                    d="M8.1958 0.291505C8.52095 -0.033645 9.04795 -0.0341907 9.37378 0.290286C9.70055 0.615714 9.7011 1.1446 9.375 1.4707L5 5.8457L0.625001 1.4707C0.298897 1.1446 0.299445 0.615714 0.626223 0.290286C0.952046 -0.0341908 1.47905 -0.033645 1.8042 0.291505L5 3.4873L8.1958 0.291505Z"
                    fill="#8F9BB3"
                  />
                </svg>
              </button>
            </template>
            <b-dropdown-item href="#" @click="logout">Выход</b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </b-collapse>
  </b-navbar>
</template>

<script>
import authEvents from '../components/Core/KernelProject/authEvents'
import tableEvents from '../components/Core/KernelProject/tableEvents'
import Widget from '../components/Core/Widget'
import scriptMixins from '@/components/scriptMixins/scriptMixins'
import axios from 'axios'
import { domain, MicroServices } from '@/globalVariables'
import NotificationPreview from '@/views/Notifications/components/NotificationPreview'
import centrifuge from '@/centrifuge'
import AppLayoutSidenav from '@/layout/LayoutSidenav'
export default {
  name: 'app-layout-navbar',
  mixins: [authEvents, tableEvents, scriptMixins],
  components: {
    AppLayoutSidenav,
    NotificationPreview,
    Widget
  },
  props: {
    sidenavToggle: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      to: null,
      from: null,
      currentDate: '',
      currentName: '',
      arrowReverse: false,
      userName: '',
      telegramLink: null,
      showNotification: false,
      centrifugeNotification: null,
      token: null,
      channel: null,
      notificationCount: null,
      notifications: [],
      userTime: null,
      userTimeZone: null,
      mobileUserTime: null,
      isOpenMenu: false
    }
  },
  mounted() {
    this.$root.$on('updateName', (name) => {
      this.userName = name
    })
    this.userName = this.$cookie.get('userName')
    this.$root.$on('bv::dropdown::show', (event) => {
      this.arrowReverse = true
      this.$root.$emit('openDropDownList', event)
    })
    this.$root.$on('bv::dropdown::hide', () => {
      this.arrowReverse = false
    })
    if (
      this.$cookie.get('internalName') === 'teacher' ||
      this.$cookie.get('internalName') === 'methodologist' ||
      this.$cookie.get('internalName') === 'student'
    ) {
      this.getTelegramLink()
    }
    this.$root.$on('readNotifications', () => {
      this.getNotifications()
    })
    this.$root.$on('updatePersonalCard', (utc) => {
      this.userTimeZone = utc
      this.$cookie.set('userTimeZone', utc)
      this.setUserTime(utc)
    })
    this.$root.$on('toggleMenu', (flag) => {
      this.isOpenMenu = flag
    })
    this.getNotifications()
    this.getChannel()
    this.getUserTimeZone()
  },
  methods: {
    subscribeCentrifugeNotification() {
      centrifuge.setToken(this.token)
      this.centrifugeNotification = centrifuge.subscribe(`${this.channel}`, (message) => {
        if (message) {
          this.getNotifications()
        }
      })
    },
    getChannel() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/User/connectToCentrifuge`,
        method: 'GET'
      }).then((response) => {
        this.token = response.data.data.token
        this.channel = response.data.data.channel
        this.subscribeCentrifugeNotification()
      })
    },
    getNotifications() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/UserNotification/getItems`,
        method: 'GET',
        params: {
          _count: 5,
          is_read: false
        }
      })
        .then((response) => {
          this.notifications = response.data.data.items
          this.notificationCount = response.data.data.total_count
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },
    getTelegramLink() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Telegram/getUserBotLink`,
        method: 'POST'
      }).then((response) => {
        this.telegramLink = response.data.data
      })
    },
    toggleSidenav() {
      this.layoutHelpers.toggleCollapsed()
    },
    toggleMenu(flag) {
      this.$root.$emit('toggleMenu', flag)
      this.isOpenMenu = flag
    },

    getLayoutNavbarBg() {
      return this.layoutNavbarBg
    },

    checkBreadCrumbs(to) {
      this.$store.state.breadcrumbs = this.$store.state.breadcrumbs.slice(
        0,
        this.$store.state.breadcrumbs.indexOf(to)
      )
    },
    getUserTimeZone() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/User/getTimeZone`,
        method: 'GET'
      })
        .then((response) => {
          this.userTimeZone = response.data.data.time_zone
          this.$cookie.set('userTimeZone', this.userTimeZone)
          this.setUserTime(this.userTimeZone)
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },
    setUserTime(utc) {
      if (utc !== this.userTimeZone) {
        return null
      }
      let time = this.countUserTimeByTimeZone(utc)
      this.userTime = time.toLocaleString().slice(0, time.toLocaleString().length - 3)
      this.mobileUserTime = this.userTime.slice(11, this.userTime.length)
      setTimeout(() => {
        this.setUserTime(utc)
      }, 500)
    }
  },
  computed: {
    arrowPosition() {
      if (!this.arrowReverse) return 'transform: rotate(0deg);'
      else return 'transform: rotate(180deg);'
    },
    name() {
      return this.$cookie.get('userName')
    },
    userRole() {
      switch (this.$cookie.get('internalName')) {
        case 'teacher':
        case 'almost_teacher':
          return 'Преподаватель'
        case 'methodologist':
        case 'almost_methodologist':
          return 'Методист'
        case 'manager':
          return 'Менеджер'
        case 'admin':
          return 'Администратор'
        case 'student':
          return 'Студент'
      }
    }
  },
  watch: {
    $route(to, from) {
      this.to = to
      this.from = from
      let check = this.$store.state.breadcrumbs.indexOf(to)
      if (this.$store.state.viewBreadCrumbs === true) {
        if (this.$store.state.breadcrumbs.length >= 1 && check !== 0) {
          this.$store.state.viewBreadCrumbs = false
          this.$store.state.breadcrumbs = []
        } else {
          this.$store.state.breadcrumbs.push(from)
        }
      }
    },
    name() {
      this.userName = this.name
    }
  },
  beforeDestroy() {
    this.centrifugeNotification.unsubscribe()
  }
}
</script>
<style lang="scss">
@import '../components/Widgets/ColorVariables';
@import '../styleVariables.scss';

.mobile-navbar {
  display: none;
}

.btn-secondary {
  background-color: transparent !important;
}
.logo {
  height: auto;
  margin: 0 auto;
}
.mobile-logo {
  width: 24px;
}
.current-date,
.current-name {
  font-size: 18px;
  line-height: 21px;
  font-weight: 400;
  color: $link-gray;
}
.layout-navbar {
  height: 68px;
  border-bottom: 1px solid $light-gray;
  background-color: $background-blue;
  width: calc(100% - 248px);
  display: flex;
  justify-self: flex-end;
  margin-right: 0;
  margin-left: auto;
  align-items: center;
  z-index: 30 !important;
  //padding-top: 15px;
}
.logout-btn {
  border: 0.75px solid $button-gray;
  box-sizing: border-box;
  border-radius: 3px;
  height: 30px;
  color: $main-gray;
  background: $main-white;
  padding-left: 19px;
  padding-right: 19px;
  transition: all 0.2s ease;
}
.logout-btn:hover {
  border: 0.75px solid $main-blue;
  color: $main-blue;
}
.breadcrumbs {
  color: $main-blue;
}

.v-application ul {
  padding: 0 !important;
}
@media screen and (max-width: 991px) {
  .layout-navbar {
    background-color: $main-white;
    box-shadow: 1px 0px 20px -7px rgba(0, 0, 0, 0.16);
    border-bottom: none;
    height: 56px;
    padding: 8px 16px;
    margin-left: 0;
    width: 100%;
    z-index: 100;
  }
  .notifications-data {
    margin-right: 10px !important;
  }
  .user-time {
    span {
      font-size: 16px !important;
    }
  }
  .mobile-navbar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .navbar-elements {
      display: flex;
      align-items: center;
    }
  }
}
</style>
<style scoped lang="scss">
@import '../styleVariables.scss';
.notifications-count {
  color: $red;
  font-size: 14px;
  margin-right: 3px;
}

.notifications-data {
  margin-right: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.telegram {
  cursor: pointer;
  margin-right: 18px;
}
.user-time {
  display: flex;
  align-items: center;
  span {
    color: $main-dark;
    font-size: 18px;
  }
  img {
    margin-right: 6px;
  }
}
/deep/ {
  .dropdown-toggle {
    padding: 0;
    border: none;
    .drop-content {
      display: flex;
      background: transparent;
      justify-content: center;
      align-items: center;
      border: none;
      outline: none;
      font-style: normal;
      font-size: 14px;
      line-height: 16px;
      padding: 0;
      svg {
        transition: 0.2s;
        margin-left: 12px;
      }
      .avatar-user {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 12px;
      }
      div {
        font-size: 18px;
        line-height: 21px;
        color: #222b45;
      }
    }
  }
  .dropdown-toggle:focus {
    box-shadow: none;
  }
  .dropdown-menu {
    background: #ffffff;
    border-radius: 4px;
    width: 100%;
    z-index: 100 !important;
    top: 35px !important;
    li {
      border-bottom: 1px solid #eff2f7;
      .dropdown-item {
        color: #a5a5a5;
        font-style: normal;
        font-size: 14px;
        line-height: 16px;
      }
      .dropdown-item:not(.disabled).active,
      .dropdown-item:not(.disabled):active {
        background-color: #f3f5fa;
        color: #2281e8;
      }
    }
    li:last-child {
      border-bottom: none;
    }
  }
}
</style>
