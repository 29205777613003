<template>
  <div class="main-container">
    <div class="table-container" :class="{ 'wide-container': filterFields.length <= 2 }">
      <div id="table" :class="{ 'wide-table': filterFields.length <= 2 }">
        <div v-if="isLoadEndpoints" class="table-header">
          <!--          <p class="nameTable" v-if="isLoadEndpoints">{{ nameTable }}</p>-->
          <div class="search-filters-widgets">
            <div class="widgets-filters">
              <b-form-row :key="index" v-for="(rec, index) in data" class="widget">
                <div :label="rec.label">
                  <div :key="field.key" v-for="field in fields" class="widget__components">
                    <Widget
                      :class="{ 'add-button': field.key === 'add' }"
                      :key="field.key"
                      :type="rec[field.key].type"
                      @$emits="$emits"
                      v-bind="renderBindWidget(field.key, data.fields)"
                      v-if="typeof rec[field.key] === 'object'"
                    />
                    <span :key="field.key" v-else> {{ field.key }} : {{ rec[field.key] }} </span>
                  </div>
                </div>
              </b-form-row>

              <div class="filter" :class="{ 'hide-filter': filterFields.length <= 2 }">
                <div class="filter-width">
                  <div v-if="filterFields.length > 2">
                    <div>
                      <div class="card" v-if="filterFields.length > 2">
                        <div class="card-body" :class="{ spinner: !filter }">
                          <form v-if="filter">
                            <b-form-row>
                              <!--                  eslint-disable -->
                              <b-form-group
                                :key="index"
                                v-for="(rec, index) in filterData"
                                v-if="renderWidgetRec(rec, filterData)"
                              >
                                <!--                  eslint-enabled -->
                                <div :label="rec.label || ''" class="col">
                                  <div
                                    :key="field.key"
                                    v-for="field in filterFields"
                                    :class="{
                                      filters:
                                        field.key !== 'search_btn' && field.key !== 'clear_btn'
                                    }"
                                  >
                                    <Widget
                                      :key="field.key"
                                      :type="rec[field.key].type"
                                      @$emits="$emits"
                                      v-bind="renderBindWidget(field.key, rec)"
                                      v-if="typeof rec[field.key] === 'object'"
                                    />
                                  </div>
                                </div>
                              </b-form-group>
                            </b-form-row>
                          </form>
                          <form v-else>
                            <div class="spinner-border text-dark" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--    end filter    -->
                </div>
              </div>
            </div>

            <div class="percent">
              <div class="percent-container">
                <div class="label">Процент выплаты, если Студент пропустил занятие</div>
                <span v-show="!isEdit">{{ `${percent}%` }}</span>
                <input
                  :style="!editedPercent.toString().match(/^[\d.]*$/) ? 'border-color: red' : ''"
                  v-if="isEdit"
                  type="text"
                  v-model="editedPercent"
                />
              </div>
              <img
                @click="setIsEdit(true)"
                src="../../assets/img/ES/edit_requisites.svg"
                alt="edit"
                v-show="!isEdit"
              />
              <img
                @click="updateWagePercent()"
                src="../../assets/img/ES/black_check.svg"
                alt="save"
                v-show="isEdit"
              />
              <img
                @click="setIsEdit(false)"
                src="../../assets/img/ES/close_requisites.svg"
                alt="close"
                v-show="isEdit"
              />
            </div>

            <div class="teacher-rate">
              <div class="teacher-rate-container">
                <div class="label">Стандартная ставка преподавателей</div>
                <span v-show="!isEditTeacherRate">{{ standardTeacherRate }}</span>
                <input
                  :style="
                    !editedTeacherRate.toString().match(/^[\d.]*$/) ||
                    editedTeacherRate > 10000 ||
                    editedTeacherRate < 1
                      ? 'border-color: red'
                      : ''
                  "
                  v-if="isEditTeacherRate"
                  type="text"
                  v-model="editedTeacherRate"
                />
                <img src="../../assets/img/ES/rub.svg" alt="rub" />
              </div>
              <img
                @click="standardUserRateEdit('teacher', true)"
                src="../../assets/img/ES/edit_requisites.svg"
                alt="edit"
                v-show="!isEditTeacherRate"
              />
              <img
                :class="{ 'save-button--disabled': !selectedUsers.length }"
                @click="updateTeacherWageRate()"
                src="../../assets/img/ES/black_check.svg"
                alt="save"
                v-show="isEditTeacherRate"
              />
              <img
                @click="standardUserRateEdit('teacher', false)"
                src="../../assets/img/ES/close_requisites.svg"
                alt="close"
                v-show="isEditTeacherRate"
              />
            </div>

            <div class="methodologist-rate">
              <div class="methodologist-rate-container">
                <div class="label">Стандартная ставка методистов</div>
                <span v-show="!isEditMethodologistRate">{{ standardMethodologistRate }}</span>

                <input
                  :style="
                    !editedMethodologistRate.toString().match(/^[\d.]*$/) ||
                    editedMethodologistRate > 10000 ||
                    editedMethodologistRate < 1
                      ? 'border-color: red'
                      : ''
                  "
                  v-if="isEditMethodologistRate"
                  type="text"
                  v-model="editedMethodologistRate"
                />
                <img src="../../assets/img/ES/rub.svg" alt="rub" />
              </div>
              <img
                @click="standardUserRateEdit('methodologist', true)"
                src="../../assets/img/ES/edit_requisites.svg"
                alt="edit"
                v-show="!isEditMethodologistRate"
              />
              <img
                :class="{ 'save-button--disabled': !selectedUsers.length }"
                @click="updateMethodologistWageRate()"
                src="../../assets/img/ES/black_check.svg"
                alt="save"
                v-show="isEditMethodologistRate"
              />
              <img
                @click="standardUserRateEdit('methodologist', false)"
                src="../../assets/img/ES/close_requisites.svg"
                alt="close"
                v-show="isEditMethodologistRate"
              />
            </div>

            <div class="global-search" v-if="viewSearch">
              <input
                id="globalSearch"
                placeholder="Поиск по таблице"
                @keyup.enter="globalSearch($event)"
                style="width: 100%"
              />
              <img src="../../assets/img/ES/gray_search.svg" alt="search" />
            </div>
          </div>
        </div>
        <Table
          :currentStore="store"
          :dataTable="tableData.total_count"
          :fieldsTable="tableFields"
          :head-variant="'light'"
          :items="tableData.items"
          @$emits="$emits"
          :viewGlobalSearch="viewSearch"
          :multiSelect="multiSelect"
        />
      </div>
    </div>
    <div id="cover-spin" v-show="!loading"></div>
  </div>
</template>

<script>
import Table from './Table'
import eventDelegate from '../../components/Core/KernelProject/eventDelegate'
import Widget from '../../components/Core/Widget'
import renderWidget from '../../components/Core/KernelProject/renderWidget'
import authEvents from '../../components/Core/KernelProject/authEvents'
import viewsDefaultEvents from '../../components/Core/KernelProject/viewsDefaultEvents'
import tableEvents from '../../components/Core/KernelProject/tableEvents'
import { domain, MicroServices } from '@/globalVariables'
import axios from 'axios'
import Swal from 'sweetalert2'

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'confirm-button',
    cancelButton: 'delete-button',
    title: 'title-class',
    input: 'input-class'
  },
  buttonsStyling: false
})

export default {
  name: 'UserWageRate',
  components: {
    Widget,
    Table
  },
  mixins: [eventDelegate, renderWidget, authEvents, viewsDefaultEvents, tableEvents],
  data() {
    return {
      tableData: [],
      tableFields: [],
      store: null,
      route: null,
      viewSearch: null,
      multiSelect: null,
      loading: false,
      to: null,
      from: null,
      isModal: false,
      requisite: null,
      dashboardInfo: null,
      percent: null,
      selectedUsers: [],
      standardTeacherRate: null,
      standardMethodologistRate: null,
      isEdit: false,
      isEditTeacherRate: false,
      isEditMethodologistRate: false,
      editedPercent: null,
      editedTeacherRate: null,
      editedMethodologistRate: null
    }
  },
  mounted() {
    this.$root.$on('getFilterData', (item) => {
      this.getFilterData()
      // eslint-disable-next-line
    })
    // filter need
    this.$root.$on('filterSearch', (data) => {
      this.getDataWithFilter()
    })
    // filter clear
    this.$root.$on('filterClear', (data) => {
      let clear = true
      this.getDataPagination(clear)
      this.$root.$emit('getMetaData', true)
    })
    // filter clear
    this.$root.$on('switchFilter', (data) => {
      this.switchFilter()
    })
    // filter spinner => true
    this.$root.$on('filterSpinner', (data) => {
      setTimeout(() => {
        this.filter = true
      }, 500)
    })

    this.getWagePercent()
    this.getStandardMethodologistWageRate()
    this.getStandardTeacherWageRate()
  },
  methods: {
    /**
     * Получить процентную ставку
     */
    getWagePercent() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Classifier/getWagePercentForSkippedByStudentLesson`,
        method: 'GET'
      })
        .then((response) => {
          this.percent = response.data.data.percent
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Получить стандартную ставку преподавателей
     */
    getStandardTeacherWageRate() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/UserWageRate/getTeacherWageRate`,
        method: 'GET'
      })
        .then((response) => {
          this.standardTeacherRate = response.data.data.wage_rate
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Получить стандартную ставку методистов
     */
    getStandardMethodologistWageRate() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/UserWageRate/getMethodologistWageRate`,
        method: 'GET'
      })
        .then((response) => {
          this.standardMethodologistRate = response.data.data.wage_rate
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Редактировать процентную ставку
     * @returns {null}
     */
    updateWagePercent() {
      if (!this.editedPercent.toString().match(/^[\d.]*$/)) {
        return null
      }
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Classifier/changeWagePercentForSkippedByStudentLesson`,
        method: 'PUT',
        data: [
          {
            percent: this.editedPercent
          }
        ]
      })
        .then(() => {
          this.successAlert('Успешно обновлено!')
          this.setIsEdit(false)
          this.getWagePercent()
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Редактировать стандартную ставку преподавателей
     */
    updateTeacherWageRate() {
      if (
        !this.editedTeacherRate ||
        !this.selectedUsers.length ||
        this.editedTeacherRate > 10000 ||
        this.editedTeacherRate < 1 ||
        !this.editedTeacherRate.toString().match(/^[\d.]*$/)
      ) {
        return null
      }
      swalWithBootstrapButtons
        .fire({
          text: `Вы уверены в правильности введённых данных?`,
          showCancelButton: true,
          confirmButtonText: 'Да',
          cancelButtonText: 'Отмена',
          reverseButtons: true
        })
        .then((resp) => {
          if (resp.isConfirmed) {
            axios({
              url: `${domain}/${MicroServices[0].prefix}/UserWageRate/updateTeacherWageRate`,
              method: 'POST',
              data: [
                {
                  user_uuids: this.selectedUsers,
                  wage_rate: this.editedTeacherRate
                }
              ]
            })
              .then(() => {
                this.selectedUsers = []
                this.standardTeacherRate = this.editedTeacherRate
                this.standardUserRateEdit('teacher', false)
                this.successAlert('Успешно обновлено!')
              })
              .catch((error) => {
                this.errorAlert(error)
              })
          }
        })
    },

    /**
     * Редактировать стадартную ставку методистов
     * @return {null}
     */
    updateMethodologistWageRate() {
      if (
        !this.editedMethodologistRate ||
        !this.selectedUsers.length ||
        this.editedMethodologistRate > 10000 ||
        this.editedMethodologistRate < 1 ||
        !this.editedMethodologistRate.toString().match(/^[\d.]*$/)
      ) {
        return null
      }
      swalWithBootstrapButtons
        .fire({
          text: `Вы уверены, в правильности введённых данных?`,
          showCancelButton: true,
          confirmButtonText: 'Да',
          cancelButtonText: 'Отмена',
          reverseButtons: true
        })
        .then((resp) => {
          if (resp.isConfirmed) {
            axios({
              url: `${domain}/${MicroServices[0].prefix}/UserWageRate/updateMethodologistWageRate`,
              method: 'POST',
              data: [
                {
                  user_uuids: this.selectedUsers,
                  wage_rate: this.editedMethodologistRate
                }
              ]
            })
              .then(() => {
                this.selectedUsers = []
                this.standardMethodologistRate = this.editedMethodologistRate
                this.standardUserRateEdit('methodologist', false)
                this.successAlert('Успешно обновлено!')
              })
              .catch((error) => {
                this.errorAlert(error)
              })
          }
        })
    },

    /**
     * Установить редактирование ставки для пользователей с выбранной ролью
     * @param userRole
     * @param flag
     */
    standardUserRateEdit(userRole, flag) {
      this.multiSelect = flag
      if (userRole === 'teacher') {
        this.isEditTeacherRate = flag
        this.isEditMethodologistRate = false
        this.editedTeacherRate = this.standardTeacherRate
        if (flag) {
          this.$store.commit('newRec', {
            keyAdd: 'role_id',
            value: [4]
          })
        }
      } else {
        this.isEditMethodologistRate = flag
        this.isEditTeacherRate = false
        this.editedMethodologistRate = this.standardMethodologistRate
        if (flag) {
          this.$store.commit('newRec', {
            keyAdd: 'role_id',
            value: [3]
          })
        }
      }
      if (flag) {
        this.$store.state.relationsData[this.$route.params.prefix] = { UserWageRate: null }
        this.$store.state.relationsData[this.$route.params.prefix].UserWageRate = {
          WageRateRoles: null
        }
        this.$store.state.relationsData[this.$route.params.prefix]['UserWageRate'][
          'WageRateRoles'
        ] = [
          {
            created_at: '2021-01-26T07:00:28.000000Z',
            id: 3,
            internal_name: 'methodologist',
            name: 'Методист',
            updated_at: '2021-01-26T07:00:28.000000Z'
          },
          {
            created_at: '2021-01-26T07:00:28.000000Z',
            id: 4,
            internal_name: 'teacher',
            name: 'Преподаватель',
            updated_at: '2021-01-26T07:00:28.000000Z'
          }
        ]
        this.getDataWithFilter()
        this.$root.$on('rowsSelected', (data) => {
          this.selectedUsers = []
          data.forEach((item) => {
            this.selectedUsers.push(item.sync_uuid)
          })
        })
      } else {
        this.$store.commit('newRec', {
          keyAdd: 'role_id',
          value: '[3, 4]'
        })
        this.getDataWithFilter()
      }
    },

    /**
     * Установить редактирование процентной ставки
     * @param flag
     */
    setIsEdit(flag) {
      this.isEdit = flag
      this.editedPercent = this.percent
    }
  },
  watch: {}
}
</script>

<style scoped lang="scss">
@import '../../styleVariables';

.teacher-rate,
.methodologist-rate,
.percent {
  display: flex;
  margin-top: 4px;
  margin-left: 10px;
  margin-bottom: 12px;
  &-container {
    padding: 5px 18px;
    background-color: rgba(0, 87, 255, 0.13);
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-right: 8px;
    .label {
      font-size: 14px;
      line-height: 24px;
      color: $main-dark;
      margin-right: 9px;
    }
    span {
      font-size: 14px;
      line-height: 24px;
      color: $bright-blue;
    }
    img {
      width: 14px !important;
      cursor: default !important;
    }
    input {
      width: 35px;
      height: 17px;
      color: $bright-blue;
      outline: none;
      border: 1px solid $bright-blue;
      border-radius: 2px;
      text-align: center;
      background: rgba(0, 87, 255, 0.13);
    }
  }
  img {
    width: 21px;
    cursor: pointer;
    margin-right: 11px;
  }
  .save-button--disabled {
    cursor: default;
    opacity: 0.3;
  }
}

.dashboard {
  background-color: $main-white;
  box-shadow: 1px 0px 20px -7px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  width: 1004px;
  margin: 2px auto;
  padding: 24px 21px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .lesson-amount {
    display: flex;
    align-items: center;
    .label {
      font-size: 14px;
      line-height: 24px;
      color: $main-gray;
      margin-right: 18px;
    }
    img {
      margin-right: 9px;
    }
    .amount {
      font-size: 14px;
      line-height: 24px;
      font-family: 'Roboto', sans-serif;
      color: $main-dark;
    }
  }
  .buttons {
    button {
      font-size: 14px;
      line-height: 24px;
      width: 184px;
      height: 32px;
      border-radius: 4px;
    }
    .return-cash {
      background-color: $main-white;
      color: $bright-blue;
      border: 1px solid $bright-blue;
    }

    .to-packs {
      background-color: $bright-blue;
      color: $main-white;
      margin-right: 15px;
    }
  }
}

.main-container {
  font-family: $font-regular, sans-serif !important;
  background-color: $background-blue !important;
  .search-filters-widgets {
    display: flex;
    justify-content: space-between;
    //align-items: center;
    flex-direction: column;
    padding-right: 10px;
  }
  .widgets-filters {
    display: flex;
    .add-button {
      /deep/ button.fill {
        background-color: $bright-blue;
        width: 42px !important;
        height: 42px;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
        border-radius: 43px;
        &:active {
          background-color: $hover-bright-blue;
        }
      }
    }
  }
  .card-header {
    padding-bottom: 0 !important;
  }
  .card {
    background-color: unset !important;
    border: none !important;
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  .table-container {
    width: 1024px;
    margin: 0 auto;
    overflow: hidden;
    height: 100%;
    padding-top: 24px;
  }
  .filter {
    //border-left: 1px solid $nav-lines;
    min-height: 100%;
    //background: $main-white;
    z-index: 10;
    margin-left: -10px;
    /deep/ .form-row {
      display: block;
      margin-left: 0 !important;
    }
    .filters {
      width: 220px;
    }
  }
  .wide-table {
    width: 100%;
    overflow: auto;
  }
  .hide-filter {
    display: none;
  }
  .wide-container {
    width: 100% !important;
  }
}
/deep/ .col {
  //padding-left: 0 !important;
  padding: 5px !important;
  display: flex;
  align-items: flex-end;
  .filters {
    margin-right: 20px;
  }
}
/deep/ table.b-table-selectable > tbody > tr.b-table-row-selected > td,
table.b-table-selectable > tbody > tr.b-table-row-selected > th {
  //background-color: $btn-hover-background !important;
}
/deep/ .table-row {
  overflow-wrap: break-word;
}
.loaderPage {
  text-align-last: center;
  margin-top: 20%;
}
.table-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
#cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 8;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#cover-spin::after {
  content: '';
  display: block;
  position: absolute;
  left: 48%;
  top: 40%;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: black;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
}

.nameTable {
  font-family: $font-bold;
  font-size: 24px;
  line-height: 30px;
  color: $main-dark;
  margin-bottom: 10px;
  margin-left: 10px;
  width: auto;
  display: inline;
}
/deep/#app-layout-navbar {
  display: block !important;
  font-size: 16px;
  line-height: 19px;
  margin: 24px 0 12px 24px;
  width: 50%;
}
.breadcrumbs {
  //color: $main-blue;
}
.collapse:not(.show) {
  display: block;
}
.form-row {
  //display: unset !important;
  margin: 0 0 0 6px !important;
}
</style>
