<template>
  <div class="main-container">
    <div class="table-container" :class="{ 'wide-container': filterFields.length <= 2 }">
      <div id="table" :class="{ 'wide-table': filterFields.length <= 2 }">
        <div v-if="isLoadEndpoints" class="table-header">
          <!--          <p class="nameTable" v-if="isLoadEndpoints">{{ nameTable }}</p>-->
          <div class="search-filters-widgets">
            <div class="date-filter">
              <DateMonth
                label=""
                :value="`${new Date().getFullYear()}-${
                  new Date().getMonth() + 1 < 10
                    ? '0' + (new Date().getMonth() + 1)
                    : new Date().getMonth() + 1
                }`"
                @monthRange="getPayout"
                :disabled="isPayout"
              />
              <button class="filterClear" @click="clearFilter" :disabled="isPayout">
                <img src="../../assets/img/ES/big_blue_cross.svg" alt="clear" />
              </button>
            </div>

            <div class="global-search" v-if="viewSearch">
              <img src="../../assets/img/ES/gray_search.svg" alt="search" />
              <input
                id="globalSearch"
                placeholder="Поиск по таблице"
                @keyup.enter="globalSearch($event)"
                style="width: 100%"
              />
            </div>
          </div>
          <ReportDashboard
            :data="payoutInfo"
            @filterByPayout="filterByPayout"
            :currentId="currentPayoutId"
            :isPayout="isPayout"
            @makePayout="makePayout"
            @savePayout="savePayout()"
            @cancelPayout="cancelPayout()"
            @downloadReport="downloadReport()"
          />
        </div>
        <Table
          :currentStore="store"
          :dataTable="tableData.total_count"
          :fieldsTable="tableFields"
          :head-variant="'light'"
          :items="tableData.items"
          @$emits="$emits"
          :viewGlobalSearch="viewSearch"
          :multiSelect="multiSelect"
          :isPayout="isPayout"
        />
      </div>
    </div>
    <div id="cover-spin" v-show="!loading"></div>
  </div>
</template>

<script>
import Table from './Table'
import eventDelegate from '../../components/Core/KernelProject/eventDelegate'
import renderWidget from '../../components/Core/KernelProject/renderWidget'
import authEvents from '../../components/Core/KernelProject/authEvents'
import viewsDefaultEvents from '../../components/Core/KernelProject/viewsDefaultEvents'
import tableEvents from '../../components/Core/KernelProject/tableEvents'
import ReportDashboard from '@/views/PayoutHistory/components/ReportDashboard'
import { domain, MicroServices } from '@/globalVariables'
import axios from 'axios'
import DateMonth from '@/components/Widgets/DateRangePicker/Content/DateMonth/DateMonth'
import Swal from 'sweetalert2'

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'confirm-button',
    cancelButton: 'delete-button',
    title: 'title-class',
    input: 'input-class'
  },
  buttonsStyling: false
})

export default {
  name: 'PayoutHistory',
  components: {
    DateMonth,
    ReportDashboard,
    Table
  },
  mixins: [eventDelegate, renderWidget, authEvents, viewsDefaultEvents, tableEvents],
  data() {
    return {
      tableData: [],
      tableFields: [],
      store: null,
      route: null,
      viewSearch: null,
      multiSelect: null,
      loading: false,
      to: null,
      from: null,
      payoutInfo: null,
      currentPayoutId: null,
      isPayout: false,
      currentMonth: null,
      selectedUsers: []
    }
  },
  mounted() {
    this.$root.$on('getFilterData', (item) => {
      this.getFilterData()
    })
    // filter need
    this.$root.$on('filterSearch', (data) => {
      this.getDataWithFilter()
    })
    // filter clear
    this.$root.$on('filterClear', (data) => {
      let clear = true
      this.getDataPagination(clear)
      this.$root.$emit('getMetaData', true)
    })
    // filter clear
    this.$root.$on('switchFilter', (data) => {
      this.switchFilter()
    })
    // filter spinner => true
    this.$root.$on('filterSpinner', (data) => {
      setTimeout(() => {
        this.filter = true
      }, 500)
    })

    this.$root.$on('rowsSelected', (data) => {
      this.selectedUsers = data
    })

    this.currentMonth = `${new Date().getFullYear()}-${
      new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1
    }`

    this.getPayout(this.currentMonth)
  },
  methods: {
    /**
     * Получить список отчетов выплат за выбранный месяц
     * @param month
     */
    getPayout(month) {
      let nextMonth = `${month.split('-')[0]}-${
        Number(month.split('-')[1]) + 1 < 10
          ? '0' + (Number(month.split('-')[1]) + 1)
          : Number(month.split('-')[1]) + 1
      }-01`
      month += '-01'
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Payout/getItems`,
        method: 'GET',
        params: {
          _range_from: {
            date: month
          },
          _range_to: {
            date: nextMonth
          },
          _order: {
            date: 'desc'
          }
        }
      })
        .then((response) => {
          this.payoutInfo = response.data.data.items
          this.currentPayoutId = this.payoutInfo[0].id
          if (!this.isPayout) {
            this.filterByPayout(this.currentPayoutId)
          }
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Получить историю выплат по id отчета
     * @param payoutId
     */
    filterByPayout(payoutId) {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/PayoutHistory/getItems`,
        method: 'GET',
        params: {
          payout_id: payoutId
        }
      })
        .then((response) => {
          this.tableData.items = response.data.data.items
          this.currentPayoutId = payoutId
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Открыть таблицу для осуществления выплаты
     */
    makePayout() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/PayoutPeriodStatistic/getItems`,
        method: 'GET'
      })
        .then((response) => {
          this.isPayout = true
          this.tableData.items = response.data.data
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Закрыть таблицу для осуществления выплаты
     */
    cancelPayout() {
      this.isPayout = false
      this.clearFilter()
    },

    /**
     * Скачать отчет
     */
    downloadReport() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/PayoutHistory/downloadReport`,
        method: 'POST',
        data: [
          {
            payout_id: this.currentPayoutId
          }
        ]
      })
        .then((response) => {
          window.open(response.data.data.path, '_blank')
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Осуществить выплату
     * @returns {null}
     */
    savePayout() {
      if (!this.selectedUsers.length) {
        this.messageAnyType('Выберите пользователей, которым хотите существить выплату', 'error')
        return null
      }
      swalWithBootstrapButtons
        .fire({
          title: `Вы уверены в правильности введенных данных?`,
          showCancelButton: true,
          confirmButtonText: 'Сохранить',
          cancelButtonText: 'Отмена',
          reverseButtons: true
        })
        .then((r) => {
          if (r.value) {
            axios({
              url: `${domain}/${MicroServices[0].prefix}/PayoutHistory/create`,
              method: 'POST',
              data: JSON.stringify(
                this.selectedUsers.map((item) => ({
                  user_uuid: item.user_uuid,
                  amount_to_paid_fact: item.amount_to_paid_fact
                }))
              )
            })
              .then(() => {
                this.successAlert('Оплата осуществена!')
                this.cancelPayout()
              })
              .catch((error) => {
                this.errorAlert(error)
              })
          }
        })
    },

    /**
     * Очистить фильтр
     */
    clearFilter() {
      this.$root.$emit('filterClear')
    }
  },
  watch: {},
  beforeDestroy() {
    this.$root.$off('rowsSelected')
  }
}
</script>

<style scoped lang="scss">
@import '../../styleVariables';

.filterClear {
  width: 28px !important;
  height: 28px !important;
  background-color: $main-white;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
  border-radius: 50px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.percent {
  display: flex;
  &-container {
    padding: 5px 18px;
    background-color: rgba(0, 87, 255, 0.13);
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-right: 8px;
    .label {
      font-size: 14px;
      line-height: 24px;
      color: $main-dark;
      margin-right: 9px;
    }
    span {
      font-size: 14px;
      line-height: 24px;
      color: $bright-blue;
    }
    input {
      width: 35px;
      height: 17px;
      color: $bright-blue;
      outline: none;
      border: 1px solid $bright-blue;
      border-radius: 2px;
      text-align: center;
      background: rgba(0, 87, 255, 0.13);
    }
  }
  img {
    width: 21px;
    cursor: pointer;
    margin-right: 11px;
  }
}

.dashboard {
  background-color: $main-white;
  box-shadow: 1px 0px 20px -7px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  width: 1004px;
  margin: 2px auto;
  padding: 24px 21px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .lesson-amount {
    display: flex;
    align-items: center;
    .label {
      font-size: 14px;
      line-height: 24px;
      color: $main-gray;
      margin-right: 18px;
    }
    img {
      margin-right: 9px;
    }
    .amount {
      font-size: 14px;
      line-height: 24px;
      font-family: 'Roboto', sans-serif;
      color: $main-dark;
    }
  }
  .buttons {
    button {
      font-size: 14px;
      line-height: 24px;
      width: 184px;
      height: 32px;
      border-radius: 4px;
    }
    .return-cash {
      background-color: $main-white;
      color: $bright-blue;
      border: 1px solid $bright-blue;
    }

    .to-packs {
      background-color: $bright-blue;
      color: $main-white;
      margin-right: 15px;
    }
  }
}

.main-container {
  font-family: $font-regular, sans-serif !important;
  background-color: $background-blue !important;
  .search-filters-widgets {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
    margin-left: 12px;
    margin-bottom: 24px;
  }
  .date-filter {
    display: flex;
    align-items: flex-end;
    button {
      margin-left: 12px;
    }
  }
  .widgets-filters {
    display: flex;
    .add-button {
      /deep/ button.fill {
        background-color: $bright-blue;
        width: 42px !important;
        height: 42px;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
        border-radius: 43px;
        &:active {
          background-color: $hover-bright-blue;
        }
      }
    }
  }
  .card-header {
    padding-bottom: 0 !important;
  }
  .card {
    background-color: unset !important;
    border: none !important;
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  .table-container {
    width: calc(100% - 48px);
    margin: 0 auto;
    overflow: hidden;
    height: 100%;
    padding-top: 24px;
  }
  .filter {
    //border-left: 1px solid $nav-lines;
    min-height: 100%;
    //background: $main-white;
    z-index: 10;
    margin-left: -10px;
    /deep/ .form-row {
      display: block;
      margin-left: 0 !important;
    }
    .filters {
      width: 220px;
    }
  }
  .wide-table {
    width: 100%;
    overflow: auto;
  }
  .hide-filter {
    display: none;
  }
  //.wide-container {
  //  width: 100% !important;
  //}
}
/deep/ .col {
  //padding-left: 0 !important;
  padding: 5px !important;
  display: flex;
  align-items: flex-end;
  .filters {
    margin-right: 20px;
  }
}
/deep/ table.b-table-selectable > tbody > tr.b-table-row-selected > td,
table.b-table-selectable > tbody > tr.b-table-row-selected > th {
  //background-color: $btn-hover-background !important;
}
/deep/ .table-row {
  overflow-wrap: break-word;
}
.loaderPage {
  text-align-last: center;
  margin-top: 20%;
}
.table-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
#cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 8;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#cover-spin::after {
  content: '';
  display: block;
  position: absolute;
  left: 48%;
  top: 40%;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: black;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
}

.nameTable {
  font-family: $font-bold;
  font-size: 24px;
  line-height: 30px;
  color: $main-dark;
  margin-bottom: 10px;
  margin-left: 10px;
  width: auto;
  display: inline;
}
/deep/#app-layout-navbar {
  display: block !important;
  font-size: 16px;
  line-height: 19px;
  margin: 24px 0 12px 24px;
  width: 50%;
}
.breadcrumbs {
  //color: $main-blue;
}
.collapse:not(.show) {
  display: block;
}
.form-row {
  //display: unset !important;
  margin: 0 0 0 6px !important;
}
</style>
