<template>
  <div class="main">
    <div class="translator-container">
      <div class="translator-window">
        <div class="mobile-language-switcher" @click="swapLanguages">
          <span class="left">{{ languages[0].name }}</span>
          <img src="../../assets/img/ES/swap.svg" alt="swap" />
          <span class="right">{{ languages[1].name }}</span>
        </div>
        <div class="input">
          <div class="language">{{ languages[0].name }}</div>
          <textarea
            @keyup.enter.exact="translate"
            @keydown.enter.exact.prevent
            v-model="inputWord"
          ></textarea>
        </div>
        <div class="translate-button">
          <button :disabled="!inputWord" @click="translate">
            <svg
              width="12"
              height="8"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.1958 0.291505C8.52095 -0.033645 9.04795 -0.0341907 9.37378 0.290286C9.70055 0.615714 9.7011 1.1446 9.375 1.4707L5 5.8457L0.625001 1.4707C0.298897 1.1446 0.299445 0.615714 0.626223 0.290286C0.952046 -0.0341908 1.47905 -0.033645 1.8042 0.291505L5 3.4873L8.1958 0.291505Z"
                fill="#2E3A59"
              />
            </svg>
          </button>
        </div>
        <div class="swap-languages" @click="swapLanguages">
          <img src="../../assets/img/ES/swap.svg" alt="swap" />
        </div>
        <div class="output">
          <img class="loader" src="../../assets/img/ES/loader.svg" alt="loader" v-show="loading" />
          <div class="language">{{ languages[1].name }}</div>
          <textarea disabled :value="translateWord"></textarea>
        </div>
      </div>

      <div class="translation-details">
        <TranslationDetails
          :translateWordVariations="translateWordVariations"
          v-if="
            translateWordVariations[0] !== undefined &&
            translateWordVariations[0].mean !== undefined
          "
        />
      </div>
      <div class="control-btn">
        <button :disabled="!inputWord" @click="addWordToGlossaryWithTranslate()">
          <img src="../../assets/img/icons/close_big_white.svg" />Добавить слово в словарь
        </button>
      </div>
    </div>
    <div class="student-glossary-container">
      <div class="table-filter">
        <vue-select
          class="select"
          style="width: 320px"
          v-model="selectedOption"
          placeholder="Сортировать по"
          :options="options"
          :clearable="false"
        >
          <span slot="no-options">Нет опций</span>
        </vue-select>
      </div>
      <div class="glossary-table">
        <b-table
          ref="mainTable"
          responsive
          borderless
          :sticky-header="true"
          empty-text="Записей не найдено!"
          empty-filtered-text="Записей не найдено!"
          :show-empty="true"
          :noLocalSorting="true"
          :items="tableData"
          :fields="fields"
          class="table-users"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
        >
          <template v-for="field in fields" v-slot:[`cell(${field.key})`]="data">
            <div
              v-if="field.key === 'english_word'"
              :key="field.key"
              class="word"
              @click="getTranslationDetails(data)"
            >
              <div class="arrow">
                <svg
                  width="16"
                  height="10"
                  viewBox="0 0 10 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  :id="`arrow-${data.item.id}`"
                  :class="{
                    'arrow-reversed': lastRowDetails && lastRowDetails.item.id === data.item.id
                  }"
                >
                  <path
                    d="M8.1958 0.291505C8.52095 -0.033645 9.04795 -0.0341907 9.37378 0.290286C9.70055 0.615714 9.7011 1.1446 9.375 1.4707L5 5.8457L0.625001 1.4707C0.298897 1.1446 0.299445 0.615714 0.626223 0.290286C0.952046 -0.0341908 1.47905 -0.033645 1.8042 0.291505L5 3.4873L8.1958 0.291505Z"
                    fill="#8F9BB3"
                  />
                </svg>
              </div>
              <span v-if="!wordId || wordId !== data.item.id">{{ data.item.english_word }}</span>
              <input v-if="wordId === data.item.id" v-model="editedWordEnglish" @click.stop />
            </div>
            <div
              v-else-if="field.key === 'russian_word'"
              :key="field.key"
              class="translation"
              @click="getTranslationDetails(data)"
            >
              <span v-if="!wordId || wordId !== data.item.id">{{ data.item.russian_word }}</span>
              <input v-if="wordId === data.item.id" v-model="editedWordRussian" @click.stop />
              <div class="control-btns">
                <button
                  @click.stop="
                    editWord(data.item.id, data.item.english_word, data.item.russian_word)
                  "
                  v-if="!wordId || wordId !== data.item.id"
                  class="edit-btn"
                >
                  <img src="../../assets/img/icons/edit_pale.svg" />
                </button>
                <button
                  @click.stop="
                    saveEdit(data.item.id, data.item.english_word, data.item.russian_word)
                  "
                  v-if="wordId === data.item.id"
                  class="save-btn"
                >
                  <img class="confirm-img" src="../../assets/img/icons/confirm.svg" />
                </button>
                <button
                  @click.stop="wordId !== data.item.id ? deleteWord(data.item.id) : (wordId = null)"
                  class="delete-btn"
                >
                  <img src="../../assets/img/icons/close_big.svg" v-if="wordId !== data.item.id" />
                  <img src="../../assets/img/ES/big_blue_cross.svg" class="cross" v-else />
                </button>
              </div>
            </div>
          </template>
          <template #row-details="row">
            <div class="row-details-body">
              <img
                class="loader loader--row-details"
                src="../../assets/img/ES/loader.svg"
                alt="loader"
                v-show="rowDetailsLoading"
              />
              <TranslationDetails
                :translateWordVariations="dictionaryTranslateDetails"
                v-if="
                  dictionaryTranslateDetails !== null &&
                  dictionaryTranslateDetails[0].mean !== undefined
                "
              />
              <p class="no-translate-variants" v-else-if="!rowDetailsLoading">
                Нет вариантов перевода
              </p>
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { domain, MicroServices } from '@/globalVariables'
import VueSelect from 'vue-select'
import TranslationDetails from '@/views/LessonCard/components/TranslationDetails'

export default {
  name: 'LessonGlossary',
  mixins: [],
  props: ['studentId'],
  components: { VueSelect, TranslationDetails },
  data() {
    return {
      sortBy: 'english_word',
      sortDesc: true,
      fields: [
        { label: 'Слово', key: 'english_word' },
        { label: 'Перевод', key: 'russian_word' }
      ],
      tableData: [],
      options: ['По алфавиту', 'По дате'],
      selectedOption: 'По дате',
      editedWordRussian: '',
      editedWordEnglish: '',
      wordId: null,
      languages: [
        {
          name: 'Английский',
          value: 'en'
        },
        {
          name: 'Русский',
          value: 'ru'
        }
      ],
      inputWord: null,
      translateWord: null,
      translateWordVariations: [],
      dictionaryTranslateDetails: [],
      lastRowDetails: null,
      lastArrowReverseId: null,
      loading: false,
      rowDetailsLoading: false,
      isTableRerender: false
    }
  },
  mounted() {
    this.getArrangedWords()
  },
  methods: {
    /**
     * Обработчик события клика на строку таблицы
     * @param rowData
     */
    getTranslationDetails(rowData) {
      this.dictionaryTranslateDetails = null
      this.rowDetailsLoading = true
      if (!rowData.detailsShowing) {
        this.translate(rowData.item.english_word)
      }
      this.toggleRowDetails(rowData)
    },

    /**
     * Показать дополнительную информацию
     * @return {null}
     */
    toggleRowDetails(row) {
      if (this.lastRowDetails) {
        this.lastRowDetails.toggleDetails()
        if (this.lastRowDetails.index === row.index) {
          this.lastRowDetails = null
          return null
        }
      }
      this.lastRowDetails = row
      row.toggleDetails()
    },

    /**
     * Добавить слово в словарь, предварительно выполнив перевод
     */
    addWordToGlossaryWithTranslate() {
      this.lastRowDetails = null
      this.translate().then((response) => {
        if (response === 'OK') {
          this.addWordToGlossary()
        }
      })
    },

    /**
     * Добавить слово в словарь
     */
    addWordToGlossary() {
      let body
      if (this.languages[0].value === 'en') {
        body = {
          english_word: this.inputWord,
          russian_word: this.translateWord,
          user_uuid: this.studentId
        }
      } else {
        body = {
          english_word: this.translateWord,
          russian_word: this.inputWord,
          user_uuid: this.studentId
        }
      }

      axios({
        url: `${domain}/${MicroServices[0].prefix}/Translate/create`,
        method: 'POST',
        data: [body]
      })
        .then(() => {
          this.getArrangedWords()
        })
        .catch((error) => this.errorAlert(error))
    },

    /**
     * Удалить слово из словаря
     * @param id
     */
    deleteWord(id) {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Translate/delete/${id}`,
        method: 'POST'
      })
        .then((response) => {
          this.getArrangedWords()
          this.lastRowDetails = null
        })
        .catch((error) => this.errorAlert(error))
    },

    /**
     * Редактировать слово
     * @param id
     * @param englishWord
     * @param russianWord
     */
    editWord(id, englishWord, russianWord) {
      this.wordId = id
      this.editedWordEnglish = englishWord
      this.editedWordRussian = russianWord
    },

    /**
     * Сохранить редактирование слова
     * @param id
     * @param englishWord
     * @param russianWord
     */
    saveEdit(id, englishWord, russianWord) {
      if (this.editedWordRussian || this.editedWordEnglish) {
        axios({
          url: `${domain}/${MicroServices[0].prefix}/Translate/update/${id}`,
          method: 'POST',
          data: [
            {
              english_word: this.editedWordEnglish ? this.editedWordEnglish : englishWord,
              russian_word: this.editedWordRussian ? this.editedWordRussian : russianWord
            }
          ]
        })
          .then((response) => {
            this.getArrangedWords()
            this.lastRowDetails = null
            this.wordId = null
            this.editedWordEnglish = ''
            this.editedWordRussian = ''
          })
          .catch((error) => this.errorAlert(error))
      } else {
        this.$toasted.show('Инпут не может быть пустым!', {
          type: 'error',
          duration: 2000
        })
      }
    },

    /**
     * Получение слов в словаре с сортировкой
     */
    getArrangedWords() {
      if (this.selectedOption === 'По алфавиту') {
        this.sortBy = 'english_word'
        axios({
          url: `${domain}/${MicroServices[0].prefix}/Translate/getItems`,
          method: 'GET',
          params: {
            user_uuid: this.studentId,
            _order: { english_word: 'asc' }
          }
        })
          .then((response) => {
            this.tableData = response.data.data.items
          })
          .catch((error) => this.errorAlert(error))
      }
      if (this.selectedOption === 'По дате') {
        this.sortBy = 'created_at'
        axios({
          url: `${domain}/${MicroServices[0].prefix}/Translate/getItems`,
          method: 'GET',
          params: {
            user_uuid: this.studentId,
            _order: { created_at: 'desc' }
          }
        })
          .then((response) => {
            this.tableData = response.data.data.items
          })
          .catch((error) => this.errorAlert(error))
      }
    },

    /**
     * Поменять местами языки в переводчике
     */
    swapLanguages() {
      this.languages.reverse()
      if (this.translateWord) {
        this.inputWord = this.translateWord
        this.translate()
      }
    },

    /**
     * Перевести слово/фразу
     * @param wordFromDictionary
     * @returns {Promise<unknown>}
     */
    translate(wordFromDictionary = null) {
      return new Promise((resolve, reject) => {
        if (!this.inputWord && wordFromDictionary === event) {
          return null
        }
        let word
        if (wordFromDictionary === event || !wordFromDictionary) {
          word = this.inputWord
          this.loading = true
        } else {
          word = wordFromDictionary
        }
        axios({
          url: `${domain}/${MicroServices[0].prefix}/Translate/translate`,
          method: 'POST',
          data: [
            {
              text: word,
              source_lang:
                !wordFromDictionary || wordFromDictionary === event
                  ? this.languages[0].value
                  : 'en',
              target_lang:
                !wordFromDictionary || wordFromDictionary === event ? this.languages[1].value : 'ru'
            }
          ]
        })
          .then((response) => {
            if (response.data.data[0].tr) {
              if (word !== wordFromDictionary) {
                this.translateWord = response.data.data[0].tr[0].text
                this.translateWordVariations = response.data.data[0].tr.map(
                  (translatedWord) => translatedWord
                )
              } else {
                this.dictionaryTranslateDetails = response.data.data[0].tr
              }
            } else {
              if (word !== wordFromDictionary) {
                this.translateWord = response.data.data[0].text
                this.translateWordVariations = []
              }
            }
            this.loading = false
            this.rowDetailsLoading = false
            resolve('OK')
          })
          .catch((error) => {
            this.loading = false
            this.rowDetailsLoading = false
            this.errorAlert(error)
            reject(error)
          })
      })
    }
  },
  computed: {},
  watch: {
    selectedOption() {
      this.getArrangedWords()
    }
  },
  validations: {}
}
</script>

<style scoped lang="scss">
@import './src/styleVariables';
.arrow-reversed {
  transform: rotate(180deg);
}
.loader {
  position: absolute;
  animation-name: rotate;
  animation-duration: 0.7s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  width: 28px;
  top: 50%;
  left: 50%;
  &--row-details {
    top: 10%;
    left: 50%;
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.main {
  .translator-container {
    width: 95%;
    min-height: 312px;
    height: auto;
    background: $main-white;
    box-shadow: 1px 0px 20px -7px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    margin: 24px auto;
    display: flex;
    flex-direction: column;
    padding: 0px 14px 17px 14px;
    .translator-window {
      display: flex;
      justify-content: center;
      margin-bottom: 12px;
      .mobile-language-switcher {
        display: none;
      }
      .input {
        width: 100%;
        max-width: 720px;
        .language {
          width: fit-content;
          margin-left: auto;
          margin-right: 14px;
          text-transform: capitalize;
          &::before {
            content: '.';
            position: absolute;
            font-size: 40px;
            bottom: -9px;
            left: -15px;
            color: $bright-blue;
          }
        }
      }
      .swap-languages {
        position: relative;
        top: 35px;
        margin: 0 6px;
        cursor: pointer;
        height: fit-content;
      }
      .output {
        width: 100%;
        max-width: 720px;
        position: relative;
        .language {
          margin-left: 14px;
          text-transform: capitalize;
        }
      }
      .language {
        position: relative;
        top: 36px;
        font-family: $font-bold;
        color: $main-gray;
        text-transform: uppercase;
      }
      textarea {
        border: 0.5px solid $light-gray;
        border-radius: 4px;
        background-color: transparent;
        outline: none;
        padding: 35px 14px 3px 14px;
        font-size: 20px;
        font-family: $font-bold;
        resize: none;
        height: 200px;
        width: 100%;
        margin-top: 10px;
        color: $main-dark;
        &:focus {
          border: 0.5px solid $bright-blue;
        }
      }
    }
    .translate-button {
      display: flex;
      justify-content: center;
      margin-bottom: 12px;
      position: relative;
      button {
        position: absolute;
        top: 50%;
        width: 42px;
        height: 42px;
        background: #dee9ff;
        border-radius: 51px;
        color: $bright-blue;
        svg {
          transform: rotate(-90deg);
          margin-left: 3px;
          margin-bottom: 3px;
        }
      }
    }
    .control-btn {
      margin-top: 37px;
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: flex-end;
      button {
        width: 240px;
        height: 33px;
        border-radius: 4px;
        background-color: $bright-blue;
        color: $main-white;
        img {
          margin-right: 8px;
        }
        &:disabled {
          background-color: $light-gray;
        }
      }
    }
  }
  .student-glossary-container {
    width: 95%;
    min-height: 412px;
    background: $main-white;
    box-shadow: 1px 0px 20px -7px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    margin: 0 auto 24px auto;
    display: flex;
    flex-direction: column;
    .table-filter {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding: 15px 21px 22px 0;
    }
    .glossary-table {
      height: 500px;
      .word {
        text-align: start;
        color: $main-dark;
        height: 100%;
        position: relative;
        padding: 0 24px 0 49px;
        span {
          position: absolute;
          top: 40%;
          font-family: $font-regular;
        }
        input {
          width: 90%;
          position: absolute;
          top: 32%;
          border: 1px solid $light-gray;
          font-family: $font-regular;
          border-radius: 4px;
          min-height: 25px;
          height: auto;
          outline: none;
          padding: 0 8px;
        }
        input:focus {
          border: 1px solid $bright-blue;
        }
        .arrow {
          position: absolute;
          left: 18px;
          top: 39%;
          svg {
            transition: 0.2s;
          }
        }
      }
      .translation {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        padding: 0 24px 0 24px;
        span {
          width: 75%;
          text-align: start;
          color: $main-dark;
          font-family: $font-regular;
        }
        div {
          width: 22%;
        }
        .control-btns {
          .edit-btn {
            margin-right: 28px;
            cursor: pointer;
          }
          .delete-btn {
            cursor: pointer;
          }
          .save-btn {
            .confirm-img {
              margin-right: 28px;
              cursor: pointer;
              width: 20px;
              height: 20px;
            }
          }
          .cross {
            width: 20px;
            height: 20px;
          }
          img {
            width: 16px;
            height: 16px;
          }
        }
        input {
          border: 1px solid $light-gray;
          font-family: $font-regular;
          border-radius: 4px;
          min-height: 25px;
          width: 85%;
          height: auto;
          outline: none;
          padding: 0 8px;
        }
        input:focus {
          border: 1px solid $bright-blue;
        }
      }
      .row-details-body {
        position: relative;
        text-align: start;
        background-color: $main-white;
        min-height: 330px;
        width: 100%;
        padding: 12px 0 0 13px;

        .no-translate-variants {
          text-align: center;
          font-size: 16px;
          margin-top: 20px;
        }
      }
    }
  }
}
/deep/ {
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: #f5faff;
  }
  .b-table-details:hover {
    cursor: auto;
  }
  .table-b-table-default {
    width: 50%;
    text-align: start;
    color: $main-dark !important;
    font-size: 18px;
    padding: 24px 0 16px 49px;
  }
  .table-b-table-default:nth-child(2) {
    padding-left: 24px;
  }
  .table td {
    padding: 0;
    height: 68px;
  }
  .table tr {
    cursor: pointer;
    border-bottom: 1px solid #dee6f5;
    &:hover {
      background-color: #dee6f5;
    }
  }
  thead {
    border-bottom: none;
  }
  .vs__dropdown-toggle {
    border: 1px solid $light-gray;
  }
  .v-select.select.vs--single.vs--searchable {
    width: 183px !important;
  }
  input.vs__search {
    color: $main-gray;
    font-family: $font-bold;
  }
}

@media screen and (max-width: 710px) {
  .main {
    .student-glossary-container {
      .glossary-table {
        .translation {
          .control-btns {
            min-width: 60px;
            .edit-btn {
              margin-right: 12px;
            }
            .save-btn {
              .confirm-img {
                margin-right: 12px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .main {
    .translator-container {
      padding-top: 16px;
      .translator-window {
        flex-direction: column;
        .mobile-language-switcher {
          display: grid;
          grid-template-columns: 1fr 30px 1fr;
          grid-column-gap: 8px;
          span {
            align-self: center;
            text-transform: uppercase;
            color: $main-dark;
            font-family: $font-regular;
          }
          .left {
            justify-self: flex-end;
          }
          .right {
            justify-self: flex-start;
          }
        }
        .input,
        .output {
          .language {
            display: none;
          }
        }
        .swap-languages {
          display: none;
        }
      }
      .control-btn,
      .translate-button {
        width: 100%;
        button {
          //width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 380px) {
  /deep/ {
    .table tbody tr td {
      padding: 0 8px !important;
    }
    .table-b-table-default {
      padding: 12px 0 12px 8px;
    }
  }
}
</style>
