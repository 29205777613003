<template>
  <sidenav
    :orientation="orientation"
    :class="curClasses"
    v-show="(width < 992 && isToggleMenu) || width > 991"
    id="sidenav"
  >
    <!-- Inner -->
    <div class="sidenav-inner" :class="{ 'py-1': orientation !== 'horizontal' }">
      <img :src="require(`../assets/img/logo-es.jpg`)" alt="icon" class="logo" />
      <div v-for="(_menu, index) in menuSide" :key="index" class="width-class">
        <!--        <li class="sidenav-header small font-weight-semibold"></li>-->
        <div
          class="sidenav-item"
          style=""
          v-for="(menu, index) in _menu"
          :key="index"
          :class="{
            hide:
              (menu.icon === 'wallet' || menu.icon === 'user_pin') &&
              $cookie.get('internalName') !== 'student'
          }"
        >
          <a class="sidenav-link sidenav-toggle cursor-pointer" v-if="menu.deep">
            <img
              class="menu-icon"
              v-if="menu.icon"
              :src="require(`../assets/img/icons/${menu.icon}.svg`)"
              alt="icon"
            />
            <div>{{ menu.label }}</div>
          </a>
          <sidenav-router-link :to="'/' + menu.route" v-else-if="menu.view">
            <img
              class="menu-icon"
              v-if="menu.icon"
              :src="require(`../assets/img/icons/${menu.icon}.svg`)"
              alt="icon"
            />
            <div>{{ menu.label }}</div>
            <div class="messages-count" v-if="menu.icon === 'chat' && messagesCount">
              {{ messagesCount }}
            </div>
          </sidenav-router-link>
          <div class="sidenav-menu" v-if="menu.deep">
            <div
              class="sidenav-item deep"
              v-for="(deepMenu, index) in menu.deep"
              :key="index"
              v-if="deepMenu.view"
            >
              <sidenav-router-link v-if="!deepMenu.deep" :to="'/' + deepMenu.route" :exact="true"
                ><span style="font-family: 'Source Sans Pro Regular', sans-serif">{{
                  deepMenu.label
                }}</span></sidenav-router-link
              >
              <div class="sidenav-item" v-else>
                <!--                <a class="sidenav-link sidenav-toggle cursor-pointer">-->
                <!--                  <img-->
                <!--                    v-if="deepMenu.icon"-->
                <!--                    :src="require(`../assets/img/icons/${menu.icon}.svg`)"-->
                <!--                    alt="icon"-->
                <!--                  />-->
                <!--                  <div>{{ deepMenu.label }}</div>-->
                <!--                </a>-->
                <!--                <div class="sidenav-menu">-->
                <!--                  <div class="sidenav-item" v-for="(Depth, index) in deepMenu.deep" :key="index">-->
                <!--                    <img-->
                <!--                      v-if="Depth.icon"-->
                <!--                      :src="require(`../assets/img/icons/${Depth.icon}.svg`)"-->
                <!--                      alt="icon"-->
                <!--                    />-->
                <!--                    <sidenav-router-link :to="'/' + Depth.route" :exact="true">{{-->
                <!--                        Depth.label-->
                <!--                      }}</sidenav-router-link>-->
                <!--                  </div>-->
                <!--                </div>-->
              </div>
            </div>
          </div>
        </div>
        <div class="side-nav-item-exit">
          <div class="label" @click="logout()">
            <img src="../assets/img/ES/exit.svg" alt="exit" />
            <span>Выйти</span>
          </div>
        </div>
        <!--        <li class="sidenav-divider mb-1" style="width: 250px"></li>-->
      </div>
    </div>
    <div class="manual" @click="getManual" v-if="$cookie.get('internalName') === 'admin'">
      Руководство пользователя
    </div>
  </sidenav>
</template>

<script>
import { domain, fileDomain, MicroServices } from '@/globalVariables'
import {
  Sidenav,
  SidenavLink,
  SidenavRouterLink,
  SidenavMenu,
  SidenavHeader,
  SidenavBlock,
  SidenavDivider
} from '@/vendor/libs/sidenav'
import centrifuge from '@/centrifuge'
import axios from 'axios'

export default {
  name: 'app-layout-sidenav',
  components: {
    /* eslint-disable vue/no-unused-components */
    Sidenav,
    SidenavLink,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavBlock,
    SidenavDivider
    /* eslint-enable vue/no-unused-components */
  },

  data() {
    return {
      menuSide: [],
      channel: null,
      token: null,
      centrifugeChat: null,
      messagesCount: null,
      isToggleMenu: false
    }
  },

  props: {
    orientation: {
      type: String,
      default: 'vertical'
    }
  },

  mounted() {
    this.$root.$on('setMenu', (prefix, name) => {
      this.setMenu(prefix, name)
    })
    this.$root.$on('toggleMenu', (flag) => {
      this.isToggleMenu = flag
    })
    this.getChannel()
    this.getMessagesCount()
  },

  beforeDestroy() {
    this.centrifugeChat.unsubscribe()
  },

  computed: {
    width() {
      return window.innerWidth
    },
    curClasses() {
      let bg = this.layoutSidenavBg

      if (
        this.orientation === 'horizontal' &&
        (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)
      ) {
        bg = bg
          .replace(' sidenav-dark', '')
          .replace(' sidenav-light', '')
          .replace('-darker', '')
          .replace('-dark', '')
      }

      return (
        `bg-${bg} ` +
        (this.orientation !== 'horizontal'
          ? 'layout-sidenav'
          : 'layout-sidenav-horizontal container-p-x flex-grow-0')
      )
    }
  },
  methods: {
    subscribeCentrifugeChat() {
      centrifuge.setToken(this.token)
      this.centrifugeChat = centrifuge.subscribe(`${this.channel}`, (message) => {
        this.getMessagesCount()
        if (
          this.$route.name === 'Chat' ||
          this.$cookie.get('internalName') === 'student' ||
          this.$cookie.get('internalName') === 'teacher' ||
          this.$cookie.get('internalName') === 'methodologist'
        ) {
          this.$root.$emit('getNewMessage', message)
        }
      })
    },
    getChannel() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/User/connectToCentrifuge`,
        method: 'GET'
      }).then((response) => {
        this.token = response.data.data.token
        this.channel = response.data.data.chat_channel
        this.subscribeCentrifugeChat()
      })
    },
    getMessagesCount() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/User/getUnreadMessagesCount`,
        method: 'GET'
      })
        .then((response) => {
          this.messagesCount = response.data.data.unread_message_amount
          if (
            this.$cookie.get('internalName') === 'student' ||
            this.$cookie.get('internalName') === 'teacher' ||
            this.$cookie.get('internalName') === 'methodologist'
          ) {
            this.$root.$emit('setMessageCount', this.messagesCount)
          }
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },
    logout() {
      this.$router.push('/login')
      this.$root.$emit('unsubscribeGeneral')
      return this.$store.dispatch('logout')
    },
    getManual() {
      window.open(`${fileDomain}/public/Руководство_пользователя.pdf`, '_blank')
    },
    isMenuActive(url) {
      return this.$route.path.indexOf(url) === 0
    },
    isMenuOpen(url) {
      return this.$route.path.indexOf(url) === 0 && this.orientation !== 'horizontal'
    },
    toggleSidenav() {
      this.layoutHelpers.toggleCollapsed()
    },
    /**
     * Создание меню с уникализацией её данных
     * @param prefix
     * @param name
     */
    setMenu(prefix, name) {
      let menu = this.$store.state.metaProjectMenu[prefix]
      this.menuSide.push(menu)
      this.menuSide[this.menuSide.length - 1]['name'] = name
      this.menuSide = this.menuSide.filter(
        ((set) => (f) => !set.has(f.name) && set.add(f.name))(new Set())
      )
    }
  },
  watch: {
    $route() {
      this.$root.$emit('toggleMenu', false)
    }
  }
}
</script>
<style lang="scss">
@import '../components/Widgets/ColorVariables';
@import '../styleVariables.scss';

.logo {
  width: 200px;
  margin: 30px auto !important;
}
.side-nav-item-exit {
  display: none;
}
.messages-count {
  font-size: 14px;
  line-height: 17px;
  color: $main-white;
  background-color: $red;
  padding: 0 5px;
  border-radius: 50px;
  width: fit-content !important;
  margin-left: 12px;
}
.manual {
  cursor: pointer;
  text-decoration: underline;
  color: $main-gray;
  margin: 12px auto;
  background-color: $main-white;
}
* {
  font-family: $font-bold;
}

.sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  color: $main-white !important;
}

.sidenav-inner {
  color: $main-dark;
}

img.menu-icon {
  margin-right: 12px;
}

.v-application .sidenav-link {
  color: $main-dark !important;
}
.sidenav-vertical {
  width: 248px;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 10;
  color: $main-dark !important;
}

.width-class {
  width: 100%;
}
@media screen and (max-width: 991px) {
  .logo {
    display: none;
  }
  .side-nav-item-exit {
    display: unset;
    .label {
      display: flex;
      border-top: 0.5px solid $light-gray;
      align-items: center;
      padding: 8px 14px;
      span {
        font-size: 16px;
        color: $main-dark;
      }
      img {
        margin-right: 14px;
      }
    }
  }
  .sidenav-vertical {
    box-shadow: 1px 0px 20px -7px rgba(0, 0, 0, 0.16);
    border-radius: 0px 0px 8px 8px;
    height: fit-content;
    width: 100%;
    position: absolute;
  }
  .sidenav-inner a {
    font-size: 16px !important;
    padding: 12px 22px !important;
    img {
      margin-right: 22px;
    }
  }
}
html:not([dir='rtl']) .py-1 {
  padding: 0 !important;
}
.sidenav {
  box-shadow: -5px 1px 17px rgba(0, 0, 0, 0.16);
}
.sidenav-inner {
  background-color: $main-white;
  font-size: 18px;
  line-height: 21px;
  width: 100%;
  .sidenav-menu {
    font-size: 16px;
    width: 100%;
  }
  a {
    color: $main-dark;
    width: 100%;
    font-size: 18px;
    div {
      display: flex;
      //width: 100%;
      align-items: center;
      //color: $main-dark;
    }
  }
  a.active > div > div {
    color: $main-white !important;
  }
  .sidenav-item.active {
    background-color: $bright-blue;
    color: $main-white !important;
  }
  .sidenav-item.open {
    width: 100%;
    .sidenav-toggle {
      color: $main-dark;
      width: 100%;
    }
    .sidenav-link {
      background-color: $bright-blue;
      color: $main-white !important;
    }
    .deep {
      .sidenav-link {
        font-size: 16px;
        line-height: 20px;
        font-family: $font-regular !important;
        background-color: $main-white;
        color: $main-dark !important;
        padding-right: 4px !important;
      }
      .active {
        .sidenav-link {
          color: $bright-blue !important;
        }
      }
    }
  }
  .sidenav-divider {
    border-top: none !important;
  }
  .sidenav-item.open:nth-child(1) {
    border-top: none;
  }
  .sidenav-toggle::after {
    display: none;
  }
  //.sidenav-link:hover {
  //  color: $bright-dark;
  //}
}

@media screen and (max-width: 680px) {
  .hide {
    display: none;
  }
}
</style>
