<template>
  <div class="message-container">
    <div class="message-container__header">
      <HeaderChat :title="title" v-show="title" />
    </div>
    <div class="message-container__none" v-show="!title">Выберите диалог</div>
    <div class="message-container__content" id="chat" v-show="messages.length">
      <div class="message-dates">
        <div class="date" v-for="(group, index) in messages" :key="index">
          <div class="date-label">{{ group.date }}</div>
          <div class="date-messages">
            <div
              class="date-messages__item"
              v-for="message in group.messages"
              :key="message.message_id"
            >
              <Message
                :type="type"
                :message="message"
                @editMessage="setEditMessage"
                @deleteMessage="(id, canDelete) => $emit('deleteMessage', id, canDelete)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="message-container__none" v-show="!messages.length && title">Нет сообщений</div>
    <div class="message-container__input" v-show="title && showInputZone">
      <InputMessage
        @sendMessage="(message, fileId) => $emit('sendMessage', message, fileId)"
        :editedMessage="editedMessage"
        :editedFile="editedFile"
        @cancelEditingMessage="cancelEditingMessage"
        @editMessage="editMessage"
      />
    </div>
    <div class="message-container__readonly" v-show="!showInputZone">
      Данный диалог доступен только для чтения
    </div>
  </div>
</template>

<script>
import HeaderChat from './components/HeaderChat'
import InputMessage from './components/InputMessage'
import Message from './components/Message'
export default {
  name: 'MessagesContainer',
  props: ['title', 'messages', 'showInputZone', 'type'],
  components: { Message, InputMessage, HeaderChat },
  data() {
    return {
      editedMessage: null,
      editedMessageId: null,
      editedFile: null
    }
  },
  methods: {
    /**
     * Скролл на последнии сообщения
     */
    scrollChat() {
      let container = document.getElementById('chat')
      setTimeout(() => {
        container.scrollTop = container.scrollHeight
      })
    },
    /**
     * Фунция для пагинации, которой нет
     * @param state
     */
    logState(state) {
      this.title && this.$emit('getMoreMessages', state)
    },
    /**
     * Установить сообщение на редактирование
     * @param content
     * @param id
     * @param file
     */
    setEditMessage(content, id, file) {
      this.editedMessage = content
      this.editedMessageId = id
      if (file) {
        this.editedFile = {
          file: {
            extension: file.extension,
            name: file.name,
            id: file.id
          },
          file_url: file.path
        }
      }
    },
    /**
     * Отменить редактирование
     */
    cancelEditingMessage() {
      this.editedMessage = null
      this.editedMessageId = null
      this.editedFile = null
    },
    /**
     * Сохранить редактирование
     * @param content
     * @param fileId
     */
    editMessage(content, fileId) {
      this.$emit('editMessage', content, this.editedMessageId, fileId)
      this.cancelEditingMessage()
    }
  },
  watch: {}
}
</script>

<style scoped lang="scss">
@import '../../../styleVariables';
.message-container {
  background-color: $main-white;
  border-left: 1px solid #edf3fa;
  border-radius: 0 8px 0 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 87vh;
  &__header {
    border-bottom: 1px solid #edf3fa;
  }
  &__content {
    padding: 24px;
    overflow-y: auto;
    height: 72vh;
    .message-dates {
      .date {
        &-label {
          margin: 24px auto 9px auto;
          width: fit-content;
          font-family: $font-regular;
          font-size: 14px;
          line-height: 18px;
          color: $main-gray;
          position: sticky;
          top: 0;
          background-color: $main-white;
          padding: 2px 6px;
          border-radius: 50px;
        }
      }
    }
  }
  &__none {
    color: $main-gray;
    font-size: 16px;
    width: fit-content;
    margin: auto;
    height: 100%;
    display: flex;
    align-items: center;
  }
  &__input {
    display: flex;
    flex-grow: 3;
    align-items: flex-end;
  }
  &__readonly {
    color: $main-dark;
    font-family: $font-regular;
    font-size: 16px;
    line-height: 20px;
    padding: 32px 0;
    text-align: center;
  }
}
</style>
