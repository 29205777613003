<template>
  <div class="student-profile">
    <div class="left-side">
      <div class="personal-card">
        <PersonalCard
          @update="
            getStudentInfo()
            getStudentLessons()
          "
          :info="profileInfo"
          :lessonCount="lessonCount"
          type="student"
        />
      </div>
      <div class="gantt-statistic">
        <Gantt />
      </div>
    </div>
    <div class="right-side">
      <div class="personal-schedule">
        <PersonalSchedule :lessons="lessons" type="student" />
      </div>
    </div>
  </div>
</template>

<script>
import PersonalCard from '@/views/Profile/components/PersonalCard'
import PersonalSchedule from '@/views/Profile/components/PersonalSchedule'
import axios from 'axios'
import { domain, MicroServices } from '@/globalVariables'
import Gantt from '@/views/Profile/components/Gantt'
export default {
  name: 'StudentProfile',
  components: { PersonalCard, PersonalSchedule, Gantt },
  data() {
    return {
      profileInfo: {},
      lessons: [],
      lessonCount: null
    }
  },
  mounted() {
    this.getStudentInfo()
    this.getStudentLessons()
    this.getStudentLessonsCount()
  },
  methods: {
    /**
     * получить личную информацию текущего пользователя (студента)
     */
    getStudentInfo() {
      axios({
        url: `${domain}/monolit/Student/getItem/${this.$cookie.get('id')}`,
        method: 'GET'
      })
        .then((response) => {
          this.profileInfo = response.data.data
          this.$root.$emit('updateName', response.data.data.full_name)
        })
        .catch((error) => this.errorAlert(error))
    },

    /**
     * Получить расписание на день текущего пользователя
     */
    getStudentLessons() {
      axios({
        url: `${domain}/monolit/Lesson/getLessonsForDay`,
        method: 'GET'
      }).then((response) => {
        this.lessons = response.data.data
      })
    },

    /**
     * Получить остаток занятий текущего пользователя (студента)
     */
    getStudentLessonsCount() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Student/getAvailableLessonsAmount`,
        method: 'GET'
      })
        .then((response) => {
          this.lessonCount = response.data.data.lessons_amount
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../styleVariables';
.student-profile {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 28px;
  padding: 30px 24px;
  background-color: $background-blue;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  .right-side {
    max-width: 844px;
    width: 100%;
  }
  .left-side {
    //max-width: 740px;
    width: 100%;
    .personal-card {
      margin-bottom: 30px;
    }
    .non-working-days {
      margin-bottom: 30px;
    }

    .gantt-statistic {
      max-width: 762px;
    }
  }
}

@media screen and (max-width: 1500px) {
  .student-profile {
    grid-template-columns: 1fr;
    /deep/ .personal-info-header {
      &__name {
        max-width: unset !important;
      }
      .save {
        margin-right: 18px;
      }
    }
    .gantt-statistic {
      margin-bottom: 30px;
    }
    .right-side {
      max-width: unset;
    }
  }
}

@media screen and (max-width: 680px) {
  .gantt-statistic {
    display: none;
  }
  .personal-schedule {
    display: none;
  }
  .student-profile {
    padding: 8px 16px;
  }
}
</style>
