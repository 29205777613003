var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"message"},[_c('div',{staticClass:"message-header",class:{
      'click-title':
        !_vm.message.is_current_user_message &&
        (_vm.$cookie.get('internalName') === 'admin' || _vm.$cookie.get('internalName') === 'manager')
    },on:{"click":_vm.goToUser}},[_vm._v("\n    "+_vm._s(_vm.message.title)+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"message-content",style:(_vm.message.is_current_user_message ? 'background-color: #3264C3' : 'background-color: #F5FAFF')},[_c('div',{staticClass:"message-content__area"},[_c('div',{staticClass:"message-content__area-text",style:(_vm.message.is_current_user_message ? 'color: #ffffff' : 'color: #222B45')},[_c('div',{staticClass:"content"},[_vm._v(_vm._s(_vm.message.content))])]),_vm._v(" "),(_vm.message.is_current_user_message && _vm.type === 'participant')?_c('div',{staticClass:"message-content__area-controls"},[(_vm.message.can_be_deleted_for_all)?_c('div',{staticClass:"edit",on:{"click":function($event){return _vm.$emit('editMessage', _vm.message.content, _vm.message.message_id, _vm.message.files[0])}}},[_c('img',{attrs:{"src":require("../../../../assets/img/ES/edit_chat.svg"),"alt":"edit"}})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"delete"},[_c('img',{attrs:{"src":require("../../../../assets/img/ES/close_chat.svg"),"alt":"delete"},on:{"click":function($event){return _vm.$emit('deleteMessage', _vm.message.message_id, _vm.message.can_be_deleted_for_all)}}})])]):(!_vm.message.is_current_user_message && _vm.type === 'participant')?_c('div',{staticClass:"message-content__area-controls"},[_c('div',{staticClass:"delete"},[_c('img',{attrs:{"src":require("../../../../assets/img/ES/close_chat.svg"),"alt":"delete"},on:{"click":function($event){return _vm.$emit('deleteMessage', _vm.message.message_id, false)}}})])]):_vm._e()]),_vm._v(" "),(_vm.message.files.length)?_c('div',{staticClass:"file",on:{"click":function($event){return _vm.openFile(_vm.filePath)}}},[(
          _vm.message.files[0].extension === 'png' ||
          _vm.message.files[0].extension === 'jpeg' ||
          _vm.message.files[0].extension === 'jpg' ||
          _vm.message.files[0].extension === 'svg'
        )?_c('img',{attrs:{"src":_vm.filePath,"alt":"file"}}):_c('img',{attrs:{"src":require("../../../../assets/img/ES/file_icon.svg"),"alt":"file"}}),_vm._v(" "),_c('div',{staticClass:"file-name"},[_vm._v(_vm._s(_vm.message.files[0].name))])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"message-content__time",style:(_vm.message.is_current_user_message ? 'color: #C5CEE0' : 'color: #8F9BB3')},[(_vm.message.is_updated)?_c('span',[_vm._v("Отредактировано")]):_vm._e(),_vm._v("\n      "+_vm._s(_vm.message.create_time)+"\n      "),(_vm.message.is_current_user_message && _vm.message.can_be_deleted_for_all)?_c('img',{attrs:{"src":require("../../../../assets/img/ES/read_chat.svg"),"alt":"ok"}}):(_vm.message.is_current_user_message && !_vm.message.can_be_deleted_for_all)?_c('img',{attrs:{"src":require("../../../../assets/img/ES/check_all.svg"),"alt":"read"}}):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }