<template>
  <b-modal
    :id="'modal' + (id || '')"
    hide-footer
    hide-header
    ref="myModalRef"
    @hidden="clearStore()"
    :size="lg"
  >
    <div class="title">
      <!--      <span> {{ type.name }} {{ data[0] ? (data[0].id ? `#${data[0].id}` : '') : '' }}</span>-->
      <span>Создание нового пользователя</span>
    </div>
    <span class="required-input">* Обязательное поле</span>
    <b-form-row :class="{ 'lg-modal': lg }">
      <b-form-group style="width: 100%">
        <div v-for="(rec, index) in data" :key="index">
          <div v-if="rec === data.fields || rec === data[0]" :label="rec.type" class="col">
            <div v-for="field in fields" :key="field.key">
              <Widget
                :key="field.key"
                @$emits="$emits"
                v-if="
                  !Array.isArray(rec[field.key]) &&
                  typeof rec[field.key] === 'object' &&
                  rec[field.key] !== null &&
                  rec[field.key].value !== 'save' &&
                  rec[field.key].value !== 'saveAndAdd' &&
                  rec[field.key].value !== 'editAll'
                "
                :type="rec[field.key].type"
                v-bind="renderBindWidget(field.key, data.fields)"
                style="margin-bottom: 20px"
                :style="
                  rec[field.key].type !== 'Button'
                    ? 'width: 100%'
                    : lg
                    ? 'margin-bottom: 30px'
                    : 'margin-bottom: 5px'
                "
              />
              <div
                v-else-if="
                  rec[field.key] &&
                  isJsonString(rec[field.key]) &&
                  typeof JSON.parse(rec[field.key]) === 'object'
                "
                :key="field.key"
              >
                <Widget
                  :key="field.key"
                  @$emits="$emits"
                  type="json"
                  v-bind="{ item: JSON.parse(rec[field.key]), isModal: true }"
                  style="width: 100%"
                />
              </div>
              <div class="view" v-else>
                <div class="view-item" v-html="renderValue(field.label, rec[field.key], rec)"></div>
              </div>
            </div>
          </div>
        </div>
      </b-form-group>
    </b-form-row>
    <div class="modal-footer">
      <div>
        <div class="save-and-add-btn">
          <div v-for="(rec, index) in data" :key="index">
            <div v-if="rec === data.fields || rec === data[0]" :label="rec.type" class="col">
              <div v-for="field in fields" :key="field.key">
                <Widget
                  :key="field.key"
                  @$emits="$emits"
                  v-if="
                    !Array.isArray(rec[field.key]) &&
                    typeof rec[field.key] === 'object' &&
                    rec[field.key] !== null &&
                    rec[field.key].value === 'saveAndAdd'
                  "
                  :type="rec[field.key].type"
                  v-bind="renderBindWidget(field.key, data.fields)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="control-btns">
        <div>
          <button class="cancel-btn" @click="$bvModal.hide('modal' + (id || ''))">Отмена</button>
          <!--      <img src="../../../assets/img/CloseModal.svg" alt="close" />-->
        </div>
        <div class="save-btn">
          <div v-for="(rec, index) in data" :key="index">
            <div v-if="rec === data.fields || rec === data[0]" :label="rec.type" class="col">
              <div v-for="field in fields" :key="field.key">
                <Widget
                  :key="field.key"
                  @$emits="$emits"
                  v-if="
                    !Array.isArray(rec[field.key]) &&
                    typeof rec[field.key] === 'object' &&
                    rec[field.key] !== null &&
                    rec[field.key].value === 'save'
                  "
                  :type="rec[field.key].type"
                  v-bind="renderBindWidget(field.key, data.fields)"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="edit-all-btn">
          <div v-for="(rec, index) in data" :key="index">
            <div v-if="rec === data.fields || rec === data[0]" :label="rec.type" class="col">
              <div v-for="field in fields" :key="field.key">
                <Widget
                  :key="field.key"
                  @$emits="$emits"
                  v-if="
                    !Array.isArray(rec[field.key]) &&
                    typeof rec[field.key] === 'object' &&
                    rec[field.key] !== null &&
                    rec[field.key].value === 'editAll'
                  "
                  :type="rec[field.key].type"
                  v-bind="renderBindWidget(field.key, data.fields)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import ModalWindowEvents from './ModalWindowEvents'

export default {
  props: ['lg'],
  mixins: [ModalWindowEvents],
  name: 'ModalWindow'
}
</script>

<style scoped lang="scss">
@import '../../Widgets/ColorVariables';
@import '../../../styleVariables';

.modal-footer {
  min-height: 69px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid $input-gray;
  padding-right: 24px;
  padding-left: 24px;
  margin-left: -24px;
  margin-right: -24px;
  height: auto;
  .control-btns {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
}
.cancel-btn {
  font-size: 14px;
  line-height: 24px;
  font-family: $font-bold;
  height: 33px;
  width: 137px;
  color: $bright-blue;
  border: 1px solid $bright-blue;
  border-radius: 4px;
  outline: none;
  background: transparent !important;
  transition: 0.2s ease;
  text-align: center;
  margin-left: -24px;
  padding: 0 !important;
}
///deep/.cancel-btn:hover:enabled {
//  background: #f3f3f3 !important;
//  border-radius: 3px;
//  padding: 0 12px;
//}
.save-btn,
.edit-all-btn {
  /deep/ .fill {
    font-size: 14px;
    line-height: 24px;
    font-family: $font-bold;
    height: 33px;
    width: 137px;
    color: $main-white;
    border: 1px solid $bright-blue;
    border-radius: 4px;
    outline: none;
    background: $bright-blue !important;
    transition: 0.2s ease;
    text-align: center;
    padding: 0 !important;
  }
  ///deep/.fill:hover:enabled,
  //.edit-all-btn:hover:enabled {
  //  background: #f3f3f3 !important;
  //  border-radius: 3px;
  //  padding: 0 12px;
  //}
  /deep/ .fill:disabled,
  .edit-all-btn:disabled {
    background-color: $light-gray !important;
    border: 1px solid transparent;
    color: $main-white !important;
  }
  /deep/ .fill:active,
  .edit-all-btn:active {
    background-color: #0e52d7 !important;
  }
}
.save-and-add-btn {
  /deep/.fill {
    font-size: 18px !important;
    line-height: 21px;
    height: 30px;
    color: $text-dark-gray;
    border: none;
    outline: none;
    background: transparent !important;
    font-weight: 400 !important;
    width: 269px !important;
    text-align: center;
    transition: all 0.2s ease-in;
    margin-left: -30px;
    margin-right: -24px;
    padding: 0 !important;
  }
  /deep/.fill:hover:enabled {
    background: #f3f3f3 !important;
    border-radius: 3px;
    padding: 0 12px;
  }
  /deep/ .fill:disabled {
    color: $disabled-btn-gray;
  }
}
/deep/ .btn_view {
  border-radius: 3px;
  height: 30px;
  font-size: 14px;
  line-height: 16px;
  outline: none;
  transition: 0.2s;
  border: none;
  color: $main-white;
  background-color: $main-blue;
}
/deep/ .btn_view:hover {
  background-color: $hover-blue;
}
/deep/ button.btn.btn-outline-secondary {
  border: 0.75px solid #c1c1c1;
  box-sizing: border-box;
  border-radius: 3px;
  outline: none;
  color: $main-gray;
  background-color: $main-white;
  height: 30px;
  font-size: 14px;
  line-height: 16px;
  transition: 0.2s;
}
/deep/ button.btn.btn-outline-secondary:hover {
  color: $text-dark-gray;
}
/deep/ .modal-body {
  background: $main-white;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
}
/deep/ .btn-secondary {
  border-radius: 3px;
  height: 30px;
  border: none;
  font-size: 14px;
  line-height: 16px;
  outline: none;
  transition: 0.2s;
  color: $main-white;
  background-color: $main-blue;
  margin-top: 20px;
}
/deep/ .btn-secondary:hover {
  background-color: $hover-blue;
}

.title {
  color: $main-dark;
  margin-bottom: 2px;
  font-size: 18px;
  line-height: 33px;
}
.required-input {
  color: $main-dark;
  font-family: $font-regular;
  margin-bottom: 16px;
}
span {
  word-break: break-all;
}
.close-modal {
  position: absolute;
  top: 21px;
  right: 30px;
  cursor: pointer;
}
/deep/ {
  .modal-content {
    border-radius: 10px;
  }
  .d-flex {
    justify-content: center;
  }
}
.view {
  //border-top: 1px solid $input-gray;
  padding: 12px 24px;
  margin: 0 -32px;
  &-item {
    display: flex;
    flex-direction: column;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    color: $main-dark;
    word-break: break-word;
    /deep/.btn-outline-primary {
      border-radius: 3px;
      height: 30px;
      font-size: 14px;
      line-height: 16px;
      outline: none;
      transition: 0.2s;
      border: none;
      color: $main-white;
      background-color: $main-blue;
    }
    /deep/.btn-outline-primary:hover {
      background-color: $hover-blue;
    }
  }
}

.lg-modal {
  .col {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 24px;
    grid-auto-rows: 82px;
    margin: 0 -6px;
    .d-flex {
      align-items: flex-end;
      justify-content: flex-end;
      grid-column-start: 1;
      grid-column-end: 3;
      margin-bottom: -24px;
    }
  }
}
</style>
