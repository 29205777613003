<template>
  <div>
    <div
      class="nav-container"
      v-if="
        lessonInfo.kls_type_code !== 'TEACHER_TEST' &&
        lessonInfo.kls_type_code !== 'TEACHER_FIRST_TEST'
      "
    >
      <div>
        <button @click="openLessonCard" :class="{ selected: lessonCardSelected }">Занятие</button>
        <button @click="openGlossary" :class="{ selected: glossarySelected }">Словарь</button>
      </div>
    </div>
    <TeacherCardToFill
      v-if="showCardToFill && lessonInfo.previous_lesson_id === null && lessonHasStarted"
      :lessonInfo="lessonInfo"
      @saveLessonCard="getLessonInfo"
      @techProblem="techProblem"
      @openCancelModalForTeacher="openCancelModalForTeacher"
    />
    <TeacherCardToFill
      v-else-if="newLessonInfo && lessonHasStarted"
      :lessonInfo="newLessonInfo"
      @saveLessonCard="getLessonInfo"
      @techProblem="techProblem"
      @openCancelModalForTeacher="openCancelModalForTeacher"
    />
    <div v-if="showLessonCard">
      <div
        class="card-container"
        :class="{
          studentCard: userRole === 'student'
        }"
        v-if="!glossarySelected"
      >
        <div class="card-header-container">
          <div class="lesson-details">
            <span class="name">Занятие {{ lessonInfo.sequence_number }}</span>
            <div class="lesson-date">
              <img src="../../assets/img/icons/calendar-dark.svg" />
              <span>{{ formatDate(lessonInfo.date) }}</span>
            </div>
            <div class="lesson-time">
              <img src="../../assets/img/icons/alarm.svg" />
              <span>{{ formatTime(lessonInfo.hour) }}</span>
            </div>
          </div>
          <div class="edit-btn" v-if="showControlButtons">
            <button
              @click="setEditMode"
              v-if="
                ($cookie.get('internalName') === 'admin' ||
                  $cookie.get('internalName') === 'methodologist' ||
                  (($cookie.get('internalName') === 'teacher' ||
                    $cookie.get('internalName') === 'methodologist') &&
                    lessonHasStarted)) &&
                lessonInfo.can_be_updated
              "
            >
              <img src="../../assets/img/icons/edit.svg" />
              <span class="edit-label">Редактировать</span>
            </button>
            <div
              class="student-controls-btn"
              v-if="
                $cookie.get('internalName') === 'teacher' ||
                ($cookie.get('internalName') === 'methodologist' &&
                  lessonInfo.kls_type_code !== 'REGULAR' &&
                  lessonInfo.kls_type_code !== 'TEACHER_TEST_REGULAR_LESSON')
              "
            >
              <button
                class="lesson-cancel-btn"
                v-if="lessonInfo.kls_status_code === 'LESSON_PLANNED'"
                @click="openCancelModalForTeacher(lessonInfo.id)"
              >
                Отменить урок
              </button>
            </div>
            <div
              class="student-controls-btn"
              v-if="
                ($cookie.get('internalName') === 'manager' ||
                  $cookie.get('internalName') === 'admin') &&
                lessonInfo.kls_status_code === 'LESSON_PLANNED'
              "
            >
              <button class="lesson-cancel-btn" @click="openTimeChangeModal">Перенести урок</button>
            </div>
            <div
              class="student-controls-btn"
              v-if="
                $cookie.get('internalName') === 'manager' || $cookie.get('internalName') === 'admin'
              "
            >
              <button
                class="lesson-cancel-btn"
                v-if="lessonInfo.kls_status_code === 'LESSON_PLANNED'"
                @click="openCancelModalForManager"
              >
                Отменить урок
              </button>
            </div>
          </div>
          <div class="student-controls-btn" v-if="$cookie.get('internalName') === 'student'">
            <button
              class="lesson-cancel-btn"
              @click="openCancelModalForTeacher(lessonInfo.id)"
              v-if="lessonInfo.kls_status_code === 'LESSON_PLANNED' && !lessonInfo.in_arbitration"
            >
              Отменить урок
            </button>
            <button
              class="complain-btn"
              @click="openModal"
              v-if="!studentComplaint && startLesson && !lessonInfo.in_arbitration"
            >
              Пожаловаться
            </button>
          </div>
          <div class="arbitration-status" v-if="lessonInfo.in_arbitration">
            <img
              src="../../assets/img/ES/error_outline.svg"
              alt="arbitration"
              v-b-tooltip="`Арбитраж спора (${lessonInfo.kls_complaint_status_name})`"
            />
          </div>
        </div>
        <div class="lesson-link-container" v-if="statusCode === 'LESSON_PLANNED'">
          <label>Ссылка на урок:</label>
          <input
            type="text"
            v-if="
              editLessonCardMode &&
              ($cookie.get('internalName') === 'manager' || $cookie.get('internalName') === 'admin')
            "
            v-model="linkToZoom"
          />
          <a
            :href="
              lessonInfo.link_to_zoom.indexOf('http') !== -1
                ? lessonInfo.link_to_zoom
                : `http://${lessonInfo.link_to_zoom}`
            "
            target="_blank"
            v-else-if="lessonInfo.link_to_zoom"
            class="lesson-link"
            >{{ lessonInfo.link_to_zoom }}</a
          >
          <span v-else-if="!lessonInfo.link_to_zoom">Ссылка недоступна</span>
        </div>
        <div class="card-members">
          <div class="card-member-name" v-if="$cookie.get('internalName') !== 'student'">
            <span class="label" v-if="lessonInfo.student_name || lessonInfo.student_short_name"
              >Student Name</span
            >
            <span
              class="name"
              v-if="
                $cookie.get('internalName') === 'manager' || $cookie.get('internalName') === 'admin'
              "
              >{{ lessonInfo.student_name }}</span
            >
            <span
              class="name"
              v-if="
                $cookie.get('internalName') !== 'manager' && $cookie.get('internalName') !== 'admin'
              "
              >{{ lessonInfo.student_short_name }}</span
            >
          </div>
          <div
            class="card-member-name"
            v-if="
              ((typeCode !== 'PLACEMENT_TEST' && typeCode !== 'CHECK_UP') ||
                $cookie.get('internalName') === 'student') &&
              $cookie.get('internalName') !== 'teacher' &&
              $cookie.get('internalName') !== 'methodologist'
            "
          >
            <span
              class="label"
              v-show="
                lessonInfo.teacher_name ||
                lessonInfo.teacher_short_name ||
                teacher ||
                ($cookie.get('internalName') === 'student' && lessonInfo.methodologist_short_name)
              "
              >Teacher Name</span
            >
            <vue-select
              v-if="
                editLessonCardMode &&
                status === 43 &&
                ($cookie.get('internalName') === 'manager' ||
                  $cookie.get('internalName') === 'admin')
              "
              v-model="teacher"
              :clearable="false"
              style="width: 260px"
              @open="getTeachers(lessonInfo.date, lessonInfo.student_uuid)"
              :options="teachers"
            >
              <template slot="option" slot-scope="option">
                <div class="d-center">
                  <span>{{ option.full_name }}</span>
                </div>
              </template>
              <template slot="selected-option">
                <div class="selected d-center">
                  {{ teacher.full_name }}
                </div>
              </template>
            </vue-select>
            <span
              class="name"
              v-else-if="
                $cookie.get('internalName') === 'manager' || $cookie.get('internalName') === 'admin'
              "
              >{{ lessonInfo.teacher_name }}</span
            >
            <span
              class="name"
              v-else-if="
                $cookie.get('internalName') !== 'manager' &&
                $cookie.get('internalName') !== 'admin' &&
                lessonInfo.teacher_short_name
              "
              >{{ lessonInfo.teacher_short_name }}</span
            >
            <span
              class="name"
              v-else-if="
                $cookie.get('internalName') === 'student' && lessonInfo.methodologist_short_name
              "
              >{{ lessonInfo.methodologist_short_name }}</span
            >
          </div>
          <div
            class="card-member-name"
            v-if="
              $cookie.get('internalName') !== 'student' &&
              $cookie.get('internalName') !== 'methodologist' &&
              (typeCode === 'PLACEMENT_TEST' ||
                typeCode === 'TEACHER_TEST' ||
                typeCode === 'CHECK_UP')
            "
          >
            <span class="label">Methodist Name</span>
            <vue-select
              v-if="
                editLessonCardMode &&
                statusCode === 'LESSON_PLANNED' &&
                ($cookie.get('internalName') === 'manager' ||
                  $cookie.get('internalName') === 'admin')
              "
              v-model="methodist"
              :clearable="false"
              style="width: 260px"
              @open="getMethodists(lessonInfo.date)"
              :options="methodists"
            >
              <template slot="option" slot-scope="option">
                <div class="d-center">
                  <span>{{ option.full_name }}</span>
                </div>
              </template>
              <template slot="selected-option">
                <div class="selected d-center">
                  {{ methodist.full_name }}
                </div>
              </template>
            </vue-select>
            <span
              class="name"
              v-else-if="
                $cookie.get('internalName') === 'manager' || $cookie.get('internalName') === 'admin'
              "
              >{{ lessonInfo.methodologist_name }}</span
            >
            <span
              class="name"
              v-else-if="
                $cookie.get('internalName') !== 'manager' && $cookie.get('internalName') !== 'admin'
              "
              >{{ lessonInfo.methodologist_short_name }}</span
            >
          </div>
        </div>
        <div class="card-status-container">
          <div class="card-status">
            <span>Status</span>
            <div
              v-if="!removeStatus"
              :class="{
                lessonFinished: statusCode === 'LESSON_ENDED',
                lessonPlanned: statusCode === 'LESSON_PLANNED',
                lessonMissedByStudent: statusCode === 'SKIPPED_STUDENT',
                lessonMissedByStudentWithReason: statusCode === 'SKIPPED_STUDENT_WITH_REASON',
                lessonMissedByTeacher: statusCode === 'SKIPPED_TEACHER',
                lessonCanceled: statusCode === 'CANCELLED'
              }"
            >
              {{ statusName }}
              <img
                v-if="editLessonCardMode && canChangeStatus"
                @click="setRemoveStatus(true)"
                src="../../assets/img/ES/close_big.svg"
                alt="close"
              />
            </div>
            <div class="choose-status" v-if="removeStatus" v-click-outside="setDefaultStatus">
              <statuses
                @chooseStatus="changeStatus"
                :currentType="typeCode"
                :currentStatus="lessonInfo.kls_status_code"
              />
            </div>
          </div>
          <div class="card-type" v-if="$cookie.get('internalName') !== 'student'">
            <span>Type</span>
            <div
              v-if="!removeType"
              :class="{
                regularLesson: typeCode === 'REGULAR' || typeCode === 'TEACHER_TEST_REGULAR_LESSON',
                teacherTesting: typeCode === 'TEACHER_TEST',
                placementTest: typeCode === 'PLACEMENT_TEST',
                checkUpLesson: typeCode === 'CHECK_UP',
                progressTest:
                  typeCode === 'CHECK_PROGRESS' || typeCode === 'TEACHER_TEST_PROGRESS_TEST_LESSON'
              }"
            >
              {{ typeName }}
              <img
                @click="setRemoveType(true)"
                v-if="editLessonCardMode && canChangeLessonType"
                src="../../assets/img/ES/close_big.svg"
                alt="close"
              />
            </div>
            <div class="choose-type" v-if="removeType" v-click-outside="setDefaultType">
              <types @chooseType="changeType" />
            </div>
          </div>
        </div>

        <!-- ЕСЛИ НЕ СТУДЕНТ -->
        <div class="card-info-container" v-if="$cookie.get('internalName') !== 'student'">
          <div class="first-table">
            <div class="table-cell">
              <span class="table-cell-label">Level</span>
              <vue-select
                class="select"
                v-if="
                  editLessonCardMode &&
                  ($cookie.get('internalName') === 'manager' ||
                    $cookie.get('internalName') === 'admin' ||
                    $cookie.get('internalName') === 'methodologist')
                "
                v-model="level"
                :clearable="false"
                :options="levels"
                @input="getCEFRByLevel"
                @open="getLevels"
              >
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    <span>{{ option.name }}</span>
                  </div>
                </template>
                <template slot="selected-option">
                  <div class="selected d-center">
                    {{ level.name }}
                  </div>
                </template>
              </vue-select>
              <span class="table-cell-name" v-else-if="lessonInfo.kls_level_name !== null">{{
                lessonInfo.kls_level_name
              }}</span>
              <span class="table-cell-name" v-else-if="lessonInfo.kls_level_name === null">
                no
              </span>
            </div>
            <div class="table-cell" v-if="lessonTypeCode === 'PLACEMENT_TEST'">
              <span class="table-cell-label">CEFR</span>
              <vue-select
                class="select"
                v-if="
                  editLessonCardMode &&
                  ($cookie.get('internalName') === 'manager' ||
                    $cookie.get('internalName') === 'admin' ||
                    $cookie.get('internalName') === 'methodologist')
                "
                v-model="CEFR"
                :clearable="false"
                :options="CEFRs"
                @input="getLevelByCEFR"
                @open="getCEFRLevels"
              >
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    <span>{{ option.name }}</span>
                  </div>
                </template>
                <template slot="selected-option">
                  <div class="selected d-center">
                    {{ CEFR.name }}
                  </div>
                </template>
              </vue-select>
              <span class="table-cell-name" v-else-if="lessonInfo.kls_europe_level_name !== null">{{
                lessonInfo.kls_europe_level_name
              }}</span>
              <span class="table-cell-name" v-else-if="lessonInfo.kls_europe_level_name === null">
                no
              </span>
            </div>
            <div class="table-cell">
              <span class="table-cell-label">Revision steps</span>
              <input
                id="revision-steps-input"
                :class="{ 'validation-error': fieldValidations.revisionSteps }"
                type="text"
                v-if="
                  editLessonCardMode &&
                  ($cookie.get('internalName') === 'manager' ||
                    $cookie.get('internalName') === 'admin' ||
                    $cookie.get('internalName') === 'methodologist')
                "
                v-model="revisionSteps"
              />
              <span class="table-cell-name" v-else-if="lessonInfo.revision_step !== null">{{
                lessonInfo.revision_step
              }}</span>
              <span class="table-cell-name" v-else-if="lessonInfo.revision_step === null">
                no
              </span>
              <b-tooltip
                target="revision-steps-input"
                triggers="hover"
                v-if="
                  editLessonCardMode &&
                  fieldValidations.revisionSteps &&
                  ($cookie.get('internalName') === 'manager' ||
                    $cookie.get('internalName') === 'admin' ||
                    $cookie.get('internalName') === 'methodologist')
                "
              >
                Revision steps не может быть меньше 1 и больше 50
              </b-tooltip>
            </div>

            <div class="table-cell" v-if="lessonTypeCode === 'PLACEMENT_TEST'">
              <span class="table-cell-label">Revision</span>
              <span class="table-cell-name" v-if="lessonInfo.revision !== null">{{
                lessonInfo.revision.join(', ')
              }}</span>
              <span class="table-cell-name" v-else-if="lessonInfo.revision === null"> no </span>
            </div>
            <div class="table-cell">
              <span class="table-cell-label">Lesson number</span>
              <input
                id="lesson-number-input"
                :class="{ 'validation-error': fieldValidations.lessonNumber }"
                type="text"
                v-if="
                  editLessonCardMode &&
                  ($cookie.get('internalName') === 'manager' ||
                    $cookie.get('internalName') === 'admin')
                "
                v-model="lessonNumber"
              />
              <span class="table-cell-name" v-else-if="lessonInfo.lesson_number !== null">{{
                lessonInfo.lesson_number
              }}</span>
              <span class="table-cell-name" v-else-if="lessonInfo.lesson_number === null">
                no
              </span>
              <b-tooltip
                target="lesson-number-input"
                triggers="hover"
                v-if="
                  editLessonCardMode &&
                  fieldValidations.lessonNumber &&
                  ($cookie.get('internalName') === 'manager' ||
                    $cookie.get('internalName') === 'admin')
                "
              >
                Lesson number не может быть меньше чем в предыдущем занятии
              </b-tooltip>
            </div>
            <div class="table-cell" v-if="lessonTypeCode !== 'PLACEMENT_TEST'">
              <span class="table-cell-label">Last taught page</span>
              <input
                id="last-taught-page-input"
                :class="{
                  'validation-error':
                    (fieldValidations.lastTaughtPageRequired && lessonInfo.has_filled_card) ||
                    fieldValidations.lastTaughtPage ||
                    fieldValidations.newPages
                }"
                type="text"
                v-if="
                  editLessonCardMode &&
                  ($cookie.get('internalName') === 'manager' ||
                    $cookie.get('internalName') === 'admin')
                "
                v-model="lastPage"
                @input="calculateNewPages()"
              />
              <span class="table-cell-name" v-else-if="lessonInfo.last_taught_page !== null">{{
                lessonInfo.last_taught_page
              }}</span>
              <span class="table-cell-name" v-else-if="lessonInfo.last_taught_page === null">
                no
              </span>
              <b-tooltip
                target="last-taught-page-input"
                triggers="hover"
                v-if="
                  editLessonCardMode &&
                  fieldValidations.lastTaughtPageRequired &&
                  lessonInfo.has_filled_card
                "
              >
                Поле обязательно для заполнения
              </b-tooltip>
              <b-tooltip
                target="last-taught-page-input"
                triggers="hover"
                v-else-if="
                  editLessonCardMode &&
                  fieldValidations.lastTaughtPage &&
                  ($cookie.get('internalName') === 'manager' ||
                    $cookie.get('internalName') === 'admin')
                "
              >
                Last taught page не должен быть меньше или равняться Last taught page предыдущего
                урока
              </b-tooltip>
              <b-tooltip
                target="last-taught-page-input"
                triggers="hover"
                v-else-if="
                  editLessonCardMode &&
                  fieldValidations.newPages &&
                  ($cookie.get('internalName') === 'manager' ||
                    $cookie.get('internalName') === 'admin')
                "
              >
                New pages не может быть больше 12
              </b-tooltip>
            </div>
            <div class="table-cell" v-if="lessonTypeCode !== 'PLACEMENT_TEST'">
              <span class="table-cell-label">New words</span>
              <input
                id="new-words-input"
                :class="{ 'validation-error': fieldValidations.newWords }"
                type="text"
                v-if="
                  editLessonCardMode &&
                  ($cookie.get('internalName') === 'manager' ||
                    $cookie.get('internalName') === 'admin')
                "
                v-model="newWords"
              />
              <span class="table-cell-name" v-else-if="lessonInfo.new_words !== null">{{
                lessonInfo.new_words
              }}</span>
              <span class="table-cell-name" v-else-if="lessonInfo.new_words === null"> no </span>
              <b-tooltip
                target="new-words-input"
                triggers="hover"
                v-if="
                  editLessonCardMode &&
                  fieldValidations.newWords &&
                  ($cookie.get('internalName') === 'manager' ||
                    $cookie.get('internalName') === 'admin')
                "
              >
                Поле New words не может быть меньше 0 и больше 50
              </b-tooltip>
            </div>
            <div class="table-cell" v-if="lessonTypeCode !== 'PLACEMENT_TEST'">
              <span class="table-cell-label">Grammar</span>
              <input
                type="text"
                v-if="
                  editLessonCardMode &&
                  ($cookie.get('internalName') === 'manager' ||
                    $cookie.get('internalName') === 'admin')
                "
                v-model="grammar"
              />
              <span class="table-cell-name" v-else-if="lessonInfo.grammar !== null">{{
                lessonInfo.grammar
              }}</span>
              <span class="table-cell-name" v-else-if="lessonInfo.grammar === null"> no </span>
            </div>
            <div class="table-cell" v-if="lessonTypeCode !== 'PLACEMENT_TEST'">
              <span class="table-cell-label">Reading</span>
              <input
                type="text"
                v-if="
                  editLessonCardMode &&
                  ($cookie.get('internalName') === 'manager' ||
                    $cookie.get('internalName') === 'admin')
                "
                v-model="reading"
              />
              <span class="table-cell-name" v-else-if="lessonInfo.reading !== null">{{
                lessonInfo.reading
              }}</span>
              <span class="table-cell-name" v-else-if="lessonInfo.reading === null"> no </span>
            </div>
            <div class="table-cell" v-if="lessonTypeCode !== 'PLACEMENT_TEST'">
              <span class="table-cell-label">Other</span>
              <input
                type="text"
                v-if="
                  editLessonCardMode &&
                  ($cookie.get('internalName') === 'manager' ||
                    $cookie.get('internalName') === 'admin')
                "
                v-model="other"
              />
              <span class="table-cell-name" v-else-if="lessonInfo.other !== null">{{
                lessonInfo.other
              }}</span>
              <span class="table-cell-name" v-else-if="lessonInfo.other === null"> no </span>
            </div>
            <div class="table-cell" v-if="lessonTypeCode !== 'PLACEMENT_TEST'">
              <span class="table-cell-label">Student comment</span>
              <textarea v-if="editLessonCardMode" v-model="studentComment"></textarea>
              <span class="table-cell-name" v-else-if="lessonInfo.comment !== null">{{
                lessonInfo.comment
              }}</span>
              <span class="table-cell-name" v-else-if="lessonInfo.comment === null"> no </span>
            </div>
            <div class="table-cell" v-if="lessonTypeCode === 'PLACEMENT_TEST'">
              <span class="table-cell-label">Link to record</span>
              <input
                type="text"
                v-if="
                  editLessonCardMode &&
                  ($cookie.get('internalName') === 'manager' ||
                    $cookie.get('internalName') === 'admin')
                "
                v-model="link"
              />
              <span class="table-cell-name" v-else-if="lessonInfo.link_to_record !== null">
                <a
                  :href="
                    lessonInfo.link_to_record.indexOf('http') !== -1
                      ? lessonInfo.link_to_record
                      : 'http://' + lessonInfo.link_to_record
                  "
                >
                  {{ lessonInfo.link_to_record }}</a
                ></span
              >
              <span class="table-cell-name" v-else-if="lessonInfo.link_to_record === null">
                no
              </span>
            </div>
          </div>
          <div class="second-table" v-if="lessonTypeCode !== 'PLACEMENT_TEST'">
            <div class="table-cell">
              <span class="table-cell-label">CEFR</span>
              <vue-select
                class="select"
                v-if="
                  editLessonCardMode &&
                  ($cookie.get('internalName') === 'manager' ||
                    $cookie.get('internalName') === 'admin' ||
                    $cookie.get('internalName') === 'methodologist')
                "
                v-model="CEFR"
                :clearable="false"
                :options="CEFRs"
                @input="getLevelByCEFR"
                @open="getCEFRLevels"
              >
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    <span>{{ option.name }}</span>
                  </div>
                </template>
                <template slot="selected-option">
                  <div class="selected d-center">
                    {{ CEFR.name }}
                  </div>
                </template>
              </vue-select>
              <span class="table-cell-name" v-else-if="lessonInfo.kls_europe_level_name !== null">{{
                lessonInfo.kls_europe_level_name
              }}</span>
              <span class="table-cell-name" v-else-if="lessonInfo.kls_europe_level_name === null">
                no
              </span>
            </div>
            <div class="table-cell">
              <span class="table-cell-label">Percentage of knowledge</span>
              <input
                type="number"
                :value="percentageOfKnowledge"
                ref="percentageOfKnowledgeField"
                v-if="
                  editLessonCardMode &&
                  ($cookie.get('internalName') === 'manager' ||
                    $cookie.get('internalName') === 'admin')
                "
                @input="setPercentageOfKnowledge"
              />
              <span
                class="table-cell-name"
                v-else-if="lessonInfo.percentage_of_knowledge !== null"
                >{{ lessonInfo.percentage_of_knowledge }}</span
              >
              <span class="table-cell-name" v-else-if="lessonInfo.percentage_of_knowledge === null">
                no
              </span>
            </div>
            <div class="table-cell">
              <span class="table-cell-label">Revision</span>
              <span class="table-cell-name" v-if="lessonInfo.revision !== null">{{
                lessonInfo.revision.join(', ')
              }}</span>
              <span class="table-cell-name" v-else> no </span>
            </div>
            <div class="table-cell">
              <span class="table-cell-label">New pages</span>
              <span class="table-cell-name">{{ newPages || 'no' }}</span>
            </div>
            <div class="table-cell">
              <span class="table-cell-label">Last taught (new) word</span>
              <input
                type="text"
                v-if="
                  editLessonCardMode &&
                  ($cookie.get('internalName') === 'manager' ||
                    $cookie.get('internalName') === 'admin')
                "
                v-model="lastWord"
              />
              <span class="table-cell-name" v-else-if="lessonInfo.last_taught_word !== null">{{
                lessonInfo.last_taught_word
              }}</span>
              <span class="table-cell-name" v-else-if="lessonInfo.last_taught_word === null">
                no
              </span>
            </div>
            <div class="table-cell">
              <span class="table-cell-label">Listening</span>
              <input
                type="text"
                v-if="
                  editLessonCardMode &&
                  ($cookie.get('internalName') === 'manager' ||
                    $cookie.get('internalName') === 'admin')
                "
                v-model="listening"
              />
              <span class="table-cell-name" v-else-if="lessonInfo.listening !== null">{{
                lessonInfo.listening
              }}</span>
              <span class="table-cell-name" v-else-if="lessonInfo.listening === null"> no </span>
            </div>
            <div class="table-cell">
              <span class="table-cell-label">Writing</span>
              <input
                type="text"
                v-if="
                  editLessonCardMode &&
                  ($cookie.get('internalName') === 'manager' ||
                    $cookie.get('internalName') === 'admin')
                "
                v-model="writing"
              />
              <span class="table-cell-name" v-else-if="lessonInfo.writing !== null">{{
                lessonInfo.writing
              }}</span>
              <span class="table-cell-name" v-else-if="lessonInfo.writing === null"> no </span>
            </div>
            <div class="table-cell">
              <span class="table-cell-label">Homework</span>
              <input type="text" v-if="editLessonCardMode" v-model="homework" />
              <span class="table-cell-name" v-else-if="lessonInfo.homework !== null">{{
                lessonInfo.homework
              }}</span>
              <span class="table-cell-name" v-else-if="lessonInfo.homework === null"> no </span>
            </div>
            <div class="table-cell">
              <span class="table-cell-label">Link to record</span>
              <input
                type="text"
                v-if="
                  editLessonCardMode &&
                  ($cookie.get('internalName') === 'manager' ||
                    $cookie.get('internalName') === 'admin')
                "
                v-model="link"
              />
              <span class="table-cell-name" v-else-if="lessonInfo.link_to_record !== null">
                <a
                  :href="
                    lessonInfo.link_to_record.indexOf('http') !== -1
                      ? lessonInfo.link_to_record
                      : 'http://' + lessonInfo.link_to_record
                  "
                >
                  {{ lessonInfo.link_to_record }}</a
                ></span
              >
              <span class="table-cell-name" v-else-if="lessonInfo.link_to_record === null">
                no
              </span>
            </div>
          </div>
        </div>
        <div class="edit-buttons" v-if="editLessonCardMode">
          <button
            class="cancel"
            @click="
              editLessonCardMode = false
              setInitialState()
            "
          >
            Отменить
          </button>
          <button class="save" @click="saveLessonCard()">Сохранить</button>
        </div>
        <!-- / ЕСЛИ НЕ СТУДЕНТ -->

        <!-- ЕСЛИ СТУДЕНТ -->
        <div class="student-card-info-container" v-if="$cookie.get('internalName') === 'student'">
          <div class="student-lesson-info-container">
            <div class="table-cell">
              <span class="table-cell-label">Lesson number</span>
              <span class="table-cell-name">{{ lessonInfo.lesson_number || 'no' }}</span>
            </div>
            <div class="table-cell">
              <span class="table-cell-label">New words</span>
              <span class="table-cell-name" v-if="lessonInfo.new_words !== null">{{
                lessonInfo.new_words
              }}</span>
              <span class="table-cell-name" v-if="lessonInfo.new_words === null"> no </span>
            </div>
            <div class="table-cell">
              <span class="table-cell-label">Homework</span>
              <span class="table-cell-name" v-if="lessonInfo.homework !== null">{{
                lessonInfo.homework
              }}</span>
              <span class="table-cell-name" v-if="lessonInfo.homework === null"> no </span>
            </div>
            <div class="table-cell">
              <span class="table-cell-label">Student comment</span>
              <span class="table-cell-name" v-if="lessonInfo.comment !== null">{{
                lessonInfo.comment
              }}</span>
              <span class="table-cell-name" v-if="lessonInfo.comment === null"> no </span>
            </div>
          </div>
          <div
            class="btn-container"
            v-if="
              canClickButtonPush &&
              lessonInfo.kls_status_code === 'LESSON_PLANNED' &&
              !lessonInfo.is_teacher_skip &&
              !lessonInfo.in_arbitration
            "
          >
            <button @click="skipLessonByTeacher">Преподаватель не вышел на связь</button>
          </div>
        </div>
        <!-- / ЕСЛИ СТУДЕНТ -->
      </div>
      <div
        v-if="
          ($cookie.get('internalName') === 'student' ||
            $cookie.get('internalName') === 'manager' ||
            $cookie.get('internalName') === 'admin') &&
          studentComplaint &&
          !glossarySelected
        "
        class="student-complaint"
      >
        <div class="complaint-header">
          <span>Жалоба</span>
          <!--      <button @click="deleteComplaint"><img src="../../assets/img/icons/close_big.svg" /></button>-->
        </div>
        <div class="complaint-content-container">
          <!--          <div class="sender-avatar">-->
          <!--            <img-->
          <!--              src="../../assets/img/avatar-sample.jpg"-->
          <!--              alt="edit-icon"-->
          <!--              v-if="!studentComplaint.author_avatar_path"-->
          <!--            />-->
          <!--            <img-->
          <!--              :src="studentComplaint.author_avatar_path"-->
          <!--              v-if="studentComplaint.author_avatar_path"-->
          <!--            />-->
          <!--          </div>-->
          <div class="complaint-info">
            <span class="sender-name">{{ studentComplaint.author_full_name }}</span>
            <span class="complaint-text">{{ studentComplaint.reason }}</span>
          </div>
        </div>
      </div>
      <Comments
        :class="{ 'mb-4': previousLessons.length }"
        v-if="
          $cookie.get('internalName') !== 'student' &&
          !(
            ($cookie.get('internalName') === 'teacher' ||
              $cookie.get('internalName') === 'methodologist') &&
            !isFilledCard
          ) &&
          !glossarySelected
        "
      ></Comments>
      <ComplaintModal
        v-if="openComplaintModal"
        :lessonId="lessonId"
        @complaintCreated="getLessonInfo"
      ></ComplaintModal>
      <ManagerCancelModal
        :isFirst="lessonInfo.kls_type_code === 'PLACEMENT_TEST'"
        v-if="cancelModalForManager"
        @cancelLesson="$router.push('/monolit/Schedule')"
        :lessonId="lessonInfo.id"
      ></ManagerCancelModal>
      <LessonTimeChangeModal
        v-if="timeChangeModal"
        @hideTimeChangeModal="timeChangeModal = false"
        @updateLessonInfo="getLessonInfo"
        :typeId="lessonInfo.kls_type_id"
        :studentUuid="lessonInfo.student_uuid"
        :lessonId="lessonId"
        :role="lessonInfo.teacher_uuid ? 'teacher' : 'methodologist'"
      ></LessonTimeChangeModal>
    </div>
    <LessonCancelModal
      :lessonId="lessonId"
      v-if="cancelModalForTeacher"
      @hideLessonCancelModalForTeacher="cancelModalForTeacher = false"
      @lessonCanceled="lessonCanceled()"
    ></LessonCancelModal>
    <LessonGlossary v-if="glossarySelected" :studentId="lessonInfo.student_uuid"></LessonGlossary>
    <TeacherTestingCard
      v-if="
        (lessonTypeCode === 'TEACHER_TEST' || lessonTypeCode === 'TEACHER_FIRST_TEST') &&
        lessonCardSelected
      "
    ></TeacherTestingCard>
    <div class="last-lessons-cards" v-if="previousLessons.length">
      <ul>
        <li
          is="last-lesson-card"
          v-for="(item, index) in previousLessons"
          :key="index"
          :lessonInfo="item"
          @goToLessonCard="getLessonInfo"
        ></li>
      </ul>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import axios from 'axios'
import { domain, MicroServices } from '@/globalVariables'
import Comments from '@/views/LessonCard/Comments'
import ComplaintModal from '@/views/LessonCard/ComplaintModal'
import TeacherTestingCard from '@/views/LessonCard/TeacherTestingCard'
import VueSelect from 'vue-select'
import Statuses from '@/views/LessonCard/components/Statuses'
import Types from '@/views/LessonCard/components/Types'
import LessonCancelModal from '@/views/LessonCard/LessonCancelModal'
import ManagerCancelModal from '@/views/LessonCard/ManagerCancelModal'
import LessonGlossary from '@/views/LessonCard/LessonGlossary'
import TeacherCardToFill from '@/views/LessonCard/TeacherCardToFill'
import LessonTimeChangeModal from '@/views/LessonCard/LessonTimeChangeModal'
import LastLessonCard from '@/views/LessonCard/components/LastLessonCard'
import scriptMixins from '@/components/scriptMixins/scriptMixins'
import Swal from 'sweetalert2'

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'confirm-button',
    cancelButton: 'delete-button',
    title: 'title-small-class',
    input: 'input-class'
  },
  buttonsStyling: false
})

export default {
  name: 'LessonCard',
  mixins: [scriptMixins],
  props: [],
  components: {
    TeacherCardToFill,
    Types,
    Statuses,
    TeacherTestingCard,
    ComplaintModal,
    Comments,
    VueSelect,
    LessonCancelModal,
    ManagerCancelModal,
    LessonGlossary,
    LessonTimeChangeModal,
    LastLessonCard
  },
  directives: {
    ClickOutside
  },
  data() {
    return {
      lessonCardSelected: true,
      glossarySelected: false,
      lessonInfo: [],
      userRole: this.$cookie.get('internalName'),
      openComplaintModal: false,
      lessonId: null,
      studentComplaint: [],
      complaintId: null,
      lessonTypeId: null,
      lessonTypeCode: null,
      editLessonCardMode: false,
      typeCode: null,
      removeStatus: false,
      removeType: false,
      level: null,
      CEFR: null,
      lessonNumber: null,
      prevLessonNumber: null,
      lastPage: null,
      newWords: null,
      grammar: null,
      reading: null,
      other: null,
      studentComment: null,
      percentageOfKnowledge: null,
      revision: null,
      revisionSteps: null,
      newPages: null,
      lastWord: null,
      listening: null,
      writing: null,
      homework: null,
      link: null,
      status: null,
      type: null,
      linkToZoom: null,
      teacher: null,
      methodist: null,
      statusName: null,
      statusCode: null,
      typeName: null,
      teachers: [],
      methodists: [],
      levels: [],
      CEFRs: [],
      cancelModalForTeacher: false,
      cancelModalForManager: false,
      timeChangeModal: false,
      isFilledCard: true,
      newLessonInfo: null,
      fieldValidations: {
        newWords: false,
        revisionSteps: false,
        lastTaughtPage: false,
        lastTaughtPageRequired: false,
        newPages: false,
        lessonNumber: false
      },
      previousLessons: [],
      percentageArray: null
    }
  },
  mounted() {
    this.getLessonInfo()
    this.$root.$on('hideLessonCancelModalForManager', () => {
      this.cancelModalForManager = false
    })
    this.$root.$on('hideComplaintModal', () => {
      this.openComplaintModal = false
    })
  },
  methods: {
    setEditMode() {
      this.editLessonCardMode = true
      axios({
        url: `${domain}/${MicroServices[0].prefix}/PercentOfKnowledge/getItems`,
        method: 'GET',
        params: {
          student_uuid: this.lessonInfo.student_uuid
        }
      })
        .then((response) => {
          this.percentageArray = response.data.data.items
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },
    setDefaultStatus() {
      this.removeStatus = false
    },
    setRemoveStatus(value) {
      setTimeout(() => {
        this.removeStatus = value
      }, 10)
    },
    setDefaultType() {
      this.removeType = false
    },
    setRemoveType(value) {
      setTimeout(() => {
        this.removeType = value
      }, 10)
    },

    /**
     * Последсвия отмены занятия
     */
    lessonCanceled() {
      if (this.$cookie.get('internalName') === 'student') {
        this.$toasted.show('Занятие отменено', {
          type: 'success',
          duration: 2000
        })
      } else {
        this.$toasted.show('Заявка на отмену занятия отправлена', {
          type: 'success',
          duration: 2000
        })
      }
      this.$router.push('/monolit/Schedule')
      // this.getLessonInfo()
    },
    formatDate(time) {
      return time && time.slice(0, 10)
    },
    formatTime(time) {
      return time + ':00' + ' - ' + (time + 1) + ':00'
    },
    openModal() {
      this.openComplaintModal = true
    },
    openCancelModalForTeacher(id) {
      this.lessonId = id
      this.cancelModalForTeacher = true
    },
    openCancelModalForManager() {
      this.cancelModalForManager = true
    },
    openTimeChangeModal() {
      this.timeChangeModal = true
    },
    openLessonCard() {
      this.lessonCardSelected = true
      this.glossarySelected = false
    },
    openGlossary() {
      this.glossarySelected = true
      this.lessonCardSelected = false
    },

    /**
     * Преподаватель не вышел на связь
     */
    skipLessonByTeacher() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/LessonCard/skipLessonByTeacher`,
        method: 'PUT',
        data: [
          {
            lesson_card_id: this.lessonInfo.lesson_card_id
          }
        ]
      })
        .then(() => {
          this.$toasted.show('Ваша жалоба будет рассмотрена.', {
            type: 'success',
            duration: 5000
          })
          this.getLessonInfo()
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Получить информацию об уроке
     */
    getLessonInfo() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/LessonCard/getItem/${this.$route.params.lesson_id}`,
        method: 'GET'
      })
        .then((response) => {
          this.lessonInfo = response.data.data
          this.prevLessonNumber = response.data.data.prev_lesson_lesson_number
          this.lessonId = response.data.data.id
          this.lessonTypeId = response.data.data.kls_type_id
          this.lessonTypeCode = response.data.data.kls_type_code
          this.checkComplaints()
          this.setInitialState()
          this.newPages = this.lessonInfo.new_pages
          this.isFilledCard = true
          if (
            this.showCardToFill &&
            this.lessonInfo.previous_lesson_id !== null &&
            this.lessonHasStarted
          ) {
            this.isFilledCard = false
            this.newLessonInfo = this.lessonInfo
            axios({
              url: `${domain}/${MicroServices[0].prefix}/LessonCard/getItem/${this.lessonInfo.previous_lesson_id}`,
              method: 'GET'
            })
              .then((response) => {
                this.lessonInfo = response.data.data
                this.lessonId = response.data.data.id
                this.lessonTypeId = response.data.data.kls_type_id
                this.lessonTypeCode = response.data.data.kls_type_code
                this.$root.$emit('setInitialStateInCardToFill')
                this.setInitialState()
              })
              .catch((error) => {
                this.errorAlert(error)
              })
          } else {
            this.newLessonInfo = null
          }
          if (
            this.$cookie.get('internalName') !== 'student' &&
            this.typeCode !== 'TEACHER_TESTING' &&
            this.typeCode !== 'TEACHER_FIRST_TEST'
          ) {
            this.getPreviousLessons()
          }
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Получить жалобу на занятие
     */
    checkComplaints() {
      if (
        this.$cookie.get('internalName') === 'manager' ||
        this.$cookie.get('internalName') === 'student' ||
        this.$cookie.get('internalName') === 'admin'
      ) {
        axios({
          url: `${domain}/${MicroServices[0].prefix}/Complaint/getItems`,
          method: 'GET',
          params: {
            lesson_id: this.lessonId
          }
        })
          .then((response) => {
            this.studentComplaint = response.data.data.items[0]
            this.complaintId = response.data.data.items[0]?.id
          })
          .catch((error) => this.errorAlert(error))
      }
    },

    /**
     * Получить возможных преподавателей на текущее время занятия
     */
    getTeachers(date, studentUuid) {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Teacher/getSuitableUsers`,
        method: 'POST',
        data: [
          {
            date: date,
            user_uuid: studentUuid
          }
        ]
      })
        .then((response) => {
          this.teachers = response.data.data
        })
        .catch((error) => this.errorAlert(error))
    },

    /**
     * Получить список возможных методистов на текущее время зянятия
     */
    getMethodists(date) {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Methodologist/getSuitableUsers`,
        method: 'POST',
        data: [
          {
            date: date
          }
        ]
      })
        .then((response) => {
          this.methodists = response.data.data
        })
        .catch((error) => this.errorAlert(error))
    },

    /**
     * Получить уровни
     */
    getLevels() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Classifier/getEducationLevels`,
        method: 'GET',
        params: {
          _count: 20
        }
      })
        .then((response) => {
          this.levels = response.data.data.items
        })
        .catch((error) => this.errorAlert(error))
    },

    /**
     * Получить уровень при изменении CEFR
     */
    getLevelByCEFR: function () {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Classifier/getSchoolLevelByEurope`,
        method: 'POST',
        data: [
          {
            kls_europe_level_id: this.CEFR.kls_europe_level_id || this.CEFR.id
          }
        ]
      })
        .then((response) => {
          this.level = response.data.data
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },
    /**
     * Получить CEFR уровни
     */
    getCEFRLevels() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Classifier/getEuropeLevels`,
        method: 'GET',
        params: {
          _count: 20
        }
      })
        .then((response) => {
          this.CEFRs = response.data.data.items
        })
        .catch((error) => this.errorAlert(error))
    },

    /**
     * Получить CEFR при изменении уровня
     */
    getCEFRByLevel() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Classifier/getEuropeLevelBySchool`,
        method: 'POST',
        data: [
          {
            kls_level_id: this.level.kls_level_id || this.level.id
          }
        ]
      })
        .then((response) => {
          this.CEFR = response.data.data
        })
        .catch((error) => {
          this.errorAlert(error)
        })

      this.percentageOfKnowledge =
        this.percentageArray.find((item) => item.kls_level_id === this.level.id)
          ?.percent_of_knowledge || 0
    },

    /**
     * Установить начальное значение для редактирования
     */
    setInitialState() {
      this.removeStatus = false
      this.removeType = false
      this.status = this.lessonInfo.kls_status_id
      this.type = this.lessonInfo.kls_type_id
      this.level = {
        kls_level_id: this.lessonInfo.kls_level_id,
        name: this.lessonInfo.kls_level_name
      }
      this.CEFR = {
        kls_europe_level_id: this.lessonInfo.kls_europe_level_id,
        name: this.lessonInfo.kls_europe_level_name
      }
      this.revisionSteps = this.lessonInfo.revision_step
      this.lessonNumber = this.lessonInfo.lesson_number
      this.lastPage = this.lessonInfo.last_taught_page
      this.newWords = this.lessonInfo.new_words
      this.grammar = this.lessonInfo.grammar
      this.reading = this.lessonInfo.reading
      this.other = this.lessonInfo.other
      this.studentComment = this.lessonInfo.comment
      this.percentageOfKnowledge = this.lessonInfo.percentage_of_knowledge
      this.revision = this.lessonInfo.revision
      this.newPages = this.lessonInfo.new_pages
      this.lastWord = this.lessonInfo.last_taught_word
      this.listening = this.lessonInfo.listening
      this.writing = this.lessonInfo.writing
      this.homework = this.lessonInfo.homework
      this.link = this.lessonInfo.link_to_record
      this.linkToZoom = this.lessonInfo.link_to_zoom
      this.teacher = {
        full_name: this.lessonInfo.teacher_name,
        id: this.lessonInfo.teacher_uuid
      }
      this.methodist = {
        full_name: this.lessonInfo.methodologist_name,
        id: this.lessonInfo.methodologist_uuid
      }
      this.statusName = this.lessonInfo.kls_status_name
      this.statusCode = this.lessonInfo.kls_status_code
      this.typeName = this.lessonInfo.kls_type_name
      this.typeCode = this.lessonInfo.kls_type_code
    },

    /**
     * Поменять тип занятия
     * @param id
     * @param code
     * @param name
     */
    changeType(id, code, name) {
      this.type = id
      this.typeName = name
      this.typeCode = code
      this.removeType = false
    },

    /**
     * Поменять статус занятия
     * @param id
     * @param code
     * @param name
     */
    changeStatus(id, code, name) {
      this.status = id
      this.statusName = name
      this.statusCode = code
      this.removeStatus = false
    },

    /**
     * Сохранить карточку
     * @return {null}
     */
    saveLessonCard() {
      this.validateFields()
      if (
        this.fieldValidations.newWords ||
        this.fieldValidations.newPages ||
        this.fieldValidations.revisionSteps ||
        this.fieldValidations.lessonNumber ||
        this.fieldValidations.lastTaughtPage
      ) {
        return null
      }

      let data = [
        {
          teacher_uuid: this.teacher.sync_uuid || this.teacher.id,
          methodologist_uuid: this.methodist.sync_uuid || this.methodist.id,
          kls_status_id: this.status,
          kls_type_id: this.type,
          kls_level_id: this.level.kls_level_id || this.level.id,
          kls_europe_level_id: this.CEFR.kls_europe_level_id || this.CEFR.id,
          percentage_of_knowledge: this.percentageOfKnowledge.toString(),
          lesson_number: this.lessonNumber,
          last_taught_page: this.lastPage ? this.lastPage : null,
          new_pages: this.newPages,
          new_words: this.newWords,
          last_taught_word: this.lastWord,
          grammar: this.grammar,
          listening: this.listening,
          reading: this.reading,
          writing: this.writing,
          other: this.other,
          homework: this.homework,
          comment: this.studentComment,
          link_to_zoom: this.linkToZoom,
          link_to_record: this.link,
          revision_step: this.revisionSteps
        }
      ]

      axios({
        url: `${domain}/${MicroServices[0].prefix}/LessonCard/update/${this.lessonInfo.id}`,
        method: 'PUT',
        data: data
      })
        .then(() => {
          if (
            this.statusCode === 'CANCELLED' ||
            this.statusCode === 'SKIPPED_TEACHER' ||
            this.statusCode === 'SKIPPED_STUDENT_WITH_REASON' ||
            this.statusCode === 'SKIPPED_STUDENT'
          ) {
            this.$router.push('/monolit/Schedule')
            return null
          }
          this.editLessonCardMode = false
          this.getLessonInfo()
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Отправить техническую проблему
     * @param id
     */
    techProblem(id) {
      swalWithBootstrapButtons
        .fire({
          title: `Подтвердите действие. <br> Заявка будет отправлена на арбитраж`,
          text: '',
          showCancelButton: true,
          confirmButtonText: 'Подтвердить',
          cancelButtonText: 'Отмена',
          reverseButtons: true
        })
        .then((r) => {
          if (r.value) {
            axios({
              url: `${domain}/${MicroServices[0].prefix}/Complaint/create`,
              method: 'POST',
              data: [
                {
                  lesson_id: id
                }
              ]
            })
              .then(() => {
                this.successAlert('Жалоба принята. Ждите связи с менеджером')
                this.getLessonInfo()
              })
              .catch((error) => {
                this.errorAlert(error)
              })
          }
        })
    },

    /**
     * Получить список предыдущих уроков
     */
    getPreviousLessons() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/LessonCard/getPreviousLessonCards`,
        method: 'POST',
        data: [
          {
            lesson_id: this.lessonId
          }
        ]
      })
        .then((resp) => {
          this.previousLessons = resp.data.data ? resp.data.data : []
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Подсчет поля New pages
     */
    calculateNewPages() {
      this.newPages =
        this.lastPage - (this.lessonInfo.prev_lesson_last_taught_page || 0) < 0
          ? 0
          : this.lastPage - (this.lessonInfo.prev_lesson_last_taught_page || 0)
    },

    /**
     * Вызывает функцию-валидатор для выбранного поля
     * @param field
     */
    validateFields() {
      this.validateNewWords()
      this.validateRevisionSteps()
      this.validateLastTaughtPage()
      this.validateNewPages()
      this.validateLessonNumber()
      this.validateLastTaughtPageRequired()
    },

    /**
     * Валидация поля New words
     */
    validateNewWords() {
      this.fieldValidations.newWords = (this.newWords < 0 || this.newWords > 50) && this.newWords
    },

    /**
     * Валидация поля Revision steps
     */
    validateRevisionSteps() {
      this.fieldValidations.revisionSteps =
        (this.revisionSteps < 1 || this.revisionSteps > 50) && this.revisionSteps
    },

    /**
     * Валидация поля Last taught page
     */
    validateLastTaughtPage() {
      this.fieldValidations.lastTaughtPage =
        this.lastPage < this.lessonInfo.prev_lesson_last_taught_page && this.lastPage
    },

    /**
     * Валидация поля Last taught page (поле не должно быть пустым)
     */
    validateLastTaughtPageRequired() {
      this.fieldValidations.lastTaughtPageRequired = !this.lastPage
    },

    /**
     * Валидация поля New pages
     */
    validateNewPages() {
      this.fieldValidations.newPages = this.newPages < 0 || this.newPages > 12
    },

    /**
     * Валидация поля Lesson number
     */
    validateLessonNumber() {
      this.fieldValidations.lessonNumber =
        this.lessonNumber < this.prevLessonNumber && this.lessonNumber
    },

    /**
     * Записывает Percentage of knowledge, если его значение 0 <= value >= 100
     * @param event
     */
    setPercentageOfKnowledge(event) {
      const value = Number(event.target.value)

      if (value > 100) {
        this.$refs.percentageOfKnowledgeField.value = 100
        this.percentageOfKnowledge = 100
      } else if (value < 0) {
        this.$refs.percentageOfKnowledgeField.value = 0
        this.percentageOfKnowledge = 0
      } else {
        this.percentageOfKnowledge = value
      }
    }
  },
  computed: {
    canChangeStatus() {
      return (
        ((this.$cookie.get('internalName') === 'teacher' ||
          this.$cookie.get('internalName') === 'methodologist') &&
          this.lessonInfo.kls_status_code !== 'SKIPPED_TEACHER' &&
          this.lessonInfo.kls_status_code !== 'SKIPPED_STUDENT_WITH_REASON' &&
          this.lessonInfo.kls_status_code !== 'SKIPPED_STUDENT' &&
          this.lessonInfo.kls_status_code !== 'CANCELLED' &&
          this.lessonInfo.kls_status_code !== 'LESSON_ENDED') ||
        this.$cookie.get('internalName') === 'manager' ||
        this.$cookie.get('internalName') === 'admin'
      )
    },
    canChangeLessonType() {
      return (
        ((this.$cookie.get('internalName') !== 'teacher' &&
          this.$cookie.get('internalName') !== 'methodologist') ||
          ((this.$cookie.get('internalName') === 'teacher' ||
            this.$cookie.get('internalName') === 'methodologist') &&
            !this.lessonInfo.has_filled_card)) &&
        this.lessonInfo.kls_status_code !== 'LESSON_ENDED'
      )
    },
    /**
     * Показать картоку для заполнения
     */
    showCardToFill() {
      return (
        ((this.$cookie.get('internalName') === 'teacher' &&
          this.lessonInfo.kls_type_code !== 'PLACEMENT_TEST' &&
          this.lessonInfo.kls_type_code !== 'TEACHER_TEST' &&
          this.lessonInfo.kls_type_code !== 'TEACHER_FIRST_TEST' &&
          this.lessonInfo.kls_status_code !== 'SKIPPED_TEACHER' &&
          this.lessonInfo.kls_status_code !== 'SKIPPED_STUDENT_WITH_REASON' &&
          this.lessonInfo.kls_status_code !== 'SKIPPED_STUDENT' &&
          this.lessonInfo.kls_status_code !== 'CANCELLED') ||
          (this.$cookie.get('internalName') === 'methodologist' &&
            this.lessonInfo.kls_type_code !== 'REGULAR' &&
            this.lessonInfo.kls_type_code !== 'TEACHER_FIRST_TEST' &&
            this.lessonInfo.kls_type_code !== 'TEACHER_TEST' &&
            this.lessonInfo.kls_type_code !== 'TEACHER_TEST_REGULAR_LESSON' &&
            this.lessonInfo.kls_status_code !== 'SKIPPED_TEACHER' &&
            this.lessonInfo.kls_status_code !== 'SKIPPED_STUDENT_WITH_REASON' &&
            this.lessonInfo.kls_status_code !== 'SKIPPED_STUDENT' &&
            this.lessonInfo.kls_status_code !== 'CANCELLED')) &&
        !this.lessonInfo.has_filled_card &&
        !this.lessonInfo.in_arbitration &&
        this.lessonInfo.can_update
      )
    },

    /**
     * Показать заполненную карточку занятия
     */
    showLessonCard() {
      return (
        this.lessonTypeCode !== 'TEACHER_TEST' &&
        this.lessonTypeCode !== 'TEACHER_FIRST_TEST' &&
        (this.lessonInfo.previous_lesson_id !== null ||
          this.lessonInfo.has_filled_card ||
          this.lessonInfo.in_arbitration ||
          this.lessonInfo.kls_status_code === 'SKIPPED_TEACHER' ||
          this.lessonInfo.kls_status_code === 'SKIPPED_STUDENT_WITH_REASON' ||
          this.lessonInfo.kls_status_code === 'SKIPPED_STUDENT' ||
          this.lessonInfo.kls_status_code === 'CANCELLED' ||
          !this.lessonInfo.can_update ||
          !this.lessonHasStarted ||
          (this.$cookie.get('internalName') === 'teacher' &&
            (this.lessonInfo.kls_type_code === 'PLACEMENT_TEST' ||
              this.lessonInfo.kls_type_code === 'TEACHER_TEST')) ||
          this.$cookie.get('internalName') === 'student' ||
          this.$cookie.get('internalName') === 'manager' ||
          this.$cookie.get('internalName') === 'admin' ||
          (this.$cookie.get('internalName') === 'methodologist' &&
            this.lessonInfo.kls_type_code === 'TEACHER_TEST_REGULAR_LESSON'))
      )
    },

    /**
     * Показать кнопки управления карточкой
     */
    showControlButtons() {
      return (
        ((this.$cookie.get('internalName') === 'teacher' &&
          (this.lessonInfo.kls_status_code === 'LESSON_PLANNED' ||
            this.lessonInfo.kls_status_code === 'LESSON_ENDED')) ||
          this.$cookie.get('internalName') === 'manager' ||
          this.$cookie.get('internalName') === 'methodologist' ||
          this.$cookie.get('internalName') === 'admin') &&
        !this.editLessonCardMode &&
        this.$cookie.get('internalName') !== 'student' &&
        !this.lessonInfo.in_arbitration &&
        this.lessonInfo.can_update
      )
    },

    /**
     * Возвращает true если урок начался и еще не закончился (можно нажать кнопку "Преподаватель не вышел на связь")
     */
    canClickButtonPush() {
      let today = this.countUserTimeByTimeZone(this.$cookie.get('userTimeZone'))
      let lessonTime = new Date(this.lessonInfo.date.replaceAll('-', '/'))
      let lessonTimeEnd = new Date(this.lessonInfo.date.replaceAll('-', '/')).setHours(
        lessonTime.getHours() + 1
      )
      return today > lessonTime && today < new Date(lessonTimeEnd)
    },

    /**
     * Возвращает true если урок начался и прошло менее 25-ти часов с его начала (можно пожаловаться на занятие)
     */
    startLesson() {
      let today = this.countUserTimeByTimeZone(this.$cookie.get('userTimeZone'))
      let lessonTime = new Date(this.lessonInfo.date.replaceAll('-', '/'))
      let lessonEndTime = new Date(this.lessonInfo.date.replaceAll('-', '/')).setHours(
        new Date(this.lessonInfo.date.replaceAll('-', '/')).getHours() + 25
      )
      return today > lessonTime && today < new Date(lessonEndTime)
    },

    /**
     * Возвращает true если урок начался
     * @returns {boolean}
     */
    lessonHasStarted() {
      let today = this.countUserTimeByTimeZone(this.$cookie.get('userTimeZone'))
      let lessonTime = new Date(
        this.newLessonInfo
          ? this.newLessonInfo.date.replaceAll('-', '/')
          : this.lessonInfo.date.replaceAll('-', '/')
      )
      return today > lessonTime
    }
  },
  watch: {},
  validations: {}
}
</script>

<style scoped lang="scss">
@import './src/styleVariables';

.validation-error {
  border-color: $red !important;
}
.tooltip {
  /deep/ .arrow::before {
    border-top-color: $red !important;
    border-bottom-color: $red !important;
  }
  /deep/ .tooltip-inner {
    background-color: $red !important;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

input[type='number'] {
  -moz-appearance: textfield !important;
}

/deep/ {
  span.table-cell-name {
    background: transparent !important;
    a {
      word-break: break-word;
      background-color: transparent !important;
    }
  }
}
.nav-container {
  width: 95%;
  margin: 25px auto;
  div {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    padding: 3px;
    height: 42px;
    width: 342px;
    display: flex;
    justify-content: space-around;
    background-color: $main-white;

    button {
      width: 158px;
      color: $main-gray;
      outline: none;
      font-family: $font-bold;
      font-size: 18px;
      line-height: 24px;
    }

    .selected {
      background-color: $bright-blue;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
      border-radius: 4px;
      color: $main-white;

      input::placeholder {
      }
    }
  }
}
.card-container,
.studentCard {
  width: 95%;
  min-height: 200px;
  height: auto;
  box-shadow: 1px 0px 20px -7px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  background: $main-white;
  margin: 25px auto;
  padding: 18px 0;
  transition: box-shadow 0.087s ease-in;
  .card-header-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 18px 0 18px;
    .lesson-details {
      display: flex;
      align-items: center;
      span {
        font-size: 18px;
      }
      div {
        margin-left: 24px;
        display: flex;
        align-items: center;
        span {
          font-family: $font-bold;
          font-size: 14px;
          line-height: 18px;
        }
        img {
          margin-right: 8px;
          width: 20px;
          height: 18px;
        }
      }
    }
    .edit-btn {
      display: flex;
      button {
        outline: none;
        margin-right: 20px;
        img {
          margin-right: 4px;
          width: 15px;
          height: 15px;
        }
      }
    }
    .student-controls-btn {
      button {
        border: 1px solid $bright-blue;
        border-radius: 4px;
        padding: 5px 21px;
        color: $bright-blue;
        outline: none;
      }
      .lesson-cancel-btn {
        margin-right: 15px;
      }
    }
    .arbitration-status {
      img {
        cursor: pointer;
      }
    }
  }
  .lesson-link-container {
    margin-top: 15px;
    padding: 0 18px 0 18px;
    label {
      margin-bottom: 0 !important;
      margin-right: 12px;
    }
    .lesson-link {
      text-decoration: none;
      color: $bright-blue !important;
      word-break: break-all;
    }
    .lesson-link:visited {
      color: $regular-lesson;
    }
    span {
      color: $bright-blue;
    }
    input {
      border: 0.5px solid $light-gray;
      border-radius: 4px;
      background-color: transparent;
      outline: none;
      padding: 3px 9px;
      height: 25px;
      width: 287px;
      font-size: 14px;
      font-family: $font-regular;

      &:focus {
        border: 0.5px solid $bright-blue;
      }
    }
  }
  .card-members {
    display: flex;
    margin-top: 31px;
    flex-wrap: wrap;
    padding-left: 18px;
    .card-member-name {
      display: flex;
      flex-direction: column;
      margin-right: 58px;
      font-size: 14px;
      width: 220px;
      .name {
        font-family: $font-regular;
      }
    }
  }
  .card-status-container {
    margin-top: 35px;
    padding-left: 18px;
    .card-status {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      span {
        margin-right: 22px;
        min-width: 70px;
      }
      img {
        cursor: pointer;
        margin-left: 4px;
      }
      .lessonFinished {
        background-color: rgba(29, 190, 64, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        display: flex;
        white-space: nowrap;
      }
      .lessonPlanned {
        background-color: rgba(75, 97, 173, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        display: flex;
        white-space: nowrap;
      }
      .lessonMissedByStudent {
        background-color: rgba(243, 210, 50, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        display: flex;
        white-space: nowrap;
      }
      .lessonMissedByStudentWithReason {
        background-color: rgba(238, 0, 200, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        display: flex;
        white-space: nowrap;
      }
      .lessonMissedByTeacher {
        background-color: rgba(28, 9, 255, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        display: flex;
        white-space: nowrap;
      }
      .lessonCanceled {
        background-color: rgba(206, 87, 25, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        display: flex;
        white-space: nowrap;
      }
    }
    .card-type {
      display: flex;
      span {
        margin-right: 22px;
        min-width: 70px;
      }
      img {
        cursor: pointer;
        margin-left: 4px;
      }
      .regularLesson {
        background-color: rgba(29, 93, 190, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        display: flex;
        white-space: nowrap;
      }
      .teacherTesting {
        background-color: rgba(29, 190, 64, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        display: flex;
        white-space: nowrap;
      }
      .placementTest {
        background-color: rgba(255, 107, 0, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        display: flex;
        white-space: nowrap;
      }
      .checkUpLesson {
        background-color: rgba(255, 184, 0, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        display: flex;
        white-space: nowrap;
      }
      .progressTest {
        background-color: rgba(82, 0, 255, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        display: flex;
        white-space: nowrap;
      }
    }
  }
  .card-info-container {
    display: flex;
    margin-top: 23px;
    .first-table {
      flex-grow: 1;
      min-height: 150px;
      .table-cell {
        width: 100%;
        min-height: 46px;
        height: auto;
        display: flex;
        align-items: center;
        padding: 0 18px;
        .table-cell-label {
          min-width: 40%;
          width: 40%;
          background-color: transparent;
          margin-right: 6px;
        }
        .select {
          width: 287px;

          /deep/ .vs__search {
            background-color: $main-white !important;
          }
          /deep/ .vs__actions {
            background-color: $main-white !important;
          }
          /deep/ .selected,
          /deep/ .vs__selected-options,
          /deep/ .v-select,
          /deep/ .select {
            background-color: $main-white !important;
          }
          /deep/ .vs__dropdown-toggle {
            background-color: $main-white !important;
          }
          /deep/ .vs__selected {
            background-color: $main-white !important;
          }
          /deep/ .vs__open-indicator {
            background-color: $main-white !important;
          }
          /deep/ .vs__dropdown-menu {
            background-color: $main-white !important;
          }
          /deep/ .vs__dropdown-option {
            background-color: $main-white;
            color: $main-dark;
            &:hover {
              color: $bright-blue;
            }
          }
          /deep/ .vs__dropdown-option span {
            background-color: $main-white;
          }
        }
        .table-cell-name {
          font-family: $font-regular;
          word-break: break-word;
          background-color: transparent;
          a {
            background-color: $main-white;
          }
        }
        input,
        textarea {
          border: 0.5px solid $light-gray;
          border-radius: 4px;
          background-color: transparent;
          outline: none;
          padding: 3px 9px;
          height: 25px;
          width: 287px;
          font-size: 14px;
          font-family: $font-regular;

          &:focus {
            border: 0.5px solid $bright-blue;
          }
        }
        textarea {
          resize: none;
          height: 136px;
          width: 287px;
          margin-top: 10px;
        }
      }
    }
    .first-table :nth-child(even) {
      background-color: $light-table-blue;
      /deep/.vs__dropdown-menu {
        background-color: #fff;
      }
      /deep/.vs__dropdown-menu li div {
        background-color: $main-white;
      }
      /deep/.vs__dropdown-menu li {
        background-color: $main-white;
        &:hover {
          color: $bright-blue;
        }
      }
      /deep/.vs__dropdown-option,
      .vs__dropdown-option--highlight {
        background-color: $main-white !important;
      }
    }

    .second-table {
      flex-grow: 1;
      min-height: 150px;
      margin-right: 24px;
      .table-cell {
        width: 100%;
        min-height: 46px;
        height: auto;
        display: flex;
        align-items: center;
        padding: 0 18px;
        .table-cell-label {
          min-width: 40%;
          width: 40%;
          margin-right: 6px;
          background-color: transparent;
        }
        .select {
          width: 287px;

          /deep/ .vs__search {
            background-color: $main-white !important;
          }
          /deep/ .vs__actions {
            background-color: $main-white !important;
          }
          /deep/ .selected,
          /deep/ .vs__selected-options,
          /deep/ .v-select,
          /deep/ .select {
            background-color: $main-white !important;
          }
          /deep/ .vs__dropdown-toggle {
            background-color: $main-white !important;
          }
          /deep/ .vs__selected {
            background-color: $main-white !important;
          }
          /deep/ .vs__open-indicator {
            background-color: $main-white !important;
          }
          /deep/ .vs__dropdown-menu {
            background-color: $main-white !important;
          }
          /deep/ .vs__dropdown-option {
            background-color: $main-white;
            color: $main-dark;
            &:hover {
              color: $bright-blue;
            }
          }
          /deep/ .vs__dropdown-option span {
            background-color: $main-white;
          }
        }
        .table-cell-name {
          word-break: break-word;
          background-color: transparent;
          font-family: $font-regular;
          a {
            background-color: $main-white;
          }
        }
        input {
          border: 0.5px solid $light-gray;
          border-radius: 4px;
          background-color: transparent;
          outline: none;
          padding: 3px 9px;
          height: 25px;
          width: 287px;
          font-size: 14px;
          font-family: $font-regular;

          &:focus {
            border: 0.5px solid $bright-blue;
          }
        }
      }
    }
    .second-table :nth-child(even) {
      background-color: $light-table-blue;
      /deep/.vs__dropdown-menu {
        background-color: #fff;
      }
      /deep/.vs__dropdown-menu li div {
        background-color: $main-white;
      }
      /deep/.vs__dropdown-menu li {
        background-color: $main-white;
        &:hover {
          color: $bright-blue;
        }
      }
      /deep/.vs__dropdown-option,
      .vs__dropdown-option--highlight {
        background-color: $main-white !important;
      }
    }
  }
  .edit-buttons {
    margin-left: auto;
    width: fit-content;
    margin-right: 18px;
    button {
      width: 137px;
      height: 33px;
      border-radius: 4px;
    }
    .save {
      background-color: $bright-blue;
      color: $main-white;
    }
    .cancel {
      background-color: $main-white;
      color: $bright-blue;
      margin-right: 15px;
      border: 1px solid $bright-blue;
    }
  }
  .student-card-info-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .student-lesson-info-container {
      display: flex;
      margin-top: 23px;
      flex-direction: column;

      .table-cell {
        width: 100%;
        min-height: 46px;
        height: auto;
        display: flex;
        align-items: center;
        padding: 0 18px;

        .table-cell-label {
          min-width: 30%;
          width: 30%;
          margin-right: 6px;
          background-color: transparent;
        }

        .select {
          width: 287px;

          /deep/ .vs__search {
            background-color: $main-white !important;
          }
          /deep/ .vs__actions {
            background-color: $main-white !important;
          }
          /deep/ .selected,
          /deep/ .vs__selected-options,
          /deep/ .v-select,
          /deep/ .select {
            background-color: $main-white !important;
          }
          /deep/ .vs__dropdown-toggle {
            background-color: $main-white !important;
          }
          /deep/ .vs__selected {
            background-color: $main-white !important;
          }
          /deep/ .vs__open-indicator {
            background-color: $main-white !important;
          }
          /deep/ .vs__dropdown-menu {
            background-color: $main-white !important;
          }
          /deep/ .vs__dropdown-option {
            background-color: $main-white;
            color: $main-dark;
            &:hover {
              color: $bright-blue;
            }
          }
          /deep/ .vs__dropdown-option span {
            background-color: $main-white;
          }
        }

        .table-cell-name {
          font-family: $font-regular;
          word-break: break-word;
          background-color: transparent;
          a {
            background-color: $main-white;
          }
        }
      }
    }
    button {
      color: $bright-blue;
      margin-top: 50px;
      outline: none;
      padding: 0 0 24px 24px;
    }
  }
  .student-lesson-info-container :nth-child(odd) {
    background: $light-table-blue;
  }
}
.studentCard {
  min-height: 437px;
}
.student-complaint {
  background: $main-white;
  box-shadow: 1px 0px 20px -7px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  width: 95%;
  margin: 0 auto;
  padding: 15px 24px;
  .complaint-header {
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    button {
      outline: none;
      img {
        width: 13px;
        height: 13px;
      }
    }
  }
  .complaint-content-container {
    display: flex;
    margin-top: 16px;
    .sender-avatar {
      img {
        border-radius: 50%;
        width: 47px;
        height: 47px;
        margin-right: 12px;
      }
    }
    .complaint-info {
      display: flex;
      flex-direction: column;
      .complaint-text {
        font-family: $font-regular;
        word-break: break-all;
      }
    }
  }
}
.last-lessons-cards {
  ul {
    box-shadow: 1px 0px 20px -7px rgba(0, 0, 0, 0.16);
    width: 95%;
    background-color: $main-white;
    border-radius: 8px !important;
    margin: 0 auto 34px auto;
  }
}

@media screen and (max-width: 720px) {
  .card-container {
    .card-header-container {
      flex-direction: column-reverse;
      .arbitration-status {
        align-self: flex-end;
      }
      .edit-btn {
        display: flex;
        flex-direction: column;
        button {
          align-self: flex-end;
          justify-self: flex-end;
          margin-right: 0;
          margin-bottom: 12px;
          .edit-label {
            display: none;
          }
        }
        .student-controls-btn {
          width: 100%;
          button {
            width: 100%;
          }
          .lesson-cancel-btn {
            margin-bottom: 12px;
          }
        }
      }

      .student-controls-btn {
        width: 100%;
        margin-bottom: 12px;
        button {
          width: 100%;
        }
        .lesson-cancel-btn {
          margin-bottom: 12px;
        }
      }
      .lesson-details {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 14px;
        grid-row-gap: 14px;
        width: 100%;
        margin-bottom: 14px;
        .name {
          grid-column-start: 1;
          grid-column-end: 3;
        }
        .lesson-date,
        .lesson-time {
          margin-left: 0;
        }
      }
    }
    .card-members {
      margin-top: 16px;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    .card-status-container {
      margin-top: 16px;
    }
    .card-info-container {
      flex-direction: column;
      .first-table :nth-child(odd) {
        background-color: $main-white;
      }
      .second-table :nth-child(odd) {
        background-color: $main-white;
      }

      .second-table {
        margin-right: 0;
      }
      .table-cell {
        flex-direction: column;
        align-items: flex-start !important;
        margin-bottom: 14px;
        input {
          width: 100% !important;
        }
        textarea {
          width: 100% !important;
        }
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .nav-container {
    div {
      width: 100%;
    }
  }
}
@media screen and (max-width: 344px) {
  .edit-buttons {
    display: flex;
    flex-direction: column;
    width: unset !important;
    margin-right: 20px !important;
    margin-left: 20px !important;
    button {
      margin-bottom: 12px;
      width: 100% !important;
    }
  }
}
</style>
