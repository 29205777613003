<template>
  <div class="pack">
    <div class="pack-header">
      <div class="pack-header__discount">
        <span>{{ `Скидка на пакет ${pack.discount}%` }}</span>
      </div>
      <div class="pack-header__lessons">
        <div class="pack-header__lessons-count">
          {{ `${pack.lessons_amount} ${endingOfWord(pack.lessons_amount)}` }}
        </div>
        <div class="pack-header__lessons-price">
          <div class="rub"><img src="../../../assets/img/ES/rub.svg" alt="rub" /></div>
          <div class="price">{{ pack.one_lesson_price * pack.lessons_amount }}</div>
          <div class="per-lesson">/ стоимость без скидки</div>
        </div>
      </div>
    </div>
    <div class="pack-price">
      <span>{{ pack.price }}</span>
      <img src="../../../assets/img/ES/big_rub.svg" alt="rub" />
    </div>
    <div class="pack-benefit">
      <span>{{ `Экономия ${pack.benefit}` }}</span>
      <img src="../../../assets/img/ES/rub.svg" alt="rub" />
    </div>
    <div class="pack-buy" v-if="role === 'student'">
      <button @click="$emit('buyPack', pack.id)">Купить</button>
    </div>
    <div class="controls" v-if="role === 'admin'">
      <img
        class="edit"
        src="../../../assets/img/ES/edit_requisites.svg"
        alt="edit"
        @click="$emit('edit')"
      />
      <img
        src="../../../assets/img/ES/pack_delete.svg"
        alt="delete"
        @click="$emit('delete', pack.id)"
      />
    </div>
  </div>
</template>

<script>
import scriptMixins from '@/components/scriptMixins/scriptMixins'

export default {
  name: 'Pack',
  props: {
    pack: Object,
    role: String
  },
  mixins: [scriptMixins]
}
</script>

<style scoped lang="scss">
@import '../../../styleVariables';

.pack {
  width: 238px;
  height: 326px;
  background-color: $main-white;
  box-shadow: 1px 0 20px -7px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  transition: 0.2s ease-in-out;
  &:hover {
    margin-top: -20px;
    box-shadow: 3px 1px 16px 4px rgba(0, 0, 0, 0.12);
  }
  &-header {
    background-image: url('../../../assets/img/ES/pack_bg.svg');
    background-position-x: center;
    padding-bottom: 12px;
    margin-bottom: 22px;
    &__discount {
      display: flex;
      justify-content: space-between;
      padding: 10px 10px 0 0;
      margin-bottom: 28px;
      span {
        background-color: $bright-blue;
        border-radius: 4px;
        color: $main-white;
        font-size: 13px;
        line-height: 24px;
        padding: 0 10px;
        width: fit-content;
        position: relative;
        right: 10px;
      }
    }
    &__lessons {
      &-count {
        color: $bright-blue;
        font-size: 24px;
        line-height: 30px;
        width: fit-content;
        margin: 0 auto 8px auto;
      }
      &-price {
        line-height: 24px;
        display: flex;
        align-items: baseline;
        width: fit-content;
        margin: 0 auto;
        .price {
          font-family: $font-regular;
          font-size: 14px;
          color: $main-dark;
          margin-right: 4px;
        }
        .per-lesson {
          font-family: $font-regular;
          font-size: 12px;
          color: $main-gray;
        }
        .rub {
          position: relative;
          bottom: 9px;
        }
      }
    }
  }
  &-price {
    padding: 4px 12px;
    background-color: #f2f5ff;
    border-radius: 3px;
    width: fit-content;
    margin: 0 auto 8px auto;
    display: flex;
    align-items: baseline;
    span {
      font-size: 30px;
      line-height: 38px;
      font-family: Roboto, sans-serif;
      color: $bright-blue;
      margin-right: 5px;
    }
  }
  &-benefit {
    width: fit-content;
    margin: 0 auto 28px auto;
    span {
      font-family: $font-regular;
      font-size: 12px;
      line-height: 24px;
      color: $main-gray;
    }
  }
  &-buy {
    width: fit-content;
    margin: 0 auto;
    button {
      background-color: $bright-blue;
      width: 137px;
      height: 33px;
      color: $main-white;
      border-radius: 4px;
      outline: none;
      font-size: 14px;
      line-height: 24px;
      &:active {
        background-color: $hover-bright-blue;
      }
    }
  }

  .controls {
    width: fit-content;
    margin: 52px auto 0 auto;
    img {
      cursor: pointer;
    }
    .edit {
      margin-right: 6px;
    }
  }
}

@media screen and (max-width: 780px) {
  .pack {
    &:hover {
      margin-top: 0;
      box-shadow: 1px 0 20px -7px rgba(0, 0, 0, 0.16);
    }
  }
}
</style>
