<template>
  <v-app>
    <button
      class="help-chat"
      @click="showHelpWindow = !showHelpWindow"
      v-if="
        ($cookie.get('internalName') === 'student' ||
          $cookie.get('internalName') === 'teacher' ||
          $cookie.get('internalName') === 'methodologist') &&
        $cookie.get('mandate')
      "
    >
      <img src="./assets/img/ES/chat_button.svg" alt="help" />
      <div class="help-chat-counter" v-show="messageCount">{{ messageCount }}</div>
    </button>
    <div
      class="help-window"
      v-if="
        ($cookie.get('internalName') === 'student' ||
          $cookie.get('internalName') === 'teacher' ||
          $cookie.get('internalName') === 'methodologist') &&
        $cookie.get('mandate')
      "
    >
      <HelpWindow v-if="showHelpWindow" @hide="showHelpWindow = false" />
    </div>
    <router-view />
  </v-app>
</template>

<!-- TODO: Вынести подключение файла в main.js-->
<style src="./assets/styles/style.scss" lang="scss"></style>

<script>
import axios from 'axios'
import {
  domain,
  dynamicRoute,
  startRoutePage,
  routeNotGenerated,
  MicroServices
} from './globalVariables'
import { notEmpty, isEmpty } from '@/components/Core/KernelProject/helperFunction'
import centrifuge from '@/centrifuge'
import HelpWindow from '@/views/Chat/HelpChat/HelpWindow'

export default {
  name: 'App',
  components: { HelpWindow },
  metaInfo: {
    title: 'LinguaTech'
  },
  data() {
    return {
      microLen: 0,
      countReq: 0,
      centrifugeGeneral: null,
      showHelpWindow: false,
      messageCount: null
    }
  },
  mounted() {
    /**
     * Обработка каждого response запроса axios
     * return => отправка в ту функцию с которой был запрос
     */
    axios.interceptors.response.use(
      (res) => {
        return res
      },
      (err) => {
        return Promise.reject(err)
      }
    )

    this.$root.$on('setMessageCount', (messageCount) => {
      this.messageCount = messageCount
    })

    this.$root.$on('unsubscribeGeneral', () => {
      this.centrifugeGeneral.unsubscribe()
      this.centrifugeGeneral.removeAllListeners()
      this.$root.$off('publishGetStatus')
    })

    if (this.$cookie.get('mandate')) {
      this.$root.$emit('getMetaData')
      let path = window.location.pathname // this.$route.path - при загрузке = '/' => используется window.location
      if (path === '/') {
        this.$router.push(startRoutePage)
      }
      this.centrifugeGeneral = centrifuge
        .subscribe('general', (message) => {
          if (this.$cookie.get('mandate')) {
            if (message.data.event && message.data.event === 'getStatus') {
              this.centrifugeGeneral.publish({
                event: 'imHere',
                uid: this.$cookie.get('uid')
              })
            } else if (message.data.event && message.data.event === 'imHere' && message.data.uid) {
              this.$root.$emit('imOnline', message.data.uid)
            }
          }
        })
        .on('join', () => {
          this.$root.$emit('publishGetStatus')
        })
        .on('leave', () => {
          this.$root.$emit('publishGetStatus')
        })
    }

    this.$root.$on('subscribeGeneral', () => {
      this.centrifugeGeneral = centrifuge
        .subscribe('general', (message) => {
          if (this.$cookie.get('mandate')) {
            if (message.data.event && message.data.event === 'getStatus') {
              this.centrifugeGeneral.publish({
                event: 'imHere',
                uid: this.$cookie.get('uid')
              })
            } else if (message.data.event && message.data.event === 'imHere' && message.data.uid) {
              this.$root.$emit('imOnline', message.data.uid)
            }
          }
        })
        .on('join', () => {
          this.$root.$emit('publishGetStatus')
        })
        .on('leave', () => {
          this.$root.$emit('publishGetStatus')
        })
    })

    this.$root.$on('getMetaData', (clear) => {
      axios.defaults.headers['Authorization'] = `bearer ${this.$cookie.get('mandate')}`
      this.getMeta(clear)
    })
  },
  beforeDestroy() {
    this.centrifugeGeneral.unsubscribe()
  },

  methods: {
    routeCalc() {
      return (
        this.$route.params.model ||
        this.$route.path
          .slice(1)
          .split(/\s+/)
          .map((word) => word[0].toUpperCase() + word.substring(1))
          .join(' ')
          .split('/')[1]
      )
    },
    /**
     * Получение метаДанных всего приложения для построения страниц
     * Метаданные получаются для каждого микросервиса в отдельности с указанием его префикса в храналище проекта
     * Одинаковая дата - но разные записи стора нужны для того, чтобы их можно было изменять без зависимостей
     *
     * this.$root.$emit('goGetEndpoints') добавлен для того, чтобы можно было получать эндпойнты в уникальных компонентах не табличного вида.
     * эндпойнты можно получить прослушивая 'goGetEndpoints' в уникальном компоненте и добавив нужный метод
     */
    getMeta(clear) {
      this.$store.state.auth.endpoints = {}
      this.$store.state.auth.metaProjectMenu = []
      this.microLen = MicroServices.length
      for (let i in MicroServices) {
        axios
          .post(`${domain}/${MicroServices[i].prefix}/Metadata/getAll`)
          .then((response) => {
            let data = response.data.data.metadata
            let prefix = MicroServices[i].prefix
            this.$store.state.relationsData[prefix] = {}
            if (isEmpty(this.$store.state.auth.endpoints[prefix]) || clear) {
              this.$store.state.auth.endpoints[prefix] = response.data.data.endpoints
              this.$root.$emit('goGetEndpoints')
            }
            this.$store.state.metaProjectData[prefix] = data
            if (isEmpty(this.$store.state.metaProjectFilter[prefix]) || clear) {
              this.$store.state.metaProjectFilter[prefix] = JSON.parse(JSON.stringify(data))
            }
            this.$store.state.metaProjectDataEdit[prefix] = JSON.parse(JSON.stringify(data))
            this.$store.state.metaProjectMultiEdit[prefix] = JSON.parse(JSON.stringify(data))
            this.setUpdate(response, MicroServices[i], prefix, data)
          })
          .catch((error) => {
            this.updateData()
            if (error.response.status !== 401) {
              this.messageAnyType(`Микросервис '${MicroServices[i].name}' - упал`, 'error')
            } else if (error.response.status === 'ERR_CONNECTION_REFUSED') {
              this.messageAnyType(`Отсутсвует соединение с сервером`, 'error')
            }
            this.errorAlert(error)
          })
      }
    },
    /**
     * Запуск процессов для сбора сервиса
     * @param response
     * @param MicroServices
     * @param prefix
     * @param data
     **/
    setUpdate(response, MicroServices, prefix, data) {
      this.setMenu(response, MicroServices.name, prefix)
      let route = this.routeCalc()
      if (isEmpty(data[route])) {
        return
      }
      if (notEmpty(data[route]['treeRelation'])) {
        this.getTree(route)
      } else {
        this.updateData()
      }
    },
    /**
     * Отрисовка таблицы в случае загрузки всех микросервисов (если упал - загрузка считается пройденной)
     **/
    updateData() {
      this.countReq++
      if (this.countReq === this.microLen) {
        this.$root.$emit(`update`)
        this.countReq = 0
        return this.$root.$emit(`switchFilter`)
      }
    },
    /**
     * Получение данных для древовидной струкутры Select'a
     * Для исключения перезаписывания модели у древовидной структуры добавлено '_tree'
     * Реализовано только для фильтров на данный момент
     */
    getTree(route) {
      let prefix = this.$route.params.prefix
      axios
        .post(`${domain}/${prefix}/${route}/getTree`)
        .then((response) => {
          this.$store.state.relationsData[prefix][`${route}_tree`] = response.data.data
          this.$store.state.metaProjectFilter[prefix][route].filterFields['tree'] = {
            inFilter: true,
            label: `Дерево ${route}`,
            model: `${route}_tree`,
            type: 'tree'
          }
          this.updateData()
        })
        .catch((error) => this.errorAlert(error))
    },
    /**
     * Меню формируется для каждого микросервиса отдельно со сбором в единный массив
     * Формирование структуры меню с проверкой прав на доступ, которые хранят в $store.state.auth.endpoints
     * Формирование происходит универсально и создавать вьюху под каждую модель не нужно, только в случае уникальной
     * модели или желания изменить страницу модели без наличия каких-то данных
     * При наличие ЭндПоинта ставится параметр view=true, иначе view=false
     * Некий костыль из-за бэкенда, далее структура меню будет происходить уже учитывая ЭндПоинты текущего юзера
     * @param response - меню микросервиса
     * @param name - имя микросервиса
     * @param prefix - префикс микросервиса
     */
    setMenu(response, name, prefix) {
      this.$store.state.metaProjectMenu[prefix] = JSON.parse(
        JSON.stringify(response.data.data.menu)
      )
      let menu = this.$store.state.metaProjectMenu[prefix]
      let endpoints = this.$store.state.auth.endpoints[prefix]
      for (let i in menu) {
        if (menu.hasOwnProperty(i)) {
          if (menu[i].route) {
            if (endpoints[menu[i].route]) {
              let onSearch = routeNotGenerated.filter((item) => {
                return item.model === menu[i]['route'] && item.microservice === prefix
              })
              menu[i]['view'] = endpoints[menu[i].route].length > 1
              if (onSearch.length === 0) {
                menu[i]['route'] = `${prefix}/${dynamicRoute}/${menu[i]['route']}`
              } else {
                menu[i]['route'] = `${prefix}/${menu[i]['route']}/`
              }
            } else {
              menu[i]['view'] = false
            }
          } else if (menu[i].deep) {
            for (let c in menu[i].deep) {
              if (menu[i].deep.hasOwnProperty(c)) {
                if (endpoints[menu[i].deep[c].route]) {
                  let onSearch = routeNotGenerated.filter((item) => {
                    return item.model === menu[i].deep[c]['route'] && item.microservice === prefix
                  })
                  menu[i].deep[c]['view'] = endpoints[menu[i].deep[c].route].length > 1
                  if (onSearch.length === 0) {
                    menu[i].deep[c][
                      'route'
                    ] = `${prefix}/${dynamicRoute}/${menu[i].deep[c]['route']}`
                  } else {
                    menu[i].deep[c]['route'] = `${prefix}/${menu[i].deep[c]['route']}`
                  }
                } else {
                  menu[i].deep[c]['view'] = false
                }
              }
            }
          }
        }
      }
      this.$root.$emit('setMenu', prefix, name)
      this.$root.$emit('goGetBreadcrumbs', menu)
    }
  },
  updated() {
    // Remove loading state
    setTimeout(() => document.body.classList.remove('app-loading'), 1)
  },

  watch: {
    /**
     * При смене route - Получение данных по проекту, для динамических роутов
     * @param to
     * @param from
     */
    $route(to, from) {
      this.$store.dispatch('reloadStore')
      if (from.path !== '/' && from.fullPath.indexOf(dynamicRoute) === -1) {
        this.$root.$emit('destroy')
      }
      this.$root.$emit('setPagination', 25, 1)
      this.$root.$emit('offTable')
      if (this.$cookie.get('mandate')) this.$root.$emit('getMetaData', true)
    }
  }
}
</script>
<!-- TODO: Вынести стили в /assets/styles/app.scss-->
<style lang="scss">
@import './styleVariables';

.help-chat {
  background-color: $bright-blue;
  padding: 10px;
  border-radius: 50px;
  width: fit-content;
  min-width: 50px;
  min-height: 50px;
  position: fixed;
  bottom: 36px;
  right: 32px;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
  outline: none;
  z-index: 1000;
  &:focus {
    outline: none;
  }
  &:active {
    box-shadow: none;
  }
}
.help-chat-counter {
  position: fixed;
  bottom: 72px;
  right: 32px;
  padding: 0 5px;
  color: $main-white;
  font-size: 14px;
  line-height: 17px;
  background-color: $red;
  border-radius: 50px;
  z-index: 1000;
}
.help-window {
  position: fixed;
  bottom: 36px;
  right: 96px;
  z-index: 1000;
}

@media screen and (max-width: 480px) {
  .help-window {
    bottom: 0;
    right: 0;
  }
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: none !important;
}
body {
  font-family: $font-regular, sans-serif !important;
  background-color: $background-blue !important;
  overflow-y: hidden;
  overflow-x: hidden;
  box-shadow: none !important;
  padding-right: 0 !important;
  -moz-appearance: none !important;
  -webkit-appearance: none;
  height: 100%;
}
</style>
<style lang="scss">
@import './styleVariables';

.swal2-textarea {
  border: 1px solid $light-gray !important;
  box-sizing: border-box;
  border-radius: 8px !important;
  font-size: 14px !important;
  padding: 6px 11px !important;
  outline: none;
  font-family: $font-regular !important;
  resize: none;
  height: 136px !important;
  margin: 0 !important;
  &:focus {
    border-color: $bright-blue !important;
    box-shadow: none !important;
  }
}
.swal2-checkbox {
  cursor: pointer;
  span {
    font-size: 14px;
  }
}
.title-small-class {
  color: $main-dark !important;
  font-size: 18px !important;
  line-height: 21px !important;
  margin-bottom: 12px !important;
  align-self: center;
}
.delete-button {
  color: $bright-blue !important;
  border: 1px solid $bright-blue;
  border-radius: 4px;
  width: 137px;
  height: 33px;
  font-size: 14px;
}
.delete-button:hover {
  opacity: 0.7;
}
.delete-button:focus {
  outline: none;
}
.confirm-button:focus {
  outline: none;
}
.confirm-button {
  color: $main-white !important;
  background-color: $bright-blue;
  border-radius: 4px;
  width: 137px;
  height: 33px;
  font-size: 14px;
}

.popup-class {
  border-radius: 10px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  align-items: flex-start;
  width: 485px;
  height: 170px;
  font-size: 18px !important;
}
.swal2-content {
  padding: 0 !important;
}
.swal2-actions {
  display: flex !important;
  justify-content: flex-end !important;
}
.swal2-header {
  padding: 0 !important;
}
.title-class {
  color: $main-dark !important;
  font-size: 18px !important;
  line-height: 21px !important;
  margin-bottom: 12px !important;
  align-self: flex-start;
}
.content-class {
  font-size: 14px !important;
  color: $light-gray !important;
  margin-bottom: 24px !important;
  text-align: unset !important;
  margin-top: 12px !important;
  padding-left: 96px !important;
  padding-right: 24px !important;
}
.actions-class {
  justify-content: flex-end !important;
  margin-top: 0 !important;
  margin-bottom: 24px;
  padding-top: 24px;
  border-top: 1px solid $light-gray;
  width: 100%;
}
.swal2-image {
  margin: 0 !important;
  position: absolute;
  top: 24px;
  left: 27px;
  width: 43px;
  height: 52px;
}
.swal2-icon {
  display: none !important;
}

@media screen and (max-width: 360px) {
  .swal2-actions {
    flex-wrap: wrap-reverse !important;
    button {
      width: 100%;
      margin-left: 0;
      margin-bottom: 12px;
    }
  }
}
</style>
