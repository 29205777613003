<template>
  <div class="modal-background" @mousedown.self="hideModal()">
    <div class="modal-content">
      <div class="modal-content-header">
        <span>Назначить тестирование</span>
        <img src="../../../assets/img/ES/close_gray.svg" alt="close" @click="hideModal()" />
      </div>
      <div class="info-modal-schedule">
        <div class="info-modal-date">
          <img src="../../../assets/img/icons/calendar-dark.svg" />
          <span>{{ formatDate(day) }}</span>
        </div>
        <div class="info-modal-time">
          <img src="../../../assets/img/icons/alarm.svg" />
          <span>{{ formatHour(hour) }}</span>
        </div>
      </div>
      <div class="lesson-type">
        <span>Type</span>
        <div class="teacherTesting">Teacher Testing</div>
      </div>
      <div class="teacher-name">
        <span class="name-label">Teacher name</span>
        <span class="name">{{ teacherName }}</span>
      </div>
      <div class="methodist-name">
        <span class="name-label">Methodist name</span>
        <div>
          <vue-select
            v-model="methodist"
            :options="methodists"
            :clearable="false"
            @open="getMethodists"
            class="select"
          >
            <template slot="option" slot-scope="option">
              <div class="d-center">
                <span>{{ option.full_name }}</span>
              </div>
            </template>
            <template slot="selected-option">
              <div class="selected d-center">
                {{ methodist.full_name }}
              </div>
            </template>
          </vue-select>
        </div>
      </div>
      <div class="save-test">
        <button @click="setTest">Сохранить</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import VueSelect from 'vue-select'
import { domain, MicroServices } from '@/globalVariables'
export default {
  name: 'AddTestModal',
  props: ['day', 'hour', 'teacherName', 'teacherUuid'],
  data() {
    return {
      methodist: null,
      methodists: []
    }
  },
  mounted() {
    this.getBestMethodist()
  },
  components: { VueSelect },
  methods: {
    /**
     * Получить самого подходящего методиста
     */
    getBestMethodist() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Methodologist/getSuitableUser`,
        method: 'POST',
        data: [
          {
            date: `${this.day} ${this.formatHourToSend(this.hour)}`
          }
        ]
      })
        .then((response) => {
          this.methodist = response.data.data
        })
        .catch((error) => {
          this.errorAlert(error)
          this.hideModal()
        })
    },

    /**
     * Получить список методистов, свободных в выбранное время
     */
    getMethodists() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Methodologist/getSuitableUsers`,
        method: 'POST',
        data: [
          {
            date: `${this.day} ${this.formatHourToSend(this.hour)}`
          }
        ]
      })
        .then((response) => {
          this.methodists = response.data.data
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Назначить тестирование
     */
    setTest() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Lesson/create`,
        method: 'POST',
        data: [
          {
            teacher_uuid: this.teacherUuid,
            methodologist_uuid: this.methodist.sync_uuid,
            kls_type_id: 42,
            date: `${this.day} ${this.formatHourToSend(this.hour)}`
          }
        ]
      })
        .then(() => {
          this.successAlert('Тестирование назначено!')
          this.hideModal(true)
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },
    hideModal(update) {
      this.$emit('hideAddTestModal', update)
    },
    formatDate(date) {
      return `${date.split('-')[2]}.${date.split('-')[1]}.${date.split('-')[0]}`
    },
    formatHour(hour) {
      return `${Number(hour) < 10 ? '0' + Number(hour) : Number(hour)}:00 - ${
        Number(hour) < 9 ? '0' + (Number(hour) + 1) : Number(hour) + 1
      }:00`
    },
    formatHourToSend(hour) {
      return `${Number(hour) < 10 ? '0' + Number(hour) : Number(hour)}:00:00`
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../styleVariables';

.modal-background {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.3);
  animation: fade-in 0.3s;
  .modal-content {
    background-color: $main-white;
    border-radius: 8px;
    padding: 14px 24px;
    width: 558px;
    height: fit-content;
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        font-size: 18px;
      }
      img {
        cursor: pointer;
      }
    }

    .info-modal-schedule {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 14px;
      .info-modal-date {
        margin-right: 26px;
        img {
          margin-right: 9px;
        }
        span {
          color: $main-dark;
        }
      }
      .info-modal-time {
        img {
          margin-right: 9px;
        }
        span {
          color: $main-dark;
        }
      }
    }

    .lesson-type {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      align-items: center;
      margin-top: 16px;
      span {
        color: $main-dark;
        margin-right: 26px;
      }
      .teacherTesting {
        background-color: rgba(29, 190, 64, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        color: $main-dark;
      }
    }
    .teacher-name {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      margin-top: 17px;
      .name-label {
        color: $main-dark;
        min-width: 128px;
        text-align: start;
        margin-right: 12px;
      }
      .name {
        color: $main-dark;
        font-family: $font-regular;
      }
    }
    .methodist-name {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      margin-top: 17px;
      margin-bottom: 32px;
      .name-label {
        color: $main-dark;
        min-width: 128px;
        text-align: start;
        margin-right: 12px;
      }
      .select {
        //color: $main-dark;
        //font-family: $font-regular;
        width: 280px;
        //border: 1px solid $light-gray;
        //border-radius: 4px !important;
      }
      /deep/ .vs__dropdown-toggle {
        display: flex;
        padding: 0 0 4px;
        background: none;
        border: 1px solid rgba(60, 60, 60, 0.26);
        border-radius: 4px;
        min-height: unset !important;
        height: fit-content;
        white-space: normal;
      }
      /deep/ .vs__search {
        display: none;
      }
    }
    .save-test {
      display: flex;
      justify-content: flex-end;
      button {
        font-size: 14px;
        color: #ffffff;
        background-color: $bright-blue;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
        border-radius: 4px;
        margin-right: 15px;
        outline: none;
        width: 156px;
        height: 32px;
        border: 1px solid transparent;

        &:active {
          background-color: $hover-bright-blue;
        }
      }
    }
  }
}
</style>
