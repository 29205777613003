<template>
  <div class="d-flex">
    <img
      class="arrow"
      style="margin-left: 10px"
      src="../../../assets/img/SelectArrow.svg"
      alt="open"
    />
  </div>
</template>

<script>
export default {
  name: 'OpenIndicator',
  mixins: [],
  props: [],
  components: {},
  data() {
    return {}
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
}
</script>

<style scoped>
img {
  width: 10px;
}
</style>
