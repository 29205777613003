<template>
  <div class="type-container">
    <div
      class="type"
      :style="`background-color: ${getBgColor(item.code)}`"
      v-for="(item, index) in items"
      :key="index"
      @click="$emit('chooseType', item.id, item.code, item.name)"
    >
      {{ item.name }}
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { domain, MicroServices } from '@/globalVariables'
export default {
  name: 'Types',
  data() {
    return {
      items: []
    }
  },
  mounted() {
    this.getTypes()
  },
  methods: {
    /**
     * Получить типы для занятия
     */
    getTypes() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Classifier/getLessonTypes`,
        method: 'GET'
      })
        .then((response) => {
          this.items = response.data.data.items
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Получить цвет фона в зависимости от типа
     * @param code
     * @returns {string}
     */
    getBgColor(code) {
      switch (code) {
        case 'PLACEMENT_TEST':
          return 'rgba(255, 107, 0, 0.13);'
        case 'REGULAR':
        case 'TEACHER_TEST_REGULAR_LESSON':
          return 'rgba(29, 93, 190, 0.13);'
        case 'CHECK_UP':
          return 'rgba(255, 184, 0, 0.13);'
        case 'CHECK_PROGRESS':
        case 'TEACHER_TEST_PROGRESS_TEST_LESSON':
          return 'rgba(82, 0, 255, 0.13);'
        case 'TEACHER_TEST':
        case 'TEACHER_FIRST_TEST':
          return 'rgba(29, 190, 64, 0.13);'
        default:
          return 'rgba(255, 255, 255, 1);'
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../styleVariables';

.type-container {
  background: $main-white;
  border: 0.5px solid $light-gray;
  box-shadow: 1px 0 20px -7px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  padding: 10px 8px 14px 8px;

  .type {
    cursor: pointer;
    padding: 4px 12px;
    margin-bottom: 10px;
    border-radius: 5px;
    width: fit-content;
  }
}
</style>
