<template>
  <div class="notification-preview">
    <div class="notification-preview-cards" v-if="notifications.length">
      <div class="notification-preview-card" v-for="item in notifications" :key="item.id">
        <div class="notification-preview-card__header">
          <div class="read-status" />
          <div class="date">{{ item.created }}</div>
          <div
            class="type"
            v-if="
              $cookie.get('internalName') === 'manager' || $cookie.get('internalName') === 'admin'
            "
          >
            {{ item.notification_type_name }}
          </div>
        </div>
        <div class="notification-preview-card__content" v-html="item.message" />
      </div>
    </div>
    <div class="notification-preview-none" v-else><span>Нет непрочитанных оповещений</span></div>
    <div class="notification-preview-all">
      <span
        @click="
          $router.push('/monolit/Notifications')
          $emit('closeNotifications')
        "
        >Показать все оповещения</span
      >
    </div>
  </div>
</template>

<script>
import scriptMixins from '@/components/scriptMixins/scriptMixins'

export default {
  name: 'NotificationPreview',
  props: {
    notifications: Array
  },
  mixins: [scriptMixins],
  data() {
    return {}
  },
  mounted() {},
  methods: {}
}
</script>

<style scoped lang="scss">
@import '../../../styleVariables';

.notification-preview {
  width: 440px;
  background-color: $main-white;
  border-radius: 4px;
  box-shadow: 1px 0px 20px -7px rgba(0, 0, 0, 0.16);
  border: 0.5px solid $light-gray;
  position: absolute;
  top: 58px;
  right: 0;

  &-cards {
    .notification-preview-card {
      border-bottom: 1px solid #dee6f5;
      padding: 8px 14px 14px 14px;
      &:last-child {
        border-color: transparent;
      }
      &__header {
        display: flex;
        align-items: center;
        margin-bottom: 2px;

        .read-status {
          width: 6px;
          height: 6px;
          border-radius: 5px;
          margin-right: 9px;
          background-color: $red;
        }

        .date {
          margin-right: 10px;
          font-family: $font-regular;
          color: $main-gray;
          font-size: 14px;
          line-height: 24px;
        }

        .time {
          margin-right: 22px;
          font-family: $font-regular;
          color: $main-gray;
          font-size: 14px;
          line-height: 24px;
        }

        .type {
          color: $main-dark;
          font-size: 14px;
          line-height: 24px;
        }
      }

      &__content {
        font-family: $font-regular;
        color: $main-dark;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        /deep/ span {
          font-family: $font-regular !important;
        }

        /deep/ a {
          color: $bright-blue;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &-none {
    padding: 24px;
    display: flex;
    justify-content: center;
    span {
      color: $main-gray;
    }
  }

  &-all {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    span {
      color: $main-gray;
      cursor: pointer;
      font-size: 14px;
      transition: 0.2s;
      &:hover {
        color: $bright-blue;
      }
    }
  }
}
</style>
