<template>
  <div class="main-table-container">
    <div>
      <b-col class="row">
        <div class="col table-and-pagination" v-click-outside="hideManageRecord">
          <b-table
            ref="mainTable"
            :bordered="this.$store.state.settingTable.bordered || bordered"
            :borderless="this.$store.state.settingTable.borderless || borderless"
            :dark="this.$store.state.settingTable.dark || dark"
            :fields="fieldsTable"
            :fixed="this.$store.state.settingTable.fixed || fixed"
            :foot-clone="this.$store.state.settingTable.footClone || footClone"
            :head-variant="HeadVariant"
            :items="items"
            :outlined="this.$store.state.settingTable.outlined || outlined"
            :small="this.$store.state.settingTable.small || small"
            responsive
            :selectable="multiSelect"
            :sticky-header="true"
            :table-variant="tableVariant"
            :empty-text="'Записей не найдено!'"
            :show-empty="true"
            @row-selected="onRowSelected"
            @sort-changed="handleSortChange"
            :noLocalSorting="true"
            :key="resetSorted"
            :class="{ 'align-items': fieldsTable.length < 2 }"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            :tbody-tr-class="rowHover"
          >
            <template v-for="field in fieldsTable" v-slot:[`cell(${field.key})`]="data">
              <Widget
                class="col-sm"
                :key="field.key"
                :type="data.item[field.key].type"
                v-bind="{ value: data.item[field.key].value, disabled: data.item.disabled }"
                v-if="
                  !Array.isArray(data.item[field.key]) &&
                  typeof data.item[field.key] === 'object' &&
                  data.item[field.key] !== null
                "
              />
              <div
                v-else-if="
                  data.item[field.key] &&
                  isJsonString(data.item[field.key]) &&
                  typeof JSON.parse(data.item[field.key]) === 'object'
                "
                :key="field.key"
              >
                <Widget
                  class="col-sm"
                  :key="field.key"
                  type="json"
                  v-bind="{
                    value: data.item[field.key].value,
                    disabled: data.item.disabled,
                    item: JSON.parse(data.item[field.key])
                  }"
                />
              </div>
              <span :key="field.key" v-else>
                <span
                  v-if="
                    (typeof data.item[`${field.key}`] === 'string' &&
                      data.item[`${field.key}`].match('image')) ||
                    field.key === 'avatar'
                  "
                >
                  <img
                    v-if="data.item.avatar"
                    :src="field.key === 'avatar' ? data.item.avatar : renderUrl(data.item.avatar)"
                    width="70px"
                    height="70px"
                    class="image_table"
                  />
                </span>
                <span
                  v-else-if="
                    typeof data.item[`${field.key}`] === 'string' &&
                    data.item[`${field.key}`].match('files')
                  "
                >
                  <button
                    v-if="data.item.file_demo"
                    class="btn btn-sm btn-outline-secondary btn_download"
                    @click="downloadFromServer(renderUrl(data.item.file_demo), data.item.file_demo)"
                  >
                    Скачать
                  </button>
                </span>
                <span v-else-if="Array.isArray(data.item[field.key])">
                  <p>
                    {{ renderValue(data.item[`${field.key}`][0], data) }}
                    <span class="hide-relation" v-if="data.item[`${field.key}`].length - 1 !== 0"
                      >+{{ data.item[`${field.key}`].length - 1 }}</span
                    >
                  </p>
                </span>
                <span
                  v-else-if="field.key === 'kls_sth_status_name'"
                  style="display: flex; align-items: center"
                >
                  <div
                    class="sth-status"
                    :style="
                      data.item.kls_sth_status_code === 'STH_LESSON_PLANNED'
                        ? 'background-color: #F3D232'
                        : data.item.kls_sth_status_code === 'STH_LESSON_ENDED'
                        ? 'background-color: #1DBE40'
                        : data.item.kls_sth_status_code === 'STH_SKIPPED_STUDENT'
                        ? 'background-color: #FFD500'
                        : data.item.kls_sth_status_code === 'STH_SKIPPED_STUDENT_WITH_REASON'
                        ? 'background-color: #FFD500'
                        : data.item.kls_sth_status_code === 'STH_SKIPPED_TEACHER'
                        ? 'background-color: #FFD500'
                        : data.item.kls_sth_status_code === 'STH_CANCELLED'
                        ? 'background-color: #E13429'
                        : data.item.kls_sth_status_code === 'STH_REFUND'
                        ? 'background-color: #E13429'
                        : 'background-color: #FF8541'
                    "
                  />
                  {{ data.item.kls_sth_status_name }}
                </span>
                <div v-else-if="field.key === 'result'" class="result-lessons">
                  {{ `${data.item.result} ${endingOfWord(data.item.result)}` }}
                </div>
                <div v-else-if="field.key === 'lesson_amount_change'">
                  <span>{{
                    `${data.item.lesson_amount_change} ${endingOfWord(
                      data.item.lesson_amount_change
                    )}`
                  }}</span>
                </div>
                <div v-else-if="field.key === 'lesson_id'">
                  <div
                    v-if="data.item.lesson_id"
                    class="to-lesson"
                    @click="toLessonCard(data.item.lesson_id)"
                  >
                    Перейти к занятию
                    <img src="../../assets/img/ES/chevron_right_blue.svg" alt="right" />
                  </div>
                  <div v-else>-</div>
                </div>
                <span v-else v-html="renderValue(data.item[`${field.key}`], data)" />
              </span>
            </template>
          </b-table>
        </div>
      </b-col>
      <!--  pagination        -->
      <div class="col-sm pagination-wrapper">
        <div class="pagination-and-output">
          <div class="amount-output" v-if="totalPages">
            <span> Отображать по: </span>
            <b-form-select
              v-model="PerPage"
              :options="perPageOptions"
              size="sm"
              class="amount-on-page-select"
              @input="getAmountOnPage"
            ></b-form-select>
          </div>
          <div class="pagination">
            <div
              class="pagination__btn back"
              :class="{ disabled: isDisabledBack }"
              @click="goFirstPage"
            >
              <p class="first-double-btn">
                <span class="page-link"
                  ><img src="../../assets/img/ES/double_arrow_left.svg" alt="start"
                /></span>
              </p>
            </div>
            <div
              class="pagination__btn back"
              :class="{ disabled: isDisabledBack }"
              @click="goPreviousPage"
            >
              <p class="first-btn">
                <span class="page-link"
                  ><img src="../../assets/img/ES/arrow_left.svg" alt="prev"
                /></span>
              </p>
            </div>
            <p class="pagination__pages">
              <span>{{ currentPage }}</span>
              из
              <span>{{ totalPages }}</span>
            </p>
            <div
              class="pagination__btn back"
              :class="{ disabled: isDisabledNext }"
              @click="goNextPage"
            >
              <p class="last-double-btn">
                <a class="page-link"
                  ><img src="../../assets/img/ES/arrow_right.svg" alt="next"
                /></a>
              </p>
            </div>
            <div
              class="pagination__btn next"
              :class="{ disabled: isDisabledNext }"
              @click="goLastPage"
            >
              <p class="last-double-btn">
                <a class="page-link"
                  ><img src="../../assets/img/ES/double_arrow_right.svg" alt="end"
                /></a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="modalWindow">
      <ModalWindow
        :data="modalContent"
        :fields="modalField"
        :items="dataTable"
        :selectedItems="selectedItems"
        :type="type"
        :lg="modalField.length >= 6 && type.value !== 'view'"
      />
    </div>

    <!--    <settingModal :fields="fields" :theme-name="themeName" />-->
    <div class="manage-record">
      <div v-for="(data, index) in DetailData" :key="index">
        <div
          class="manage-record__item"
          v-for="(item, index) in data"
          :key="index"
          @click="$root.$emit(item.value, selectedItems)"
          :style="
            item.value === 'edit'
              ? 'color: #4B83F0'
              : item.value === 'delete'
              ? 'color: #FF7474'
              : ''
          "
          :hidden="roleHidden(item)"
        >
          <img
            src="~@/assets/img/searchIcon.svg"
            class="options-btn"
            v-if="item.value === 'view'"
            alt="view button"
          />
          <img
            src="~@/assets/img/editHover.svg"
            v-if="item.value === 'edit'"
            alt="edit button"
            class="options-btn"
          />
          <img
            src="~@/assets/img/delHover.svg"
            v-if="item.value === 'delete'"
            alt="delete button"
            class="options-btn"
          />
          {{ item.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Widget from '../../components/Core/Widget'
import eventDelegate from '../../components/Core/KernelProject/eventDelegate'
import ModalWindow from '../../components/Table/ModalWindow/ModalWindow'
import renderWidget from '../../components/Core/KernelProject/renderWidget'
import tableEvents from '../../components/Core/KernelProject/tableEvents'
import TableMixinsComponents from '../../components/Table/TableMixinsComponents'
import ClickOutside from 'vue-click-outside'
import authEvents from '../../components/Core/KernelProject/authEvents'
import scriptMixins from '@/components/scriptMixins/scriptMixins'
export default {
  name: 'Table',
  components: {
    Widget,
    ModalWindow
  },
  data() {
    return {}
  },

  methods: {
    toLessonCard(id) {
      this.$router.push({ name: 'LessonCard', params: { lesson_id: id } })
    },
    /**
     * Скрыть управление записями
     * */
    hideManageRecord() {
      document.getElementsByClassName('manage-record')[0].style.display = 'none'
      this.isManageRecordShow = false
    }
  },
  mounted: function () {
    this.$root.$on('getFilterData', (item) => {
      this.getFilterData()
    })
    // delete items
    this.$root.$on('delete', (item) => {
      this.deleteOnTable(item)
    })
    // view open modal add items
    this.$root.$on('add', (item) => {
      this.openModalAdd()
    })
    // view items
    this.$root.$on('view', (item) => {
      this.viewOnModal(item)
    })
    // close modal
    this.$root.$on('close', () => {
      this.$bvModal.hide('modal')
    })
    // edit
    this.$root.$on('edit', (item) => {
      this.viewEditModal(item)
    })
    // save
    this.$root.$on('save', (data) => {
      this.addOnModal()
    })
    // save and add more
    this.$root.$on('saveAndAdd', () => {
      this.hideModal = false
      this.addOnModal()
    })
    // filter need
    this.$root.$on('filterSearch', (data) => {
      this.getDataWithFilter()
    })
    // filter clear
    this.$root.$on('filterClear', (data) => {
      let clear = true
      this.getDataPagination(clear)
      this.$root.$emit('getMetaData', true)
    })
    // filter clear
    this.$root.$on('switchFilter', (data) => {
      this.switchFilter()
    })
    // open Relation
    this.$root.$on('OpenRelation', () => {
      this.openByRelation()
      this.$store.state.viewBreadCrumbs = true
    })
    // Multi remove records
    this.$root.$on('removeAll', (data) => {
      this.multiRemoveItems()
    })
    // open modal Multi edit records
    this.$root.$on('editAllModal', (data) => {
      this.modalMultiEditItems()
    })
    // save multi edit
    this.$root.$on('editAll', (data) => {
      this.MultiEditItems()
    })
    // filter spinner => true
    this.$root.$on('filterSpinner', (data) => {
      setTimeout(() => {
        this.filter = true
      }, 500)
    })
    this.$root.$on('setPagination', (PerPage, currentPage) => {
      this.PerPage = PerPage
      this.currentPage = currentPage
    })
    this.$root.$on('setDefaultSort', (data) => {
      this.sortBy = Object.keys(data).toString()
      this.sortDesc = Object.values(data).toString()
    })
  },
  mixins: [
    eventDelegate,
    renderWidget,
    tableEvents,
    TableMixinsComponents,
    authEvents,
    scriptMixins
  ],
  directives: {
    ClickOutside
  }
}
</script>
<style lang="scss">
@import '../../styleVariables.scss';

::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  background-color: rgba(145, 160, 186, 0.15);
  border-radius: 4px;
  margin: 5px;
}

::-webkit-scrollbar-thumb {
  background: rgba(145, 160, 186, 0.2);
  border-radius: 4px;
}
.row.col {
  overflow: hidden;
}
.main-table-container {
  height: 100vh;
}
#table {
  min-height: 100%;
}
.flex-fill {
  display: flex;
  flex-direction: column;
  min-height: 83%;
}
.pagination-wrapper {
  display: flex;
  max-height: 50px;
  margin-top: auto;
}
.b-table-sticky-header.table-responsive {
  min-height: 100%;
}

.table-hover tbody tr:hover {
  background-color: transparent !important;
  cursor: pointer;
}
.b-table > tbody > tr:hover td {
  background-color: $background-blue;
}

.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  overflow-y: auto;
}

.wide-table {
  width: 100% !important;
}
.hide-filter {
  display: none;
}

.widget .col div {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

p {
  margin-bottom: 0 !important;
}

.widget__components {
  padding-right: 10px;
}

.table {
  box-shadow: 1px 0px 20px -7px rgba(0, 0, 0, 0.16);
  overflow-x: auto;
  font-size: 13px;
  width: 100%;
  background-color: $main-white;
  margin-bottom: 10px !important;
}

.table-cells {
  white-space: pre-line;
  text-align: center;
}
body {
  min-height: 100vh;
}

.invalid-feedback {
  display: contents !important;
}

.invalid-control {
  border-color: #d9534f !important;
}

.page-link {
  border: none;
  pointer-events: none;
  min-width: unset;
}

.pagination__btn {
  cursor: pointer !important;
}

.spinner {
  align-self: center;
  margin-top: 50%;
}

.card-body {
  padding: 0 !important;
}
.col {
  padding-right: 0 !important;
}
.btn_download {
  height: 30px;
  border: 1px solid #8897aa;
  box-sizing: border-box;
  border-radius: 4px;
}
.btn-sm,
.btn-group-sm > .btn {
  padding: 0.177rem 0.6rem;
}
.image_table {
  border-radius: 100px;
  object-fit: cover;
}

.table tbody tr td {
  vertical-align: middle;
  text-align: center;
}

.table thead {
  text-align: center;
}

.table tfoot {
  text-align: center;
}

.filters div .form-group label {
  padding-bottom: 5px;
}

.item {
  color: #4b83f0;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  margin-top: 4px;
}
.btn_view {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
}
.image__View {
  background: #f5fcff;
  border-radius: 4px;
  text-align: center;
}
.swal2-confirm {
  margin-left: 10px;
}

.approve-buttons {
  display: flex;
  justify-content: center;
  button {
    width: 28px;
    height: 28px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
    border-radius: 50px;
    //img {
    //  width: 16px;
    //  height: 16px;
    //}
  }
  .approve {
    background-color: $bright-blue;
    margin-right: 20px;
  }
  .disapprove {
    background-color: $main-white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.sth-status {
  width: 6px;
  height: 6px;
  border-radius: 50px;
  margin-right: 9px;
}

.result-lessons {
  width: fit-content;
  font-size: 14px;
  line-height: 24px;
  color: $bright-blue;
  padding: 5px 16px;
  background-color: rgba(0, 87, 255, 0.13);
  border-radius: 5px;
  white-space: nowrap;
}
.to-lesson {
  font-size: 14px;
  line-height: 24px;
  color: $bright-blue;
  display: flex;
  font-family: $font-regular;

  span {
    cursor: pointer;
  }
}

@media screen and (max-width: 768px) {
  .pagination-and-output {
    display: flex;
  }
}

@media screen and (max-width: 570px) {
  .pagination-and-output {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: 0 auto;
    align-items: flex-start;
  }
}
</style>

<style lang="scss">
@import '../../components/Widgets/ColorVariables.scss';
@import '../../styleVariables';

$color_gray-1: #aab3c3;
$color_gray-2: #cfd5e0;
$color_primary: #0b2454;

.hide-relation {
  color: $link-gray;
}

/deep/ .table-hover tbody tr:hover {
  color: $main-blue !important;
  background-color: transparent !important;
}
/deep/.table-hover > tbody > tr:hover > td,
.table-hover > tbody tr:hover > th {
  color: $main-blue;
  background: transparent !important;
}
/deep/ .custom-select-sm {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
/deep/ table.b-table-selectable > tbody > tr.b-table-row-selected > td,
table.b-table-selectable > tbody > tr.b-table-row-selected > th {
  background-color: $btn-hover-background !important;
  color: $main-gray !important;
}

/deep/ .default-style .b-table-sticky-header,
.default-style .table-responsive,
.default-style[class='table-responsive-'] {
  margin-bottom: 0 !important;
}
.pagination a,
.pagination span {
  font-size: 14px !important;
  line-height: 16px;
  color: $main-gray !important;
}
.amount-output {
  font-family: $font-bold;
  .custom-select-sm {
    font-family: $font-bold;
    color: $main-gray !important;
  }
  span {
    color: $main-gray !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    min-width: 120px;
  }
}
.custom-select-sm,
.input-group-sm .custom-select {
  border: none;
  color: $link-gray;
  font-weight: 400;
  font-size: 14px;
  font-family: Roboto, sans-serif;
  background: url('../../assets/img/paginationIcon.svg') no-repeat right center/17px 12px;
  background-size: 11px 8px;
  padding-right: 20px;
}
/deep/.custom-select {
  border: none !important;
}
.card {
  box-shadow: none !important;
  border: none !important;
}
.card-header {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: $text-gray;
  border-bottom: 0 !important;
  padding-left: 0 !important;
  padding-bottom: 12px;
}
.global-search {
  img {
    position: relative;
    right: -36px;
  }
}
#globalSearch {
  background: $main-white;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  //border: 1.5px solid transparent;
  border: 0.5px solid #e8edf3;
  color: $main-dark;
  font-family: $font-regular;
  outline: none;
  padding: 7px 6px 7px 41px;
  height: 41px;
  width: 375px !important;
  font-size: 14px;
  line-height: 16px;
}
#globalSearch::placeholder {
  color: $light-gray;
  font-size: 14px;
  line-height: 16px;
  font-family: $font-bold;
  margin-left: 40px !important;
}
#globalSearch:focus {
  border: 1.5px solid $main-blue;
  box-shadow: 0px 1px 5px -2px rgba(18, 132, 255, 0.55);
}
#globalSearch:disabled {
  background: $hover-gray;
  color: $inactive-gray;
  font-weight: 400;
}
.search-icon {
  top: 78px !important;
  left: 25px !important;
}
thead {
  border-bottom: 1px solid $table-border;
  color: $main-dark;
}
.align-items {
  .table-row {
    text-align: center !important;
  }
}
.pagination-and-output {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  width: 100%;
}

.amount-output {
  display: flex;
  justify-content: flex-end;
  color: #9ea6bd;
  font-weight: 500;
  margin-right: 80px;

  span {
    margin-right: 9px;
    align-self: center;
  }
}

.pagination {
  align-items: baseline;
  .pagination__btn {
    cursor: pointer;
  }

  .pagination__pages {
    width: fit-content;
    width: -moz-fit-content;
    text-align: center;
    padding: 0 6px 0 0;
    line-height: 2.57;
    color: $main-gray;
    margin-bottom: 0;
    input {
      color: $main-gray;
    }

    @-moz-document url-prefix() {
      & {
        min-width: 100px;
      }
    }
  }

  .page-switch {
    width: 34px;
    height: 37px;
    border-radius: 5px;
    text-align: center;
    color: $color_primary;
    font-weight: 500;
  }

  p {
    height: 2.57rem;
    background-repeat: no-repeat;
    background-position: center;
  }

  .first-btn {
    transition: 0.01s linear;
  }

  .last-btn {
    transition: 0.01s ease-out;
  }

  .last-double-btn {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .pagination {
    padding-right: 15px;

    .page-switch {
      height: 32px;
    }
  }
}
</style>

<style lang="scss" scoped>
@import '../../components/Widgets/ColorVariables';
@import '../../styleVariables';

.manage-record {
  background-color: $main-white;
  display: none;
  position: absolute;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  width: 148px;
  box-shadow: $tiny_shadow;
  &__item {
    border-bottom: 1px solid $input-gray;
    cursor: pointer;
    padding: 12px;
  }
  &__item:hover {
    background-color: $hover-gray;
  }
  div:last-child {
    border: none;
  }
}
.options-btn {
  width: 14px;
  height: 14px;
  margin-right: 4px;
}
.amount-output {
  span {
    color: $main-dark;
    font-weight: 500;
    font-size: 14px;
  }
}
input {
  background: $main-light;
  border: none;
  border-radius: 4px;
  color: $main-dark;
  outline: none;
  padding: 7px 28px 7px 12px;
  font-size: 12px;
  font-weight: 500;
}
input::placeholder {
  color: $inactive-gray;
}
.pagination__pages {
  font-size: 14px;
  font-weight: 500;
  span {
    font-size: 14px;
    color: $inactive-gray;
  }
  input {
    width: 20px;
    border: none;
    background: transparent;
    color: $main-dark;
    font-size: 14px;
    font-weight: 500;
    padding: 0 !important;
  }
}
.btn:focus {
  box-shadow: none;
}
/deep/ {
  thead th {
    background-color: $main-white;
    outline: none;
    color: $main-dark;
  }
  .table-row {
    text-align: left;
    font-family: $font-regular;
    color: $main-dark;
    font-size: 14px;
    outline: none;
    height: 66px;
    border-bottom: 1px solid #dee6f5;
  }
  .table tbody tr {
    cursor: pointer;
  }
  .table tbody tr td span span {
    font-family: $font-regular !important;
  }
  .table thead th {
    border: none;
    vertical-align: inherit;
    color: $main-dark !important;
    font-family: $font-bold;
    font-size: 14px;
    line-height: 18px;
    height: 30px;
    white-space: break-spaces;
  }

  tbody td {
    border: none;
  }
  tr:focus {
    outline: none;
  }
  .table.b-table > thead > tr > [aria-sort='ascending'] {
    background-image: url('../../assets/img/SortASC.svg');
    background-size: 10px;
  }
  .table.b-table > thead > tr > [aria-sort='descending'] {
    background-image: url('../../assets/img/SortDESC.svg');
    background-size: 10px;
  }
  .table.b-table > thead > tr > [aria-sort='none'] {
    background-image: url('../../assets/img/SortNone.svg');
    background-size: 10px;
  }
  .table .table-primary > td {
    background-color: #e4ecfd;
    color: $main-dark;
  }
  fieldset {
    width: 100%;
  }
  .table-responsive {
    min-height: 60vh !important;
    overflow-y: auto;
    overflow-x: auto;
    margin-bottom: 0 !important;
    border-radius: 8px 8px 0px 0px;
  }
}
</style>
