<template>
  <div class="notification-card">
    <div class="notification-card__header">
      <div class="mark-card">
        <input
          type="checkbox"
          class="custom-checkbox"
          :id="item.id"
          v-model="mark"
          @change="$emit('setMark', item.id, mark)"
        />
        <label :for="item.id"></label>
      </div>
      <div
        class="read-status"
        :style="item.is_read ? 'background-color: #C5CEE0' : 'background-color: #E13429'"
      ></div>
      <div class="date">{{ item.created }}</div>
      <div
        class="type"
        v-if="$cookie.get('internalName') === 'manager' || $cookie.get('internalName') === 'admin'"
      >
        {{ item.notification_type_name }}
      </div>
    </div>
    <div class="notification-card__content" v-html="item.message"></div>
  </div>
</template>

<script>
export default {
  name: 'NotificationCard',
  props: {
    item: Object,
    mark: Boolean
  }
}
</script>

<style scoped lang="scss">
@import '../../../styleVariables';
.notification-card {
  padding: 25px 24px 20px;
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    .mark-card {
      margin-right: 17px;
      height: 17px;
      .custom-checkbox {
        position: fixed;
        z-index: -1;
        opacity: 0;
      }
      .custom-checkbox + label {
        display: inline-flex;
        align-items: center;
        user-select: none;
        cursor: pointer;
      }
      .custom-checkbox + label::before {
        content: '';
        display: inline-block;
        width: 17px;
        height: 17px;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1px solid $light-gray;
        border-radius: 0.25em;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;
      }
      .custom-checkbox:checked + label::before {
        border-color: $light-gray;
        background-color: $main-white;
        background-image: url('../../../assets/img/ES/Checkmark.svg');
        background-size: 11px;
      }
    }
    .read-status {
      width: 6px;
      height: 6px;
      border-radius: 5px;
      margin-right: 9px;
    }
    .date {
      margin-right: 10px;
      font-family: $font-regular;
      color: $main-gray;
      font-size: 14px;
      line-height: 24px;
    }
    .time {
      margin-right: 22px;
      font-family: $font-regular;
      color: $main-gray;
      font-size: 14px;
      line-height: 24px;
    }
    .type {
      color: $main-dark;
      font-size: 14px;
      line-height: 24px;
    }
  }
  &__content {
    font-family: $font-regular;
    color: $main-dark;
    /deep/ span {
      font-family: $font-regular !important;
    }
    /deep/ a {
      color: $bright-blue;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
