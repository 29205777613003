<template>
  <div class="chosen-lesson-time">
    <div class="dates" v-for="date in sortedDates" :key="date.kls_day_id">
      <div class="date-item">
        <span>{{ days[date.kls_day_id - 1] }}</span>
        <SortHours :hours="date.work_hours" />
      </div>
    </div>
  </div>
</template>

<script>
import SortHours from '@/views/Schedule/components/MobileCalendar/components/SortHours'
export default {
  name: 'ChosenLessonTime',
  components: { SortHours },
  props: {
    dates: Array
  },
  data() {
    return {
      days: ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС']
    }
  },
  computed: {
    sortedDates() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.dates.sort(this.compare)
    }
  },
  methods: {
    compare(a, b) {
      if (a.kls_day_id < b.kls_day_id) {
        return -1
      }
      if (a.kls_day_id > b.kls_day_id) {
        return 1
      }
      return 0
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../../../styleVariables';

.chosen-lesson-time {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 12px;
  grid-column-gap: 12px;
  margin-bottom: 12px;
}
</style>
