<template>
  <div class="filter-select">
    <div class="filter-select__item" v-for="(item, index) in items" :key="item.id">
      <input
        v-if="multiple"
        type="checkbox"
        :id="item.id"
        @input="filter(item.id)"
        v-model="checked[name][index]"
        class="custom-checkbox"
      />
      <label :for="item.id" @click="!multiple && filter(item.id)">{{ item.name }}</label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterSelect',
  props: ['items', 'multiple', 'name', 'filters'],
  data() {
    return {
      checked: {
        status: [],
        role: []
      }
    }
  },
  mounted() {
    this.$root.$on('setClearChatFilters', () => {
      if (this.checked[this.name].length) {
        this.checked[this.name] = []
        for (let i = 0; i < this.items; i++) {
          this.checked[this.name].push(false)
        }
      }
      this.filters = {}
    })
  },
  methods: {
    /**
     * Формирование данных в удобном формате для фильтра
     * @param value
     */
    filter(value) {
      if (this.multiple) {
        if (!this.filters[this.name]) {
          this.filters[this.name] = []
        }
        if (!this.filters[this.name].filter((item) => item === value).length) {
          this.filters[this.name].push(value)
        } else {
          this.filters[this.name].splice(this.filters[this.name].indexOf(value), 1)
        }
      } else {
        this.filters[this.name] = []
        this.filters[this.name].push(value)
      }
      this.$emit('filter', this.filters)
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../../styleVariables';
.filter-select {
  background-color: $main-white;
  border-radius: 4px;
  box-shadow: 1px 0px 20px -7px rgba(0, 0, 0, 0.16);
  padding: 17px 12px 8px 12px;
  position: absolute;
  margin-top: 4px;
  z-index: 10;
  .custom-checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  .custom-checkbox + label {
    display: inline-flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
  }
  .custom-checkbox + label::before {
    content: '';
    display: inline-block;
    width: 17px;
    height: 17px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid $light-gray;
    border-radius: 0.25em;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
  }
  .custom-checkbox:checked + label::before {
    border-color: $light-gray;
    background-color: $main-white;
    background-image: url('../../../../assets/img/ES/Checkmark.svg');
    background-size: 11px;
  }
  &__item {
    transition: 0.2s;
    label {
      cursor: pointer;
    }
    cursor: pointer;
    //&:hover {
    //  background-color: $background-blue;
    //}
  }
}
</style>
