<template>
  <component :is="component" v-if="component" v-bind="{ ...$props, ...$attrs }" @$emits="$emits" />
</template>

<script>
import { debug } from '@/main'

export default {
  name: 'Widget',
  props: ['type'],
  data() {
    return {
      component: null
    }
  },
  computed: {
    componentLoader() {
      if (this.type === 'field') {
        this.type = this.$store.state.metaProjectData[this.$route.params.prefix][
          this.$route.params.model
        ]['fields'][this.$attrs.keyAdd].type
      }
      if (!this.type) {
        return null
      } else if (this.type === 'bool') {
        return () => import(`../Widgets/CheckBox/CheckBox.vue`)
      } else if (this.type === 'text') {
        return () => import(`../Widgets/Textarea/Textarea.vue`)
      } else if (this.type === 'email') {
        return () => import(`../Widgets/InputEmail/InputEmail.vue`)
      } else if (this.type === 'password') {
        return () => import(`../Widgets/InputPassword/InputPassword.vue`)
      } else if (this.type === 'phone') {
        return () => import(`../Widgets/InputPhone/InputPhone.vue`)
      } else if (this.type === 'number' || this.type === 'float' || this.type === 'int') {
        return () => import(`../Widgets/InputNumber/InputNumber.vue`)
      } else if (this.type === 'string') {
        return () => import(`../Widgets/InputText/InputText.vue`)
      } else if (this.type === 'datetime') {
        return () => import(`../Widgets/InputDateTimeLocal/InputDateTimeLocal.vue`)
      } else if (this.type === 'date') {
        return () => import(`../Widgets/InputDate/InputDate.vue`)
      } else if (this.type === 'Button') {
        return () => import(`../Widgets/Button/Button.vue`)
      } else if (
        this.type === 'relation' ||
        this.type === 'relationFilter' ||
        this.type === 'list'
      ) {
        return () => import(`../Widgets/Select/Select.vue`)
      } else if (this.type === 'time') {
        return () => import(`../Widgets/InputTime/InputTime.vue`)
      } else if (this.type === 'image') {
        return () => import(`../Widgets/InputFile/InputFile.vue`)
      } else if (this.type === 'mark') {
        return () => import('../Widgets/MarkDown/MarkDown')
      } else if (this.type === 'chat') {
        return () => import('../Widgets/Chat/Chat')
      } else if (this.type === 'file') {
        return () => import(`../Widgets/InputFile/InputFile.vue`)
      } else if (this.type === 'tree') {
        return () => import('../Widgets/TreeSelect/TreeSelect.vue')
      } else if (this.type === 'progress') {
        return () => import('../Widgets/ProgressBar/ProgressBar.vue')
      } else if (this.type === 'file-uploader') {
        return () => import('../Widgets/FileUploader/FileUploader')
      } else if (this.type === 'toggle') {
        return () => import('../Widgets/ToggleButton/ToggleButton.vue')
      } else if (this.type === 'toggleMassEdit') {
        return () => import('../Widgets/ToggleMassEdit/ToggleMassEdit.vue')
      } else if (this.type === 'radio') {
        return () => import('../Widgets/InputRadio/InputRadio.vue')
      } else if (
        this.type === 'range-day' ||
        this.type === 'range-month' ||
        this.type === 'range-year' ||
        this.type === 'month' ||
        this.type === 'month_range' ||
        this.type === 'range'
      ) {
        return () => import('../Widgets/DateRangePicker/DateRangePicker.vue')
      } else {
        return null
      }
      // return () => import(`../Widgets/${this.type}/${this.type}.vue`)
    }
  },
  mounted() {
    if (this.componentLoader) {
      this.component = () => this.componentLoader()
    } else {
      if (debug) {
        this.messageAnyType(`Виджет с типом: ${this.type} не найден`, 'error')
      }
    }
  },
  methods: {
    $emits(data) {
      this.$emit('$emits', data)
    }
  }
}
</script>
