<template>
  <div>
    <div class="card-container">
      <div class="card-header-container">
        <div class="lesson-details">
          <span class="name">Тестирование {{ lessonNumber }}</span>
          <div class="lesson-date">
            <img src="../../assets/img/icons/calendar-dark.svg" />
            <span>{{ formatDate(lessonInfo.date) }}</span>
          </div>
          <div class="lesson-time">
            <img src="../../assets/img/icons/alarm.svg" />
            <span>{{ formatTime(lessonInfo.hour) }}</span>
          </div>
        </div>
        <div class="edit-btn" v-if="lessonInfo.can_update">
          <div class="student-controls-btn" v-if="status.code === 'LESSON_PLANNED'">
            <button @click="openModal" class="lesson-cancel-btn">Отменить тестирование</button>
          </div>
          <div
            class="student-controls-btn"
            v-if="
              $cookie.get('internalName') !== 'teacher' &&
              lessonInfo.kls_type_code === 'TEACHER_FIRST_TEST' &&
              !lessonInfo.is_teacher_accepted
            "
          >
            <button @click="openModalComplete = true" class="lesson-cancel-btn">
              Завершить тестирование
            </button>
          </div>

          <button
            @click="editMode = true"
            v-if="
              !editMode &&
              $cookie.get('internalName') !== 'teacher' &&
              ($cookie.get('internalName') !== 'methodologist' || lessonInfo.has_filled_card) &&
              lessonInfo.can_be_updated
            "
          >
            <img src="../../assets/img/icons/edit.svg" />
            <div class="edit-label">Редактировать</div>
          </button>
        </div>
      </div>
      <div
        class="lesson-link-container"
        v-if="status.code === 'LESSON_PLANNED' && $cookie.get('internalName') !== 'methodologist'"
      >
        <label>Ссылка на урок:</label>
        <input
          type="text"
          v-if="
            editLessonCardMode &&
            ($cookie.get('internalName') === 'manager' || $cookie.get('internalName') === 'admin')
          "
          v-model="linkToZoom"
        />
        <a
          :href="
            lessonInfo.link_to_zoom.indexOf('http') !== -1
              ? lessonInfo.link_to_zoom
              : `http://${lessonInfo.link_to_zoom}`
          "
          target="_blank"
          v-else-if="lessonInfo.link_to_zoom"
          class="lesson-link"
          >{{ lessonInfo.link_to_zoom }}</a
        >
        <span v-else-if="!lessonInfo.link_to_zoom">Ссылка недоступна</span>
      </div>

      <div class="card-members">
        <div class="card-member-name" v-if="$cookie.get('internalName') !== 'teacher'">
          <span class="label">Teacher Name</span>
          <span class="name">{{ lessonInfo.teacher_name }}</span>
        </div>
        <div class="card-member-name" v-if="$cookie.get('internalName') !== 'methodologist'">
          <span class="label">Methodist Name</span>

          <vue-select
            v-if="
              editMode &&
              status.code === 'LESSON_PLANNED' &&
              ($cookie.get('internalName') === 'manager' || $cookie.get('internalName') === 'admin')
            "
            v-model="methodist"
            :clearable="false"
            style="width: 260px"
            @open="getMethodists(lessonInfo.date)"
            :options="methodists"
          >
            <template slot="option" slot-scope="option">
              <div class="d-center">
                <span>{{ option.full_name }}</span>
              </div>
            </template>
            <template slot="selected-option">
              <div class="selected d-center">
                {{ methodist.full_name }}
              </div>
            </template>
          </vue-select>
          <span
            class="name"
            v-else-if="
              $cookie.get('internalName') === 'manager' || $cookie.get('internalName') === 'admin'
            "
            >{{ lessonInfo.methodologist_name }}</span
          >
          <span class="name" v-else>{{ lessonInfo.methodologist_short_name }}</span>
        </div>
      </div>

      <div class="card-status-container">
        <div class="card-status">
          <span>Status</span>
          <div
            v-if="!removeStatus"
            :class="{
              lessonFinished: status.code === 'LESSON_ENDED',
              lessonPlanned: status.code === 'LESSON_PLANNED',
              lessonMissedByStudent: status.code === 'SKIPPED_STUDENT',
              lessonMissedByStudentWithReason: status.code === 'SKIPPED_STUDENT_WITH_REASON',
              lessonMissedByTeacher: status.code === 'SKIPPED_TEACHER',
              lessonCanceled: status.code === 'CANCELLED'
            }"
          >
            {{ status.name }}
            <img
              v-if="
                editMode &&
                ($cookie.get('internalName') === 'manager' ||
                  ($cookie.get('internalName') === 'methodologist' &&
                    status.code === 'LESSON_PLANNED') ||
                  $cookie.get('internalName') === 'admin')
              "
              @click="setRemoveStatus"
              src="../../assets/img/ES/close_big.svg"
              alt="close"
            />
          </div>
          <div
            class="choose-status"
            v-if="removeStatus && editMode"
            v-click-outside="setDefaultStatus"
          >
            <statuses
              @chooseStatus="changeStatus"
              :currentType="lessonInfo.kls_type_code"
              :currentStatus="lessonInfo.kls_type_code"
            />
          </div>
        </div>
        <div class="card-type">
          <span>Type</span>
          <div class="teacherTesting">
            {{ lessonInfo.kls_type_name }}
          </div>
        </div>
      </div>
      <div class="student-card-info-container">
        <div class="student-lesson-info-container">
          <div class="table-cell">
            <span class="table-cell-label">School level</span>
            <span class="table-cell-name" v-if="!editMode">{{
              lessonInfo.kls_level_name || 'no'
            }}</span>
            <span class="table-cell-select" v-else>
              <vue-select
                class="select"
                v-model="schoolLevel"
                :clearable="false"
                @open="getLevels"
                @input="getCEFRByLevel"
                :options="levels"
              >
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    <span>{{ option.name }}</span>
                  </div>
                </template>
                <template slot="selected-option">
                  <div class="selected d-center">
                    {{ schoolLevel.name }}
                  </div>
                </template>
              </vue-select>
            </span>
          </div>
          <div class="table-cell">
            <span class="table-cell-label">CEFR</span>
            <span class="table-cell-name" v-if="!editMode">{{
              lessonInfo.kls_europe_level_name || 'no'
            }}</span>
            <span class="table-cell-name" v-else>
              <vue-select
                class="select"
                v-model="europeLevel"
                :clearable="false"
                :options="europeLevels"
                @input="getLevelByCEFR"
                @open="getEuropeLevels"
              >
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    <span>{{ option.name }}</span>
                  </div>
                </template>
                <template slot="selected-option">
                  <div class="selected d-center">
                    {{ europeLevel.name }}
                  </div>
                </template>
              </vue-select>
            </span>
          </div>
          <div class="table-cell">
            <span class="table-cell-label">Comment for the teacher</span>
            <span class="table-cell-name" v-if="!editMode">{{ lessonInfo.comment || 'no' }}</span>
            <span class="table-cell-name" v-else>
              <input type="text" v-model="comment" />
            </span>
          </div>
          <div class="table-cell">
            <span class="table-cell-label">Link to record</span>
            <span
              class="table-cell-name"
              v-if="
                !editMode ||
                (lessonInfo.has_filled_card && $cookie.get('internalName') === 'methodologist')
              "
            >
              <a
                v-if="lessonInfo.link_to_record"
                target="_blank"
                :href="
                  lessonInfo.link_to_record.indexOf('http') !== -1
                    ? lessonInfo.link_to_record
                    : 'http://' + lessonInfo.link_to_record
                "
              >
                {{ lessonInfo.link_to_record }}</a
              >
              <span v-else>no</span>
            </span>
            <span class="table-cell-name" v-else>
              <input type="text" v-model="link_to_record" />
            </span>
          </div>
        </div>
        <div class="edit-buttons" v-if="editMode">
          <button
            class="cancel"
            @click="
              editMode = false
              setInitialState()
            "
          >
            Отменить
          </button>
          <button class="save" @click="saveTestingCard()" :disabled="removeStatus">
            Сохранить
          </button>
        </div>
      </div>
    </div>
    <Comments v-if="$cookie.get('internalName') !== 'teacher'"></Comments>
    <lesson-cancel-modal
      v-if="openCancelModal"
      :lessonId="lessonId"
      @hideLessonCancelModalForTeacher="openCancelModal = false"
      @lessonCanceled="lessonCanceled"
    ></lesson-cancel-modal>
    <FirstTestComplete
      v-if="openModalComplete"
      @hide="openModalComplete = false"
      @rejectTeacher="allowToTeaching(false)"
      @approveTeacher="allowToTeaching(true)"
    />
    <ManagerCancelModal
      v-if="openManagerCancelModal"
      :type="'teacherTest'"
      :isFirst="lessonInfo.kls_type_code === 'TEACHER_FIRST_TEST'"
      @cancelLesson="getLessonInfo"
      :lessonId="lessonInfo.id"
    ></ManagerCancelModal>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import Comments from '@/views/LessonCard/Comments'
import Statuses from '@/views/LessonCard/components/Statuses'
import VueSelect from 'vue-select'
import axios from 'axios'
import { domain, MicroServices } from '@/globalVariables'
import LessonCancelModal from '@/views/LessonCard/LessonCancelModal'
import ManagerCancelModal from '@/views/LessonCard/ManagerCancelModal'
import FirstTestComplete from '@/views/LessonCard/components/FirstTestComplete'
export default {
  name: 'TeacherTestingCard',
  mixins: [],
  props: [],
  components: {
    FirstTestComplete,
    LessonCancelModal,
    Comments,
    Statuses,
    VueSelect,
    ManagerCancelModal
  },
  directives: {
    ClickOutside
  },
  data() {
    return {
      lessonCardSelected: true,
      glossarySelected: false,
      lessonInfo: [],
      userRole: this.$cookie.get('internalName'),
      openComplaintModal: false,
      lessonId: null,
      studentComplaint: [],
      complaintId: null,
      lessonTypeId: null,
      openCancelModal: false,
      editMode: false,
      status: null,
      linkToZoom: null,
      methodist: null,
      schoolLevel: null,
      europeLevel: null,
      comment: null,
      removeStatus: false,
      methodists: [],
      levels: [],
      europeLevels: [],
      isFilledCard: true,
      newLessonInfo: null,
      link_to_record: null,
      openManagerCancelModal: false,
      openModalComplete: false
    }
  },
  mounted() {
    this.getLessonInfo()
    this.$root.$on('hideCancelModal', () => {
      this.openCancelModal = false
    })
    this.$root.$on('hideLessonCancelModalForManager', () => {
      this.openManagerCancelModal = false
    })
  },
  methods: {
    /**
     * Установить значение что статус не удален
     */
    setDefaultStatus() {
      this.removeStatus = false
    },

    /**
     * Установить значение на удалили статус или нет (при клик аутсайд)
     */
    setRemoveStatus(value) {
      setTimeout(() => {
        this.removeStatus = value
      }, 10)
    },

    /**
     * Допустить учителя к преподаванию (если это вводное тестирование учителя)
     * @param isAllow
     */
    allowToTeaching(isAllow) {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/LessonCard/allowToTeaching/${this.lessonInfo.id}`,
        method: 'POST',
        data: [
          {
            user_uuid: this.lessonInfo.teacher_uuid,
            is_allow: isAllow
          }
        ]
      }).then(() => {
        isAllow
          ? this.successAlert('Пользователь допущен!')
          : this.successAlert('Пользователь отклонен!')
        this.openModalComplete = false
        if (
          (this.$cookie.get('internalName') === 'manager' ||
            this.$cookie.get('internalName') === 'admin') &&
          !isAllow
        ) {
          this.$router.push('/monolit/Schedule')
        } else {
          this.getLessonInfo()
        }
      })
    },

    /**
     * Последствия отмены занятия
     */
    lessonCanceled() {
      if (
        this.$cookie.get('internalName') === 'teacher' &&
        this.lessonInfo.kls_type_code === 'TEACHER_FIRST_TEST'
      ) {
        this.successAlert('Тестирование отменено!')
        this.$router.push('/monolit/Schedule')
      } else {
        this.successAlert('Заявка на отмену занятия отправлена!')
        this.getLessonInfo()
      }
    },

    /**
     * Открыть окно отмены занятия
     */
    openModal() {
      if (
        this.$cookie.get('internalName') === 'manager' ||
        this.$cookie.get('internalName') === 'admin'
      ) {
        this.openManagerCancelModal = true
      } else {
        this.openCancelModal = true
      }
    },
    formatDate(time) {
      return time.slice(0, 10)
    },
    formatTime(hour) {
      return `${Number(hour) < 10 ? '0' + Number(hour) : Number(hour)}:00 - ${
        Number(hour) < 9 ? '0' + (Number(hour) + 1) : Number(hour) + 1
      }:00`
    },

    /**
     * Получить информацию о карточке занятия
     */
    getLessonInfo() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/LessonCard/getItem/${this.$route.params.lesson_id}`,
        method: 'GET'
      })
        .then((response) => {
          this.lessonInfo = response.data.data
          this.lessonId = response.data.data.id
          this.lessonTypeId = response.data.data.kls_type_id
          this.setInitialState()
          this.editMode = this.conditionsAllowed
        })
        .catch((error) => this.errorAlert(error))
    },

    /**
     * Сохранить карточку занятия
     */
    saveTestingCard() {
      let data = [
        {
          methodologist_uuid: this.methodist.sync_uuid || this.methodist.id,
          teacher_uuid: this.lessonInfo.teacher_uuid,
          kls_status_id: this.status.id,
          kls_level_id: this.schoolLevel.id,
          kls_europe_level_id: this.europeLevel.id,
          comment: this.comment,
          link_to_record: this.link_to_record,
          link_to_zoom: this.linkToZoom
        }
      ]

      axios({
        url: `${domain}/${MicroServices[0].prefix}/LessonCard/update/${this.lessonInfo.id}`,
        method: 'PUT',
        data: data
      })
        .then(() => {
          if (
            this.status.code === 'CANCELLED' ||
            this.status.code === 'SKIPPED_TEACHER' ||
            this.status.code === 'SKIPPED_STUDENT_WITH_REASON' ||
            this.status.code === 'SKIPPED_STUDENT'
          ) {
            this.$router.push('/monolit/Schedule')
            return null
          }
          this.editMode = false
          this.getLessonInfo()
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Установить начальные значения (для редактирования)
     */
    setInitialState() {
      this.linkToZoom = this.lessonInfo.link_to_zoom
      this.status = {
        id: this.lessonInfo.kls_status_id,
        code: this.lessonInfo.kls_status_code,
        name: this.lessonInfo.kls_status_name
      }
      this.schoolLevel = {
        id: this.lessonInfo.kls_level_id,
        name: this.lessonInfo.kls_level_name
      }
      this.europeLevel = {
        kls_europe_level_id: this.lessonInfo.kls_europe_level_id,
        name: this.lessonInfo.kls_europe_level_name
      }
      this.methodist = {
        full_name: this.lessonInfo.methodologist_name,
        id: this.lessonInfo.methodologist_uuid
      }
      this.comment = this.lessonInfo.comment
      this.link_to_record = this.lessonInfo.link_to_record
      this.removeStatus = false
    },

    /**
     * Получить список доступных методистов на выбранное время
     * @param date
     */
    getMethodists(date) {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Methodologist/getSuitableUsers`,
        method: 'POST',
        data: [
          {
            date: date
          }
        ]
      })
        .then((response) => {
          this.methodists = response.data.data
        })
        .catch((error) => this.errorAlert(error))
    },

    /**
     * Получить список уровней
     */
    getLevels() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Classifier/getEducationLevels`,
        method: 'GET',
        params: {
          for_teacher: true,
          _count: 20
        }
      })
        .then((response) => {
          this.levels = response.data.data.items
        })
        .catch((error) => this.errorAlert(error))
    },

    /**
     * Получить список европейских уровней
     */
    getEuropeLevels() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Classifier/getEuropeLevels`,
        method: 'GET',
        params: {
          for_teacher: true,
          _count: 20
        }
      })
        .then((response) => {
          this.europeLevels = response.data.data.items
        })
        .catch((error) => this.errorAlert(error))
    },

    /**
     * Получить уровень при изменении CEFR
     */
    getLevelByCEFR() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Classifier/getSchoolLevelByEurope`,
        method: 'POST',
        data: [
          {
            kls_europe_level_id: this.europeLevel.kls_europe_level_id || this.europeLevel.id
          }
        ]
      })
        .then((response) => {
          this.schoolLevel = response.data.data
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Получить CEFR при изменении уровня
     */
    getCEFRByLevel() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Classifier/getEuropeLevelBySchool`,
        method: 'POST',
        data: [
          {
            kls_level_id: this.schoolLevel.kls_level_id || this.schoolLevel.id
          }
        ]
      })
        .then((response) => {
          this.europeLevel = response.data.data
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Поменять статус занятия
     * @param id
     * @param code
     * @param name
     */
    changeStatus(id, code, name) {
      this.status = {
        id: id,
        code: code,
        name: name
      }
      this.removeStatus = false
    }
  },
  computed: {
    conditionsAllowed() {
      return (
        !this.lessonInfo.has_filled_card &&
        this.userRole === 'methodologist' &&
        this.lessonInfo.can_update &&
        this.status.code !== 'CANCELLED'
      )
    }
  },
  watch: {},
  validations: {}
}
</script>

<style scoped lang="scss">
@import './src/styleVariables';
/deep/ {
  span.table-cell-name {
    background: transparent !important;
  }
}
.card-container,
.studentCard {
  width: 95%;
  min-height: 411px;
  height: auto;
  box-shadow: 1px 0px 20px -7px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  background: $main-white;
  margin: 25px auto;
  color: $main-dark;
  .card-header-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 18px 18px 0 24px;
    .lesson-details {
      display: flex;
      align-items: center;
      span {
        font-size: 18px;
      }
      div {
        margin-left: 24px;
        display: flex;
        align-items: center;
        span {
          font-family: $font-bold;
          font-size: 14px;
          line-height: 18px;
        }
        img {
          margin-right: 8px;
          width: 20px;
          height: 18px;
        }
      }
    }
    .edit-btn {
      display: flex;
      button {
        outline: none;
        display: flex;
        align-items: center;
        img {
          margin-right: 8px;
          width: 15px;
          height: 15px;
        }
      }
    }
    .student-controls-btn {
      button {
        border: 1px solid $bright-blue;
        border-radius: 4px;
        padding: 5px 21px;
        color: $bright-blue;
        outline: none;
      }
      .lesson-cancel-btn {
        margin-right: 15px;
      }
    }
  }
  .lesson-link-container {
    margin-top: 15px;
    padding: 0 24px;
    label {
      margin-bottom: 0 !important;
      margin-right: 12px;
    }
    .lesson-link {
      text-decoration: none;
      word-break: break-all;
      color: $bright-blue !important;
    }
    .lesson-link:visited {
      color: $regular-lesson;
    }
    span {
      color: $bright-blue;
    }
    input {
      border: 0.5px solid $light-gray;
      border-radius: 4px;
      background-color: transparent;
      outline: none;
      padding: 3px 9px;
      height: 25px;
      width: 287px;
      font-size: 14px;
      font-family: $font-regular;

      &:focus {
        border: 0.5px solid $bright-blue;
      }
    }
  }
  .card-members {
    display: flex;
    margin-top: 31px;
    flex-wrap: wrap;
    padding: 0 18px 0 24px;
    .card-member-name {
      display: flex;
      flex-direction: column;
      margin-right: 58px;
      font-size: 14px;
      .name {
        font-family: $font-regular;
      }
    }
  }
  .card-status-container {
    margin-top: 35px;
    padding: 0 18px 0 24px;
    .card-status {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      img {
        cursor: pointer;
      }
      span {
        margin-right: 22px;
        min-width: 70px;
      }
      .lessonFinished {
        background-color: rgba(29, 190, 64, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        white-space: nowrap;
      }
      .lessonPlanned {
        background-color: rgba(75, 97, 173, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        white-space: nowrap;
      }
      .lessonMissedByStudent {
        background-color: rgba(243, 210, 50, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        white-space: nowrap;
      }
      .lessonMissedByStudentWithReason {
        background-color: rgba(238, 0, 200, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        white-space: nowrap;
      }
      .lessonMissedByTeacher {
        background-color: rgba(28, 9, 255, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        white-space: nowrap;
      }
      .lessonCanceled {
        background-color: rgba(206, 87, 25, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        white-space: nowrap;
      }
    }
    .card-type {
      display: flex;
      align-items: center;
      span {
        margin-right: 22px;
        min-width: 70px;
      }
      .regularLesson {
        background-color: rgba(29, 93, 190, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        white-space: nowrap;
      }
      .teacherTesting {
        background-color: rgba(29, 190, 64, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        white-space: nowrap;
      }
      .placementTest {
        background-color: rgba(255, 107, 0, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        white-space: nowrap;
      }
      .checkUpLesson {
        background-color: rgba(255, 184, 0, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        white-space: nowrap;
      }
      .progressTest {
        background-color: rgba(82, 0, 255, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        white-space: nowrap;
      }
    }
  }
  .edit-buttons {
    margin-left: auto;
    width: fit-content;
    margin-right: 18px;
    margin-bottom: 16px;
    button {
      width: 137px;
      height: 33px;
      border-radius: 4px;
    }
    .save {
      background-color: $bright-blue;
      color: $main-white;
    }
    .cancel {
      background-color: $main-white;
      color: $bright-blue;
      margin-right: 15px;
      border: 1px solid $bright-blue;
    }
  }
  .card-info-container {
    display: flex;
    margin-top: 23px;
    .first-table {
      min-width: 50%;
      min-height: 300px;
      .table-cell {
        width: 100%;
        min-height: 46px;
        height: auto;
        display: flex;
        align-items: center;
        padding-left: 24px;
        .table-cell-label {
          min-width: 40%;
          width: 40%;
        }

        .select {
          width: 287px;
        }
        .table-cell-name {
          font-family: $font-regular;
          word-break: break-word;
          input {
            border: 0.5px solid $light-gray;
            border-radius: 4px;
            background-color: transparent;
            outline: none;
            padding: 3px 9px;
            height: 25px;
            width: 287px;
            font-size: 14px;
            font-family: $font-regular;

            &:focus {
              border: 0.5px solid $bright-blue;
            }
          }
        }
      }
    }
    .first-table :nth-child(even) {
      background: $light-table-blue;
    }
    .second-table {
      min-width: 50%;
      min-height: 300px;
      margin-right: 24px;
      .table-cell {
        width: 100%;
        min-height: 46px;
        height: auto;
        display: flex;
        align-items: center;
        padding-left: 24px;
        .table-cell-label {
          min-width: 40%;
          width: 40%;
        }

        .select {
          width: 287px;
          background: unset !important;
        }
        .table-cell-name {
          font-family: $font-regular;
          word-break: break-word;
          input {
            border: 0.5px solid $light-gray;
            border-radius: 4px;
            background-color: transparent;
            outline: none;
            padding: 3px 9px;
            height: 25px;
            width: 287px;
            font-size: 14px;
            font-family: $font-regular;

            &:focus {
              border: 0.5px solid $bright-blue;
            }
          }
        }
      }
    }
    .second-table :nth-child(even) {
      background: $light-table-blue;
    }
  }
  .student-card-info-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .student-lesson-info-container {
      display: flex;
      margin-top: 23px;
      flex-direction: column;

      .table-cell {
        width: 100%;
        min-height: 46px;
        height: auto;
        display: flex;
        align-items: center;
        padding-left: 24px;
        .table-cell-label {
          min-width: 30%;
          width: 30%;
        }

        .select {
          width: 287px;
          height: 32px;
          background-color: $main-white;
          /deep/ .vs__search {
            background-color: $main-white !important;
          }
          /deep/ .vs__actions {
            background-color: $main-white !important;
          }
          /deep/ .vs__open-indicator {
            background-color: $main-white !important;
          }
          /deep/ .vs__dropdown-menu {
            background-color: $main-white !important;
          }
          /deep/ .vs__dropdown-option {
            background-color: $main-white;
            color: $main-dark;
            &:hover {
              color: $bright-blue;
            }
          }
        }
        .table-cell-name {
          font-family: $font-regular;
          word-break: break-word;
          span {
            font-family: $font-regular;
          }
          input {
            border: 0.5px solid $light-gray;
            border-radius: 4px;
            background-color: transparent;
            outline: none;
            padding: 3px 9px;
            height: 25px;
            width: 287px;
            font-size: 14px;
            font-family: $font-regular;

            &:focus {
              border: 0.5px solid $bright-blue;
            }
          }
        }
      }
    }
    button {
      color: $bright-blue;
      margin-top: 50px;
      outline: none;
    }
  }
  .student-lesson-info-container :nth-child(even) {
    background: $light-table-blue;
  }
}
.studentCard {
  min-height: 437px;
}

@media screen and (max-width: 720px) {
  .card-container {
    .card-header-container {
      flex-direction: column-reverse;
      .edit-btn {
        display: flex;
        flex-direction: column;
        .edit-label {
          display: none;
        }
        button {
          align-self: flex-end;
          justify-self: flex-end;
          margin-right: 0;
          margin-bottom: 12px;
          .edit-label {
            display: none;
          }
        }

        .student-controls-btn {
          width: 100%;
          button {
            display: flex;
            justify-content: center;
            width: 100%;
          }
          .lesson-cancel-btn {
            margin-bottom: 12px;
          }
        }
      }
      .lesson-details {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 14px;
        grid-row-gap: 14px;
        width: 100%;
        margin-bottom: 14px;
        .name {
          grid-column-start: 1;
          grid-column-end: 3;
        }
        .lesson-date,
        .lesson-time {
          margin-left: 0;
        }
      }
    }

    .card-members {
      margin-top: 16px;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    .card-status-container {
      margin-top: 16px;
    }
  }
  .student-card-info-container {
    .student-lesson-info-container :nth-child(even) {
      background: $main-white;
    }
    .student-lesson-info-container {
      .table-cell {
        flex-direction: column;
        align-items: flex-start !important;
        margin-bottom: 14px;
        &-label {
          margin-right: 6px;
        }
        &-name {
          width: 100%;
          padding-right: 24px;
          input {
            width: 100% !important;
          }
        }
        &-select {
          width: 100%;
          padding-right: 24px;
        }
        .select {
          width: 100% !important;
        }
      }
    }
  }
}

@media screen and (max-width: 344px) {
  .edit-buttons {
    display: flex;
    flex-direction: column;
    width: unset !important;
    margin-right: 20px !important;
    margin-left: 20px !important;
    button {
      margin-bottom: 12px;
      width: 100% !important;
    }
    .save {
      margin-top: 0 !important;
    }
  }
}
</style>
