<template>
  <div class="modal-background" @mousedown.self="hideModal()">
    <div class="modal-container">
      <div class="message">
        <span>Вы уверены, что хотите отменить занятие?</span>
        <div class="with-recreate" v-if="type !== 'teacherTest' && !isFirst">
          <label>
            <input type="checkbox" v-model="recreate" />
            Отменить с восстановлением
          </label>
        </div>
      </div>
      <div class="controls-btns">
        <button class="hide-modal-btn" @click="hideModal">Нет, не отменять</button>
        <button class="send-complaint-btn" @click="cancelLesson">Да, отменить</button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { domain, MicroServices } from '@/globalVariables'

export default {
  name: 'ManagerCancelModal',
  mixins: [],
  props: ['lessonId', 'type', 'isFirst'],
  components: {},
  data() {
    return {
      recreate: this.type !== 'teacherTest' && !this.isFirst
    }
  },
  mounted() {},
  methods: {
    hideModal() {
      this.$root.$emit('hideLessonCancelModalForManager', false)
    },

    /**
     * Отменить урок
     */
    cancelLesson() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Lesson/cancelLesson`,
        method: 'POST',
        data: [
          {
            lesson_id: this.lessonId,
            with_recreate_lesson: this.recreate
          }
        ]
      })
        .then(() => {
          this.successAlert('Занятие отменено!')
          this.hideModal()
          if (!this.isFirst) {
            this.$emit('cancelLesson')
          } else {
            this.$router.push('/monolit/Schedule')
          }
        })
        .catch((error) => this.errorAlert(error))
    }
  },
  computed: {},
  watch: {},
  validations: {}
}
</script>

<style scoped lang="scss">
@import './src/styleVariables';
.modal-background {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.3);
  animation: fade-in 0.3s;
  .modal-container {
    background-color: $main-white;
    border-radius: 8px;
    padding: 14px 24px;
    min-width: 558px;
    min-height: 257px;
    display: flex;
    flex-direction: column;
    .with-recreate label {
      display: flex;
      align-items: center;
      input {
        margin-right: 8px;
      }
    }
    .message {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-height: 150px;
      span {
        font-size: 18px;
        margin-bottom: 12px;
      }
    }
    .controls-btns {
      display: flex;
      justify-content: center;
      margin-top: auto;
      .send-complaint-btn {
        margin-left: 15px;
        border: none;
        background-color: $bright-blue;
        border-radius: 4px;
        padding: 5px 21px;
        color: $main-white;
        outline: none;
      }
      .hide-modal-btn {
        border: 1px solid $bright-blue;
        border-radius: 4px;
        padding: 5px 21px;
        color: $bright-blue;
        outline: none;
      }
    }
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
