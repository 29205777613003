<template>
  <div class="mobile-calendar">
    <div
      class="mobile-choose-time-label"
      v-if="studentStage === 'FL_CHOICE_STUDY_TIME' || addTimeMode"
    >
      <span>Пожалуйста, выберите время для занятий</span>
    </div>
    <div class="mobile-choose-test-label" v-if="studentStage === 'FL_CHOICE_TEST_TIME'">
      <span>Пожалуйста, выберите время для вводного тестирования</span>
    </div>

    <div class="mobile-calendar-lesson-days" v-if="freeTimeData.work_times.length">
      <ChosenLessonTime :dates="freeTimeData.work_times" />
    </div>

    <div
      class="mobile-calendar-lesson-time-buttons"
      v-if="freeTimeData.work_times.length || addTimeMode"
    >
      <button
        class="cancel"
        @click="
          cancelFreeTime()
          $emit('addTimeModeOff')
        "
      >
        Отменить
      </button>
      <button
        class="save"
        @click="
          saveFreeTime($cookie.get('internalName'))
          $emit('addTimeModeOff')
        "
        v-if="freeTimeData.work_times.length"
      >
        Сохранить
      </button>
    </div>
    <div class="mobile-calendar-days">
      <MobileDaysFilter
        :chosenDay="chosenDay"
        @filter="setDay"
        :dates="dates"
        :showNumber="studentStage !== 'FL_CHOICE_STUDY_TIME' && !addTimeMode"
      />
    </div>

    <div
      class="mobile-calendar-add-free-time"
      v-if="
        ($cookie.get('internalName') === 'teacher' ||
          $cookie.get('internalName') === 'methodologist') &&
        !addTimeMode &&
        teacherStage !== 'FTL_CHOICE_TEST_TIME'
      "
    >
      <button @click="$emit('addTimeModeOn')">Добавить свободное время</button>
    </div>
    <div
      class="mobile-calendar-legend"
      v-if="studentStage !== 'FL_CHOICE_STUDY_TIME' && !addTimeMode"
    >
      <MobileLegend
        :type="$cookie.get('internalName')"
        :sortType="sortByType"
        @filter="(type) => (sortByType = type)"
      />
    </div>

    <div class="mobile-calendar-lessons" v-if="currentLessons && currentLessons.length">
      <div
        class="mobile-calendar-lessons__item"
        v-for="(card, index) in currentLessons"
        :key="index"
      >
        <div class="hour-line" v-if="currentHour(card.hour, data[chosenDay].date)" />
        <div v-if="card.cards.length">
          <div
            class="lesson-card"
            :class="{
              'chosen-time': wasAddStudyTime(card.hour, data[chosenDay].day),
              'blocked-date':
                (studentStage === 'FL_CHOICE_TEST_TIME' ||
                  teacherStage === 'FTL_CHOICE_TEST_TIME') &&
                !canSetLesson(card.hour)
            }"
            @click="
              ;(studentStage === 'FL_CHOICE_TEST_TIME' ||
                teacherStage === 'FTL_CHOICE_TEST_TIME') &&
              canSetLesson(card.hour)
                ? chooseStudentTestTime(card.hour, data[chosenDay].date)
                : studentStage === 'FL_CHOICE_STUDY_TIME'
                ? addFreeTime(card.hour, data[chosenDay].day)
                : (currentCardId = card.cards[0].lesson_id)
            "
            v-if="showCard(card.cards[0])"
          >
            <div :class="cardClass(card.cards[0])"></div>
            <div class="number" v-if="card.cards[0].type !== 'WORKS_TIME'">
              {{
                card.cards[0].kls_type_code === 'TEACHER_TEST' ||
                card.cards[0].kls_type_code === 'TEACHER_FIRST_TEST'
                  ? 'Тестирование'
                  : card.cards[0].sequence_number
                  ? `Занятие номер ${card.cards[0].sequence_number}`
                  : 'Занятие'
              }}
            </div>
            <div class="number" v-else>Свободное время</div>
            <div class="time">
              <img src="../../../../assets/img/alarm.svg" alt="time" />
              <span>{{ formatTime(card.hour) }}</span>
            </div>
          </div>

          <ModalTemplate
            @hideInfoModal="currentCardId = null"
            :chosen-card-id="card.cards[0].lesson_id"
            :modalInfo="getModalInfo(currentCardId)"
            v-if="currentCardId === card.cards[0].lesson_id && currentCardId !== null"
          />
        </div>
        <div @click="addFreeTime(card.hour, data[chosenDay].day)" v-else>
          <ChosenCard :free-time-data="freeTimeData" :hour="card.hour" :day="data[chosenDay].day" />
        </div>
      </div>
    </div>
    <div class="mobile-calendar-none" v-else-if="currentLessons && !currentLessons.length">
      Нет занятий
    </div>
  </div>
</template>

<script>
import MobileDaysFilter from '@/views/Schedule/components/MobileCalendar/components/MobileDaysFilter'
import MobileLegend from '@/views/Schedule/components/MobileCalendar/components/MobileLegend'
import ModalTemplate from '@/views/Schedule/components/InfoModal/ModalTemplate'
import scriptMixins from '@/components/scriptMixins/scriptMixins'
import ScheduleEvents from '../../ScheduleEvents'
import ChosenLessonTime from '@/views/Schedule/components/MobileCalendar/components/ChosenLessonTime'
import ChosenCard from '@/views/Schedule/components/MobileCalendar/components/ChosenCard'
export default {
  name: 'MobileCalendar',
  components: { ChosenCard, ChosenLessonTime, ModalTemplate, MobileLegend, MobileDaysFilter },
  mixins: [scriptMixins, ScheduleEvents],
  props: {
    studentStage: String,
    teacherStage: String
  },
  data() {
    return {
      chosenDay: 0,
      sortByType: null,
      currentCardId: null,
      modalCardInfo: null,
      selectedName: [],
      freeTimeData: {
        user_uuid: null,
        work_times: []
      },
      addTimeMode: false,
      data: null
    }
  },
  mounted() {
    this.$root.$on('getScheduleData', (data, timeMode) => {
      this.addTimeMode = !!timeMode
      this.data = data
      this.getDefaultDay()
    })
  },
  beforeDestroy() {
    this.$root.$off('getScheduleData')
  },
  computed: {
    /**
     * Получить все занятия без пустых ячеек, если не стадия добавления свободного времени
     * @returns {T[]}
     */
    currentLessons() {
      return (
        this.data &&
        (this.addTimeMode
          ? this.data[this.chosenDay].sections
          : this.data[this.chosenDay].sections.filter((item) => item.cards.length))
      )
    },

    /**
     * Получить все даты
     * @returns {{date: *, day: *}[]}
     */
    dates() {
      return (
        this.data &&
        this.data.map((item) => ({
          date: item.date,
          day: item.day
        }))
      )
    }
  },
  methods: {
    /**
     * Возвращает true если на это время выбрали свободное время
     * @param hour
     * @param day
     * @returns {boolean}
     */
    wasAddStudyTime(hour, day) {
      if (this.freeTimeData.work_times) {
        for (let i in this.freeTimeData.work_times) {
          if (this.freeTimeData.work_times[i].kls_day_id === day.kls_id) {
            for (let j in this.freeTimeData.work_times[i].work_hours) {
              if (hour === this.freeTimeData.work_times[i].work_hours[j]) {
                return true
              }
            }
          }
        }
      }
      return false
    },

    /**
     * Добавить свободное время для преподавателей/методистов или выбрать время занятий для студента
     * @param hour
     * @param day
     */
    addFreeTime(hour, day) {
      let uuid
      if (
        this.$cookie.get('internalName') === 'manager' ||
        this.$cookie.get('internalName') === 'admin'
      ) {
        uuid = this.userUuid
      } else {
        uuid = this.$cookie.get('uid')
      }
      this.freeTimeData.user_uuid = uuid
      if (this.freeTimeData.work_times.find((item) => item.kls_day_id === day.kls_id)) {
        this.freeTimeData.work_times.forEach((item) => {
          if (item.kls_day_id === day.kls_id) {
            if (item.work_hours.find((el) => el === hour) !== undefined) {
              item.work_hours.splice(item.work_hours.indexOf(hour), 1)
              if (!item.work_hours.length) {
                for (let i in this.freeTimeData.work_times) {
                  if (item.kls_day_id === this.freeTimeData.work_times[i].kls_day_id) {
                    this.freeTimeData.work_times.splice(i, 1)
                  }
                }
              }
              // } else if (item.work_hours.length > 1 && this.studentStage === 'FL_CHOICE_STUDY_TIME') {
              //   this.messageAnyType('Вы не можете выбрать более 2х занятий в день', 'error')
            } else {
              item.work_hours.push(hour)
            }
          }
        })
      } else {
        this.freeTimeData.work_times.push({
          kls_day_id: day.kls_id,
          work_hours: [hour]
        })
      }
    },

    /**
     * Емит на обновление расписания
     */
    getUserTimetable() {
      this.$emit('updateSchedule')
    },

    /**
     * Емит на обновление стадии препода
     */
    getCurrentTeacherStage() {
      this.$emit('updateTeacherStage')
    },

    /**
     * Емит на обновление стадии студента
     */
    getCurrentStudentStage() {
      this.$emit('updateStudentStage')
    },

    /**
     * Получить изначально выбранный день
     * @returns {null}
     */
    getDefaultDay() {
      let dayIndex = this.data.findIndex(
        (item) =>
          new Date(item.date).getDate() === new Date().getDate() &&
          new Date(item.date).getMonth() === new Date().getMonth()
      )
      if (dayIndex === -1) {
        this.chosenDay = 0
        return null
      }
      this.chosenDay = dayIndex
    },

    /**
     * Возвращает true если выбранное время больше текущего
     * @param hour
     * @returns {boolean}
     */
    canSetLesson(hour) {
      let day = this.data[this.chosenDay].date
      let today = this.countUserTimeByTimeZone(this.$cookie.get('userTimeZone'))
      let date = new Date(day)
      date = new Date(date.setHours(hour - 1))
      return new Date(today) < new Date(date)
    },

    /**
     * Выбрать день для отображения
     * @param index
     */
    setDay(index) {
      this.chosenDay = index
    },
    formatTime(hour) {
      return `${hour < 10 ? '0' + hour : hour}:00 - ${
        hour + 1 < 10 ? '0' + (hour + 1) : hour + 1
      }:00`
    },

    /**
     * Получить информацию о выбранном занятии
     * @param id
     * @returns {null}
     */
    getModalInfo(id) {
      if (id) {
        this.data.forEach((item) => {
          item.sections.forEach((section) => {
            if (section.cards[0] && section.cards[0].lesson_id === id) {
              this.modalCardInfo = section.cards[0]
              this.modalCardInfo.hour = section.hour
              this.modalCardInfo.date = item.date
            }
          })
        })
        return this.modalCardInfo
      }
    },

    /**
     * Получить класс для разного типа мини-карточек занятий
     * @param lesson
     * @returns {string}
     */
    cardClass(lesson) {
      if (lesson.type === 'WORKS_TIME') {
        return 'free'
      }
      if (
        lesson.kls_status_code === 'LESSON_ENDED' &&
        (lesson.has_filled_card || this.$cookie.get('internalName') === 'student')
      ) {
        return 'done'
      }
      if (
        (lesson.kls_type_code === 'PLACEMENT_TEST' ||
          lesson.kls_type_code === 'CHECK_UP' ||
          lesson.kls_type_code === 'CHECK_PROGRESS' ||
          lesson.kls_type_code === 'TEACHER_TEST_PROGRESS_TEST_LESSON' ||
          lesson.kls_type_code === 'TEACHER_TEST' ||
          lesson.kls_type_code === 'TEACHER_FIRST_TEST') &&
        (lesson.has_filled_card || lesson.kls_status_code === 'LESSON_PLANNED') &&
        lesson.kls_status_code !== 'LESSON_ENDED' &&
        this.$cookie.get('internalName') !== 'student'
      ) {
        return 'test'
      }
      if (
        (lesson.kls_type_code === 'REGULAR' ||
          lesson.kls_type_code === 'TEACHER_TEST_REGULAR_LESSON') &&
        lesson.kls_status_code !== 'LESSON_ENDED'
      ) {
        return 'lesson'
      }
      if (
        lesson.has_filled_card === false &&
        lesson.type !== 'WORKS_TIME' &&
        lesson.kls_status_code !== 'LESSON_PLANNED' &&
        this.$cookie.get('internalName') !== 'student'
      ) {
        return 'empty'
      }
      if (
        lesson.kls_status_code !== 'LESSON_ENDED' &&
        this.$cookie.get('internalName') === 'student'
      ) {
        return 'studentCard'
      }
    },

    /**
     * Фильтр по типам занятий
     * @param card
     * @returns {boolean}
     */
    showCard(card) {
      if (!this.sortByType) {
        return true
      }
      if (
        this.sortByType === 'empty' &&
        !card.has_filled_card &&
        card.type !== 'WORKS_TIME' &&
        card.kls_status_code === 'LESSON_ENDED'
      ) {
        return true
      }
      if (
        this.sortByType === 'lesson' &&
        (((card.kls_type_code === 'REGULAR' ||
          card.kls_type_code === 'TEACHER_TEST_REGULAR_LESSON') &&
          card.kls_status_code !== 'LESSON_ENDED') ||
          (this.$cookie.get('internalName') === 'student' &&
            card.kls_status_code === 'LESSON_PLANNED'))
      ) {
        return true
      }
      if (
        this.sortByType === 'test' &&
        (card.kls_type_code === 'PLACEMENT_TEST' ||
          card.kls_type_code === 'CHECK_UP' ||
          card.kls_type_code === 'CHECK_PROGRESS' ||
          card.kls_type_code === 'TEACHER_TEST_PROGRESS_TEST_LESSON' ||
          card.kls_type_code === 'TEACHER_TEST' ||
          card.kls_type_code === 'TEACHER_FIRST_TEST')
      ) {
        return true
      }
      if (
        this.sortByType === 'ended' &&
        card.kls_status_code === 'LESSON_ENDED' &&
        (card.has_filled_card || this.$cookie.get('internalName') === 'student')
      ) {
        return true
      }
      return false
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../../styleVariables';

.mobile-calendar {
  padding: 0 16px;
  &-lesson-time-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 12px;
    margin-bottom: 12px;
    button {
      font-size: 14px;
      color: #ffffff;
      background-color: $bright-blue;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
      border-radius: 4px;
      margin-right: 15px;
      outline: none;
      padding: 6px;
      width: 100%;
      border: 1px solid transparent;
    }
    .cancel {
      background-color: $main-white;
      border: 1px solid $red;
      color: $red;
      margin-right: 12px;
    }
  }
  &-add-free-time {
    width: 100%;
    margin-bottom: 18px;
    button {
      font-size: 14px;
      color: #ffffff;
      background-color: $bright-blue;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
      border-radius: 4px;
      margin-right: 15px;
      outline: none;
      padding: 6px;
      width: 100%;
      border: 1px solid transparent;
    }
  }
  .mobile-choose-time-label,
  .mobile-choose-test-label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 32px;
    span {
      text-align: center;
      color: $main-gray;
      font-size: 16px;
    }
  }
  &-days {
    margin-bottom: 20px;
  }
  &-legend {
    padding-bottom: 12px;
    border-bottom: 1px solid $light-gray;
  }
  &-lessons {
    padding: 12px 0;
    &__item {
      .hour-line {
        margin-top: -1px;
        //margin-bottom: 6px;
        position: relative;
        height: 2px;
        width: 100%;
        background-color: $red;
        margin-bottom: 4px;
        &::before {
          content: '';
          background-color: $red;
          display: block;
          position: relative;
          top: -2px;
          border-radius: 50px;
          width: 6px;
          height: 6px;
        }
      }
      .blocked-date {
        background-color: $regular-lesson-tag !important;
      }
      .chosen-time {
        border: 1px solid $bright-blue;
      }
      .lesson-card {
        background-color: $main-white;
        height: 70px;
        margin-bottom: 8px;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
        border-radius: 4px;

        .number {
          margin: 8px 20px 12px;
          font-size: 14px;
          line-height: 18px;
          color: $main-dark;
        }

        .time {
          display: flex;
          align-items: center;
          margin: 0 20px;
          font-size: 14px;
          color: $main-dark;
          line-height: 18px;

          img {
            margin-right: 6px;
          }
        }

        .empty,
        .done,
        .test,
        .lesson,
        .studentCard,
        .free {
          width: 100%;
          height: 4px;
          border-radius: 4px 4px 0px 0px;
        }

        .done {
          background-color: $lesson-ended;
        }

        .test {
          background-color: $test;
        }

        .lesson {
          background-color: $regular-lesson;
        }

        .empty {
          background-color: $empty-card;
        }

        .studentCard {
          background: $regular-lesson;
        }

        .empty-time {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          .time {
            color: $main-gray;
            svg {
              margin-right: 6px;
              margin-bottom: 2px;
            }
          }
        }
      }
    }
  }
  &-none {
    color: $light-gray;
    font-size: 14px;
    width: fit-content;
    margin: 80px auto;
  }
  /deep/ .modal-background {
    width: 100%;
    position: relative;
    left: 0;
    margin-bottom: 16px;
    .info-modal-container {
      width: 100%;
    }
  }
}
</style>
