<template>
  <div class="achievements">
    <div class="achievements-header">Личные достижения</div>
    <div class="achievements-content">
      <div class="files" v-for="(file, index) in files" :key="index">
        <div class="files-content" @click="downloadAchievements(file.file.path, file.file.name)">
          <img src="../../../assets/img/ES/file.svg" alt="file" />
          <span>{{ file.file.name }}</span>
        </div>
        <img
          @click="deleteFile(file.id)"
          class="file-delete"
          src="../../../assets/img/ES/delete.svg"
          alt="delete"
        />
      </div>
      <div class="upload" v-if="count < 20">
        <div
          v-show="$refs.uploadConstructor && $refs.uploadConstructor.dropActive"
          class="drop-active"
        >
          Drop file here
        </div>
        <img class="loader" v-show="loader" src="../../../assets/img/ES/loader.svg" alt="loader" />
        <file-upload
          v-model="file"
          input-id="uploadConstructor"
          class="file-uploader"
          post-action="/upload/post"
          :drop="true"
          :dropDirectory="true"
          :fileDrop="true"
          :multiple="false"
          accept=".pdf"
          ref="uploadConstructor"
        >
          <img
            v-show="!($refs.uploadConstructor && $refs.uploadConstructor.dropActive) && !loader"
            src="../../../assets/img/ES/download.svg"
            alt="upload"
          />
        </file-upload>
      </div>
    </div>
  </div>
</template>

<script>
import FileUpload from 'vue-upload-component'
import { domain, fileDomain } from '@/globalVariables'
import axios from 'axios'
export default {
  name: 'Achievements',
  props: {
    files: Array,
    count: Number,
    uuid: String
  },
  components: { FileUpload },
  data() {
    return {
      file: [],
      loader: false
    }
  },
  methods: {
    /**
     * Удалить файл
     */
    deleteFile(id) {
      axios({
        url: `${domain}/monolit/AchievementFile/delete/${id}`,
        method: 'POST'
      })
        .then(() => {
          this.successAlert('Файл успешно удален!')
          this.$emit('update')
        })
        .catch((error) => this.errorAlert(error))
    },
    /**
     * Функция запускает процесс загрузки файла по чанкам, создает пул и высылает его название
     * оно нужно для последующей отправки чанков на сервер и для получения url автара
     */

    uploadFile() {
      if (this.file.length) {
        this.loader = true
        axios({
          url: `${domain}/monolit/File/createChunksPool`,
          method: 'POST',
          data: [this.file[0].name]
        })
          .then((response) => {
            let pullBasename = response.data.data.pull_basename
            this.createChunks(this.file[0], pullBasename)
          })
          .catch((error) => {
            this.loader = false
            this.errorAlert(error)
          })
      }
    },
    /**
     * функция переводит файл в base64, разбивает на чанки и отправляет на сервер последовательно, чанк за чанком
     * когда последний чанк успешно отправлен, запускается заключительная функция генерации url аватара
     * @param file
     * @param pullBasename
     * @returns {Promise<void>}
     */
    async createChunks(file, pullBasename) {
      await this.getBase64(file.file).then((resp) => {
        this.base64 = resp.replace(resp.substring(0, resp.search(',') + 1), '')
        this.chunksArr = this.base64.match(/.{1,500000}/g)
      })
      for (const [i, item] of this.chunksArr.entries()) {
        try {
          await axios({
            url: `${domain}/monolit/File/uploadPoolChunk`,
            method: 'POST',
            data: [pullBasename, item]
          })
        } catch (error) {
          this.loader = false
          this.errorAlert(error)
        } finally {
          if (parseInt(i) === this.chunksArr.length - 1) {
            this.getFileFromPool(pullBasename)
          }
        }
      }
    },
    /**
     * Функция получает url аватара и запускает функцию обновления аватара пользователя
     */
    getFileFromPool(pullBasename) {
      axios({
        url: `${domain}/monolit/File/collectFileFromPool`,
        method: 'POST',
        data: [pullBasename]
      })
        .then((response) => {
          let fileId = response.data.data.file.id
          this.uploadAchievements(fileId)
        })
        .catch((error) => {
          this.loader = false
          this.errorAlert(error)
        })
    },
    /**
     * функция обновления аватара, при успешном обновлении вызавает функцию запроса данных пользователя
     * @param file
     */
    uploadAchievements(file) {
      axios({
        url: `${domain}/monolit/AchievementFile/create`,
        method: 'POST',
        data: [
          {
            user_uuid: this.uuid,
            file_id: file
          }
        ]
      })
        .then(() => {
          this.loader = false
          this.successAlert('Файл успешно загружен!')
          this.$emit('update')
        })
        .catch((error) => {
          this.loader = false
          this.errorAlert(error)
        })
    },

    /**
     * Открыть файл
     * @param path
     */
    downloadAchievements(path) {
      window.open(`${fileDomain}${path}`, '_blank')
    }
  },
  watch: {
    file() {
      this.uploadFile()
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../styleVariables';
.loader {
  position: absolute;
  animation-name: rotate;
  animation-duration: 0.7s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.achievements {
  background-color: $main-white;
  box-shadow: 1px 0px 20px -7px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  min-height: 288px;
  padding: 14px 24px;
  &-header {
    font-size: 18px;
    color: $main-dark;
    margin-bottom: 14px;
  }

  &-content {
    display: flex;
    flex-wrap: wrap;
    .files {
      background: rgba(197, 206, 224, 0.12);
      border: 1px solid #c5cee0;
      border-radius: 5px;
      width: 84px;
      height: 106px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-right: 14px;
      margin-bottom: 14px;

      &-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 90px;
        padding: 0 6px;
        cursor: pointer;

        span {
          font-size: 10px;
          font-family: $font-regular;
          color: $bright-blue;
          text-align: center;
          line-height: 12px;
          word-break: break-all;
        }
        img {
          width: 24px;
          height: 24px;
          margin-right: 0;
          margin-bottom: 4px;
        }
      }
      .file-delete {
        position: relative;
        align-self: flex-end;
        margin-right: 3px;
        margin-bottom: 2px;
        cursor: pointer;
      }
    }

    .upload {
      width: 84px;
      height: 106px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px dashed #c5cee0;
      border-radius: 5px;
      transition: 0.2s;
      .file-uploads {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .file-uploader {
        /deep/ label {
          cursor: pointer;
        }
      }
      &:hover {
        border-color: $regular-lesson;
      }
    }
    .drop-active {
      width: 84px;
      height: 106px;
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px dashed $regular-lesson;
      border-radius: 5px;
      font-family: $font-regular;
      color: $regular-lesson;
      position: absolute;
    }
  }
}
</style>
