export default {
  methods: {
    Checkbox__changeDate(data) {
      data.value = data.value.replace(/(\d*)-(\d*)-(\d*)/, '$1-$2-$3')
      this.$store.commit('newRec', data)
    }
  },
  data() {
    return {
      inputDate: {
        value: this.value,
        keyAdd: this.keyAdd
      }
    }
  }
}
