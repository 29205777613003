<template>
  <div class="day-schedule">
    <div class="date-nav-panel">
      <div class="choose-week">
        <button class="prev-week arrow-btn" type="Button" @click="$emit('prevDay')">
          <img src="@/assets/img/icons/arrow-left.svg" />
        </button>
        <div class="current-week">
          {{ currentDay }}
        </div>
        <button class="next-week arrow-btn" type="Button" @click="$emit('nextDay')">
          <img src="@/assets/img/icons/arrow-right.svg" />
        </button>
        <div class="button-today">
          <button @click="$emit('today')">Сегодня</button>
        </div>
      </div>
      <div class="lesson-legend">
        <button
          :class="{ active: sortByType === 'free' }"
          class="free-time"
          @click="sortByType === 'free' ? (sortByType = null) : (sortByType = 'free')"
        >
          <img :src="require(`../../assets/img/ES/free_time.svg`)" />Свободное время
        </button>
        <button
          :class="{ active: sortByType === 'empty' }"
          class="empty-card"
          @click="sortByType === 'empty' ? (sortByType = null) : (sortByType = 'empty')"
        >
          <img :src="require(`../../assets/img/empty-card.svg`)" />Незаполененная карточка
        </button>
        <button
          :class="{ active: sortByType === 'complete' }"
          class="lesson-complete"
          @click="sortByType === 'complete' ? (sortByType = null) : (sortByType = 'complete')"
        >
          <img :src="require(`../../assets/img/lesson-complete.svg`)" />Занятие состоялось
        </button>
        <button
          :class="{ active: sortByType === 'test' }"
          class="test"
          @click="sortByType === 'test' ? (sortByType = null) : (sortByType = 'test')"
        >
          <img :src="require(`../../assets/img/test.svg`)" />Тест
        </button>
        <button
          :class="{ active: sortByType === 'lesson' }"
          class="lesson"
          @click="sortByType === 'lesson' ? (sortByType = null) : (sortByType = 'lesson')"
        >
          <img :src="require(`../../assets/img/lesson.svg`)" />Занятие
        </button>
      </div>
    </div>
    <div class="calendar" v-if="min !== undefined">
      <div class="header">
        <div class="header-users">ФИО</div>
        <div
          class="header-hours"
          :class="{ 'current-hour': currentHour(hour, data[0].date) }"
          v-for="(hour, index) in hours"
          :key="index"
          :style="!(index >= min && index < max) ? 'display: none' : ''"
        >
          {{ hour }}
        </div>
      </div>
      <div class="content">
        <div class="content-users">
          <div class="user" v-for="user in data[0].users" :key="user.user_uuid">
            {{ user.full_name }}
          </div>
        </div>
        <div class="content-lessons">
          <div class="lesson-row" v-for="user in data[0].users" :key="user.user_uuid">
            <div
              class="lesson"
              v-for="(lesson, indexLesson) in user.sections"
              :key="indexLesson"
              :style="!(indexLesson >= min && indexLesson <= max) ? 'display: none' : ''"
            >
              <div
                class="card"
                :class="{ 'rests-time': card.type === 'RESTS_TIME' }"
                v-for="(card, index) in lesson.card"
                :key="index"
                @click="openInfoModalFunc(card.lesson_id, user.user_uuid)"
                :style="
                  sortByType && sortByType !== 'free'
                    ? (sortByType === 'empty' &&
                        !card.has_filled_card &&
                        card.type !== 'WORKS_TIME' &&
                        card.kls_status_code === 'LESSON_ENDED') ||
                      (sortByType === 'lesson' &&
                        (card.kls_type_code === 'REGULAR' || 'TEACHER_TEST_REGULAR_LESSON') &&
                        card.kls_status_code !== 'LESSON_ENDED') ||
                      (sortByType === 'test' &&
                        (card.kls_type_code === 'PLACEMENT_TEST' ||
                          card.kls_type_code === 'CHECK_UP' ||
                          card.kls_type_code === 'CHECK_PROGRESS' ||
                          card.kls_type_code === 'TEACHER_TEST_PROGRESS_TEST_LESSON' ||
                          card.kls_type_code === 'TEACHER_TEST' ||
                          card.kls_type_code === 'TEACHER_FIRST_TEST')) ||
                      (sortByType === 'complete' &&
                        card.kls_status_code === 'LESSON_ENDED' &&
                        card.has_filled_card)
                      ? ''
                      : 'display: none'
                    : sortByType === 'free' && card.type !== 'WORKS_TIME'
                    ? 'display: none'
                    : ''
                "
              >
                <div
                  :class="{
                    done: card.kls_status_code === 'LESSON_ENDED',
                    test:
                      (card.kls_type_code === 'PLACEMENT_TEST' ||
                        card.kls_type_code === 'CHECK_UP' ||
                        card.kls_type_code === 'CHECK_PROGRESS' ||
                        card.kls_type_code === 'TEACHER_TEST_PROGRESS_TEST_LESSON' ||
                        card.kls_type_code === 'TEACHER_TEST' ||
                        card.kls_type_code === 'TEACHER_FIRST_TEST') &&
                      (card.has_filled_card || card.kls_status_code === 'LESSON_PLANNED') &&
                      card.kls_status_code !== 'LESSON_ENDED',
                    lesson:
                      (card.kls_type_code === 'REGULAR' ||
                        card.kls_type_code === 'TEACHER_TEST_REGULAR_LESSON') &&
                      card.kls_status_code !== 'LESSON_ENDED',
                    empty:
                      !card.has_filled_card &&
                      card.type !== 'WORKS_TIME' &&
                      card.type !== 'RESTS_TIME' &&
                      card.kls_status_code !== 'LESSON_PLANNED',
                    free: card.type === 'WORKS_TIME' || card.type === 'RESTS_TIME'
                  }"
                ></div>
                <div class="card-content">
                  <div class="card-content__name" v-if="card.lesson_id">
                    {{
                      card.kls_type_code === 'TEACHER_TEST' ||
                      card.kls_type_code === 'TEACHER_FIRST_TEST'
                        ? 'Тестирование'
                        : `Занятие номер ${card.sequence_number || 1}`
                    }}
                  </div>
                  <div class="card-content__name" v-else-if="card.type === 'WORKS_TIME'">
                    Свободное время
                  </div>
                  <div class="card-content__name" v-else>Нерабочее время время</div>
                  <div class="placement-test" v-if="card.kls_type_code === 'PLACEMENT_TEST'">
                    Placement tests
                  </div>
                  <div class="card-content__time">
                    <img src="../../assets/img/alarm.svg" alt="time" />
                    <span>{{ `${lesson.hour}:00-${lesson.hour + 1}:00` }}</span>
                  </div>
                </div>
                <ModalTemplate
                  @hideInfoModal="cardLessonId = null"
                  :chosenCardId="card.lesson_id"
                  :openModalFlag="openModalFlag"
                  :modalInfo="modalCardInfo"
                  v-if="
                    cardLessonId === card.lesson_id &&
                    userUuid === user.user_uuid &&
                    cardLessonId !== null
                  "
                ></ModalTemplate>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="no-lesson" v-else>Нет занятий</div>
  </div>
</template>

<script>
import ModalTemplate from '@/views/Schedule/components/InfoModal/ModalTemplate'
import scriptMixins from '@/components/scriptMixins/scriptMixins'
export default {
  name: 'ScheduleByDay',
  components: { ModalTemplate },
  mixins: [scriptMixins],
  props: {
    data: Array,
    currentDay: String,
    hours: Array,
    max: Number,
    min: Number
  },
  data() {
    return {
      sortByType: null,
      cardLessonId: null,
      userUuid: null,
      openModalFlag: false,
      modalCardInfo: {}
    }
  },
  methods: {
    /**
     * Открыть превью занятия
     */
    openInfoModalFunc(id, userUuid) {
      this.userUuid = userUuid
      this.cardLessonId = id
      this.openModalFlag = true
      this.getModalInfo(id)
    },

    /**
     * Получить информация о выбранном занятии
     * @param id
     * @return null
     */
    getModalInfo(id) {
      if (id) {
        for (let user of this.data[0].users) {
          for (let section of user.sections) {
            if (section.card[0] && section.card[0].lesson_id === id) {
              this.modalCardInfo = section.card[0]
              this.modalCardInfo.hour = section.hour
              this.modalCardInfo.date = this.data[0].date
              return null
            }
          }
        }
      }
    },

    currentHour(hour, date) {
      hour = hour.slice(0, 2)
      date = new Date(date).setHours(hour)
      return (
        new Date(date).getDate() ===
          this.countUserTimeByTimeZone(this.$cookie.get('userTimeZone')).getDate() &&
        new Date(date).getHours() ===
          this.countUserTimeByTimeZone(this.$cookie.get('userTimeZone')).getHours() &&
        new Date(date).getMonth() ===
          this.countUserTimeByTimeZone(this.$cookie.get('userTimeZone')).getMonth() &&
        new Date(date).getFullYear() ===
          this.countUserTimeByTimeZone(this.$cookie.get('userTimeZone')).getFullYear()
      )
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../styleVariables';

::-webkit-scrollbar-thumb {
  background: $main-gray;
  border-radius: 4px;
}

.active {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
}
.calendar {
  overflow: auto;
  min-height: 70vh;
  .header {
    width: fit-content;
    display: flex;
    font-size: 24px;
    color: $main-gray;
    border-bottom: 1px solid #c5cee0;
    border-top: 1px solid #c5cee0;
    border-collapse: separate;
    padding-right: 197px;
    position: sticky;
    top: 0;
    z-index: 20;
    background-color: $light-table-blue;
    &-users {
      padding: 15px 120px;
      width: 293px;
      margin-right: 32px;
      border-right: 1px solid #c5cee0;
      position: sticky;
      background-color: $light-table-blue;
      left: 0;
    }
    &-hours {
      margin-left: 169px;
      align-self: center;
    }
    .current-hour {
      background-color: $main-white;
      padding: 0 8px;
      margin-left: 153px;
      border-radius: 4px;
      border: 1px solid $bright-blue;
      color: $bright-blue;
      font-family: $font-regular;
    }
  }
  .content {
    display: flex;
    width: fit-content;
    max-height: 55vh;
    &-users {
      position: sticky;
      background-color: $light-table-blue;
      left: 0;
      z-index: 16;
      .user {
        font-family: $font-regular;
        color: $main-dark;
        font-size: 18px;
        line-height: 23px;
        height: 108px;
        width: 293px;
        display: flex;
        align-items: center;
        padding-left: 24px;
        border-bottom: 1px solid $light-gray;
        border-right: 1px solid $light-gray;
        background-color: $light-table-blue;
      }
    }
    &-lessons {
      display: flex;
      flex-direction: column;
      .lesson-row {
        display: flex;
        .lesson {
          width: 225px;
          height: 108px;
          border-bottom: 1px solid $light-gray;
          border-right: 1px solid $light-gray;

          display: flex;
          align-items: center;
          justify-content: center;

          .placement-test {
            border-radius: 5px;
            background-color: rgba(255, 107, 0, 0.13);
            padding: 1px 12px;
            font-size: 14px;
            line-height: 24px;
            color: $main-dark;
            width: fit-content;
            margin: 0 auto;
          }

          .card,
          .rests-time {
            width: 215px;
            height: 100px;
            background-color: $main-white;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16) !important;
            border-radius: 4px;
            .empty,
            .done,
            .free,
            .test,
            .lesson {
              width: 100%;
              height: 4px;
              border-radius: 4px 4px 0px 0px;
            }
            .done {
              background-color: $lesson-ended;
            }
            .test {
              background-color: $test;
            }
            .lesson {
              background-color: $regular-lesson;
            }
            .empty {
              background-color: $empty-card;
            }

            &-content {
              padding: 5px 7px;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              font-size: 14px;
              &__time {
                display: flex;
                align-items: center;
                img {
                  margin-right: 5px;
                }
                span {
                  margin-top: 2px;
                }
              }
            }
          }
          .rests-time {
            background-color: $regular-lesson-tag;
          }
        }
      }
    }
  }
}

.no-lesson {
  width: 100%;
  height: 72vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-size: 26px;
  text-align: center;
  padding-top: 50px;
  color: $bright-blue;
}

.date-nav-panel {
  display: flex;
  justify-content: space-between;
  margin: 25px 28px 25px 28px;
  align-items: center;
  .lesson-legend {
    flex-wrap: wrap;
    justify-content: flex-end;
    button {
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      border-radius: 5px;
      padding: 5px 8px;
      outline: none;
      img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
    }
    .empty-card {
      background-color: rgba(250, 75, 0, 0.13);
      margin-right: 14px;
    }
    .lesson-complete {
      background-color: rgba(29, 190, 64, 0.13);
      margin-right: 14px;
    }
    .test {
      background-color: rgba(255, 213, 0, 0.13);
      margin-right: 14px;
    }
    .lesson {
      background-color: rgba(50, 100, 195, 0.13);
    }
    .free-time {
      background-color: $main-white;
      margin-right: 14px;
    }
  }
  .choose-week {
    //max-width: 303px;
    display: flex;
    .arrow-btn {
      background: #ffffff;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
      border-radius: 4px;
      width: 42px;
      min-width: 42px;
      height: 42px;
      outline: none;
      img {
        width: 10px;
        height: 15px;
        margin: 0;
      }
    }
    .prev-week {
      margin-right: 8px;
    }
    .next-week {
      margin-left: 8px;
    }
    .current-week {
      background: #ffffff;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
      border-radius: 4px;
      min-width: 203px;
      width: auto;
      height: 42px;
      margin: 0 auto;
      font-size: 14px;
      text-align: center;
      padding: 0 8px;
      line-height: 42px;
      vertical-align: middle;
    }

    .button-today {
      margin-left: 16px;

      button {
        border-radius: 4px;
        border: 1px solid $bright-blue;
        color: $bright-blue;
        font-size: 14px;
        background-color: $main-white;
        height: 42px;
        width: 120px;
      }
    }
  }
}
</style>
