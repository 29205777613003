import axios from 'axios'
import { domain, MicroServices } from '@/globalVariables'
import Swal from 'sweetalert2'
import scriptMixins from '@/components/scriptMixins/scriptMixins'

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'confirm-button',
    cancelButton: 'delete-button',
    title: 'title-class',
    input: 'input-class'
  },
  buttonsStyling: false
})
export default {
  mixins: [scriptMixins],
  methods: {
    /**
     * Возвращает true если на это время выбрали свободное время
     * @param hour
     * @param day
     * @returns {boolean}
     */
    wasAddStudyTime(hour, day) {
      if (this.freeTimeData.work_times) {
        for (let i in this.freeTimeData.work_times) {
          if (this.freeTimeData.work_times[i].kls_day_id === day.kls_id) {
            for (let j in this.freeTimeData.work_times[i].work_hours) {
              if (hour === this.freeTimeData.work_times[i].work_hours[j]) {
                return true
              }
            }
          }
        }
      }
      return false
    },

    /**
     * Получение стадии студента
     * @param uid
     */
    getCurrentStudentStage(uid) {
      let axiosPrams
      if (uid) {
        axiosPrams = {
          url: `${domain}/${MicroServices[0].prefix}/Student/getCurrentStage`,
          method: 'POST',
          data: [
            {
              user_uuid: uid
            }
          ]
        }
      } else {
        axiosPrams = {
          url: `${domain}/${MicroServices[0].prefix}/Student/getCurrentStage`,
          method: 'GET'
        }
      }
      axios(axiosPrams)
        .then((response) => {
          this.studentStage = response.data.data.code
          this.teacherStage = null
          if (!this.addTestMode) {
            this.addTestMode = this.studentStage === 'FL_CHOICE_TEST_TIME'
          }
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Получение стадии преподавателя
     * @param uid
     */
    getCurrentTeacherStage(uid) {
      let axiosParams
      if (uid) {
        axiosParams = {
          url: `${domain}/${MicroServices[0].prefix}/Teacher/getCurrentStage`,
          method: 'POST',
          data: [
            {
              user_uuid: uid
            }
          ]
        }
      } else {
        axiosParams = {
          url: `${domain}/${MicroServices[0].prefix}/Teacher/getCurrentStage`,
          method: 'GET'
        }
      }
      axios(axiosParams)
        .then((response) => {
          this.teacherStage = response.data.data.code
          this.studentStage = null
          if (!this.addTestMode) {
            this.addTestMode = this.teacherStage === 'FTL_CHOICE_TEST_TIME'
          }
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Получение свободного времени методистов
     */
    getMethodistWorkTime() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/WorkTime/getWorkTimeSchedule`,
        method: 'GET',
        params: {
          begin_date: this.weekStart,
          end_date: this.weekEnd,
          roles: '[methodologist]'
        }
      })
        .then((response) => {
          this.groupTeacherAndMethodistWorkTime(this.data, response.data.data)
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Пересечение свободного времени методистов и преподавателя
     * @param teacherTime
     * @param methodistTime
     */
    groupTeacherAndMethodistWorkTime(teacherTime, methodistTime) {
      let groupedData = []
      teacherTime.forEach((day, dayIndex) => {
        groupedData.push({
          date: day.date,
          day: day.day,
          sections: []
        })
        day.sections.forEach((section, sectionIndex) => {
          if (section.cards.length && methodistTime[dayIndex].sections[sectionIndex].cards.length) {
            groupedData[dayIndex].sections.push(section)
          } else {
            groupedData[dayIndex].sections.push({
              cards: [],
              hour: section.hour
            })
          }
        })
      })
      this.data = groupedData
      this.loading = false
    },

    /**
     * Получение расписания на день
     * @param day
     * @param uuid
     * @param role
     * @param level
     */
    getScheduleByDay(day, uuid, role, level) {
      this.loading = true
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Schedule/getDailyItems`,
        method: 'GET',
        params: {
          kls_level_ids: JSON.stringify(level) || [],
          user_uuids: JSON.stringify(uuid) || [],
          user_roles: JSON.stringify(role) || [],
          date: day
        }
      })
        .then((response) => {
          this.data = response.data.data
          this.cutDailyData(response.data.data[0])
          this.loading = false
        })
        .catch((error) => {
          this.errorAlert(error)
          this.loading = false
        })
    },

    /**
     * Возвращает true если выбранное время больше текущего
     * @param day
     * @param hour
     * @returns {boolean}
     */
    canSetLesson(day, hour) {
      let today = this.countUserTimeByTimeZone(this.$cookie.get('userTimeZone'))
      let date = new Date(day)
      date = new Date(date.setHours(hour - 1))
      return new Date(today) < new Date(date)
    },

    /**
     * Получение расписания на месяц
     */
    getScheduleByMonth() {
      this.loading = true
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Schedule/getMonthlyItems`,
        method: 'GET',
        params: {
          user_uuid: this.userUuid,
          date: this.weekStart
        }
      })
        .then((response) => {
          this.data = response.data.data
          let arr = []
          for (let i = 1; i < this.data[0].day.kls_id; i++) {
            arr.push({})
          }
          if (arr.length) {
            this.data = arr.concat(this.data)
          }
          this.loading = false
        })
        .catch((error) => {
          this.errorAlert(error)
          this.loading = false
        })
    },

    /**
     * Получение свободного времени пользователя
     */
    getUserWorkTime() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/WorkTime/getUserWorkTimeSchedule`,
        method: 'GET',
        params: {
          begin_date: this.weekStart,
          end_date: this.weekEnd,
          user_uuid: this.selectedName.sync_uuid || this.$cookie.get('uid')
        }
      })
        .then((response) => {
          this.data = response.data.data
          this.$root.$emit('getScheduleData', this.data, true)
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Отправка запроса на сброс расписания
     */
    requestToUnsetSchedule() {
      swalWithBootstrapButtons
        .fire({
          title: `Пожалуйста, укажите причину сброса`,
          showCancelButton: true,
          confirmButtonText: 'Сохранить',
          cancelButtonText: 'Отмена',
          reverseButtons: true,
          input: 'textarea',
          showClass: {
            popup: 'swal2-noanimation',
            backdrop: 'swal2-noanimation'
          },
          inputValidator: (value) => {
            if (!value) {
              return 'Пожалуйста, укажите причину'
            }
          }
        })
        .then((r) => {
          if (r.isConfirmed && r.value) {
            axios({
              url: `${domain}/${MicroServices[0].prefix}/UserStage/sendChangeScheduleRequest`,
              method: 'POST',
              data: [
                {
                  canceling_reason: r.value
                }
              ]
            })
              .then(() => {
                this.successAlert('Заявка отправлена!')
              })
              .catch((error) => {
                this.errorAlert(error)
              })
          }
        })
    },

    /**
     * Получение информации о выбранном занятии
     * @param id
     */
    getModalInfo(id) {
      if (id) {
        this.data.forEach((item) => {
          item.sections.forEach((section) => {
            if (section.cards[0] && section.cards[0].lesson_id === id) {
              this.modalCardInfo = section.cards[0]
              this.modalCardInfo.hour = section.hour
              this.modalCardInfo.date = item.date
            }
          })
        })
      }
    },

    /**
     * Обрезать время для расписания по дням
     * @param data
     */
    cutDailyData(data) {
      this.maxDaily = 0
      this.minDaily = undefined
      for (let k = 0; k < data.users.length; k++) {
        if (data.users[k].sections[0].card.length) {
          this.minDaily = 0
        }
        if (data.users[k].sections[data.users[k].sections.length - 1].card.length) {
          this.maxDaily = data.users[k].sections.length - 1
        }
        for (let i = 1; i < data.users[k].sections.length; i++) {
          if (
            data.users[k].sections[i].card.length &&
            !data.users[k].sections[i - 1].card.length &&
            (this.minDaily === undefined || this.minDaily > i)
          ) {
            this.minDaily = i
          } else if (
            !data.users[k].sections[i].card.length &&
            data.users[k].sections[i - 1].card.length &&
            this.maxDaily < i
          ) {
            this.maxDaily = i - 1
          }
        }
      }
      this.loading = false
    },

    /**
     * Получение пользователей для фильтра расписания по дням
     */
    getUsersForFilter() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/User/getUsersWithSchedule`,
        method: 'GET',
        params: {
          _count: 1000
        }
      })
        .then((response) => {
          this.userList = response.data.data.items
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Добавить свободное время для преподавателей/методистов или выбрать время занятий для студента
     * @param hour
     * @param day
     */
    addFreeTime(hour, day) {
      let uuid
      if (
        this.$cookie.get('internalName') === 'manager' ||
        this.$cookie.get('internalName') === 'admin'
      ) {
        uuid = this.userUuid
      } else {
        uuid = this.$cookie.get('uid')
      }
      this.freeTimeData.user_uuid = uuid
      if (this.freeTimeData.work_times.find((item) => item.kls_day_id === day.kls_id)) {
        this.freeTimeData.work_times.forEach((item) => {
          if (item.kls_day_id === day.kls_id) {
            if (item.work_hours.find((el) => el === hour) !== undefined) {
              item.work_hours.splice(item.work_hours.indexOf(hour), 1)
              if (!item.work_hours.length) {
                for (let i in this.freeTimeData.work_times) {
                  if (item.kls_day_id === this.freeTimeData.work_times[i].kls_day_id) {
                    this.freeTimeData.work_times.splice(i, 1)
                  }
                }
              }
            } else if (item.work_hours.length > 1 && this.studentStage === 'FL_CHOICE_STUDY_TIME') {
              this.messageAnyType('Вы не можете выбрать более 2х занятий в день', 'error')
            } else {
              item.work_hours.push(hour)
            }
          }
        })
      } else {
        this.freeTimeData.work_times.push({
          kls_day_id: day.kls_id,
          work_hours: [hour]
        })
      }
    },

    /**
     * Сохранить свободное время для преподавателя/методиста или занятия для студента
     * @param type
     */
    saveFreeTime(type) {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/WorkTime/create`,
        method: 'POST',
        data: JSON.stringify([this.freeTimeData])
      })
        .then(() => {
          if (type !== 'student') {
            this.addTimeMode = false
            this.freeTimeData = {
              user_uuid: null,
              work_times: []
            }
            this.getUserTimetable()
          } else {
            this.freeTimeData = {
              user_uuid: null,
              work_times: []
            }
            if (this.$cookie.get('internalName') === 'student') {
              this.getCurrentStudentStage()
            } else {
              this.getCurrentStudentStage(this.selectedName.sync_uuid)
            }
            this.getUserTimetable()
            this.successAlert('Занятия добавлены!')
          }
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Отмена выбора свободного времени или занятий для студента
     */
    cancelFreeTime() {
      this.freeTimeData = {
        user_uuid: null,
        work_times: []
      }
      this.addTimeMode = false
      this.getUserTimetable()
    },

    /**
     * Выбор времени для вводного тестирования
     * @param hour
     * @param day
     */
    chooseStudentTestTime(hour, day) {
      swalWithBootstrapButtons
        .fire({
          text: `Вы уверены, что хотите выбрать это время?`,
          showCancelButton: true,
          confirmButtonText: 'Да',
          cancelButtonText: 'Отмена',
          reverseButtons: true
        })
        .then((r) => {
          if (r.value) {
            axios({
              url: `${domain}/${MicroServices[0].prefix}/Lesson/createFirstLesson`,
              method: 'POST',
              data: JSON.stringify([
                {
                  date: `${day} ${hour}`,
                  user_uuid: this.selectedName.sync_uuid
                }
              ])
            })
              .then(() => {
                this.successAlert('Тестирование назначено!')
                this.getUserTimetable()
                if (this.teacherStage && this.$cookie.get('internalName') === 'teacher') {
                  this.getCurrentTeacherStage()
                } else if (this.$cookie.get('internalName') === 'student') {
                  this.getCurrentStudentStage()
                }
                this.addTestMode = false
              })
              .catch((error) => {
                this.errorAlert(error)
              })
          }
        })
    },

    /**
     * Открыть модлку для добавления тестирования
     * @param hour
     * @param day
     */
    openModalAddTest(hour, day) {
      this.hourForTest = hour
      this.dayForTest = day
      if (this.teacherStage !== 'FTL_CHOICE_TEST_TIME') {
        this.isOpenModalAddTest = true
      } else {
        this.chooseStudentTestTime(hour, day)
      }
    },

    /**
     * Закрыть модалку для добавления тестрования
     * @param update
     */
    hideAddTestModal(update) {
      this.hourForTest = null
      this.dayForTest = null
      this.isOpenModalAddTest = false
      this.addTestMode = !update
      this.getUserTimetable()
    },

    /**
     * Сбросить расписание пользователя
     */
    unsetUserStage() {
      swalWithBootstrapButtons
        .fire({
          text: `Вы уверены, что хотите сбросить расписание?`,
          showCancelButton: true,
          confirmButtonText: 'Да',
          cancelButtonText: 'Отмена',
          reverseButtons: true
        })
        .then((r) => {
          if (r.value) {
            axios({
              url: `${domain}/${MicroServices[0].prefix}/UserStage/changeStageOnStudyTime`,
              method: 'POST',
              data: [
                {
                  user_uuid: this.userUuid
                }
              ]
            })
              .then(() => {
                this.successAlert('Расписание сброшено!')
                this.getUserTimetable()
              })
              .catch((error) => {
                this.errorAlert(error)
              })
          }
        })
    },
    formatHours(hour) {
      return `${Number(hour) < 10 ? '0' + Number(hour) : Number(hour)}:00 - ${
        Number(hour) < 9 ? '0' + (Number(hour) + 1) : Number(hour) + 1
      }:00`
    },

    /**
     * Перелистнуть расписание на сегодня
     */
    showTodaySchedule() {
      this.$cookie.delete('weekStart')
      this.$cookie.delete('weekEnd')
      this.$cookie.delete('currentYear')
      this.$cookie.delete('currentDay')
      this.$cookie.delete('monday')
      this.$cookie.delete('sunday')

      this.weekStart = null
      this.weekEnd = null
      this.monday = this.countUserTimeByTimeZone(this.$cookie.get('userTimeZone'), new Date())
      this.sunday = this.countUserTimeByTimeZone(this.$cookie.get('userTimeZone'), new Date())
      this.currentDay = this.countUserTimeByTimeZone(this.$cookie.get('userTimeZone'), new Date())
      this.getCurrentWeek(
        this.countUserTimeByTimeZone(this.$cookie.get('userTimeZone'), new Date())
      )
    },

    /**
     * Возвращает true, если текущий час
     * @param hour
     * @param date
     * @returns {boolean}
     */
    currentHour(hour, date) {
      date = new Date(date)
      return (
        this.countUserTimeByTimeZone(this.$cookie.get('userTimeZone')).getHours() === hour &&
        this.isCurrentDay(date)
      )
    },

    /**
     * Возвращает true, если текущий день
     * @param date
     * @returns {boolean}
     */
    isCurrentDay(date) {
      date = new Date(date)
      return (
        this.countUserTimeByTimeZone(this.$cookie.get('userTimeZone')).getDate() ===
          date.getDate() &&
        this.countUserTimeByTimeZone(this.$cookie.get('userTimeZone')).getMonth() ===
          date.getMonth() &&
        this.countUserTimeByTimeZone(this.$cookie.get('userTimeZone')).getFullYear() ===
          date.getFullYear()
      )
    }
  }
}
