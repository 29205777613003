import Widget from '../../Core/Widget'
import eventDelegate from '../../Core/KernelProject/eventDelegate'
// eslint-disable-next-line no-unused-vars
import allEvents from '../../Widgets/allEvents'
import renderWidget from '../../Core/KernelProject/renderWidget'
import tableEvents from '../../Core/KernelProject/tableEvents'
import { isEmpty, isUndefined, valueEmpty } from '@/components/Core/KernelProject/helperFunction'
import { DefaultValueSettings } from '@/globalVariables'

export default {
  props: ['data', 'fields', 'type', 'selectedItems', 'items', 'route', 'id'],
  mixins: [eventDelegate, renderWidget, tableEvents],
  components: {
    Widget
  },
  methods: {
    /**
     * Обработка просмотра внутри модального окна, вынесена отдельно, так как отличия от того, что используется в
     * миксине renderWidget
     * @param label
     * @param item
     * @param rec
     * @return {string}
     */
    renderValue(label, item, rec) {
      if (this.type.value === 'view') {
        if (typeof item === 'string' && (item.match('image') || item.match('files'))) {
          return this.renderValueImageAndFiles(label, item)
        }
        let route =
          this.$route.params.model ||
          this.$route.path
            .slice(1)
            .split(/\s+/)
            .map((word) => word[0].toUpperCase() + word.substring(1))
            .join(' ')
            .split('/')[1]
        let store = this.$store.state
        let currentPath = store['metaProjectData'][this.$route.params.prefix][route].fields
        let key
        for (let _key in currentPath) {
          if (currentPath.hasOwnProperty(_key)) {
            if (currentPath[_key]['label'] === label) {
              key = _key
            }
          }
        }
        if (isEmpty(item) && currentPath[key] && currentPath[key].type !== 'bool') {
          return `<span>${label}:</span> <span class="item">${DefaultValueSettings.emptyValue}</span>`
        }
        if (!currentPath[key]) {
          return `<span>${label}</span> <span>${item}</span>`
        }
        if (currentPath[key].type === 'relation') {
          return this.renderValueRelation(currentPath, key, route, label, store, item)
        } else if (currentPath[key].type === 'bool') {
          return `<span>${label}:</span> <span class="item">${item ? 'Да' : 'Нет'}</span>`
        } else {
          return `<span>${label}:</span> <span class="item">${
            item || valueEmpty(item) ? item : DefaultValueSettings.emptyValue
          }</span>`
        }
      }
    },
    renderValueImageAndFiles(label, item) {
      if (item.match('image')) {
        return `<span style="float: left">${label}:</span><div class="image__View"><img class="image_table"
                    src="${this.renderUrl(item)}" width='100px' height='100px' alt="image"></div>`
      } else if (item.match('files')) {
        return `<a href="${this.renderUrl(
          item
        )}"><button type="Button" class="btn btn-outline-primary btn_view"
                    value="edit" variant="outline-primary">Скачать</button></a>`
      }
    },
    renderValueRelation(currentPath, key, route, label, store, item) {
      if (typeof item === 'object') {
        return this.renderValueRelationM2M(currentPath, key, route, label, store, item)
      }
      let currentRelation =
        store.metaProjectData[this.$route.params.prefix][this.$route.params.model || route][
          'relations'
        ][currentPath[key]['relation']]['relationModel']
      if (
        isUndefined(
          store['relationsData'][this.$route.params.prefix][this.$route.params.model || route]
        )
      ) {
        return undefined
      }
      let storeRelation =
        store['relationsData'][this.$route.params.prefix][this.$route.params.model || route][
          currentRelation
        ]
      for (let i in storeRelation) {
        if (!isNaN(parseInt(item))) {
          if (parseInt(item) === storeRelation[i].id) {
            let name = this.$store.state.metaProjectData[this.$route.params.prefix][
              this.$store.state.metaProjectData[this.$route.params.prefix][
                this.$route.params.model
              ]['relations'][currentPath[key]['relation']]['relationModel']
            ].viewName
            return `<span>${label}:</span> <span class="item">${storeRelation[i][name]}</span>`
          }
        }
      }
      return `<span>${label}:</span> <span class="item">${DefaultValueSettings.emptyValue}</span>`
    },
    renderValueRelationM2M(currentPath, key, route, label, store, item) {
      let currentRelation =
        store.metaProjectData[this.$route.params.prefix][route]['relations'][
          currentPath[key]['relation']
        ]['relationModel']
      let storeRelation = store['relationsData'][this.$route.params.prefix][route][currentRelation]
      let name = this.$store.state.metaProjectData[this.$route.params.prefix][currentRelation]
        .viewName
      let m2mString = ''
      for (let i in item) {
        if (storeRelation[item[i]]) {
          let _item = storeRelation[item[i]]
          m2mString += `${_item[name]}, `
        } else {
          m2mString += `NotFound, `
        }
      }
      return `<span>${label}:</span> <span class="item">${m2mString.slice(0, -2)}</span>`
    },
    clearStore() {
      this.$store.state.newRecord = {} // clear store with new record
      this.$store.state.multiEditIds = null
      this.$root.$emit('switchFilter')
    }
  },
  name: 'ModalWindow'
}
