<template>
  <div class="packs-container">
    <div class="packs-header" v-if="$cookie.get('internalName') === 'student'">
      Выберите свой пакет
    </div>
    <div class="packs-count" v-if="$cookie.get('internalName') === 'student'">
      <img src="../../assets/img/ES/packs_count.svg" alt="packs" />
      <span>На вашем счету</span>
      <span class="count">{{ `${lessonCount} ${endingOfWord(lessonCount)}` }}</span>
    </div>
    <div class="packs-create" v-if="$cookie.get('internalName') === 'admin'">
      <div class="one-lesson-price">
        <div class="one-lesson-price__content">
          <div class="label">Стоимость одного занятия</div>
          <span v-if="!isEditBasicPrice">{{ basicPrice }}</span>
          <input v-else type="text" v-model="editedBasicPrice" />
          <img src="../../assets/img/ES/rub.svg" alt="rub" />
        </div>
        <img
          class="controls"
          v-if="!isEditBasicPrice"
          src="../../assets/img/ES/edit_requisites.svg"
          alt="edit"
          @click="setIsEditBasicPrice(true)"
        />
        <img
          class="controls"
          v-if="isEditBasicPrice"
          @click="updateBasicLessonPrice()"
          src="../../assets/img/ES/black_check.svg"
          alt="save"
        />
        <img
          class="controls"
          @click="setIsEditBasicPrice(false)"
          v-if="isEditBasicPrice"
          src="../../assets/img/ES/close_requisites.svg"
          alt="close"
        />
      </div>
      <button @click="openModal(null)">Создать пакет</button>
    </div>
    <div class="packs">
      <button class="left" :class="{ disabled: from <= 1 }" @click="decrementPagination">
        <img src="../../assets/img/ES/chevron_left.svg" alt="left" />
      </button>
      <div class="packs-items">
        <div class="packs-item" v-for="pack in packs" :key="pack.id">
          <Pack
            :pack="pack"
            @buyPack="buyPack"
            :role="$cookie.get('internalName')"
            @edit="openModal(pack)"
            @delete="deletePack"
          />
        </div>
      </div>
      <button
        class="right"
        :class="{ disabled: from + count > totalCount }"
        @click="incrementPagination"
      >
        <img src="../../assets/img/ES/chevron_right.svg" alt="right" />
      </button>
    </div>
    <div class="file-link" @click="openFile('Условия_оплаты')">Условия оплаты</div>
    <div class="file-link file-link--below" @click="openFile('Контакты')">Контакты</div>
    <ModalWindow
      v-if="isModal"
      @hide="closeModal()"
      :packInfo="currentPack"
      :basicPrice="basicPrice"
      @save="savePack"
    />
  </div>
</template>

<script>
import axios from 'axios'
import { domain, MicroServices } from '@/globalVariables'
import Pack from '@/views/Packs/components/Pack'
import ModalWindow from './components/ModalWindow'
import Swal from 'sweetalert2'
import scriptMixins from '@/components/scriptMixins/scriptMixins'
import { fileDomain } from '../../globalVariables'

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'confirm-button',
    cancelButton: 'delete-button',
    title: 'title-class',
    input: 'input-class'
  },
  buttonsStyling: false
})
export default {
  name: 'PacksContainer',
  components: { ModalWindow, Pack },
  mixins: [scriptMixins],
  data() {
    return {
      packs: [],
      from: 1,
      count: 6,
      totalCount: null,
      lessonCount: null,
      isModal: false,
      currentPack: null,
      basicPrice: null,
      editedBasicPrice: null,
      isEditBasicPrice: false,
      width: window.innerWidth
    }
  },
  mounted() {
    this.setCount()
    this.getPacks()
    if (this.$cookie.get('internalName') === 'student') {
      this.getLessonsCount()
    }
    if (this.$cookie.get('internalName') === 'admin') {
      this.getBasicLessonPrice()
    }
    window.addEventListener('resize', this.getWidth)
  },
  methods: {
    openFile(name) {
      let url = `${fileDomain}/public/${name}.pdf`
      window.open(`${url}`, '_blank')
    },

    /**
     * Получение ширины экрана
     */
    getWidth() {
      this.width = document.documentElement.clientWidth
    },

    /**
     * Установить _count для пагинации
     */
    setCount() {
      if (this.width <= 1800 && this.width > 1620) {
        this.count = 5
      } else if (this.width <= 1620 && this.width > 1360) {
        this.count = 4
      } else if (this.width <= 1360 && this.width > 1090) {
        this.count = 3
      } else if (this.width <= 1090 && this.width > 580) {
        this.count = 2
      } else if (this.width <= 580) {
        this.count = 1
      }
    },

    /**
     * Получить пакеты занятий
     */
    getPacks() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/LessonPack`,
        method: 'GET',
        params: {
          _from: this.from,
          _count: this.count
        }
      })
        .then((response) => {
          this.packs = response.data.data.items
          this.totalCount = response.data.data.total_count
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Сохранить пакет занятий
     * @param amount
     * @param price
     * @param id
     */
    savePack(amount, price, id) {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/LessonPack/${id ? 'update/' + id : 'create'}`,
        method: `${id ? 'PUT' : 'POST'}`,
        data: [
          {
            lessons_amount: amount,
            price: price
          }
        ]
      })
        .then(() => {
          this.closeModal()
          this.successAlert('Успешно обновлено!')
          this.getPacks()
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Удалить пакет занятий
     * @param id
     */
    deletePack(id) {
      swalWithBootstrapButtons
        .fire({
          title: `Вы действительно хотите удалить данный пакет?`,
          showCancelButton: true,
          confirmButtonText: 'Удалить',
          cancelButtonText: 'Отмена',
          reverseButtons: true
        })
        .then((r) => {
          if (r.value) {
            axios({
              url: `${domain}/${MicroServices[0].prefix}/LessonPack/delete/${id}`,
              method: 'DELETE'
            })
              .then(() => {
                this.successAlert('Пакет удален!')
                this.getPacks()
              })
              .catch((error) => {
                this.errorAlert(error)
              })
          }
        })
    },

    /**
     * Открыть модалку для создания/редактирвания пакета
     * @param data
     */
    openModal(data) {
      this.isModal = true
      this.currentPack = data
    },

    /**
     * Закрыть модалку
     */
    closeModal() {
      this.isModal = false
      this.currentPack = null
    },

    /**
     * Установить редактирование базовой цены за пакет
     * @param flag
     */
    setIsEditBasicPrice(flag) {
      this.editedBasicPrice = this.basicPrice
      this.isEditBasicPrice = flag
    },

    /**
     * Получить предыдущую страницу с пакетами
     * @returns {null}
     */
    decrementPagination() {
      if (this.from <= 1) {
        return null
      }
      this.from -= this.count
      this.getPacks()
    },

    /**
     * Получить следующую страницу с пакетами
     * @returns {null}
     */
    incrementPagination() {
      if (this.from + this.count > this.totalCount) {
        return null
      }
      this.from += this.count
      this.getPacks()
    },

    /**
     * Получить базовую цену за пакет
     */
    getBasicLessonPrice() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/LessonPack/getBasicLessonPrice`,
        method: 'GET'
      }).then((response) => {
        this.basicPrice = response.data.data.price
      })
    },

    /**
     * Редактировать базовую цену за пакет
     */
    updateBasicLessonPrice() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/LessonPack/changeBasicLessonPrice`,
        method: 'PUT',
        data: [
          {
            price: this.editedBasicPrice
          }
        ]
      })
        .then(() => {
          this.isEditBasicPrice = false
          this.successAlert('Успешно обновлено!')
          this.getBasicLessonPrice()
          this.getPacks()
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Получить кол-во оставшихся занятий у студента (текущего пользователя)
     */
    getLessonsCount() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Student/getAvailableLessonsAmount`,
        method: 'GET'
      })
        .then((response) => {
          this.lessonCount = response.data.data.lessons_amount
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Купить пакет
     * @param id
     */
    buyPack(id) {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/PaymentSystem/register`,
        method: 'POST',
        data: [
          {
            lesson_pack_id: id
          }
        ]
      })
        .then((response) => {
          this.openLink(response.data.data.formUrl)
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Окрыть ссылку на оплату пакета
     * @param url
     */
    openLink(url) {
      window.open(url, '_blank')
    }
  },
  watch: {
    /**
     * Следить за шириной экрана, от которой зависит _count, и посылать запрос на получение пакетов, если _count меняется
     */
    width() {
      this.setCount()
    },
    count() {
      this.getPacks()
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../styleVariables';

.packs-container {
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .packs-header {
    font-size: 24px;
    line-height: 33px;
    color: $main-dark;
    margin-bottom: 13px;
  }
  .packs-count {
    background-color: rgba(0, 87, 255, 0.13);
    border-radius: 5px;
    padding: 5px 16px;
    margin-bottom: 40px;
    img {
      margin-right: 8px;
    }
    span {
      font-size: 14px;
      line-height: 24px;
      color: $main-dark;
    }
    .count {
      color: $bright-blue;
      margin-left: 5px;
    }
  }
  .packs-create {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 40px;
    width: 100%;
    padding: 0 24px;
    button {
      justify-self: center;
      border: 1px solid $bright-blue;
      border-radius: 4px;
      background-color: transparent;
      color: $bright-blue;
      font-size: 14px;
      line-height: 24px;
      width: 184px;
      height: 32px;
    }
    .one-lesson-price {
      display: flex;
      &__content {
        padding: 5px 18px;
        background-color: rgba(0, 87, 255, 0.13);
        border-radius: 5px;
        display: flex;
        align-items: center;
        margin-right: 8px;
        .label {
          font-size: 14px;
          line-height: 24px;
          color: $main-dark;
          margin-right: 9px;
        }
        span {
          font-size: 14px;
          line-height: 24px;
          color: $bright-blue;
        }
        input {
          width: 35px;
          height: 17px;
          color: $bright-blue;
          outline: none;
          border: 1px solid $bright-blue;
          border-radius: 2px;
          text-align: center;
          background: rgba(0, 87, 255, 0.13);
        }
      }

      .controls {
        cursor: pointer;
        margin-right: 11px;
      }
    }
  }
  .packs {
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;

    .packs-items {
      width: fit-content;
      display: flex;
      justify-content: center;
      .packs-item {
        margin-right: 30px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .left,
    .right {
      padding: 10px;
      height: fit-content;
      border-radius: 50px;
      background-color: rgba(0, 87, 255, 0.13);
    }

    .left {
      margin-right: -10px;
      z-index: 2;
    }

    .right {
      margin-left: -10px;
      z-index: 2;
    }
    .disabled {
      background-color: rgba(0, 0, 0, 0.1) !important;
      cursor: unset !important;
    }
  }

  .file-link {
    color: $main-gray;
    cursor: pointer;
    text-decoration: underline;

    &--below {
      margin-top: 6px;
    }
  }
}

@media screen and (max-width: 600px) {
  .packs-container {
    .packs-header {
      font-size: 18px;
    }
  }
}
</style>
