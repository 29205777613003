<template>
  <div class="modal-background" @mousedown.self="hide">
    <div class="modal-container">
      <div class="header">
        <div class="header-name">Принять жалобу</div>
        <div class="header-required">*Обязательное поле</div>
      </div>
      <div class="content">
        <div class="content-select">
          <div class="content-select__item">
            <div class="label">Восстановление занятий*</div>
            <div class="select">
              <vue-select
                v-model="selectedRecreate"
                :options="recreateLesson"
                :clearable="false"
                :searchable="false"
                :filterable="false"
              >
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    <span>{{ option.name }}</span>
                  </div>
                </template>
                <template slot="selected-option">
                  <div class="selected d-center">
                    {{ selectedRecreate.name }}
                  </div>
                </template>
              </vue-select>
            </div>
          </div>
          <div class="content-select__item">
            <div class="label">Оплата преподавателю*</div>
            <div class="select">
              <vue-select
                v-model="selectedPay"
                :options="payToTeacher"
                :clearable="false"
                :searchable="false"
                :filterable="false"
              >
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    <span>{{ option.name }}</span>
                  </div>
                </template>
                <template slot="selected-option">
                  <div class="selected d-center">
                    {{ selectedPay.name }}
                  </div>
                </template>
              </vue-select>
            </div>
          </div>
        </div>
        <div class="content-textarea">
          <div class="label">Комментарий*</div>
          <textarea v-model="comment" maxlength="255"></textarea>
        </div>
      </div>
      <div class="modal-controls">
        <button class="cancel" @click="$emit('cancel')">Отменить</button>
        <button class="save" @click="save()" :disabled="!comment">Сохранить</button>
      </div>
    </div>
  </div>
</template>

<script>
import VueSelect from 'vue-select'
export default {
  name: 'ApproveModal',
  data() {
    return {
      payToTeacher: [
        {
          name: 'Оплатить 30%',
          value: true
        },
        {
          name: 'Не оплачивать',
          value: false
        }
      ],
      recreateLesson: [
        {
          name: 'Восстановить',
          value: true
        },
        {
          name: 'Без восстановления',
          value: false
        }
      ],
      comment: null,
      selectedPay: {
        name: 'Оплатить 30%',
        value: true
      },
      selectedRecreate: {
        name: 'Восстановить',
        value: true
      }
    }
  },
  components: { VueSelect },
  methods: {
    hide() {
      this.$emit('hide')
    },
    /**
     * Принять жалобу с формированием необходимых для запроса параметров
     */
    save() {
      let data = {
        is_accepted: true,
        with_recovery: this.selectedRecreate.value,
        with_payment: this.selectedPay.value,
        comment: this.comment
      }
      this.$emit('save', data)
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../styleVariables.scss';

.modal-background {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.3);
  animation: fade-in 0.3s;
  color: $main-dark;

  .modal-container {
    background-color: $main-white;
    border-radius: 8px;
    padding: 14px 24px;
    width: 550px;
    //height: 313px;
    display: flex;
    flex-direction: column;

    .header {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 24px;
      &-name {
        font-size: 18px;
        line-height: 33px;
      }
      &-required {
        font-size: 16px;
        line-height: 20px;
        font-family: $font-regular;
      }
    }

    .content {
      margin-bottom: 17px;
      .label {
        font-family: $font-regular;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 7px;
      }
      &-select {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 36px;
        margin-bottom: 14px;
        .select {
          width: 210px;
          background-color: $main-white;
          margin-right: 15px;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16) !important;
          border-radius: 4px !important;
          min-height: 42px !important;
          height: fit-content;
          border: 0.5px solid #e8edf3 !important;

          /deep/.vs__dropdown-toggle {
            min-height: 42px;
            border: none;
            padding: 0 8px !important;
            .vs__selected {
              margin: 0 !important;
            }
            .vs__selected-options input::placeholder {
              color: #c5cee0;
              margin-top: -4px;
            }
            .vs__search,
            .vs__search:focus {
              margin: 0;
            }
            .vs--disabled .vs__search {
              background-color: #f8f8f8 !important;
            }
            .vs--disabled .vs__dropdown-toggle {
              background-color: #c5cee0;
            }
            .vs__selected {
              background-color: $main-white;
            }
          }
          /deep/ {
            .v-select.select-list.vs--single.vs--searchable {
              max-width: 230px;
            }
            .v-input__slot {
              box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16) !important;
              border-radius: 4px !important;
              height: 42px !important;
            }
            .v-text-field.v-text-field--solo .v-input__control input {
              font-family: $font-bold !important;
              font-size: 18px !important;
              color: $main-gray;
            }
            .v-list .v-select-list .v-sheet .theme--light .v-list--dense .theme--light {
              margin-top: 46px !important;
            }
            .v-menu__content.theme--light {
              top: 120px !important;
            }
          }
        }
      }
      &-textarea {
        textarea {
          border: 0.5px solid #e8edf3;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
          border-radius: 4px;
          box-sizing: border-box;
          height: 128px;
          width: 100%;
          margin: 0 auto;
          padding: 6px 11px;
          outline: none;
          font-family: $font-regular;
          resize: none;
        }
      }
    }
    .modal-controls {
      display: flex;
      justify-content: flex-end;
      button {
        width: 137px;
        height: 33px;
        border-radius: 4px;
        font-size: 14px;
        line-height: 24px;
        outline: none;
      }
      .save {
        background-color: $bright-blue;
        color: $main-white;
        &:disabled {
          background-color: $light-gray;
        }
      }
      .cancel {
        border: 1px solid $bright-blue;
        color: $bright-blue;
        margin-right: 15px;
      }
    }
  }
}
</style>
