<template>
  <div class="authentication-wrapper authentication-1 px-4">
    <div class="authentication-inner">
      <!-- Logo -->
      <div class="d-flex justify-content-center align-items-center">
        <img :src="require(`../assets/img/logo-es.jpg`)" alt="icon" class="auth-logo" />
        <img src="../assets/img/logo-es.jpg" alt="logo" class="mobile-auth-logo" />
      </div>
      <!-- / Logo -->
      <div
        class="auth"
        v-if="!isReg && !resetPassword && !token"
        :style="role.indexOf('pending') === -1 ? '' : 'min-height: 340px'"
      >
        <div>
          <div class="header">Вход</div>
          <div class="form" v-if="role.indexOf('pending') === -1">
            <div class="form-item">
              <label for="emailAuth">Email</label>
              <input
                id="emailAuth"
                maxlength="50"
                type="email"
                v-model.trim="email"
                @keyup.enter="auth"
              />
            </div>
            <div class="form-item">
              <label for="passwordAuth">Пароль</label>
              <input
                id="passwordAuth"
                maxlength="16"
                :type="hideAuthPassword ? 'password' : 'text'"
                v-model.trim="password"
                @keyup.enter="auth"
              />
              <img
                v-if="hideAuthPassword"
                src="../assets/img/hide.svg"
                alt="show"
                @click="hideAuthPassword = false"
              />
              <img
                v-else-if="!hideAuthPassword"
                src="../assets/img/show.svg"
                alt="hide"
                @click="hideAuthPassword = true"
              />
            </div>
            <div class="reset-password" @click="resetPassword = true">
              <span>Забыли пароль?</span>
            </div>
          </div>
          <div class="form" v-else>
            <div class="form-item policy-label">
              Ознакомьтесь с <span @click="getPrivacyPolicy()">политикой конфиденциальности</span>
            </div>
          </div>
        </div>
        <div class="login">
          <button
            @click="role.indexOf('pending') === -1 ? auth() : agreePolicy()"
            :disabled="authValidation"
          >
            {{ role.indexOf('pending') === -1 ? 'Войти' : 'Согласен' }}
          </button>
        </div>
      </div>

      <!-- / Form LOGIN-->

      <!-- Form REGISTER -->
      <div class="register" v-if="isReg && !resetPassword && !token">
        <div class="register-step-1" v-if="role === ''">
          <div class="student" @click="role = 'student'">
            <div class="student-icon">
              <img src="../assets/img/ES/student.svg" alt="student" />
            </div>
            <div class="student-info">
              <div class="student-info__name">Ученик</div>
              <div class="student-info__description">
                Изучение языка по уникальной методике. Постоянная разговорная практика.
              </div>
            </div>
            <div class="student-arrow">
              <img src="../assets/img/reg_arrow.svg" alt="arrow" />
            </div>
          </div>
          <div class="teacher" @click="role = 'almost_teacher'">
            <div class="teacher-icon">
              <img src="../assets/img/ES/teacher.svg" alt="teacher" />
            </div>
            <div class="teacher-info">
              <div class="teacher-info__name">Преподаватель</div>
              <div class="teacher-info__description">
                Поможем вам найти учеников для проведения занятий.
              </div>
            </div>
            <div class="teacher-arrow">
              <img src="../assets/img/reg_arrow.svg" alt="arrow" />
            </div>
          </div>
          <div class="methodist" @click="role = 'almost_methodologist'">
            <div class="methodist-icon">
              <img src="../assets/img/ES/methodologist.svg" alt="meth" />
            </div>
            <div class="methodist-info">
              <div class="methodist-info__name">Методист</div>
              <div class="methodist-info__description">
                Проведение тестирования студентов и преподавателей для определения их уровня и
                прогресса.
              </div>
            </div>
            <div class="methodist-arrow">
              <img src="../assets/img/reg_arrow.svg" alt="arrow" />
            </div>
          </div>
        </div>
        <div class="register-step-2" v-if="role !== '' && step === 2">
          <div class="navigate">
            <div class="navigate-prev" @click="back">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style="transform: rotate(180deg)"
              >
                <path
                  d="M18.17 11L15.59 8.41L17 7L22 12L17 17L15.59 15.59L18.17 13H2V11H18.17Z"
                  :fill="'#0057FF'"
                />
              </svg>
              <span>Назад</span>
            </div>
            <div class="navigate-next" @click="!stepOneValidation ? (step = 3) : ''">
              <span :style="stepOneValidation ? 'color: #C5CEE0' : ''">Вперед</span>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.17 11L15.59 8.41L17 7L22 12L17 17L15.59 15.59L18.17 13H2V11H18.17Z"
                  :fill="stepOneValidation ? '#C5CEE0' : '#0057FF'"
                />
              </svg>
            </div>
          </div>
          <div class="header">
            <div class="header-info">
              <div class="header-info__name">Регистрация</div>
              <div class="header-info__description">*Обязательное поле</div>
            </div>
            <div class="header-steps">Шаг 2 из 3</div>
          </div>
          <div class="form">
            <div class="form-item">
              <label for="last-name">Фамилия*</label>
              <input id="last-name" maxlength="25" type="text" v-model.trim="lastName" />
            </div>
            <div class="form-item">
              <label for="first-name">Имя*</label>
              <input id="first-name" type="text" maxlength="25" v-model.trim="firstName" />
            </div>
            <div class="form-item">
              <label for="middle-name">Отчество</label>
              <input id="middle-name" maxlength="25" type="text" v-model.trim="middleName" />
            </div>
            <div class="form-item">
              <label for="phone">Номер телефона*</label>
              <input id="phone" type="text" v-model.trim="phone" v-mask="phoneMask" />
            </div>
            <div
              class="form-item"
              v-if="role === 'almost_teacher' || role === 'almost_methodologist'"
            >
              <label for="link">Ссылка на Zoom*</label>
              <input id="link" type="text" v-model.trim="link" />
            </div>
          </div>
        </div>
        <div class="register-step-2" v-if="role !== '' && step === 3">
          <div class="navigate">
            <div class="navigate-prev" @click="step = 2">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style="transform: rotate(180deg)"
              >
                <path
                  d="M18.17 11L15.59 8.41L17 7L22 12L17 17L15.59 15.59L18.17 13H2V11H18.17Z"
                  :fill="'#0057FF'"
                />
              </svg>
              <span>Назад</span>
            </div>
          </div>
          <div class="header">
            <div class="header-info">
              <div class="header-info__name">Регистрация</div>
              <div class="header-info__description">*Обязательное поле</div>
            </div>
            <div class="header-steps">Шаг 3 из 3</div>
          </div>
          <div class="form">
            <div class="form-item">
              <label for="email">Email*</label>
              <input
                :style="!this.isEmail && this.email ? 'border-color: #CE5719' : ''"
                id="email"
                maxlength="50"
                type="text"
                v-model.trim="email"
              />
            </div>
            <div class="form-item">
              <label for="password">Пароль*</label>
              <input
                id="password"
                maxlength="16"
                :type="hidePassword ? 'password' : 'text'"
                v-model.trim="password"
              />
              <img
                v-if="hidePassword"
                src="../assets/img/hide.svg"
                alt="show"
                @click="hidePassword = false"
              />
              <img
                v-else-if="!hidePassword"
                src="../assets/img/show.svg"
                alt="hide"
                @click="hidePassword = true"
              />
            </div>
            <div class="form-item">
              <label for="repeat-password">Подтверждение пароля*</label>
              <input
                :style="
                  this.password !== this.repeatPassword && this.repeatPassword !== ''
                    ? 'border-color: #CE5719'
                    : ''
                "
                id="repeat-password"
                maxlength="16"
                :type="hideRepeatPassword ? 'password' : 'text'"
                v-model.trim="repeatPassword"
              />
              <img
                v-if="hideRepeatPassword"
                src="../assets/img/hide.svg"
                alt="show"
                @click="hideRepeatPassword = false"
              />
              <img
                v-else-if="!hideRepeatPassword"
                src="../assets/img/show.svg"
                alt="hide"
                @click="hideRepeatPassword = true"
              />
            </div>

            <div class="form-item" style="margin-bottom: 20px">
              <label for="timezone">Тайм зона</label>
              <vue-select
                class="select"
                id="timezone"
                v-model="timeZoneReg"
                :clearable="false"
                :options="timeZoneList"
                :searchable="false"
                :filterable="false"
                :multiple="false"
              >
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    <span>{{ option.name }}</span>
                  </div>
                </template>
                <template slot="selected-option">
                  <div class="selected d-center">
                    {{ timeZoneReg.name }}
                  </div>
                </template>
              </vue-select>
            </div>
            <div class="form-checkbox">
              <input id="agree" type="checkbox" v-model="agree" />
              <label for="agree"
                >Я согласен с
                <span @click="getPrivacyPolicy()">политикой конфиденциальности</span></label
              >
            </div>
            <div class="form-button">
              <button :disabled="stepTwoPassword" @click="createAccount">Регистрация</button>
            </div>
          </div>
        </div>
      </div>

      <!-- / Form REGISTER-->

      <!-- Form RESET PASSWORD -->
      <div class="reset-password-form" v-if="resetPassword && !token">
        <div>
          <div class="navigate-prev" @click="resetPassword = false">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style="transform: rotate(180deg)"
            >
              <path
                d="M18.17 11L15.59 8.41L17 7L22 12L17 17L15.59 15.59L18.17 13H2V11H18.17Z"
                :fill="'#0057FF'"
              />
            </svg>
            <span>Назад</span>
          </div>
          <div class="header">Восстановление пароля</div>
          <div class="form">
            <div class="form-item">
              <label for="emailReset">Email</label>
              <input
                id="emailReset"
                maxlength="50"
                type="text"
                v-model.trim="email"
                @keyup.enter="sendRequestResetPassword"
              />
            </div>
          </div>
        </div>
        <div class="login">
          <button @click="sendRequestResetPassword" :disabled="isEmailValid">
            Восстановить пароль
          </button>
        </div>
      </div>
      <!-- / Form RESET PASSWORD -->

      <!-- Form CHANGE PASSWORD -->
      <div class="reset-password-form" v-if="token">
        <div>
          <div class="header">Восстановление пароля</div>
          <div class="form">
            <div class="form-item">
              <label for="changePassword">Пароль</label>
              <input
                :style="
                  this.passwordChange !== this.repeatPasswordChange &&
                  this.passwordChange.length < 6
                    ? 'border-color: #CE5719'
                    : ''
                "
                id="changePassword"
                maxlength="16"
                :type="hideAuthPassword ? 'password' : 'text'"
                v-model.trim="passwordChange"
                @keyup.enter="changePassword()"
              />
              <img
                v-if="hideAuthPassword"
                src="../assets/img/hide.svg"
                alt="show"
                @click="hideAuthPassword = false"
              />
              <img
                v-else-if="!hideAuthPassword"
                src="../assets/img/show.svg"
                alt="hide"
                @click="hideAuthPassword = true"
              />
            </div>

            <div class="form-item">
              <label for="changePasswordRepeat">Повторите пароль</label>
              <input
                id="changePasswordRepeat"
                :style="
                  this.passwordChange !== this.repeatPasswordChange && this.repeatPasswordChange
                    ? 'border-color: #CE5719'
                    : ''
                "
                maxlength="16"
                :type="hideAuthPassword ? 'password' : 'text'"
                v-model.trim="repeatPasswordChange"
                @keyup.enter="changePassword()"
              />
              <img
                v-if="hideAuthPassword"
                src="../assets/img/hide.svg"
                alt="show"
                @click="hideAuthPassword = false"
              />
              <img
                v-else-if="!hideAuthPassword"
                src="../assets/img/show.svg"
                alt="hide"
                @click="hideAuthPassword = true"
              />
            </div>
          </div>
        </div>
        <div class="login">
          <button
            @click="changePassword()"
            :disabled="
              passwordChange.length < 6 ||
              repeatPasswordChange.length < 6 ||
              repeatPasswordChange !== passwordChange
            "
          >
            Изменить пароль
          </button>
        </div>
      </div>
      <!-- / Form CHANGE PASSWORD -->

      <div
        v-if="!token"
        class="text-center text-muted mt-3 under-auth-text"
        style="font-size: 18px; margin-bottom: 40px"
      >
        {{ FooterText }}
        <a href="javascript:void(0)" @click="switchReg">{{ ButtonText }}</a>
      </div>

      <!--      <div class="text-center text-muted">-->
      <!--        Don't have an account yet? <a href="javascript:void(0)">Sign Up</a>-->
      <!--      </div>-->
    </div>
  </div>
</template>
<script>
import authEvents from '../components/Core/KernelProject/authEvents'
import VueSelect from 'vue-select'
import timeZoneList from '../assets/timeZone'
export default {
  name: 'LayoutAuth',
  mixins: [authEvents],
  components: { VueSelect },
  data() {
    return {
      role: '',
      step: 2,
      lastName: '',
      firstName: '',
      middleName: '',
      phone: '',
      email: null,
      password: null,
      repeatPassword: '',
      agree: false,
      phoneMask: {
        mask: '+9 (999) 999 99-99',
        showMaskOnHover: false
      },
      emailValidation: /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i,
      isEmail: true,
      link: '',
      hidePassword: true,
      hideRepeatPassword: true,
      hideAuthPassword: true,
      resetPassword: false,
      passwordChange: '',
      repeatPasswordChange: '',
      token: null,
      timeZoneReg: {
        name: '(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk',
        value: 3
      },
      timeZoneList
    }
  },
  mounted() {
    this.token = this.$route.query.token
  },
  methods: {
    back() {
      this.role = ''
      this.firstName = ''
      this.lastName = ''
      this.middleName = ''
      this.phone = ''
      this.link = ''
    }
    // backTwo() {
    //   this.email = ''
    //   this.isEmail = true
    //   this.password = ''
    //   this.repeatPassword = ''
    // }
  },
  computed: {
    stepOneValidation() {
      return (
        this.lastName === '' ||
        this.firstName === '' ||
        this.phone.toString().replaceAll('_', '').length < 18 ||
        ((this.role === 'almost_teacher' || this.role === 'almost_methodologist') &&
          this.link === '')
      )
    },

    stepTwoPassword() {
      let regexp = new RegExp(this.emailValidation)
      this.isEmail = regexp.test(this.email)
      return (
        !this.email ||
        this.password.length < 6 ||
        this.repeatPassword === '' ||
        !this.agree ||
        !this.isEmail ||
        this.password !== this.repeatPassword
      )
    },

    authValidation() {
      return !this.email || !this.password
    },
    isEmailValid() {
      let regexp = new RegExp(this.emailValidation)
      this.isEmail = regexp.test(this.email)
      return !this.isEmail
    }
  }
}
</script>
<!--auth css-->
<style src="@/vendor/styles/pages/authentication.scss" lang="scss"></style>

<style scoped lang="scss">
@import '../styleVariables';

.authentication-wrapper.authentication-1 .authentication-inner {
  max-width: 426px;
  a {
    color: #1976d2 !important;
  }
}
.auth-logo {
  width: 414px;
  margin: 50px 0;

  cursor: pointer;
}

.mobile-auth-logo {
  display: none;
}

.auth,
.reset-password-form {
  width: 426px;
  min-height: 460px;
  box-shadow: 0 2px 14px 1px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  padding: 28px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .navigate {
    display: flex;
    justify-content: space-between;

    &-prev {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-bottom: 12px;

      span {
        line-height: 20px;
        font-size: 16px;
        color: $bright-blue;
        margin-left: 8px;
      }
    }
  }
  .header {
    font-size: 24px;
    font-weight: bold;
    font-family: $font-bold-bold;
    margin-bottom: 22px;
    color: $main-dark;
  }
  .form {
    img {
      position: relative;
      top: -36px;
      right: -310px;
      width: 24px;
      cursor: pointer;
    }

    &-item {
      display: flex;
      flex-direction: column;
      margin-bottom: 12px;

      label {
        cursor: pointer;
        margin-bottom: 5px !important;
        font-family: $font-regular;
        font-size: 16px;
        color: $main-dark;
      }

      input {
        width: 350px;
        height: 50px;
        border: 1px solid #c5cee0;
        border-radius: 5px;
        transition: 0.2s;
        padding: 15px 12px;
        font-family: $font-regular;
        font-size: 16px;

        &:focus {
          border: 1px solid #0057ff;
          outline: none;
        }
      }
    }

    .policy-label {
      width: 100%;
      text-align: center;
      font-size: 18px;
      color: $main-dark;
      margin-top: 50px;
      span {
        color: $bright-blue;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .reset-password {
      width: fit-content;
      cursor: pointer;
      margin-top: -16px;
      font-size: 16px;
      span {
        color: $bright-blue;
      }
    }
  }

  .login {
    button {
      width: 347px;
      height: 50px;
      background-color: $bright-blue;
      border-radius: 5px;
      color: $main-white;
      font-size: 16px;
      transition: 0.3s;
      outline: none;

      &:disabled {
        background-color: $light-gray;
      }

      &:hover:enabled {
        background-color: #5c93ff;
      }
    }
  }
}

.register {
  width: fit-content;
  margin: 0 auto;
  &-step-1 {
    .student,
    .teacher,
    .methodist {
      width: 426px;
      height: 108px;
      background: #ffffff;
      box-shadow: 0 2px 14px 1px rgba(0, 0, 0, 0.06);
      border-radius: 6px;
      cursor: pointer;
      transition: 0.3s;
      border: 1px solid transparent;
      margin-bottom: 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 25px 32px;

      &:hover {
        background: #f5f9ff;
        /* blue */

        border: 1px solid #0057ff;
        box-shadow: 0 4px 14px 1px rgba(0, 0, 0, 0.04);
      }

      &-icon {
      }

      &-info {
        &__name {
          font-size: 16px;
        }
        &__description {
          font-size: 12px;
          color: #8692a6;
          font-family: 'Inter', sans-serif;
          max-width: 239px;
          line-height: 17px;
        }
      }

      &-arrow {
        transition: 0.3s;
        opacity: 0;
      }
    }
    .methodist {
      &:hover .methodist-arrow {
        opacity: 1;
      }
    }
    .student {
      &:hover .student-arrow {
        opacity: 1;
      }
    }
    .teacher {
      &:hover .teacher-arrow {
        opacity: 1;
      }
    }
  }

  &-step-2 {
    width: 426px;
    min-height: 560px;
    box-shadow: 0 2px 14px 1px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    padding: 28px 40px;

    .navigate {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 12px;
      color: $bright-blue;

      &-prev {
        display: flex;
        align-items: center;
        cursor: pointer;
        span {
          margin-left: 8px;
        }
      }
      &-next {
        display: flex;
        align-items: center;
        cursor: pointer;
        span {
          margin-right: 8px;
        }
      }
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 23px;

      &-info {
        &__name {
          font-size: 24px;
          font-weight: bold;
          font-family: $font-bold-bold;
        }
        &__description {
          font-size: 16px;
          font-family: $font-regular;
        }
      }
      &-steps {
        margin-top: 6px;
        font-size: 16px;
        font-family: $font-bold-bold;
      }
    }

    .form {
      img {
        position: relative;
        top: -36px;
        right: -310px;
        width: 24px;
        cursor: pointer;
      }
      &-item {
        display: flex;
        flex-direction: column;
        margin-bottom: 12px;
        height: 79px;
        label {
          cursor: pointer;
          margin-bottom: 5px !important;
          font-family: $font-regular;
          font-size: 16px;
        }
        input {
          width: 350px;
          height: 50px;
          border: 1px solid #c5cee0;
          border-radius: 5px;
          transition: 0.2s;
          padding: 15px 12px;
          font-family: $font-regular;
          font-size: 16px;

          &:focus {
            border: 1px solid #0057ff;
            outline: none;
          }
        }
        /deep/ .vs__dropdown-menu {
          max-height: 210px;
          width: unset;
        }
        /deep/ .vs__dropdown-toggle {
          width: 350px;
          height: 56px;
          border: 1px solid #c5cee0;
          .arrow {
            margin-right: 10px;
          }
          .selected {
            font-family: $font-regular;
            font-size: 16px;
          }
          .vs__selected-options {
            align-items: center;
          }
        }
      }

      &-checkbox {
        font-size: 14px;
        color: #8f9bb3;
        display: flex;
        align-items: center;
        margin-bottom: 34px;
        label {
          cursor: pointer;
          margin-bottom: 0;
          margin-left: 8px;
          span {
            color: $bright-blue;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        input {
          cursor: pointer;
        }
      }

      &-button {
        button {
          width: 347px;
          height: 50px;
          background-color: $bright-blue;
          border-radius: 5px;
          color: $main-white;
          font-size: 16px;
          transition: 0.3s;
          outline: none;

          &:disabled {
            background-color: $light-gray;
          }

          &:hover:enabled {
            background-color: #5c93ff;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  /deep/ html:not([dir='rtl']) .pl-4,
  html:not([dir='rtl']) .px-4 {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .reset-password {
    margin-bottom: 24px;
  }
  .auth-logo {
    display: none;
  }
  .mobile-auth-logo {
    width: 233px;
    margin-top: 44px;
    margin-bottom: 44px;
    display: unset;
  }
  .under-auth-text {
    display: flex;
    flex-direction: column;
    font-size: 14px !important;
    a {
      font-size: 16px !important;
    }
  }
  .register {
    margin: 0;
    width: 100%;
    .register-step-1 {
      width: 100%;
      .student-arrow,
      .teacher-arrow,
      .methodist-arrow {
        display: none;
      }
      .student-icon,
      .teacher-icon,
      .methodist-icon {
        img {
          width: 28px;
        }
      }
      .student-info,
      .teacher-info,
      .methodist-info {
        width: 100%;
        &__description {
          max-width: unset;
        }
      }
      img {
        margin-right: 12px;
      }
    }
    .register-step-2 {
      .header {
        margin-bottom: 20px;
        &-info {
          &__name {
            font-size: 18px;
          }
        }
        &-steps {
          font-size: 14px;
          margin-top: 4px;
        }
      }
    }
  }
  .auth,
  .reset-password-form,
  .register-step-1,
  .register-step-2 {
    box-shadow: none;
    width: auto;
    padding: 0;
    justify-content: center;
    min-height: unset;
    .header {
      font-size: 18px;
      //margin-bottom: 12px;
    }
    .form img {
      right: unset;
      left: 86%;
      top: -31px;
    }
    .form-item {
      input {
        width: 100%;
        height: 40px;
        padding: 8px 12px;
      }
      /deep/ .vs__dropdown-toggle {
        min-width: 100%;
        min-height: 40px;
      }

      //.select {
      //  width: 100%;
      //  height: 40px;
      //  //padding: 8px 12px;
      //}
    }
    .form-checkbox {
      margin-bottom: 20px;
      input {
        height: 17px;
        width: 17px;
      }
      label {
        font-size: 12px;
        font-family: $font-regular;
      }
    }
    button {
      width: 100% !important;
      height: 40px !important;
    }
    .student,
    .teacher,
    .methodist {
      padding: 12px;
      width: 100%;
      margin-bottom: 26px;
    }
  }
}
</style>
