export default {
  methods: {
    Change__Number(data) {
      this.$store.commit('newRec', data)
    }
  },
  data() {
    return {
      inputNumber: {
        value: this.value,
        text: this.text,
        disabled: this.disabled,
        min: this.min,
        max: this.max,
        step: this.step,
        placeholder: this.placeholder,
        tooltip: this.tooltip,
        keyAdd: this.keyAdd
      }
    }
  }
}
