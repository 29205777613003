export default {
  methods: {
    Checkbox__changeChecked(data) {
      this.$store.commit('newRec', data)
    }
  },
  data() {
    return {
      checkbox: {
        value: this.value,
        text: this.text,
        disabled: this.disabled,
        tooltip: this.tooltip,
        keyAdd: this.keyAdd
      }
    }
  }
}
