<template>
  <div class="modal-background" @mousedown.self="hide">
    <div class="modal-container">
      <div class="header">
        <span>Мои реквизиты</span>
        <div class="controls" v-show="!isEdit">
          <img
            class="edit"
            src="../../../assets/img/ES/edit_requisites.svg"
            alt="edit"
            @click="setEdit()"
          />
          <img src="../../../assets/img/ES/close_requisites.svg" alt="close" @click="hide()" />
        </div>
      </div>
      <div class="required" v-show="isEdit">*Обязательное поле</div>
      <div class="content">
        <div class="content-item">
          <div class="label">Номер счета {{ isEdit ? '*' : '' }}</div>
          <div class="editable">
            <span v-if="!isEdit">{{ (data && data.account_number) || '-' }}</span>
            <input v-model="billNumber" type="text" v-else v-mask="billNumberMask" />
          </div>
        </div>
        <div class="content-item">
          <div class="label">Банк получатель {{ isEdit ? '*' : '' }}</div>
          <div class="editable">
            <span v-if="!isEdit">{{ (data && data.bank) || '-' }}</span>
            <input
              v-model="bank"
              type="text"
              :class="{ 'error-border': !checkAddressAndBankName(bank) && bank }"
              v-else
            />
          </div>
        </div>
        <div class="content-item">
          <div class="label">БИК Банка получателя {{ isEdit ? '*' : '' }}</div>
          <div class="editable">
            <span v-if="!isEdit">{{ (data && data.bank_bic) || '-' }}</span>
            <input v-model="BIK" type="text" v-mask="BICMask" v-else />
          </div>
        </div>
        <div class="content-item">
          <div class="label">Корреспондентский счет</div>
          <div class="editable">
            <span v-if="!isEdit">{{ (data && data.correspondent_account) || '-' }}</span>
            <input v-model="korrespBill" type="text" v-mask="korrespBillMask" v-else />
          </div>
        </div>
        <div class="content-item">
          <div class="label">Код подразделения Банка</div>
          <div class="editable">
            <span v-if="!isEdit">{{ (data && data.bank_code) || '-' }}</span>
            <input v-model="bankCode" type="text" v-else @input="cutLetterSymbols" />
          </div>
        </div>
        <div class="content-item">
          <div class="label">Адрес подразделения Банка по месту ведения счета карты</div>
          <div class="editable">
            <span v-if="!isEdit">{{ (data && data.bank_address) || '-' }}</span>
            <textarea
              v-model="bankAddress"
              :class="{ 'error-border': !checkAddressAndBankName(bankAddress) && bankAddress }"
              v-else
            />
          </div>
        </div>
      </div>
      <div class="controls-btns" v-if="isEdit">
        <button class="hide-modal-btn" @click="cancelEdit" v-if="!create">Отменить</button>
        <button class="send-complaint-btn" :disabled="!validation" @click="saveRequisite">
          Сохранить
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { domain, MicroServices } from '@/globalVariables'

export default {
  name: 'ModalRequisites',
  mixins: [],
  props: ['data', 'create'],
  components: {},
  data() {
    return {
      isEdit: false,
      billNumber: null,
      bank: null,
      BIK: null,
      korrespBill: null,
      bankCode: null,
      bankAddress: null,
      billNumberMask: {
        mask: '9999 9999 9999 9999 9999',
        showMaskOnHover: false
      },
      BICMask: {
        mask: '999999999',
        showMaskOnHover: false
      },
      korrespBillMask: {
        mask: '9999 9999 9999 9999 9999',
        showMaskOnHover: false
      }
    }
  },
  mounted() {
    if (this.create) {
      this.setEdit()
    }
  },
  methods: {
    hide() {
      this.$emit('hide')
    },

    /**
     * Установаить начальные значения для редактирования
     */
    setInitialState() {
      this.billNumber = this.data.account_number
      this.bank = this.data.bank
      this.BIK = this.data.bank_bic
      this.korrespBill = this.data.correspondent_account
      this.bankCode = this.data.bank_code
      this.bankAddress = this.data.bank_address
    },

    /**
     * Установить редактирование реквизитов
     */
    setEdit() {
      if (!this.create) {
        this.setInitialState()
      }
      this.isEdit = true
    },

    /**
     * Отменить редактирование реквизитов
     */
    cancelEdit() {
      this.isEdit = false
    },

    /**
     * Вализация для адреса
     * @param word
     * @returns {*}
     */
    checkAddressAndBankName(word) {
      return word?.match(/^([A-ZА-Я.0-9\s\-_/,;:"'»«()]*)$/iu)
    },

    /**
     * Убрать все символы кроме цифр
     */
    cutLetterSymbols() {
      this.bankCode = this.bankCode.replace(/[^0-9]/g, '')
    },

    /**
     * Сохранить реквизиты
     */
    saveRequisite() {
      this.korrespBill = this.korrespBill.replaceAll(' ', '')
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Requisite/${
          this.create ? 'create' : 'update/' + this.data.id
        }`,
        method: `${this.create ? 'POST' : 'PUT'}`,
        data: [
          {
            user_uuid: this.$cookie.get('uid'),
            account_number: this.billNumber,
            bank: this.bank,
            bank_bic: this.BIK,
            correspondent_account: this.korrespBill,
            bank_code: this.bankCode,
            bank_address: this.bankAddress
          }
        ]
      })
        .then(() => {
          this.successAlert('Данные успешно обновлены!')
          this.$emit('update')
          this.cancelEdit()
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    }
  },
  computed: {
    validation() {
      return (
        this.billNumber?.replaceAll('_', '').length === 24 &&
        ((this.korrespBill.indexOf(' ') !== -1 &&
          this.korrespBill?.replaceAll('_', '').length === 24) ||
          (this.korrespBill.indexOf(' ') === -1 &&
            this.korrespBill?.replaceAll('_', '').length === 20)) &&
        this.bank &&
        this.BIK.replaceAll('_', '').length === 9 &&
        this.checkAddressAndBankName(this.bank) &&
        this.checkAddressAndBankName(this.bankAddress)
      )
    }
  },
  watch: {},
  validations: {}
}
</script>

<style scoped lang="scss">
@import './src/styleVariables';
.modal-background {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.3);
  animation: fade-in 0.3s;
  .modal-container {
    background-color: $main-white;
    border-radius: 8px;
    padding: 14px 24px;
    width: 503px;
    display: flex;
    flex-direction: column;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 18px;
      span {
        font-size: 18px;
      }
      .controls {
        .edit {
          margin-right: 16px;
        }
        img {
          cursor: pointer;
        }
      }
    }
    .required {
      font-family: $font-regular;
      font-size: 16px;
      line-height: 20px;
      margin-top: -16px;
      margin-bottom: 14px;
    }
    .content {
      &-item {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 40px;
        margin-bottom: 10px;
        .label {
          font-size: 14px;
          line-height: 24px;
          color: $main-dark;
        }
        .editable {
          input {
            width: 100%;
            height: 25px;
            border: 0.5px solid #c5cee0;
            border-radius: 4px;
            padding: 3px 8px;
            font-family: $font-regular;

            &:focus {
              outline: none;
              border-color: $bright-blue;
            }
          }
          .error-border {
            border-color: $red !important;
          }
          textarea {
            width: 100%;
            border: 0.5px solid #c5cee0;
            border-radius: 4px;
            padding: 3px 8px;
            font-family: $font-regular;
            resize: none;

            &:focus {
              outline: none;
              border-color: $bright-blue;
            }
          }
          span {
            font-family: $font-regular;
            font-size: 13px;
            line-height: 24px;
          }
        }
      }
    }
    .controls-btns {
      display: flex;
      justify-content: flex-end;
      margin-top: 18px;
      button {
        width: 137px;
      }
      .send-complaint-btn {
        margin-left: 15px;
        border: none;
        background-color: $bright-blue;
        border-radius: 4px;
        padding: 5px 21px;
        color: $main-white;
        outline: none;
        &:disabled {
          background-color: $light-gray;
        }
      }
      .hide-modal-btn {
        border: 1px solid $bright-blue;
        border-radius: 4px;
        padding: 5px 21px;
        color: $bright-blue;
        outline: none;
      }
    }
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-height: 400px) {
  .modal-background {
    display: block;

    .modal-container {
      margin: 13px auto;
    }
  }
}
</style>
