import axios from 'axios'
import { DefaultValueSettings, domain } from '@/globalVariables'
import {
  isEmpty,
  isUndefined,
  mergeObject,
  notEmpty
} from '@/components/Core/KernelProject/helperFunction'
export default {
  data() {
    return {
      fields: [
        {
          key: 'add',
          label: `add`
        },
        {
          key: 'multiSelect',
          label: `multiSelect`
        },
        {
          key: 'removeAll',
          label: `removeAll`
        },
        {
          key: 'editAll',
          label: `editAll`
        }
      ],
      data: [
        {
          add: {
            type: 'Button',
            label: '',
            tooltip: 'Добавить новую запись',
            variant: 'outline-primary',
            value: 'add',
            role: 'create'
          },
          multiSelect: {
            type: 'toggleMassEdit',
            color: '#4b83f0',
            label: `Изменить`,
            tooltip: `Массовое изменение`,
            value: false,
            emitName: 'multiSelect',
            role: `create`,
            keyAdd: 'multiSelect',
            hidden: true
          },
          removeAll: {
            type: 'Button',
            label: 'Удалить выбранные',
            tooltip: 'Удалить выбранные записи',
            variant: 'outline-danger',
            value: 'removeAll',
            readonly: true,
            role: 'deleteByIds',
            hidden: true,
            lg: true
          },
          editAll: {
            type: 'Button',
            label: 'Изменить выбранные',
            tooltip: 'Изменить выбранные записи',
            variant: 'outline-info',
            value: 'editAllModal',
            role: 'updateByIds',
            hidden: true,
            lg: true
          },
          chooseAll: {
            type: 'Button',
            label: 'Выбрать все',
            tooltip: 'Выбрать все записи',
            variant: 'outline-info',
            value: 'chooseAll',
            role: 'chooseAllItems',
            hidden: true,
            lg: true
          }
        }
      ],
      addButton: [
        {
          button: {
            type: 'Button',
            text: 'Сохранить',
            tooltip: 'Сохранить данную запись',
            variant: 'outline-success',
            value: 'save',
            role: 'create',
            renderPolicy: false
          }
        }
      ],
      fieldAddButton: [
        {
          key: 'button',
          label: `Закрыть`,
          component: 'Button'
        }
      ],
      isLoadEndpoints: false
    }
  },
  destroyed() {
    this.$root.$off('update')
    this.$root.$off('multiSelect')
    this.$root.$off('multiSelectButtonDisabled')
  },
  mounted() {
    this.multiSelect = false
    this.route = this.routeCalc()
    this.$root.$on('offTable', () => {
      this.loading = false
      this.isLoadEndpoints = false
    })
    /**
     * Слушатель события обновления данных внутри таблицы
     * @param _from - откуда начинается поиск
     * @param perPage - сколько записей получить
     * @param query - запрос для поиска
     * @param queryGlobal - запрос для глобального поиска
     * @param querySort - запрос для сортировка по полю
     * @param queryExact - запрос для точного поиска
     * @param rangeQuery - запрос для поиска в промежутке
     * @param clear - флаг отчистки фильтров
     */
    this.$root.$on(
      'update',
      (_from, perPage, query, queryGlobal, querySort, queryExact, rangeQuery, clear, route) => {
        if (_from >= 0) {
          this.$store.state.pagination.from = _from
        }
        if (perPage >= 0) {
          this.$store.state.pagination.count = perPage
        }
        if (query !== null && query !== '' && query !== undefined) {
          this.$store.state.pagination.query = query
        }
        if (querySort !== null && querySort !== '' && querySort !== undefined) {
          this.$store.state.pagination.querySort = querySort
        }
        if (queryExact !== null && queryExact !== '' && queryExact !== undefined) {
          this.$store.state.pagination.queryExact = queryExact
        }
        if (rangeQuery !== null && rangeQuery !== '' && rangeQuery !== undefined) {
          this.$store.state.pagination.rangeQuery = rangeQuery
        }
        if (queryGlobal !== null) {
          this.$store.state.pagination.queryGlobal = queryGlobal
        }
        if (clear || query === null) {
          this.$store.state.pagination.query = ''
        }
        if (clear || querySort === null) {
          this.$store.state.pagination.querySort = ''
        }
        if (clear || queryExact === null) {
          this.$store.state.pagination.queryExact = ''
        }
        if (clear || rangeQuery === null) {
          this.$store.state.pagination.rangeQuery = ''
        }

        if (!route) {
          route =
            this.selectedRole.value === 'student'
              ? 'StudentTransactionHistoryView'
              : 'LessonTransactionHistoryViewForAdmin'
        }
        this.getTableInfo(
          this.$store.state.pagination.from,
          this.$store.state.pagination.count,
          this.$store.state.pagination.query,
          this.$store.state.pagination.queryGlobal,
          this.$store.state.pagination.querySort,
          this.$store.state.pagination.queryExact,
          this.$store.state.pagination.rangeQuery,
          route
        )
      }
    )

    this.$root.$on('multiSelect', () => {
      this.switchMultiSelect()
    })
    this.$root.$on('multiSelectButtonDisabled', (state) => {
      this.multiSelectButtonDisabled(state)
    })
  },
  methods: {
    routeCalc() {
      return (
        this.$route.params.model ||
        this.$route.path
          .slice(1)
          .split(/\s+/)
          .map((word) => word[0].toUpperCase() + word.substring(1))
          .join(' ')
          .split('/')[1]
      )
    },
    /**
     * Функция получения данных для таблицы
     * Все данные обрабатываются и создаются некий fields -> нужный для обычного компонента b-table
     * Полученнные данные фильтруются для CRUD-функция и в таблицу попадают данные с флагом inList !== false
     * @param _from
     * @param perPage
     * @param query
     * @param queryGlobal
     * @param querySort
     * @param queryExact
     * @param rangeQuery
     */
    getTableInfo(
      _from,
      perPage,
      query,
      queryGlobal,
      querySort,
      queryExact,
      rangeQuery,
      route,
      role,
      uuid
    ) {
      this.tableFields = []
      if (!query) {
        query = this.selectedMonth || this.currentMonth
      }
      if (!queryGlobal) {
        queryGlobal = ''
      }
      if (!querySort) {
        querySort = this.$store.state.pagination.querySort || ''
        this.$store.state.pagination.querySort = ''
      }
      if (!queryExact) {
        queryExact = this.$store.state.pagination.queryExact || ''
        this.$store.state.pagination.queryExact = ''
      }
      if (!rangeQuery) {
        rangeQuery = this.$store.state.pagination.rangeQuery || ''
        this.$store.state.pagination.rangeQuery = ''
      }
      if (!uuid) {
        uuid = this.selectedUser.sync_uuid
      }
      if (!role) {
        if (this.selectedRole.value !== 'student') {
          role = this.selectedRole.value
        }
      }

      let prefix = this.$route.params.prefix
      this.store = this.$store.state.metaProjectData[prefix]
      // let route = this.routeCalc()
      this.setAddBtn()
      let objectWith = []
      let currentPath
      if (this.store) {
        currentPath = this.store[route]
      } else {
        return null
      }
      for (let i in currentPath['relations']) {
        objectWith.push(i)
      }
      objectWith = objectWith.filter((v, i, a) => a.indexOf(v) === i)
      this.setFields(route)
      axios({
        method: 'get',
        url: `${domain}/${prefix}/${route}/?${queryExact}&${querySort}`,
        params: {
          _from: _from || '',
          _count: perPage || 25,
          _full_search: queryGlobal || '',
          _with: JSON.stringify(objectWith),
          _search: query && JSON.stringify({ created: query }),
          _range_from: rangeQuery.start,
          _range_to: rangeQuery.end,
          role: role && JSON.stringify([role]),
          user_uuid: uuid
        }
      })
        .then((response) => {
          let data = response.data.data
          this.getRelationFields(data, route)
        })
        .catch((error) => this.errorAlert(error))
    },
    /**
     * функция отрисовки кнопки "добавить"
     */
    setAddBtn() {
      let route = this.routeCalc()
      if (this.store && this.store[route] && this.store[route].fields['add_btn']) {
        delete this.store[route].fields['add_btn']
      }
    },
    /**
     * функция отрисовки филдов таблицы
     */
    setFields(route) {
      // let route = this.routeCalc()
      let currentPath
      if (this.store) {
        currentPath = this.store[route]
      }
      for (let key in currentPath.fields) {
        if (currentPath.fields.hasOwnProperty(key)) {
          if (
            notEmpty(currentPath.fields[key]['inList'])
              ? currentPath.fields[key]['inList'] !== false
              : currentPath.fields[key]['renderPolicy'] !== false
          ) {
            let objField = {
              key: key,
              label: currentPath.fields[key].label,
              thClass: this.getStyleField(key, route, 'thClass'),
              tdClass: this.getStyleField(key, route, 'tdClass'),
              visible: this.getStyleField(key, route, 'visible'),
              sortable:
                currentPath.fields[key].sortable || DefaultValueSettings.isSortableAllColumnsTable
            }
            this.tableFields.push(objField)
          }
        }
      }
      if (currentPath['defaultSortBy']) {
        // let sortBy = {
        //   sortBy: currentPath['defaultSortBy'],
        //   sortDesc: currentPath.fields[currentPath['defaultSortBy']]['defaultSortOrder'] === 'desc'
        // }
        this.$root.$emit('setDefaultSort', currentPath['defaultSortBy'])
      }
    },
    /**
     * вспомогательная функция для getStyleField, проверяет есть ли в куки информация о филдах
     * @param fields
     * @param route
     * @param key
     * @returns {*}
     */
    checkStyleFields(fields, route, key) {
      return (
        fields &&
        fields[this.$route.params.prefix] &&
        fields[this.$route.params.prefix][route] &&
        fields[this.$route.params.prefix][route][key]
      )
    },
    /**
     * вспомогательная функция для отрисовки таблицы, проверяет есть ли в куки информация о том, какие филды отрисовывать
     * @param key
     * @param route
     * @param param
     * @returns {string|boolean|*}
     */
    getStyleField(key, route, param) {
      let styleFields = JSON.parse(this.$cookie.get('styleFields'))
      if (this.checkStyleFields(styleFields, route, key)) {
        let field = styleFields[this.$route.params.prefix][route][key]
        return field[param]
      } else {
        return param === 'tdClass' || param === 'thClass' ? 'table-row' : true
      }
    },
    /**
     * При запросе метаДанных приходит информация о каждой связанной сущности без дополнительного запроса
     * таким образом мы просто заносим каждую связь по типу: микросервис/модель/связянная_сущность (пр p2p/user/team)
     * где команды являются m2m связью.
     * @param data
     * @param route
     */
    getRelationFields(data, route) {
      let prefix = this.$route.params.prefix
      let model = this.routeCalc()
      if (notEmpty(data['relations'])) {
        if (isEmpty(this.$store.state.relationsData[prefix][model])) {
          this.$store.state.relationsData[prefix][model] = {}
        }
        if (
          isUndefined(
            this.$store.state.metaProjectData[this.$route.params.prefix][model]['relations']
          )
        ) {
          return undefined
        }
        this.getRelationFieldData(data, model, prefix)
      }
      this.setTable(data)
    },
    /**
     * вспомогательная функция для getRelationFields
     * @param data
     * @param model
     * @param prefix
     */
    getRelationFieldData(data, model, prefix) {
      for (let i in data['relations']) {
        if (
          isUndefined(
            this.$store.state.metaProjectData[this.$route.params.prefix][model]['relations'][i]
          )
        ) {
          return undefined
        }
        let globalModel = this.$store.state.metaProjectData[this.$route.params.prefix][model][
          'relations'
        ][i]['relationModel']
        let relationsData = this.$store.state.relationsData[prefix][model]
        if (isEmpty(relationsData[globalModel])) {
          relationsData[globalModel] = {}
        }
        relationsData[globalModel] = mergeObject(relationsData[globalModel], data['relations'][i])
      }
    },
    /**
     * Установка данных для таблицы с её отрисовкой и запуск зависимых процессов
     * @param data
     */
    setTable(data) {
      this.tableData = []
      this.tableData = data
      let metaData = this.$store.state.metaProjectData[this.$route.params.prefix]
      let route = this.routeCalc()
      this.viewSearch = metaData[route]['supportFullSearch']
      this.$root.$emit('getFilterData')
      this.$root.$emit('filterSpinner')
      this.loading = true
      if (notEmpty(this.$store.state.auth.endpoints[this.$route.params.prefix])) {
        this.isLoadEndpoints = notEmpty(
          this.$store.state.auth.endpoints[this.$route.params.prefix][
            this.$route.params.model || route
          ]
        )
      }
    },
    /**
     * Включение и выключение возможности мульти-выбора записей таблицы
     */
    switchMultiSelect() {
      this.multiSelect = !this.multiSelect
      this.data[0].removeAll.hidden = !this.data[0].removeAll.hidden
      this.data[0].editAll.hidden = !this.data[0].editAll.hidden
      this.data[0].removeAll.readonly = true
      this.data[0].editAll.readonly = true
    },
    /**
     * Выключение мультиселекта при изменении страницы
     */
    refreshMultiSelect() {
      this.multiSelect = false
      this.data[0].multiSelect.hidden = true
      this.data[0].removeAll.hidden = true
      this.data[0].editAll.hidden = true
      this.data[0].removeAll.readonly = true
      this.data[0].editAll.readonly = true
    },
    /**
     * Отключение кнопок, если элементы таблицы при МультиСелекте не были выбраны
     * @param state
     */
    multiSelectButtonDisabled(state) {
      this.data[0].removeAll.readonly = state
      this.data[0].editAll.readonly = state
    }
  },
  computed: {
    nameTable() {
      return this.$store.state.metaProjectData[this.$route.params.prefix]
        ? this.$store.state.metaProjectData[this.$route.params.prefix][this.routeCalc()].label
        : 'NoName'
    }
  },
  watch: {
    /**
     * Установка статуса для кнопки массового изменения
     * @return {any}
     */
    multiSelect() {
      this.data[0].multiSelect.value = this.multiSelect
    },
    /**
     * Скрытие кнопки включение массового изменения
     */
    store() {
      if (this.data[0].multiSelect) {
        this.refreshMultiSelect()
      }
      let route = this.routeCalc()
      if (isUndefined(this.$store.state.auth.endpoints[this.$route.params.prefix])) {
        return
      }
      this.$store.state.auth.endpoints[this.$route.params.prefix][route].filter((role) => {
        if (role === 'deleteByIds' || role === 'updateByIds') {
          this.data[0].multiSelect.hidden = false
          if (role !== 'deleteByIds') {
            this.data[0].removeAll.hidden = true
          }
          if (role !== 'updateByIds') {
            this.data[0].editAll.hidden = true
          }
        }
      })
    }
  }
}
