<template>
  <div class="main-container" @keyup.enter.native="createLessonComments">
    <span class="container-header">Комментарии</span>
    <div class="existing-comments" v-for="item in comments" :key="item.id" ref="comments">
      <div class="single-comment-container" v-for="comment in item" :key="comment.id">
        <div class="comment-info">
          <div class="comment-main">
            <div class="sender-details">
              <span class="sender-name">{{ comment.author_full_name }}</span>
              <span class="send-time">{{ formatTime(comment.created_at) }}</span>
            </div>
            <p class="comment">
              {{ comment.content }}
            </p>
          </div>
        </div>
        <div class="comment-controls" v-if="checkAuthor(comment.author_uuid)">
          <button class="edit-btn" @click="setEditComment(comment.id, comment.content)">
            <img src="../../assets/img/icons/edit_pale.svg" alt="edit-icon" />
          </button>
          <button class="delete-btn" @click="deleteComment(comment.id)">
            <img src="../../assets/img/icons/close_big.svg" alt="delete-icon" />
          </button>
        </div>
      </div>
    </div>
    <div class="input-container">
      <div class="send-btn" v-if="editingMode" @click="removeEditMode">
        <img src="../../assets/img/ES/close_gray.svg" alt="send-icon" />
      </div>
      <div class="send-btn" @click="createLessonComments">
        <img src="../../assets/img/icons/feather_send.svg" alt="send-icon" />
      </div>
      <div id="editorMessage">
        <input
          type="text"
          v-model="commentContent"
          @keyup.enter="createLessonComments"
          :placeholder="'Добавить комментарий...'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { domain, MicroServices } from '@/globalVariables'
import formatDistance from 'date-fns/formatDistance'
import { ru } from 'date-fns/locale'

export default {
  name: 'Comments',
  mixins: [],
  props: [],
  components: {},
  data() {
    return {
      commentContent: '',
      comments: [],
      authorUuid: null,
      editingMode: false,
      commentId: null,
      ownComment: false
    }
  },
  mounted() {
    this.$root.$on('getChatContent', function () {
      this.getContent()
    })
    this.getLessonComments()
  },
  methods: {
    /**
     * возвращает true если текущий пользователь - автор
     * @param uuid
     * @returns {boolean}
     */
    checkAuthor(uuid) {
      if (this.$cookie.get('uid') === uuid) {
        return true
      }
    },
    formatTime(time) {
      return formatDistance(new Date(time), new Date(), {
        locale: ru,
        addSuffix: true
      })
    },
    getContent() {
      this.comments.push(this.comment)
      this.comment = ''
    },

    /**
     * Получить комментарии
     */
    getLessonComments() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Comment/getItems?lesson_id=${this.$route.params.lesson_id}`,
        method: 'GET'
      })
        .then((response) => {
          this.comments.push(response.data.data.items)
        })
        .catch((error) => this.errorAlert(error))
    },

    /**
     * Создать/Редактировать комментарий
     */
    createLessonComments() {
      if (this.editingMode === false && this.commentContent) {
        axios({
          url: `${domain}/${MicroServices[0].prefix}/Comment/create`,
          method: 'POST',
          data: [
            {
              content: this.commentContent,
              lesson_id: this.$route.params.lesson_id
            }
          ]
        })
          .then(() => {
            this.getLessonComments()
            this.commentContent = ''
            this.comments = []
          })
          .catch((error) => this.errorAlert(error))
      } else if (this.editingMode === true && this.commentContent) {
        axios({
          url: `${domain}/${MicroServices[0].prefix}/Comment/update/${this.commentId}`,
          method: 'POST',
          data: [
            {
              content: this.commentContent,
              lesson_id: this.$route.params.lesson_id
            }
          ]
        })
          .then((response) => {
            this.getLessonComments()
            this.commentContent = ''
            this.comments = []
            this.editingMode = false
          })
          .catch((error) => this.errorAlert(error))
      }
    },

    /**
     * Установить редактрование комментарию
     * @param id
     * @param content
     */
    setEditComment(id, content) {
      this.commentContent = content
      this.editingMode = true
      this.commentId = id
    },

    /**
     * Отменить редактирование
     */
    removeEditMode() {
      this.commentContent = null
      this.editingMode = false
      this.commentId = null
    },

    /**
     * Удалить комментарий
     * @param id
     */
    deleteComment(id) {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Comment/delete/${id}`,
        method: 'POST'
      })
        .then((response) => {
          this.getLessonComments()
          this.commentContent = ''
          this.comments = []
          this.editingMode = false
        })
        .catch((error) => this.errorAlert(error))
    }
  },
  computed: {},
  watch: {},
  validations: {}
}
</script>

<style scoped lang="scss">
@import './src/styleVariables';
.main-container {
  width: 95%;
  margin: 25px auto 100px auto;
  background-color: $main-white;
  min-height: 102px;
  height: auto;
  padding: 15px 0;
  box-shadow: 1px 0px 20px -7px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  .container-header {
    font-size: 18px;
    font-family: $font-bold;
    margin-left: 24px;
  }
  .existing-comments {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    max-height: 300px;
    overflow: auto;
    overflow-x: hidden;
    //padding-right: 20px;
    .single-comment-container {
      display: flex;
      transition: all 0.1s ease-in;
      margin-bottom: 8px;
      padding: 10px 24px;
      .comment-info {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        .sender-avatar {
          img {
            border-radius: 50%;
            width: 47px;
            height: 47px;
            margin-right: 12px;
          }
        }
        .comment-main {
          display: flex;
          flex-direction: column;
          .sender-details {
            display: flex;
            align-items: center;
            .sender-name {
              font-size: 14px;
              margin-right: 16px;
            }
            .send-time {
              font-size: 12px;
              color: $light-gray;
              font-family: $font-regular;
            }
          }
          .comment {
            font-family: $font-regular;
            font-size: 14px;
            margin-top: 3px;
          }
        }
      }
      .comment-controls {
        display: flex;
        button {
          outline: none;
          img {
            width: 13px;
            height: 13px;
          }
        }
        .edit-btn {
          img {
            margin-right: 25px;
          }
        }
      }
    }
  }
  .existing-comments {
    .single-comment-container:hover {
      background-color: $light-table-blue;
    }
  }
  .input-container {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 14px;
    .send-btn {
      margin-left: 7px;
      cursor: pointer;
      margin-right: 24px;
    }
    #editorMessage {
      width: 100%;
      padding-left: 24px;
      input {
        width: 100%;
        height: 26px;
        border: 1px solid #c5cee0;
        border-radius: 5px;
        padding: 4px 11px;
        outline: none;
      }
      input::placeholder {
        color: $light-gray;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .main-container {
    .existing-comments {
      margin-top: 0;
    }
  }
}
</style>
