<template>
  <div class="personal-schedule">
    <div class="personal-schedule-header">
      <div class="personal-schedule-header__name">Расписание на день</div>
      <div class="personal-schedule-header__tags">
        <!--        <div class="remove" v-if="type === 'student'">-->
        <!--          <img src="../../../assets/img/ES/remove_lesson.svg" alt="remove" />-->
        <!--          <span>Перенос занятия</span>-->
        <!--        </div>-->
        <div class="empty" v-if="type === 'teacher' || type === 'methodist'">
          <img src="../../../assets/img/empty-card.svg" alt="done" />
          <span>Незаполненная карточка</span>
        </div>
        <div class="done" v-if="type === 'teacher' || type === 'student'">
          <img src="../../../assets/img/lesson-complete.svg" alt="done" />
          <span>Занятие состоялось</span>
        </div>
        <div class="test-done" v-if="type === 'methodist'">
          <img src="../../../assets/img/lesson-complete.svg" alt="done" />
          <span>Тестирование состоялось</span>
        </div>
        <div class="test" v-if="type !== 'student'">
          <img src="../../../assets/img/test.svg" alt="test" />
          <span>Тест</span>
        </div>
        <div class="lesson" v-if="type === 'teacher' || type === 'student'">
          <img src="../../../assets/img/lesson.svg" alt="lesson" />
          <span>Занятие</span>
        </div>
      </div>
    </div>
    <div class="personal-schedule-content" v-if="lessons && lessons.length">
      <div
        class="card"
        v-for="(lesson, index) in lessons"
        :key="index"
        @click="$router.push({ name: 'LessonCard', params: { lesson_id: lesson.id } })"
      >
        <div
          :class="{
            done:
              lesson.kls_status_code === 'LESSON_ENDED' &&
              (lesson.has_filled_card || $cookie.get('internalName') === 'student'),
            test:
              (lesson.kls_type_code === 'PLACEMENT_TEST' ||
                lesson.kls_type_code === 'CHECK_UP' ||
                lesson.kls_type_code === 'CHECK_PROGRESS' ||
                lesson.kls_type_code === 'TEACHER_TEST_PROGRESS_TEST_LESSON' ||
                lesson.kls_type_code === 'TEACHER_TEST' ||
                lesson.kls_type_code === 'TEACHER_FIRST_TEST') &&
              (lesson.has_filled_card || lesson.kls_status_code === 'LESSON_PLANNED') &&
              lesson.kls_status_code !== 'LESSON_ENDED' &&
              $cookie.get('internalName') !== 'student',
            lesson:
              (lesson.kls_type_code === 'REGULAR' ||
                lesson.kls_type_code === 'TEACHER_TEST_REGULAR_LESSON') &&
              lesson.kls_status_code !== 'LESSON_ENDED',
            empty:
              lesson.has_filled_card === false &&
              lesson.type !== 'WORKS_TIME' &&
              lesson.kls_status_code !== 'LESSON_PLANNED' &&
              $cookie.get('internalName') !== 'student',
            studentCard:
              lesson.kls_status_code !== 'LESSON_ENDED' && $cookie.get('internalName') === 'student'
          }"
        ></div>
        <div class="card-content">
          <div class="card-content__name">
            {{
              lesson.kls_type_code === 'TEACHER_TEST' ||
              lesson.kls_type_code === 'TEACHER_FIRST_TEST'
                ? 'Тестирование'
                : lesson.sequence_number
                ? `Занятие номер ${lesson.sequence_number}`
                : 'Занятие'
            }}
          </div>
          <div class="card-content__time">
            <img src="../../../assets/img/alarm.svg" alt="time" />
            <span>{{ lesson.converted_date }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="personal-schedule-empty" v-else>
      <span>Нет занятий</span>
    </div>
    <div
      class="personal-schedule-footer"
      v-if="
        this.$cookie.get('internalName') !== 'almost_teacher' &&
        this.$cookie.get('internalName') !== 'almost_methodologist'
      "
    >
      <span @click="$router.push('/monolit/Schedule')">Перейти к расписанию</span>
      <img src="../../../assets/img/ES/black_arrow.svg" alt="arrow" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PersonalSchedule',
  props: {
    lessons: Array,
    type: String
  }
}
</script>

<style scoped lang="scss">
@import '../../../styleVariables';

.personal-schedule {
  background-color: $main-white;
  box-shadow: 1px 0px 20px -7px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  height: 520px;
  padding: 17px 24px;
  color: $main-dark;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 44px;

    &__name {
      font-size: 18px;
    }
    &__tags {
      display: flex;

      .remove,
      .test-done,
      .empty,
      .done,
      .test,
      .lesson {
        padding: 7px;
        border-radius: 5px;
        font-size: 14px;
        display: flex;
        align-items: center;
        img {
          margin-right: 8px;
        }
      }

      .done {
        background-color: $lesson-ended-tag;
        margin-right: 14px;
        width: 181px;
      }
      .test-done {
        background-color: $lesson-ended-tag;
        margin-right: 14px;
        width: 210px;
      }
      .test {
        background-color: $test-tag;
        margin-right: 14px;
        width: 81px;
      }
      .lesson {
        background-color: $regular-lesson-tag;
        width: 102px;
      }
      .empty {
        background-color: $empty-card-tag;
        width: 211px;
        margin-right: 14px;
      }
      .remove {
        background-color: $empty-card-tag;
        width: 190px;
        margin-right: 14px;
      }
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    flex-wrap: wrap;
    height: 360px;
    margin-bottom: 24px;
    overflow-x: auto;

    .card {
      width: 215px;
      height: 100px;
      background-color: $main-white;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16) !important;
      border-radius: 4px;
      margin-bottom: 14px;
      margin-right: 14px;
      cursor: pointer;
      .empty,
      .done,
      .test,
      .lesson,
      .studentCard {
        width: 100%;
        height: 4px;
        border-radius: 4px 4px 0px 0px;
      }
      .done {
        background-color: $lesson-ended;
      }
      .test {
        background-color: $test;
      }
      .lesson {
        background-color: $regular-lesson;
      }
      .empty {
        background-color: $empty-card;
      }
      .studentCard {
        background: $regular-lesson;
      }

      &-content {
        padding: 5px 7px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 14px;
        &__time {
          display: flex;
          align-items: center;
          img {
            margin-right: 5px;
          }
          span {
            margin-top: 2px;
          }
        }
      }
    }
  }

  &-empty {
    width: 100%;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-size: 18px;
      color: #c5cee0;
    }
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    span {
      font-size: 14px;
      margin-right: 7px;
    }
  }
}
</style>
