<template>
  <div class="statistic-filter">
    <div class="label">Выводить статистику с</div>
    <div class="date">
      <date-pick
        v-model="startDate"
        :format="'YYYY-MM-DD'"
        :displayFormat="'DD.MM.YYYY'"
        :inputAttributes="{ readonly: true }"
        :clearable="false"
        :class="{
          'error-date': new Date(startDate) > new Date(endDate) && endDate && startDate
        }"
        @input="filter"
      />
    </div>
    <div class="label">по</div>
    <div class="date">
      <date-pick
        v-model="endDate"
        :format="'YYYY-MM-DD'"
        :displayFormat="'DD.MM.YYYY'"
        :inputAttributes="{ readonly: true }"
        :clearable="false"
        :class="{
          'error-date': new Date(startDate) > new Date(endDate) && endDate && startDate
        }"
        @input="filter"
      />
    </div>
  </div>
</template>

<script>
import DatePick from 'vue-date-pick'
import 'vue-date-pick/dist/vueDatePick.css'
import scriptMixins from '@/components/scriptMixins/scriptMixins'

export default {
  name: 'StatisticFilter',
  components: { DatePick },
  mixins: [scriptMixins],
  data() {
    return {
      startDate: null,
      endDate: null
    }
  },
  mounted() {
    this.setDefaultDate()
  },
  methods: {
    /**
     * Форматирование дат и емит на фильтрацию статистики
     */
    filter() {
      this.endDate = this.endDate.replaceAll('.', '-')
      this.startDate = this.startDate.replaceAll('.', '-')
      if (new Date(this.startDate) <= new Date(this.endDate)) {
        this.$emit('filter', this.formatDate(this.startDate), this.formatDate(this.endDate))
      }
    },

    /**
     * Установка начального значения для фильра по датам (на сегодня)
     */
    setDefaultDate() {
      let today = this.countUserTimeByTimeZone(this.$cookie.get('userTimeZone'))
      this.startDate = `${new Date(today).getFullYear()}.${
        new Date(today).getMonth() + 1 < 10
          ? '0' + (new Date(today).getMonth() + 1)
          : new Date(today).getMonth() + 1
      }.${
        new Date(today).getDate() < 10 ? '0' + new Date(today).getDate() : new Date(today).getDate()
      }`
      this.endDate = this.startDate
    },
    formatDate(date) {
      if (date.indexOf('.') === -1) {
        return date
      }
      return `${date.split('.')[0]}-${date.split('.')[1]}-${date.split('.')[2]}`
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../../styleVariables';

.statistic-filter {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $main-dark;
  .date {
    margin-right: 12px;
    /deep/ input {
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
      border-radius: 4px;
      background-color: $main-white;
      outline: none;
      height: 42px;
      width: 160px;
      padding: 9px 11px;
      font-size: 16px;
      line-height: 24px;
      color: $main-dark;
      border: 0.5px solid #e8edf3;
    }
    /deep/ .vdpClearInput {
      display: none;
    }
    .error-date {
      /deep/ input {
        border-color: $red !important;
      }
    }
  }
  .label {
    font-size: 16px;
    margin-right: 12px;
  }
}
</style>
