<template>
  <div class="modal-background" @mousedown.self="hideModal()">
    <div class="modal-container">
      <div class="message">
        <span>Изменить время урока</span>
      </div>
      <div class="modal-body">
        <div class="date-select">
          <span>Выберите дату</span>
          <vue-select
            v-model="lessonDate"
            :clearable="true"
            style="width: 260px"
            @open="getDates"
            :options="lessonDates"
            @input="getLessonHours"
          >
          </vue-select>
        </div>
        <div class="time-select">
          <span>Выберите время</span>
          <vue-select
            v-model="selectedHour"
            :clearable="true"
            style="width: 260px"
            :options="lessonHours"
            @open="getDates"
            @input="getDatesForHours"
          >
          </vue-select>
        </div>
      </div>
      <label class="replace-all" v-if="typeId !== 38">
        <input type="checkbox" v-model="updateAll" />
        <span> Перенести все занятия с этого времени </span>
      </label>
      <div class="controls-btns">
        <button class="hide-modal-btn" @click="hideModal">Отменить</button>
        <button
          class="send-complaint-btn"
          :disabled="!selectedHour || !lessonDate"
          @click="updateTime"
        >
          Сохранить
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { domain, MicroServices } from '@/globalVariables'
import VueSelect from 'vue-select'
import scriptMixins from '@/components/scriptMixins/scriptMixins'

export default {
  name: 'LessonTimeChangeModal',
  mixins: [scriptMixins],
  props: ['lessonId', 'typeId', 'role', 'studentUuid'],
  components: { VueSelect },
  data() {
    return {
      complaintText: '',
      lessonDate: null,
      lessonDates: [],
      lessonHours: [],
      lessonTime: null,
      selectedHour: null,
      updateAll: false
    }
  },
  mounted() {},
  methods: {
    hideModal() {
      this.$emit('hideTimeChangeModal')
    },

    /**
     * Изменить время урока
     */
    updateTime() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Lesson/transferLesson`,
        method: 'POST',
        data: [
          {
            lesson_id: this.lessonId,
            transfer_date: `${this.lessonDate} ${this.selectedHour}`,
            change_lesson_time: Number(this.updateAll)
          }
        ]
      })
        .then(() => {
          this.successAlert('Занятие перенесено!')
          this.hideModal()
          this.$emit('updateLessonInfo')
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Получить все возможные часы для занятия (для которых есть методисты/препродаватели)
     */
    getAllHours() {
      this.lessonHours = []
      this.lessonTime.forEach((item) => {
        item.hours.forEach((hour) => {
          if (!this.lessonHours.filter((elem) => elem === this.formatHour(hour)).length) {
            this.lessonHours.push(this.formatHour(hour))
          }
        })
      })
      this.lessonHours.sort()
    },

    /**
     * Получить все возможные даты для занятий в выбранный час
     */
    getDatesForHours() {
      this.lessonDates = []
      this.lessonTime.forEach((item) => {
        item.hours.forEach((hour) => {
          if (this.formatHour(hour) === this.selectedHour) {
            this.lessonDates.push(item.date)
          }
        })
      })
    },

    /**
     * Получить все возможные чаты и даты для занятий
     */
    getDates() {
      if (!this.selectedHour) {
        let dates = this.getWeekPeriod()
        axios({
          url: `${domain}/${MicroServices[0].prefix}/WorkTime/getUserWorkTime`,
          method: 'GET',
          params: {
            begin_date: dates.today,
            end_date: dates.next,
            lesson_id: this.lessonId,
            student_uuid: this.studentUuid,
            roles: JSON.stringify([this.role])
          }
        })
          .then((response) => {
            this.lessonTime = response.data.data
            this.lessonDates = this.lessonTime.map((item) => item.date)
            if (!this.lessonDate) {
              this.getAllHours()
            }
          })
          .catch((error) => this.errorAlert(error))
      }
    },

    /**
     * Получить все возможные часы на выбранную дату
     */
    getLessonHours() {
      if (this.lessonDate) {
        this.lessonHours = this.lessonTime
          .find((item) => item.date === this.lessonDate)
          .hours.map((hour) => {
            return this.formatHour(hour)
          })
        if (!this.lessonHours.filter((item) => item === this.selectedHour).length) {
          this.selectedHour = null
        }
      } else {
        this.getAllHours()
      }
    },

    /**
     * Получить период за который необходимо получить возможные часы и даты
     * @returns {{next: string, today: string}}
     */
    getWeekPeriod() {
      let today = this.countUserTimeByTimeZone(this.$cookie.get('userTimeZone'))
      let nextWeekDay = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7)
      return {
        today: `${today.getFullYear()}-${
          today.getMonth() + 1 < 10 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1
        }-${today.getDate() < 10 ? '0' + today.getDate() : today.getDate()} ${
          today.getHours() + 1 < 10 ? '0' + (today.getHours() + 1) : today.getHours() + 1
        }:00:00`,
        next: `${nextWeekDay.getFullYear()}-${
          nextWeekDay.getMonth() + 1 < 10
            ? '0' + (nextWeekDay.getMonth() + 1)
            : nextWeekDay.getMonth() + 1
        }-${nextWeekDay.getDate() < 10 ? '0' + nextWeekDay.getDate() : nextWeekDay.getDate()}`
      }
    },
    formatHour(hour) {
      return `${Number(hour) < 10 ? '0' + Number(hour) : Number(hour)}:00:00`
    }
  },
  computed: {},
  watch: {},
  validations: {}
}
</script>

<style scoped lang="scss">
@import './src/styleVariables';
.modal-background {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.3);
  animation: fade-in 0.3s;
  .modal-container {
    background-color: $main-white;
    border-radius: 8px;
    padding: 14px 24px;
    min-width: 558px;
    min-height: 257px;
    display: flex;
    flex-direction: column;
    .message {
      display: flex;
      flex-direction: column;
      width: 100%;
      span {
        font-size: 18px;
        margin-bottom: 12px;
      }
      textarea {
        border: 1px solid $light-gray;
        box-sizing: border-box;
        border-radius: 8px;
        min-height: 136px;
        height: auto;
        width: 100%;
        margin: 0 auto;
        padding: 6px 11px;
        outline: none;
        font-family: $font-regular;
      }
      textarea:focus {
        border: 1px solid $bright-blue;
      }
      textarea::placeholder {
        font-size: 14px;
        color: $light-gray;
      }
    }
    .modal-body {
      display: flex;
      padding: 24px 14px;
      .date-select {
        margin-right: 12px;
      }
    }
    .replace-all {
      display: flex;
      align-items: center;
      cursor: pointer;
      input {
        margin-right: 6px;
        cursor: pointer;
      }
    }
    .controls-btns {
      display: flex;
      justify-content: flex-end;
      .send-complaint-btn {
        margin-left: 15px;
        border: none;
        background-color: $bright-blue;
        border-radius: 4px;
        padding: 5px 21px;
        color: $main-white;
        outline: none;
      }
      .hide-modal-btn {
        border: 1px solid $bright-blue;
        border-radius: 4px;
        padding: 5px 21px;
        color: $bright-blue;
        outline: none;
      }
    }
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
