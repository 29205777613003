<template>
  <div>
    <li class="last-lesson-card-header" @click="showCard">
      <div class="arrow">
        <svg
          width="10"
          height="6"
          viewBox="0 0 10 6"
          fill="#8F9BB3"
          xmlns="http://www.w3.org/2000/svg"
          :style="arrowPosition"
        >
          <path
            d="M8.1958 0.291505C8.52095 -0.033645 9.04795 -0.0341907 9.37378 0.290286C9.70055 0.615714 9.7011 1.1446 9.375 1.4707L5 5.8457L0.625001 1.4707C0.298897 1.1446 0.299445 0.615714 0.626223 0.290286C0.952046 -0.0341908 1.47905 -0.033645 1.8042 0.291505L5 3.4873L8.1958 0.291505Z"
            fill="#8F9BB3"
          />
        </svg>
      </div>
      <h4 class="card-title">
        {{ lessonInfo.sequence_number ? `Занятие №${lessonInfo.sequence_number}` : 'Занятие' }}
      </h4>
    </li>
    <b-collapse class="card-body" :visible="isCardShowing">
      <div v-if="showLessonCard">
        <div
          class="card-container"
          :class="{
            studentCard: userRole === 'student',
            'card-container--red': validationError
          }"
          v-if="!glossarySelected"
        >
          <div class="card-header-container">
            <div class="lesson-details">
              <div class="lesson-date">
                <img src="../../../assets/img/icons/calendar-dark.svg" />
                <span>{{ formatDate(lessonInfo.date) }}</span>
              </div>
              <div class="lesson-time">
                <img src="../../../assets/img/icons/alarm.svg" />
                <span>{{ formatTime(lessonInfo.hour) }}</span>
              </div>
            </div>
            <div class="arbitration-status" v-if="lessonInfo.in_arbitration">
              <img
                src="../../../assets/img/ES/error_outline.svg"
                alt="arbitration"
                v-b-tooltip="`Арбитраж спора (${lessonInfo.kls_complaint_status_name})`"
              />
            </div>
          </div>
          <div class="lesson-link-container" v-if="statusCode === 'LESSON_PLANNED'">
            <label>Ссылка на урок:</label>
            <input
              type="text"
              v-if="
                editLessonCardMode &&
                ($cookie.get('internalName') === 'manager' ||
                  $cookie.get('internalName') === 'admin')
              "
              v-model="linkToZoom"
            />
            <a
              :href="
                lessonInfo.link_to_zoom.indexOf('http') !== -1
                  ? lessonInfo.link_to_zoom
                  : `http://${lessonInfo.link_to_zoom}`
              "
              target="_blank"
              v-else-if="lessonInfo.link_to_zoom"
              class="lesson-link"
              >{{ lessonInfo.link_to_zoom }}</a
            >
            <span v-else-if="!lessonInfo.link_to_zoom">Ссылка недоступна</span>
          </div>
          <div class="card-members">
            <div class="card-member-name" v-if="$cookie.get('internalName') !== 'student'">
              <span class="label" v-if="lessonInfo.student_name || lessonInfo.student_short_name"
                >Student Name</span
              >
              <span
                class="name"
                v-if="
                  $cookie.get('internalName') === 'manager' ||
                  $cookie.get('internalName') === 'admin'
                "
                >{{ lessonInfo.student_name }}</span
              >
              <span
                class="name"
                v-if="
                  $cookie.get('internalName') !== 'manager' &&
                  $cookie.get('internalName') !== 'admin'
                "
                >{{ lessonInfo.student_short_name }}</span
              >
            </div>
            <div
              class="card-member-name"
              v-if="
                ((typeCode !== 'PLACEMENT_TEST' && typeCode !== 'CHECK_UP') ||
                  $cookie.get('internalName') === 'student') &&
                $cookie.get('internalName') !== 'teacher' &&
                $cookie.get('internalName') !== 'methodologist'
              "
            >
              <span
                class="label"
                v-show="
                  lessonInfo.teacher_name ||
                  lessonInfo.teacher_short_name ||
                  teacher ||
                  ($cookie.get('internalName') === 'student' && lessonInfo.methodologist_short_name)
                "
                >Teacher Name</span
              >
              <vue-select
                v-if="
                  editLessonCardMode &&
                  status === 43 &&
                  ($cookie.get('internalName') === 'manager' ||
                    $cookie.get('internalName') === 'admin')
                "
                v-model="teacher"
                :clearable="false"
                style="width: 260px"
                @open="getTeachers(lessonInfo.date, lessonInfo.student_uuid)"
                :options="teachers"
              >
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    <span>{{ option.full_name }}</span>
                  </div>
                </template>
                <template slot="selected-option">
                  <div class="selected d-center">
                    {{ teacher.full_name }}
                  </div>
                </template>
              </vue-select>
              <span
                class="name"
                v-else-if="
                  $cookie.get('internalName') === 'manager' ||
                  $cookie.get('internalName') === 'admin'
                "
                >{{ lessonInfo.teacher_name }}</span
              >
              <span
                class="name"
                v-else-if="
                  $cookie.get('internalName') !== 'manager' &&
                  $cookie.get('internalName') !== 'admin' &&
                  lessonInfo.teacher_short_name
                "
                >{{ lessonInfo.teacher_short_name }}</span
              >
              <span
                class="name"
                v-else-if="
                  $cookie.get('internalName') === 'student' && lessonInfo.methodologist_short_name
                "
                >{{ lessonInfo.methodologist_short_name }}</span
              >
            </div>
            <div
              class="card-member-name"
              v-if="
                $cookie.get('internalName') !== 'student' &&
                $cookie.get('internalName') !== 'methodologist' &&
                (typeCode === 'PLACEMENT_TEST' ||
                  typeCode === 'TEACHER_TEST' ||
                  typeCode === 'CHECK_UP')
              "
            >
              <span class="label">Methodist Name</span>
              <vue-select
                v-if="
                  editLessonCardMode &&
                  statusCode === 'LESSON_PLANNED' &&
                  ($cookie.get('internalName') === 'manager' ||
                    $cookie.get('internalName') === 'admin')
                "
                v-model="methodist"
                :clearable="false"
                style="width: 260px"
                @open="getMethodists(lessonInfo.date)"
                :options="methodists"
              >
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    <span>{{ option.full_name }}</span>
                  </div>
                </template>
                <template slot="selected-option">
                  <div class="selected d-center">
                    {{ methodist.full_name }}
                  </div>
                </template>
              </vue-select>
              <span
                class="name"
                v-else-if="
                  $cookie.get('internalName') === 'manager' ||
                  $cookie.get('internalName') === 'admin'
                "
                >{{ lessonInfo.methodologist_name }}</span
              >
              <span
                class="name"
                v-else-if="
                  $cookie.get('internalName') !== 'manager' &&
                  $cookie.get('internalName') !== 'admin'
                "
                >{{ lessonInfo.methodologist_short_name }}</span
              >
            </div>
          </div>
          <div class="card-status-container">
            <div class="card-status">
              <span>Status</span>
              <div
                v-if="!removeStatus"
                :class="{
                  lessonFinished: statusCode === 'LESSON_ENDED',
                  lessonPlanned: statusCode === 'LESSON_PLANNED',
                  lessonMissedByStudent: statusCode === 'SKIPPED_STUDENT',
                  lessonMissedByStudentWithReason: statusCode === 'SKIPPED_STUDENT_WITH_REASON',
                  lessonMissedByTeacher: statusCode === 'SKIPPED_TEACHER',
                  lessonCanceled: statusCode === 'CANCELLED'
                }"
              >
                {{ statusName }}
                <img
                  v-if="
                    editLessonCardMode &&
                    ($cookie.get('internalName') === 'manager' ||
                      $cookie.get('internalName') === 'admin')
                  "
                  @click="setRemoveStatus(true)"
                  src="../../../assets/img/ES/close_big.svg"
                  alt="close"
                />
              </div>
              <div class="choose-status" v-if="removeStatus" v-click-outside="setDefaultStatus">
                <statuses @chooseStatus="changeStatus" :currentType="typeCode"
                          :currentStatus="statusCode" />
              </div>
            </div>
            <div class="card-type" v-if="$cookie.get('internalName') !== 'student'">
              <span>Type</span>
              <div
                v-if="!removeType"
                :class="{
                  regularLesson:
                    typeCode === 'REGULAR' || typeCode === 'TEACHER_TEST_REGULAR_LESSON',
                  teacherTesting: typeCode === 'TEACHER_TEST',
                  placementTest: typeCode === 'PLACEMENT_TEST',
                  checkUpLesson: typeCode === 'CHECK_UP',
                  progressTest:
                    typeCode === 'CHECK_PROGRESS' ||
                    typeCode === 'TEACHER_TEST_PROGRESS_TEST_LESSON'
                }"
              >
                {{ typeName }}
                <img
                  @click="setRemoveType(true)"
                  v-if="editLessonCardMode"
                  src="../../../assets/img/ES/close_big.svg"
                  alt="close"
                />
              </div>
              <div class="choose-type" v-if="removeType" v-click-outside="setDefaultType">
                <types @chooseType="changeType" />
              </div>
            </div>
          </div>

          <!-- ЕСЛИ НЕ СТУДЕНТ -->
          <div class="card-info-container" v-if="$cookie.get('internalName') !== 'student'">
            <div class="first-table">
              <div class="table-cell">
                <span class="table-cell-label">Level</span>
                <vue-select
                  class="select"
                  v-if="
                    editLessonCardMode &&
                    ($cookie.get('internalName') === 'manager' ||
                      $cookie.get('internalName') === 'admin')
                  "
                  v-model="level"
                  :clearable="false"
                  :options="levels"
                  @input="getCEFRByLevel"
                  @open="getLevels"
                >
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      <span>{{ option.name }}</span>
                    </div>
                  </template>
                  <template slot="selected-option">
                    <div class="selected d-center">
                      {{ level.name }}
                    </div>
                  </template>
                </vue-select>
                <span class="table-cell-name" v-else-if="lessonInfo.kls_level_name !== null">{{
                  lessonInfo.kls_level_name
                }}</span>
                <span class="table-cell-name" v-else-if="lessonInfo.kls_level_name === null">
                  no
                </span>
              </div>
              <div class="table-cell" v-if="lessonTypeCode === 'PLACEMENT_TEST'">
                <span class="table-cell-label">CEFR</span>
                <vue-select
                  class="select"
                  v-if="
                    editLessonCardMode &&
                    ($cookie.get('internalName') === 'manager' ||
                      $cookie.get('internalName') === 'admin' ||
                      $cookie.get('internalName') === 'methodologist')
                  "
                  v-model="CEFR"
                  :clearable="false"
                  :options="CEFRs"
                  @input="getLevelByCEFR"
                  @open="getCEFRLevels"
                >
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      <span>{{ option.name }}</span>
                    </div>
                  </template>
                  <template slot="selected-option">
                    <div class="selected d-center">
                      {{ CEFR.name }}
                    </div>
                  </template>
                </vue-select>
                <span
                  class="table-cell-name"
                  v-else-if="lessonInfo.kls_europe_level_name !== null"
                  >{{ lessonInfo.kls_europe_level_name }}</span
                >
                <span class="table-cell-name" v-else-if="lessonInfo.kls_europe_level_name === null">
                  no
                </span>
              </div>
              <div class="table-cell">
                <span class="table-cell-label">Revision steps</span>
                <input
                  type="text"
                  v-if="
                    editLessonCardMode &&
                    ($cookie.get('internalName') === 'manager' ||
                      $cookie.get('internalName') === 'admin' ||
                      $cookie.get('internalName') === 'methodologist')
                  "
                  v-model="revisionSteps"
                  @input="validateRevisionSteps"
                />
                <span class="table-cell-name" v-else-if="lessonInfo.revision_step !== null">{{
                  lessonInfo.revision_step
                }}</span>
                <span class="table-cell-name" v-else-if="lessonInfo.revision_step === null">
                  no
                </span>
              </div>

              <div class="table-cell" v-if="lessonTypeCode === 'PLACEMENT_TEST'">
                <span class="table-cell-label">Revision</span>
                <span class="table-cell-name" v-if="lessonInfo.revision !== null">{{
                  JSON.parse(lessonInfo.revision).join(', ')
                }}</span>
                <span class="table-cell-name" v-else-if="lessonInfo.revision === null"> no </span>
              </div>
              <div class="table-cell">
                <span class="table-cell-label">Lesson number</span>
                <input
                  type="text"
                  v-if="
                    editLessonCardMode &&
                    ($cookie.get('internalName') === 'manager' ||
                      $cookie.get('internalName') === 'admin')
                  "
                  v-model="lessonNumber"
                />
                <span class="table-cell-name" v-else-if="lessonInfo.lesson_number !== null">{{
                  lessonInfo.lesson_number
                }}</span>
                <span class="table-cell-name" v-else-if="lessonInfo.lesson_number === null">
                  no
                </span>
              </div>
              <div class="table-cell" v-if="lessonTypeCode !== 'PLACEMENT_TEST'">
                <span class="table-cell-label">Last taught page</span>
                <input
                  type="text"
                  v-if="
                    editLessonCardMode &&
                    ($cookie.get('internalName') === 'manager' ||
                      $cookie.get('internalName') === 'admin')
                  "
                  v-model="lastPage"
                  @input="calculateNewPages(), validateNewPages()"
                />
                <span class="table-cell-name" v-else-if="lessonInfo.last_taught_page !== null">{{
                  lessonInfo.last_taught_page
                }}</span>
                <span class="table-cell-name" v-else-if="lessonInfo.last_taught_page === null">
                  no
                </span>
              </div>
              <div class="table-cell" v-if="lessonTypeCode !== 'PLACEMENT_TEST'">
                <span class="table-cell-label">New words</span>
                <input
                  type="text"
                  v-if="
                    editLessonCardMode &&
                    ($cookie.get('internalName') === 'manager' ||
                      $cookie.get('internalName') === 'admin')
                  "
                  v-model="newWords"
                  @input="validateNewWords()"
                />
                <span class="table-cell-name" v-else-if="lessonInfo.new_words !== null">{{
                  lessonInfo.new_words
                }}</span>
                <span class="table-cell-name" v-else-if="lessonInfo.new_words === null"> no </span>
              </div>
              <div class="table-cell" v-if="lessonTypeCode !== 'PLACEMENT_TEST'">
                <span class="table-cell-label">Grammar</span>
                <input
                  type="text"
                  v-if="
                    editLessonCardMode &&
                    ($cookie.get('internalName') === 'manager' ||
                      $cookie.get('internalName') === 'admin')
                  "
                  v-model="grammar"
                />
                <span class="table-cell-name" v-else-if="lessonInfo.grammar !== null">{{
                  lessonInfo.grammar
                }}</span>
                <span class="table-cell-name" v-else-if="lessonInfo.grammar === null"> no </span>
              </div>
              <div class="table-cell" v-if="lessonTypeCode !== 'PLACEMENT_TEST'">
                <span class="table-cell-label">Reading</span>
                <input
                  type="text"
                  v-if="
                    editLessonCardMode &&
                    ($cookie.get('internalName') === 'manager' ||
                      $cookie.get('internalName') === 'admin')
                  "
                  v-model="reading"
                />
                <span class="table-cell-name" v-else-if="lessonInfo.reading !== null">{{
                  lessonInfo.reading
                }}</span>
                <span class="table-cell-name" v-else-if="lessonInfo.reading === null"> no </span>
              </div>
              <div class="table-cell" v-if="lessonTypeCode !== 'PLACEMENT_TEST'">
                <span class="table-cell-label">Other</span>
                <input
                  type="text"
                  v-if="
                    editLessonCardMode &&
                    ($cookie.get('internalName') === 'manager' ||
                      $cookie.get('internalName') === 'admin')
                  "
                  v-model="other"
                />
                <span class="table-cell-name" v-else-if="lessonInfo.other !== null">{{
                  lessonInfo.other
                }}</span>
                <span class="table-cell-name" v-else-if="lessonInfo.other === null"> no </span>
              </div>
              <div class="table-cell" v-if="lessonTypeCode !== 'PLACEMENT_TEST'">
                <span class="table-cell-label">Student comment</span>
                <textarea v-if="editLessonCardMode" v-model="studentComment"></textarea>
                <span class="table-cell-name" v-else-if="lessonInfo.comment !== null">{{
                  lessonInfo.comment
                }}</span>
                <span class="table-cell-name" v-else-if="lessonInfo.comment === null"> no </span>
              </div>
            </div>
            <div class="second-table" v-if="lessonTypeCode !== 'PLACEMENT_TEST'">
              <div class="table-cell">
                <span class="table-cell-label">CEFR</span>
                <vue-select
                  class="select"
                  v-if="
                    editLessonCardMode &&
                    ($cookie.get('internalName') === 'manager' ||
                      $cookie.get('internalName') === 'admin')
                  "
                  v-model="CEFR"
                  :clearable="false"
                  :options="CEFRs"
                  @input="getLevelByCEFR"
                  @open="getCEFRLevels"
                >
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      <span>{{ option.name }}</span>
                    </div>
                  </template>
                  <template slot="selected-option">
                    <div class="selected d-center">
                      {{ CEFR.name }}
                    </div>
                  </template>
                </vue-select>
                <span
                  class="table-cell-name"
                  v-else-if="lessonInfo.kls_europe_level_name !== null"
                  >{{ lessonInfo.kls_europe_level_name }}</span
                >
                <span class="table-cell-name" v-else-if="lessonInfo.kls_europe_level_name === null">
                  no
                </span>
              </div>
              <div class="table-cell">
                <span class="table-cell-label">Percentage of knowledge</span>
                <input
                  type="text"
                  v-if="
                    editLessonCardMode &&
                    ($cookie.get('internalName') === 'manager' ||
                      $cookie.get('internalName') === 'admin' ||
                      ($cookie.get('internalName') === 'teacher' &&
                        (lessonTypeCode === 'CHECK_PROGRESS' ||
                          lessonTypeCode === 'TEACHER_TEST_PROGRESS_TEST_LESSON')))
                  "
                  v-model="percentageOfKnowledge"
                />
                <span
                  class="table-cell-name"
                  v-else-if="lessonInfo.percentage_of_knowledge !== null"
                  >{{ lessonInfo.percentage_of_knowledge }}</span
                >
                <span
                  class="table-cell-name"
                  v-else-if="lessonInfo.percentage_of_knowledge === null"
                >
                  no
                </span>
              </div>
              <div class="table-cell">
                <span class="table-cell-label">Revision</span>
                <span class="table-cell-name" v-if="lessonInfo.revision !== null">{{
                  JSON.parse(lessonInfo.revision).join(', ')
                }}</span>
                <span class="table-cell-name" v-else-if="lessonInfo.revision === null"> no </span>
              </div>
              <div class="table-cell">
                <span class="table-cell-label">New pages</span>
                <span class="table-cell-name">{{ newPages || 'no' }}</span>
              </div>
              <div class="table-cell">
                <span class="table-cell-label">Last taught (new) word</span>
                <input
                  type="text"
                  v-if="
                    editLessonCardMode &&
                    ($cookie.get('internalName') === 'manager' ||
                      $cookie.get('internalName') === 'admin')
                  "
                  v-model="lastWord"
                />
                <span class="table-cell-name" v-else-if="lessonInfo.last_taught_word !== null">{{
                  lessonInfo.last_taught_word
                }}</span>
                <span class="table-cell-name" v-else-if="lessonInfo.last_taught_word === null">
                  no
                </span>
              </div>
              <div class="table-cell">
                <span class="table-cell-label">Listening</span>
                <input
                  type="text"
                  v-if="
                    editLessonCardMode &&
                    ($cookie.get('internalName') === 'manager' ||
                      $cookie.get('internalName') === 'admin')
                  "
                  v-model="listening"
                />
                <span class="table-cell-name" v-else-if="lessonInfo.listening !== null">{{
                  lessonInfo.listening
                }}</span>
                <span class="table-cell-name" v-else-if="lessonInfo.listening === null"> no </span>
              </div>
              <div class="table-cell">
                <span class="table-cell-label">Writing</span>
                <input
                  type="text"
                  v-if="
                    editLessonCardMode &&
                    ($cookie.get('internalName') === 'manager' ||
                      $cookie.get('internalName') === 'admin')
                  "
                  v-model="writing"
                />
                <span class="table-cell-name" v-else-if="lessonInfo.writing !== null">{{
                  lessonInfo.writing
                }}</span>
                <span class="table-cell-name" v-else-if="lessonInfo.writing === null"> no </span>
              </div>
              <div class="table-cell">
                <span class="table-cell-label">Homework</span>
                <input type="text" v-if="editLessonCardMode" v-model="homework" />
                <span class="table-cell-name" v-else-if="lessonInfo.homework !== null">{{
                  lessonInfo.homework
                }}</span>
                <span class="table-cell-name" v-else-if="lessonInfo.homework === null"> no </span>
              </div>
              <div class="table-cell">
                <span class="table-cell-label">Link to record</span>
                <input
                  type="text"
                  v-if="
                    editLessonCardMode &&
                    ($cookie.get('internalName') === 'manager' ||
                      $cookie.get('internalName') === 'admin')
                  "
                  v-model="link"
                />
                <span class="table-cell-name" v-else-if="lessonInfo.link_to_record !== null">
                  <a
                    :href="
                      lessonInfo.link_to_record.indexOf('http') !== -1
                        ? lessonInfo.link_to_record
                        : 'http://' + lessonInfo.link_to_record
                    "
                  >
                    {{ lessonInfo.link_to_record }}</a
                  ></span
                >
                <span class="table-cell-name" v-else-if="lessonInfo.link_to_record === null">
                  no
                </span>
              </div>
            </div>
          </div>
          <!-- / ЕСЛИ НЕ СТУДЕНТ -->

          <!-- ЕСЛИ СТУДЕНТ -->
          <div class="student-card-info-container" v-if="$cookie.get('internalName') === 'student'">
            <div class="student-lesson-info-container">
              <div class="table-cell">
                <span class="table-cell-label">Lesson number</span>
                <span class="table-cell-name">{{ lessonInfo.lesson_number || 'no' }}</span>
              </div>
              <div class="table-cell">
                <span class="table-cell-label">New words</span>
                <span class="table-cell-name" v-if="lessonInfo.new_words !== null">{{
                  lessonInfo.new_words
                }}</span>
                <span class="table-cell-name" v-if="lessonInfo.new_words === null"> no </span>
              </div>
              <div class="table-cell">
                <span class="table-cell-label">Homework</span>
                <span class="table-cell-name" v-if="lessonInfo.homework !== null">{{
                  lessonInfo.homework
                }}</span>
                <span class="table-cell-name" v-if="lessonInfo.homework === null"> no </span>
              </div>
              <div class="table-cell">
                <span class="table-cell-label">Student comment</span>
                <span class="table-cell-name" v-if="lessonInfo.comment !== null">{{
                  lessonInfo.comment
                }}</span>
                <span class="table-cell-name" v-if="lessonInfo.comment === null"> no </span>
              </div>
            </div>
            <div
              class="btn-container"
              v-if="
                canClickButtonPush &&
                lessonInfo.kls_status_code === 'LESSON_PLANNED' &&
                !lessonInfo.is_teacher_skip &&
                !lessonInfo.in_arbitration
              "
            >
              <button @click="skipLessonByTeacher">Преподаватель не вышел на связь</button>
            </div>
          </div>
          <!-- / ЕСЛИ СТУДЕНТ -->
        </div>
        <div
          v-if="
            ($cookie.get('internalName') === 'student' ||
              $cookie.get('internalName') === 'manager' ||
              $cookie.get('internalName') === 'admin') &&
            studentComplaint &&
            !glossarySelected
          "
          class="student-complaint"
        >
          <div class="complaint-header">
            <span>Жалоба</span>
            <!--      <button @click="deleteComplaint"><img src="../../assets/img/icons/close_big.svg" /></button>-->
          </div>
          <div class="complaint-content-container">
            <!--          <div class="sender-avatar">-->
            <!--            <img-->
            <!--              src="../../assets/img/avatar-sample.jpg"-->
            <!--              alt="edit-icon"-->
            <!--              v-if="!studentComplaint.author_avatar_path"-->
            <!--            />-->
            <!--            <img-->
            <!--              :src="studentComplaint.author_avatar_path"-->
            <!--              v-if="studentComplaint.author_avatar_path"-->
            <!--            />-->
            <!--          </div>-->
            <div class="complaint-info">
              <span class="sender-name">{{ studentComplaint.author_full_name }}</span>
              <span class="complaint-text">{{ studentComplaint.reason }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="go-to-lesson-btn" v-if="userRole === 'admin' || userRole === 'manager'">
        <button @click="goToLessonCard">Перейти к карточке</button>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import axios from 'axios'
import { domain, MicroServices } from '@/globalVariables'
import VueSelect from 'vue-select'
import Statuses from '@/views/LessonCard/components/Statuses'
import Types from '@/views/LessonCard/components/Types'
import scriptMixins from '@/components/scriptMixins/scriptMixins'
import Swal from 'sweetalert2'

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'confirm-button',
    cancelButton: 'delete-button',
    title: 'title-small-class',
    input: 'input-class'
  },
  buttonsStyling: false
})

export default {
  name: 'LastLessonCard',
  mixins: [scriptMixins],
  props: {
    lessonInfo: Object
  },
  components: {
    Types,
    Statuses,
    VueSelect
  },
  directives: {
    ClickOutside
  },
  data() {
    return {
      lessonCardSelected: true,
      glossarySelected: false,
      userRole: this.$cookie.get('internalName'),
      openComplaintModal: false,
      lessonId: null,
      studentComplaint: [],
      complaintId: null,
      lessonTypeId: null,
      lessonTypeCode: null,
      typeCode: null,
      removeStatus: false,
      removeType: false,
      level: null,
      CEFR: null,
      lessonNumber: null,
      lastPage: null,
      newWords: null,
      grammar: null,
      reading: null,
      other: null,
      studentComment: null,
      percentageOfKnowledge: null,
      revision: null,
      revisionSteps: null,
      newPages: null,
      lastWord: null,
      listening: null,
      writing: null,
      homework: null,
      link: null,
      status: null,
      type: null,
      linkToZoom: null,
      teacher: null,
      methodist: null,
      statusName: null,
      statusCode: null,
      typeName: null,
      teachers: [],
      methodists: [],
      levels: [],
      CEFRs: [],
      cancelModalForTeacher: false,
      cancelModalForManager: false,
      timeChangeModal: false,
      isFilledCard: true,
      newLessonInfo: null,
      validationError: false,
      isCardShowing: false,
      arrowReverse: false
    }
  },
  mounted() {
    this.setData()
  },
  methods: {
    setDefaultStatus() {
      this.removeStatus = false
    },
    setRemoveStatus(value) {
      setTimeout(() => {
        this.removeStatus = value
      }, 10)
    },
    setDefaultType() {
      this.removeType = false
    },
    setRemoveType(value) {
      setTimeout(() => {
        this.removeType = value
      }, 10)
    },

    setData() {
      this.lessonId = this.lessonInfo.id
      this.lessonTypeId = this.lessonInfo.kls_type_id
      this.lessonTypeCode = this.lessonInfo.kls_type_code
      this.checkComplaints()
      this.setInitialState()
      this.newPages = this.lessonInfo.new_pages
      this.isFilledCard = true
      if (
        this.showCardToFill &&
        this.lessonInfo.previous_lesson_id !== null &&
        this.lessonHasStarted
      ) {
        this.isFilledCard = false
        this.newLessonInfo = this.lessonInfo
      }
    },

    /**
     * Показать карту предыдущего занятия
     */
    showCard() {
      this.isCardShowing = !this.isCardShowing
      this.arrowReverse = !this.arrowReverse
    },

    /**
     * Последсвия отмены занятия
     */
    lessonCanceled() {
      if (this.$cookie.get('internalName') === 'student') {
        this.$toasted.show('Занятие отменено', {
          type: 'success',
          duration: 2000
        })
      } else {
        this.$toasted.show('Заявка на отмену занятия отправлена', {
          type: 'success',
          duration: 2000
        })
      }
      this.$router.push('/monolit/Schedule')
      // this.getLessonInfo()
    },
    formatDate(time) {
      return time && time.slice(0, 10)
    },
    formatTime(time) {
      return time + ':00' + ' - ' + (time + 1) + ':00'
    },
    openModal() {
      this.openComplaintModal = true
    },
    openCancelModalForTeacher(id) {
      this.lessonId = id
      this.cancelModalForTeacher = true
    },
    openCancelModalForManager() {
      this.cancelModalForManager = true
    },
    openTimeChangeModal() {
      this.timeChangeModal = true
    },
    openLessonCard() {
      this.lessonCardSelected = true
      this.glossarySelected = false
    },
    openGlossary() {
      this.glossarySelected = true
      this.lessonCardSelected = false
    },

    /**
     * Преподаватель не вышел на связь
     */
    skipLessonByTeacher() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/LessonCard/skipLessonByTeacher`,
        method: 'PUT',
        data: [
          {
            lesson_card_id: this.lessonInfo.lesson_card_id
          }
        ]
      })
        .then(() => {
          this.$toasted.show('Ваша жалоба будет рассмотрена.', {
            type: 'success',
            duration: 5000
          })
          this.getLessonInfo()
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Получить информацию об уроке
     */
    getLessonInfo() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/LessonCard/getItem/${this.lessonInfo.previous_lesson_id}`,
        method: 'GET'
      })
        .then((response) => {
          this.lessonInfo = response.data.data
          this.lessonId = response.data.data.id
          this.lessonTypeId = response.data.data.kls_type_id
          this.lessonTypeCode = response.data.data.kls_type_code
          this.$root.$emit('setInitialStateInCardToFill')
          this.setInitialState()
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Получить жалобу на занятие
     */
    checkComplaints() {
      if (
        this.$cookie.get('internalName') === 'manager' ||
        this.$cookie.get('internalName') === 'student' ||
        this.$cookie.get('internalName') === 'admin'
      ) {
        axios({
          url: `${domain}/${MicroServices[0].prefix}/Complaint/getItems`,
          method: 'GET',
          params: {
            lesson_id: this.lessonId
          }
        })
          .then((response) => {
            this.studentComplaint = response.data.data.items[0]
            this.complaintId = response.data.data.items[0]?.id
          })
          .catch((error) => this.errorAlert(error))
      }
    },

    /**
     * Получить возможных преподавателей на текущее время занятия
     */
    getTeachers(date, studentUuid) {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Teacher/getSuitableUsers`,
        method: 'POST',
        data: [
          {
            date: date,
            user_uuid: studentUuid
          }
        ]
      })
        .then((response) => {
          this.teachers = response.data.data
        })
        .catch((error) => this.errorAlert(error))
    },

    /**
     * Получить список возможных методистов на текущее время зянятия
     */
    getMethodists(date) {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Methodologist/getSuitableUsers`,
        method: 'POST',
        data: [
          {
            date: date
          }
        ]
      })
        .then((response) => {
          this.methodists = response.data.data
        })
        .catch((error) => this.errorAlert(error))
    },

    /**
     * Получить уровни
     */
    getLevels() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Classifier/getEducationLevels`,
        method: 'GET',
        params: {
          _count: 20
        }
      })
        .then((response) => {
          this.levels = response.data.data.items
        })
        .catch((error) => this.errorAlert(error))
    },

    /**
     * Получить уровень при изменении CEFR
     */
    getLevelByCEFR() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Classifier/getSchoolLevelByEurope`,
        method: 'POST',
        data: [
          {
            kls_europe_level_id: this.CEFR.kls_europe_level_id || this.CEFR.id
          }
        ]
      })
        .then((response) => {
          this.level = response.data.data
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Получить CEFR уровни
     */
    getCEFRLevels() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Classifier/getEuropeLevels`,
        method: 'GET',
        params: {
          _count: 20
        }
      })
        .then((response) => {
          this.CEFRs = response.data.data.items
        })
        .catch((error) => this.errorAlert(error))
    },

    /**
     * Получить CEFR при изменении уровня
     */
    getCEFRByLevel() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Classifier/getEuropeLevelBySchool`,
        method: 'POST',
        data: [
          {
            kls_level_id: this.level.kls_level_id || this.level.id
          }
        ]
      })
        .then((response) => {
          this.CEFR = response.data.data
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Установить начальное значение для редактирования
     */
    setInitialState() {
      this.removeStatus = false
      this.removeType = false
      this.status = this.lessonInfo.kls_status_id
      this.type = this.lessonInfo.kls_type_id
      this.level = {
        kls_level_id: this.lessonInfo.kls_level_id,
        name: this.lessonInfo.kls_level_name
      }
      this.CEFR = {
        kls_europe_level_id: this.lessonInfo.kls_europe_level_id,
        name: this.lessonInfo.kls_europe_level_name
      }
      this.lessonNumber = this.lessonInfo.lesson_number
      this.lastPage = this.lessonInfo.last_taught_page
      this.newWords = this.lessonInfo.new_words
      this.grammar = this.lessonInfo.grammar
      this.reading = this.lessonInfo.reading
      this.other = this.lessonInfo.other
      this.studentComment = this.lessonInfo.comment
      this.percentageOfKnowledge = this.lessonInfo.percentage_of_knowledge
      this.revision = this.lessonInfo.revision
      this.newPages = this.lessonInfo.new_pages
      this.lastWord = this.lessonInfo.last_taught_word
      this.listening = this.lessonInfo.listening
      this.writing = this.lessonInfo.writing
      this.homework = this.lessonInfo.homework
      this.link = this.lessonInfo.link_to_record
      this.linkToZoom = this.lessonInfo.link_to_zoom
      this.teacher = {
        full_name: this.lessonInfo.teacher_name,
        id: this.lessonInfo.teacher_uuid
      }
      this.methodist = {
        full_name: this.lessonInfo.methodologist_name,
        id: this.lessonInfo.methodologist_uuid
      }
      this.statusName = this.lessonInfo.kls_status_name
      this.statusCode = this.lessonInfo.kls_status_code
      this.typeName = this.lessonInfo.kls_type_name
      this.typeCode = this.lessonInfo.kls_type_code
    },

    /**
     * Поменять тип занятия
     * @param id
     * @param code
     * @param name
     */
    changeType(id, code, name) {
      this.type = id
      this.typeName = name
      this.typeCode = code
      this.removeType = false
    },

    /**
     * Поменять статус занятия
     * @param id
     * @param code
     * @param name
     */
    changeStatus(id, code, name) {
      this.status = id
      this.statusName = name
      this.statusCode = code
      this.removeStatus = false
    },

    /**
     * Сохранить карточку
     */
    saveLessonCard() {
      let data = [
        {
          teacher_uuid: this.teacher.sync_uuid || this.teacher.id,
          methodologist_uuid: this.methodist.sync_uuid || this.methodist.id,
          kls_status_id: this.status,
          kls_type_id: this.type,
          kls_level_id: this.level.kls_level_id || this.level.id,
          kls_europe_level_id: this.CEFR.kls_europe_level_id || this.CEFR.id,
          percentage_of_knowledge: this.percentageOfKnowledge.toString(),
          lesson_number: this.lessonNumber,
          revision: this.revision,
          last_taught_page: this.lastPage,
          new_pages: this.newPages,
          new_words: this.newWords,
          last_taught_word: this.lastWord,
          grammar: this.grammar,
          listening: this.listening,
          reading: this.reading,
          writing: this.writing,
          other: this.other,
          homework: this.homework,
          comment: this.studentComment,
          link_to_zoom: this.linkToZoom,
          link_to_record: this.link
        }
      ]

      axios({
        url: `${domain}/${MicroServices[0].prefix}/LessonCard/update/${this.lessonInfo.id}`,
        method: 'PUT',
        data: data
      })
        .then(() => {
          if (
            this.statusCode === 'CANCELLED' ||
            this.statusCode === 'SKIPPED_TEACHER' ||
            this.statusCode === 'SKIPPED_STUDENT_WITH_REASON' ||
            this.statusCode === 'SKIPPED_STUDENT'
          ) {
            this.$router.push('/monolit/Schedule')
            return null
          }
          this.editLessonCardMode = false
          this.getLessonInfo()
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Отправить техническую проблему
     * @param id
     */
    techProblem(id) {
      swalWithBootstrapButtons
        .fire({
          title: `Подтвердите действие. <br> Заявка будет отправлена на арбитраж`,
          text: '',
          showCancelButton: true,
          confirmButtonText: 'Подтвердить',
          cancelButtonText: 'Отмена',
          reverseButtons: true
        })
        .then((r) => {
          if (r.value) {
            axios({
              url: `${domain}/${MicroServices[0].prefix}/Complaint/create`,
              method: 'POST',
              data: [
                {
                  lesson_id: id
                }
              ]
            })
              .then(() => {
                this.successAlert('Жалоба принята. Ждите связи с менеджером')
                this.getLessonInfo()
              })
              .catch((error) => {
                this.errorAlert(error)
              })
          }
        })
    },

    /**
     * Переход к выбранной карточке урока
     */
    goToLessonCard() {
      this.$router.push({ name: 'LessonCard', params: { lesson_id: this.lessonId } }, () => {
        this.showCard()
        this.$emit('goToLessonCard')
      })
    },

    /**
     * Подсчет поля New pages
     */
    calculateNewPages() {
      this.newPages =
        this.lastPage - (this.lessonInfo.prev_lesson_last_taught_page || 0) < 0
          ? 0
          : this.lastPage - (this.lessonInfo.prev_lesson_last_taught_page || 0)
    },
    /**
     * Валидация поля New pages
     */
    validateNewPages() {
      this.validationError = this.newPages < 0 || this.newPages > 12
    },
    /**
     * Валидация поля New words
     */
    validateNewWords() {
      this.validationError = this.newWords < 0 || this.newWords > 50
    },

    /**
     * Валидация поля Revision steps
     */
    validateRevisionSteps() {
      this.validationError = this.revisionSteps < 1 || this.revisionSteps > 50
    }
  },
  computed: {
    /**
     * Направление стрелки
     * @returns {string}
     */
    arrowPosition() {
      if (!this.arrowReverse) return 'transform: rotate(0deg);'
      else return 'transform: rotate(180deg);'
    },

    validation() {
      return this.removeType || this.removeStatus
    },

    /**
     * Показать картоку для заполнения
     */
    showCardToFill() {
      return (
        ((this.$cookie.get('internalName') === 'teacher' &&
          this.lessonInfo.kls_type_code !== 'PLACEMENT_TEST' &&
          this.lessonInfo.kls_type_code !== 'TEACHER_TEST' &&
          this.lessonInfo.kls_type_code !== 'TEACHER_FIRST_TEST' &&
          this.lessonInfo.kls_status_code !== 'SKIPPED_TEACHER' &&
          this.lessonInfo.kls_status_code !== 'SKIPPED_STUDENT_WITH_REASON' &&
          this.lessonInfo.kls_status_code !== 'SKIPPED_STUDENT' &&
          this.lessonInfo.kls_status_code !== 'CANCELLED') ||
          (this.$cookie.get('internalName') === 'methodologist' &&
            this.lessonInfo.kls_type_code !== 'REGULAR' &&
            this.lessonInfo.kls_type_code !== 'TEACHER_FIRST_TEST' &&
            this.lessonInfo.kls_type_code !== 'TEACHER_TEST' &&
            this.lessonInfo.kls_type_code !== 'TEACHER_TEST_REGULAR_LESSON' &&
            this.lessonInfo.kls_status_code !== 'SKIPPED_TEACHER' &&
            this.lessonInfo.kls_status_code !== 'SKIPPED_STUDENT_WITH_REASON' &&
            this.lessonInfo.kls_status_code !== 'SKIPPED_STUDENT' &&
            this.lessonInfo.kls_status_code !== 'CANCELLED')) &&
        !this.lessonInfo.has_filled_card &&
        !this.lessonInfo.in_arbitration &&
        this.lessonInfo.can_update
      )
    },

    /**
     * Показать заполненную карточку занятия
     */
    showLessonCard() {
      return (
        this.lessonTypeCode !== 'TEACHER_TEST' &&
        this.lessonTypeCode !== 'TEACHER_FIRST_TEST' &&
        (this.lessonInfo.previous_lesson_id !== null ||
          this.lessonInfo.has_filled_card ||
          this.lessonInfo.in_arbitration ||
          this.lessonInfo.kls_status_code === 'SKIPPED_TEACHER' ||
          this.lessonInfo.kls_status_code === 'SKIPPED_STUDENT_WITH_REASON' ||
          this.lessonInfo.kls_status_code === 'SKIPPED_STUDENT' ||
          this.lessonInfo.kls_status_code === 'CANCELLED' ||
          !this.lessonInfo.can_update ||
          !this.lessonHasStarted ||
          (this.$cookie.get('internalName') === 'teacher' &&
            (this.lessonInfo.kls_type_code === 'PLACEMENT_TEST' ||
              this.lessonInfo.kls_type_code === 'TEACHER_TEST')) ||
          this.$cookie.get('internalName') === 'student' ||
          this.$cookie.get('internalName') === 'manager' ||
          this.$cookie.get('internalName') === 'admin' ||
          (this.$cookie.get('internalName') === 'methodologist' &&
            this.lessonInfo.kls_type_code === 'TEACHER_TEST_REGULAR_LESSON'))
      )
    },

    /**
     * Показать кнопки управления карточкой
     */
    showControlButtons() {
      return (
        ((this.$cookie.get('internalName') === 'teacher' &&
          (this.lessonInfo.kls_status_code === 'LESSON_PLANNED' ||
            this.lessonInfo.kls_status_code === 'LESSON_ENDED')) ||
          this.$cookie.get('internalName') === 'manager' ||
          this.$cookie.get('internalName') === 'methodologist' ||
          this.$cookie.get('internalName') === 'admin') &&
        !this.editLessonCardMode &&
        this.$cookie.get('internalName') !== 'student' &&
        !this.lessonInfo.in_arbitration &&
        this.lessonInfo.can_update
      )
    },

    /**
     * Возвращает true если урок начался и еще не закончился (можно нажать кнопку "Преподаватель не вышел на связь")
     */
    canClickButtonPush() {
      let today = this.countUserTimeByTimeZone(this.$cookie.get('userTimeZone'))
      let lessonTime = new Date(this.lessonInfo.date.replaceAll('-', '/'))
      let lessonTimeEnd = new Date(this.lessonInfo.date.replaceAll('-', '/')).setHours(
        lessonTime.getHours() + 1
      )
      return today > lessonTime && today < new Date(lessonTimeEnd)
    },

    /**
     * Возвращает true если урок начался и прошло менее 25-ти часов с его начала (можно пожаловаться на занятие)
     */
    startLesson() {
      let today = this.countUserTimeByTimeZone(this.$cookie.get('userTimeZone'))
      let lessonTime = new Date(this.lessonInfo.date.replaceAll('-', '/'))
      let lessonEndTime = new Date(this.lessonInfo.date.replaceAll('-', '/')).setHours(
        new Date(this.lessonInfo.date.replaceAll('-', '/')).getHours() + 25
      )
      return today > lessonTime && today < new Date(lessonEndTime)
    },

    /**
     * Возвращает true если урок начался
     * @returns {boolean}
     */
    lessonHasStarted() {
      let today = this.countUserTimeByTimeZone(this.$cookie.get('userTimeZone'))
      let lessonTime = new Date(
        this.newLessonInfo
          ? this.newLessonInfo.date.replaceAll('-', '/')
          : this.lessonInfo.date.replaceAll('-', '/')
      )
      return today > lessonTime
    }
  },
  watch: {
    lessonInfo() {
      this.arrowReverse = false
      this.isCardShowing = false
      this.setData()
    }
  }
}
</script>

<style scoped lang="scss">
@import './src/styleVariables';
.last-lesson-card-header {
  min-height: 66px;
  border-bottom: 1px solid #dee6f5;
  list-style: none;
  position: relative;
  cursor: pointer;
  &:hover {
    background-color: $background-blue;
  }
  .arrow {
    position: absolute;
    top: 35%;
    margin-left: 23px;
    svg {
      width: 16px;
      height: 10px;
      transition: 0.2s;
    }
  }
  .card-title {
    position: absolute;
    top: 35%;
    margin-left: 56px;
    font-family: $font-bold;
    font-size: 18px;
    color: $main-dark;
  }
}
.card-body {
  border-bottom: 1px solid #dee6f5;
}
.go-to-lesson-btn {
  text-align: end;

  button {
    font-size: 16px;
    margin: 0 40px 20px 0;
  }
}
/deep/ {
  span.table-cell-name {
    background: transparent !important;
    a {
      word-break: break-word;
    }
  }
}

.card-container,
.studentCard {
  width: 100%;
  min-height: 200px;
  height: auto;
  background: $main-white;
  padding: 15px 0;
  transition: box-shadow 0.087s ease-in;
  .card-header-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 18px 0 18px;
    .lesson-details {
      display: flex;
      align-items: center;
      span {
        font-size: 18px;
      }
      div {
        display: flex;
        align-items: center;
        span {
          font-family: $font-bold;
          font-size: 14px;
          line-height: 18px;
        }
        img {
          margin-right: 8px;
          width: 20px;
          height: 18px;
        }
      }
      .lesson-time {
        margin-left: 30px;
      }
    }
  }
  .lesson-link-container {
    margin-top: 15px;
    padding: 0 18px 0 18px;
    label {
      margin-bottom: 0 !important;
      margin-right: 12px;
    }
    .lesson-link {
      text-decoration: none;
      color: $bright-blue !important;
      word-break: break-all;
    }
    .lesson-link:visited {
      color: $regular-lesson;
    }
    span {
      color: $bright-blue;
    }
    input {
      border: 0.5px solid $light-gray;
      border-radius: 4px;
      background-color: transparent;
      outline: none;
      padding: 3px 9px;
      height: 25px;
      width: 287px;
      font-size: 14px;
      font-family: $font-regular;

      &:focus {
        border: 0.5px solid $bright-blue;
      }
    }
  }
  .card-members {
    display: flex;
    margin-top: 31px;
    flex-wrap: wrap;
    padding-left: 18px;
    .card-member-name {
      display: flex;
      flex-direction: column;
      margin-right: 58px;
      font-size: 14px;
      width: 220px;
      .name {
        font-family: $font-regular;
      }
    }
  }
  .card-status-container {
    margin-top: 35px;
    padding-left: 18px;
    .card-status {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      span {
        margin-right: 22px;
        min-width: 70px;
      }
      img {
        cursor: pointer;
        margin-left: 4px;
      }
      .lessonFinished {
        background-color: rgba(29, 190, 64, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        display: flex;
        white-space: nowrap;
      }
      .lessonPlanned {
        background-color: rgba(75, 97, 173, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        display: flex;
        white-space: nowrap;
      }
      .lessonMissedByStudent {
        background-color: rgba(243, 210, 50, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        display: flex;
        white-space: nowrap;
      }
      .lessonMissedByStudentWithReason {
        background-color: rgba(238, 0, 200, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        display: flex;
        white-space: nowrap;
      }
      .lessonMissedByTeacher {
        background-color: rgba(28, 9, 255, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        display: flex;
        white-space: nowrap;
      }
      .lessonCanceled {
        background-color: rgba(206, 87, 25, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        display: flex;
        white-space: nowrap;
      }
    }
    .card-type {
      display: flex;
      span {
        margin-right: 22px;
        min-width: 70px;
      }
      img {
        cursor: pointer;
        margin-left: 4px;
      }
      .regularLesson {
        background-color: rgba(29, 93, 190, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        display: flex;
        white-space: nowrap;
      }
      .teacherTesting {
        background-color: rgba(29, 190, 64, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        display: flex;
        white-space: nowrap;
      }
      .placementTest {
        background-color: rgba(255, 107, 0, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        display: flex;
        white-space: nowrap;
      }
      .checkUpLesson {
        background-color: rgba(255, 184, 0, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        display: flex;
        white-space: nowrap;
      }
      .progressTest {
        background-color: rgba(82, 0, 255, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        display: flex;
        white-space: nowrap;
      }
    }
  }
  .card-info-container {
    display: flex;
    margin-top: 23px;
    .first-table {
      flex-grow: 1;
      min-height: 150px;
      .table-cell {
        width: 100%;
        min-height: 46px;
        height: auto;
        display: flex;
        align-items: center;
        padding: 0 18px;
        .table-cell-label {
          min-width: 40%;
          width: 40%;
          background-color: transparent;
          margin-right: 6px;
        }
        .select {
          width: 287px;

          /deep/ .vs__search {
            background-color: $main-white !important;
          }
          /deep/ .vs__actions {
            background-color: $main-white !important;
          }
          /deep/ .selected,
          /deep/ .vs__selected-options,
          /deep/ .v-select,
          /deep/ .select {
            background-color: $main-white !important;
          }
          /deep/ .vs__dropdown-toggle {
            background-color: $main-white !important;
          }
          /deep/ .vs__selected {
            background-color: $main-white !important;
          }
          /deep/ .vs__open-indicator {
            background-color: $main-white !important;
          }
          /deep/ .vs__dropdown-menu {
            background-color: $main-white !important;
          }
          /deep/ .vs__dropdown-option {
            background-color: $main-white;
            color: $main-dark;
            &:hover {
              color: $bright-blue;
            }
          }
          /deep/ .vs__dropdown-option span {
            background-color: $main-white;
          }
        }
        .table-cell-name {
          font-family: $font-regular;
          word-break: break-word;
          background-color: transparent;
          a {
            background-color: $main-white;
          }
        }
        input,
        textarea {
          border: 0.5px solid $light-gray;
          border-radius: 4px;
          background-color: transparent;
          outline: none;
          padding: 3px 9px;
          height: 25px;
          width: 287px;
          font-size: 14px;
          font-family: $font-regular;

          &:focus {
            border: 0.5px solid $bright-blue;
          }
        }
        textarea {
          resize: none;
          height: 136px;
          width: 287px;
          margin-top: 10px;
        }
      }
    }
    .first-table :nth-child(even) {
      background-color: $light-table-blue;
      /deep/.vs__dropdown-menu {
        background-color: #fff;
      }
      /deep/.vs__dropdown-menu li div {
        background-color: $main-white;
      }
      /deep/.vs__dropdown-menu li {
        background-color: $main-white;
        &:hover {
          color: $bright-blue;
        }
      }
      /deep/.vs__dropdown-option,
      .vs__dropdown-option--highlight {
        background-color: $main-white !important;
      }
    }

    .second-table {
      flex-grow: 1;
      min-height: 150px;
      margin-right: 24px;
      .table-cell {
        width: 100%;
        min-height: 46px;
        height: auto;
        display: flex;
        align-items: center;
        padding: 0 18px;
        .table-cell-label {
          min-width: 40%;
          width: 40%;
          margin-right: 6px;
          background-color: transparent;
        }
        .select {
          width: 287px;

          /deep/ .vs__search {
            background-color: $main-white !important;
          }
          /deep/ .vs__actions {
            background-color: $main-white !important;
          }
          /deep/ .selected,
          /deep/ .vs__selected-options,
          /deep/ .v-select,
          /deep/ .select {
            background-color: $main-white !important;
          }
          /deep/ .vs__dropdown-toggle {
            background-color: $main-white !important;
          }
          /deep/ .vs__selected {
            background-color: $main-white !important;
          }
          /deep/ .vs__open-indicator {
            background-color: $main-white !important;
          }
          /deep/ .vs__dropdown-menu {
            background-color: $main-white !important;
          }
          /deep/ .vs__dropdown-option {
            background-color: $main-white;
            color: $main-dark;
            &:hover {
              color: $bright-blue;
            }
          }
          /deep/ .vs__dropdown-option span {
            background-color: $main-white;
          }
        }
        .table-cell-name {
          word-break: break-word;
          background-color: transparent;
          font-family: $font-regular;
          a {
            background-color: $main-white;
          }
        }
        input {
          border: 0.5px solid $light-gray;
          border-radius: 4px;
          background-color: transparent;
          outline: none;
          padding: 3px 9px;
          height: 25px;
          width: 287px;
          font-size: 14px;
          font-family: $font-regular;

          &:focus {
            border: 0.5px solid $bright-blue;
          }
        }
      }
    }
    .second-table :nth-child(even) {
      background-color: $light-table-blue;
      /deep/.vs__dropdown-menu {
        background-color: #fff;
      }
      /deep/.vs__dropdown-menu li div {
        background-color: $main-white;
      }
      /deep/.vs__dropdown-menu li {
        background-color: $main-white;
        &:hover {
          color: $bright-blue;
        }
      }
      /deep/.vs__dropdown-option,
      .vs__dropdown-option--highlight {
        background-color: $main-white !important;
      }
    }
    .second-table :last-child {
      border: none;
    }
  }
  .edit-buttons {
    margin-left: auto;
    width: fit-content;
    margin-right: 18px;
    button {
      width: 137px;
      height: 33px;
      border-radius: 4px;
    }
    .save {
      background-color: $bright-blue;
      color: $main-white;
    }
    .save--disabled {
      background-color: $light-gray;
      color: $main-white;
    }
    .cancel {
      background-color: $main-white;
      color: $bright-blue;
      margin-right: 15px;
      border: 1px solid $bright-blue;
    }
  }
  .student-card-info-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .student-lesson-info-container {
      display: flex;
      margin-top: 23px;
      flex-direction: column;

      .table-cell {
        width: 100%;
        min-height: 46px;
        height: auto;
        display: flex;
        align-items: center;
        padding: 0 18px;

        .table-cell-label {
          min-width: 30%;
          width: 30%;
          margin-right: 6px;
          background-color: transparent;
        }

        .select {
          width: 287px;

          /deep/ .vs__search {
            background-color: $main-white !important;
          }
          /deep/ .vs__actions {
            background-color: $main-white !important;
          }
          /deep/ .selected,
          /deep/ .vs__selected-options,
          /deep/ .v-select,
          /deep/ .select {
            background-color: $main-white !important;
          }
          /deep/ .vs__dropdown-toggle {
            background-color: $main-white !important;
          }
          /deep/ .vs__selected {
            background-color: $main-white !important;
          }
          /deep/ .vs__open-indicator {
            background-color: $main-white !important;
          }
          /deep/ .vs__dropdown-menu {
            background-color: $main-white !important;
          }
          /deep/ .vs__dropdown-option {
            background-color: $main-white;
            color: $main-dark;
            &:hover {
              color: $bright-blue;
            }
          }
          /deep/ .vs__dropdown-option span {
            background-color: $main-white;
          }
        }

        .table-cell-name {
          font-family: $font-regular;
          word-break: break-word;
          background-color: transparent;
          a {
            background-color: $main-white;
          }
        }
      }
    }
    button {
      color: $bright-blue;
      margin-top: 50px;
      outline: none;
      padding: 0 0 24px 24px;
    }
  }
  .student-lesson-info-container :nth-child(odd) {
    background: $light-table-blue;
  }
}
.card-container--red {
  box-shadow: 0 1px 4px 1px rgba(225, 52, 41, 0.75);
}
.studentCard {
  min-height: 437px;
}
.student-complaint {
  background: $main-white;
  box-shadow: 1px 0px 20px -7px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  width: 95%;
  margin: 0 auto;
  padding: 15px 24px;
  .complaint-header {
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    button {
      outline: none;
      img {
        width: 13px;
        height: 13px;
      }
    }
  }
  .complaint-content-container {
    display: flex;
    margin-top: 16px;
    .sender-avatar {
      img {
        border-radius: 50%;
        width: 47px;
        height: 47px;
        margin-right: 12px;
      }
    }
    .complaint-info {
      display: flex;
      flex-direction: column;
      .complaint-text {
        font-family: $font-regular;
        word-break: break-all;
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .card-container {
    .card-header-container {
      flex-direction: column-reverse;
      .arbitration-status {
        align-self: flex-end;
      }
      .edit-btn {
        display: flex;
        flex-direction: column;
        button {
          align-self: flex-end;
          justify-self: flex-end;
          margin-right: 0;
          margin-bottom: 12px;
          .edit-label {
            display: none;
          }
        }
        .student-controls-btn {
          width: 100%;
          button {
            width: 100%;
          }
          .lesson-cancel-btn {
            margin-bottom: 12px;
          }
        }
      }

      .student-controls-btn {
        width: 100%;
        margin-bottom: 12px;
        button {
          width: 100%;
        }
        .lesson-cancel-btn {
          margin-bottom: 12px;
        }
      }
      .lesson-details {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 14px;
        grid-row-gap: 14px;
        width: 100%;
        margin-bottom: 14px;
        .name {
          grid-column-start: 1;
          grid-column-end: 3;
        }
        .lesson-time {
          margin-left: 0;
        }
      }
    }
    .card-members {
      margin-top: 16px;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    .card-status-container {
      margin-top: 16px;
    }
    .card-info-container {
      flex-direction: column;
      .first-table :nth-child(odd) {
        background-color: $main-white;
      }
      .second-table :nth-child(odd) {
        background-color: $main-white;
      }

      .second-table {
        margin-right: 0;
      }
      .table-cell {
        flex-direction: column;
        align-items: flex-start !important;
        margin-bottom: 14px;
        input {
          width: 100% !important;
        }
        textarea {
          width: 100% !important;
        }
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .nav-container {
    div {
      width: 100%;
    }
  }
}
@media screen and (max-width: 344px) {
  .edit-buttons {
    display: flex;
    flex-direction: column;
    width: unset !important;
    margin-right: 20px !important;
    margin-left: 20px !important;
    button {
      margin-bottom: 12px;
      width: 100% !important;
    }
  }
}
</style>
