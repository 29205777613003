export default {
  methods: {
    Change__DateTimeLocal(data) {
      this.$store.commit('newRec', data)
    }
  },
  data() {
    return {
      inputDateTimeLocal: {
        value: this.value,
        text: this.text,
        disabled: this.disabled,
        min: this.min,
        max: this.max,
        step: this.step,
        tooltip: this.tooltip,
        keyAdd: this.keyAdd
      }
    }
  }
}
