export default {
  methods: {
    Change__TextArea(data) {
      this.$store.commit('newRec', data)
    }
  },
  data() {
    return {
      Textarea: {
        value: this.value,
        caption: this.caption,
        maxLength: this.maxLength,
        rows: this.rows,
        placeholder: this.placeholder,
        cols: this.cols,
        disabled: this.disabled,
        tooltip: this.tooltip,
        keyAdd: this.keyAdd
      }
    }
  }
}
