<template>
  <img src="../../../assets/img/CloseModal.svg" alt="cross" />
</template>

<script>
export default {
  name: 'OpenIndicator',
  mixins: [],
  props: [],
  components: {},
  data() {
    return {}
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
}
</script>

<style scoped>
img {
  width: 10px;
  margin-right: 0 !important;
}
</style>
