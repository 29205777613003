<template>
  <div class="side-bar-chat">
    <div class="side-bar-chat-filters">
      <DialogueFilters
        @switchRole="(type, filters, search) => $emit('getDialogues', type, filters, search)"
        @filterDialogue="(filters, search) => $emit('getDialogues', null, filters, search)"
      />
    </div>
    <div class="side-bar-chat-dialogues">
      <div
        class="side-bar-chat-dialogues__item"
        v-for="(dialogue, index) in dialogues"
        :key="dialogue.chat_id"
        @click="$emit('chooseChat', dialogue.chat_id, dialogue.title)"
      >
        <Dialogue
          :dialogue="dialogue"
          :border="dialogues.length === index + 1"
          :chosen="chatId === dialogue.chat_id"
        />
      </div>
    </div>
    <div class="side-bar-chat-user-search" v-show="users">Поиск по пользователям</div>
    <div class="side-bar-chat-users">
      <div
        class="side-bar-chat-users__item"
        v-for="(user, index) in users"
        :key="user.chat_id"
        @click="$emit('createChat', user.user_uuid, user.title)"
      >
        <Dialogue :dialogue="user" :border="users.length === index + 1" :chosen="none" />
      </div>
    </div>
    <div class="side-bar-chat-none" v-if="!dialogues && !users">Ничего не найдено</div>
  </div>
</template>

<script>
import DialogueFilters from '@/views/Chat/SideBar/components/DialogueFilters'
import Dialogue from '@/views/Chat/SideBar/components/Dialogue'
export default {
  name: 'SideBarChat',
  components: { Dialogue, DialogueFilters },
  props: ['chatId', 'dialogues', 'users']
}
</script>

<style scoped lang="scss">
@import '../../../styleVariables';
.side-bar-chat {
  width: 386px;
  min-width: 386px;

  max-height: 87vh;
  overflow-y: auto;
  background-color: $main-white;
  border-radius: 8px 0 0 0;
  z-index: 2;
  &-filters {
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: $main-white;
  }
  &-dialogues {
    &__item {
      cursor: pointer;
    }
  }
  &-user-search {
    margin-top: 12px;
    margin-left: 26px;
    color: $main-gray;
    text-transform: uppercase;
  }
  &-users {
    &__item {
      cursor: pointer;
    }
  }
  &-none {
    width: fit-content;
    margin: 50px auto;
    color: $main-gray;
  }
}
</style>
