<template>
  <div class="donut">
    <div class="donut-header">{{ header }}</div>
    <div class="donut-content">
      <div class="donut-content__chart">
        <vc-donut
          :sections="sections"
          :thickness="thickness"
          :size="200"
          total="100"
          :foreground="getChartBg"
          >{{ centerValue || 'Нет данных' }}</vc-donut
        >
      </div>
      <div class="donut-content__links">
        <div class="donut-content__links-item" v-for="(link, index) in links" :key="index">
          <div class="name">
            <div class="status" :style="`background-color: ${link.color}`" />
            <span>{{ link.name }}</span>
          </div>
          <div class="value">{{ link.value || '0' }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Donut',
  props: {
    sections: Array,
    centerValue: String,
    thickness: Number,
    header: String,
    links: Array
  },
  computed: {
    /**
     * Установить фон такого же цвета, как самая большая секция (решает баг плагина с полоской посреди самой большой секции)
     * @returns {string|*}
     */
    getChartBg() {
      if (!this.sections.length) {
        return 'rgb(238, 238, 238)'
      }
      let values = this.sections.map((item) => item.value)
      let max = this.getMaxOfArray(values)
      return this.sections.find((item) => item.value === max).color
    }
  },
  methods: {
    /**
     * Полчить максимум из масива
     * @param numArray
     * @returns {number}
     */
    getMaxOfArray(numArray) {
      return Math.max.apply(null, numArray)
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../../styleVariables';

.donut {
  color: $main-dark;
  &-header {
    font-size: 18px;
    line-height: 33px;
    margin-bottom: 24px;
  }
  &-content {
    display: flex;
    &__chart {
      margin-right: 45px;
      /deep/ .cdc-text {
        font-size: 14px !important;
        line-height: 24px;
        font-family: Roboto, sans-serif;
      }
    }
    &__links {
      width: 368px;
      &-item {
        display: grid;
        grid-template-columns: 1fr 32px;
        grid-column-gap: 32px;
        margin-bottom: 18px;

        .name {
          display: flex;
          align-items: center;
          span {
            font-size: 14px;
            line-height: 24px;
          }
          .status {
            min-width: 6px;
            width: 6px;
            height: 6px;
            border-radius: 50px;
            margin-right: 9px;
          }
        }
        .value {
          font-size: 14px;
          line-height: 24px;
          font-family: $font-regular;
          color: $main-gray;
        }
      }
    }
  }
}
</style>
