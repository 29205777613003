var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input-message-container"},[_c('div',{staticClass:"input-message"},[_c('div',{staticClass:"input-message__file"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.loader),expression:"loader"}],staticClass:"loader",attrs:{"src":require("../../../../assets/img/ES/loader.svg"),"alt":"loader"}}),_vm._v(" "),(!_vm.uploadedFile)?_c('file-upload',{ref:"uploadConstructorChat",staticClass:"file-uploader",attrs:{"input-id":"uploadConstructorChat","post-action":"/upload/post","drop":true,"dropDirectory":true,"fileDrop":true,"multiple":false},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(
            !(_vm.$refs.uploadConstructorChat && _vm.$refs.uploadConstructorChat.dropActive) && !_vm.loader
          ),expression:"\n            !($refs.uploadConstructorChat && $refs.uploadConstructorChat.dropActive) && !loader\n          "}],attrs:{"src":require("../../../../assets/img/ES/file_chat.svg"),"alt":"file"}})]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"input-message__textarea"},[_c('v-textarea',{attrs:{"id":"chat-message-typer-textarea","auto-grow":"","rows":"1","placeholder":"Введите текст сообщения...","counter":"1000"},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }$event.preventDefault();},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }if(!$event.ctrlKey){ return null; }if($event.shiftKey||$event.altKey||$event.metaKey){ return null; }return _vm.newLine($event)}],"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; };((_vm.message && _vm.message.length <= 1000) || _vm.uploadedFile) && !_vm.editedMessage
            ? _vm.$emit('sendMessage', _vm.message, _vm.fileId)
            : _vm.editedMessage && ((_vm.message && _vm.message.length <= 1000) || _vm.uploadedFile)
            ? _vm.$emit('editMessage', _vm.message, _vm.fileId)
            : ''}},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(((_vm.message && _vm.message.length <= 1000) || _vm.uploadedFile) && !_vm.editedMessage),expression:"((message && message.length <= 1000) || uploadedFile) && !editedMessage"}],staticClass:"input-message__send",on:{"click":function($event){return _vm.$emit('sendMessage', _vm.message, _vm.fileId)}}},[_c('img',{attrs:{"src":require("../../../../assets/img/ES/send_chat.svg"),"alt":"send"}})]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.editedMessage),expression:"editedMessage"}],staticClass:"input-message__cancel",on:{"click":function($event){return _vm.$emit('cancelEditingMessage')}}},[_c('img',{attrs:{"src":require("../../../../assets/img/ES/close_edit_chat.svg"),"alt":"close"}})]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.editedMessage && ((_vm.message && _vm.message.length <= 1000) || _vm.uploadedFile)),expression:"editedMessage && ((message && message.length <= 1000) || uploadedFile)"}],staticClass:"input-message__save",on:{"click":_vm.editMessage}},[_c('img',{attrs:{"src":require("../../../../assets/img/ES/check_chat.svg"),"alt":"edit"}})])]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.message && _vm.message.length > 1000),expression:"message && message.length > 1000"}],staticClass:"error-label"},[_vm._v("\n    Сообщение не должно превышать 1000 символов\n  ")]),_vm._v(" "),(_vm.uploadedFile)?_c('div',{staticClass:"uploaded-file",on:{"click":function($event){return _vm.openFile(_vm.filePath)}}},[(
        _vm.uploadedFile.file.extension === 'png' ||
        _vm.uploadedFile.file.extension === 'jpeg' ||
        _vm.uploadedFile.file.extension === 'jpg' ||
        _vm.uploadedFile.file.extension === 'svg'
      )?_c('img',{attrs:{"src":_vm.filePath,"alt":"file"}}):_c('img',{attrs:{"src":require("../../../../assets/img/ES/file_icon.svg"),"alt":"file"}}),_vm._v(" "),_c('div',{staticClass:"file-name"},[_vm._v("\n      "+_vm._s(_vm.uploadedFile.file.name)+"\n    ")]),_vm._v(" "),_c('img',{staticClass:"delete",attrs:{"src":require("../../../../assets/img/ES/close_big.svg"),"alt":"delete"},on:{"click":_vm.deleteFile}})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }