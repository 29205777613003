<template>
  <div class="modal-background" @mousedown.self="hide">
    <div class="modal-container">
      <div class="header">
        <div class="header-content">
          <div class="header-content__name">Жалоба</div>
          <div class="header-content__status">
            <div
              class="indicator"
              :style="
                data.complaint_status.code === 'COMPLAINT_ACCEPTED'
                  ? 'background-color: #1DBE40'
                  : data.complaint_status.code === 'COMPLAINT_REVIEW'
                  ? 'background-color: #FFD500'
                  : 'background-color: #FA4B00'
              "
            />
            <span>{{ data.complaint_status.name }}</span>
          </div>
        </div>
        <div class="header-close" @click="hide()">
          <img src="../../../assets/img/ES/close_gray_big.svg" alt="close" />
        </div>
      </div>
      <div class="content">
        <div class="content-dates">
          <div class="content-dates__item">
            <div class="label">Дата поступления жалобы</div>
            <span>{{ data.created }}</span>
          </div>
          <div class="content-dates__item">
            <div class="label">Дата рассмотрения жалобы</div>
            <span>{{ data.review_date || '-' }}</span>
          </div>
        </div>
        <div class="content-item">
          <div class="label">ФИО отправителя</div>
          <span
            class="link"
            @click="$router.push({ name: 'UserControl', params: { user_id: data.author_id } })"
            >{{ data.author_full_name || '-' }}</span
          >
        </div>
        <div class="content-item">
          <div class="label">ФИО арбитра</div>
          <span
            class="link"
            @click="$router.push({ name: 'UserControl', params: { user_id: data.arbitrator_id } })"
            >{{ data.arbitrator_full_name || '-' }}</span
          >
        </div>
        <div class="content-item">
          <div class="label">Занятие</div>
          <span
            class="link"
            @click="$router.push({ name: 'LessonCard', params: { lesson_id: data.lesson_id } })"
            >Карточка занятия</span
          >
        </div>
        <div class="content-item">
          <div class="label">Жалоба</div>
          <span>{{ data.reason || '-' }}</span>
        </div>
        <div class="content-item">
          <div class="label">Решение</div>
          <span>{{ data.decision || '-' }}</span>
        </div>
        <div class="content-item">
          <div class="label">Комментарий арбитра</div>
          <span>{{ data.comment || '-' }}</span>
        </div>
      </div>
      <div class="modal-controls" v-if="data.complaint_status.code === 'COMPLAINT_REVIEW'">
        <button class="disapprove" @click="$emit('disapprove')">Отклонить</button>
        <button class="approve" @click="$emit('approve')">Принять</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ViewModal',
  props: {
    data: Object
  },
  methods: {
    hide() {
      this.$emit('hide')
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../styleVariables.scss';

.modal-background {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.3);
  animation: fade-in 0.3s;
  color: $main-dark;

  .modal-container {
    background-color: $main-white;
    border-radius: 8px;
    padding: 14px 24px;
    width: 550px;
    //height: 313px;
    display: flex;
    flex-direction: column;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 8px;

      &-content {
        display: flex;
        align-items: flex-end;
        &__name {
          font-size: 18px;
          margin-right: 32px;
        }
        &__status {
          display: flex;
          align-items: center;
          .indicator {
            width: 6px;
            height: 6px;
            border-radius: 50px;
            margin-right: 9px;
          }
          span {
            font-size: 14px;
            line-height: 24px;
            font-family: $font-regular;
          }
        }
      }
      &-close {
        cursor: pointer;
      }
    }

    .content {
      margin-bottom: 4px;
      &-dates {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 12px;
        margin-bottom: 8px;
        &__item {
          display: flex;
          flex-direction: column;
          .label {
            font-size: 14px;
            line-height: 24px;
          }
          span {
            font-size: 14px;
            line-height: 24px;
            font-family: $font-regular;
          }
        }
      }
      &-item {
        display: flex;
        margin-bottom: 14px;
        .label {
          font-size: 14px;
          line-height: 24px;
          margin-right: 10px;
          white-space: nowrap;
        }
        span {
          font-size: 14px;
          line-height: 24px;
          font-family: $font-regular;
        }
        .link {
          cursor: pointer;
          color: $bright-blue;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    .modal-controls {
      display: flex;
      justify-content: flex-end;
      button {
        width: 137px;
        height: 33px;
        border-radius: 4px;
        font-size: 14px;
        line-height: 24px;
        outline: none;
      }
      .approve {
        background-color: $bright-blue;
        color: $main-white;
      }
      .disapprove {
        border: 1px solid $bright-blue;
        color: $bright-blue;
        margin-right: 15px;
      }
    }
  }
}
</style>
