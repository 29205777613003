<template>
  <div class="add-free-time-card">
    <img v-show="!wasAddFreeTime" src="../../../assets/img/ES/plus_gray.svg" alt="plus" />
    <div v-show="wasAddFreeTime" class="add-free-time-card-content">
      <span>Свободное время</span>
      <span class="time">
        <img src="../../../assets/img/alarm.svg" alt="time" />
        <span>{{ formatHours }}</span></span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddFreeTimeCard',
  props: {
    hour: String,
    day: Object,
    freeTimeData: Object
  },
  computed: {
    /**
     * Возвращает true если на текущую дату было выбрано свободное время
     * @returns {boolean}
     */
    wasAddFreeTime() {
      if (this.freeTimeData.work_times) {
        for (let i in this.freeTimeData.work_times) {
          if (this.freeTimeData.work_times[i].kls_day_id === this.day.kls_id) {
            for (let j in this.freeTimeData.work_times[i].work_hours) {
              if (this.hour === this.freeTimeData.work_times[i].work_hours[j]) {
                return true
              }
            }
          }
        }
      }
      return false
    },
    formatHours() {
      return `${Number(this.hour) < 10 ? '0' + Number(this.hour) : Number(this.hour)}:00 - ${
        Number(this.hour) < 9 ? '0' + (Number(this.hour) + 1) : Number(this.hour) + 1
      }:00`
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../styleVariables';
.add-free-time-card {
  width: 100%;
  padding: 0 4px;
  &-content {
    width: 100%;
    height: 95px;
    background-color: #fff;
    border: 1px solid $bright-blue;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 4px 12px;
    .time {
      color: $main-dark;
      text-align: left;
      display: flex;
      align-items: center;
      img {
        margin-right: 6px;
        margin-bottom: 2px;
      }
    }
  }
}
</style>
