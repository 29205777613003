<template>
  <div class="mobile-legend">
    <div
      class="ended"
      :class="{ active: sortType === 'ended' }"
      @click="$emit('filter', sortType === 'ended' ? null : 'ended')"
    >
      <img src="../../../../../assets/img/lesson-complete.svg" alt="complete" />
      <span>Состоялось</span>
    </div>
    <div
      class="regular"
      :class="{ active: sortType === 'lesson' }"
      v-if="type !== 'methodologist'"
      @click="$emit('filter', sortType === 'lesson' ? null : 'lesson')"
    >
      <img src="../../../../../assets/img/lesson.svg" alt="regular" />
      <span>Занятие</span>
    </div>
    <div
      class="empty"
      :class="{ active: sortType === 'empty' }"
      v-if="type !== 'student'"
      @click="$emit('filter', sortType === 'empty' ? null : 'empty')"
    >
      <img src="../../../../../assets/img/empty-card.svg" alt="empty" />
      <span>Незаполненная карточка</span>
    </div>
    <div
      class="test"
      :class="{ active: sortType === 'test' }"
      v-if="type !== 'student'"
      @click="$emit('filter', sortType === 'test' ? null : 'test')"
    >
      <img src="../../../../../assets/img/test.svg" alt="test" />
      <span>Тест</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobileLegend',
  props: {
    type: String,
    sortType: String
  }
}
</script>

<style scoped lang="scss">
@import '../../../../../styleVariables';

.mobile-legend {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 34px;
  grid-column-gap: 16px;
  grid-row-gap: 10px;
  .regular,
  .ended,
  .test,
  .empty {
    border-radius: 5px;
    color: $main-dark;
    display: flex;
    align-items: center;
    padding: 7px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    img {
      margin-right: 8px;
    }
    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .active {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  }
  .regular {
    background-color: $regular-lesson-tag;
  }
  .ended {
    background-color: $lesson-ended-tag;
  }
  .test {
    background-color: $test-tag;
  }
  .empty {
    background-color: $empty-card-tag;
  }
}
</style>
