<template>
  <div class="translation-details-container">
    <div class="dictionary">
      <h3 class="title">Словарь</h3>
      <p class="translated-word-container">
        <span class="translated-word">{{ translatedWord.text }}</span>
        <span class="translated-word-pos">{{ formatedPos }}</span>
        <span class="translated-word-gen">{{ translatedWord.gen }}</span>
      </p>
      <div class="dictionary-body">
        <div class="mean-list-container">
          <ol class="mean-list">
            <li v-for="(mean, index) in translateMeans" :key="index" class="mean-item">
              <span class="mean-item-content">{{ mean.text }}</span>
            </li>
          </ol>
          <b-collapse :visible="showMoreItems" v-if="translateMeansMore.length">
            <ol class="mean-list--show-more" start="4">
              <li v-for="(mean, index) in translateMeansMore" :key="index" class="mean-item">
                <span class="mean-item-content">{{ mean.text }}</span>
              </li>
            </ol>
          </b-collapse>
        </div>
        <div class="translation-list-container">
          <ul class="translation-list">
            <li
              v-for="(variation, index) in translateVariations"
              :key="index"
              class="translation-list-item"
            >
              {{ variation.text }}
              <span class="translated-word-pos">{{ formatedPos }}</span>
              <span class="translated-word-gen">{{ variation.gen }}</span>
            </li>
          </ul>
          <b-collapse :visible="showMoreItems" v-if="translateVariationsMore.length">
            <ul class="translation-list">
              <li
                v-for="(variation, index) in otherItems"
                :key="index"
                class="translation-list-item"
              >
                {{ variation.text }}
                <span class="translated-word-pos">{{ formatedPos }}</span>
                <span class="translated-word-gen">{{ variation.gen }}</span>
              </li>
            </ul>
          </b-collapse>
        </div>
      </div>
    </div>
    <button
      class="show-more-toggle"
      @click="showMoreToggle"
      v-if="translateMeansMore.length && translateVariationsMore.length"
    >
      <span
        >{{ showMoreItems ? 'Скрыть' : `Ещё ${translateMeansMore.length} ${wordEnding}` }}
      </span>
      <svg
        width="10"
        height="6"
        viewBox="0 0 10 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :style="arrowPosition"
      >
        <path
          d="M8.1958 0.291505C8.52095 -0.033645 9.04795 -0.0341907 9.37378 0.290286C9.70055 0.615714 9.7011 1.1446 9.375 1.4707L5 5.8457L0.625001 1.4707C0.298897 1.1446 0.299445 0.615714 0.626223 0.290286C0.952046 -0.0341908 1.47905 -0.033645 1.8042 0.291505L5 3.4873L8.1958 0.291505Z"
          fill="#8F9BB3"
        />
      </svg>
    </button>
    <div class="examples" v-if="translateExamples">
      <h3 class="title">Примеры</h3>
      <div class="examples-body">
        <ul class="examples-list">
          <li v-for="(example, index) in translateExamples" :key="index" class="example-item">
            {{ example.text }}
          </li>
        </ul>
        <ul class="examples-translation-list">
          <li v-for="(example, index) in translateExamples" :key="index" class="example-item">
            {{ example.tr[0].text }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TranslationDetails',
  props: {
    translateWordVariations: Array
  },
  data() {
    return {
      isTable: true,
      isFirstCell: true,
      showMoreItems: false,
      arrowReverse: false
    }
  },
  methods: {
    /**
     * Показать больше вариантов перевода
     */
    showMoreToggle() {
      this.showMoreItems = !this.showMoreItems
      this.arrowReverse = !this.arrowReverse
    }
  },
  computed: {
    /**
     * Переведённое слово
     * @returns {Object}
     */
    translatedWord() {
      return this.translateWordVariations[0]
    },

    /**
     * Первые три варианта перевода
     * @returns {Array}
     */
    translateVariations() {
      if (this.translateWordVariations.length >= 3) {
        let threeFirstItems = []
        for (let i = 0; i < 3; i++) {
          threeFirstItems.push(this.translateWordVariations[i])
        }
        return threeFirstItems
      } else {
        return this.translateWordVariations
      }
    },

    /**
     *Остальные варианты перевода
     * @returns {Array}
     */
    translateVariationsMore() {
      let showMoreItems = []
      for (let i = 3; i < this.translateWordVariations.length; i++) {
        showMoreItems.push(this.translateWordVariations[i])
      }
      return showMoreItems
    },

    /**
     * Сходные по смыслу слова
     * @returns {Array}
     */
    translateMeans() {
      if (this.translateWordVariations[0].mean.length > 3) {
        let threeFirstItems = []
        for (let i = 0; i < 3; i++) {
          threeFirstItems.push(this.translateWordVariations[0].mean[i])
        }
        return threeFirstItems
      } else {
        return this.translateWordVariations[0].mean
      }
    },

    /**
     * Остальные похожие по смыслу слова
     * @returns {Array}
     */
    translateMeansMore() {
      let showMoreItems = []
      for (let i = 3; i < this.translateWordVariations[0].mean.length; i++) {
        showMoreItems.push(this.translateWordVariations[0].mean[i])
      }
      return showMoreItems
    },

    /**
     * Примеры использования
     * @returns {Object}
     */
    translateExamples() {
      return this.translateWordVariations[0].ex
    },

    /**
     * Форматирование отображения части речи
     * @returns {string}
     */
    formatedPos() {
      let cuttedPos
      switch (this.translatedWord.pos) {
        case 'существительное':
          cuttedPos = 'сущ'
          break
        case 'прилагательное':
          cuttedPos = 'прил'
          break
        case 'глагол':
          cuttedPos = 'гл'
          break
      }
      return cuttedPos
    },

    /**
     * Форматирование окончания слова "вариант"
     * @returns {string}
     */
    wordEnding() {
      switch (this.translateMeansMore.length) {
        case 1:
          return 'вариант'
        case 3:
          return 'варианта'
        case 4:
          return 'варианта'
        case 2:
          return 'варианта'
        default:
          return 'вариантов'
      }
    },

    /**
     * Направление стрелки
     * @returns {string}
     */
    arrowPosition() {
      if (!this.arrowReverse) return 'transform: rotate(0deg);'
      else return 'transform: rotate(180deg);'
    }
  },
  watch: {
    translateWordVariations() {
      this.arrowReverse = false
      this.showMoreItems = false
    }
  }
}
</script>

<style scoped lang="scss">
@import './src/styleVariables';

.translated-word-container {
  margin-bottom: 12px !important;
  span {
    margin-right: 5px;
  }
}
.translated-word {
  font-size: 14px;
  line-height: 18px;
  color: #222b45;
  font-family: $font-regular;
  text-transform: capitalize;
}
.translated-word-pos {
  font-size: 14px;
  line-height: 18px;
  color: #45c661;
  font-family: $font-regular;
}
.translated-word-gen {
  font-size: 14px;
  line-height: 18px;
  color: #8f9bb3;
  font-family: $font-regular;
}
.show-more-toggle {
  color: #8f9bb3;
  span {
    font-family: $font-regular;
  }
  svg {
    transition: 0.2s;
  }
}
.translation-details-container {
  margin-top: 20px;
  padding: 0 34px;
  .title {
    font-family: $font-bold;
    font-size: 14px !important;
    line-height: 24px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #222b45;
    margin-bottom: 12px;
  }
  ol {
    list-style-type: decimal;
    margin-bottom: 0;
  }
  ul {
    list-style: none;
    margin-bottom: 0;
  }
  .dictionary {
    .dictionary-body {
      display: flex;
      flex-direction: row;
      .translation-list-container,
      .mean-list-container {
        width: 50%;
      }
      .mean-list,
      .mean-list--show-more,
      .translation-list,
      .translation-list--show-more {
        padding-left: 12px;
        li {
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 12px;
          font-family: $font-regular;
        }
        .mean-item {
          color: #8f9bb3;
          &-content {
            color: $bright-blue;
            font-family: $font-regular;
          }
        }
      }
      .translation-list,
      .translation-list--show-more {
        margin-left: 22px;
      }
    }
  }

  .examples {
    margin-top: 25px;
    .examples-body {
      display: flex;
      flex-direction: row;
      .examples-list,
      .examples-translation-list {
        width: 50%;
        padding-left: 24px;

        li {
          font-family: $font-regular;
          margin-bottom: 12px;
        }
      }
      .examples-translation-list {
        margin-left: 46px;
      }
    }
  }
}

@media screen and (max-width: 710px) {
  .translation-details-container {
    padding: 0;
  }
}
</style>
