export default {
  methods: {
    Button__click(data) {
      return this.$root.$emit(`${data.value}`, this.selectedItems)
    }
  },
  data() {
    return {
      button: {
        value: 'details',
        caption: 'caption',
        loading: false
      }
    }
  },
  watch: {
    ChangeText: function (val) {
      this.$emit('ChangeText', val)
    }
  }
}
