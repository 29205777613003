<template>
  <div class="month-picker-background" v-click-outside="showRangeWindow">
    <div class="label-month">
      {{ label }}
    </div>
    <input
      @focus.stop="checkValue"
      v-mask="monthRangeMask"
      @input="setValueMonthRange"
      type="text"
      :placeholder="placeholder"
      v-model="monthRangeValue"
      :class="{ 'invalid-input': invalid }"
      ref="month"
      :disabled="disabled"
    />
    <small class="invalid-label" v-if="invalid">{{ userText }}</small>
    <div v-if="showRange" id="month-picker">
      <div class="month_picker_container">
        <div class="year_select_area">
          <img
            src="../../../../../assets/img/icons/arrowNextYear.svg"
            class="right_arrow arrow"
            @click="clickYear(0)"
          />
          <div class="now_year">{{ nowYear }}</div>
          <img
            src="../../../../../assets/img/icons/arrowNextYear.svg"
            class="arrow"
            @click="clickYear(1)"
          />
        </div>
        <div class="month_select_area">
          <ul class="month_list_area">
            <li
              v-for="(list, index) in month"
              class="month_list"
              @click="clickMonth(index)"
              :key="list"
              :style="CheckMain(index) || CheckOutOfRange(index)"
            >
              <span>{{ list }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import ValidationAllInputs from '../../../../Core/KernelProject/validationAllInputs'

export default {
  name: 'DateMonth',
  mixins: [ValidationAllInputs],
  props: {
    minDate: {
      type: String,
      default: '2000-05'
    },
    maxDate: {
      type: String,
      default: `${new Date().getFullYear()}-${new Date().getMonth() + 1}`
    },
    placeholder: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: 'Дата'
    },
    value: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    keyAdd: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data() {
    return {
      currentYear: new Date().getFullYear(),
      nowYear: new Date().getFullYear(),
      month: ['ЯНВ', 'ФЕВ', 'МАР', 'АПР', 'МАЙ', 'ИЮН', 'ИЮЛ', 'АВГ', 'СЕН', 'ОКТ', 'НОЯ', 'ДЕК'],
      activeMonth: '',
      activeYear: '',
      showRange: false,
      monthRangeValue: '',
      monthRangeMask: {
        mask: '99-9999',
        placeholder: '',
        showMaskOnHover: false
      }
    }
  },
  mounted() {
    if (this.value) {
      this.monthRangeValue = this.value.split('-').reverse().join('-')
      this.setValueMonthRange()
    }
  },
  methods: {
    checkValue() {
      this.showRange = true
    },
    showRangeWindow() {
      this.showRange = false
    },
    /**
     * Функция проверки значения на валидность данных и отрисовки нужной даты в модальном окне выбора месяцев
     */
    setValueMonthRange() {
      let minMonth = this.minDate.split('-')[1]
      let minYear = this.minDate.split('-')[0]
      let maxMonth = this.maxDate.split('-')[1]
      let maxYear = this.maxDate.split('-')[0]
      if (this.monthRangeValue.length === 7) {
        let date = this.monthRangeValue.split('-').reverse()
        /**
         * Если Минимальный год, ровняется введенному
         */
        if (minYear === date[0]) {
          /**
           * Проверка на то что, месяц не меньше минимального, и не больше Декабря.
           */
          if (date[1] < minMonth || date[1] > 12) {
            date[1] = minMonth
          }
        } else if (maxYear === date[0]) {
          /**
           * Если Максимальный год, ровняется введенному
           */
          /**
           * Проверка на то что, месяц не больше максимального, и не меньше Января.
           */
          if (date[1] > maxMonth || date[1] < 1) {
            date[1] = maxMonth
          }
        } else if (date[0] < minYear || date[0] > maxYear) {
          /**
           * Если Введеный год, меньше минимального или больше максимального.
           */
          date[0] = maxYear
          /**
           * Проверка на валидность месяца.
           */
          if (date[1] > 12 || date[1] < 1) {
            date[1] = maxMonth
          }
        } else {
          /**
           * Проверка на валидность месяца.
           */
          if (date[1] > 12 || date[1] < 1) {
            date[1] = maxMonth
          }
        }

        this.activeMonth = date[1] - 1
        this.activeYear = parseInt(date[0])
        this.monthRangeValue = `${date[1]}-${date[0]}`
        this.$emit('monthRange', `${date[0]}-${date[1]}`)
        this.showRange = false
      } else {
        this.activeMonth = ''
        this.activeYear = ''
      }
    },
    /**
     * Отвечает за переключение Годов.
     */
    clickYear(status) {
      if (status === 0) {
        if (this.nowYear > this.minDate.split('-')[0]) {
          this.nowYear--
        }
      }
      if (status === 1) {
        if (this.nowYear < this.maxDate.split('-')[0]) {
          this.nowYear++
        }
      }
    },
    /**
     * Отвечает за нажатие на Месяца.
     */
    clickMonth(index) {
      let minMonth = this.minDate.split('-')[1]
      let minYear = this.minDate.split('-')[0]
      let maxMonth = this.maxDate.split('-')[1]
      let maxYear = this.maxDate.split('-')[0]
      /**
       * Если находимся на страницы с минимальной датой
       */
      if (parseInt(minYear) === this.nowYear) {
        /**
         * Проверка на то, что кликаем на месяц который >= Минимального месяца
         */
        if (index >= minMonth - 1) {
          this.activeMonth = index
          this.activeYear = this.nowYear
          this.emitEvent()
          this.monthRangeValue = `${
            this.activeMonth + 1 < 10 ? `0${this.activeMonth + 1}` : this.activeMonth + 1
          }-${this.activeYear}`
          this.showRange = false
        }
      } else if (parseInt(maxYear) === this.nowYear) {
        /**
         * Если находимся на страницы с максимальной датой
         */
        /**
         * Проверка на то, что кликаем на месяц который <= Максимального месяца
         */
        if (index <= maxMonth - 1) {
          this.activeMonth = index
          this.activeYear = this.nowYear
          this.emitEvent()
          this.monthRangeValue = `${
            this.activeMonth + 1 < 10 ? `0${this.activeMonth + 1}` : this.activeMonth + 1
          }-${this.activeYear}`
          this.showRange = false
        }
      } else if (this.middleMonth) {
        /**
         * Проверка на то, что кликаем на месяц который находится на промежуточной странице, между максимальной и минимальной страницами
         */
        this.activeMonth = index
        this.activeYear = this.nowYear
        this.emitEvent()
        this.monthRangeValue = `${
          this.activeMonth + 1 < 10 ? `0${this.activeMonth + 1}` : this.activeMonth + 1
        }-${this.activeYear}`
        this.showRange = false
      }
    },
    /**
     * Отвечает за отрисовку главного месяца.
     */
    CheckMain(index) {
      if (this.nowYear === this.activeYear && index === this.activeMonth) {
        return 'color: #35455E; background-color: #FFFFFF; border-radius: 4px; box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);'
      }
    },
    /**
     * Отвечает за отрисовку месяцев которые больше Максимальной даты
     * @returns {string}
     */
    CheckOutOfRange(index) {
      if (this.currentYear === this.nowYear && index >= this.maxDate.split('-')[1]) {
        return 'color: #F5FAFF; background-color: #F5FAFF; border-radius: 4px;'
      }
    },
    /**
     * Функция emit которая в нужном расположении записывает данные в store
     */
    emitEvent() {
      let month = this.activeMonth + 1 < 10 ? `0${this.activeMonth + 1}` : this.activeMonth + 1
      this.$emit('monthRange', `${this.activeYear}-${month}`)
    }
  },
  computed: {
    /**
     * Проверка на то, что кликаем на месяц который находится на промежуточной странице, между максимальной и минимальной страницами
     * @returns {boolean|boolean}
     */
    middleMonth() {
      return (
        parseInt(this.minDate.split('-')[0]) !== this.nowYear &&
        parseInt(this.maxDate.split('-')[0]) !== this.nowYear
      )
    }
  },
  watch: {
    value() {
      /**
       * Смотрим, если приходит какой-то value, то приводим его в валидный вид.
       */
      if (this.value) {
        this.monthRangeValue = this.value.split('-').reverse().join('-')
        this.setValueMonthRange()
      } else {
        this.value = this.$store.state.newRecord[this.keyAdd]
      }
    }
  },
  validations: {},
  directives: {
    ClickOutside
  }
}
</script>

<style scoped lang="scss">
@import '../../../ColorVariables';
@import '../../../../../styleVariables';

.month-picker-background {
  position: relative;
  .label-month {
    color: $main-dark;
    font-size: 14px !important;
    font-weight: 500;
    line-height: 14px;
    margin-bottom: 6px;
  }
  input {
    background: $main-white;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    //border: 1.5px solid transparent;
    border: 0.5px solid #e8edf3;
    color: $main-dark;
    outline: none;
    padding: 0 12px;
    height: 41px;
    font-size: 14px;
    line-height: 16px;
  }

  input::placeholder {
    color: $light-gray;
    font-family: $font-bold;
    font-size: 14px;
    line-height: 16px;
  }

  input:invalid {
    box-shadow: none;
  }

  .invalid-input {
    border: 1px solid $red;
  }

  .invalid-label {
    color: $red;
  }

  #month-picker {
    .month_picker_container {
      position: absolute;
      z-index: 5;
      top: 110%;
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      background: #ffffff;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      user-select: none;
      z-index: 100;

      .year_select_area {
        display: flex;
        align-items: center;
        padding: 12px 24px;

        .now_year {
          margin: 0 12px;
        }

        .arrow {
          cursor: pointer;
        }

        .right_arrow {
          transform: rotate(180deg);
        }
      }

      .month_select_area {
        ul,
        li {
          list-style: none;
          padding: 0;
          margin: 0;
        }

        .month_list_area {
          height: 100%;
          display: flex;
          justify-content: center;
          flex-direction: row;
          flex-wrap: wrap;

          .month_list {
            width: 60px;
            height: 25px;
            border-radius: 4px;
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            background-color: transparent;
            text-align: center;
            font-size: 14px;
            color: #222;
          }

          .month_list:hover {
            background-color: #f6f6f6;
          }
        }
      }
    }
  }
}
</style>
