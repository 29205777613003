var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-navbar',{staticClass:"layout-navbar align-items-lg-center container-p-x",style:(!_vm.isOpenMenu ? 'border-radius: 0px 0px 8px 8px' : ''),attrs:{"toggleable":"lg","variant":_vm.getLayoutNavbarBg()}},[_c('div',{staticClass:"global-search"},[(_vm.viewGlobalSearch)?_c('b-form-group',[_c('input',{staticStyle:{"width":"100%"},attrs:{"id":"globalSearch","placeholder":"Поиск по таблице"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.globalSearch($event)}}}),_vm._v(" "),_c('img',{attrs:{"src":require("../assets/img/ES/gray_search.svg"),"alt":"search"}})]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"mobile-navbar"},[_c('div',{staticClass:"toggle-sidenav"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isOpenMenu),expression:"!isOpenMenu"}],attrs:{"src":require("../assets/img/ES/burger.svg"),"alt":"menu"},on:{"click":function($event){return _vm.toggleMenu(true)}}}),_vm._v(" "),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpenMenu),expression:"isOpenMenu"}],attrs:{"src":require("../assets/img/ES/close_menu.svg"),"alt":"close"},on:{"click":function($event){return _vm.toggleMenu(false)}}}),_vm._v(" "),_c('img',{staticClass:"mobile-logo",attrs:{"src":require("../assets/img/ES/mobile_logo.png"),"alt":"logo"}})]),_vm._v(" "),_c('div',{staticClass:"user-time"},[_c('img',{attrs:{"src":require("../assets/img/ES/alarm.svg"),"alt":"clock"}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.mobileUserTime))])]),_vm._v(" "),_c('div',{staticClass:"navbar-elements"},[(
          _vm.$cookie.get('internalName') === 'teacher' ||
          _vm.$cookie.get('internalName') === 'methodologist' ||
          _vm.$cookie.get('internalName') === 'student'
        )?_c('a',{staticClass:"telegram",attrs:{"href":_vm.telegramLink,"target":"_blank"}},[_c('img',{attrs:{"src":require("../assets/img/ES/logo_telegram.svg"),"alt":"tlg"}})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"notifications-data",on:{"click":function($event){return _vm.$router.push('/monolit/Notifications')}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.notificationCount),expression:"notificationCount"}],staticClass:"notifications-count"},[_vm._v(_vm._s(_vm.notificationCount))]),_vm._v(" "),_c('img',{staticClass:"notifications",attrs:{"src":_vm.notificationCount
              ? require("../assets/img/ES/notification_count.svg")
              : require("../assets/img/notification.svg"),"alt":"notifications"}})])])]),_vm._v(" "),_c('b-collapse',{attrs:{"is-nav":"","id":"app-layout-navbar"}},[_c('div',{staticClass:"user-time"},[_c('img',{attrs:{"src":require("../assets/img/ES/alarm.svg"),"alt":"clock"}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.userTime))])]),_vm._v(" "),_c('div',{staticClass:"navbar-nav align-items-lg-center ml-auto"},[(
          _vm.$cookie.get('internalName') === 'teacher' ||
          _vm.$cookie.get('internalName') === 'methodologist' ||
          _vm.$cookie.get('internalName') === 'student'
        )?_c('a',{staticClass:"telegram",attrs:{"href":_vm.telegramLink,"target":"_blank"}},[_c('img',{attrs:{"src":require("../assets/img/ES/logo_telegram.svg"),"alt":"tlg"}})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"notifications-data",on:{"click":function($event){_vm.showNotification = !_vm.showNotification}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.notificationCount),expression:"notificationCount"}],staticClass:"notifications-count"},[_vm._v(_vm._s(_vm.notificationCount))]),_vm._v(" "),_c('img',{staticClass:"notifications",attrs:{"src":_vm.notificationCount
              ? require("../assets/img/ES/notification_count.svg")
              : require("../assets/img/notification.svg"),"alt":"notifications"}})]),_vm._v(" "),(_vm.showNotification)?_c('NotificationPreview',{attrs:{"notifications":_vm.notifications},on:{"closeNotifications":function($event){_vm.showNotification = false}}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"demo-navbar-user nav-item dropdown"},[_c('b-dropdown',{attrs:{"no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('button',{staticClass:"drop-content"},[_c('div',[_vm._v(_vm._s((_vm.userName + " (" + _vm.userRole + ")")))]),_vm._v(" "),_c('svg',{style:(_vm.arrowPosition),attrs:{"width":"10","height":"6","viewBox":"0 0 10 6","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M8.1958 0.291505C8.52095 -0.033645 9.04795 -0.0341907 9.37378 0.290286C9.70055 0.615714 9.7011 1.1446 9.375 1.4707L5 5.8457L0.625001 1.4707C0.298897 1.1446 0.299445 0.615714 0.626223 0.290286C0.952046 -0.0341908 1.47905 -0.033645 1.8042 0.291505L5 3.4873L8.1958 0.291505Z","fill":"#8F9BB3"}})])])]},proxy:true}])},[_vm._v(" "),_c('b-dropdown-item',{attrs:{"href":"#"},on:{"click":_vm.logout}},[_vm._v("Выход")])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }