<template>
  <div class="user-notifications">
    <div class="user-notifications-filters">
      <NotificationFilter
        @filter="getNotifications"
        @markAll="markAllNotifications"
        @read="readNotifications"
        @delete="deleteNotifications"
        :markSome="isMarkedSome"
        :markAll="isMarkedAll"
      />
    </div>
    <img class="loader" src="../../assets/img/ES/loader.svg" alt="loader" v-show="loading" />
    <div id="user-notifications-cards" class="user-notifications-cards" v-if="notifications.length">
      <div class="user-notifications-cards__item" v-for="item in notifications" :key="item.id">
        <NotificationCard :item="item" :mark="isMarked(item.id)" @setMark="setMark" />
      </div>
    </div>
    <div class="user-notifications-none" v-else v-show="!loading">
      <span>Оповещения отсутствуют</span>
    </div>
    <div class="user-notifications-pagination" v-show="notifications.length">
      <NotificationPagination
        class="paginator"
        :currentPage="currentPage"
        :totalPages="totalPages"
        @firstPageClick="goFirstPage"
        @prevPageClick="goPreviousPage"
        @nextPageClick="goNextPage"
        @lastPageClick="goLastPage"
        @changedPerPage="changePerPageCount"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { domain, MicroServices } from '@/globalVariables'
import NotificationFilter from '@/views/Notifications/components/NotificationFilter'
import NotificationCard from '@/views/Notifications/components/NotificationCard'
import NotificationPagination from '@/views/Notifications/components/NotificationPagination'

export default {
  name: 'Notifications',
  components: { NotificationCard, NotificationFilter, NotificationPagination },
  data() {
    return {
      notifications: [],
      markAll: false,
      marks: [],
      loading: false,
      currentPage: null,
      totalCount: null,
      perPage: 25,
      from: 1
    }
  },
  mounted() {
    this.getNotifications()
  },
  computed: {
    /**
     * Возвращает true если выбраны все оповещения
     * @returns {boolean|number}
     */
    isMarkedAll() {
      return this.marks.length === this.notifications.length && this.notifications.length
    },

    /**
     * Возвращает true если выбрано хотя бы одно оповещение
     * @returns {number}
     */
    isMarkedSome() {
      return this.marks.length
    },

    /**
     * Возвращает количество страниц
     * @returns {number}
     */
    totalPages() {
      return Math.ceil(this.totalCount / this.perPage)
    }
  },

  methods: {
    /**
     * Получить оповещения с фильтрацией (для админа и манагера)
     * @param ids
     */
    getNotifications(ids, filterChanged = false) {
      this.loading = true
      if (ids && !ids.length) {
        ids = undefined
      }
      if (filterChanged) {
        this.from = 1
      }
      axios({
        url: `${domain}/${MicroServices[0].prefix}/UserNotification/getItems`,
        method: 'GET',
        params: {
          _from: this.from,
          _count: this.perPage,
          kls_notification_type_id: JSON.stringify(ids)
        }
      })
        .then((response) => {
          this.notifications = response.data.data.items
          this.currentPage = response.data.data.current_page
          this.totalCount = response.data.data.total_count
          this.loading = false
          document.getElementById('user-notifications-cards').scroll({ top: 0 })
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Пометить оповещения как прочитанные
     */
    readNotifications() {
      this.marks.forEach((item, index) => {
        axios({
          url: `${domain}/monolit/UserNotification/update/${item}`,
          method: 'PUT',
          data: [
            {
              is_read: true
            }
          ]
        }).then(() => {
          if (this.marks.length === index + 1) {
            this.getNotifications()
            this.$root.$emit('readNotifications')
            this.marks = []
          }
        })
      })
    },

    /**
     * Удалить оповещения
     */
    deleteNotifications() {
      this.loading = true
      this.marks.forEach((item, index) => {
        axios({
          url: `${domain}/monolit/UserNotification/delete/${item}`,
          method: 'DELETE'
        }).then(() => {
          if (this.marks.length === index + 1) {
            this.getNotifications()
            this.marks = []
          }
        })
      })
    },

    /**
     * Выбрать все оповещения
     * @param value
     */
    markAllNotifications(value) {
      if (value) {
        this.marks = []
        this.marks = this.notifications.map((item) => {
          return item.id
        })
      } else {
        this.marks = []
      }
    },

    /**
     * Выбрать одно оповещение
     * @param id
     * @param value
     */
    setMark(id, value) {
      if (value) {
        this.marks.push(id)
      } else {
        this.marks.splice(this.marks.indexOf(id), 1)
      }
    },

    /**
     * Возвращает true если текущее оповещение выбрано
     * @param id
     * @returns {number}
     */
    isMarked(id) {
      return this.marks.filter((item) => item === id).length
    },

    /**
     * Переход на первую страницу
     */
    goFirstPage() {
      if (this.from !== 1) {
        this.from = 1
        this.getNotifications()
      }
    },

    /**
     * Переход на предыдыщую страницу
     */
    goPreviousPage() {
      if (this.currentPage > 1) {
        this.from -= this.perPage
        this.getNotifications()
      }
    },

    /**
     * Переход на следующую страницу
     */
    goNextPage() {
      if (this.currentPage < this.totalPages) {
        this.from += this.perPage
        this.getNotifications()
      }
    },

    /**
     * Переход на последнюю страницу
     */
    goLastPage() {
      if (this.currentPage !== this.totalPages) {
        this.from = this.perPage * this.totalPages
        this.getNotifications()
      }
    },

    /**
     * Смена количества записей на странице
     * @param newPerPageCount
     */
    changePerPageCount(newPerPageCount) {
      this.currentPage = 1
      this.from = 1
      this.perPage = newPerPageCount
      this.getNotifications()
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../styleVariables';
.user-notifications {
  background-color: $main-white;
  border-radius: 8px;
  width: 786px;
  margin: 24px auto;
  position: relative;
  min-height: 30vh;

  &-filters {
    border-bottom: 1px solid #dee6f5;
  }
  &-cards {
    max-height: 80vh;
    overflow-y: auto;
  }
  &-cards__item {
    border-bottom: 1px solid #dee6f5;
    &:last-child {
      border-color: transparent;
    }
  }
  &-none {
    padding: 24px;
    display: flex;
    justify-content: center;
    span {
      color: $main-gray;
    }
  }
}
.loader {
  position: absolute;
  animation-name: rotate;
  animation-duration: 0.7s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  width: 43px;
  top: 50%;
  left: 50%;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 800px) {
  .user-notifications {
    width: auto;
    margin: 8px 16px 30px 16px;
    &-cards {
      max-height: 75vh;
    }
  }
}
</style>
