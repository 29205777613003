<template>
  <div class="modal-background" :style="dayId === 1 ? 'left: 0' : ''">
    <div class="info-modal-container">
      <div class="info-modal-header">
        <span
          v-if="
            modalInfo.kls_type_code !== 'TEACHER_TEST' &&
            modalInfo.kls_type_code !== 'TEACHER_FIRST_TEST'
          "
          >Занятие {{ modalInfo.sequence_number }}</span
        >
        <span v-else>{{ modalInfo.kls_type_name }}</span>
        <button @click.stop="$emit('hideInfoModal')">
          <img :src="require(`../../../../assets/img/icons/close_icon.svg`)" />
        </button>
      </div>
      <div class="info-modal-schedule">
        <div class="info-modal-date">
          <img src="../../../../assets/img/icons/calendar-dark.svg" />
          <span>{{ formatDate(modalInfo.date) }}</span>
        </div>
        <div class="info-modal-time">
          <img src="../../../../assets/img/icons/alarm.svg" />
          <span>{{ formatTime(modalInfo.hour) }}</span>
        </div>
      </div>
      <div class="info-modal-lesson">
        <div class="lesson-status">
          <span>Status</span>
          <div
            :class="{
              lessonFinished: modalInfo.kls_status_code === 'LESSON_ENDED',
              lessonPlanned: modalInfo.kls_status_code === 'LESSON_PLANNED',
              lessonMissedByStudent: modalInfo.kls_status_code === 'SKIPPED_STUDENT',
              lessonMissedByStudentWithReason:
                modalInfo.kls_status_code === 'SKIPPED_STUDENT_WITH_REASON',
              lessonMissedByTeacher: modalInfo.kls_status_code === 'SKIPPED_TEACHER',
              lessonCanceled: modalInfo.kls_status_code === 'CANCELLED'
            }"
          >
            {{ modalInfo.kls_status_name }}
          </div>
        </div>
        <div class="lesson-type" v-if="$cookie.get('internalName') !== 'student'">
          <span>Type</span>
          <div
            :class="{
              regularLesson:
                modalInfo.kls_type_code === 'REGULAR' ||
                modalInfo.kls_type_code === 'TEACHER_TEST_REGULAR_LESSON',
              teacherTesting:
                modalInfo.kls_type_code === 'TEACHER_TEST' ||
                modalInfo.kls_type_code === 'TEACHER_FIRST_TEST',
              placementTest: modalInfo.kls_type_code === 'PLACEMENT_TEST',
              checkUpLesson: modalInfo.kls_type_code === 'CHECK_UP',
              progressTest:
                modalInfo.kls_type_code === 'CHECK_PROGRESS' ||
                modalInfo.kls_type_code === 'TEACHER_TEST_PROGRESS_TEST_LESSON'
            }"
          >
            {{ modalInfo.kls_type_name }}
          </div>
        </div>
        <div
          class="teacher-name"
          v-if="
            modalInfo.student_name &&
            (($cookie.get('internalName') === 'teacher' &&
              modalInfo.kls_type_code !== 'TEACHER_TEST' &&
              modalInfo.kls_type_code !== 'TEACHER_FIRST_TEST') ||
              $cookie.get('internalName') === 'manager' ||
              $cookie.get('internalName') === 'admin' ||
              $cookie.get('internalName') === 'methodologist')
          "
        >
          <span class="name-label" v-if="modalInfo.student_role"
            >{{
              modalInfo.student_role[0].toUpperCase() + modalInfo.student_role.slice(1)
            }}
            name</span
          >
          <span class="name">{{ modalInfo.student_name }}</span>
        </div>
        <div
          class="teacher-name"
          v-if="
            ($cookie.get('internalName') === 'manager' ||
              $cookie.get('internalName') === 'student' ||
              ($cookie.get('internalName') === 'teacher' &&
                (modalInfo.kls_type_code === 'TEACHER_TEST' ||
                  modalInfo.kls_type_code === 'TEACHER_FIRST_TEST')) ||
              $cookie.get('internalName') === 'admin') &&
            modalInfo.teacher_name
          "
        >
          <span class="name-label" v-if="modalInfo.teacher_role"
            >{{
              modalInfo.teacher_role[0].toUpperCase() + modalInfo.teacher_role.slice(1)
            }}
            name</span
          >
          <span class="name">{{ modalInfo.teacher_name || '' }}</span>
        </div>
        <!--        <div-->
        <!--          class="teacher-name"-->
        <!--          v-if="-->
        <!--            ($cookie.get('internalName') === 'manager' ||-->
        <!--              $cookie.get('internalName') === 'teacher') &&-->
        <!--            modalInfo.methodologist_name-->
        <!--          "-->
        <!--        >-->
        <!--          <span class="name-label">Methodist name</span>-->
        <!--          <span class="name">{{ modalInfo.methodologist_name || '' }}</span>-->
        <!--        </div>-->
        <div
          class="teacher-name"
          v-if="
            $cookie.get('internalName') !== 'student' &&
            !(
              $cookie.get('internalName') === 'teacher' &&
              (modalInfo.kls_type_code === 'TEACHER_TEST' ||
                modalInfo.kls_type_code === 'TEACHER_FIRST_TEST')
            )
          "
        >
          <span class="name-label">Ставка</span>
          <div class="price-container">
            <div class="price-values">
              <span class="wage">{{ modalInfo.wage_rate || 0 }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="info-modal-more-btn">
        <button @click="goToLessonCard">Подробнее</button>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

export default {
  name: 'ModalTemplate',
  mixins: [],
  props: ['chosenCardId', 'openModalFlag', 'modalInfo', 'dayId'],
  components: {},
  data() {
    return {
      clickedCardId: null
    }
  },
  mounted() {},
  methods: {
    hideModal() {
      this.$emit('hideInfoModal')
    },
    getWage(wage, bonus) {
      return wage + bonus
    },
    formatDate(time) {
      return time.slice(0, 10)
    },
    formatTime(time) {
      return time + ':00' + ' - ' + (time + 1) + ':00'
    },

    /**
     * Перейти к карточке занятия
     */
    goToLessonCard() {
      this.$router.push({ name: 'LessonCard', params: { lesson_id: this.modalInfo.lesson_id } })
    }
  },
  computed: {},
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
}
</script>

<style scoped lang="scss">
@import './src/styleVariables';
.modal-background {
  top: 100%;
  left: -161px;
  position: absolute;
  z-index: 17;
  box-shadow: 1px 0px 20px -7px rgba(0, 0, 0, 0.16);
  .info-modal-container {
    width: 372px;
    min-height: 240px;
    height: auto;
    padding: 15px 18px;
    background-color: $main-white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    .info-modal-header {
      display: flex;
      justify-content: space-between;
      padding: 0;
      width: 100%;
      span {
        font-size: 18px;
        color: $main-dark;
      }
      button {
        outline: none;
        user-select: none;
      }
    }
    .info-modal-schedule {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 14px;
      .info-modal-date {
        margin-right: 26px;
        img {
          margin-right: 9px;
        }
        span {
          color: $main-dark;
        }
      }
      .info-modal-time {
        img {
          margin-right: 9px;
        }
        span {
          color: $main-dark;
        }
      }
    }
    .info-modal-lesson {
      width: 100%;
      margin-top: 22px;
      .lesson-status {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        align-items: center;
        span {
          color: $main-dark;
          margin-right: 26px;
        }
        .lessonFinished {
          background-color: rgba(29, 190, 64, 0.13);
          padding: 3px 12px;
          border-radius: 5px;
          color: $main-dark;
        }
        .lessonPlanned {
          background-color: rgba(75, 97, 173, 0.13);
          padding: 3px 12px;
          border-radius: 5px;
          color: $main-dark;
        }
        .lessonMissedByStudent {
          background-color: rgba(243, 210, 50, 0.13);
          padding: 3px 12px;
          border-radius: 5px;
          color: $main-dark;
        }
        .lessonMissedByStudentWithReason {
          background-color: rgba(238, 0, 200, 0.13);
          padding: 3px 12px;
          border-radius: 5px;
          color: $main-dark;
        }
        .lessonMissedByTeacher {
          background-color: rgba(28, 9, 255, 0.13);
          padding: 3px 12px;
          border-radius: 5px;
          color: $main-dark;
        }
        .lessonCanceled {
          background-color: rgba(206, 87, 25, 0.13);
          padding: 3px 12px;
          border-radius: 5px;
          color: $main-dark;
        }
      }
      .lesson-type {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        align-items: center;
        margin-top: 16px;
        span {
          color: $main-dark;
          margin-right: 26px;
        }
        .regularLesson {
          background-color: rgba(29, 93, 190, 0.13);
          padding: 3px 12px;
          border-radius: 5px;
          color: $main-dark;
        }
        .teacherTesting {
          background-color: rgba(29, 190, 64, 0.13);
          padding: 3px 12px;
          border-radius: 5px;
          color: $main-dark;
        }
        .placementTest {
          background-color: rgba(255, 107, 0, 0.13);
          padding: 3px 12px;
          border-radius: 5px;
          color: $main-dark;
          white-space: nowrap;
        }
        .checkUpLesson {
          background-color: rgba(255, 184, 0, 0.13);
          padding: 3px 12px;
          border-radius: 5px;
          color: $main-dark;
        }
        .progressTest {
          background-color: rgba(82, 0, 255, 0.13);
          padding: 3px 12px;
          border-radius: 5px;
          color: $main-dark;
        }
      }
      .teacher-name {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        margin-top: 17px;
        .name-label {
          color: $main-dark;
          min-width: 128px;
          text-align: start;
          margin-right: 12px;
        }
        .name {
          color: $main-dark;
          font-family: $font-regular;
        }
        .price-container {
          display: flex;
          color: $main-dark;
          flex-direction: column;
          min-height: 50px;
          .price-values {
            display: flex;
          }
          .prompt {
            width: 100%;
            background-color: $main-gray;
            padding: 2px 5%;
            border-radius: 3px;
            position: relative;
          }
          .prompt:after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border: 7px solid transparent;
            border-bottom-color: $main-gray;
            border-top: 0;
            margin-top: -7px;
            left: 35%;
          }
          .wage {
            display: flex;
            flex-direction: column;
            margin-right: 4px;
          }
          .bonus {
            margin-left: 4px;
            margin-right: 4px;
          }
          .sum {
            margin-left: 4px;
          }
        }
      }
    }
    .info-modal-more-btn {
      display: flex;
      margin-top: auto;
      justify-content: flex-end;
      align-items: flex-end;
      button {
        color: $main-dark;
        outline: none;
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .modal-background {
    .info-modal-container {
      .info-modal-more-btn {
        justify-content: center;
        button {
          color: $bright-blue;
        }
      }
    }
  }
}
</style>
