export default {
  data() {
    return {
      userText: null
    }
  },
  computed: {
    /**
     * Проверка на валидность данных
     * Строится на основе метаДанных, для пример с блокировкой элемента смотреть виджет кнопки
     * @return {boolean}
     */
    invalid() {
      if (this.required && !this.value && this.value !== null) {
        this.userText = 'Это поле обязательно для заполнения!'
        return true
      }
      if (this.regex) {
        let re = new RegExp(this.regex.regex, this.regex.flags)
        if (!re.test(this.value)) {
          this.userText = this.userDescription
          return true
        }
      }
      if (this.type === 'number' || this.type === 'float' || this.type === 'int') {
        return false
      }
      if (this.max && this.value) {
        if (this.max < this.value.length) {
          this.userText = `Не должно быть символов больше ${this.max}`
          return true
        }
      }
      if (this.min && this.value) {
        if (this.min > this.value.length || !this.value) {
          this.userText = `Символов должно быть больше ${this.min}`
          return true
        }
      }
    }
  }
}
