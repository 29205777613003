<template>
  <div class="admin-statistic">
    <div class="admin-statistic__item">
      <Donut
        header="Статистика по типу занятий"
        :sections="lessonTypeSections"
        :links="lessonTypeLinks"
        :thickness="100"
      />
    </div>
    <div class="admin-statistic__item">
      <Donut
        header="Статистика по финансам"
        :sections="financeSections"
        :links="financeLinks"
        :thickness="100"
      />
    </div>
  </div>
</template>

<script>
import Donut from '@/views/Profile/components/Statistic/Donut'

import { domain, MicroServices } from '@/globalVariables'
import axios from 'axios'
import scriptMixins from '@/components/scriptMixins/scriptMixins'

export default {
  name: 'AdminStatistic',
  components: { Donut },
  mixins: [scriptMixins],
  data() {
    return {
      colors: {
        green: '#45C661',
        blue: '#567ECA',
        yellow: '#FAD82E',
        red: '#F66A2E',
        orange: '#FA9862',
        violet: '#CD6EDC'
      },
      adminUid: '00000000-0000-0000-0000-000000000000',
      lessonTypeSections: [],
      lessonTypeLinks: [
        {
          name: 'Placement tests',
          color: '#3264C3'
        },
        {
          name: 'Regular lessons',
          color: '#1DBE40'
        },
        {
          name: 'Check up lessons',
          color: '#FFD500'
        },
        {
          name: 'Progress tests',
          color: '#FF8541'
        },
        {
          name: 'Teacher Testing',
          color: '#E13429'
        }
      ],
      financeSections: [],
      financeLinks: [
        {
          name: 'Купленные пакеты',
          color: '#3264C3'
        },
        {
          name: 'Возврат средств',
          color: '#1DBE40'
        }
      ],
      startDate: null,
      endDate: null
    }
  },
  mounted() {
    this.setDefaultDate()
    this.getLessonTypeStatistic()
    this.getFinanceStatistic()

    this.$root.$on('filterAdminStatistic', (start, end) => {
      this.getLessonTypeStatistic(start, end)
      this.getFinanceStatistic(start, end)
    })
  },
  beforeDestroy() {
    this.$root.$off('filterAdminStatistic')
  },
  methods: {
    /**
     * Установить начальное значение фильтров по дате (на сегодня)
     */
    setDefaultDate() {
      let today = this.countUserTimeByTimeZone(this.$cookie.get('userTimeZone'))
      this.startDate = `${new Date(today).getFullYear()}-${
        new Date(today).getMonth() + 1 < 10
          ? '0' + (new Date(today).getMonth() + 1)
          : new Date(today).getMonth() + 1
      }-${
        new Date(today).getDate() < 10 ? '0' + new Date(today).getDate() : new Date(today).getDate()
      }`
      this.endDate = this.startDate
    },

    /**
     * Получить статистику по типу занятий
     * @param start
     * @param end
     */
    getLessonTypeStatistic(start, end) {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/LessonTypesStatistic/getItems`,
        method: 'GET',
        params: {
          manager_uuid:
            this.$cookie.get('internalName') === 'manager'
              ? this.$cookie.get('uid')
              : this.adminUid,
          begin_date: start || this.startDate,
          end_date: end || this.endDate
        }
      })
        .then((response) => {
          let data = response.data.data[0]
          this.groupLessonTypeSection(data)
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Получить статистику по финансам
     * @param start
     * @param end
     */
    getFinanceStatistic(start, end) {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/FinanceStatistic/getItems`,
        method: 'GET',
        params: {
          manager_uuid:
            this.$cookie.get('internalName') === 'manager'
              ? this.$cookie.get('uid')
              : this.adminUid,
          begin_date: start || this.startDate,
          end_date: end || this.endDate
        }
      }).then((response) => {
        let data = response.data.data[0]
        this.groupFinanceStatistic(data)
      })
    },

    /**
     * Получение секций и надписей для легенды для статистики по типам занятий
     * @param data
     * @returns {null}
     */
    groupLessonTypeSection(data) {
      this.lessonTypeSections = []
      delete data.amount_total
      delete data.date

      let values = Object.values(data)
      let sum = 0
      values.forEach((item) => {
        sum += Number(item)
      })

      this.lessonTypeLinks[0].value = data.amount_placement_test
      this.lessonTypeLinks[1].value = data.amount_regular
      this.lessonTypeLinks[2].value = data.amount_check_up
      this.lessonTypeLinks[3].value = data.amount_check_progress
      this.lessonTypeLinks[4].value = data.amount_teacher_test

      if (!sum) {
        return null
      }

      this.lessonTypeSections.push({
        value: Number(((100 * data.amount_placement_test) / sum).toFixed(10)),
        color: data.amount_placement_test ? this.colors.blue : 'transparent'
      })
      this.lessonTypeSections.push({
        value: Number(((100 * data.amount_regular) / sum).toFixed(10)),
        color: data.amount_regular ? this.colors.green : 'transparent'
      })
      this.lessonTypeSections.push({
        value: Number(((100 * data.amount_check_up) / sum).toFixed(10)),
        color: data.amount_check_up ? this.colors.yellow : 'transparent'
      })
      this.lessonTypeSections.push({
        value: Number(((100 * data.amount_check_progress) / sum).toFixed(10)),
        color: data.amount_check_progress ? this.colors.orange : 'transparent'
      })
      this.lessonTypeSections.push({
        value: Number(((100 * data.amount_teacher_test) / sum).toFixed(10)),
        color: data.amount_teacher_test ? this.colors.red : 'transparent'
      })
    },

    /**
     * Получение секций и надписей для легенды по финансам
     * @param data
     * @returns {null}
     */
    groupFinanceStatistic(data) {
      this.financeSections = []
      this.financeLinks[0].value = data.amount_purchase
      this.financeLinks[1].value = data.amount_refund

      delete data.amount_total
      let values = Object.values(data)
      let sum = 0
      values.forEach((item) => {
        sum += Number(item)
      })

      if (!sum) {
        return null
      }

      this.financeSections.push({
        value: Number(((100 * data.amount_purchase) / sum).toFixed(10)),
        color: data.amount_purchase ? this.colors.blue : 'transparent'
      })
      this.financeSections.push({
        value: Number(((100 * data.amount_refund) / sum).toFixed(10)),
        color: data.amount_refund ? this.colors.green : 'transparent'
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../../styleVariables';

.admin-statistic {
  background-color: $main-white;
  box-shadow: 1px 0px 20px -7px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  padding: 14px 24px;
  &__item {
    margin-bottom: 46px;
  }
}
</style>
