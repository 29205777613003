export default {
  methods: {
    Tool__click(data) {
      this.$store.commit('newRec', data)
      if (data.editing) {
        this.$root.$emit(`editCommentPush`, data)
      } else if (data.reply) {
        this.$root.$emit(`replyCommentPush`, data)
      } else {
        this.$root.$emit(`sendCommentPush`, data)
      }
      this.$root.$emit(`showCancelComments`)
      data.quill.root.innerHTML = ''
    }
  },
  computed: {},
  data() {
    return {
      content: ''
    }
  },
  watch: {}
}
