<template>
  <div class="status-container">
    <div
      class="status"
      :style="`background-color: ${getBgColor(item.code)}`"
      v-for="(item, index) in items"
      :key="index"
      @click="$emit('chooseStatus', item.id, item.code, item.name)"
    >
      {{ item.name }}
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { domain, MicroServices } from '@/globalVariables'
export default {
  name: 'Statuses',
  props: {
    currentType: String,
    currentStatus: String
  },
  data() {
    return {
      items: []
    }
  },
  mounted() {
    /**
     * Получить все статусы
     */
    axios({
      url: `${domain}/${MicroServices[0].prefix}/Lesson/getLessonStatuses`,
      method: 'POST',
      data: [
        {
          lesson_type_code: this.currentType === 'TEACHER_TEST' ? this.currentType : 'OTHER'
        }
      ]
    })
      .then((response) => {
        this.items = response.data.data[this.currentStatus]
      })
      .catch((error) => {
        this.errorAlert(error)
      })
  },
  methods: {
    /**
     * Получить цвет фона в зависимости от статуса
     * @param code
     * @returns {string}
     */
    getBgColor(code) {
      switch (code) {
        case 'LESSON_PLANNED':
          return 'rgba(75, 97, 173, 0.13);'
        case 'LESSON_ENDED':
          return 'rgba(29, 190, 64, 0.13);'
        case 'SKIPPED_STUDENT':
          return 'rgba(243, 210, 50, 0.13);'
        case 'SKIPPED_STUDENT_WITH_REASON':
          return 'rgba(238, 0, 200, 0.13);'
        case 'SKIPPED_TEACHER':
          return 'rgba(28, 9, 255, 0.13);'
        case 'CANCELLED':
          return 'rgba(206, 87, 25, 0.13);'
        default:
          return 'rgba(255, 255, 255, 1);'
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../styleVariables';

.status-container {
  background: $main-white;
  border: 0.5px solid $light-gray;
  box-shadow: 1px 0 20px -7px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  padding: 10px 8px 14px 8px;

  .status {
    cursor: pointer;
    padding: 4px 12px;
    margin-bottom: 10px;
    border-radius: 5px;
    width: fit-content;
  }
}
</style>
