export default {
  methods: {
    Change__Phone(data) {
      this.$store.commit('newRec', data)
    }
  },
  data() {
    return {
      inputPhone: {
        placeholder: this.placeholder,
        tooltip: this.tooltip,
        pattern: this.pattern,
        value: this.value,
        disabled: this.disabled,
        text: this.text,
        maxlength: this.maxlength,
        keyAdd: this.keyAdd
      }
    }
  }
}
