<template>
  <div class="mobile-days-filter">
    <div
      class="mobile-days-filter__item"
      :class="{ active: chosenDay === index, 'current-day': isCurrentDay(date.date) }"
      v-for="(date, index) in dates"
      :key="index"
      @click="$emit('filter', index)"
    >
      <div class="number" v-if="showNumber">{{ formatDate(date.date) }}</div>
      <div class="name">{{ date.day.day_short_name }}</div>
    </div>
  </div>
</template>

<script>
import ScheduleEvents from '@/views/Schedule/ScheduleEvents'

export default {
  name: 'MobileDaysFilter',
  props: {
    dates: Array,
    chosenDay: Number,
    showNumber: Boolean
  },
  mixins: [ScheduleEvents],
  data() {
    return {}
  },
  methods: {
    formatDate(date) {
      return date.slice(8, date.length)
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../../../styleVariables';

.mobile-days-filter {
  display: flex;
  justify-content: center;
  &__item {
    width: 36px;
    height: 48px;
    margin-right: 6px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .number {
      font-family: $font-bold;
      color: $main-dark;
    }
    .name {
      font-family: $font-regular;
      color: $main-gray;
    }
  }

  .current-day {
    background-color: $main-white;
    border-radius: 4px;
    border: 1px solid $bright-blue;

    .number,
    .name {
      color: $bright-blue;
    }
  }
  .active {
    background-color: $bright-blue;

    .number,
    .name {
      color: $main-white;
    }
  }
}
</style>
