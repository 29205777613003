<template>
  <div class="non-working-days">
    <div class="bg-white">
      <div class="header">
        <div class="header-label">Нерабочий период</div>
        <div
          class="add-rest-button"
          v-if="type === 'manager' && !editMode && !isAddRest"
          @click="isAddRest = true"
        >
          <img src="../../../assets/img/ES/plus_big.svg" alt="add" />
          <span>Добавить</span>
        </div>
        <div class="user-time-zone" v-else-if="timeZone">
          Вам необходимо учесть тайм-зону пользователя: {{ timeZone }}
        </div>
      </div>
      <div class="table-head" v-if="days.length || isAddRest">
        <div class="table-head__item">Тип</div>
        <div class="table-head__item">Период</div>
      </div>
    </div>

    <div class="table-content__add" v-if="isAddRest">
      <div class="type">
        <vue-select :options="types" @open="getRestTypes" v-model="selectedType" :clearable="false">
          <template slot="option" slot-scope="option">
            <div class="d-center">
              <span>{{ option.name }}</span>
            </div>
          </template>
          <template slot="selected-option">
            <div class="selected d-center">
              {{ selectedType.name }}
            </div>
          </template>
        </vue-select>
      </div>
      <div class="period">
        <div
          class="period-start"
          :class="{ 'error-date': new Date(startDate) > new Date(endDate) && endDate && startDate }"
        >
          <date-pick
            v-model="startDate"
            :pickTime="true"
            :format="'YYYY-MM-DD HH:mm'"
            :displayFormat="'DD.MM.YYYY HH:mm'"
            :inputAttributes="{ readonly: true, placeholder: 'Дата начала' }"
            :clearable="false"
          ></date-pick>
        </div>
        <div
          class="period-end"
          :class="{ 'error-date': new Date(startDate) > new Date(endDate) && startDate && endDate }"
        >
          <date-pick
            v-model="endDate"
            :pickTime="true"
            :format="'YYYY-MM-DD HH:mm'"
            :displayFormat="'DD.MM.YYYY HH:mm'"
            :inputAttributes="{ readonly: true, placeholder: 'Дата окончания' }"
            :clearable="false"
          ></date-pick>
        </div>
      </div>
      <div class="controls-button">
        <button
          class="save"
          :disabled="
            !startDate ||
            !endDate ||
            selectedType.value === null ||
            new Date(startDate) > new Date(endDate)
          "
          @click="createRest"
        >
          <img src="../../../assets/img/ES/big_white_check.svg" alt="save" />
        </button>
        <button class="cancel" @click="cancelAddRest">
          <img src="../../../assets/img/ES/big_blue_cross.svg" alt="cancel" />
        </button>
      </div>
    </div>
    <div class="table-info" v-if="days.length">
      <div class="table-content" v-for="(day, index) in days" :key="index">
        <div class="table-content__item">
          <span v-show="!editMode || editableDayId !== day.id">{{ day.rest_type.name }}</span>
          <div class="type" v-show="editMode && editableDayId === day.id">
            <vue-select
              :options="types"
              @open="getRestTypes"
              v-model="selectedType"
              :clearable="false"
            >
              <template slot="option" slot-scope="option">
                <div class="d-center">
                  <span>{{ option.name }}</span>
                </div>
              </template>
              <template slot="selected-option">
                <div class="selected d-center">
                  {{ selectedType.name }}
                </div>
              </template>
            </vue-select>
          </div>
        </div>
        <div class="table-content__item">
          <span v-show="!editMode || editableDayId !== day.id"
            >{{ formatDateToDisplay(day.rest_beg) }} - {{ decrementDate(day.rest_end) }}</span
          >
          <div class="period" v-show="editMode && editableDayId === day.id">
            <div
              class="period-start"
              :class="{
                'error-date': new Date(startDate) > new Date(endDate) && endDate && startDate
              }"
            >
              <date-pick
                v-model="startDate"
                :format="'YYYY-MM-DD HH:mm'"
                :displayFormat="'DD.MM.YYYY HH:mm'"
                :pickTime="true"
                :inputAttributes="{ readonly: true, placeholder: 'Дата начала' }"
                :clearable="false"
              ></date-pick>
            </div>
            <div
              class="period-end"
              :class="{
                'error-date': new Date(startDate) > new Date(endDate) && startDate && endDate
              }"
            >
              <date-pick
                v-model="endDate"
                :format="'YYYY-MM-DD HH:mm'"
                :displayFormat="'DD.MM.YYYY HH:mm'"
                :pickTime="true"
                :inputAttributes="{ readonly: true, placeholder: 'Дата окончания' }"
                :clearable="false"
              ></date-pick>
            </div>
          </div>
        </div>
        <div
          class="table-content__edit"
          v-if="type === 'manager' && (!editMode || editableDayId !== day.id)"
          @click="setEditMode(day)"
        >
          <img src="../../../assets/img/ES/edit_gray.svg" alt="edit" />
        </div>
        <div
          class="table-content__delete"
          v-if="type === 'manager' && (!editMode || editableDayId !== day.id)"
          @click="deleteRest(day)"
        >
          <img src="../../../assets/img/ES/close_gray.svg" alt="delete" />
        </div>

        <div
          class="controls-button"
          v-else-if="type === 'manager' && editMode && editableDayId === day.id"
        >
          <button
            class="save"
            :disabled="
              !startDate ||
              !endDate ||
              selectedType.value === null ||
              new Date(startDate) > new Date(endDate)
            "
            @click="updateRest(day.id)"
          >
            <img src="../../../assets/img/ES/big_white_check.svg" alt="save" />
          </button>
          <button class="cancel" @click="cancelEdit">
            <img src="../../../assets/img/ES/big_blue_cross.svg" alt="cancel" />
          </button>
        </div>
      </div>
    </div>
    <div class="table-empty" v-if="!isAddRest && !days.length">
      <span>Нет записей</span>
    </div>
  </div>
</template>

<script>
import VueSelect from 'vue-select'
import DatePick from 'vue-date-pick'
import 'vue-date-pick/dist/vueDatePick.css'
import axios from 'axios'
import { domain } from '@/globalVariables'

export default {
  name: 'NonWorkingDays',
  props: {
    days: Array,
    type: String,
    uuid: String,
    timeZone: String
  },
  components: { VueSelect, DatePick },
  data() {
    return {
      isAddRest: false,
      types: [],
      selectedType: {
        name: 'Выберите тип',
        value: null
      },
      startDate: null,
      endDate: null,
      editMode: false,
      editableDayId: null
    }
  },
  methods: {
    formatDateToDisplay(date) {
      let arrDate = date.split('-')
      return `${arrDate[2].split(' ')[0]}.${arrDate[1]}.${arrDate[0]} ${arrDate[2].split(' ')[1]}`
    },
    /**
     * Отменить добавление нерабочих дней
     */
    cancelAddRest() {
      this.isAddRest = false
      this.selectedType = {
        name: 'Выберите тип',
        value: null
      }
      this.startDate = ''
      this.endDate = ''
    },

    /**
     * Отменить редактирование нерабочих дней
     */
    cancelEdit() {
      this.editMode = false
      this.selectedType = {
        name: 'Выберите тип',
        value: null
      }
      this.startDate = ''
      this.endDate = ''
    },

    /**
     * Форматирование даты с уменьшением дня на 1
     * @param date
     * @returns {string}
     */
    formatDateWithDecrement(date) {
      let formatted = this.decrementDate(date).split('.')
      return `${formatted[2].split(' ')[0]}-${formatted[1]}-${formatted[0]} ${
        formatted[2].split(' ')[1]
      }`
    },

    /**
     * Установить редактирование нерабочих дней
     * @param day
     */
    setEditMode(day) {
      this.startDate = day.rest_beg
      this.endDate = this.formatDateWithDecrement(day.rest_end)
      this.selectedType = day.rest_type
      this.editMode = true
      this.editableDayId = day.id
      this.isAddRest = false
    },

    /**
     * Получить типы нерабочих дней
     */
    getRestTypes() {
      axios({
        url: `${domain}/monolit/Classifier/getRestType`,
        method: 'GET'
      })
        .then((response) => {
          this.types = response.data.data.items
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Отредактировать нерабочие дни
     * @param id
     */
    updateRest(id) {
      axios({
        url: `${domain}/monolit/Rest/update/${id}`,
        method: 'POST',
        data: [
          {
            kls_rest_type_id: this.selectedType.id,
            user_uuid: this.uuid,
            rest_beg: this.startDate,
            rest_end: this.incrementDate(this.endDate)
          }
        ]
      })
        .then(() => {
          this.successAlert('Нерабочие дни изменены!')
          this.cancelEdit()
          this.$emit('updateRest')
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Добавить нерабочие дни
     */
    createRest() {
      axios({
        url: `${domain}/monolit/Rest/create`,
        method: 'POST',
        data: [
          {
            kls_rest_type_id: this.selectedType.id,
            user_uuid: this.uuid,
            rest_beg: this.startDate,
            rest_end: this.incrementDate(this.endDate)
          }
        ]
      })
        .then(() => {
          this.successAlert('Нерабочие дни добавлены!')
          this.cancelAddRest()
          this.$emit('updateRest')
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Удалить нерабочие дни
     * @param day
     */
    deleteRest(day) {
      axios({
        url: `${domain}/monolit/Rest/delete/${day.id}`,
        method: 'POST'
      })
        .then(() => {
          this.successAlert('Нерабочие дни удалены!')
          this.$emit('updateRest')
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Увеличить день на 1
     * @param date
     * @returns {string}
     */
    incrementDate(date) {
      let incDate = new Date(date).setDate(new Date(date).getDate() + 1)
      incDate = new Date(incDate)
      incDate = `${incDate.getFullYear()}-${
        incDate.getMonth() + 1 < 10 ? '0' + (incDate.getMonth() + 1) : incDate.getMonth() + 1
      }-${incDate.getDate() < 10 ? '0' + incDate.getDate() : incDate.getDate()} ${
        date.split(' ')[1]
      }`
      return incDate
    },

    /**
     * Уменьшить день на 1
     * @param date
     * @returns {Date}
     */
    decrementDate(date) {
      let arrDate = date.split('-')
      let decDate = new Date(arrDate[0], arrDate[1] - 1, arrDate[2].split(' ')[0])
      decDate = decDate.setDate(new Date(decDate).getDate() - 1)
      decDate = new Date(decDate)
      decDate = `${decDate.getDate() < 10 ? '0' + decDate.getDate() : decDate.getDate()}.${
        decDate.getMonth() + 1 < 10 ? '0' + (decDate.getMonth() + 1) : decDate.getMonth() + 1
      }.${decDate.getFullYear()} ${date.split(' ')[1]}`
      return decDate
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../styleVariables';
.non-working-days {
  box-shadow: 1px 0px 20px -7px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  color: $main-dark;
  width: 100%;
  height: 360px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: $main-white;

  .bg-white {
    position: sticky;
    top: 0;
    background-color: $main-white;
    z-index: 2;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 14px 24px 0 24px;
    &-label {
      font-size: 18px;
      margin-bottom: 14px;
    }
    .add-rest-button {
      font-size: 14px;
      cursor: pointer;
      display: flex;
      align-items: center;
      height: fit-content;
      img {
        margin-right: 8px;
      }
    }
    .user-time-zone {
      color: $main-gray;
    }
  }

  .table-head {
    font-size: 14px;
    display: grid;
    grid-template-columns: 220px 1fr;
    padding: 6px 24px;
  }

  .table-content {
    font-size: 14px;
    display: grid;
    grid-template-columns: 220px 1fr 40px 40px;
    &__item {
      padding: 6px 24px;
      align-self: center;

      /deep/ .vs__selected .selected {
        font-family: $font-regular;
      }

      span {
        font-family: $font-regular;
      }
    }
    &__edit,
    &__delete {
      display: flex;
      align-items: center;
      opacity: 0;
      cursor: pointer;
      transition: 0.15s;
    }
    &:hover .table-content__edit {
      opacity: 1;
    }
    &:hover .table-content__delete {
      opacity: 1;
    }
    &:nth-child(2n + 1) {
      background-color: $light-table-blue;
    }

    .period {
      display: flex;
      justify-content: flex-start;
      margin-right: 12px;
      &-start {
        margin-right: 12px;
      }
      /deep/ .vdpClearInput {
        display: none;
      }
      /deep/ .vdpComponent {
        position: static;
      }
      /deep/ .vdpFloating.vdpPositionTop {
        top: unset;
      }
      /deep/ .vdpFloating.vdpPositionLeft {
        left: unset;
      }
      /deep/ .vdpFloating.vdpPositionBottom {
        bottom: unset;
      }
      /deep/ input {
        width: 160px;
        height: 32px;
        border: 0.5px solid #c5cee0;
        border-radius: 4px;
        padding: 3px 8px;
        font-size: 14px;
        font-family: $font-regular;

        &:focus {
          outline: none;
          border-color: $bright-blue;
        }

        &::placeholder {
          color: $main-gray;
        }
      }

      /deep/ .vdpHoursInput {
        width: 100%;
        padding: 0;
        border: none;
      }
      /deep/ .vdpMinutesInput {
        width: 100%;
        padding: 0;
        border: none;
      }
      /deep/ {
        .vdpTimeCaption,
        .vdpTimeSeparator {
          vertical-align: bottom;
        }
      }
    }

    .controls-button {
      display: flex;
      align-items: center;
      margin-top: -4px;
      grid-column-start: 3;
      grid-column-end: 5;
      button {
        width: 32px;
        height: 32px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .save {
        background-color: $bright-blue;
        margin-right: 6px;
      }
      .cancel {
        border: 1px solid $bright-blue;
      }
    }
    &__add {
      display: grid;
      grid-template-columns: 220px 1fr 80px;
      /deep/ .vs__selected .selected {
        font-family: $font-regular;
      }

      span {
        font-family: $font-regular;
      }
      .type {
        padding: 6px 24px;
      }
      .period {
        display: flex;
        justify-content: flex-start;
        padding: 6px 24px;
        &-start {
          margin-right: 12px;
        }
        /deep/ .vdpComponent {
          position: static;
        }
        /deep/ .vdpFloating.vdpPositionTop {
          top: unset;
        }
        /deep/ .vdpFloating.vdpPositionLeft {
          left: unset;
        }
        /deep/ .vdpFloating.vdpPositionBottom {
          bottom: unset;
        }
        /deep/ input {
          width: 160px;
          height: 32px;
          border: 0.5px solid #c5cee0;
          border-radius: 4px;
          padding: 3px 8px;
          margin-bottom: 6px;
          font-size: 14px;
          font-family: $font-regular;

          &:focus {
            outline: none;
            border-color: $bright-blue;
          }

          &::placeholder {
            color: $main-gray;
            font-family: $font-bold;
          }
        }

        /deep/ .vdpHoursInput {
          width: 100%;
          padding: 0;
          border: none;
        }
        /deep/ .vdpMinutesInput {
          width: 100%;
          padding: 0;
          border: none;
        }
        /deep/ {
          .vdpTimeCaption,
          .vdpTimeSeparator {
            vertical-align: bottom;
          }
        }
        /deep/ button {
          display: none;
        }
      }
      .controls-button {
        display: flex;
        align-items: center;
        margin-top: -5px;
        button {
          width: 32px;
          height: 32px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .save {
          background-color: $bright-blue;
          margin-right: 6px;
        }
        .cancel {
          border: 1px solid $bright-blue;
        }
      }
    }
  }

  .error-date {
    /deep/ input {
      border-color: $red !important;
    }
  }
  .table-empty {
    width: 100%;
    height: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-size: 18px;
      color: #c5cee0;
    }
  }
}
</style>
