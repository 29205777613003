<template>
  <div>
    <div class="month-schedule" v-if="data">
      <div class="weekdays">
        <div class="day" v-for="(day, index) in weekDays" :key="index">
          <span>{{ day }}</span>
        </div>
      </div>
      <div class="content">
        <div class="content-days" v-for="(date, index) in formattedData" :key="index">
          <div class="date" :class="{ 'current-day': isCurrentDay(date.date) }">
            {{ date.date ? new Date(date.date).getDate() : '' }}
          </div>
          <div
            :class="{
              'content-days__lessons': !isShowMore[index],
              'content-days__lessons__all': isShowMore[index]
            }"
          >
            <img
              class="close-show-more"
              src="../../assets/img/ES/close_big.svg"
              alt="close"
              v-show="isShowMore[index]"
              @click="isShowMore = []"
            />
            <div
              :class="{ lesson: !isShowMore[index], 'all-lessons': isShowMore[index] }"
              v-for="(card, cardIndex) in date.cards"
              :style="card.type !== 'WORKS_TIME' ? 'cursor: pointer' : ''"
              :key="cardIndex"
            >
              <div
                v-if="cardIndex < 3 || isShowMore[index]"
                class="card"
                :class="{ 'rests-time': card.type === 'RESTS_TIME' }"
                @click="openInfoModalFunc(card.lesson_id, date.day.kls_id)"
              >
                <div
                  class="type"
                  :class="{
                    done: card.kls_status_code === 'LESSON_ENDED',
                    test:
                      (card.kls_type_code === 'PLACEMENT_TEST' ||
                        card.kls_type_code === 'CHECK_UP' ||
                        card.kls_type_code === 'CHECK_PROGRESS' ||
                        card.kls_type_code === 'TEACHER_TEST_PROGRESS_TEST_LESSON' ||
                        card.kls_type_code === 'TEACHER_TEST' ||
                        card.kls_type_code === 'TEACHER_FIRST_TEST') &&
                      (card.has_filled_card || card.kls_status_code === 'LESSON_PLANNED') &&
                      card.kls_status_code !== 'LESSON_ENDED',
                    reglesson:
                      (card.kls_type_code === 'REGULAR' ||
                        card.kls_type_code === 'TEACHER_TEST_REGULAR_LESSON') &&
                      card.kls_status_code !== 'LESSON_ENDED',
                    empty:
                      !card.has_filled_card &&
                      card.type !== 'WORKS_TIME' &&
                      card.type !== 'RESTS_TIME' &&
                      card.kls_status_code !== 'LESSON_PLANNED',
                    free: card.type === 'WORKS_TIME' || card.type === 'RESTS_TIME'
                  }"
                ></div>
                <img src="../../assets/img/alarm.svg" alt="time" />
                <span>{{ formatHours(card.hour) }}</span>
                <ModalTemplate
                  @hideInfoModal="cardLessonId = null"
                  :chosenCardId="card.lesson_id"
                  :openModalFlag="openModalFlag"
                  :modalInfo="modalCardInfo"
                  :dayId="dayId"
                  v-if="cardLessonId === card.lesson_id && cardLessonId !== null"
                ></ModalTemplate>
              </div>
            </div>
            <div
              class="show-more"
              v-if="!!date.cards && date.cards.length > 3 && !isShowMore[index]"
              @click="
                isShowMore = []
                isShowMore[index] = true
              "
            >
              Еще {{ date.cards.length - 3 }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="!data" class="start-screen">
      <span
        >Пожалуйста, выберите роль и имя пользователя, расписание которого вы хотите
        просмотреть</span
      >
    </div>
  </div>
</template>

<script>
import ModalTemplate from '@/views/Schedule/components/InfoModal/ModalTemplate'
import ScheduleEvents from '@/views/Schedule/ScheduleEvents'
export default {
  name: 'ScheduleByMonth',
  components: { ModalTemplate },
  mixins: [ScheduleEvents],
  props: {
    data: Array,
    sortByType: String,
    isFilter: Boolean
  },
  data() {
    return {
      weekDays: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
      cardsCount: [],
      cardLessonId: null,
      openModalFlag: false,
      modalCardInfo: {},
      isShowMore: [],
      dayId: null
    }
  },
  mounted() {},
  methods: {
    /**
     * Открыть превью карточки занятия
     * @param id
     * @param day
     */
    openInfoModalFunc(id, day) {
      this.cardLessonId = id
      this.openModalFlag = true
      this.dayId = day
      this.getModalInfo(id)
    },
    formatHours(hour) {
      return `${Number(hour) < 10 ? '0' + Number(hour) : Number(hour)}:00 - ${
        Number(hour) < 9 ? '0' + (Number(hour) + 1) : Number(hour) + 1
      }:00`
    },

    /**
     * Получить информацию выбранного занятия
     * @param id
     */
    getModalInfo(id) {
      if (id) {
        this.data.forEach((item) => {
          item.cards &&
            item.cards.forEach((card) => {
              if (card.lesson_id === id) {
                this.modalCardInfo = card
                return null
              }
            })
        })
      }
    }
  },
  computed: {
    /**
     * Фильтрация по типам карточки
     * @returns {{date: *, cards, day: *}[]|*[]}
     */
    formattedData() {
      if (!this.sortByType) {
        return this.data
      } else {
        let info
        if (this.sortByType === 'free') {
          info = this.data.map((item) => ({
            date: item.date,
            day: item.day,
            cards: item.cards && item.cards.filter((card) => card.type === 'WORKS_TIME')
          }))
          return info
        } else if (this.sortByType === 'empty') {
          info = this.data.map((item) => ({
            date: item.date,
            day: item.day,
            cards:
              item.cards &&
              item.cards.filter(
                (card) =>
                  card.type !== 'WORKS_TIME' &&
                  card.kls_status_code === 'LESSON_ENDED' &&
                  !card.has_filled_card
              )
          }))
          return info
        } else if (this.sortByType === 'lesson') {
          info = this.data.map((item) => ({
            date: item.date,
            day: item.day,
            cards:
              item.cards &&
              item.cards.filter(
                (card) =>
                  (card.kls_type_code === 'REGULAR' ||
                    card.kls_type_code === 'TEACHER_TEST_REGULAR_LESSON') &&
                  card.kls_status_code !== 'LESSON_ENDED' &&
                  card.type !== 'WORKS_TIME'
              )
          }))
          return info
        } else if (this.sortByType === 'test') {
          info = this.data.map((item) => ({
            date: item.date,
            day: item.day,
            cards:
              item.cards &&
              item.cards.filter(
                (card) =>
                  card.kls_type_code === 'PLACEMENT_TEST' ||
                  card.kls_type_code === 'CHECK_UP' ||
                  card.kls_type_code === 'CHECK_PROGRESS' ||
                  card.kls_type_code === 'TEACHER_TEST_PROGRESS_TEST_LESSON' ||
                  card.kls_type_code === 'TEACHER_TEST' ||
                  card.kls_type_code === 'TEACHER_FIRST_TEST'
              )
          }))
          return info
        } else if (this.sortByType === 'complete') {
          info = this.data.map((item) => ({
            date: item.date,
            day: item.day,
            cards:
              item.cards &&
              item.cards.filter(
                (card) => card.kls_status_code === 'LESSON_ENDED' && card.has_filled_card
              )
          }))
          return info
        }
      }
      return this.data
    }
  },
  watch: {}
}
</script>

<style scoped lang="scss">
@import '../../styleVariables';

.month-schedule {
  overflow-y: auto;
  .weekdays {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: 61px;
    border-bottom: 1px solid $light-gray;
    border-top: 1px solid $light-gray;
    position: sticky;
    top: 0;
    background-color: $light-table-blue;
    z-index: 2;
    .day {
      font-size: 18px;
      color: $main-gray;
      border-right: 1px solid $light-gray;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .content {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-auto-rows: 148px;
    &-days {
      width: 100%;
      height: 100%;
      border-bottom: 1px solid $light-gray;
      border-right: 1px solid $light-gray;
      position: relative;

      .date {
        font-size: 18px;
        color: $main-gray;
        text-align: end;
        padding-right: 6px;
      }
      .current-day {
        background-color: $main-white;
        border-radius: 4px;
        padding: 0 8px;
        border: 1px solid $bright-blue;
        color: $bright-blue;
        width: fit-content;
        margin-left: auto;
      }

      &__lessons__all {
        position: relative;
        top: -22px;
        z-index: 20;
        padding: 12px 14px;
        background: $main-white;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
        border-radius: 4px;
        .close-show-more {
          cursor: pointer;
          position: absolute;
          top: 8px;
          right: 8px;
          width: 24px;
        }
      }

      &__lessons,
      &__lessons__all {
        .show-more {
          cursor: pointer;
          padding: 8px 13px;
        }
        .all-lessons {
          padding-right: 18px;
          .card {
            padding: 2px 8px;
            margin-bottom: 3px;
            display: flex;
            flex-direction: row;
            align-items: center;
            img {
              margin-left: 9px;
            }
            span {
              margin-left: 8px;
            }
            .type {
              width: 8px;
              height: 8px;
              border-radius: 50%;
            }
            .done {
              background-color: $lesson-ended;
            }
            .test {
              background-color: $test;
            }
            .reglesson {
              background-color: $regular-lesson;
            }
            .empty {
              background-color: $empty-card;
            }
          }

          .rests-time {
            background-color: $regular-lesson-tag;
          }
        }
        .lesson {
          padding: 0 12px;
          .card {
            padding: 2px 8px;
            margin-bottom: 3px;
            display: flex;
            flex-direction: row;
            align-items: center;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16) !important;
            border-radius: 4px;
            img {
              margin-left: 9px;
            }
            span {
              margin-left: 8px;
            }
            .type {
              width: 8px;
              height: 8px;
              border-radius: 50%;
            }
            .done {
              background-color: $lesson-ended;
            }
            .test {
              background-color: $test;
            }
            .reglesson {
              background-color: $regular-lesson;
            }
            .empty {
              background-color: $empty-card;
            }
          }
          .rests-time {
            background-color: $regular-lesson-tag;
          }
        }
      }
    }
  }
}
.start-screen {
  width: 100%;
  height: 72vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-size: 26px;
  text-align: center;
  padding-top: 50px;
  color: $bright-blue;
}
</style>
