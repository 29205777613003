<template>
  <div class="dashboard">
    <div class="dashboard-item" v-if="info">
      <div class="label">Текущая ставка</div>
      <div class="content">
        <img src="../../../assets/img/ES/dashboard_coin.svg" alt="coin" />
        <span
          >{{ info.wage_rate || '-' }}<img src="../../../assets/img/ES/black_rub.svg" alt="rub"
        /></span>
      </div>
    </div>
    <div class="dashboard-item" v-if="info">
      <div class="label">Дата последней выплаты</div>
      <div class="content">
        <img src="../../../assets/img/ES/dashboard_calendar.svg" alt="calendar" />
        <span>{{ info.payout_date || '-' }}</span>
      </div>
    </div>
    <div class="dashboard-item" v-if="info">
      <div class="label">Итоговая сумма к выплате</div>
      <div class="content">
        <img src="../../../assets/img/ES/dashboard_coin.svg" alt="coin" />
        <span
          >{{ info.payout_amount || '-' }}<img src="../../../assets/img/ES/black_rub.svg" alt="rub"
        /></span>
      </div>
    </div>
    <div
      class="dashboard-button"
      v-if="
        info &&
        ($cookie.get('internalName') === 'teacher' ||
          $cookie.get('internalName') === 'methodologist')
      "
    >
      <button @click="$emit('openModalRequisites')">Мои реквизиты</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dashboard',
  props: {
    info: Object
  }
}
</script>

<style scoped lang="scss">
@import '../../../styleVariables';

.dashboard {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr 172px;
  padding: 11px 28px 13px 16px !important;
  &-item {
    .label {
      font-size: 14px;
      line-height: 24px;
      color: $main-gray;
      margin-bottom: 7px;
    }
    .content {
      display: flex;
      align-items: center;
      img {
        margin-right: 9px;
      }
      span {
        font-size: 14px;
        line-height: 24px;
        font-family: 'Roboto', sans-serif;
      }
    }
  }
  &-button {
    button {
      border: 1px solid $bright-blue;
      color: $bright-blue;
      background-color: $main-white;
      border-radius: 4px;
      width: 172px;
      height: 32px;
    }
  }
}
</style>
