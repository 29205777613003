import { notUndefined, notEmpty, isEmpty } from '@/components/Core/KernelProject/helperFunction'
import { DefaultValueSettings } from '@/globalVariables'

export default {
  methods: {
    /**
     * Обработка виджетов для передачи параметров в виджет
     * relation & tree & list = Комбобокс || дерево значений
     * @param key
     * @param data
     * @return {{}}
     * @description соберет нужные данные для отрисовки виджета из меты вернув данные для v-bind
     */
    renderBindWidget(key, data) {
      let obj = {}
      let route =
        this.$route.params.model ||
        this.$route.path
          .slice(1)
          .split(/\s+/)
          .map((word) => word[0].toUpperCase() + word.substring(1))
          .join(' ')
          .split('/')[1]
      if (data === undefined) {
        data = this.data[0]
        for (let _key in data[key]) {
          if (data[key].hasOwnProperty(_key)) {
            obj[_key] = data[key][_key]
          }
        }
        return obj
      } else {
        let mainFiled
        if (data[key].type === 'field') {
          mainFiled = JSON.parse(
            JSON.stringify(
              this.$store.state.metaProjectData[this.$route.params.prefix][route]['fields'][key]
            )
          )
        } else {
          mainFiled = data[key]
        }
        if (!data[key]['label']) {
          data[key]['label'] = `${mainFiled.keyAdd || data[key].keyAdd}`
        }
        if (DefaultValueSettings.isGenerateDefaultPlaceholder) {
          if (!data[key]['placeholder']) {
            data[key]['placeholder'] = mainFiled['label'] || `Введите ${mainFiled['label']}`
          }
        }
        if (DefaultValueSettings.isGenerateDefaultHints) {
          if (!data[key]['tooltip']) {
            data[key][
              'tooltip'
            ] = `Введите ${mainFiled['label']} -> тут должна быть более подробная информация`
          }
        }
        if (!data[key]['min']) {
          data[key]['min'] = 0
        }
        if (!data[key]['max']) {
          if (data[key]['type'] === 'float' || mainFiled.type === 'float') {
            data[key]['max'] = 999999
          } else if (data[key]['type'].indexOf('date') !== -1) {
            data[key]['max'] = null
          } else {
            data[key]['max'] = 255
          }
        }
        if (data[key]['regex']) {
          if (data[key]['userDescription']) {
            data[key][
              'userDescription'
            ] = `Текст не соответствует регулярному выражению ${mainFiled['regex']}`
          }
        }
        if (!data[key]['noOptions']) {
          data[key]['noOptions'] = `Ничего не найдено`
        }
        if (data[key].type === 'relation' || mainFiled.type === 'relation') {
          this.reverseBindRelation(obj, data, route, key)
        }
        if (data[key].type === 'tree' || mainFiled.type === 'tree') {
          obj['list'] = this.$store.state.relationsData[this.$route.params.prefix][mainFiled.model]
        }
        if (data[key].type === 'list' || mainFiled.type === 'list') {
          obj['list'] = mainFiled['list']
        }
        for (let keys in data[key]) {
          if (data[key].hasOwnProperty(keys)) {
            obj[keys] = data[key][keys]
          }
        }
        return obj
      }
    },
    /**
     * @param item
     * @param data
     * @return {string|boolean|*}
     * @description Приведет id's/id в информацию понятную пользователю
     */
    renderValue(item, data) {
      let store = this.$store.state
      let route = this.$route.path
        .slice(1)
        .split(/\s+/)
        .map((word) => word[0].toUpperCase() + word.substring(1))
        .join(' ')
        .split('/')[1]
      let currentPath =
        store['metaProjectData'][this.$route.params.prefix][this.$route.params.model || route]
          .fields
      if (currentPath[data.field.key]) {
        if (isEmpty(item) && currentPath[data.field.key].type !== 'bool') {
          return DefaultValueSettings.emptyValue
        }
        if (currentPath[data.field.key].type === 'relation') {
          return this.reverseRelation(currentPath, data, store, route, item)
        } else if (currentPath[data.field.key].type === 'bool') {
          return this.reverseBool(item)
        } else if (typeof item === 'string') {
          return this.reverseString(item)
        } else {
          return item
        }
      }
    },
    /**
     * Преобразование id в таблице -> читает значение исходя из viewName сущности
     * Так как при получение модели у нас есть данные её связей и название модели ищем viewName для вывода связанной сущности
     * и получаем ключ по которому следует обратиться к объекту
     * @param currentPath
     * @param data
     * @param store
     * @param route
     * @param item
     * @return {*}
     */
    reverseRelation(currentPath, data, store, route, item) {
      if (
        isEmpty(
          store['relationsData'][this.$route.params.prefix][this.$route.params.model || route]
        )
      ) {
        return
      }
      let currentRelation =
        store.metaProjectData[this.$route.params.prefix][this.$route.params.model || route][
          'relations'
        ][currentPath[data.field.key]['relation']]['relationModel']
      let storeRelation =
        store['relationsData'][this.$route.params.prefix][this.$route.params.model || route][
          currentRelation
        ]
      if (notUndefined(storeRelation) && notEmpty(storeRelation)) {
        let viewName = this.$store.state.metaProjectData[this.$route.params.prefix][
          store.metaProjectData[this.$route.params.prefix][this.$route.params.model || route][
            'relations'
          ][currentPath[data.field.key]['relation']]['relationModel']
        ].viewName
        if (typeof item === 'object') {
          return this.reverseRelationM2M(item, store, currentRelation, viewName, route)
        }
        let _item =
          store['relationsData'][this.$route.params.prefix][this.$route.params.model || route][
            currentRelation
          ][item]
        return _item ? _item[viewName] : DefaultValueSettings.emptyValue
      }
      return DefaultValueSettings.emptyValue
    },
    /**
     * Обработка связи многие ко многим путем сбора данных и преобразование через запятую
     * Работает на основе строки
     * @param item
     * @param store
     * @param currentRelation
     * @param viewName
     * @param route
     * @return {string}
     */
    reverseRelationM2M(item, store, currentRelation, viewName, route) {
      let m2mString = ''
      let _store =
        store['relationsData'][this.$route.params.prefix][this.$route.params.model || route][
          currentRelation
        ]
      for (let i in item) {
        for (let j in _store) {
          if (item[i] === _store[j]['id']) {
            m2mString += `${_store[j][viewName]}${
              DefaultValueSettings.isWrapEnterM2M ? '<br>' : ','
            }`
          }
        }
      }
      return m2mString.slice(0, -1)
    },
    /**
     * Преобразование булевого значение в читабельный
     * @param item
     * @return {string}
     */
    reverseBool(item) {
      return item ? 'Да' : 'Нет'
    },
    /**
     * Преобразование строки в простой вид или через <br> в случае > 2 слов
     * @param item
     * @return {*}
     */
    reverseString(item) {
      if (DefaultValueSettings.isWrapByWordsOnTable && item.split(' ').length >= 2) {
        item = item.split(' ').join('<br>')
        return item
      } else {
        return item
      }
    },
    /**
     * Передача параметров в виджет из меты
     * @param obj
     * @param data
     * @param route
     * @param key
     */
    reverseBindRelation(obj, data, route, key) {
      if (
        this.$store.state.metaProjectData[this.$route.params.prefix][
          this.$route.params.model || route
        ]['relations'] &&
        this.$store.state.metaProjectData[this.$route.params.prefix][
          this.$route.params.model || route
        ]['relations'][data[key]['relation']]
      ) {
        let currentRelation = this.$store.state.metaProjectData[this.$route.params.prefix][
          this.$route.params.model || route
        ]['relations'][data[key]['relation']]['relationModel']
        obj['viewName'] = this.$store.state.metaProjectData[this.$route.params.prefix][
          [currentRelation]
        ].viewName
        obj['model'] = currentRelation
      }
    },
    /**
     * Обработка вывода виджетов фильтров
     * @param rec
     * @param data
     * @return {boolean}
     */
    renderWidgetRec(rec, data) {
      if ((data.fields && rec === data.fields) || (data[0] && rec === data[0])) {
        return true
      }
    }
  }
}
