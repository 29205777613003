<template>
  <div class="modal-background" @mousedown.self="hide">
    <div class="modal-container">
      <div class="header">
        <div class="header-name">Вы действительно хотите отклонить жалобу?</div>
        <div class="header-required">
          В этом случае преподаватель получит полную оплату за занятие. Для студента занятие не
          будет восстановлено.
        </div>
      </div>
      <div class="content">
        <div class="content-textarea">
          <div class="label">Комментарий*</div>
          <textarea v-model="comment" maxlength="255"></textarea>
        </div>
      </div>
      <div class="modal-controls">
        <button class="cancel" @click="$emit('cancel')">Отменить</button>
        <button class="save" @click="save()" :disabled="!comment">Сохранить</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DisapproveModal',
  data() {
    return {
      comment: null
    }
  },
  methods: {
    hide() {
      this.$emit('hide')
    },
    /**
     * Отклонить жалобу с формированием необходимых для запроса параметров
     */
    save() {
      let data = {
        is_accepted: false,
        with_recovery: false,
        with_payment: true,
        comment: this.comment
      }
      this.$emit('save', data)
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../styleVariables.scss';

.modal-background {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.3);
  animation: fade-in 0.3s;
  color: $main-dark;

  .modal-container {
    background-color: $main-white;
    border-radius: 8px;
    padding: 14px 24px;
    width: 550px;
    //height: 313px;
    display: flex;
    flex-direction: column;

    .header {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-bottom: 26px;
      &-name {
        font-size: 18px;
        line-height: 33px;
        margin-bottom: 3px;
      }
      &-required {
        font-size: 14px;
        line-height: 24px;
        font-family: $font-regular;
        width: 406px;
        text-align: center;
      }
    }

    .content {
      margin-bottom: 17px;
      .label {
        font-family: $font-regular;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 7px;
      }
      &-textarea {
        textarea {
          border: 0.5px solid #e8edf3;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
          border-radius: 4px;
          box-sizing: border-box;
          height: 128px;
          width: 100%;
          margin: 0 auto;
          padding: 6px 11px;
          outline: none;
          font-family: $font-regular;
          resize: none;
        }
      }
    }
    .modal-controls {
      display: flex;
      justify-content: flex-end;
      button {
        width: 137px;
        height: 33px;
        border-radius: 4px;
        font-size: 14px;
        line-height: 24px;
        outline: none;
      }
      .save {
        background-color: $bright-blue;
        color: $main-white;
        &:disabled {
          background-color: $light-gray;
        }
      }
      .cancel {
        border: 1px solid $bright-blue;
        color: $bright-blue;
        margin-right: 15px;
      }
    }
  }
}
</style>
