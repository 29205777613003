export default {
  methods: {
    InputTime__ChangeData(data) {
      data.value = `${data.value}:00`
      this.$store.commit('newRec', data)
    }
  },
  data() {
    return {
      inputDate: {
        value: this.value,
        keyAdd: this.keyAdd
      }
    }
  }
}
