<template>
  <div class="card-to-fill">
    <div class="card-container">
      <div class="card-header-container">
        <div class="lesson-details">
          <span class="name">Занятие {{ lessonInfo.sequence_number }}</span>
          <div class="lesson-date">
            <img src="../../assets/img/icons/calendar-dark.svg" />
            <span>{{ formatDate(lessonInfo.date) }}</span>
          </div>
          <div class="lesson-time">
            <img src="../../assets/img/icons/alarm.svg" />
            <span>{{ formatTime(lessonInfo.hour) }}</span>
          </div>
        </div>
        <div
          class="edit-btn"
          v-if="$cookie.get('internalName') !== 'student' && !editLessonCardMode"
        >
          <div
            class="student-controls-btn"
            v-if="
              $cookie.get('internalName') === 'teacher' ||
              $cookie.get('internalName') === 'methodologist'
            "
          >
            <button
              class="lesson-cancel-btn"
              v-if="lessonInfo.kls_status_code === 'LESSON_PLANNED'"
              @click="$emit('openCancelModalForTeacher', lessonInfo.id)"
            >
              Отменить урок
            </button>
            <button
              class="lesson-cancel-btn"
              v-if="lessonInfo.kls_status_code === 'LESSON_PLANNED' && startLesson"
              @click="$emit('techProblem', lessonInfo.id)"
            >
              Технические неполадки
            </button>
          </div>
        </div>
      </div>
      <div class="lesson-link-container">
        <label>Ссылка на урок:</label>
        <a
          :href="
            lessonInfo.link_to_zoom.indexOf('http') !== -1
              ? lessonInfo.link_to_zoom
              : `http://${lessonInfo.link_to_zoom}`
          "
          target="_blank"
          v-if="lessonInfo.link_to_zoom"
          class="lesson-link"
          >{{ lessonInfo.link_to_zoom }}</a
        >
        <span v-else-if="!lessonInfo.link_to_zoom">Ссылка недоступна</span>
      </div>
      <div class="card-members">
        <div class="card-member-name" v-if="$cookie.get('internalName') !== 'student'">
          <span class="label" v-if="lessonInfo.student_name || lessonInfo.student_short_name"
            >Student Name</span
          >
          <span class="name" v-if="$cookie.get('internalName') === 'manager'">{{
            lessonInfo.student_name
          }}</span>
          <span
            class="name"
            v-if="
              $cookie.get('internalName') !== 'manager' && $cookie.get('internalName') !== 'admin'
            "
            >{{ lessonInfo.student_short_name }}</span
          >
        </div>
        <div
          class="card-member-name"
          v-if="
            $cookie.get('internalName') !== 'methodologist' &&
            $cookie.get('internalName') !== 'teacher'
          "
        >
          <span class="label">Teacher Name</span>
          <span class="name" v-if="$cookie.get('internalName') === 'manager'">{{
            lessonInfo.teacher_name
          }}</span>
          <span
            class="name"
            v-if="
              $cookie.get('internalName') !== 'manager' && $cookie.get('internalName') !== 'admin'
            "
            >{{ lessonInfo.teacher_short_name }}</span
          >
        </div>
        <div
          class="card-member-name"
          v-if="
            $cookie.get('internalName') !== 'student' &&
            $cookie.get('internalName') !== 'methodologist' &&
            $cookie.get('internalName') !== 'teacher'
          "
        >
          <span class="label">Methodist Name</span>
          <span class="name" v-if="$cookie.get('internalName') === 'manager'">{{
            lessonInfo.methodologist_name
          }}</span>
          <span
            class="name"
            v-if="
              $cookie.get('internalName') !== 'manager' && $cookie.get('internalName') !== 'admin'
            "
            >{{ lessonInfo.methodologist_short_name }}</span
          >
        </div>
      </div>
      <div class="card-status-container">
        <div class="card-status">
          <span>Status</span>
          <div
            v-if="!removeStatus"
            :class="{
              lessonFinished: statusCode === 'LESSON_ENDED',
              lessonPlanned: statusCode === 'LESSON_PLANNED',
              lessonMissedByStudent: statusCode === 'SKIPPED_STUDENT',
              lessonMissedByStudentWithReason: statusCode === 'SKIPPED_STUDENT_WITH_REASON',
              lessonMissedByTeacher: statusCode === 'SKIPPED_TEACHER',
              lessonCanceled: statusCode === 'CANCELLED'
            }"
          >
            {{ statusName }}
            <img
              @click="setRemoveStatus(true)"
              src="../../assets/img/ES/close_big.svg"
              alt="close"
              v-if="canChangeStatus"
            />
          </div>
          <div class="choose-status" v-if="removeStatus" v-click-outside="setDefaultStatus">
            <statuses
              @chooseStatus="changeStatus"
              :currentType="typeCode"
              :currentStatus="lessonInfo.kls_status_code"
            />
          </div>
        </div>
        <div class="card-type" v-if="$cookie.get('internalName') !== 'student'">
          <span>Type</span>
          <div
            v-if="!removeType"
            :class="{
              regularLesson: typeCode === 'REGULAR' || typeCode === 'TEACHER_TEST_REGULAR_LESSON',
              teacherTesting: typeCode === 'TEACHER_TEST',
              placementTest: typeCode === 'PLACEMENT_TEST',
              checkUpLesson: typeCode === 'CHECK_UP',
              progressTest:
                typeCode === 'CHECK_PROGRESS' || typeCode === 'TEACHER_TEST_PROGRESS_TEST_LESSON'
            }"
          >
            {{ typeName }}
            <img @click="setRemoveType(true)" src="../../assets/img/ES/close_big.svg" alt="close" />
          </div>
          <div class="choose-type" v-if="removeType">
            <types @chooseType="changeType" v-click-outside="setDefaultType" />
          </div>
        </div>
      </div>
      <div class="card-info-container" v-if="$cookie.get('internalName') !== 'student'">
        <div class="first-table">
          <div class="table-cell">
            <span class="table-cell-label">Level</span>
            <div class="table-cell-name" v-if="userRole === 'teacher'">
              {{ lessonInfo.kls_level_name }}
            </div>
            <vue-select
              class="select"
              v-model="level"
              :clearable="false"
              :options="levels"
              @open="getLevels"
              @input="getCEFRByLevel"
              v-else
            >
              <template slot="option" slot-scope="option">
                <div class="d-center">
                  <span>{{ option.name }}</span>
                </div>
              </template>
              <template slot="selected-option">
                <div class="selected d-center">
                  {{ level.name }}
                </div>
              </template>
            </vue-select>
          </div>
          <div class="table-cell" v-if="typeCode === 'PLACEMENT_TEST'">
            <span class="table-cell-label">CEFR</span>
            <div class="table-cell-name" v-if="userRole === 'teacher'">
              {{ lessonInfo.kls_europe_level_name || 'no' }}
            </div>
            <vue-select
              class="select"
              v-model="CEFR"
              :clearable="false"
              :options="CEFRs"
              @open="getCEFRLevels"
              @input="getLevelByCEFR"
              v-else
            >
              <template slot="option" slot-scope="option">
                <div class="d-center">
                  <span>{{ option.name }}</span>
                </div>
              </template>
              <template slot="selected-option">
                <div class="selected d-center">
                  {{ CEFR.name }}
                </div>
              </template>
            </vue-select>
          </div>
          <div class="table-cell">
            <span class="table-cell-label">Revision steps</span>
            <input
              id="revision-steps-input--placement-test"
              :class="{ 'validation-error': fieldValidations.revisionSteps }"
              type="text"
              v-if="
                lessonInfo.kls_type_code !== 'REGULAR' &&
                lessonInfo.kls_type_code !== 'CHECK_PROGRESS' &&
                lessonInfo.kls_type_code !== 'TEACHER_TEST_PROGRESS_TEST_LESSON' &&
                lessonInfo.kls_type_code !== 'TEACHER_TESET_REGULAR_LESSON'
              "
              v-model="revisionSteps"
            />
            <span v-else class="table-cell-name"> {{ revisionSteps }} </span>
            <b-tooltip
              target="revision-steps-input--placement-test"
              triggers="hover"
              v-if="fieldValidations.revisionSteps"
            >
              Revision steps не может быть меньше 1 и больше 50
            </b-tooltip>
          </div>
          <div class="table-cell" v-if="typeCode === 'PLACEMENT_TEST'">
            <span class="table-cell-label">Revision</span>
            <span class="table-cell-name" v-if="revision !== null">{{ revision.join(', ') }}</span>
            <span class="table-cell-name" v-else-if="revision === null"> no </span>
          </div>
          <div class="table-cell">
            <span class="table-cell-label">Lesson number</span>
            <input
              id="lesson-number-input"
              :class="{ 'validation-error': fieldValidations.lessonNumber }"
              type="text"
              v-model="lessonNumber"
            />
            <b-tooltip
              target="lesson-number-input"
              triggers="hover"
              v-if="fieldValidations.lessonNumber"
            >
              Lesson number не может быть меньше чем в предыдущем занятии
            </b-tooltip>
          </div>
          <div class="table-cell" v-if="typeCode === 'PLACEMENT_TEST'">
            <span class="table-cell-label">Link to record</span>
            <input type="text" v-model="link" />
          </div>
          <div class="table-cell" v-if="typeCode !== 'PLACEMENT_TEST'">
            <span class="table-cell-label">Last taught page</span>
            <input
              id="last-taught-page-input"
              :class="{
                'validation-error':
                  (fieldValidations.lastTaughtPageRequired &&
                    statusCode !== 'SKIPPED_STUDENT' &&
                    statusCode !== 'CANCELLED') ||
                  fieldValidations.lastTaughtPage ||
                  fieldValidations.newPages
              }"
              type="text"
              v-model="lastPage"
              @input="calculateNewPages()"
            />
            <b-tooltip
              target="last-taught-page-input"
              triggers="hover"
              v-if="fieldValidations.lastTaughtPageRequired"
            >
              Поле обязательно для заполнения
            </b-tooltip>
            <b-tooltip
              target="last-taught-page-input"
              triggers="hover"
              v-else-if="fieldValidations.lastTaughtPage"
            >
              Last taught page не должен быть меньше или равняться Last taught page предыдущего
              урока
            </b-tooltip>
            <b-tooltip
              target="last-taught-page-input"
              triggers="hover"
              v-else-if="fieldValidations.newPages"
            >
              New pages не может быть больше 12
            </b-tooltip>
          </div>
          <div class="table-cell" v-if="typeCode !== 'PLACEMENT_TEST'">
            <span class="table-cell-label">New words</span>
            <input
              id="new-words-input"
              :class="{ 'validation-error': fieldValidations.newWords }"
              type="text"
              v-model="newWords"
            />
            <b-tooltip target="new-words-input" triggers="hover" v-if="fieldValidations.newWords">
              Поле New words не может быть меньше 0 и больше 50
            </b-tooltip>
          </div>
          <div class="table-cell" v-if="typeCode !== 'PLACEMENT_TEST'">
            <span class="table-cell-label">Grammar</span>
            <input type="text" v-model="grammar" />
          </div>
          <div class="table-cell" v-if="typeCode !== 'PLACEMENT_TEST'">
            <span class="table-cell-label">Reading</span>
            <input type="text" v-model="reading" />
          </div>
          <div class="table-cell" v-if="typeCode !== 'PLACEMENT_TEST'">
            <span class="table-cell-label">Other</span>
            <input type="text" v-model="other" />
          </div>
          <div class="table-cell" v-if="typeCode !== 'PLACEMENT_TEST'">
            <span class="table-cell-label">Student comment</span>
            <textarea v-model="studentComment"></textarea>
          </div>
        </div>
        <div class="second-table" v-if="typeCode !== 'PLACEMENT_TEST'">
          <div class="table-cell">
            <span class="table-cell-label">CEFR</span>
            <div class="table-cell-name" v-if="userRole === 'teacher'">
              {{ lessonInfo.kls_europe_level_name || 'no' }}
            </div>
            <vue-select
              class="select"
              v-model="CEFR"
              :clearable="false"
              :options="CEFRs"
              @open="getCEFRLevels"
              @input="getLevelByCEFR"
              v-else
            >
              <template slot="option" slot-scope="option">
                <div class="d-center">
                  <span>{{ option.name }}</span>
                </div>
              </template>
              <template slot="selected-option">
                <div class="selected d-center">
                  {{ CEFR.name }}
                </div>
              </template>
            </vue-select>
          </div>
          <div class="table-cell">
            <span class="table-cell-label">Percentage of knowledge</span>
            <input
              type="number"
              :value="percentageOfKnowledge"
              ref="percentageOfKnowledgeField"
              v-if="
                typeCode === 'CHECK_PROGRESS' || typeCode === 'TEACHER_TEST_PROGRESS_TEST_LESSON'
              "
              @input="setPercentageOfKnowledge"
            />
            <span v-else class="table-cell-name">{{ percentageOfKnowledge || 'no' }}</span>
          </div>
          <div class="table-cell">
            <span class="table-cell-label">Revision</span>
            <span class="table-cell-name" v-if="revision !== null">{{ revision.join(', ') }}</span>
            <span class="table-cell-name" v-else-if="revision === null"> no </span>
          </div>
          <div class="table-cell">
            <span class="table-cell-label">New pages</span>
            <span class="table-cell-name">{{ newPages || 'no' }}</span>
          </div>
          <div class="table-cell">
            <span class="table-cell-label">Last taught (new) word</span>
            <input type="text" v-model="lastWord" />
          </div>
          <div class="table-cell">
            <span class="table-cell-label">Listening</span>
            <input type="text" v-model="listening" />
          </div>
          <div class="table-cell">
            <span class="table-cell-label">Writing</span>
            <input type="text" v-model="writing" />
          </div>
          <div class="table-cell">
            <span class="table-cell-label">Homework</span>
            <input type="text" v-model="homework" />
          </div>
          <div class="table-cell">
            <span class="table-cell-label">Link to record</span>
            <input type="text" v-model="link" />
          </div>
        </div>
      </div>
      <div class="edit-buttons">
        <button class="cancel" @click="setInitialState()">Отменить</button>
        <button class="save" @click="saveLessonCard()">Сохранить</button>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import Types from '@/views/LessonCard/components/Types'
import Statuses from '@/views/LessonCard/components/Statuses'
import VueSelect from 'vue-select'
import axios from 'axios'
import { domain, MicroServices } from '@/globalVariables'
import scriptMixins from '@/components/scriptMixins/scriptMixins'
export default {
  name: 'TeacherCardToFill',
  props: ['lessonInfo'],
  components: { Types, Statuses, VueSelect },
  mixins: [scriptMixins],
  directives: {
    ClickOutside
  },
  data() {
    return {
      lessonCardSelected: true,
      glossarySelected: false,
      userRole: this.$cookie.get('internalName'),
      lessonId: null,
      studentComplaint: [],
      complaintId: null,
      lessonTypeId: null,
      lessonTypeCode: null,
      editLessonCardMode: false,
      removeStatus: false,
      removeType: false,
      level: null,
      CEFR: null,
      lessonNumber: null,
      prevLessonNumber: null,
      lastPage: null,
      newWords: null,
      grammar: null,
      reading: null,
      other: null,
      studentComment: null,
      percentageOfKnowledge: null,
      revision: null,
      revisionSteps: null,
      newPages: null,
      lastWord: null,
      listening: null,
      writing: null,
      homework: null,
      link: null,
      status: null,
      statusCode: null,
      type: null,
      linkToZoom: null,
      teacher: null,
      methodist: null,
      statusName: null,
      typeName: null,
      typeCode: null,
      teachers: [],
      methodists: [],
      levels: [],
      CEFRs: [],
      cancelModalForTeacher: false,
      cancelModalForManager: false,
      isFilledCard: true,
      fieldValidations: {
        newWords: false,
        revisionSteps: false,
        lastTaughtPage: false,
        lastTaughtPageRequired: false,
        newPages: false,
        lessonNumber: false
      }
    }
  },
  mounted() {
    this.setInitialState()
    this.$root.$on('setInitialStateInCardToFill', () => {
      this.setInitialState()
    })
  },
  methods: {
    setDefaultStatus() {
      this.removeStatus = false
    },
    setRemoveStatus(value) {
      setTimeout(() => {
        this.removeStatus = value
      }, 10)
    },
    setDefaultType() {
      this.removeType = false
    },
    setRemoveType(value) {
      setTimeout(() => {
        this.removeType = value
      }, 10)
    },
    formatDate(time) {
      return time.slice(0, 10)
    },
    formatTime(time) {
      return time + ':00' + ' - ' + (time + 1) + ':00'
    },

    /**
     * Установить дефолтные значения переменным для заполнения карточки
     */
    setInitialState() {
      this.status = this.lessonInfo.kls_status_id
      this.statusCode = this.lessonInfo.kls_status_code
      this.type = this.lessonInfo.kls_type_id
      this.typeCode = this.lessonInfo.kls_type_code
      this.level = {
        kls_level_id: this.lessonInfo.kls_level_id,
        name: this.lessonInfo.kls_level_name
      }
      this.CEFR = {
        kls_europe_level_id: this.lessonInfo.kls_europe_level_id,
        name: this.lessonInfo.kls_europe_level_name
      }
      this.lessonNumber = this.lessonInfo.lesson_number
      this.lastPage = this.lessonInfo.last_taught_page
      this.newWords = this.lessonInfo.new_words
      this.grammar = this.lessonInfo.grammar
      this.reading = this.lessonInfo.reading
      this.other = this.lessonInfo.other
      this.studentComment = this.lessonInfo.comment
      this.percentageOfKnowledge = this.lessonInfo.percentage_of_knowledge
      this.revisionSteps = this.lessonInfo.revision_step
      this.revision = this.lessonInfo.revision
      this.newPages = this.lessonInfo.new_pages
      this.lastWord = this.lessonInfo.last_taught_word
      this.listening = this.lessonInfo.listening
      this.writing = this.lessonInfo.writing
      this.homework = this.lessonInfo.homework
      this.link = this.lessonInfo.link_to_record
      this.linkToZoom = this.lessonInfo.link_to_zoom
      this.teacher = {
        full_name: this.lessonInfo.teacher_name,
        id: this.lessonInfo.teacher_uuid
      }
      this.methodist = {
        full_name: this.lessonInfo.methodologist_name,
        id: this.lessonInfo.methodologist_uuid
      }
      this.statusName = this.lessonInfo.kls_status_name
      this.typeName = this.lessonInfo.kls_type_name
    },

    /**
     * Получить список уровней
     */
    getLevels() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Classifier/getEducationLevels`,
        method: 'GET'
      })
        .then((response) => {
          this.levels = response.data.data.items
        })
        .catch((error) => this.errorAlert(error))
    },

    /**
     * Получить уровень при изменении CEFR
     */
    getLevelByCEFR() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Classifier/getSchoolLevelByEurope`,
        method: 'POST',
        data: [
          {
            kls_europe_level_id: this.CEFR.kls_europe_level_id || this.CEFR.id
          }
        ]
      })
        .then((response) => {
          this.level = response.data.data
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Получить CEFR при изменении уровня
     */
    getCEFRByLevel() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Classifier/getEuropeLevelBySchool`,
        method: 'POST',
        data: [
          {
            kls_level_id: this.level.kls_level_id || this.level.id
          }
        ]
      })
        .then((response) => {
          this.CEFR = response.data.data
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Получить список CEFR уровней
     */
    getCEFRLevels() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Classifier/getEducationLevels`,
        method: 'GET'
      })
        .then((response) => {
          this.CEFRs = response.data.data.items
        })
        .catch((error) => this.errorAlert(error))
    },

    /**
     * Поменять статус занятия
     * @param id
     * @param code
     * @param name
     */
    changeStatus(id, code, name) {
      this.status = id
      this.statusCode = code
      this.statusName = name
      this.removeStatus = false
    },

    /**
     * Поменять тип занятия
     * @param id
     * @param code
     * @param name
     */
    changeType(id, code, name) {
      this.type = id
      this.typeName = name
      this.typeCode = code
      this.removeType = false
    },

    /**
     * Сохранить карточку занятия
     * @return {null}
     */
    saveLessonCard() {
      this.validateFields()
      if (
        this.fieldValidations.newWords ||
        this.fieldValidations.newPages ||
        this.fieldValidations.revisionSteps ||
        this.fieldValidations.lessonNumber ||
        this.fieldValidations.lastTaughtPage ||
        (this.fieldValidations.lastTaughtPageRequired &&
          this.lessonInfo.kls_type_code !== 'PLACEMENT_TEST' &&
          this.statusCode !== 'SKIPPED_STUDENT' &&
          this.statusCode !== 'CANCELLED')
      ) {
        return null
      }

      let data = [
        {
          kls_status_id: this.status,
          kls_type_id: this.type,
          kls_level_id: this.level.kls_level_id || this.level.id,
          percentage_of_knowledge: this.percentageOfKnowledge.toString(),
          lesson_number: Number(this.lessonNumber),
          revision: Number(this.revision),
          revision_step: Number(this.revisionSteps),
          last_taught_page: Number(this.lastPage),
          new_pages: Number(this.newPages),
          new_words: Number(this.newWords),
          last_taught_word: this.lastWord,
          grammar: this.grammar,
          listening: this.listening,
          reading: this.reading,
          writing: this.writing,
          other: this.other,
          homework: this.homework,
          comment: this.studentComment,
          link_to_zoom: this.linkToZoom,
          link_to_record: this.link
        }
      ]

      axios({
        url: `${domain}/${MicroServices[0].prefix}/LessonCard/update/${this.lessonInfo.id}`,
        method: 'PUT',
        data: data
      })
        .then(() => {
          if (
            this.statusCode === 'CANCELLED' ||
            this.statusCode === 'SKIPPED_TEACHER' ||
            this.statusCode === 'SKIPPED_STUDENT_WITH_REASON' ||
            this.statusCode === 'SKIPPED_STUDENT'
          ) {
            this.$router.push('/monolit/Schedule')
            return null
          }
          this.$emit('saveLessonCard')
          this.setInitialState()
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Подсчет поля New pages
     */
    calculateNewPages() {
      this.newPages =
        this.lastPage - (this.lessonInfo.prev_lesson_last_taught_page || 0) < 0
          ? 0
          : this.lastPage - (this.lessonInfo.prev_lesson_last_taught_page || 0)
    },

    /**
     * Вызывает функцию-валидатор для выбранного поля
     * @param field
     */
    validateFields() {
      this.validateNewWords()
      this.validateRevisionSteps()
      this.validateLastTaughtPage()
      this.validateNewPages()
      this.validateLessonNumber()
      this.validateLastTaughtPageRequired()
    },

    /**
     * Валидация поля New words
     */
    validateNewWords() {
      this.fieldValidations.newWords = (this.newWords < 0 || this.newWords > 50) && this.newWords
    },

    /**
     * Валидация поля Revision steps
     */
    validateRevisionSteps() {
      this.fieldValidations.revisionSteps =
        (this.revisionSteps < 1 || this.revisionSteps > 50) && this.revisionSteps
    },

    /**
     * Валидация поля Last taught page
     */
    validateLastTaughtPage() {
      this.fieldValidations.lastTaughtPage =
        this.lastPage <= this.lessonInfo.prev_lesson_last_taught_page && this.lastPage
    },

    /**
     * Валидация поля Last taught page (поле не должно быть пустым)
     */
    validateLastTaughtPageRequired() {
      this.fieldValidations.lastTaughtPageRequired = !this.lastPage
    },

    /**
     * Валидация поля New pages
     */
    validateNewPages() {
      this.fieldValidations.newPages = this.newPages < 0 || this.newPages > 12
    },

    /**
     * Валидация поля Lesson number
     */
    validateLessonNumber() {
      this.fieldValidations.lessonNumber =
        this.lessonNumber < this.prevLessonNumber && this.lessonNumber
    },

    /**
     * Записывает Percentage of knowledge, если его значение 0 <= value >= 100
     * @param event
     */
    setPercentageOfKnowledge(event) {
      const value = Number(event.target.value)

      if (value > 100) {
        this.$refs.percentageOfKnowledgeField.value = 100
        this.percentageOfKnowledge = 100
      } else if (value < 0) {
        this.$refs.percentageOfKnowledgeField.value = 0
        this.percentageOfKnowledge = 0
      } else {
        this.percentageOfKnowledge = value
      }
    }
  },
  computed: {
    canChangeStatus() {
      return (
        ((this.$cookie.get('internalName') === 'teacher' ||
          this.$cookie.get('internalName') === 'methodologist') &&
          this.lessonInfo.kls_status_code !== 'SKIPPED_TEACHER' &&
          this.lessonInfo.kls_status_code !== 'SKIPPED_STUDENT_WITH_REASON' &&
          this.lessonInfo.kls_status_code !== 'SKIPPED_STUDENT' &&
          this.lessonInfo.kls_status_code !== 'CANCELLED' &&
          this.lessonInfo.kls_status_code !== 'LESSON_ENDED') ||
        this.$cookie.get('internalName') === 'manager' ||
        this.$cookie.get('internalName') === 'admin'
      )
    },
    /**
     * Возвращает true если урок начался и не прошло 25-ти часов с начала урока
     * @returns {boolean}
     */
    startLesson() {
      let today = this.countUserTimeByTimeZone(this.$cookie.get('userTimeZone'))
      let lessonTime = new Date(this.lessonInfo.date.replaceAll('-', '/'))
      let lessonEndTime = new Date(this.lessonInfo.date.replaceAll('-', '/')).setHours(
        new Date(this.lessonInfo.date.replaceAll('-', '/')).getHours() + 25
      )
      return today > lessonTime && today < new Date(lessonEndTime)
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../styleVariables';

.validation-error {
  border-color: $red !important;
}
.tooltip {
  /deep/ .arrow::before {
    border-top-color: $red !important;
    border-bottom-color: $red !important;
  }
  /deep/ .tooltip-inner {
    background-color: $red !important;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

input[type='number'] {
  -moz-appearance: textfield !important;
}

.card-container {
  width: 95%;
  min-height: 200px;
  height: auto;
  box-shadow: 1px 0px 20px -7px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  background: $main-white;
  margin: 25px auto;
  padding: 18px 0;
  transition: box-shadow 0.087s ease-in;
  .card-header-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 18px 18px 0 24px;
    .lesson-details {
      display: flex;
      align-items: center;
      span {
        font-size: 18px;
      }
      div {
        margin-left: 24px;
        display: flex;
        align-items: center;
        span {
          font-family: $font-bold;
          font-size: 14px;
          line-height: 18px;
        }
        img {
          margin-right: 8px;
          width: 20px;
          height: 18px;
        }
      }
    }
    .edit-btn {
      display: flex;
      button {
        outline: none;
        margin-right: 20px;
        img {
          margin-right: 4px;
          width: 15px;
          height: 15px;
        }
      }
    }
    .student-controls-btn {
      button {
        border: 1px solid $bright-blue;
        border-radius: 4px;
        padding: 5px 21px;
        color: $bright-blue;
        outline: none;
      }
      .lesson-cancel-btn {
        margin-right: 15px;
      }
    }
  }
  .lesson-link-container {
    margin-top: 15px;
    padding: 0 18px 0 24px;
    label {
      margin-bottom: 0 !important;
      margin-right: 12px;
    }
    .lesson-link {
      text-decoration: none;
      color: $bright-blue !important;
      word-break: break-all;
    }
    .lesson-link:visited {
      color: $regular-lesson;
    }
    span {
      color: $bright-blue;
    }
    input {
      border: 0.5px solid $light-gray;
      border-radius: 4px;
      background-color: transparent;
      outline: none;
      padding: 3px 9px;
      height: 25px;
      width: 287px;
      font-size: 14px;
      font-family: $font-regular;

      &:focus {
        border: 0.5px solid $bright-blue;
      }
    }
  }
  .card-members {
    display: flex;
    margin-top: 31px;
    flex-wrap: wrap;
    padding-left: 18px;
    .card-member-name {
      display: flex;
      flex-direction: column;
      margin-right: 58px;
      font-size: 14px;
      width: 220px;
      .name {
        font-family: $font-regular;
      }
    }
  }
  .card-status-container {
    margin-top: 35px;
    padding-left: 18px;
    .card-status {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      span {
        margin-right: 22px;
        min-width: 70px;
      }
      img {
        cursor: pointer;
        margin-left: 4px;
      }
      .lessonFinished {
        background-color: rgba(29, 190, 64, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        display: flex;
      }
      .lessonPlanned {
        background-color: rgba(75, 97, 173, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        display: flex;
      }
      .lessonMissedByStudent {
        background-color: rgba(243, 210, 50, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        display: flex;
      }
      .lessonMissedByStudentWithReason {
        background-color: rgba(238, 0, 200, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        display: flex;
      }
      .lessonMissedByTeacher {
        background-color: rgba(28, 9, 255, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        display: flex;
      }
      .lessonCanceled {
        background-color: rgba(206, 87, 25, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        display: flex;
      }
    }
    .card-type {
      display: flex;
      span {
        margin-right: 22px;
        min-width: 70px;
      }
      img {
        cursor: pointer;
        margin-left: 4px;
      }
      .regularLesson {
        background-color: rgba(29, 93, 190, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        display: flex;
        white-space: nowrap;
      }
      .teacherTesting {
        background-color: rgba(29, 190, 64, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        display: flex;
      }
      .placementTest {
        background-color: rgba(255, 107, 0, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        display: flex;
        white-space: nowrap;
      }
      .checkUpLesson {
        background-color: rgba(255, 184, 0, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        display: flex;
      }
      .progressTest {
        background-color: rgba(82, 0, 255, 0.13);
        padding: 3px 12px;
        border-radius: 5px;
        display: flex;
      }
    }
  }
  .card-info-container {
    display: flex;
    margin-top: 23px;
    .first-table {
      flex-grow: 1;
      min-height: 150px;
      .table-cell {
        width: 100%;
        min-height: 46px;
        height: auto;
        display: flex;
        align-items: center;
        padding: 0 18px;
        .table-cell-label {
          min-width: 40%;
          width: 40%;
          background-color: transparent;
        }
        .select {
          width: 287px;

          /deep/ .vs__search {
            background-color: $main-white !important;
          }
          /deep/ .vs__actions {
            background-color: $main-white !important;
          }
          /deep/ .selected,
          /deep/ .vs__selected-options,
          /deep/ .v-select,
          /deep/ .select {
            background-color: $main-white !important;
          }
          /deep/ .vs__dropdown-toggle {
            background-color: $main-white !important;
          }
          /deep/ .vs__selected {
            background-color: $main-white !important;
          }
          /deep/ .vs__open-indicator {
            background-color: $main-white !important;
          }
          /deep/ .vs__dropdown-menu {
            background-color: $main-white !important;
          }
          /deep/ .vs__dropdown-option {
            background-color: $main-white;
            color: $main-dark;
            &:hover {
              color: $bright-blue;
            }
          }
          /deep/ .vs__dropdown-option span {
            background-color: $main-white;
          }
        }
        .table-cell-name {
          font-family: $font-regular;
          background-color: transparent;
        }
        input,
        textarea {
          border: 0.5px solid $light-gray;
          border-radius: 4px;
          background-color: transparent;
          outline: none;
          padding: 3px 9px;
          height: 25px;
          width: 287px;
          font-size: 14px;
          font-family: $font-regular;

          &:focus {
            border: 0.5px solid $bright-blue;
          }
        }
        textarea {
          resize: none;
          height: 136px;
          width: 287px;
          margin-top: 10px;
        }
      }
    }
    .first-table :nth-child(even) {
      background-color: $light-table-blue;
      /deep/.vs__dropdown-menu {
        background-color: #fff;
      }
      /deep/ .vs__selected {
        background-color: $main-white !important;
      }
      /deep/.vs__dropdown-menu li div {
        background-color: $main-white;
      }
      /deep/.vs__dropdown-menu li {
        background-color: $main-white;
        &:hover {
          color: $bright-blue;
        }
      }
      /deep/.vs__dropdown-option,
      .vs__dropdown-option--highlight {
        background-color: $main-white !important;
      }
    }
    .first-table :last-child {
      border: none;
    }

    .second-table {
      flex-grow: 1;
      min-height: 150px;
      margin-right: 24px;
      .table-cell {
        width: 100%;
        min-height: 46px;
        height: auto;
        display: flex;
        align-items: center;
        padding: 0 18px;
        .table-cell-label {
          min-width: 40%;
          width: 40%;
          background-color: transparent;
        }
        .select {
          width: 287px;
          background-color: $main-white !important;

          /deep/ .vs__search {
            background-color: $main-white !important;
          }
          /deep/ .vs__selected {
            background-color: $main-white !important;
          }
          /deep/ .vs__actions {
            background-color: $main-white !important;
          }
          /deep/ .vs__open-indicator {
            background-color: $main-white !important;
          }
          /deep/ .vs__dropdown-menu {
            background-color: $main-white !important;
          }
          /deep/ .vs__dropdown-option {
            background-color: $main-white;
            color: $main-dark;
            &:hover {
              color: $bright-blue;
            }
          }
        }
        .table-cell-name {
          background-color: transparent;
          font-family: $font-regular;
        }
        input {
          border: 0.5px solid $light-gray;
          border-radius: 4px;
          background-color: transparent;
          outline: none;
          padding: 3px 9px;
          height: 25px;
          width: 287px;
          font-size: 14px;
          font-family: $font-regular;

          &:focus {
            border: 0.5px solid $bright-blue;
          }
        }
      }
    }
    .second-table :nth-child(even) {
      background-color: $light-table-blue;
      /deep/.vs__dropdown-menu {
        background-color: #fff;
      }
      /deep/ .vs__selected {
        background-color: $main-white !important;
      }
      /deep/.vs__dropdown-menu li div {
        background-color: $main-white;
      }
      /deep/.vs__dropdown-menu li {
        background-color: $main-white;
        &:hover {
          color: $bright-blue;
        }
      }
      /deep/.vs__dropdown-option,
      .vs__dropdown-option--highlight {
        background-color: $main-white !important;
      }
    }
  }
  .edit-buttons {
    margin-left: auto;
    width: fit-content;
    margin-right: 18px;
    button {
      width: 137px;
      height: 33px;
      border-radius: 4px;
    }
    .save {
      background-color: $bright-blue;
      color: $main-white;
    }
    .cancel {
      background-color: $main-white;
      color: $bright-blue;
      margin-right: 15px;
      border: 1px solid $bright-blue;
    }
  }
  .student-card-info-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .student-lesson-info-container {
      display: flex;
      margin-top: 23px;
      flex-direction: column;

      .table-cell {
        width: 100%;
        min-height: 46px;
        height: auto;
        display: flex;
        align-items: center;
        padding: 0 18px;

        .table-cell-label {
          min-width: 30%;
          width: 30%;
          background-color: transparent;
        }

        .select {
          width: 287px;

          /deep/ .vs__search {
            background-color: $main-white !important;
          }
          /deep/ .vs__selected {
            background-color: $main-white !important;
          }
          /deep/ .vs__actions {
            background-color: $main-white !important;
          }
          /deep/ .vs__open-indicator {
            background-color: $main-white !important;
          }
          /deep/ .vs__dropdown-menu {
            background-color: $main-white !important;
          }
          /deep/ .vs__dropdown-option {
            background-color: $main-white;
            color: $main-dark;
            &:hover {
              color: $bright-blue;
            }
          }
        }

        .table-cell-name {
          font-family: $font-regular;
          background-color: transparent;
        }
      }
    }
    button {
      color: $bright-blue;
      margin-top: 50px;
      outline: none;
      padding: 0 0 24px 24px;
    }
  }
  .student-lesson-info-container :nth-child(odd) {
    background: $light-table-blue;
  }
}

@media screen and (max-width: 720px) {
  .card-container {
    .card-header-container {
      flex-direction: column-reverse;
      .edit-btn {
        display: flex;
        flex-direction: column;
        button {
          align-self: flex-end;
          justify-self: flex-end;
          margin-right: 0;
          margin-bottom: 12px;
          .edit-label {
            display: none;
          }
        }
        .student-controls-btn {
          width: 100%;
          button {
            width: 100%;
          }
          .lesson-cancel-btn {
            margin-bottom: 12px;
          }
        }
      }
      .lesson-details {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 14px;
        grid-row-gap: 14px;
        width: 100%;
        margin-bottom: 14px;
        .name {
          grid-column-start: 1;
          grid-column-end: 3;
        }
        .lesson-date,
        .lesson-time {
          margin-left: 0;
        }
      }
    }
    .card-members {
      margin-top: 16px;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    .card-status-container {
      margin-top: 16px;
    }
    .card-info-container {
      flex-direction: column;
      .first-table :nth-child(odd) {
        background-color: $main-white;
      }
      .second-table :nth-child(odd) {
        background-color: $main-white;
      }
      .second-table {
        margin-right: 0;
      }
      .table-cell {
        flex-direction: column;
        align-items: flex-start !important;
        margin-bottom: 14px;
        input {
          width: 100% !important;
        }

        textarea {
          width: 100% !important;
        }
      }
    }
  }
}

@media screen and (max-width: 344px) {
  .edit-buttons {
    display: flex;
    flex-direction: column;
    width: unset !important;
    margin-right: 20px !important;
    margin-left: 20px !important;
    button {
      margin-bottom: 12px;
      width: 100% !important;
    }
  }
}
</style>
