<template>
  <div class="methodologist-profile">
    <div class="left-side">
      <div class="personal-card">
        <PersonalCard
          @update="
            getMethodologistInfo()
            getMethodologistLessons()
          "
          :info="profileInfo"
          type="methodologist"
        />
      </div>
      <div class="non-working-days">
        <NonWorkingDays :days="nonWorking" :uuid="$cookie.get('uid')" />
      </div>
    </div>
    <div class="right-side">
      <div class="personal-schedule">
        <PersonalSchedule :lessons="lessons" type="methodist" />
      </div>
    </div>
  </div>
</template>

<script>
import PersonalCard from '@/views/Profile/components/PersonalCard'
import PersonalSchedule from '@/views/Profile/components/PersonalSchedule'
import NonWorkingDays from '@/views/Profile/components/NonWorkingDays'
import axios from 'axios'
import { domain } from '@/globalVariables'

export default {
  name: 'MethodologistProfile',
  components: { PersonalCard, PersonalSchedule, NonWorkingDays },
  data() {
    return {
      nonWorking: [],
      profileInfo: {},
      lessons: []
    }
  },
  mounted() {
    this.getMethodologistInfo()
    this.getMethodologistNonWorking()
    this.getMethodologistLessons()
  },
  methods: {
    /**
     * Получить личную информацию текущего пользователя (методиста)
     */
    getMethodologistInfo() {
      axios({
        url: `${domain}/monolit/Methodologist/getItem/${this.$cookie.get('id')}`,
        method: 'GET'
      })
        .then((response) => {
          this.profileInfo = response.data.data
          this.$root.$emit('updateName', response.data.data.full_name)
        })
        .catch((error) => this.errorAlert(error))
    },

    /**
     * Получить нерабочие дни текущего пользователя
     */
    getMethodologistNonWorking() {
      axios({
        url: `${domain}/monolit/Rest/getItems`,
        method: 'GET',
        params: {
          user_uuid: this.$cookie.get('uid')
        }
      })
        .then((response) => {
          this.nonWorking = response.data.data.items
        })
        .catch((error) => this.errorAlert(error))
    },

    /**
     * Получить расписание на день текущего пользователя
     */
    getMethodologistLessons() {
      axios({
        url: `${domain}/monolit/Lesson/getLessonsForDay`,
        method: 'GET'
      })
        .then((response) => {
          this.lessons = response.data.data
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../styleVariables';
.methodologist-profile {
  display: grid;
  grid-template-columns: 0.8fr 1fr;
  grid-column-gap: 28px;
  padding: 30px 24px;
  background-color: $background-blue;
  width: 100%;
  max-width: 1800px;
  margin: 0 auto;
  .right-side {
    //max-width: 844px;
    width: 100%;
  }
  .left-side {
    //max-width: 740px;
    width: 100%;
    .personal-card {
      margin-bottom: 30px;
    }
    .non-working-days {
      margin-bottom: 30px;
    }
  }
}

@media screen and (max-width: 1680px) {
  .methodologist-profile {
    grid-template-columns: 1fr;
    /deep/ .personal-info-header {
      &__name {
        max-width: unset !important;
      }
      .save {
        margin-right: 18px;
      }
    }
    .right-side {
      max-width: unset;
    }
  }
}

@media screen and (max-width: 1040px) {
  .non-working-days {
    display: none;
  }
  .personal-schedule {
    display: none;
  }
  .methodologist-profile {
    padding: 8px 16px;
  }
}
</style>
