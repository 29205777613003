<template>
  <div class="manager-profile">
    <div class="left-side">
      <div class="personal-card">
        <PersonalCard @update="getManagerInfo" :info="profileInfo" type="manager" />
      </div>
      <div class="non-working-days">
        <NonWorkingDays
          :days="nonWorking"
          type="manager"
          @updateRest="getManagerNonWorking"
          :uuid="$cookie.get('uid')"
        />
      </div>
    </div>
    <div class="right-side">
      <Statistic />
    </div>
  </div>
</template>

<script>
import PersonalCard from '@/views/Profile/components/PersonalCard'
import NonWorkingDays from '@/views/Profile/components/NonWorkingDays'
import Statistic from '@/views/Profile/components/Statistic/Statistic'

import axios from 'axios'
import { domain } from '@/globalVariables'
export default {
  name: 'ManagerProfile',
  components: { PersonalCard, NonWorkingDays, Statistic },
  data() {
    return {
      profileInfo: {},
      nonWorking: []
    }
  },
  mounted() {
    this.getManagerInfo()
    this.getManagerNonWorking()
  },
  methods: {
    /**
     * Получение личной информации о текущем пользователе (менеджер)
     */
    getManagerInfo() {
      axios({
        url: `${domain}/monolit/Manager/getItem/${this.$cookie.get('id')}`,
        method: 'GET'
      })
        .then((response) => {
          this.profileInfo = response.data.data
          this.$root.$emit('updateName', response.data.data.full_name)
        })
        .catch((error) => this.errorAlert(error))
    },

    /**
     * Получение нерабочих дней текущего пользователя
     */
    getManagerNonWorking() {
      axios({
        url: `${domain}/monolit/Rest/getItems`,
        method: 'GET',
        params: {
          user_uuid: this.$cookie.get('uid')
        }
      })
        .then((response) => {
          this.nonWorking = response.data.data.items
        })
        .catch((error) => this.errorAlert(error))
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../styleVariables';
.manager-profile {
  display: flex;
  justify-content: space-between;
  padding: 30px 24px;
  background-color: $background-blue;
  .right-side {
    max-width: 844px;
    width: 100%;
  }
  .left-side {
    max-width: 740px;
    width: 100%;
    margin-right: 18px;
    .personal-card {
      margin-bottom: 30px;
    }
    .non-working-days {
      margin-bottom: 30px;
    }
  }
}
</style>
