<template>
  <div class="dialogue-filters">
    <div class="dialogue-filters-select" v-if="$cookie.get('internalName') === 'admin'">
      <div class="select-status">
        <div class="select-status__label" @click="showStatuses = !showStatuses">
          <span>{{ selectedStatus.name }}</span>
          <img
            src="../../../../assets/img/ES/chevron_down.svg"
            alt="open"
            :style="showStatuses ? 'transform: rotate(180deg)' : ''"
          />
        </div>
        <div class="select-status__list" v-show="showStatuses">
          <FilterSelect
            :items="statuses"
            :multiple="false"
            :filters="filters"
            @filter="filterStatuses"
            name="status"
          />
        </div>
      </div>
      <div class="select-role">
        <div class="select-role__label" @click="showRoles = !showRoles">
          <span>Роль</span>
          <img
            src="../../../../assets/img/ES/chevron_down.svg"
            alt="open"
            :style="showRoles ? 'transform: rotate(180deg)' : ''"
          />
        </div>
        <div class="select-role__list" v-show="showRoles">
          <FilterSelect
            :items="roles"
            :multiple="true"
            :filters="filters"
            @filter="filterRole"
            name="role"
          />
        </div>
      </div>
      <div class="select-clear">
        <button>
          <img
            src="../../../../assets/img/ES/big_blue_cross.svg"
            alt="clear"
            @click="clearFilters"
          />
        </button>
      </div>
    </div>
    <div class="dialogue-filters-search">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="search-img"
      >
        <path
          d="M18.677 19.607L12.962 13.891C10.4196 15.6984 6.91642 15.2563 4.90285 12.8739C2.88929 10.4915 3.03714 6.96358 5.24298 4.75799C7.44824 2.55144 10.9765 2.40295 13.3594 4.4164C15.7422 6.42986 16.1846 9.93344 14.377 12.476L20.092 18.192L18.678 19.606L18.677 19.607ZM9.48498 4.99997C7.58868 4.99955 5.95267 6.33066 5.56745 8.18742C5.18224 10.0442 6.15369 11.9163 7.89366 12.6702C9.63362 13.4242 11.6639 12.8528 12.7552 11.302C13.8466 9.75126 13.699 7.64731 12.402 6.26399L13.007 6.86399L12.325 6.18399L12.313 6.17199C11.5648 5.41917 10.5464 4.99712 9.48498 4.99997Z"
          :fill="search ? '#222B45' : '#C5CEE0'"
        />
      </svg>

      <input
        type="text"
        v-model.trim="search"
        @input="$emit('filterDialogue', filters, search)"
        placeholder="Поиск по ФИО"
      />
    </div>
    <div class="dialogue-filters-tabs" v-if="$cookie.get('internalName') === 'admin'">
      <span
        class="active-dialogues"
        @click="
          isActive = true
          $emit('switchRole', 'participant', filters, search)
        "
        :style="isActive ? 'color: #0057FF' : 'color: #C5CEE0'"
        >Активные диалоги</span
      >
      <span
        class="inactive-dialogues"
        @click="
          isActive = false
          $emit('switchRole', 'observer', filters)
        "
        :style="!isActive ? 'color: #0057FF' : 'color: #C5CEE0'"
        >Для чтения</span
      >
    </div>
  </div>
</template>

<script>
import FilterSelect from '@/views/Chat/SideBar/components/FilterSelect'
import axios from 'axios'
import { domain, MicroServices } from '@/globalVariables'
export default {
  name: 'DialogueFilters',
  components: { FilterSelect },
  data() {
    return {
      showRoles: false,
      showStatuses: false,
      roles: [],
      statuses: [
        {
          name: 'Все сообщения',
          id: 'all'
        },
        {
          name: 'Прочитанные',
          id: 'read'
        },
        {
          name: 'Непрочитанные',
          id: 'unread'
        }
      ],
      selectedRole: null,
      selectedStatus: null,
      search: null,
      isActive: true,
      filters: {
        role: [],
        status: []
      }
    }
  },
  mounted() {
    this.getFilterRoles()
  },
  methods: {
    /**
     * Отфильтровать по статусу (прочитано/непрочитано)
     * @param filters
     */
    filterStatuses(filters) {
      this.filters = filters
      this.$emit('filterDialogue', filters)
      this.showStatuses = false
      this.selectedStatus = this.statuses.find((item) => item.id === filters.status[0])
    },
    /**
     * Отфильтровать по роли
     * @param filters
     */
    filterRole(filters) {
      this.filters = filters
      this.$emit('filterDialogue', filters)
    },
    /**
     * Очистить фильтр
     */
    clearFilters() {
      this.filters = { role: [], status: [] }
      this.search = null
      this.$root.$emit('setClearChatFilters')
      this.selectedStatus = this.statuses[0]
      this.showStatuses = false
      this.showRoles = false
      this.$emit('filterDialogue')
    },
    /**
     * Получить роли для фильтра
     */
    getFilterRoles() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Role/getItems`,
        method: 'GET'
      })
        .then((response) => {
          this.roles = response.data.data.items
          this.selectedRole = this.roles[0]
          this.selectedStatus = this.statuses[0]
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../../styleVariables';

.dialogue-filters {
  &-select {
    padding: 7px 9px 6px 26px;
    display: grid;
    grid-template-columns: 1fr 1fr 30px;
    grid-column-gap: 12px;
    .select-role,
    .select-status,
    .select-clear {
      align-self: center;
    }
    .select-status__label,
    .select-role__label {
      cursor: pointer;
      img {
        transition: 0.2s;
      }
    }
    span {
      color: $main-gray;
      font-size: 14px;
      margin-right: 6px;
    }
    .select-clear {
      button {
        border-radius: 50px;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:active {
          box-shadow: none;
        }
      }
    }
  }
  &-search {
    position: relative;
    .search-img {
      position: absolute;
      margin-top: 9px;
      margin-left: 24px;
      path {
        transition: 0.2s;
      }
    }
    input {
      width: 100%;
      height: 40px;
      outline: 1px solid #edf3fa;
      padding: 10px 10px 10px 57px;
      color: $main-dark;
      font-family: $font-regular;
      &::placeholder {
        color: $light-gray;
        font-size: 14px;
        font-family: $font-regular;
      }
    }
  }
  &-tabs {
    padding: 24px 26px 6px;
    span {
      font-size: 14px;
      text-transform: uppercase;
      cursor: pointer;
    }
    .active-dialogues {
      margin-right: 25px;
    }
  }
}
</style>
