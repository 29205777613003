<template>
  <div class="profile">
    <TeacherProfile
      v-if="
        $cookie.get('internalName') === 'teacher' ||
        $cookie.get('internalName') === 'almost_teacher'
      "
    />
    <StudentProfile v-else-if="$cookie.get('internalName') === 'student'" />
    <MethodologistProfile
      v-else-if="
        $cookie.get('internalName') === 'methodologist' ||
        $cookie.get('internalName') === 'almost_methodologist'
      "
    />
    <ManagerProfile v-else-if="$cookie.get('internalName') === 'manager'" />
    <AdminProfile v-else-if="$cookie.get('internalName') === 'admin'" />
  </div>
</template>

<script>
import TeacherProfile from '@/views/Profile/Teacher/TeacherProfile'
import StudentProfile from '@/views/Profile/Student/StudentProfile'
import MethodologistProfile from '@/views/Profile/Methodologist/MethodologistProfile'
import ManagerProfile from '@/views/Profile/Manager/ManagerProfile'
import AdminProfile from '@/views/Profile/Admin/AdminProfile'
export default {
  name: 'Profile',
  components: { AdminProfile, ManagerProfile, MethodologistProfile, StudentProfile, TeacherProfile }
}
</script>

<style scoped></style>
