export default {
  methods: {
    Toggle_Change(data) {
      if (data.emit_name) {
        this.$root.$emit(`${data.emit_name}`)
        this.$store.commit('newRec', data)
      } else {
        this.$store.commit('newRec', data)
      }
    }
  },
  data() {
    return {}
  }
}
