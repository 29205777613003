<template>
  <div class="header-chat">{{ title }}</div>
</template>

<script>
export default {
  name: 'HeaderChat',
  props: ['title']
}
</script>

<style scoped lang="scss">
@import '../../../../styleVariables';
.header-chat {
  font-size: 16px;
  line-height: 20px;
  color: $main-dark;
  padding: 10px 24px;
}
</style>
