import Vue from 'vue'
import App from './App'
import router from './router'
import VueSelect from 'vue-select'
import BootstrapVue from 'bootstrap-vue'
import 'vue-select/dist/vue-select.css'
import store from './store'
import Toasted from 'vue-toasted'
import cryptoJs from 'crypto-js'
import VueCookie from 'vue-cookie'
import globals from './globals'
import serverHandler from './components/Core/KernelProject/serverHandler'
import Popper from 'popper.js'
import ToggleButton from 'vue-js-toggle-button'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import Deselect from '@/components/Widgets/Select/Deselect'
import OpenIndicator from '@/components/Widgets/Select/OpenIndicator'
import vuetify from './plugins/vuetify'
import VCalendar from 'v-calendar'
import centrifuge from './centrifuge'
import Donut from 'vue-css-donut-chart'
import 'vue-css-donut-chart/dist/vcdonut.css'

export const debug = false

// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false
const VueInputMask = require('vue-inputmask').default

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(VueSelect)
VueSelect.props.components.default = () => ({ OpenIndicator, Deselect })
Vue.use(VueInputMask)
Vue.use(Toasted)
Vue.use(cryptoJs)
Vue.use(VueCookie)
Vue.use(ToggleButton)
Vue.use(vuetify)
Vue.use(VCalendar)
Vue.use(centrifuge)
Vue.use(Donut)
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker)

Vue.mixin(serverHandler)
// Global RTL flag
Vue.mixin({
  data: globals
})

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')

const isDebug = debug ? -1 : ''

Vue.config.warnHandler = function (msg, vm, trace) {
  if (msg.indexOf('prop') !== isDebug) {
    return msg
  } else {
    return vm.errorAlert(msg)
  }
}

Vue.config.errorHandler = function (err, vm, info) {
  if (err.message.indexOf('label') !== isDebug) {
    return err
  } else {
    return vm.errorAlert(err)
  }
}
