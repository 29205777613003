<template>
  <div class="report-dashboard">
    <div class="report-dashboard__reports" v-if="data.length">
      <div
        class="report"
        :class="{ active: currentId === item.id && !isPayout, disabled: isPayout }"
        v-for="item in data"
        :key="item.id"
        @click="!isPayout && $emit('filterByPayout', item.id)"
      >
        {{ `Отчет ${item.date}` }}
      </div>
    </div>
    <div class="report-dashboard__none" v-else>Нет отчетов за выбранный месяц</div>
    <div class="report-dashboard__controls" v-if="!isPayout">
      <button class="download" @click="$emit('downloadReport')">
        <img src="../../../assets/img/ES/blue_download.svg" alt="download" />
        Скачать отчет
      </button>
      <button class="pay" @click="$emit('makePayout')">Осуществить выплату</button>
    </div>
    <div class="report-dashboard__controls" v-else>
      <button class="download" @click="$emit('cancelPayout')">Отмена</button>
      <button class="pay" @click="$emit('savePayout')">Сохранить</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReportDashboard',
  props: {
    data: Array,
    currentId: Number,
    isPayout: Boolean
  }
}
</script>

<style scoped lang="scss">
@import '../../../styleVariables';

.report-dashboard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 21px 0 35px;
  box-shadow: 1px 0px 20px -7px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  background-color: $main-white;
  margin: 0 12px 10px 12px;
  &__reports {
    display: flex;
    overflow-x: auto;
    padding-bottom: 23px;
    .report {
      margin-right: 52px;
      cursor: pointer;
      font-size: 18px;
      line-height: 24px;
      color: $main-gray;
      min-width: 163px;
    }
    .active {
      color: $bright-blue;
    }
    .disabled {
      cursor: default;
    }
  }
  &__none {
    font-size: 18px;
    line-height: 24px;
    color: $main-gray;
    padding-bottom: 23px;
  }
  &__controls {
    display: flex;
    padding-bottom: 23px;
    padding-left: 12px;
    button {
      width: 172px;
      height: 32px;
      font-size: 14px;
      line-height: 24px;
      border-radius: 4px;
    }
    .download {
      border: 1px solid $bright-blue;
      color: $bright-blue;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      img {
        margin-right: 3px;
      }
    }
    .pay {
      background-color: $bright-blue;
      color: $main-white;
    }
  }
}
</style>
