export default {
  methods: {
    Radio_Change(data) {
      if (data.emit) {
        this.$root.$emit(`${data.emit}`, data.value)
      } else {
        this.$store.commit('newRec', data)
      }
    }
  },
  data() {
    return {}
  }
}
