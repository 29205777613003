export default {
  methods: {
    /**
     * Получить слово "занятие" с нужным окончанием в зависимости от кол-ва
     * @param count
     * @returns {string}
     */
    endingOfWord(count) {
      if (count > 100) {
        count = count % Math.pow(10, count.toString().length - 1)
      }
      count = count.toString().replaceAll('-', '')
      if (Number(count) > 19 && Number(count[count.length - 1]) === 1) {
        return 'занятие'
      }
      if (
        Number(count) > 19 &&
        Number(count[count.length - 1]) > 1 &&
        Number(count[count.length - 1]) < 5
      ) {
        return 'занятия'
      }
      if (
        Number(count) > 19 &&
        (Number(count[count.length - 1]) > 4 || Number(count[count.length - 1]) === 0)
      ) {
        return 'занятий'
      }
      if ((Number(count) > 4 && Number(count) < 21) || Number(count) === 0) {
        return 'занятий'
      }
      if (Number(count) === 1) {
        return 'занятие'
      }
      if (Number(count) > 1 && Number(count) < 5) {
        return 'занятия'
      }
      return 'занятий'
    },

    /**
     * Получить слово "студент" с нужным окончанием в зависимости от кол-ва
     * @param count
     * @returns {string}
     */
    endingOfWordStudent(count) {
      if (count > 100) {
        count = count % Math.pow(10, count.toString().length - 1)
      }
      count = count.toString().replaceAll('-', '')
      if (Number(count) > 19 && Number(count[count.length - 1]) === 1) {
        return 'студент'
      }
      if (
        Number(count) > 19 &&
        Number(count[count.length - 1]) > 1 &&
        Number(count[count.length - 1]) < 5
      ) {
        return 'студента'
      }
      if (
        Number(count) > 19 &&
        (Number(count[count.length - 1]) > 4 || Number(count[count.length - 1]) === 0)
      ) {
        return 'студентов'
      }
      if ((Number(count) > 4 && Number(count) < 21) || Number(count) === 0) {
        return 'студентов'
      }
      if (Number(count) === 1) {
        return 'студент'
      }
      if (Number(count) > 1 && Number(count) < 5) {
        return 'студента'
      }
      return 'студентов'
    },

    /**
     * Получить слово "жалоба" ч нужным окончанием в зависимости от кол-ва
     * @param count
     * @returns {string}
     */
    endingOfWordComplaint(count) {
      if (count > 100) {
        count = count % Math.pow(10, count.toString().length - 1)
      }
      count = count.toString().replaceAll('-', '')
      if (Number(count) > 19 && Number(count[count.length - 1]) === 1) {
        return 'жалоба'
      }
      if (
        Number(count) > 19 &&
        Number(count[count.length - 1]) > 1 &&
        Number(count[count.length - 1]) < 5
      ) {
        return 'жалобы'
      }
      if (
        Number(count) > 19 &&
        (Number(count[count.length - 1]) > 4 || Number(count[count.length - 1]) === 0)
      ) {
        return 'жалоб'
      }
      if ((Number(count) > 4 && Number(count) < 21) || Number(count) === 0) {
        return 'жалоб'
      }
      if (Number(count) === 1) {
        return 'жалоба'
      }
      if (Number(count) > 1 && Number(count) < 5) {
        return 'жалобы'
      }
      return 'жалоб'
    },

    /**
     * Получить время в тайм зоне пользователя
     * @param utc
     * @param date
     * @returns {Date}
     */
    countUserTimeByTimeZone(utc, date) {
      let time = date ? new Date(date) : new Date()
      let timeZone = time.getTime() + time.getTimezoneOffset() * 60000
      let newTime = new Date(timeZone + 3600000 * utc)
      return newTime
    },

    /**
     * Убрать все символы кроме цифр и заменить запятую на точку
     * @param word
     * @returns {*}
     */
    cutLetterSymbols(word) {
      word = word.replace(',', '.')
      return word
        .replace(/[^0-9.]/g, '')
        .replace('.', 'x')
        .replace(/\./g, '')
        .replace('x', '.')
    }
  }
}
