import { host } from '../../../globalVariables'
import axios from 'axios'

export default {
  methods: {
    getBase64(file) {
      // eslint-disable-next-line no-undef
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line no-undef
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    },
    Change__File(data) {
      if (data.value) {
        this.data = data
        this.getBase64(data.event.target.files[0]).then((response) =>
          this.sendFileToServer(response)
        )
      } else {
        this.$store.commit('newRec', data)
      }
    },
    sendFileToServer(base64) {
      base64 = base64.split(',')[1]
      let newRec = []
      newRec.push(this.data.event.target.files[0].name)
      newRec.push(base64)
      let url = ''
      let method = 'POST'
      if (this.data.type === 'image') {
        url = `${host.fileServerUrl}/filesrv/Image/upload`
      } else if (this.data.type === 'file') {
        url = `${host.fileServerUrl}/filesrv/File/upload`
      }
      const options = {
        method: method,
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: newRec,
        url
      }
      axios(options)
        .then((response) => {
          let data = response.data
          this.data.value = data.data
          this.$store.commit('newRec', this.data)
        })
        .catch((error) => this.errorAlert(error.response))
    }
  },
  data() {
    return {
      InputFile: {
        value: this.value,
        text: this.text,
        disabled: this.disabled,
        placeholder: this.placeholder,
        tooltip: this.tooltip,
        keyAdd: this.keyAdd
      }
    }
  }
}
