var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('li',{staticClass:"last-lesson-card-header",on:{"click":_vm.showCard}},[_c('div',{staticClass:"arrow"},[_c('svg',{style:(_vm.arrowPosition),attrs:{"width":"10","height":"6","viewBox":"0 0 10 6","fill":"#8F9BB3","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M8.1958 0.291505C8.52095 -0.033645 9.04795 -0.0341907 9.37378 0.290286C9.70055 0.615714 9.7011 1.1446 9.375 1.4707L5 5.8457L0.625001 1.4707C0.298897 1.1446 0.299445 0.615714 0.626223 0.290286C0.952046 -0.0341908 1.47905 -0.033645 1.8042 0.291505L5 3.4873L8.1958 0.291505Z","fill":"#8F9BB3"}})])]),_vm._v(" "),_c('h4',{staticClass:"card-title"},[_vm._v("\n      "+_vm._s(_vm.lessonInfo.sequence_number ? ("Занятие №" + (_vm.lessonInfo.sequence_number)) : 'Занятие')+"\n    ")])]),_vm._v(" "),_c('b-collapse',{staticClass:"card-body",attrs:{"visible":_vm.isCardShowing}},[(_vm.showLessonCard)?_c('div',[(!_vm.glossarySelected)?_c('div',{staticClass:"card-container",class:{
          studentCard: _vm.userRole === 'student',
          'card-container--red': _vm.validationError
        }},[_c('div',{staticClass:"card-header-container"},[_c('div',{staticClass:"lesson-details"},[_c('div',{staticClass:"lesson-date"},[_c('img',{attrs:{"src":require("../../../assets/img/icons/calendar-dark.svg")}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.formatDate(_vm.lessonInfo.date)))])]),_vm._v(" "),_c('div',{staticClass:"lesson-time"},[_c('img',{attrs:{"src":require("../../../assets/img/icons/alarm.svg")}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.formatTime(_vm.lessonInfo.hour)))])])]),_vm._v(" "),(_vm.lessonInfo.in_arbitration)?_c('div',{staticClass:"arbitration-status"},[_c('img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:(("Арбитраж спора (" + (_vm.lessonInfo.kls_complaint_status_name) + ")")),expression:"`Арбитраж спора (${lessonInfo.kls_complaint_status_name})`"}],attrs:{"src":require("../../../assets/img/ES/error_outline.svg"),"alt":"arbitration"}})]):_vm._e()]),_vm._v(" "),(_vm.statusCode === 'LESSON_PLANNED')?_c('div',{staticClass:"lesson-link-container"},[_c('label',[_vm._v("Ссылка на урок:")]),_vm._v(" "),(
              _vm.editLessonCardMode &&
              (_vm.$cookie.get('internalName') === 'manager' ||
                _vm.$cookie.get('internalName') === 'admin')
            )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.linkToZoom),expression:"linkToZoom"}],attrs:{"type":"text"},domProps:{"value":(_vm.linkToZoom)},on:{"input":function($event){if($event.target.composing){ return; }_vm.linkToZoom=$event.target.value}}}):(_vm.lessonInfo.link_to_zoom)?_c('a',{staticClass:"lesson-link",attrs:{"href":_vm.lessonInfo.link_to_zoom.indexOf('http') !== -1
                ? _vm.lessonInfo.link_to_zoom
                : ("http://" + (_vm.lessonInfo.link_to_zoom)),"target":"_blank"}},[_vm._v(_vm._s(_vm.lessonInfo.link_to_zoom))]):(!_vm.lessonInfo.link_to_zoom)?_c('span',[_vm._v("Ссылка недоступна")]):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"card-members"},[(_vm.$cookie.get('internalName') !== 'student')?_c('div',{staticClass:"card-member-name"},[(_vm.lessonInfo.student_name || _vm.lessonInfo.student_short_name)?_c('span',{staticClass:"label"},[_vm._v("Student Name")]):_vm._e(),_vm._v(" "),(
                _vm.$cookie.get('internalName') === 'manager' ||
                _vm.$cookie.get('internalName') === 'admin'
              )?_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.lessonInfo.student_name))]):_vm._e(),_vm._v(" "),(
                _vm.$cookie.get('internalName') !== 'manager' &&
                _vm.$cookie.get('internalName') !== 'admin'
              )?_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.lessonInfo.student_short_name))]):_vm._e()]):_vm._e(),_vm._v(" "),(
              ((_vm.typeCode !== 'PLACEMENT_TEST' && _vm.typeCode !== 'CHECK_UP') ||
                _vm.$cookie.get('internalName') === 'student') &&
              _vm.$cookie.get('internalName') !== 'teacher' &&
              _vm.$cookie.get('internalName') !== 'methodologist'
            )?_c('div',{staticClass:"card-member-name"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.lessonInfo.teacher_name ||
                _vm.lessonInfo.teacher_short_name ||
                _vm.teacher ||
                (_vm.$cookie.get('internalName') === 'student' && _vm.lessonInfo.methodologist_short_name)
              ),expression:"\n                lessonInfo.teacher_name ||\n                lessonInfo.teacher_short_name ||\n                teacher ||\n                ($cookie.get('internalName') === 'student' && lessonInfo.methodologist_short_name)\n              "}],staticClass:"label"},[_vm._v("Teacher Name")]),_vm._v(" "),(
                _vm.editLessonCardMode &&
                _vm.status === 43 &&
                (_vm.$cookie.get('internalName') === 'manager' ||
                  _vm.$cookie.get('internalName') === 'admin')
              )?_c('vue-select',{staticStyle:{"width":"260px"},attrs:{"clearable":false,"options":_vm.teachers},on:{"open":function($event){return _vm.getTeachers(_vm.lessonInfo.date, _vm.lessonInfo.student_uuid)}},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"d-center"},[_c('span',[_vm._v(_vm._s(option.full_name))])])]}}],null,false,2065877257),model:{value:(_vm.teacher),callback:function ($$v) {_vm.teacher=$$v},expression:"teacher"}},[_vm._v(" "),_c('template',{slot:"selected-option"},[_c('div',{staticClass:"selected d-center"},[_vm._v("\n                  "+_vm._s(_vm.teacher.full_name)+"\n                ")])])],2):(
                _vm.$cookie.get('internalName') === 'manager' ||
                _vm.$cookie.get('internalName') === 'admin'
              )?_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.lessonInfo.teacher_name))]):(
                _vm.$cookie.get('internalName') !== 'manager' &&
                _vm.$cookie.get('internalName') !== 'admin' &&
                _vm.lessonInfo.teacher_short_name
              )?_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.lessonInfo.teacher_short_name))]):(
                _vm.$cookie.get('internalName') === 'student' && _vm.lessonInfo.methodologist_short_name
              )?_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.lessonInfo.methodologist_short_name))]):_vm._e()],1):_vm._e(),_vm._v(" "),(
              _vm.$cookie.get('internalName') !== 'student' &&
              _vm.$cookie.get('internalName') !== 'methodologist' &&
              (_vm.typeCode === 'PLACEMENT_TEST' ||
                _vm.typeCode === 'TEACHER_TEST' ||
                _vm.typeCode === 'CHECK_UP')
            )?_c('div',{staticClass:"card-member-name"},[_c('span',{staticClass:"label"},[_vm._v("Methodist Name")]),_vm._v(" "),(
                _vm.editLessonCardMode &&
                _vm.statusCode === 'LESSON_PLANNED' &&
                (_vm.$cookie.get('internalName') === 'manager' ||
                  _vm.$cookie.get('internalName') === 'admin')
              )?_c('vue-select',{staticStyle:{"width":"260px"},attrs:{"clearable":false,"options":_vm.methodists},on:{"open":function($event){return _vm.getMethodists(_vm.lessonInfo.date)}},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"d-center"},[_c('span',[_vm._v(_vm._s(option.full_name))])])]}}],null,false,2065877257),model:{value:(_vm.methodist),callback:function ($$v) {_vm.methodist=$$v},expression:"methodist"}},[_vm._v(" "),_c('template',{slot:"selected-option"},[_c('div',{staticClass:"selected d-center"},[_vm._v("\n                  "+_vm._s(_vm.methodist.full_name)+"\n                ")])])],2):(
                _vm.$cookie.get('internalName') === 'manager' ||
                _vm.$cookie.get('internalName') === 'admin'
              )?_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.lessonInfo.methodologist_name))]):(
                _vm.$cookie.get('internalName') !== 'manager' &&
                _vm.$cookie.get('internalName') !== 'admin'
              )?_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.lessonInfo.methodologist_short_name))]):_vm._e()],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"card-status-container"},[_c('div',{staticClass:"card-status"},[_c('span',[_vm._v("Status")]),_vm._v(" "),(!_vm.removeStatus)?_c('div',{class:{
                lessonFinished: _vm.statusCode === 'LESSON_ENDED',
                lessonPlanned: _vm.statusCode === 'LESSON_PLANNED',
                lessonMissedByStudent: _vm.statusCode === 'SKIPPED_STUDENT',
                lessonMissedByStudentWithReason: _vm.statusCode === 'SKIPPED_STUDENT_WITH_REASON',
                lessonMissedByTeacher: _vm.statusCode === 'SKIPPED_TEACHER',
                lessonCanceled: _vm.statusCode === 'CANCELLED'
              }},[_vm._v("\n              "+_vm._s(_vm.statusName)+"\n              "),(
                  _vm.editLessonCardMode &&
                  (_vm.$cookie.get('internalName') === 'manager' ||
                    _vm.$cookie.get('internalName') === 'admin')
                )?_c('img',{attrs:{"src":require("../../../assets/img/ES/close_big.svg"),"alt":"close"},on:{"click":function($event){return _vm.setRemoveStatus(true)}}}):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.removeStatus)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.setDefaultStatus),expression:"setDefaultStatus"}],staticClass:"choose-status"},[_c('statuses',{attrs:{"currentType":_vm.typeCode,"currentStatus":_vm.statusCode},on:{"chooseStatus":_vm.changeStatus}})],1):_vm._e()]),_vm._v(" "),(_vm.$cookie.get('internalName') !== 'student')?_c('div',{staticClass:"card-type"},[_c('span',[_vm._v("Type")]),_vm._v(" "),(!_vm.removeType)?_c('div',{class:{
                regularLesson:
                  _vm.typeCode === 'REGULAR' || _vm.typeCode === 'TEACHER_TEST_REGULAR_LESSON',
                teacherTesting: _vm.typeCode === 'TEACHER_TEST',
                placementTest: _vm.typeCode === 'PLACEMENT_TEST',
                checkUpLesson: _vm.typeCode === 'CHECK_UP',
                progressTest:
                  _vm.typeCode === 'CHECK_PROGRESS' ||
                  _vm.typeCode === 'TEACHER_TEST_PROGRESS_TEST_LESSON'
              }},[_vm._v("\n              "+_vm._s(_vm.typeName)+"\n              "),(_vm.editLessonCardMode)?_c('img',{attrs:{"src":require("../../../assets/img/ES/close_big.svg"),"alt":"close"},on:{"click":function($event){return _vm.setRemoveType(true)}}}):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.removeType)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.setDefaultType),expression:"setDefaultType"}],staticClass:"choose-type"},[_c('types',{on:{"chooseType":_vm.changeType}})],1):_vm._e()]):_vm._e()]),_vm._v(" "),(_vm.$cookie.get('internalName') !== 'student')?_c('div',{staticClass:"card-info-container"},[_c('div',{staticClass:"first-table"},[_c('div',{staticClass:"table-cell"},[_c('span',{staticClass:"table-cell-label"},[_vm._v("Level")]),_vm._v(" "),(
                  _vm.editLessonCardMode &&
                  (_vm.$cookie.get('internalName') === 'manager' ||
                    _vm.$cookie.get('internalName') === 'admin')
                )?_c('vue-select',{staticClass:"select",attrs:{"clearable":false,"options":_vm.levels},on:{"input":_vm.getCEFRByLevel,"open":_vm.getLevels},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"d-center"},[_c('span',[_vm._v(_vm._s(option.name))])])]}}],null,false,816142725),model:{value:(_vm.level),callback:function ($$v) {_vm.level=$$v},expression:"level"}},[_vm._v(" "),_c('template',{slot:"selected-option"},[_c('div',{staticClass:"selected d-center"},[_vm._v("\n                    "+_vm._s(_vm.level.name)+"\n                  ")])])],2):(_vm.lessonInfo.kls_level_name !== null)?_c('span',{staticClass:"table-cell-name"},[_vm._v(_vm._s(_vm.lessonInfo.kls_level_name))]):(_vm.lessonInfo.kls_level_name === null)?_c('span',{staticClass:"table-cell-name"},[_vm._v("\n                no\n              ")]):_vm._e()],1),_vm._v(" "),(_vm.lessonTypeCode === 'PLACEMENT_TEST')?_c('div',{staticClass:"table-cell"},[_c('span',{staticClass:"table-cell-label"},[_vm._v("CEFR")]),_vm._v(" "),(
                  _vm.editLessonCardMode &&
                  (_vm.$cookie.get('internalName') === 'manager' ||
                    _vm.$cookie.get('internalName') === 'admin' ||
                    _vm.$cookie.get('internalName') === 'methodologist')
                )?_c('vue-select',{staticClass:"select",attrs:{"clearable":false,"options":_vm.CEFRs},on:{"input":_vm.getLevelByCEFR,"open":_vm.getCEFRLevels},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"d-center"},[_c('span',[_vm._v(_vm._s(option.name))])])]}}],null,false,816142725),model:{value:(_vm.CEFR),callback:function ($$v) {_vm.CEFR=$$v},expression:"CEFR"}},[_vm._v(" "),_c('template',{slot:"selected-option"},[_c('div',{staticClass:"selected d-center"},[_vm._v("\n                    "+_vm._s(_vm.CEFR.name)+"\n                  ")])])],2):(_vm.lessonInfo.kls_europe_level_name !== null)?_c('span',{staticClass:"table-cell-name"},[_vm._v(_vm._s(_vm.lessonInfo.kls_europe_level_name))]):(_vm.lessonInfo.kls_europe_level_name === null)?_c('span',{staticClass:"table-cell-name"},[_vm._v("\n                no\n              ")]):_vm._e()],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"table-cell"},[_c('span',{staticClass:"table-cell-label"},[_vm._v("Revision steps")]),_vm._v(" "),(
                  _vm.editLessonCardMode &&
                  (_vm.$cookie.get('internalName') === 'manager' ||
                    _vm.$cookie.get('internalName') === 'admin' ||
                    _vm.$cookie.get('internalName') === 'methodologist')
                )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.revisionSteps),expression:"revisionSteps"}],attrs:{"type":"text"},domProps:{"value":(_vm.revisionSteps)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.revisionSteps=$event.target.value},_vm.validateRevisionSteps]}}):(_vm.lessonInfo.revision_step !== null)?_c('span',{staticClass:"table-cell-name"},[_vm._v(_vm._s(_vm.lessonInfo.revision_step))]):(_vm.lessonInfo.revision_step === null)?_c('span',{staticClass:"table-cell-name"},[_vm._v("\n                no\n              ")]):_vm._e()]),_vm._v(" "),(_vm.lessonTypeCode === 'PLACEMENT_TEST')?_c('div',{staticClass:"table-cell"},[_c('span',{staticClass:"table-cell-label"},[_vm._v("Revision")]),_vm._v(" "),(_vm.lessonInfo.revision !== null)?_c('span',{staticClass:"table-cell-name"},[_vm._v(_vm._s(JSON.parse(_vm.lessonInfo.revision).join(', ')))]):(_vm.lessonInfo.revision === null)?_c('span',{staticClass:"table-cell-name"},[_vm._v(" no ")]):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"table-cell"},[_c('span',{staticClass:"table-cell-label"},[_vm._v("Lesson number")]),_vm._v(" "),(
                  _vm.editLessonCardMode &&
                  (_vm.$cookie.get('internalName') === 'manager' ||
                    _vm.$cookie.get('internalName') === 'admin')
                )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.lessonNumber),expression:"lessonNumber"}],attrs:{"type":"text"},domProps:{"value":(_vm.lessonNumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.lessonNumber=$event.target.value}}}):(_vm.lessonInfo.lesson_number !== null)?_c('span',{staticClass:"table-cell-name"},[_vm._v(_vm._s(_vm.lessonInfo.lesson_number))]):(_vm.lessonInfo.lesson_number === null)?_c('span',{staticClass:"table-cell-name"},[_vm._v("\n                no\n              ")]):_vm._e()]),_vm._v(" "),(_vm.lessonTypeCode !== 'PLACEMENT_TEST')?_c('div',{staticClass:"table-cell"},[_c('span',{staticClass:"table-cell-label"},[_vm._v("Last taught page")]),_vm._v(" "),(
                  _vm.editLessonCardMode &&
                  (_vm.$cookie.get('internalName') === 'manager' ||
                    _vm.$cookie.get('internalName') === 'admin')
                )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.lastPage),expression:"lastPage"}],attrs:{"type":"text"},domProps:{"value":(_vm.lastPage)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.lastPage=$event.target.value},function($event){_vm.calculateNewPages(), _vm.validateNewPages()}]}}):(_vm.lessonInfo.last_taught_page !== null)?_c('span',{staticClass:"table-cell-name"},[_vm._v(_vm._s(_vm.lessonInfo.last_taught_page))]):(_vm.lessonInfo.last_taught_page === null)?_c('span',{staticClass:"table-cell-name"},[_vm._v("\n                no\n              ")]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.lessonTypeCode !== 'PLACEMENT_TEST')?_c('div',{staticClass:"table-cell"},[_c('span',{staticClass:"table-cell-label"},[_vm._v("New words")]),_vm._v(" "),(
                  _vm.editLessonCardMode &&
                  (_vm.$cookie.get('internalName') === 'manager' ||
                    _vm.$cookie.get('internalName') === 'admin')
                )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newWords),expression:"newWords"}],attrs:{"type":"text"},domProps:{"value":(_vm.newWords)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.newWords=$event.target.value},function($event){return _vm.validateNewWords()}]}}):(_vm.lessonInfo.new_words !== null)?_c('span',{staticClass:"table-cell-name"},[_vm._v(_vm._s(_vm.lessonInfo.new_words))]):(_vm.lessonInfo.new_words === null)?_c('span',{staticClass:"table-cell-name"},[_vm._v(" no ")]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.lessonTypeCode !== 'PLACEMENT_TEST')?_c('div',{staticClass:"table-cell"},[_c('span',{staticClass:"table-cell-label"},[_vm._v("Grammar")]),_vm._v(" "),(
                  _vm.editLessonCardMode &&
                  (_vm.$cookie.get('internalName') === 'manager' ||
                    _vm.$cookie.get('internalName') === 'admin')
                )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.grammar),expression:"grammar"}],attrs:{"type":"text"},domProps:{"value":(_vm.grammar)},on:{"input":function($event){if($event.target.composing){ return; }_vm.grammar=$event.target.value}}}):(_vm.lessonInfo.grammar !== null)?_c('span',{staticClass:"table-cell-name"},[_vm._v(_vm._s(_vm.lessonInfo.grammar))]):(_vm.lessonInfo.grammar === null)?_c('span',{staticClass:"table-cell-name"},[_vm._v(" no ")]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.lessonTypeCode !== 'PLACEMENT_TEST')?_c('div',{staticClass:"table-cell"},[_c('span',{staticClass:"table-cell-label"},[_vm._v("Reading")]),_vm._v(" "),(
                  _vm.editLessonCardMode &&
                  (_vm.$cookie.get('internalName') === 'manager' ||
                    _vm.$cookie.get('internalName') === 'admin')
                )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.reading),expression:"reading"}],attrs:{"type":"text"},domProps:{"value":(_vm.reading)},on:{"input":function($event){if($event.target.composing){ return; }_vm.reading=$event.target.value}}}):(_vm.lessonInfo.reading !== null)?_c('span',{staticClass:"table-cell-name"},[_vm._v(_vm._s(_vm.lessonInfo.reading))]):(_vm.lessonInfo.reading === null)?_c('span',{staticClass:"table-cell-name"},[_vm._v(" no ")]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.lessonTypeCode !== 'PLACEMENT_TEST')?_c('div',{staticClass:"table-cell"},[_c('span',{staticClass:"table-cell-label"},[_vm._v("Other")]),_vm._v(" "),(
                  _vm.editLessonCardMode &&
                  (_vm.$cookie.get('internalName') === 'manager' ||
                    _vm.$cookie.get('internalName') === 'admin')
                )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.other),expression:"other"}],attrs:{"type":"text"},domProps:{"value":(_vm.other)},on:{"input":function($event){if($event.target.composing){ return; }_vm.other=$event.target.value}}}):(_vm.lessonInfo.other !== null)?_c('span',{staticClass:"table-cell-name"},[_vm._v(_vm._s(_vm.lessonInfo.other))]):(_vm.lessonInfo.other === null)?_c('span',{staticClass:"table-cell-name"},[_vm._v(" no ")]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.lessonTypeCode !== 'PLACEMENT_TEST')?_c('div',{staticClass:"table-cell"},[_c('span',{staticClass:"table-cell-label"},[_vm._v("Student comment")]),_vm._v(" "),(_vm.editLessonCardMode)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.studentComment),expression:"studentComment"}],domProps:{"value":(_vm.studentComment)},on:{"input":function($event){if($event.target.composing){ return; }_vm.studentComment=$event.target.value}}}):(_vm.lessonInfo.comment !== null)?_c('span',{staticClass:"table-cell-name"},[_vm._v(_vm._s(_vm.lessonInfo.comment))]):(_vm.lessonInfo.comment === null)?_c('span',{staticClass:"table-cell-name"},[_vm._v(" no ")]):_vm._e()]):_vm._e()]),_vm._v(" "),(_vm.lessonTypeCode !== 'PLACEMENT_TEST')?_c('div',{staticClass:"second-table"},[_c('div',{staticClass:"table-cell"},[_c('span',{staticClass:"table-cell-label"},[_vm._v("CEFR")]),_vm._v(" "),(
                  _vm.editLessonCardMode &&
                  (_vm.$cookie.get('internalName') === 'manager' ||
                    _vm.$cookie.get('internalName') === 'admin')
                )?_c('vue-select',{staticClass:"select",attrs:{"clearable":false,"options":_vm.CEFRs},on:{"input":_vm.getLevelByCEFR,"open":_vm.getCEFRLevels},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"d-center"},[_c('span',[_vm._v(_vm._s(option.name))])])]}}],null,false,816142725),model:{value:(_vm.CEFR),callback:function ($$v) {_vm.CEFR=$$v},expression:"CEFR"}},[_vm._v(" "),_c('template',{slot:"selected-option"},[_c('div',{staticClass:"selected d-center"},[_vm._v("\n                    "+_vm._s(_vm.CEFR.name)+"\n                  ")])])],2):(_vm.lessonInfo.kls_europe_level_name !== null)?_c('span',{staticClass:"table-cell-name"},[_vm._v(_vm._s(_vm.lessonInfo.kls_europe_level_name))]):(_vm.lessonInfo.kls_europe_level_name === null)?_c('span',{staticClass:"table-cell-name"},[_vm._v("\n                no\n              ")]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"table-cell"},[_c('span',{staticClass:"table-cell-label"},[_vm._v("Percentage of knowledge")]),_vm._v(" "),(
                  _vm.editLessonCardMode &&
                  (_vm.$cookie.get('internalName') === 'manager' ||
                    _vm.$cookie.get('internalName') === 'admin' ||
                    (_vm.$cookie.get('internalName') === 'teacher' &&
                      (_vm.lessonTypeCode === 'CHECK_PROGRESS' ||
                        _vm.lessonTypeCode === 'TEACHER_TEST_PROGRESS_TEST_LESSON')))
                )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.percentageOfKnowledge),expression:"percentageOfKnowledge"}],attrs:{"type":"text"},domProps:{"value":(_vm.percentageOfKnowledge)},on:{"input":function($event){if($event.target.composing){ return; }_vm.percentageOfKnowledge=$event.target.value}}}):(_vm.lessonInfo.percentage_of_knowledge !== null)?_c('span',{staticClass:"table-cell-name"},[_vm._v(_vm._s(_vm.lessonInfo.percentage_of_knowledge))]):(_vm.lessonInfo.percentage_of_knowledge === null)?_c('span',{staticClass:"table-cell-name"},[_vm._v("\n                no\n              ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"table-cell"},[_c('span',{staticClass:"table-cell-label"},[_vm._v("Revision")]),_vm._v(" "),(_vm.lessonInfo.revision !== null)?_c('span',{staticClass:"table-cell-name"},[_vm._v(_vm._s(JSON.parse(_vm.lessonInfo.revision).join(', ')))]):(_vm.lessonInfo.revision === null)?_c('span',{staticClass:"table-cell-name"},[_vm._v(" no ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"table-cell"},[_c('span',{staticClass:"table-cell-label"},[_vm._v("New pages")]),_vm._v(" "),_c('span',{staticClass:"table-cell-name"},[_vm._v(_vm._s(_vm.newPages || 'no'))])]),_vm._v(" "),_c('div',{staticClass:"table-cell"},[_c('span',{staticClass:"table-cell-label"},[_vm._v("Last taught (new) word")]),_vm._v(" "),(
                  _vm.editLessonCardMode &&
                  (_vm.$cookie.get('internalName') === 'manager' ||
                    _vm.$cookie.get('internalName') === 'admin')
                )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.lastWord),expression:"lastWord"}],attrs:{"type":"text"},domProps:{"value":(_vm.lastWord)},on:{"input":function($event){if($event.target.composing){ return; }_vm.lastWord=$event.target.value}}}):(_vm.lessonInfo.last_taught_word !== null)?_c('span',{staticClass:"table-cell-name"},[_vm._v(_vm._s(_vm.lessonInfo.last_taught_word))]):(_vm.lessonInfo.last_taught_word === null)?_c('span',{staticClass:"table-cell-name"},[_vm._v("\n                no\n              ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"table-cell"},[_c('span',{staticClass:"table-cell-label"},[_vm._v("Listening")]),_vm._v(" "),(
                  _vm.editLessonCardMode &&
                  (_vm.$cookie.get('internalName') === 'manager' ||
                    _vm.$cookie.get('internalName') === 'admin')
                )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.listening),expression:"listening"}],attrs:{"type":"text"},domProps:{"value":(_vm.listening)},on:{"input":function($event){if($event.target.composing){ return; }_vm.listening=$event.target.value}}}):(_vm.lessonInfo.listening !== null)?_c('span',{staticClass:"table-cell-name"},[_vm._v(_vm._s(_vm.lessonInfo.listening))]):(_vm.lessonInfo.listening === null)?_c('span',{staticClass:"table-cell-name"},[_vm._v(" no ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"table-cell"},[_c('span',{staticClass:"table-cell-label"},[_vm._v("Writing")]),_vm._v(" "),(
                  _vm.editLessonCardMode &&
                  (_vm.$cookie.get('internalName') === 'manager' ||
                    _vm.$cookie.get('internalName') === 'admin')
                )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.writing),expression:"writing"}],attrs:{"type":"text"},domProps:{"value":(_vm.writing)},on:{"input":function($event){if($event.target.composing){ return; }_vm.writing=$event.target.value}}}):(_vm.lessonInfo.writing !== null)?_c('span',{staticClass:"table-cell-name"},[_vm._v(_vm._s(_vm.lessonInfo.writing))]):(_vm.lessonInfo.writing === null)?_c('span',{staticClass:"table-cell-name"},[_vm._v(" no ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"table-cell"},[_c('span',{staticClass:"table-cell-label"},[_vm._v("Homework")]),_vm._v(" "),(_vm.editLessonCardMode)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.homework),expression:"homework"}],attrs:{"type":"text"},domProps:{"value":(_vm.homework)},on:{"input":function($event){if($event.target.composing){ return; }_vm.homework=$event.target.value}}}):(_vm.lessonInfo.homework !== null)?_c('span',{staticClass:"table-cell-name"},[_vm._v(_vm._s(_vm.lessonInfo.homework))]):(_vm.lessonInfo.homework === null)?_c('span',{staticClass:"table-cell-name"},[_vm._v(" no ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"table-cell"},[_c('span',{staticClass:"table-cell-label"},[_vm._v("Link to record")]),_vm._v(" "),(
                  _vm.editLessonCardMode &&
                  (_vm.$cookie.get('internalName') === 'manager' ||
                    _vm.$cookie.get('internalName') === 'admin')
                )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.link),expression:"link"}],attrs:{"type":"text"},domProps:{"value":(_vm.link)},on:{"input":function($event){if($event.target.composing){ return; }_vm.link=$event.target.value}}}):(_vm.lessonInfo.link_to_record !== null)?_c('span',{staticClass:"table-cell-name"},[_c('a',{attrs:{"href":_vm.lessonInfo.link_to_record.indexOf('http') !== -1
                      ? _vm.lessonInfo.link_to_record
                      : 'http://' + _vm.lessonInfo.link_to_record}},[_vm._v("\n                  "+_vm._s(_vm.lessonInfo.link_to_record))])]):(_vm.lessonInfo.link_to_record === null)?_c('span',{staticClass:"table-cell-name"},[_vm._v("\n                no\n              ")]):_vm._e()])]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.$cookie.get('internalName') === 'student')?_c('div',{staticClass:"student-card-info-container"},[_c('div',{staticClass:"student-lesson-info-container"},[_c('div',{staticClass:"table-cell"},[_c('span',{staticClass:"table-cell-label"},[_vm._v("Lesson number")]),_vm._v(" "),_c('span',{staticClass:"table-cell-name"},[_vm._v(_vm._s(_vm.lessonInfo.lesson_number || 'no'))])]),_vm._v(" "),_c('div',{staticClass:"table-cell"},[_c('span',{staticClass:"table-cell-label"},[_vm._v("New words")]),_vm._v(" "),(_vm.lessonInfo.new_words !== null)?_c('span',{staticClass:"table-cell-name"},[_vm._v(_vm._s(_vm.lessonInfo.new_words))]):_vm._e(),_vm._v(" "),(_vm.lessonInfo.new_words === null)?_c('span',{staticClass:"table-cell-name"},[_vm._v(" no ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"table-cell"},[_c('span',{staticClass:"table-cell-label"},[_vm._v("Homework")]),_vm._v(" "),(_vm.lessonInfo.homework !== null)?_c('span',{staticClass:"table-cell-name"},[_vm._v(_vm._s(_vm.lessonInfo.homework))]):_vm._e(),_vm._v(" "),(_vm.lessonInfo.homework === null)?_c('span',{staticClass:"table-cell-name"},[_vm._v(" no ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"table-cell"},[_c('span',{staticClass:"table-cell-label"},[_vm._v("Student comment")]),_vm._v(" "),(_vm.lessonInfo.comment !== null)?_c('span',{staticClass:"table-cell-name"},[_vm._v(_vm._s(_vm.lessonInfo.comment))]):_vm._e(),_vm._v(" "),(_vm.lessonInfo.comment === null)?_c('span',{staticClass:"table-cell-name"},[_vm._v(" no ")]):_vm._e()])]),_vm._v(" "),(
              _vm.canClickButtonPush &&
              _vm.lessonInfo.kls_status_code === 'LESSON_PLANNED' &&
              !_vm.lessonInfo.is_teacher_skip &&
              !_vm.lessonInfo.in_arbitration
            )?_c('div',{staticClass:"btn-container"},[_c('button',{on:{"click":_vm.skipLessonByTeacher}},[_vm._v("Преподаватель не вышел на связь")])]):_vm._e()]):_vm._e()]):_vm._e(),_vm._v(" "),(
          (_vm.$cookie.get('internalName') === 'student' ||
            _vm.$cookie.get('internalName') === 'manager' ||
            _vm.$cookie.get('internalName') === 'admin') &&
          _vm.studentComplaint &&
          !_vm.glossarySelected
        )?_c('div',{staticClass:"student-complaint"},[_c('div',{staticClass:"complaint-header"},[_c('span',[_vm._v("Жалоба")])]),_vm._v(" "),_c('div',{staticClass:"complaint-content-container"},[_c('div',{staticClass:"complaint-info"},[_c('span',{staticClass:"sender-name"},[_vm._v(_vm._s(_vm.studentComplaint.author_full_name))]),_vm._v(" "),_c('span',{staticClass:"complaint-text"},[_vm._v(_vm._s(_vm.studentComplaint.reason))])])])]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.userRole === 'admin' || _vm.userRole === 'manager')?_c('div',{staticClass:"go-to-lesson-btn"},[_c('button',{on:{"click":_vm.goToLessonCard}},[_vm._v("Перейти к карточке")])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }