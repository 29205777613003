<template>
  <div class="user-control">
    <div class="user-control__card">
      <UserCard :user="userData" @update="getUserData" :userStage="userStage" />
    </div>
    <div class="user-control__blocks" v-if="userData.role_id !== 5 && userData.role_id !== 1">
      <div class="user-control__blocks-rest">
        <NonWorkingDays
          :days="userRest"
          :uuid="userData.sync_uuid"
          @updateRest="getUserRest"
          type="manager"
          :time-zone="userTimeZone"
        />
      </div>
      <div class="user-control__blocks-achievements" v-if="userData.role_id === 4">
        <Achievements
          @update="getUserAchievements"
          :files="userFiles"
          :count="userFilesCount"
          :uuid="userData.sync_uuid"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UserCard from '@/views/UserControl/components/UserCard'
import axios from 'axios'
import { domain, MicroServices } from '@/globalVariables'
import NonWorkingDays from '@/views/Profile/components/NonWorkingDays'
import Achievements from '@/views/Profile/components/Achievements'
import timeZoneList from '../../assets/timeZone'
export default {
  name: 'UserControl',
  components: { Achievements, NonWorkingDays, UserCard },
  data() {
    return {
      userData: null,
      userRest: null,
      userFiles: null,
      userFilesCount: null,
      userStage: null,
      timeZoneList
    }
  },
  mounted() {
    this.getUserData()
  },
  computed: {
    userTimeZone() {
      return this.timeZoneList.find((item) => item.value.toString() === this.userData.time_zone)
        .name
    }
  },
  methods: {
    /**
     * Получить стадию пользователя
     */
    getUserStage() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/${
          this.userData.role_id === 4 ? 'Teacher' : 'Student'
        }/getCurrentStage`,
        method: 'POST',
        data: [
          {
            user_uuid: this.userData.sync_uuid
          }
        ]
      })
        .then((response) => {
          this.userStage = response.data.data.name
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Получить личную информацию пользователя
     */
    getUserData() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/UserControl/getItem/${this.$route.params.user_id}`,
        method: 'POST'
      })
        .then((response) => {
          this.userData = response.data.data
          if (this.userData.role_id !== 5 && this.userData.role_id !== 1) {
            this.getUserRest()
            this.getUserAchievements()
          }
          if (this.userData.role_id === 5 || this.userData.role_id === 4) {
            this.getUserStage()
          }
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Получить нерабочие дни пользователя
     */
    getUserRest() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Rest/getItems`,
        method: 'GET',
        params: {
          user_uuid: this.userData.sync_uuid
        }
      })
        .then((response) => {
          this.userRest = response.data.data.items
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Получить личные достижения пользователя
     */
    getUserAchievements() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/AchievementFile/getItems/`,
        method: 'GET',
        params: {
          user_uuid: this.userData.sync_uuid,
          _count: 20
        }
      })
        .then((response) => {
          this.userFiles = response.data.data.items
          this.userFilesCount = response.data.data.total_count
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../styleVariables';

.user-control {
  color: $main-dark;
  padding: 24px;
  display: flex;
  &__card {
    margin-right: 24px;
  }
  &__blocks {
    width: 100%;
    &-rest {
      margin-bottom: 24px;
    }
  }
}
</style>
