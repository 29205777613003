<template>
  <div class="layout-wrapper layout-1">
    <div class="layout-inner">
      <app-layout-navbar />

      <div class="layout-container">
        <app-layout-sidenav />

        <div class="layout-content">
          <div class="router-transitions container-fluid flex-grow-1 container-p-y">
            <router-view />
          </div>

          <!--          <app-layout-footer />-->
        </div>
      </div>
    </div>
    <div class="layout-overlay" @click="closeSidenav"></div>
  </div>
</template>

<script>
import LayoutNavbar from './LayoutNavbar'
import LayoutSidenav from './LayoutSidenav'
import LayoutFooter from './LayoutFooter'

export default {
  name: 'app-layout-1',
  components: {
    'app-layout-navbar': LayoutNavbar,
    'app-layout-sidenav': LayoutSidenav,
    'app-layout-footer': LayoutFooter
  },

  mounted() {
    this.layoutHelpers.init()
    this.layoutHelpers.update()
    this.layoutHelpers.setAutoUpdate(true)
  },

  beforeDestroy() {
    this.layoutHelpers.destroy()
  },

  methods: {
    closeSidenav() {
      this.layoutHelpers.setCollapsed(true)
    }
  }
}
</script>
<style lang="scss">
@import '../styleVariables';
.router-transitions.container-fluid.flex-grow-1.container-p-y {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  width: calc(100% - 248px);
  margin-right: 0;
  margin-left: auto;
  background-color: $background-blue;
}
.container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
}

@media screen and (max-width: 991px) {
  .router-transitions.container-fluid.flex-grow-1.container-p-y {
    margin-left: 0;
    width: unset;
  }
}
</style>
