<template>
  <div class="hours">
    <div class="hour" v-for="hour in sortedHours" :key="hour">
      {{ formatTime(hour) }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SortHours',
  props: {
    hours: Array
  },
  computed: {
    sortedHours() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.hours.sort(this.compareHours)
    }
  },
  methods: {
    formatTime(hour) {
      return `${hour < 10 ? '0' + hour : hour}:00 - ${
        hour + 1 < 10 ? '0' + (hour + 1) : hour + 1
      }:00`
    },
    compareHours(a, b) {
      if (Number(a) < Number(b)) {
        return -1
      }
      if (Number(a) > Number(b)) {
        return 1
      }
      return 0
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../../../styleVariables';
.hours {
  .hour {
    font-family: $font-regular;
  }
}
</style>
