<template>
  <div class="gantt-container">
    <div class="gantt-header">Уровень успеваемости</div>
    <div class="gantt-container__content" v-if="userStatistic">
      <div class="gantt-row" v-for="level in levelList" :key="level.id">
        <div class="gantt-row__level">{{ level.name }}</div>
        <div
          class="gantt-row__value"
          v-for="(date, index) in level.date"
          :key="index"
          :style="rowStyles(level, index)"
        ></div>
      </div>
    </div>
    <div class="gantt-container__dates" v-if="userStatistic">
      <div class="gantt-date" v-for="(date, index) in dateList" :key="index">
        <div class="gantt-date__item">{{ date }}</div>
      </div>
    </div>
    <div class="gantt-container__empty" v-else>Уровень не определен</div>
  </div>
</template>

<script>
import { domain, MicroServices } from '@/globalVariables'
import axios from 'axios'

export default {
  name: 'Gantt',
  data() {
    return {
      levelList: null,
      userStatistic: null,
      dateList: null,
      dateUnit: 204,
      colors: ['#3264C3', '#FFD500', '#1DBE40', '#E13429']
    }
  },
  mounted() {
    this.getStatistic()
  },
  methods: {
    /**
     * Получить все возможные уровни
     */
    getLevels() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Classifier/getEducationLevels`,
        method: 'GET',
        params: {
          _count: 20
        }
      })
        .then((response) => {
          this.levelList = response.data.data.items.reverse()
          this.pushDateToLevels()
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Получить статистику по текущему пользователя (студент)
     */
    getStatistic() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/StudentPerformanceStatistic/getItems`,
        method: 'GET',
        params: {
          student_uuid: this.$cookie.get('uid')
        }
      })
        .then((response) => {
          this.userStatistic = response.data.data
          this.dateList = this.userStatistic.map((item) => item.date)
          this.getLevels()
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Получение значения для атрибута left
     * @param dateIndex
     * @param levelId
     * @returns {number}
     */
    getRowPosition(dateIndex, levelId) {
      let leftSum = 0
      let index = this.userStatistic.findIndex((item) => item.kls_level_id === levelId)
      for (let i = 0; i < index; i++) {
        leftSum += this.dateUnit * (dateIndex + 1)
      }
      return leftSum
    },

    /**
     * Сопоставить даты и уровни
     */
    pushDateToLevels() {
      this.levelList.splice(
        this.levelList.findIndex((level) => level.code === 'WITHOUT_LEVEL'),
        1
      )

      this.levelList.forEach((item) => {
        if (this.userStatistic.filter((date) => date.kls_level_id === item.id).length) {
          item.date = this.userStatistic.filter((date) => date.kls_level_id === item.id)
        }
      })
    },

    /**
     * Получить стили для полосок
     * @param data
     * @param dateIndex
     * @returns {string|null}
     */
    rowStyles(data, dateIndex) {
      if (!data.date) {
        return null
      }
      let levelIndex = this.levelList
        .filter((item) => item.date)
        .findIndex((item) => item.id === data.id)
      let colorIndex = levelIndex % this.colors.length
      let left = this.getRowPosition(dateIndex, data.id)
      return `background-color: ${this.colors[colorIndex]}; width: ${this.dateUnit}px; margin-left: ${left}px`
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../styleVariables';

.gantt-container {
  background-color: $main-white;
  box-shadow: 1px 0px 20px -7px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  padding: 14px 8px 14px 0;
  color: $main-dark;
  overflow-x: auto;

  .gantt-header {
    font-size: 18px;
    line-height: 33px;
    padding: 0 16px;
    margin-bottom: 12px;
    position: sticky;
    left: 0;
    top: 0;
  }

  &__content {
    border-bottom: 1px solid #f2f5ff;
    border-collapse: separate;
    width: fit-content;
    min-width: 100%;
    .gantt-row {
      display: flex;
      align-items: center;
      &__level {
        width: fit-content;
        font-size: 13px;
        line-height: 24px;
        color: $main-gray;
        font-family: $font-regular;
        padding: 5px 8px 5px 14px;
        border-right: 1px solid #f2f5ff;
        position: sticky;
        left: 0;
        z-index: 3;
        background-color: $main-white;
      }
      &__value {
        height: 20px;
        border-radius: 12px;
        z-index: 2;
      }
    }
  }
  &__dates {
    display: flex;
    padding-right: 12px;
    padding-left: 6px;
    width: fit-content;
    .gantt-date {
      margin-right: 144px;
      &__item {
        font-size: 12px;
        line-height: 24px;
        color: $main-gray;
        font-family: $font-regular;
        white-space: nowrap;
        width: 60px;
        margin-top: -560px;

        &::before {
          content: '';
          border: 1px solid #f2f5ff;
          position: relative;
          left: 30px;
          top: -20px;
          display: inline-block;
          height: 580px;
        }
      }
    }
  }

  &__empty {
    width: 100%;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: $light-gray;
  }
}
</style>
