<template>
  <div class="modal-background" @mousedown.self="hide">
    <div class="modal-container">
      <div class="header">
        <span>{{ packInfo ? 'Редактирование пакета' : 'Создание нового пакета' }}</span>
      </div>
      <div class="content">
        <div class="content-item">
          <div class="label">Количество занятий в пакете</div>
          <input
            :class="{ 'error-border': !validAmount }"
            @input="calculateInputs('amount')"
            type="text"
            v-model="amount"
          />
        </div>
        <div class="content-item">
          <div class="label">Стоимость пакета</div>
          <input
            :class="{ 'error-border': !validPrice }"
            @input="calculateInputs('packPrice')"
            v-model="packPrice"
          />
        </div>
        <div class="content-item">
          <div class="label">Скидка на пакет</div>
          <input
            @input="calculateInputs('discount')"
            type="text"
            :class="{ 'error-border': !validDiscount }"
            v-model="discount"
          />
        </div>
        <div class="content-item">
          <div class="label disabled">Экономия</div>
          <input disabled type="text" v-model="benefit" />
        </div>
      </div>
      <div class="controls-btns">
        <button class="hide-modal-btn" @click="hide">Отменить</button>
        <button
          class="send-complaint-btn"
          :disabled="!(validPrice && validAmount && packPrice && amount && validDiscount)"
          @click="savePack()"
        >
          Сохранить
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalWindow',
  props: {
    packInfo: Object,
    basicPrice: String
  },
  data() {
    return {
      amount: null,
      discount: null,
      packPrice: null,
      benefit: null,
      validPrice: true,
      validAmount: true,
      validDiscount: true
    }
  },
  mounted() {
    if (this.packInfo) {
      this.setInitialState()
    }
  },
  methods: {
    hide() {
      this.$emit('hide')
    },

    /**
     * Установить начальные значения для редактирования
     */
    setInitialState() {
      this.amount = this.packInfo.lessons_amount
      this.discount = this.packInfo.discount
      this.packPrice = this.packInfo.price
      this.benefit = this.packInfo.benefit
    },

    /**
     * Сохранить пакет
     */
    savePack() {
      this.packInfo
        ? this.$emit('save', this.amount, this.packPrice, this.packInfo.id)
        : this.$emit('save', this.amount, this.packPrice)
    },

    /**
     * Менять запятую на точку при вооде и убирать все символы, кроме цифр
     * @param word
     * @returns {*}
     */
    cutLetterSymbols(word) {
      word = word.replace(',', '.')
      return word
        .replace(/[^0-9.]/g, '')
        .replace('.', 'x')
        .replace(/\./g, '')
        .replace('x', '.')
    },

    /**
     * Подсчет скидки и суммы пакета при вводе
     * @param type
     * @returns {null}
     */
    calculateInputs(type) {
      if (this.discount && type === 'discount') {
        this.discount = this.cutLetterSymbols(this.discount)
        this.validDiscount =
          this.discount.toString().match(/^[\d.]*$/) &&
          Number(this.discount) >= 0 &&
          Number(this.discount) <= 100
      }
      if (this.packPrice && type === 'packPrice') {
        this.packPrice = this.cutLetterSymbols(this.packPrice)
        this.validPrice = this.packPrice.toString().match(/^[\d.]*$/)
      }
      if (this.amount && type === 'amount') {
        this.amount = this.cutLetterSymbols(this.amount).replace('.', '')
      }
      if (this.amount && (this.discount || this.packPrice)) {
        this.validAmount = this.amount.toString().match(/^[\d]*$/)
        if ((type === 'discount' || type === 'amount') && this.discount) {
          this.packPrice =
            ((100 - Number(this.discount)) * Number(this.amount) * Number(this.basicPrice)) / 100
          this.benefit = Number(this.amount) * Number(this.basicPrice) - Number(this.packPrice)
          return null
        }
        if ((type === 'packPrice' || type === 'amount') && this.packPrice) {
          this.discount =
            100 - (Number(this.packPrice) * 100) / (Number(this.amount) * Number(this.basicPrice))
          this.benefit = Number(this.amount) * Number(this.basicPrice) - Number(this.packPrice)
          return null
        }
      }
      this.benefit = null
    }
  }
}
</script>

<style scoped lang="scss">
@import './src/styleVariables';
.modal-background {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.3);
  animation: fade-in 0.3s;
  .modal-container {
    background-color: $main-white;
    border-radius: 8px;
    padding: 14px 24px;
    width: 503px;
    //height: 313px;
    display: flex;
    flex-direction: column;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 18px;
      span {
        font-size: 18px;
      }
    }
    .content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 16px;
      grid-column-gap: 30px;
      margin-bottom: 30px;
      &-item {
        .label {
          color: $main-dark;
          font-size: 16px;
          line-height: 20px;
          font-family: $font-regular;
          margin-bottom: 7px;
        }
        .disabled {
          color: $light-gray !important;
        }
        .error-border {
          border-color: $red !important;
        }
        input {
          width: 100%;
          background: $main-white;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
          border-radius: 4px;
          border: 0.5px solid #e8edf3;
          color: $main-dark;
          font-family: $font-regular;
          outline: none;
          padding: 7px 6px;
          height: 41px;
          font-size: 14px;
          line-height: 16px;
        }
        input::placeholder {
          color: $light-gray;
          font-family: $font-bold;
          font-size: 14px;
          line-height: 16px;
        }
        input:focus {
          border: 1.5px solid $bright-blue;
        }
      }
    }
    .controls-btns {
      display: flex;
      justify-content: flex-end;
      button {
        width: 137px;
      }
      .send-complaint-btn {
        margin-left: 15px;
        border: none;
        background-color: $bright-blue;
        border-radius: 4px;
        padding: 5px 21px;
        color: $main-white;
        outline: none;
        &:disabled {
          background-color: $light-gray;
        }
      }
      .hide-modal-btn {
        border: 1px solid $bright-blue;
        border-radius: 4px;
        padding: 5px 21px;
        color: $bright-blue;
        outline: none;
      }
    }
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
