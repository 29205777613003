export default {
  methods: {
    Change__Email(data) {
      this.$store.commit('newRec', data)
    }
  },
  data() {
    return {
      inputEmail: {
        value: this.value,
        text: this.text,
        disabled: this.disabled,
        placeholder: this.placeholder,
        tooltip: this.tooltip,
        keyAdd: this.keyAdd
      }
    }
  }
}
