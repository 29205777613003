<template>
  <div class="notification-filter">
    <div class="notification-filter__controls">
      <div class="mark-all">
        <input
          type="checkbox"
          id="all"
          class="custom-checkbox"
          v-model="markAll"
          @change="$emit('markAll', markAll)"
        />
        <label for="all">Выбрать все</label>
      </div>
      <div
        class="read"
        :style="markSome ? 'cursor: pointer' : ''"
        @click="readItems()"
        v-b-tooltip="'Пометить как прочитано'"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.6667 18.3333H3.33333C2.41286 18.3333 1.66666 17.5871 1.66666 16.6667V7.54084C1.69479 6.98156 1.99773 6.4724 2.47583 6.18084L9.1425 2.18084C9.6701 1.86448 10.3291 1.86448 10.8567 2.18084L17.5233 6.18084C18.0243 6.48315 18.3314 7.02488 18.3333 7.61001V16.6667C18.3333 17.5871 17.5871 18.3333 16.6667 18.3333ZM3.33333 8.22334V16.6667H16.6667V8.22334L10 12.6675L3.33333 8.22334ZM10 3.61001L4.43 6.95167L10 10.665L15.5692 6.95167L10 3.61001Z"
            :fill="markSome ? '#2E3A59' : '#C5CEE0'"
          />
        </svg>
      </div>
      <div
        class="trash"
        :style="markSome ? 'cursor: pointer' : ''"
        @click="deleteItems()"
        v-b-tooltip="'Удалить'"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.1667 18.3333H5.83333C4.91286 18.3333 4.16667 17.5871 4.16667 16.6667V5.83332H2.5V4.16666H5.83333V3.33332C5.83333 2.41285 6.57953 1.66666 7.5 1.66666H12.5C13.4205 1.66666 14.1667 2.41285 14.1667 3.33332V4.16666H17.5V5.83332H15.8333V16.6667C15.8333 17.5871 15.0871 18.3333 14.1667 18.3333ZM5.83333 5.83332V16.6667H14.1667V5.83332H5.83333ZM7.5 3.33332V4.16666H12.5V3.33332H7.5ZM12.5 15H10.8333V7.49999H12.5V15ZM9.16667 15H7.5V7.49999H9.16667V15Z"
            :fill="markSome ? '#2E3A59' : '#C5CEE0'"
          />
        </svg>
      </div>
    </div>
    <div
      class="notification-filter__type"
      @click="showTypes = !showTypes"
      v-if="$cookie.get('internalName') === 'manager' || $cookie.get('internalName') === 'admin'"
    >
      <span>Тип оповещений</span>
      <img
        src="../../../assets/img/ES/chevron_down.svg"
        alt="open"
        :style="showTypes ? 'transform: rotate(180deg)' : ''"
      />
      <div class="notification-filter__types-select" v-show="showTypes">
        <div class="types-select__item" v-for="type in types" :key="type.id">
          <input type="checkbox" :id="type.id" @input="filter(type.id)" class="custom-checkbox" />
          <label :for="type.id">{{ type.name }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { domain, MicroServices } from '@/globalVariables'

export default {
  name: 'NotificationFilter',
  props: {
    markAll: Boolean,
    markSome: Number
  },
  data() {
    return {
      showTypes: false,
      filters: [],
      types: [],
      filterChanged: true
    }
  },
  mounted() {
    this.getTypes()
  },
  methods: {
    /**
     * Формирование фильтров для оповещений
     */
    filter(value) {
      if (!this.filters.filter((item) => item === value).length) {
        this.filters.push(value)
      } else {
        this.filters.splice(this.filters.indexOf(value), 1)
      }
      this.$emit('filter', this.filters, this.filterChanged)
    },

    /**
     * Емит на установление оповещений как прочитано
     */
    readItems() {
      if (this.markSome) {
        this.$emit('read')
      }
    },

    /**
     * Емит на удаление оповещений
     */
    deleteItems() {
      if (this.markSome) {
        this.$emit('delete')
      }
    },

    /**
     * Получить типы оповещений для фильтра
     */
    getTypes() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Classifier/getNotificationTypes`,
        method: 'GET',
        params: {
          for_admin: this.$cookie.get('internalName') === 'admin'
        }
      })
        .then((response) => {
          this.types = response.data.data.items
        })
        .catch((error) => this.errorAlert(error))
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../styleVariables';
.notification-filter {
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  &__controls {
    display: flex;
    .mark-all {
      display: flex;
      align-items: center;
      margin-right: 28px;
      cursor: pointer;
      label {
        width: max-content;
        color: $main-dark;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 0;
      }
      .custom-checkbox {
        position: absolute;
        z-index: -1;
        opacity: 0;
      }
      .custom-checkbox + label {
        display: inline-flex;
        align-items: center;
        user-select: none;
        cursor: pointer;
      }
      .custom-checkbox + label::before {
        content: '';
        display: inline-block;
        width: 17px;
        height: 17px;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1px solid $light-gray;
        border-radius: 0.25em;
        margin-right: 10px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;
      }
      .custom-checkbox:checked + label::before {
        border-color: $light-gray;
        background-color: $main-white;
        background-image: url('../../../assets/img/ES/Checkmark.svg');
        background-size: 11px;
      }
    }
    .read {
      margin-right: 17px;
    }
  }
  &__type {
    display: flex;
    align-items: center;
    cursor: pointer;
    span {
      width: max-content;
      color: $main-gray;
      font-size: 14px;
      line-height: 18px;
      margin-right: 6px;
    }
    img {
      transition: 0.2s;
    }
  }
  &__types-select {
    background-color: $main-white;
    border-radius: 4px;
    box-shadow: 1px 0px 20px -7px rgba(0, 0, 0, 0.16);
    padding: 17px 12px 8px 12px;
    position: absolute;
    margin-top: 168px;
    .custom-checkbox {
      position: absolute;
      z-index: -1;
      opacity: 0;
    }
    .custom-checkbox + label {
      display: inline-flex;
      align-items: center;
      user-select: none;
      cursor: pointer;
    }
    .custom-checkbox + label::before {
      content: '';
      display: inline-block;
      width: 17px;
      height: 17px;
      flex-shrink: 0;
      flex-grow: 0;
      border: 1px solid $light-gray;
      border-radius: 0.25em;
      margin-right: 10px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 50% 50%;
    }
    .custom-checkbox:checked + label::before {
      border-color: $light-gray;
      background-color: $main-white;
      background-image: url('../../../assets/img/ES/Checkmark.svg');
      background-size: 11px;
    }
  }
}
</style>
