<template>
  <div class="admin-profile">
    <div class="left-side">
      <div class="personal-card">
        <PersonalCard @update="getAdminInfo" :info="profileInfo" type="admin" />
      </div>
      <div class="statistic">
        <AdminStatistic />
      </div>
    </div>
    <div class="right-side">
      <Statistic />
    </div>
  </div>
</template>

<script>
import Statistic from '@/views/Profile/components/Statistic/Statistic'
import AdminStatistic from '@/views/Profile/components/Statistic/AdminStatistic'
import PersonalCard from '@/views/Profile/components/PersonalCard'
import axios from 'axios'
import { domain } from '@/globalVariables'
export default {
  name: 'AdminProfile',
  components: { PersonalCard, Statistic, AdminStatistic },
  data() {
    return {
      profileInfo: []
    }
  },
  mounted() {
    this.getAdminInfo()
  },
  methods: {
    /**
     * Получение личной информации текущего пользователя (админ)
     */
    getAdminInfo() {
      axios({
        url: `${domain}/monolit/Admin/getItem/${this.$cookie.get('id')}`,
        method: 'GET'
      })
        .then((response) => {
          this.profileInfo = response.data.data
          this.$root.$emit('updateName', response.data.data.full_name)
        })
        .catch((error) => this.errorAlert(error))
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../styleVariables';
.admin-profile {
  display: flex;
  justify-content: space-between;
  padding: 30px 24px;
  background-color: $background-blue;
  .right-side {
    max-width: 844px;
    width: 100%;
  }
  .left-side {
    max-width: 740px;
    width: 100%;
    margin-right: 18px;
    .personal-card {
      margin-bottom: 30px;
    }
    .non-working-days {
      margin-bottom: 30px;
    }
  }
}
</style>
