<template>
  <div class="dialogue-container" :class="{ 'dialogue-container__chosen': chosen }">
    <div class="has-unread">
      <div class="unread" v-show="dialogue.has_unread_messages" />
    </div>
    <div class="dialogue" :style="border ? 'border-color: transparent' : ''">
      <div class="dialogue-header">
        <div class="user">{{ dialogue.title }}</div>
      </div>
      <div class="dialogue-date">
        <div class="date">{{ formatDate }}</div>
        <div class="time">{{ formatTime }}</div>
      </div>
      <div class="dialogue-content">{{ dialogue.last_message_content || '' }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dialogue',
  props: {
    dialogue: Object,
    border: Boolean,
    chosen: Boolean
  },
  computed: {
    formatDate() {
      if (this.dialogue.last_message_date) {
        return `${
          new Date(this.dialogue.last_message_date).getDate() < 10
            ? '0' + new Date(this.dialogue.last_message_date).getDate()
            : new Date(this.dialogue.last_message_date).getDate()
        }.${
          new Date(this.dialogue.last_message_date).getMonth() < 9
            ? '0' + (new Date(this.dialogue.last_message_date).getMonth() + 1)
            : new Date(this.dialogue.last_message_date).getMonth() + 1
        }.${new Date(this.dialogue.last_message_date).getFullYear()}`
      } else {
        return ''
      }
    },
    formatTime() {
      if (this.dialogue.last_message_date) {
        return `${
          new Date(this.dialogue.last_message_date).getHours() < 10
            ? '0' + new Date(this.dialogue.last_message_date).getHours()
            : new Date(this.dialogue.last_message_date).getHours()
        }:${
          new Date(this.dialogue.last_message_date).getMinutes() < 10
            ? '0' + new Date(this.dialogue.last_message_date).getMinutes()
            : new Date(this.dialogue.last_message_date).getMinutes()
        }`
      } else {
        return ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../../styleVariables';
.dialogue-container {
  display: flex;
  padding: 0 24px 0 11px;
  background-color: $main-white;
  &:hover {
    background-color: $light-table-blue;
  }
  &__chosen {
    background-color: $light-table-blue;
  }
  .has-unread {
    width: 6px;
    height: 6px;
    margin-right: 9px;
    margin-top: 19px;
    .unread {
      width: 6px;
      height: 6px;
      border-radius: 50px;
      background-color: $red;
    }
  }
  .dialogue {
    border-bottom: 1px solid #dee6f5;
    padding: 12px 0 12px 0;
    max-width: 100%;
    width: 100%;
    &-header {
      color: $main-dark;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 2px;
    }
    &-date {
      display: flex;
      color: $main-gray;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 6px;
      .date,
      .time {
        font-family: $font-regular;
      }
      .date {
        margin-right: 11px;
      }
    }
    &-content {
      font-family: $font-regular;
      font-size: 14px;
      line-height: 18px;
      color: $main-dark;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
