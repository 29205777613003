import Vue from 'vue'
import CheckboxEvents from './CheckBox/CheckBoxEvents'
import SelectEvents from './Select/SelectEvents'
import ButtonEvents from './Button/ButtonEvents'
import InputTextEvents from './InputText/InputTextEvents'
import InputTimeEvents from './InputTime/InputTime'
import InputDateEvents from './InputDate/InputDateEvents'
import InputDateTimeLocalEvents from '@/components/Widgets/InputDateTimeLocal/InputDateTimeLocalEvents'
import TextareaEvents from './Textarea/TextareaEvents'
import InputEmailEvents from './InputEmail/InputEmailEvents'
import InputPasswordEvents from './InputPassword/InputPasswordEvents'
import InputNumberEvents from './InputNumber/InputNumberEvents'
import InputPhoneEvents from './InputPhone/InputPhoneEvents'
import InputFileEvents from './InputFile/InputFileEvents'
import TreeSelectEvents from './TreeSelect/TreeSelectEvents'
import ProgressBarEvents from './ProgressBar/ProgressBarEvents'
import FileUploaderEvents from '@/components/Widgets/FileUploader/FileUploaderEvents'
import ToggleButtonEvents from '@/components/Widgets/ToggleButton/ToggleButtonEvents'
import InputRadioEvents from '@/components/Widgets/InputRadio/InputRadioEvents'
import DateRangePickerEvents from '@/components/Widgets/DateRangePicker/DateRangePickerEvents'
import MarkDownEvents from '@/components/Widgets/MarkDown/MarkDownEvents'

Vue.mixin(CheckboxEvents)
Vue.mixin(SelectEvents)
Vue.mixin(ButtonEvents)
Vue.mixin(InputTextEvents)
Vue.mixin(InputDateEvents)
Vue.mixin(InputDateTimeLocalEvents)
Vue.mixin(TextareaEvents)
Vue.mixin(InputEmailEvents)
Vue.mixin(InputPasswordEvents)
Vue.mixin(InputNumberEvents)
Vue.mixin(InputPhoneEvents)
Vue.mixin(InputFileEvents)
Vue.mixin(TreeSelectEvents)
Vue.mixin(ProgressBarEvents)
Vue.mixin(FileUploaderEvents)
Vue.mixin(InputTimeEvents)
Vue.mixin(ToggleButtonEvents)
Vue.mixin(InputRadioEvents)
Vue.mixin(DateRangePickerEvents)
Vue.mixin(MarkDownEvents)
