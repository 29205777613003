<template>
  <div class="main-container">
    <div class="table-container" :class="{ 'wide-container': filterFields.length <= 2 }">
      <div id="table" :class="{ 'wide-table': filterFields.length <= 2 }">
        <div v-if="isLoadEndpoints" class="table-header">
          <!--          <p class="nameTable" v-if="isLoadEndpoints">{{ nameTable }}</p>-->
          <div class="search-filters-widgets">
            <div class="filter">
              <div class="filter-width">
                <div class="card-body">
                  <form>
                    <div class="select-filters">
                      <DateMonth
                        class="date"
                        label=""
                        placeholder="Месяц"
                        @monthRange="setMonth"
                        :value="selectedMonth || currentMonth"
                      />
                      <vue-select
                        class="select"
                        v-model="selectedRole"
                        :options="roles"
                        :clearable="false"
                        :searchable="false"
                        :filterable="false"
                        @input="setSelectedRole()"
                        placeholder="Роль"
                      >
                        <template slot="option" slot-scope="option">
                          <div class="d-center">
                            <span>{{ option.name }}</span>
                          </div>
                        </template>
                        <template slot="selected-option">
                          <div class="selected d-center">
                            {{ selectedRole.name }}
                          </div>
                        </template>
                        <span slot="no-options">Нет ролей</span>
                      </vue-select>
                      <vue-select
                        class="select"
                        v-model="selectedUser"
                        :options="userList"
                        :clearable="false"
                        :searchable="false"
                        :filterable="false"
                        @open="getUsers"
                        :disabled="!selectedRole"
                        @input="getMetaData()"
                        placeholder="ФИО"
                        style="width: 320px"
                      >
                        <template slot="option" slot-scope="option">
                          <div class="d-center">
                            <span>{{ option.full_name }}</span>
                          </div>
                        </template>
                        <template slot="selected-option">
                          <div class="selected d-center">
                            {{ selectedUser.full_name }}
                          </div>
                        </template>
                        <span slot="no-options">Нет ролей</span>
                      </vue-select>
                      <button class="filterClear" @click="clearFilter">
                        <img src="../../assets/img/ES/big_blue_cross.svg" alt="clear" />
                      </button>
                    </div>
                  </form>
                </div>

                <!--    end filter    -->
              </div>
            </div>

            <div class="global-search" v-if="viewSearch">
              <img src="../../assets/img/ES/gray_search.svg" alt="search" />
              <input
                id="globalSearch"
                placeholder="Поиск по таблице"
                @keyup.enter="globalSearch($event)"
                style="width: 100%"
              />
            </div>
          </div>
          <div class="dashboard" v-if="selectedRole.value === 'student' && selectedUser">
            <div class="lesson-amount">
              <div class="label">Остаток занятий</div>
              <img src="../../assets/img/ES/packs-count-blue.svg" alt="" />
              <div class="amount">
                {{
                  `${dashboardInfo.lessons_amount} ${endingOfWord(dashboardInfo.lessons_amount)}`
                }}
              </div>
            </div>
          </div>
          <Dashboard v-else @openModalRequisites="isModal = true" :info="dashboardInfo" />
        </div>
        <Table
          v-if="selectedUser"
          :currentStore="store"
          :dataTable="tableData.total_count"
          :fieldsTable="tableFields"
          :head-variant="'light'"
          :items="tableData.items"
          @$emits="$emits"
          :viewGlobalSearch="viewSearch"
          :multiSelect="multiSelect"
          :selectedRole="selectedRole.model"
        />
        <div class="choose-user" v-else><span>Выберите пользователя</span></div>
      </div>
    </div>
    <div id="cover-spin" v-show="!loading"></div>
  </div>
</template>

<script>
import Table from './Table'
import eventDelegate from '../../components/Core/KernelProject/eventDelegate'
import renderWidget from '../../components/Core/KernelProject/renderWidget'
import authEvents from '../../components/Core/KernelProject/authEvents'
import viewsDefaultEvents from './components/viewsDefaultEvents'
import tableEvents from './components/tableEvents'
import axios from 'axios'
import { domain, MicroServices } from '@/globalVariables'
import Dashboard from '@/views/SalaryReport/components/Dashboard'
import VueSelect from 'vue-select'
import DateMonth from '@/components/Widgets/DateRangePicker/Content/DateMonth/DateMonth'
import scriptMixins from '@/components/scriptMixins/scriptMixins'

export default {
  name: 'Report',
  components: {
    DateMonth,
    Dashboard,
    Table,
    VueSelect
  },
  mixins: [eventDelegate, renderWidget, authEvents, viewsDefaultEvents, tableEvents, scriptMixins],
  data() {
    return {
      tableData: [],
      tableFields: [],
      store: null,
      route: null,
      viewSearch: null,
      multiSelect: null,
      loading: false,
      to: null,
      from: null,
      isModal: false,
      requisite: null,
      dashboardInfo: null,
      roles: [
        {
          name: 'Методист',
          value: 'methodologist',
          model: 'Methodologist'
        },
        {
          name: 'Преподаватель',
          value: 'teacher',
          model: 'Teacher'
        },
        {
          name: 'Студент',
          value: 'student',
          model: 'Student'
        }
      ],
      selectedRole: '',
      userList: [],
      selectedUser: '',
      selectedMonth: '',
      fromPage: 1,
      currentMonth: null
    }
  },
  mounted() {
    this.$root.$on('getFilterData', (item) => {
      this.getFilterData()
    })
    // filter need
    this.$root.$on('filterSearch', (data) => {
      this.getDataWithFilter()
      this.$root.$emit('getMetaData', true)
    })
    // filter clear
    this.$root.$on('filterClear', (data) => {
      let clear = true
      this.getDataPagination(clear)
      this.$root.$emit('getMetaData', true)
    })
    // filter clear
    this.$root.$on('switchFilter', (data) => {
      this.switchFilter()
    })
    // filter spinner => true
    this.$root.$on('filterSpinner', (data) => {
      setTimeout(() => {
        this.filter = true
      }, 500)
    })

    this.$root.$on('changePage', (pages) => {
      this.fromPage = this.perPage * this.currentPage - this.perPage + 1
      this.getMetaData()
    })
    this.$root.$on('changePages', (pages) => {
      this.perPage = pages
      this.getMetaData()
    })

    this.currentMonth = `${new Date().getFullYear()}-${
      new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1
    }`
  },
  methods: {
    /**
     * Получение информации для верхней плашки
     */
    getDashboardInfo() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/${
          this.selectedRole.value === 'student'
            ? 'Student/getAvailableLessonsAmount'
            : 'LessonTransactionHistoryView/getLastPaymentAndCurrentWageRate'
        }`,
        method: 'POST',
        data: [
          {
            user_uuid: this.selectedUser.sync_uuid
          }
        ]
      })
        .then((response) => {
          this.dashboardInfo = response.data.data
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Получение пользователей по ролям для фильтра
     */
    getUsers() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/${this.selectedRole.model}/getItems`,
        method: 'GET',
        params: {
          _count: 1000
        }
      })
        .then((response) => {
          this.userList = response.data.data.items
        })
        .catch((error) => this.errorAlert(error))
    },
    setSelectedRole() {
      this.selectedUser = ''
      this.dashboardInfo = null
    },
    setMonth(month) {
      this.selectedMonth = month
      if (this.selectedUser) {
        this.getMetaData()
      }
    },
    clearFilter() {
      this.selectedUser = ''
      this.selectedRole = ''
      this.selectedMonth = ''
      this.dashboardInfo = null
      this.$root.$emit('filterClear')
    },

    /**
     * Получить данные для таблицы
     * @returns {null}
     */
    getMetaData() {
      this.getDashboardInfo()
      if (this.selectedRole.value === 'student') {
        this.getTableInfo(
          this.$store.state.pagination.from,
          this.$store.state.pagination.count,
          this.selectedMonth || this.currentMonth,
          null,
          null,
          null,
          null,
          'StudentTransactionHistoryView',
          null,
          this.selectedUser.sync_uuid
        )
        return null
      }
      this.getTableInfo(
        this.$store.state.pagination.from,
        this.$store.state.pagination.count,
        this.selectedMonth || this.currentMonth,
        null,
        null,
        null,
        null,
        'LessonTransactionHistoryViewForAdmin',
        this.selectedRole.value,
        this.selectedUser.sync_uuid
      )
    }
  },
  watch: {}
}
</script>

<style scoped lang="scss">
@import '../../styleVariables';

.select-filters {
  display: flex;
  align-items: flex-end;
}
.choose-user {
  width: 1004px;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 0px 20px -7px rgba(0, 0, 0, 0.16);
  border-radius: 8px 8px 0px 0px;
  background-color: $main-white;
  height: 60vh;
  span {
    color: $main-gray;
    font-size: 18px;
    line-height: 23px;
    font-family: $font-regular;
  }
}
.select {
  width: 210px;
  background-color: $main-white;
  margin-right: 15px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16) !important;
  border-radius: 4px !important;
  min-height: 42px !important;
  height: fit-content;
  border: none !important;
}
.date {
  margin-top: -6px;
  width: 210px;
  background-color: $main-white;
  margin-right: 15px;
  border-radius: 4px !important;
  min-height: 42px !important;
  height: fit-content;
  border: none !important;
  /deep/ input {
    border: none;
    height: 42px;
  }
}
/deep/.vs__dropdown-toggle {
  min-height: 42px;
  border: none;
  padding: 0 8px !important;
  .vs__selected {
    margin: 0 !important;
  }
  .vs__selected-options input::placeholder {
    color: #c5cee0;
    margin-top: -4px;
  }
  .vs__search,
  .vs__search:focus {
    margin: 0;
  }
  .vs--disabled .vs__search {
    background-color: #f8f8f8 !important;
  }
  .vs--disabled .vs__dropdown-toggle {
    background-color: #c5cee0;
  }
  .vs__selected {
    background-color: $main-white;
  }
}
/deep/ {
  .v-select.select-list.vs--single.vs--searchable {
    max-width: 230px;
  }
  .v-input__slot {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16) !important;
    border-radius: 4px !important;
    height: 42px !important;
  }
  .v-text-field.v-text-field--solo .v-input__control input {
    font-family: $font-bold !important;
    font-size: 18px !important;
    color: $main-gray;
  }
  .v-list .v-select-list .v-sheet .theme--light .v-list--dense .theme--light {
    margin-top: 46px !important;
  }
  .v-menu__content.theme--light {
    top: 120px !important;
  }
}

.dashboard {
  background-color: $main-white;
  box-shadow: 1px 0px 20px -7px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  width: 1004px;
  margin: 0 auto 10px auto;
  padding: 24px 21px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 79px;
  .lesson-amount {
    display: flex;
    align-items: center;
    .label {
      font-size: 14px;
      line-height: 24px;
      color: $main-gray;
      margin-right: 18px;
    }
    img {
      margin-right: 9px;
    }
    .amount {
      font-size: 14px;
      line-height: 24px;
      font-family: 'Roboto', sans-serif;
      color: $main-dark;
    }
  }
  .buttons {
    button {
      font-size: 14px;
      line-height: 24px;
      width: 184px;
      height: 32px;
      border-radius: 4px;
    }
    .return-cash {
      background-color: $main-white;
      color: $bright-blue;
      border: 1px solid $bright-blue;
    }

    .to-packs {
      background-color: $bright-blue;
      color: $main-white;
      margin-right: 15px;
    }
  }
}

.main-container {
  font-family: $font-regular, sans-serif !important;
  background-color: $background-blue !important;
  .search-filters-widgets {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
    margin-bottom: 24px;
  }
  .widgets-filters {
    display: flex;
    .add-button {
      /deep/ button.fill {
        background-color: $bright-blue;
        width: 42px !important;
        height: 42px;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
        border-radius: 43px;
        &:active {
          background-color: $hover-bright-blue;
        }
      }
    }
  }
  .card-header {
    padding-bottom: 0 !important;
  }
  .card {
    background-color: unset !important;
    border: none !important;
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  .table-container {
    width: 1024px;
    margin: 0 auto;
    overflow: hidden;
    height: 100%;
    padding-top: 24px;
  }
  .filterClear {
    width: 28px !important;
    height: 28px !important;
    background-color: $main-white;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
    border-radius: 50px;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .filter {
    //border-left: 1px solid $nav-lines;
    min-height: 100%;
    //background: $main-white;
    z-index: 10;
    margin-left: 10px;
    /deep/ .form-row {
      display: block;
      margin-left: 0 !important;
    }
    .filters {
      width: 220px;
    }
  }
  .wide-table {
    width: 100%;
    overflow: auto;
  }
  .hide-filter {
    display: none;
  }
}
/deep/ .col {
  //padding-left: 0 !important;
  padding: 5px !important;
  display: flex;
  align-items: flex-end;
  .filters {
    margin-right: 20px;
  }
}
/deep/ table.b-table-selectable > tbody > tr.b-table-row-selected > td,
table.b-table-selectable > tbody > tr.b-table-row-selected > th {
  //background-color: $btn-hover-background !important;
}
/deep/ .table-row {
  overflow-wrap: break-word;
}
.loaderPage {
  text-align-last: center;
  margin-top: 20%;
}
.table-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
#cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 8;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#cover-spin::after {
  content: '';
  display: block;
  position: absolute;
  left: 48%;
  top: 40%;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: black;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
}

.nameTable {
  font-family: $font-bold;
  font-size: 24px;
  line-height: 30px;
  color: $main-dark;
  margin-bottom: 10px;
  margin-left: 10px;
  width: auto;
  display: inline;
}
/deep/#app-layout-navbar {
  display: block !important;
  font-size: 16px;
  line-height: 19px;
  margin: 24px 0 12px 24px;
  width: 50%;
}
.breadcrumbs {
  //color: $main-blue;
}
.collapse:not(.show) {
  display: block;
}
.form-row {
  //display: unset !important;
  margin: 0 0 0 6px !important;
}
</style>
