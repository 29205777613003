<template>
  <div class="user-card">
    <div class="user-card__header">
      <div class="user-card__header-title">Карточка пользователя</div>
      <div
        class="user-card__header-btns"
        v-if="
          !user.is_confirmed &&
          user.user_status.code !== 'USER_BLOCKED' &&
          $cookie.get('internalName') !== 'methodologist'
        "
      >
        <button class="approve" @click="setUserApproval('1')">
          <img src="../../../assets/img/ES/big_white_check.svg" alt="approve" />
        </button>
        <button class="disapprove" @click="setUserApproval('0')">
          <img src="../../../assets/img/ES/big_blue_cross.svg" alt="disapprove" />
        </button>
      </div>
    </div>
    <div class="user-card__content">
      <div class="user-card__content-item">
        <span>Фамилия</span>
        <div class="editable">
          <span v-if="!editMode || $cookie.get('internalName') === 'methodologist'">{{
            user.lastname
          }}</span>
          <input v-else type="text" maxlength="25" v-model="editData.lastname" />
        </div>
      </div>
      <div class="user-card__content-item">
        <span>Имя</span>
        <div class="editable">
          <span v-if="!editMode || $cookie.get('internalName') === 'methodologist'">{{
            user.firstname
          }}</span>
          <input v-else type="text" maxlength="25" v-model="editData.firstname" />
        </div>
      </div>
      <div class="user-card__content-item">
        <span>Отчество</span>
        <div class="editable">
          <span v-if="!editMode || $cookie.get('internalName') === 'methodologist'">{{
            user.middlename
          }}</span>
          <input v-else type="text" maxlength="25" v-model="editData.middlename" />
        </div>
      </div>
      <div class="user-card__content-item">
        <span>Роль</span>
        <div class="editable">
          <span>{{ user.role }}</span>
        </div>
      </div>
      <div class="user-card__content-item">
        <span>Номер телефона</span>
        <div class="editable">
          <span v-if="!editMode || $cookie.get('internalName') === 'methodologist'">{{
            user.phone
          }}</span>
          <input v-else type="text" v-mask="phoneMask" v-model="editData.phone" />
        </div>
      </div>
      <div class="user-card__content-item">
        <span>Email</span>
        <div class="editable">
          <span v-if="!editMode || $cookie.get('internalName') === 'methodologist'">{{
            user.email
          }}</span>
          <input v-else type="text" v-model="editData.email" />
        </div>
      </div>
      <div class="user-card__content-item">
        <span>Статус</span>
        <div class="editable">
          <div
            class="user-status"
            :style="
              user.user_status.code === 'USER_NEW'
                ? 'background-color: #F3D232'
                : user.user_status.code === 'USER_ACTIVE'
                ? 'background-color: #1DBE40'
                : 'background-color: #E13429'
            "
          />
          <span>{{ user.user_status.name }}</span>
        </div>
      </div>
      <div class="user-card__content-item" v-if="user.role_id !== 5 && user.role_id !== 1">
        <span>Рабочий статус</span>
        <div class="editable">
          <div
            v-if="user.work_status"
            class="user-status"
            :style="
              user.work_status.code === 'WORK_ACTIVE'
                ? 'background-color: #1DBE40'
                : user.work_status.code === 'WORK_HOSPITAL'
                ? 'background-color: #FF8541'
                : user.work_status.code === 'WORK_TIME_OFF'
                ? 'background-color: #F3D232'
                : user.work_status.code === 'WORK_ABSENCES'
                ? 'background-color: #E13429'
                : 'background-color: #4B61AD'
            "
          />
          <span>{{ user.work_status.name }}</span>
        </div>
      </div>
      <div class="user-card__content-item" v-if="user.role_id === 4 || user.role_id === 5">
        <span>Стадия</span>
        <div class="editable">
          <span>{{ userStage }}</span>
        </div>
      </div>
      <div class="user-card__content-item" v-if="user.role_id === 3 || user.role_id === 4">
        <span>Ссылка на Zoom</span>
        <div class="editable">
          <span v-if="!editMode || $cookie.get('internalName') === 'methodologist'">{{
            user.link_to_zoom || 'no'
          }}</span>
          <input v-else type="text" v-model="editData.link_to_zoom" />
        </div>
      </div>
      <div class="user-card__content-item" v-if="user.role_id === 5">
        <span>Дата рождения</span>
        <div class="editable">
          <span v-if="!editMode || $cookie.get('internalName') === 'methodologist'">{{
            user.birthday_date
          }}</span>
          <date-pick
            v-model="date"
            :format="'YYYY-MM-DD'"
            :displayFormat="'DD.MM.YYYY'"
            :inputAttributes="{ readonly: true }"
            :clearable="false"
            class="date-pick"
            :isDateDisabled="isFutureDate"
            v-else
          ></date-pick>
        </div>
      </div>
      <div class="user-card__content-item">
        <span>Дата регистрации</span>
        <div class="editable">
          <span>{{ user.created_at }}</span>
        </div>
      </div>
      <div class="user-card__content-item" v-if="user.role_id === 4 || user.role_id === 5">
        <span>Level</span>
        <div class="editable">
          <span v-if="!editMode">{{ user.level.name }}</span>
          <vue-select
            @open="getLevels"
            :options="levels"
            v-model="editData.level"
            v-if="editMode"
            @input="getCEFRByLevel"
            class="select"
            :clearable="false"
          >
            <template slot="option" slot-scope="option">
              <div class="d-center">
                <span>{{ option.name }}</span>
              </div>
            </template>
            <template slot="selected-option">
              <div class="selected d-center">
                {{ editData.level.name }}
              </div>
            </template>
          </vue-select>
        </div>
      </div>
      <div class="user-card__content-item" v-if="user.role_id === 4 || user.role_id === 5">
        <span>CEFR</span>
        <div class="editable">
          <span v-if="!editMode">{{ user.europe_level ? user.europe_level.name : 'no' }}</span>
          <vue-select
            @open="getEuropeLevels"
            :options="europeLevels"
            v-model="editData.europe_level"
            v-if="editMode"
            class="select"
            @input="getLevelByCEFR"
            :clearable="false"
          >
            <template slot="option" slot-scope="option">
              <div class="d-center">
                <span>{{ option.name }}</span>
              </div>
            </template>
            <template slot="selected-option">
              <div class="selected d-center">
                {{ editData.europe_level.name }}
              </div>
            </template>
          </vue-select>
        </div>
      </div>
      <div class="user-card__content-item" v-if="user.role_id === 3 || user.role_id === 4">
        <span>Баланс</span>
        <div class="editable">
          <span
            v-if="
              !editMode ||
              this.$cookie.get('internalName') === 'manager' ||
              $cookie.get('internalName') === 'methodologist'
            "
            >{{ user.balance }}</span
          >
          <input v-else type="number" v-model="editData.balance" />
        </div>
      </div>
      <!--      <div class="user-card__content-item" v-if="user.role_id === 5">-->
      <!--        <span>Уровень</span>-->
      <!--        <div class="editable">-->
      <!--          <span v-if="!editMode">{{ user.level.name }}</span>-->
      <!--          <vue-select-->
      <!--            @open="getLevels"-->
      <!--            :options="levels"-->
      <!--            v-model="editData.level"-->
      <!--            v-if="editMode"-->
      <!--            class="select"-->
      <!--            @input="getCEFRByLevel"-->
      <!--            :clearable="false"-->
      <!--          >-->
      <!--            <template slot="option" slot-scope="option">-->
      <!--              <div class="d-center">-->
      <!--                <span>{{ option.name }}</span>-->
      <!--              </div>-->
      <!--            </template>-->
      <!--            <template slot="selected-option">-->
      <!--              <div class="selected d-center">-->
      <!--                {{ editData.level.name }}-->
      <!--              </div>-->
      <!--            </template>-->
      <!--          </vue-select>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="user-card__content-item" v-if="user.role_id === 5">
        <span>Остаток занятий</span>
        <div class="editable">
          <span>{{ user.lessons_amount }}</span>
        </div>
      </div>
      <div class="user-card__content-item" v-if="user.role_id === 5">
        <span>Revision steps</span>
        <div class="editable">
          <span v-if="!editMode">{{ user.revision_steps }}</span>
          <input v-else type="text" v-model="editData.revision_steps" />
        </div>
      </div>
      <div class="user-card__content-item" v-if="user.role_id === 5">
        <span>The amount of new words</span>
        <div class="editable">
          <span>{{ user.words_amount }}</span>
        </div>
      </div>
      <div class="user-card__content-item" v-if="user.role_id === 5">
        <span>Average speed</span>
        <div class="editable">
          <span>{{ user.average_speed }}</span>
        </div>
      </div>
      <div class="user-card__content-item" v-if="user.role_id !== 2 && user.role_id !== 1">
        <span>Менеджер</span>
        <div class="editable">
          <span v-if="!editMode || $cookie.get('internalName') === 'methodologist'">{{
            user.manager_full_name
          }}</span>
          <vue-select
            @open="getManagers"
            :options="managers"
            v-model="editData.manager"
            v-else
            class="select"
            :clearable="false"
          >
            <template slot="option" slot-scope="option">
              <div class="d-center">
                <span>{{ option.full_name }}</span>
              </div>
            </template>
            <template slot="selected-option">
              <div class="selected d-center">
                {{ editData.manager.full_name }}
              </div>
            </template>
          </vue-select>
        </div>
      </div>
      <div class="user-card__content-item" v-if="user.role_id === 5">
        <span>Методист</span>
        <div class="editable">
          <span v-if="!editMode || $cookie.get('internalName') === 'methodologist'">{{
            user.methodologist_full_name
          }}</span>
          <vue-select
            @open="getMethodists"
            :options="methodists"
            v-model="editData.methodist"
            v-else
            class="select methodists-select"
            :clearable="false"
          >
            <template slot="option" slot-scope="option">
              <div class="d-center">
                <span>{{ option.full_name }}</span>
              </div>
            </template>
            <template slot="selected-option">
              <div class="selected d-center">
                {{ editData.methodist.full_name }}
              </div>
            </template>
          </vue-select>
        </div>
      </div>
    </div>
    <div class="user-card__btns">
      <div class="control-btns" v-if="!editMode">
        <button
          class="btn-outline"
          @click="blockUser('1')"
          v-if="
            user.user_status.code !== 'USER_BLOCKED' &&
            $cookie.get('internalName') !== 'methodologist' &&
            user.sync_uuid !== $cookie.get('uid')
          "
        >
          Заблокировать
        </button>
        <button
          class="btn-outline"
          @click="blockUser('0')"
          v-else-if="user.user_status.code === 'USER_BLOCKED'"
        >
          Разблокировать
        </button>
        <button
          class="btn-fill"
          @click="
            setInitialState()
            editMode = true
          "
          v-if="user.role_id !== 1"
        >
          Редактировать
        </button>
      </div>
      <div class="edit-btns" v-else>
        <button
          class="btn-outline"
          @click="
            setInitialState()
            editMode = false
          "
        >
          Отменить
        </button>
        <button class="btn-fill" @click="saveUserCard">Сохранить</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { domain, MicroServices } from '@/globalVariables'
import VueSelect from 'vue-select'
import DatePick from 'vue-date-pick'
import 'vue-date-pick/dist/vueDatePick.css'

export default {
  name: 'UserCard',
  props: {
    user: Object,
    userStage: String
  },
  components: { VueSelect, DatePick },
  data() {
    return {
      editMode: false,
      editData: {},
      managers: [],
      levels: [],
      europeLevels: [],
      phoneMask: {
        mask: '+9 (999) 999 99-99',
        showMaskOnHover: false
      },
      date: null,
      methodists: []
    }
  },
  mounted() {},
  computed: {
    currentDate() {
      let today = new Date()
      return `${today.getFullYear()}-${
        today.getMonth() < 9 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1
      }-${today.getDate() < 10 ? '0' + today.getDate() : today.getDate()}`
    }
  },
  methods: {
    /**
     * Установка начального значения для редактирования
     */
    setInitialState() {
      this.editData = {
        firstname: this.user.firstname,
        lastname: this.user.lastname,
        middlename: this.user.middlename,
        phone: this.user.phone,
        email: this.user.email,
        balance: this.user.balance,
        lessons_amount: this.user.lessons_amount,
        manager: {
          sync_uuid: this.user.manager_uuid,
          full_name: this.user.manager_full_name
        },
        methodist: {
          sync_uuid: this.user.methodologist_uuid,
          full_name: this.user.methodologist_full_name
        },
        level: this.user.level,
        europe_level: this.user.europe_level || {
          name: ''
        }
      }
      if (this.user.role_id === 5) {
        this.date = this.formatDateToSend(this.user.birthday_date)
        this.editData.revision_steps = this.user.revision_steps
        this.editData.words_amount = this.user.words_amount
        this.editData.average_speed = this.user.average_speed
      } else if (this.user.role_id === 3 || this.user.role_id === 4) {
        this.editData.link_to_zoom = this.user.link_to_zoom
      }
    },

    validation() {
      if (
        !this.editData.firstname ||
        !this.editData.lastname ||
        this.editData.phone.indexOf('_') !== -1
          ? this.editData.phone.toString().replaceAll('_', '').length < 18
          : this.editData.phone.length < 11 || !this.editData.email
      ) {
        return true
      }
      if (this.user.role_id === 5) {
        return (
          !toString(this.editData.revision_steps) ||
          !toString(this.editData.average_speed) ||
          !toString(this.editData.words_amount) ||
          !toString(this.editData.lessons_amount)
        )
      }
      if (this.user.role_id === 3 || this.user.role_id === 4) {
        return !this.editData.link_to_zoom || !this.editData.balance
      }
      return false
    },
    formatDate(date) {
      let format = date.split('-')
      return `${format[2]}.${format[1]}.${format[0]}`
    },
    formatDateToSend(date) {
      if (date) {
        return `${date.split('.')[2]}-${date.split('.')[1]}-${date.split('.')[0]}`
      } else return null
    },
    isFutureDate(date) {
      return date > new Date()
    },

    /**
     * Получение списка менеджеров
     */
    getManagers() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Manager/getSuitableUsers`,
        method: 'POST',
        data: [
          {
            date: this.currentDate
          }
        ]
      })
        .then((response) => {
          this.managers = response.data.data
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Получение списка методистов
     */
    getMethodists() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Methodologist/getWorkingUsers`,
        method: 'POST',
        data: [
          {
            date: this.currentDate
          }
        ]
      })
        .then((response) => {
          this.methodists = response.data.data
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Получение списка уровней
     */
    getLevels() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Classifier/getEducationLevels`,
        method: 'GET',
        params: {
          _count: 20,
          for_teacher: this.user.role_id === 4
        }
      })
        .then((response) => {
          this.levels = response.data.data.items
        })
        .catch((error) => this.errorAlert(error))
    },

    /**
     * Получение списка европейских уровней
     */
    getEuropeLevels() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Classifier/getEuropeLevels`,
        method: 'GET',
        params: {
          _count: 20,
          for_teacher: this.user.role_id === 4
        }
      })
        .then((response) => {
          this.europeLevels = response.data.data.items
        })
        .catch((error) => this.errorAlert(error))
    },

    /**
     * Получить уровень при изменении CEFR
     */
    getLevelByCEFR() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Classifier/getSchoolLevelByEurope`,
        method: 'POST',
        data: [
          {
            kls_europe_level_id:
              this.editData.europe_level.kls_europe_level_id || this.editData.europe_level.id
          }
        ]
      })
        .then((response) => {
          this.editData.level = response.data.data
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Получить CEFR при изменении уровня
     */
    getCEFRByLevel() {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/Classifier/getEuropeLevelBySchool`,
        method: 'POST',
        data: [
          {
            kls_level_id: this.editData.level.kls_level_id || this.editData.level.id
          }
        ]
      })
        .then((response) => {
          this.editData.europe_level = response.data.data
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Сохранить личную информацию пользователя
     */
    saveUserCard() {
      this.editData.kls_level_id = this.editData.level?.id
      this.editData.kls_europe_level_id = this.editData.europe_level?.id
      this.editData.manager_uuid = this.editData.manager?.sync_uuid
      this.editData.methodologist_uuid = this.editData.methodist?.sync_uuid
      this.editData.birthday_date = this.date
      if (this.validation()) {
        this.messageAnyType('Заполните поля', 'error')
        return null
      }
      axios({
        url: `${domain}/${MicroServices[0].prefix}/User/update/${this.user.id}`,
        method: 'PUT',
        data: [this.editData]
      })
        .then(() => {
          this.successAlert('Данные обновлены!')
          this.$emit('update')
          this.editMode = false
          this.setInitialState()
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },
    /**
     * Подтвердить/Отклонить пользователя
     * @param flag
     */
    setUserApproval(flag) {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/User/confirmUser/${this.user.id}`,
        method: 'POST',
        data: [flag]
      })
        .then(() => {
          this.successAlert(flag === '1' ? 'Пользователь подтвержден' : 'Пользователь отклонен')
          this.$emit('update')
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    },

    /**
     * Заблокировать/разблокировать пользователя
     * @param flag
     */
    blockUser(flag) {
      axios({
        url: `${domain}/${MicroServices[0].prefix}/User/blockUser/${this.user.id}`,
        method: 'POST',
        data: [flag]
      })
        .then(() => {
          this.successAlert(
            flag === '1' ? 'Пользователь заблокирован' : 'Пользователь разблокирован'
          )
          this.$emit('update')
        })
        .catch((error) => {
          this.errorAlert(error)
        })
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../styleVariables';

.user-card {
  background-color: $main-white;
  border-radius: 8px;
  box-shadow: 1px 0px 20px -7px rgba(0, 0, 0, 0.16);
  width: 584px;
  &__header {
    padding: 16px 24px 20px;
    display: flex;
    justify-content: space-between;
    &-title {
      font-family: $font-bold;
      font-size: 18px;
      line-height: 23px;
    }
    &-btns {
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        width: 28px;
        height: 28px;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .approve {
        background-color: $bright-blue;
        margin-right: 14px;
        &:active {
          background-color: #0e52d7;
          box-shadow: none;
        }
      }
      .disapprove {
        background-color: $main-white;
        &:active {
          box-shadow: none;
        }
      }
    }
  }
  &__content {
    &-item {
      display: grid;
      grid-template-columns: 160px 1fr;
      grid-column-gap: 32px;
      grid-template-rows: 48px;
      padding-left: 24px;

      &:nth-child(2n + 1) {
        background-color: $light-table-blue;
      }

      span {
        align-self: center;
      }
      .editable {
        display: flex;
        align-items: center;
        align-self: center;
        span {
          font-family: $font-regular;
        }
        .date-pick {
          /deep/ .vdpComponent {
            position: static;
          }

          /deep/ .vdpFloating.vdpPositionTop {
            top: unset;
          }

          /deep/ .vdpFloating.vdpPositionLeft {
            left: unset;
          }

          /deep/ .vdpFloating.vdpPositionBottom {
            bottom: unset;
          }

          /deep/ input {
            width: 280px;
            height: 25px;
            border: 0.5px solid #c5cee0;
            border-radius: 4px;
            padding: 3px 8px;
            font-size: 14px;
            font-family: $font-regular;

            &:focus {
              outline: none;
              border-color: $bright-blue;
            }
          }
          /deep/ .vdpClearInput {
            display: none;
          }
        }
        .select {
          width: 280px;
          /deep/ .vs__selected .selected {
            font-family: $font-regular;
          }
          /deep/ .vs__dropdown-toggle {
            max-height: 52px;
          }
          /deep/ .vs__dropdown-menu {
            max-height: 120px;
          }
        }
        .vs--open {
          border-top: none;
          border-bottom: 1px solid $light-gray;
          border-radius: 4px;
        }
        .methodists-select {
          /deep/ .vs__dropdown-menu {
            top: -120px;
            height: 120px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom: none;
            border-top: 1px solid $light-gray;
          }
        }
        input {
          width: 280px;
          height: 25px;
          border: 0.5px solid #c5cee0;
          border-radius: 4px;
          padding: 3px 8px;
          margin-bottom: 6px;
          font-family: $font-regular;

          &:focus {
            outline: none;
            border-color: $bright-blue;
          }
        }
        .user-status {
          width: 6px;
          height: 6px;
          border-radius: 50px;
          margin-right: 9px;
        }
      }
    }
  }
  &__btns {
    .control-btns,
    .edit-btns {
      padding: 16px 24px;
      display: flex;
      justify-content: flex-end;
      button {
        width: 156px;
        height: 32px;
        border-radius: 4px;
        font-size: 14px;
      }
      .btn-outline {
        border: 1px solid $bright-blue;
        color: $bright-blue;
        margin-right: 14px;
        &:active {
          border-color: #0e52d7;
          color: #0e52d7;
        }
      }
      .btn-fill {
        background-color: $bright-blue;
        color: $main-white;
        &:active {
          background-color: #0e52d7;
        }
      }
    }
  }
}
</style>
