import Vue from 'vue'
import Vuex from 'vuex'
import Cookies from 'vue-cookie'
import axios from 'axios'

const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
const LOGOUT = 'LOGOUT'
const NEW_RECORD = 'newRec'
const RELOAD_STORE = 'ReloadStore'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    metaProjectData: {},
    metaProjectFilter: {},
    metaProjectDataEdit: {},
    metaProjectMenu: [],
    relationsData: {},
    metaProjectMultiEdit: {},
    newRecord: {},
    filterRec: {},
    pagination: {
      from: null,
      count: null,
      query: null,
      queryRelation: null,
      queryGlobal: null,
      queryThree: null,
      querySort: null,
      queryExact: null
    },
    auth: {
      authorisedStatus: Cookies.get('mandate'),
      mandate: {},
      endpoints: {}
    },
    breadcrumbs: [],
    viewBreadCrumbs: false,
    multiEditIds: null,
    settingTable: {
      dark: Cookies.get('dark') || false,
      bordered: Cookies.get('bordered') || false,
      borderless: Cookies.get('borderless') || false,
      fixed: Cookies.get('fixed') || false,
      footClone: Cookies.get('footClone') || false,
      hover: Cookies.get('hover') || false,
      outlined: Cookies.get('outlined') || false,
      small: Cookies.get('small') || false,
      stickyHeader: Cookies.get('stickyHeader') || false,
      theme: Cookies.get('themeName') || null
    }
  },
  mutations: {
    [NEW_RECORD](state, data) {
      if (data.type === 'tree' && data.keyAdd === 'tree') {
        Vue.set(state.newRecord, `_rel_model`, data.treeData.model)
        Vue.set(state.newRecord, `_rel_id`, data.treeData.id)
        Vue.set(state.newRecord, `value`, data.value)
      } else if (data.type !== 'tree' && (data.value || data.value === false || data.value === 0)) {
        Vue.set(state.newRecord, data.keyAdd, data.value)
      } else if (state.newRecord[data.keyAdd] && !data.value) {
        Vue.set(state.newRecord, data.keyAdd, '')
      }
    },
    [RELOAD_STORE](state) {
      state.newRecord = {}
      state.pagination.from = null
      state.pagination.count = null
      state.pagination.sortQuery = null
      state.pagination.query = null
      state.pagination.querySort = null
      state.pagination.queryExact = null
      state.pagination.rangeQuery = null
      state.pagination.queryGlobal = null
    },
    [LOGIN_SUCCESS](state) {
      state.auth.authorisedStatus = true
    },
    [LOGOUT](state) {
      state.auth.authorisedStatus = false
    }
  },
  actions: {
    login({ commit }, data) {
      commit(LOGIN_SUCCESS)
      return new Promise((resolve) => {
        this.state.mandate = data.data
        resolve()
      })
    },
    logout({ commit }) {
      Cookies.delete('mandate')
      Cookies.delete('weekSelected')
      Cookies.delete('daySelected')
      Cookies.delete('monthSelected')
      Cookies.delete('roleFilter')
      Cookies.delete('levelFilter')
      Cookies.delete('userFilter')
      Cookies.delete('weekStart')
      Cookies.delete('weekEnd')
      Cookies.delete('currentYear')
      Cookies.delete('currentDay')
      Cookies.delete('monday')
      Cookies.delete('sunday')
      delete axios.defaults.headers['Authorization']
      commit(LOGOUT)
    },
    reloadStore({ commit }) {
      commit('ReloadStore')
    }
  },
  getters: {
    isLoggedIn: (state) => {
      return state.auth.authorisedStatus
    }
  }
})
