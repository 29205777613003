<template>
  <div class="message">
    <div
      class="message-header"
      @click="goToUser"
      :class="{
        'click-title':
          !message.is_current_user_message &&
          ($cookie.get('internalName') === 'admin' || $cookie.get('internalName') === 'manager')
      }"
    >
      {{ message.title }}
    </div>
    <div
      class="message-content"
      :style="
        message.is_current_user_message ? 'background-color: #3264C3' : 'background-color: #F5FAFF'
      "
    >
      <div class="message-content__area">
        <div
          class="message-content__area-text"
          :style="message.is_current_user_message ? 'color: #ffffff' : 'color: #222B45'"
        >
          <!--          <div class="content">{{ message.message_id }}</div>-->
          <div class="content">{{ message.content }}</div>
        </div>
        <div
          class="message-content__area-controls"
          v-if="message.is_current_user_message && type === 'participant'"
        >
          <div
            v-if="message.can_be_deleted_for_all"
            class="edit"
            @click="$emit('editMessage', message.content, message.message_id, message.files[0])"
          >
            <img src="../../../../assets/img/ES/edit_chat.svg" alt="edit" />
          </div>
          <div class="delete">
            <img
              src="../../../../assets/img/ES/close_chat.svg"
              alt="delete"
              @click="$emit('deleteMessage', message.message_id, message.can_be_deleted_for_all)"
            />
          </div>
        </div>
        <div
          class="message-content__area-controls"
          v-else-if="!message.is_current_user_message && type === 'participant'"
        >
          <div class="delete">
            <img
              src="../../../../assets/img/ES/close_chat.svg"
              alt="delete"
              @click="$emit('deleteMessage', message.message_id, false)"
            />
          </div>
        </div>
      </div>
      <div class="file" @click="openFile(filePath)" v-if="message.files.length">
        <img
          :src="filePath"
          alt="file"
          v-if="
            message.files[0].extension === 'png' ||
            message.files[0].extension === 'jpeg' ||
            message.files[0].extension === 'jpg' ||
            message.files[0].extension === 'svg'
          "
        />
        <img src="../../../../assets/img/ES/file_icon.svg" alt="file" v-else />
        <div class="file-name">{{ message.files[0].name }}</div>
      </div>
      <div
        class="message-content__time"
        :style="message.is_current_user_message ? 'color: #C5CEE0' : 'color: #8F9BB3'"
      >
        <span v-if="message.is_updated">Отредактировано</span>
        {{ message.create_time }}
        <img
          src="../../../../assets/img/ES/read_chat.svg"
          alt="ok"
          v-if="message.is_current_user_message && message.can_be_deleted_for_all"
        />
        <img
          src="../../../../assets/img/ES/check_all.svg"
          alt="read"
          v-else-if="message.is_current_user_message && !message.can_be_deleted_for_all"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { fileDomain } from '@/globalVariables'

export default {
  name: 'Message',
  props: {
    message: Object,
    type: String
  },
  computed: {
    filePath() {
      return `${fileDomain}${this.message.files[0].path}`
    }
  },
  methods: {
    /**
     * Открыть файл
     */
    openFile(path) {
      window.open(`${path}`, '_blank')
    },
    /**
     * Открыть карточку пользователя
     */
    goToUser() {
      if (
        !this.message.is_current_user_message &&
        (this.$cookie.get('internalName') === 'admin' ||
          this.$cookie.get('internalName') === 'manager')
      ) {
        this.$router.push({ name: 'UserControl', params: { user_id: this.message.author_id } })
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../../styleVariables';
.message {
  margin-bottom: 24px;
  width: 100%;
  .click-title {
    cursor: pointer;
    &:hover {
      color: $bright-blue;
    }
  }
  &-hedaer {
    font-size: 16px;
    line-height: 20px;
    color: $main-dark;
  }
  &-content {
    border-radius: 8px;
    padding: 6px 6px 8px 15px;
    width: fit-content;
    max-width: 100%;
    &:hover .message-content__area-controls {
      opacity: 1;
    }

    .file {
      display: flex;
      align-items: center;
      padding: 2px;
      border-radius: 4px;
      margin-top: 12px;
      cursor: pointer;
      background-color: $main-white;
      max-width: 90%;
      width: fit-content;
      img {
        margin-right: 4px;
        height: 24px;
        max-width: 60px;
      }
      &-name {
        max-width: 100%;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        font-family: $font-regular;
        color: $main-dark;
      }
    }
    &__area {
      display: flex;
      justify-content: space-between;
      &-text {
        display: flex;
        flex-direction: column;
        .content {
          width: fit-content;
          word-break: break-all;
          min-width: 49px;
          margin-right: 12px;
          font-size: 16px;
          line-height: 144.2%;
          padding-top: 4px;
          font-family: $font-regular;
        }
      }
      &-controls {
        display: flex;
        opacity: 0;
        transition: 0.2s;
        .edit {
          margin-right: 6px;
        }
        .edit,
        .delete {
          cursor: pointer;
        }
      }
    }
    &__time {
      text-align: end;
      padding-right: 5px;
      font-size: 14px;
      line-height: 18px;
      font-family: $font-regular;
      span {
        font-family: $font-regular;
        margin-right: 12px;
      }
      img {
        margin-top: -4px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .message-header {
    font-size: 14px !important;
  }
}
</style>
