var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[(
      (_vm.$cookie.get('internalName') === 'student' ||
        _vm.$cookie.get('internalName') === 'teacher' ||
        _vm.$cookie.get('internalName') === 'methodologist') &&
      _vm.$cookie.get('mandate')
    )?_c('button',{staticClass:"help-chat",on:{"click":function($event){_vm.showHelpWindow = !_vm.showHelpWindow}}},[_c('img',{attrs:{"src":require("./assets/img/ES/chat_button.svg"),"alt":"help"}}),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.messageCount),expression:"messageCount"}],staticClass:"help-chat-counter"},[_vm._v(_vm._s(_vm.messageCount))])]):_vm._e(),_vm._v(" "),(
      (_vm.$cookie.get('internalName') === 'student' ||
        _vm.$cookie.get('internalName') === 'teacher' ||
        _vm.$cookie.get('internalName') === 'methodologist') &&
      _vm.$cookie.get('mandate')
    )?_c('div',{staticClass:"help-window"},[(_vm.showHelpWindow)?_c('HelpWindow',{on:{"hide":function($event){_vm.showHelpWindow = false}}}):_vm._e()],1):_vm._e(),_vm._v(" "),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }